import { useEffect, useState } from 'react';
import apiDoubts from '../../../services/api';
import CardPost from '../CardPost';
import { Container } from './styles';
import GIF from '../../../assets/not-found.gif';
import { toast } from 'react-toastify';
import { IconLoading } from '../../Icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
import EmptyProducts from '../../EmptyProducts';
import EmptyPosts from '../../EmptyPosts';

interface IProps {
  playlist?: any;
  posicao?: string;
  load?: boolean;
  where?: {
    interesse?: string;
    search?: string;
    hash?: string;
  };
  order?: string;
  consultorId?: string;
  tipo?: string;
}

export default function ListPosts({
  playlist,
  posicao,
  load = false,
  where = {
    hash: '',
    search: '',
    interesse: '',
  },
  order = 'popular',
  consultorId,
  tipo,
}: IProps) {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [completeData, setCompleteData] = useState<any>();

  const params: any = useParams();

  useEffect(() => {
    if (consultorId) getDataByConsultor();
    if (tipo === 'playlist') getDataByPlaylist();
  }, [consultorId, playlist]);

  useEffect(() => {
    if (!consultorId && tipo !== 'playlist' && page === 1) {
      //quando abre a página pela primeira vez
      console.log('puxar os primeiros vídeos');
      getData([]);
    }
    if (!consultorId && tipo !== 'playlist' && page > 1) {
      //quando carrega um novo video
      console.log('puxar mais videos');
      getData(data);
    }

    if (consultorId && page !== 1) getDataByConsultor();
  }, [playlist, page]);

  useEffect(() => {
    setPage(1);
    if (!consultorId && tipo !== 'playlist') {
      getData([]);
    }
  }, [where, order]);

  useEffect(() => {
    const normalVideos = data?.filter((video) => video?.type !== 'anuncio');

    localStorage?.setItem('nextVideo', JSON.stringify(normalVideos[0]));
  }, [data]);

  async function getDataByConsultor() {
    setLoading(true);
    try {
      if (!page) {
        setLoading(false);
        return;
      }

      page === 1 && setData([]);

      await apiDoubts
        .get(
          `postagens/getPostagemConsultor/${consultorId}?pagination=true&limit=20&page=${page}`
        )
        .then((response) => {
          if (response?.data?.data?.docs?.length === 0)
            throw new Error('Não há mais vídeos para serem carregados...');

          setCompleteData(response);
          const newData: any = [];

          response?.data?.data?.docs?.map((row: any, key: number) => {
            newData.push(row);
          });
          setData([...data, ...newData]);
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
          toast.error(error, {
            className: 'toast-error',
            theme: 'colored',
          });
        });
    } catch (error: any) {
      setLoading(false);
      console.log('error', error);
      toast.error(error, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  async function getDataByPlaylist() {
    console.log('pegou as playlists');
    try {
      const newData: any = [];

      playlist?.postagens?.map((row: any, key: number) => {
        newData.push(row);
      });
      setData([...newData]);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err: any) {
      setLoading(false);
      console.log('catcvh playlist', err);
      alert('Não foi possivel listar os videos dessa playlist.');
    }
  }

  async function getData(dados?: any) {
    try {
      if (page === 1) {
        setData([]);
        setLoading(true);
      }

      if (!dados) {
        setData([]);
      }

      let response: any;

      if (where?.interesse === 'em-alta') {
        response = await apiDoubts.get(
          `postagens-em-alta?page=1&limit=20&pagination=true`
        );
      } else if (where.interesse === 'aovivo') {
        response = await apiDoubts.get(
          `/postagens/pesquisa/live?limit=30&page=1&pagination=true`
        );
      } else if (posicao === 'lateral') {
        let partialResponse = await apiDoubts.post(
          `/postagens/relacionadas?limit=10&page=1&pagination=true`,
          {
            postId: `${params?.id}`,
          }
        );
        response = {
          data: {
            data: {
              docs: partialResponse.data.data,
            },
          },
        };
      } else {
        response = await apiDoubts.get(
          `postagens?limit=30&page=${page}&ordenar=${order}&listaInteresses=${
            where?.interesse ?? ''
          }&pagination=true&pesquisa=${where.search ?? ''}&listaHashtags=${
            where?.hash ?? ''
          }`
        );
      }

      setCompleteData(response);
      const newData: any = [];

      response?.data?.data?.docs?.map((row: any, key: number) => {
        newData.push(row);
      });

      if (!dados[0]) {
        //quando eu quero que apareça apenas os vídeos que foram puxados agora
        setData([...newData]);
      }

      if (dados[0]) {
        // setData(
        //   [...dados, ...newData].filter((row: any) => {
        //     return !dados.includes(row) || row.type === 'anuncio';
        //   })
        // );
        setData([...dados, ...newData]);
      }

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  const handleScroll = (event: any) => {
    console.log('scrollTop: ', event.currentTarget.scrollTop);
    console.log('offsetHeight: ', event.currentTarget.offsetHeight);
  };

  return (
    <Container
      loading={false}
      lenght={data?.length}
      posicao={posicao}
      playlist={playlist}
      onScroll={handleScroll}
    >
      <InfiniteScroll
        dataLength={data?.length}
        next={() => {
          setPage(completeData?.data?.data?.nextPage);
        }}
        hasMore={true}
        loader={<h4></h4>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        {(load || loading) && (
          <div className="loadingWrapper">
            <IconLoading />
          </div>
        )}

        {!load && !loading && data.length > 0
          ? data.map((row: any, key) => (
              <CardPost
                playlist={playlist}
                key={key}
                item={row}
                type={row?.type ? 'anuncio' : tipo}
                posicao={posicao}
              />
            ))
          : !loading && (
              <div className="emptyPosts">
                <EmptyPosts
                  description="Volte mais tarde e talvez tenha algo para você ver!"
                  title="Ops"
                />
              </div>
            )}
      </InfiniteScroll>
    </Container>
  );
}
