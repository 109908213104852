import { LoginCardLeft, LoginCardRight, LoginContainer } from './styles';
import Logo from '../../assets/Logo.png';
import Screen from '../../assets/UserScreen.png';
import { useState } from 'react';
import apiDoubts from '../../services/api';
import SyncLoader from 'react-spinners/SyncLoader';
import EnterArrowIcon from '../../assets/icons/EnterArrowIcon';
import EyeIcon from '../../assets/icons/EyeIcon';
import ClosedEyeIcon from '../../assets/icons/ClosedEyeIcon';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory, useLocation } from 'react-router-dom';
import { Axios, AxiosError } from 'axios';

const ChangePassword: React.FC<any> = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const buffer: string | null = searchParams.get('buffer');

  const [loading, setLoading] = useState<boolean>(false);
  const [DTO, setDTO] = useState({
    senha: '',
    senhaRepetida: '',
    token: buffer ?? '',
  });
  const [showPassword1, setShowPassword1] = useState<boolean>(false);
  const [showPassword2, setShowPassword2] = useState<boolean>(false);

  const history = useHistory();

  function validatePassword(password: string): void {
    const regexNumber = /[0-9]/;
    const regexUppercaseLetter = /[A-Z]/;
    const regexSymbol = /[$&+,:;=?@#|'<>.^*()%!-]/;

    let errorMessage = '';

    if (password.length < 6) {
      errorMessage += 'A senha deve ter pelo menos 3 caracteres. ';
    }

    if (!regexNumber.test(password)) {
      errorMessage += 'A senha deve conter pelo menos 1 número. ';
    }

    if (!regexUppercaseLetter.test(password)) {
      errorMessage += 'A senha deve conter pelo menos 1 letra maiúscula. ';
    }

    if (!regexSymbol.test(password)) {
      errorMessage += 'A senha deve conter pelo menos 1 símbolo. ';
    }

    if (errorMessage) {
      throw new Error(errorMessage);
    }
  }

  function handleOnChange(name: any, value: any) {
    const newDTO: any = DTO;
    newDTO[name] = value;
    setDTO({ ...newDTO });
  }

  async function handleOnSubmit(e: any) {
    try {
      e.preventDefault();

      if (loading === true)
        throw new Error('Aguarde enquanto processamos seus dados.');

      setLoading(true);

      if (!DTO.senha)
        throw new Error('Preencha sua senha antes de prosseguir.');
      console.log('passou da primeira');

      if (!DTO.senhaRepetida)
        throw new Error('Preencha sua senha antes de prosseguir.');
      console.log('passou da segunda');

      validatePassword(DTO.senha);
      validatePassword(DTO.senhaRepetida);

      if (DTO.senha !== DTO.senhaRepetida)
        throw new Error('As duas senhas precisam ser iguais.');

      let counterError = 0;
      let tokenExpiradoCounter = 0;

      await Promise.all([
        apiDoubts.post(`cadastros/trocarSenha`, DTO).catch((response: any) => {
          if (response.response.data.message === 'Token expirado') {
            tokenExpiradoCounter++;
          }

          counterError++;
        }),
        apiDoubts.post(`consultor/trocarSenha`, DTO).catch((response: any) => {
          if (response.response.data.message === 'Token expirado') {
            tokenExpiradoCounter++;
          }

          counterError++;
        }),
        apiDoubts.post(`empresa/trocarSenha`, DTO).catch((response: any) => {
          if (response.response.data.message === 'Token expirado') {
            tokenExpiradoCounter++;
          }

          counterError++;
        }),
        apiDoubts.post(`agencia/trocarSenha`, DTO).catch((response: any) => {
          if (response.response.data.message === 'Token expirado') {
            tokenExpiradoCounter++;
          }

          counterError++;
        }),
      ]);

      console.log(tokenExpiradoCounter);

      if (counterError === 3) {
        toast.success('Senha redefinida com sucesso !!!', {
          className: 'toast-succes',
          theme: 'colored',
        });

        history.push('/login');
      } else if (counterError === 4 && tokenExpiradoCounter === 4) {
        toast.error(
          'O email que te enviamos expirou, repita o processo para redefinir a sua senha',
          {
            className: 'toast-error',
            theme: 'colored',
          }
        );
      } else {
        toast.error(
          'O email que te enviamos expirou, repita o processo para redefinir a sua senha',
          {
            className: 'toast-error',
            theme: 'colored',
          }
        );
      }

      setLoading(false);
    } catch (err: any) {
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
      setLoading(false);
    }
  }

  return (
    <LoginContainer>
      <LoginCardLeft>
        <div className="logo">
          <img src={Logo} alt="Logo Doubts no lado esquerdo" />
        </div>

        <div className="middle">
          <div className="text">
            <h1>Redefinição de senha</h1>
            <p className="bold">
              Digite abaixo a sua nova senha. Ao apertar "Confirmar" ela se
              tornará a única forma de acessar a sua conta
            </p>
          </div>
          <div className="input">
            <form onSubmit={handleOnSubmit}>
              <div className="pass">
                <input
                  className="input-icon"
                  name="Senha"
                  type={showPassword1 ? 'text' : 'password'}
                  value={DTO.senha}
                  onChange={(e) => handleOnChange('senha', e.target.value)}
                  placeholder="Senha"
                />
                {showPassword1 ? (
                  <div
                    className="pass-icon"
                    onClick={() => {
                      setShowPassword1((oldValue) => !oldValue);
                    }}
                  >
                    <ClosedEyeIcon />
                  </div>
                ) : (
                  <div
                    className="pass-icon"
                    onClick={() => {
                      setShowPassword1((oldValue) => !oldValue);
                    }}
                  >
                    <EyeIcon />
                  </div>
                )}
              </div>

              <div className="pass">
                <input
                  className="input-icon"
                  name="Senha Repetida"
                  type={showPassword2 ? 'text' : 'password'}
                  value={DTO.senhaRepetida}
                  onChange={(e) =>
                    handleOnChange('senhaRepetida', e.target.value)
                  }
                  placeholder="Senha Novamente"
                />
                {showPassword2 ? (
                  <div
                    className="pass-icon"
                    onClick={() => {
                      setShowPassword2((oldValue) => !oldValue);
                    }}
                  >
                    <ClosedEyeIcon />
                  </div>
                ) : (
                  <div
                    className="pass-icon"
                    onClick={() => {
                      setShowPassword2((oldValue) => !oldValue);
                    }}
                  >
                    <EyeIcon />
                  </div>
                )}
              </div>

              <button type="submit">
                {loading === true ? (
                  <SyncLoader
                    color={'#0FBABA'}
                    loading={loading}
                    size={10}
                    margin={10}
                  />
                ) : (
                  'Redefinir Senha'
                )}
                <EnterArrowIcon />
              </button>
              <div className="linkArea">
                <a href="/login" className="link">
                  Fazer login com
                  <span style={{ fontWeight: 700 }}> outra conta </span>
                </a>
              </div>
            </form>
          </div>
        </div>
      </LoginCardLeft>

      <LoginCardRight>
        <div
          className="user-photo"
          style={{ backgroundImage: `url(${Screen})` }}
        />
      </LoginCardRight>
    </LoginContainer>
  );
};

export default ChangePassword;
