import { Container } from './styles';

import HeaderConsultor from '../../components/HeaderConsultor';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import HeaderType from '../../components/HeaderType';
import bannerNovo from '../../assets/bannerNovo.png';
import Listar from '../../pages/Videos/Listar';
import ListPosts from '../../components/Posts/ListPosts';

export default function Consultant() {
  const params = useParams<any>();
  const [page, setPage] = useState('Canal');
  const history = useHistory()


  useEffect(()=>{
    history.push(`/consultor/visualizar/${page.toLocaleLowerCase()}/${params.id}`);
  },[page])

  return (
    <Container>
      <HeaderConsultor consultorId={params.id} />

      {( page === 'Social') && (
        <main className="generic">
          <div className="content">
            <HeaderType tipo={page} consultorId={params.id} />
            <footer></footer>
          </div>
          <div className="sideRigth"> </div>
        </main>
      )}

      {(page === 'Playlist' ) && (
        <main className="generic">
          {/* <div className="content">
            <HeaderType tipo={page} consultorId={params.id} />
            <footer></footer>
          </div>
          <div className="sideRigth"></div> */}
        </main>
      )}

      {page === 'Canal' && (
        <main className="canal">
          <div className="DescriptionConsultor">
            <HeaderType tipo={page} consultorId={params.id} />

            <div
              className="banner"
              style={{ backgroundImage: `url(${bannerNovo})` }}
            ></div>
          </div>
          <div className="contentCanal">
            <ListPosts
              order=""
              where={{}}
              consultorId={params.id}
              tipo="consultor"
            />
            <footer></footer>
          </div>
        </main>
      )}
    </Container>
  );
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// import { useEffect, useState } from 'react';
// import apiDoubts from '../../services/api';
// import { Container, Content, Header, Main } from './styles';
// import { toast } from 'react-toastify';
// import { CardView } from '../Videos/ListarConsultor/styles';
// import { useHistory } from 'react-router-dom';
// import Moment from 'react-moment';
// import 'moment/locale/pt-br';
// import IconPlay from '../../assets/icons/IconPlay';
// import ModalDefault from '../../components/Modal Default';
// import Visualizar from '../Videos/Visualizar';
// import FavoritosIcon from '../../assets/icons/favoritosIcon';
// import UnfollowIcon from '../../assets/icons/UnfollowIcon';
// import VideoIcon from '../../assets/icons/videoIcon';
// import EyeIcon from '../../assets/icons/EyeIcon';
// import StatsIcon from '../../assets/icons/analyticsIcon';
// import CalendarIcon from '../../assets/icons/CalendarIcon';
// import ConsultorConsultoria from '../ConsultorConsultoria';
// import ConsultorModal from '../../components/ConsultorModal';
// import Banner from '../../components/Banner';

// const Consultant: React.FC<any> = (item: any) => {
//   const history = useHistory();
//   const [loading, setLoading] = useState<boolean>(false);
//   const [modal, setModal] = useState<boolean>(false);
//   const [consultorInfo, setConsultorInfo] = useState<any>([]);
//   const [videos, setVideos] = useState<any[]>([]);
//   const [videoSelected, setVideoSelected] = useState<any>();
//   const [selected, setSelected] = useState<any>('canal');
//   const [totalVisulizations, setTotalVisualizations] = useState<any>();
//   const [profile, setProfile] = useState<any>({});
//   const [following, setFollowing] = useState<any>();
//   const [updateFollowers, setUpdateFollowers] = useState<any>();
//   const [banner, setBanner] = useState();

// const consultorId = item.match.params.id;

// useEffect(() => {
//   async function getConsultantInfo() {
//     try {
//       setLoading(true);
//       // const response = await apiDoubts.get('/consultor/get-by-id/' + item)
//       const response = await apiDoubts.get(
//         '/consultor/get-by-id/' + consultorId
//       );
//       setConsultorInfo(response.data.data);
//       if (response.data.status === 'error')
//         throw new Error(response.data.message);
//       setLoading(false);
//     } catch (err: any) {
//       toast.error(err, {
//         className: 'toast-error',
//         theme: 'colored',
//       });
//       setLoading(false);
//     }
//   }

//   async function getVideos(consultorId: any) {
//     try {
//       setLoading(true);
//       const response = await apiDoubts.get(
//         'postagens/getPostagemConsultor/' + consultorId
//       );
//       setVideos(response.data.data);
//       if (response.data.status !== 'success')
//         throw new Error('Não foi possivel encontrar os vídeos');
//       setLoading(false);
//     } catch (error: any) {
//       toast.error(error, {
//         className: 'toast-error',
//         theme: 'colored',
//       });
//       setLoading(false);
//     }
//   }

//   async function getTotalVisualizations() {
//     try {
//       setLoading(true);
//       const response = await apiDoubts.get(
//         'consultor-visualizacoes/' + consultorId
//       );
//       if (response.data.status !== 'success')
//         throw new Error(
//           'Não foi possível encontrar o total de visualizações'
//         );
//       setTotalVisualizations(response.data.data);
//       setLoading(false);
//     } catch (error: any) {
//       toast.error(error, {
//         className: 'toast-error',
//         theme: 'colored',
//       });
//       setLoading(false);
//     }
//   }

//   async function getProfile() {
//     try {
//       setLoading(true);

//       const response = await apiDoubts.get('profile');
//       setProfile(response.data.data);

//       setLoading(false);
//     } catch (err: any) {
//       toast.error(err, {
//         className: 'toast-error',
//         theme: 'colored',
//       });
//       setLoading(false);
//     }
//   }

//   getProfile();
//   getConsultantInfo();
//   getVideos(consultorId);
//   getTotalVisualizations();
// }, [updateFollowers]);

// useEffect(() => {
//   async function getBanner() {
//     await apiDoubts
//       .get('banners?tipo=consultor-canal&randon=true')
//       .then((response) => {
//         setBanner(response.data.data[0]);
//       });
//   }
//   getBanner();
// }, []);

// useEffect(() => {
//   function getFollowers() {
//     try {
//       if (profile === null) {
//         throw new Error('Usuário não logado');
//       }
//       const user = profile.id;
//       const followers = consultorInfo[0]?.seguidores;
//       const followerExists = followers?.filter((obj: string | string[]) =>
//         obj.includes(`${user}`)
//       );
//       if (followerExists?.length > 0) {
//         setFollowing('exists');
//       }
//     } catch (error: any) {
//       console.log('log erro followers', error);
//     }
//   }

//   getFollowers();
// }, [updateFollowers]);

// async function followConsultant() {
//   try {
//     if (profile === null) {
//       history.push('/login');
//       throw new Error('Necessário estar logado para seguir um consultor');
//     }

//     setLoading(true);
//     const response = await apiDoubts.put(
//       'consultor/seguidor/novo/' + profile.id,
//       {
//         consultorId: consultorId,
//       }
//     );

//     if (response.data.status !== 'success')
//       throw new Error(response.data.message);
//     setUpdateFollowers(response.data.data);
//     setLoading(false);
//   } catch (error: any) {
//     toast.error(error.message, {
//       className: 'toast-error',
//       theme: 'colored',
//     });

//     setLoading(false);
//   }
// }

// function handleOnSelected(item: any) {
//   setModal(true);
//   setVideoSelected({ ...item });
// }

// return (
//   <Container>
//     {loading !== true && (
//       <Content>
//         <Header>
//           <div className="infos">
//             <div
//               className="logo"
//               style={{ backgroundImage: `url(${consultorInfo[0]?.foto})` }}
//             />

//             <div className="title">
//               <div className="name">
//                 {consultorInfo[0]?.nickName}
//                 {consultorInfo[0]?.online === 1 ? (
//                   <div className="on">Online</div>
//                 ) : (
//                   <div className="off">Offline</div>
//                 )}
//               </div>
//               <div className="inscriptions">
//                 {consultorInfo[0]?.seguidores.length > 1
//                   ? consultorInfo[0]?.seguidores.length + ' inscritos'
//                   : consultorInfo[0]?.seguidores.length + ' inscrito'}
//               </div>
//             </div>
//           </div>

//           <div className="subscribe">
//             {following ? (
//               <div className="follow" onClick={() => followConsultant()}>
//                 <FavoritosIcon />
//               </div>
//             ) : (
//               <div className="unfollow" onClick={() => followConsultant()}>
//                 <UnfollowIcon />
//               </div>
//             )}
//           </div>
//         </Header>

//         <Main>
//           <div className="menu">
//             <div
//               className={selected === 'canal' ? 'tab video' : 'tab'}
//               onClick={() => setSelected('canal')}
//             >
//               Canal
//             </div>
//             <div
//               className={selected === 'playlist' ? 'tab about' : 'tab'}
//               onClick={() => setSelected('playlist')}
//             >
//               Playlist
//             </div>
//             <div
//               className={selected === 'social' ? 'tab about' : 'tab'}
//               onClick={() => setSelected('social')}
//             >
//               Social
//             </div>
//             <div
//               className={selected === 'consultoria' ? 'tab about' : 'tab'}
//               onClick={() => setSelected('consultoria')}
//             >
//               Consultoria
//             </div>
//             <div
//               className={selected === 'sobre' ? 'tab about' : 'tab'}
//               onClick={() => setSelected('sobre')}
//             >
//               Sobre
//             </div>
//           </div>

//           {selected === 'about' && (
//             <div>
//               <p>Playlist</p>
//             </div>
//           )}

//           {selected === 'canal' ? (
//             <div className="containerCanal">
//               <div className="consultorDescription">
//                 <div className='consultorModal'>
//                   <ConsultorModal consultorInfo={consultorInfo[0]} />
//                 </div>
//                 <Banner type="2" banner={banner} />
//               </div>
//               <div className="videos-consultant">
//                 {videos.map((row: any, key: any) => (
//                   <CardView key={key} onClick={() => handleOnSelected(row)}>
//                     <div
//                       className="image"
//                       style={{ backgroundImage: `url(${row.thumbnail})` }}
//                     >
//                       <div className="player">
//                         <IconPlay />
//                       </div>
//                     </div>

//                     <div className="cardInfo">
//                       <footer>
//                         <div className="header">
//                           <div className="title">
//                             <h3>{row.titulo}</h3>
//                             <span className="visual">
//                               {row.qtdVisualizacoes > 0
//                                 ? row.qtdVisualizacoes
//                                 : 0}{' '}
//                               visualizações |{' '}
//                               <Moment locale="pt-br" fromNow>
//                                 {row.data}
//                               </Moment>
//                             </span>
//                           </div>
//                         </div>
//                       </footer>
//                     </div>
//                   </CardView>
//                 ))}
//               </div>
//             </div>
//           ) : (
//             ''
//           )}

//           {selected === 'social' && (
//             <div>
//               <h3> Social </h3>
//             </div>
//           )}
//           {selected === 'consultoria' && (
//             <ConsultorConsultoria data={consultorInfo} />
//           )}
//           {selected === 'sobre' && (
//             <div className="about-consultant">
//               <div className="description">
//                 Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ab
//                 perspiciatis quasi ullam optio suscipit dicta beatae esse
//                 consectetur! Nobis laudantium vel a veniam atque id suscipit
//                 reiciendis magni repellat commodi.
//               </div>

//               <div className="stats-consultant">
//                 <div className="name-consultant">
//                   {consultorInfo[0]?.nickName} -{' '}
//                   <div className="icon">
//                     <StatsIcon />
//                   </div>{' '}
//                 </div>
//                 <div className="info-stats">
//                   <div className="info-title">Consultor desde:</div>
//                   <div>
//                     <CalendarIcon />
//                   </div>
//                   <div>
//                     <Moment locale="pt-br" format="DD/MM/YYYY">
//                       {consultorInfo[0]?.createdAt}
//                     </Moment>
//                   </div>
//                 </div>
//                 <div className="info-stats">
//                   <div className="info-title">Total vídeos:</div>
//                   <div className="icon">
//                     <VideoIcon />
//                   </div>
//                   <div>{videos.length} vídeos</div>
//                 </div>
//                 <div className="info-stats">
//                   <div className="info-title">Total visualizações:</div>
//                   <div>
//                     <EyeIcon />
//                   </div>
//                   <div>{totalVisulizations} visualizações</div>
//                 </div>
//               </div>
//             </div>
//           )}
//         </Main>
//       </Content>
//     )}

//     {modal && (
//       <ModalDefault
//         id="overlayModal"
//         onClose={() => setModal(!modal)}
//         openModal={modal}
//       >
//         <Visualizar item={videoSelected} />
//       </ModalDefault>
//     )}
//   </Container>
// );
// };

// export default Consultant;
