import moment from 'moment';

export function getDifferenceBetweenTwoMoments(
  startMoment: moment.Moment,
  endMoment: moment.Moment
) {
  const difference = moment.duration(endMoment.diff(startMoment));

  return {
    seconds: difference?.seconds(),
    minutes: difference?.minutes(),
    hours: difference?.hours(),
    days: difference?.days(),
    months: difference?.months(),
    years: difference?.years(),
  };
}
