import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(345px, 1fr));
  /* flex-direction: column; */
  gap: 20px;
  padding-bottom: 20px;
  

  .loadingWrapper {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
