import styled from "styled-components";

export const Container = styled.div`
margin-left: -16px;
margin-right: -16px;
margin-top: -16px;
background-color: #F5F5F5;
border-radius: 16px 16px 0 0 ;
padding: 16px;
display: flex;
align-items: center;

.active{
    background-color: #0FBABA;
}

.inactive{
    background-color: #DCDCDC ;
}

.steps{
    height: 24px;
    width: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-size: 14px;
}

.line{
    flex: 1;
    width: 50%;
    height: 2px;
}
`