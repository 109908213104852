import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ButttonDefault from '../../../../components/ButtonDefault';
import AddimageCirculo from '../../../../components/ButtonFile/AddImagemCirculo';
import {
  IconFacebook,
  IconInstagram,
  IconTikTok,
  IconTrash,
  IconTwitter,
  IconWWW,
} from '../../../../components/Icons';
import InputLink from '../../../../components/Inputs/InputLink';
import UserContext from '../../../../contexts/UserContext';
import apiDoubts from '../../../../services/api';
import { Container } from './styles';
import bucket from '../../../../services/bucket';

export default function Destaques() {
  const [DTO, setDTO] = useState<any>({
    visivelOportunidades: '',
    login: '',
    nickName: '',
    foto: '',
    termosUso: '', 
    disponivelWhatsApp: '',
    disponivelEmail: '',
    disponivelConferencia: '',
    valor10: '',
    valor20: '',
    valor30: '',
    descricaoCanal: '',
    listaConsultores: '',
    capa: '',
    link_site: '',
    link_twitter: '',
    link_facebook: '',
    link_instagram: '',
    ink_tiktok: '',
  });
  const [loadButton, setLoadButton] = useState<boolean>(false);

  const { user } = useContext<any>(UserContext);

  useEffect(() => {
    getUser();
  }, [user]);

  function handleOnChange(name: any, value: any) {
    const newDTO: any = DTO;
    newDTO[name] = value;
    setDTO({ ...newDTO });
  }

  async function getUser() {
    let newUser = {
      visivelOportunidades: user?.visivelOportunidades,
      login: user?.login,
      nickName: user?.nickName,
      foto: user?.foto,
      termosUso: user.termosUso,
      disponivelWhatsApp: user?.disponivelWhatsApp,
      disponivelEmail: user?.disponivelEmail,
      disponivelConferencia: user?.disponivelConferencia,
      valor10: user?.valor10,
      valor20: user?.valor20,
      valor30: user?.valor30,
      descricaoCanal: user?.descricaoCanal,
      listaConsultores: user?.listaConsultores,
      link_site: user?.link_site,
      link_twitter: user?.link_twitter,
      link_facebook: user?.link_facebook,
      link_instagram: user?.link_instagram,
      link_tiktok: user.link_tiktok,
      capa: user?.capa,
    };
    setDTO(newUser);
  }

  async function setCapaProfile(file: any) {
    let formData = new FormData();
    formData.append('file', file.target.files[0]);
    const response: any = await bucket.post(`/upload`, formData);
    handleOnChange('capa', response.data.arquivo);
  }

  async function setImageProfile(file: any) {
    let formData = new FormData();
    formData.append('file', file.target.files[0]);
    const response: any = await bucket.post(`/upload`, formData);
    handleOnChange('foto', response.data.arquivo);
  }

  function validateData(DTO: any) {
    setLoadButton(true);
    try {
      if (!DTO?.foto) throw new Error('É preciso definir uma foto de perfil');
      if (!DTO?.capa) throw new Error('É preciso definir uma foto de capa');
      if (!DTO?.nickName) throw new Error('É preciso definir um nome');
      if (!DTO?.descricaoCanal)
        throw new Error('É preciso definir uma descrição');
      sendNewData();
    } catch (err: any) {
      setLoadButton(false);
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  async function sendNewData() {
    try {
      await apiDoubts
        .put(`/agencia/editar/${user?.id}`, DTO)
        .then((response: any) => {
          console.log('sucesso', response);
          toast.success('Usuário atualizado com sucesso', {
            className: 'toast-sucess',
            theme: 'colored',
          });
          setLoadButton(false);
        });
    } catch (err: any) {
      setLoadButton(false);
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  return (
    <Container buttonDeleteOnCapa={!!DTO.capa} buttonDeleteOn={!!DTO.foto}>
      <h3 className="subtitleSection">Avatar e capa do canal</h3>
      <div className="UserImages">
        <div
          className="bannerImage"
          style={{ backgroundImage: `url(${DTO?.capa})` }}
        >
          <div className="button">
            {DTO?.capa ? (
              <button
                className="removeImage"
                onClick={() => {
                  handleOnChange('capa', '');
                }}
              >
                <IconTrash />
              </button>
            ) : (
              <button className="addImage">
                <AddimageCirculo
                  change={(e: any) => {
                    setCapaProfile(e);
                  }}
                  file={'Foto de capa'}
                  tipo={'file'}
                  placeholder={'foto de perfil'}
                />
              </button>
            )}
          </div>
        </div>

        <div
          className="ProfileImage"
          style={{ backgroundImage: `url(${DTO?.foto})` }}
        >
          <div className="buttonImageProfile">
            {DTO?.foto ? (
              <button
                className="removeImage"
                onClick={() => {
                  handleOnChange('foto', '');
                }}
              >
                <IconTrash />
              </button>
            ) : (
              <button className="addImage">
                <AddimageCirculo
                  change={(e: any) => {
                    setImageProfile(e);
                  }}
                  file={'Foto de capa'}
                  tipo={'file'}
                  placeholder={'foto de perfil'}
                />
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="inputDescricaoAgencia">
        <p className="textInputDescricao">Descrição da agência</p>
        <textarea
          value={DTO?.descricaoCanal}
          onChange={(e: any) => {
            handleOnChange('descricaoCanal', e.target.value);
          }}
          className="descricaoUsuario"
        ></textarea>
      </div>
      <div className="areaInputs">
        <div className="inputItem">
          <h3 className="labelLink">Site</h3>
          <InputLink
            icon={<IconWWW size={30} />}
            link={DTO?.link_site}
            value={DTO?.link_site}
            changeText={(text: string) => {
              handleOnChange('link_site', text);
            }}
          />
        </div>
        <div className="inputItem">
          <h3 className="labelLink">Instagram</h3>
          <InputLink
            icon={<IconInstagram size={25} />}
            link={DTO?.link_instagram}
            value={DTO?.link_instagram}
            changeText={(text: string) => {
              handleOnChange('link_instagram', text);
            }}
          />
        </div>
        <div className="inputItem">
          <h3 className="labelLink">Facebook</h3>
          <InputLink
            icon={<IconFacebook size={30} />}
            link={DTO?.link_facebook}
            value={DTO?.link_facebook}
            changeText={(text: string) => {
              handleOnChange('link_facebook', text);
            }}
          />
        </div>
        <div className="inputItem">
          <h3 className="labelLink">Twitter</h3>
          <InputLink
            icon={<IconTwitter size={30} />}
            link={DTO?.link_twitter}
            value={DTO?.link_twitter}
            changeText={(text: string) => {
              handleOnChange('link_twitter', text);
            }}
          />
        </div>
        <div className="inputItem">
          <h3 className="labelLink">Tik Tok</h3>
          <InputLink
            icon={<IconTikTok size={30} />}
            link={DTO?.link_tiktok}
            value={DTO?.link_tiktok}
            changeText={(text: string) => {
              handleOnChange('link_tiktok', text);
            }}
          />
        </div>
      </div>
      <div className="areaButton">
        <ButttonDefault
          title={'Cancelar'}
          tipo={'11'}
          onClick={() => {
            getUser();
          }}
        />
        <ButttonDefault
          title={'Salvar'}
          tipo={'6'}
          icon={loadButton ? 'Loading' : ''}
          onClick={() => {
            validateData(DTO);
          }}
        />
      </div>
    </Container>
  );
}
