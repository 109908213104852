/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import UserContext from '../../contexts/UserContext';
import apiDoubts from '../../services/api';
import ButttonDefault from '../ButtonDefault';
import SelectDefaultSearch from '../Form/SelectDefaultSearch';
import Inputdefault from '../Inputs/InputDefault';
import VideoCardPlaylist from '../VideoCardPlaylist';
import { Container } from './styles';

interface props {
  reload: () => void;
}

export default function AdicionarPlaylist({ reload }: props) {
  const { user } = useContext(UserContext);
  const [data, setData] = useState<any[]>([]);
  const [completeData, setCompleteData] = useState<any[]>([]);
  const [selected, setSelected] = useState<any[]>();
  const [DTO, setDTO] = useState({
    titulo: '',
    descricao: '',
    listaPostagens: [],
  });

  useEffect(() => {
    if (user.id) {
      getData();
    }
  }, [user]);

  function handleOnChange(name: any, value: any) {
    const newDTO: any = DTO;
    newDTO[name] = value;
    setDTO({ ...newDTO });
  }

  async function getData() {
    apiDoubts
      .get(
        `/postagens/${
          user.tipo === 'empresa'
            ? 'getPostagemEmpresa'
            : 'getPostagemConsultor'
        }/${user.id}`
      )
      .then((response: any) => {
        let data = response.data.data;
        setCompleteData(data);
        options(data);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  function options(data: any) {
    let newData: any[] = [];
    data.map((row: any) => {
      let option = {
        value: row.id,
        label: row.titulo,
      };
      newData.push(option);
    });
    setData(newData);
  }

  function videosSelect(e: any) {
    completeData.map((row: any) => {
      if (row.id === e.value) {
        if (DTO.listaPostagens.length === 0) {
          let array: any[] = [row.id];
          let completeArray = [row];
          handleOnChange('listaPostagens', array);
          setSelected(completeArray);
        } else if (DTO.listaPostagens.length > 0) {
          let array: any[] = DTO?.listaPostagens;
          let completeArray: any = selected;
          console.log(array.indexOf(e.value));
          if (array.indexOf(e.value) === -1) {
            array.push(e.value);
            completeArray.push(row);
          }
          handleOnChange('listaPostagens', array);
          setSelected(completeArray);
        }
      }
    });
  }

  function removeSelect(id: string) {
    let selecionados = DTO.listaPostagens;
    let selectedComplete = selected;
    selecionados = selecionados.filter((val: any) => {
      return val !== id;
    });
    selectedComplete = selectedComplete?.filter((val: any) => {
      return val.id !== id;
    });
    handleOnChange('listaPostagens', selecionados);
    setSelected(selectedComplete);
  }

  function checkInfos() {
    try {
      if (!DTO?.titulo)
        throw new Error('Por favor, digite um titulo para a playlist');
      if (!DTO?.descricao)
        throw new Error('Por favor, digite uma descrição para a playlist');
      if (DTO.listaPostagens.length === 0)
        throw new Error('Por favor, adicione ao menos um video à playlist');
      cadastraPlaylist();
    } catch (err: any) {
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  async function cadastraPlaylist() {
    try {
      await apiDoubts.post(`playlists`, DTO).then(async (response: any) => {
        await getData();
        reload();
        setDTO({
          titulo: '',
          descricao: '',
          listaPostagens: [],
        });
        setSelected([]);
        toast.success('Playlist Cadastrada com sucesso', {
          className: 'toast-sucess',
          theme: 'colored',
        });
      });
    } catch (err: any) {
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }
  console.log('data', data);
  console.log('selected', selected);

  return (
    <Container>
      <h3 className="page-title">Adicionar playlist</h3>
      <div className="title">
        <h3 className="subtitle">Título</h3>
        <Inputdefault
          placeholder="Ex: cozinhando em casa"
          change={(e: any) => {
            handleOnChange('titulo', e.target.value);
          }}
          tipo={'text'}
          key={'input de nome de playlist'}
          value={DTO.titulo}
        />
      </div>
      <div className="description">
        <h3 className="subtitle">Descrição</h3>
        <textarea
          placeholder="Ex: essa é uma playlist focada em receitas simples"
          value={DTO.descricao}
          className="comment"
          onChange={(e) => {
            handleOnChange('descricao', e.target.value);
          }}
        ></textarea>
      </div>
      <div
        className="videos"
        style={{
          position: 'relative',
          zIndex: 2,
        }}
      >
        <h3 className="subtitle">Videos</h3>
        <SelectDefaultSearch
          onChange={(e: any) => {
            videosSelect(e);
          }}
          options={data}
          key={'select para selecionar os videos da playlist'}
          tipo={'1'}
          value={{ label: 'Selecione um video...', value: 'tete' }}
        />
      </div>
      <div className="selecionados">
        {selected?.map((row: any, key: number) => (
          <VideoCardPlaylist
            key={key}
            data={row}
            remove={(id: string) => {
              removeSelect(id);
            }}
          />
        ))}
      </div>
      <div
        style={{
          position: 'relative',
          zIndex: 1,
        }}
      >
        <ButttonDefault
          title={'Criar nova playlist'}
          full={true}
          hover={'medio'}
          onClick={checkInfos}
        />
      </div>
    </Container>
  );
}
