import styled, { css } from 'styled-components';
import LogoIco from '../../assets/icons/logoIcon';
import SearchIcon from '../../assets/icons/searchIcon';
import cameraIcon from '../../assets/icons/cameraIcon';
import settingsIcon from '../../assets/icons/settingsIcon';
import notificacaoIcon from '../../assets/icons/notificacaoIcon';
import userIcon from '../../assets/icons/userIcon';

interface Props {
  hasCompact: boolean;
}

interface ModalProps {
  isHidden: boolean;
}

export const Container = styled.div`
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--white);
  padding: 0 20px;
  border-bottom: 1px solid #ebe8ff;
  width: 100%;
  position: fixed;
  z-index: 10;
  top: 0;
  height: 80px;
  .buttonToggleMenu {
    display: none;
    @media (max-width: 800px) {
      display: flex;
    }
  }
  form {
    width: 100%;
  }
  @media (max-width: 425px) {
    padding: 0px;
  }
  .areaSearch {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
  }
`;

export const ModalSearch = styled.div<ModalProps>`
  -webkit-box-shadow: 0px 5px 13px -10px #000000;
  box-shadow: 0px 5px 13px -10px #000000;
  display: none;
  .close {
    position: absolute;
    left: 0;
    top: 0;
    margin-left: 10px;
    margin-top: 70px;
  }
  @media (max-width: 500px) {
    padding-top: 50px;
    display: flex;
    margin-top: ${({ isHidden }) => (isHidden ? '-300px' : '0px')};
    position: absolute;
    width: 100%;
    height: 200px;
    background-color: var(--branco);
    z-index: 3;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    transition: all 0.3s;
  }
  .overlay {
    display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: transparent;
    top: 0;
    margin-top: 200px;
  }
`;

export const Logo = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
  padding: 16px 0;
  display: flex;
  align-items: center;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    margin-right: 22px;
  }
  a {
    margin-right: 24px;
  }

  .hamburger {
    color: var(--purple2);
    border-top: 2px solid;
    width: 20px;
    margin-bottom: 8px;

    &::after,
    &::before {
      content: '';
      display: block;
      width: 20px;
      height: 2px;
      background: currentColor;
      margin-top: 5px;
      transition: 0.25s ease-in-out;
      position: relative;
    }
  }

  ${(props) =>
    !props.hasCompact &&
    css`
      .hamburger {
        border-top-color: transparent;
        &::before {
          transform: rotate(135deg);
        }
        &::after {
          transform: rotate(-135deg);
          top: -7px;
        }
      }
    `}

  @media (max-width: 425px) {
    padding: 16px 10px;
    a {
      margin-right: 0;
    }
  }
`;

export const LogoIcon = styled(LogoIco)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InputSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-right: 40px;

  div {
    width: 100%;
    max-width: 445px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 36px;
    padding: 12px 0;
    border: 1px solid var(--green);
    background: #d6eefe;
    border-radius: 4px;
    input {
      width: 100%;
      background: none;
      color: #7d7d7d;
      font-size: 12px;
      padding: 12px 0 12px 12px;
      border: none;
      outline: none;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;

      border: none;
      border-radius: 0 4px 4px 0;

      width: 61px;
      background: var(--green);
      height: 36px;
    }
  }
  @media (max-width: 425px) {
    width: 85%;
    padding: 10px;
    margin-right: 10px;
  }
`;

export const MenuRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: none;

  div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    /* .camera,
    .settings,
    .notification {
      visibility: hidden;
    } */

    button {
      border: none;
      background: none;

      .areaUser {
        background-color: var(--paleta2D);
        padding: 10px 16px 10px 16px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        gap: 5.5px;

        h3 {
          color: var(--branco);
          font-weight: 700;
          font-size: 13px;
          display: flex;
          white-space: nowrap;
        }
      }

      .icon {
        display: flex;
        align-items: center;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        overflow: hidden;
        background-size: cover;
        background-position: center;
        margin-right: 8px;
      }
    }

    button + button {
      margin-left: 10px;
    }
  }

  @media (max-width: 750px) {
    div {
      button {
        .areaUser {
          display: none;
        }
      }
    }
  }
`;

export const CameraIcon = styled(cameraIcon)``;

export const SettingsIcon = styled(settingsIcon)``;
export const NotificationIcon = styled(notificacaoIcon)``;
export const Usericon = styled(userIcon)``;
