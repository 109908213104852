import styled from "styled-components";

export const Container = styled.button`
background-color: var(--background2);
height:70px ;
border: none;
outline: none;
border-radius: 10px;
-webkit-box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.3);
box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.3);
display: flex;
gap: 30px;
padding: 20px 30px;
align-items: center;
transition: scale 0.2s;

&:hover{
    transform: scale(1.005);
    transition: scale 0.2s;
}
.areaIconCardTypesAdsense{

}
.areaTextCardTypesAdsense{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .TitleCardTypesAdsense{
        font-size: 18px;
        line-height: 27px;
        font-weight: 600;
        color: var(--paleta1A);

    }
    .DescriptionCardTypesAdsense{
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: var(--texto);
    }
}
`