import styled from "styled-components";

export const Container = styled.div`
margin-top: 20px;
display: flex;
flex-direction: column;
gap: 10px;
.inputArea{
    display: flex;
    flex-direction: column;
    gap: 5px;
    .labelInput{
        font-size: 13px;
        font-weight: 700;
        color: var(--paleta2C);
        .videoTime{
            font-weight: 400;
        }
    }
}
.containerMain{
    .labelInput{
        font-size: 13px;
        font-weight: 700;
        color: var(--paleta2C);
    }
}
.exampleBannerArea{
    display: flex;
    flex-direction: column;
    gap: 5px;
    .textExampleBannerArea{
        font-size: 13px;
        line-height: 15px;
        font-weight: 700;
        color: var(--paleta2C);
    }
    .mainContainerExampleBannerArea{
        width: 100%;
        height: 500px;
        border-radius: 10px;
        background-color: var(--background2);
        padding: 20px;
        .imageBanenrExampleBannerArea{
            width: 100%;
            height: 100%;
            background-position: center;
            background-size:contain;
            background-repeat: no-repeat;
            border-radius: 10px;
        }
    }
}
.exampleBannerArea{
    .textExampleBannerArea{

    }
    .mainContainerExampleBannerArea{
        .topMainContainerExample{

        }  
        .contentMainContainerExample{
            display: flex;
            gap: 20px;
            
            .leftContentContainerExample{
                width: calc(100% - 194px);
                display: flex;
                flex-direction: column;
                gap: 10px;

            }
            .rightContentContainerExample{
                width: 194px;
                background-color: red;
                .banner322x227px{

                }
                .banner322x673px{

                }
            }
        }      
    }    
}
.areaButtons{
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}                   
`