import styled from 'styled-components';

interface props {
  imageHeigth: number;
  buttonDeleteOn: boolean;
}

export const Container = styled.div<props>`
  display: flex;
  flex-direction: column;
  gap: 30px;
  .selectAction {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .main {
    width: 100%;
    display: flex;
    gap: 30px;
    .areaLeft {
      width: 100%;
      height: 100%;
      .title {
        margin-top: 15px;
        font-size: 28px;
        line-height: 30px;
        font-weight: 700;
        color: var(--paleta2C);
      }
      .subtitle {
        margin-top: 20px;
        font-size: 13px;
        line-height: 15px;
        font-weight: 700;
        color: var(--texto);
        margin-bottom: 5px;
      }
      .comment {
        width: 100%;
        height: 120px;
        background-color: var(--background2);
        margin-top: 3px;
        border: none;
        border-radius: 4px;
        padding: 10px;
        outline: none;
        resize: none;
      }
      .areaButton {
        margin-top: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: flex-end;
        padding-bottom: 50px;
        .areaInputs {
          display: flex;
          flex-direction: column;
          margin-top: 20px;
          margin-bottom: 20px;
          gap: 10px;
          width: 90%;
          margin-left: 5%;
          .label {
            font-size: 13px;
            line-height: 15px;
            font-weight: 700;
            color: var(--texto);
            margin-bottom: 5px;
          }
          .cardInputSenha{
            .alertSenha{
              color: var(--offline);
              font-size: 13px;
              font-weight: 600;
            }
          }
        }
      }
      .DatePickerInput{
        width: 100%;
        border: none;
        padding-right: 10px;
        margin-right: 10px;
        background-color: rgba(0,0,0,0.2);
        border-radius: 5px;
        outline: none;
        ::-webkit-datetime-edit { 
          height: 40px;
          background-color: var(--background2);
          border-radius: 5px;
          width: 100%;
          display: flex;
          align-items: center;
          padding-left: 10px;
        }
        ::-webkit-datetime-edit-fields-wrapper { background: transparent; }
        ::-webkit-datetime-edit-text {  padding: 0 0.3em; }
        ::-webkit-datetime-edit-month-field {  }
        ::-webkit-datetime-edit-day-field {  }
        ::-webkit-datetime-edit-year-field {  }
        ::-webkit-inner-spin-button { display: none; }
        ::-webkit-calendar-picker-indicator {
          margin-left: 10px;
          color: var(--branco);
          svg{
            path{
            }
          }
        }
      }
    }

    @media (max-width: 1150px) {
      .main {
        display: flex;
        flex-direction: column;
        .areaLeft {
          .areaButton {
            padding: 0;
          }
        }
      }

      @media (max-width: 800px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        .areaLeft {
          width: 100%;
          gap: 0px;
          .areaButton {
            padding: 0px;
          }
        }
        .areaRight {
          width: 100%;
          margin-top: 0;
          .cardpost {
            min-width: 100%;
          }
        }
      }
    }
  }
`;
