import moment from 'moment';
import { useState } from 'react';
import ButttonDefault from '../ButtonDefault';
import { IconArrowDown, IconArrowUp, IconChevronLeft } from '../Icons';
import { ContainerBox } from './styles';

interface IProps {
  profile: {
    image: string;
    name: string;
  };
  item: {
    date: string;
    title: string;
    description: string;
    type: 'Grátis' | 'Paga';
  };
  textConfirm?: string;
  textCancel?: string;
  onConfirm?(): void;
  onCancel?(): void;
  styles?: any;
  toggleOpened?: boolean;
}

export const ContainerConsultoriaRequest = ({
  profile,
  item,
  onConfirm,
  onCancel,
  textConfirm = 'Confirmar',
  textCancel = 'Rejeitar',
  styles = {},
  toggleOpened,
}: IProps) => {
  const [opened, setOpened] = useState(toggleOpened);

  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);

  return (
    <ContainerBox {...styles}>
      <div className="head">
        <div className="user-info">
          <img src={profile.image} />
          <span className="name">{profile.name}</span>
        </div>
        <div className="right">
          <span
            style={{
              backgroundColor:
                item.type === 'Grátis' ? 'var(--warning)' : 'var(--purple7)',
            }}
          >
            {item.type}
          </span>
        </div>
      </div>
      <div className="content">
        <div className="date-info">
          <span>
            <b>Horário:</b> {moment(item.date).format('HH:mm')}
          </span>
          <span>
            <b>Data:</b> {moment(item.date).format('DD/MM/YYYY')}
          </span>
        </div>
        <div className={`description ${opened ? 'opened' : 'closed'}`}>
          <div className="head-desc" onClick={() => setOpened(!opened)}>
            <span className="title">Descrição</span>
            <IconChevronLeft size={12} />
          </div>

          <div className="fields">
            <div className="field">
              <span className="label">Assunto</span>
              <div className="input-fake">{item.title}</div>
            </div>
            <div className="field">
              <span className="label">Resumo</span>
              <div className="input-fake">{item.description}</div>
            </div>
          </div>
        </div>
      </div>

      {(onCancel || onConfirm) && (
        <div className="footer">
          {onCancel && (
            <ButttonDefault
              tipo={'15'}
              icon={loadingCancel ? 'Loading' : ''}
              title={textCancel}
              onClick={() => {
                setLoadingCancel(true);
                onCancel();
              }}
            />
          )}
          {onConfirm && (
            <ButttonDefault
              tipo={'16'}
              icon={loadingConfirm ? 'Loading' : ''}
              title={textConfirm}
              onClick={() => {
                setLoadingConfirm(true);
                onConfirm();
              }}
            />
          )}
        </div>
      )}
    </ContainerBox>
  );
};
