import { useContext, useState } from 'react';
import CardEmpresaConfig from '../../../../components/Cards/CardEmpresaConfig';
import UserContext from '../../../../contexts/UserContext';
import { Container } from './styles';
import EmptyPosts from '../../../../components/EmptyPosts';
import apiDoubts from '../../../../services/api';
import CardAgenciaConfig from '../../../../components/Cards/CardAgenciaConfig';

export default function Agencias() {
  const { user }: any = useContext(UserContext);
  const [empresasSelecionadas, setEmpresasSelecionadas] = useState<any[]>([]);

  function handleAgenciaSelecionadas(id: string) {
    if (empresasSelecionadas.includes(id)) {
      let arrayConsultores = empresasSelecionadas;
      let newArray = arrayConsultores.filter((val: any) => {
        return val != id;
      });
      setEmpresasSelecionadas([...newArray]);
      console.log('já tinha', newArray);
    } else {
      let newArray = empresasSelecionadas;
      newArray.push(id);
      setEmpresasSelecionadas([...newArray]);
      console.log('ainda não tinha', newArray);
    }
  }

  function removeVinculo(account: any) {
    let accountType;

    if (account?.listaAgencias && account?.listaEmpresas) {
      accountType = 'consultor';
    } else if (account?.listaConsultores && account?.listaEmpresas) {
      accountType = 'agencia';
    } else if (account?.listaAgencias && account?.listaConsultores) {
      accountType = 'empresa';
    }

    apiDoubts?.put(`/finalizarVinculo`, {
      idParaRemover: account?._id,
      tipo: accountType,
    });
  }

  function handleMessage() {
    const isAnConsultor = user?.listaEmpresas;

    let message;

    if (isAnConsultor) {
      message = 'Associe-se com agências para que elas possam aparecer aqui';
      return message;
    }

    message = 'Envie convite para que elas possam aparecer aqui';
    return message;
  }

  const isEmptyAgency = user?.agencias?.length === 0 || !user?.agencias;

  return (
    <Container>
      {isEmptyAgency && (
        <EmptyPosts
          title="Ops, você não esta associado com nenhuma agência"
          description={handleMessage()}
        />
      )}

      {user?.agencias?.map((row: any) => (
        <CardAgenciaConfig
          checked={empresasSelecionadas?.includes(row?.id)}
          agenciaData={row}
          setChecked={(idEmpresa: string) => {
            handleAgenciaSelecionadas(idEmpresa);
          }}
          deleteAgencia={removeVinculo}
          key={row.id}
        />
      ))}
    </Container>
  );
}
