import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Banner from '../../components/Banner';
import Comentarios from '../../components/Comentarios';
import HeaderConsultor from '../../components/HeaderConsultor';
import HeaderType from '../../components/HeaderType';
import ListPlaylist from '../../components/Playlist/ListPlaylist';
import apiDoubts from '../../services/api';
import { Container } from './styles';

export default function Playlist() {
  const params = useParams<any>();
  const [banner, setBanner] = useState<any>('');

  useEffect(() => {
    getBanner();
  }, []);

  async function getBanner() {
    const response = await apiDoubts.get('/banners?tipo=2&randon=true');
    setBanner(response.data.data[0]);
    console.log(response.data.data[0]);
  }

  return (
    <Container>
      <HeaderConsultor consultorId={params.id} page="Playlist" />

      <div className="content-user-page">
        <div className="render">
          <ListPlaylist consultorId={params.id} recarregar={false} />
        </div>
        <div className="sideRigth">
          <div className="areaBanner">
            <Banner peso="2" position="1" typeBanner="322x227" />
            <Banner peso="2" position="1" typeBanner="322x673" />
          </div>
        </div>
      </div>
    </Container>
  );
}
