import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .notfound {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    background-color: var(--background);
    padding: 100px 0px;
    img {
      width: 100px;
    }
    h3 {
      color: var(--purple2);
    }
  }
`;

export const ContainerWithOutLogin = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  .takeLogin {
    color: var(--paleta2A);
    font-weight: 700;
    font-size: 24px;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(8rem, 1fr));
  gap: 25px;

  margin: 0 auto;
  background: var(--background);
  padding: 40px;
  padding-bottom: 150px;
  width: 100%;
  @media (max-width: 425px) {
    padding: 0 0 100px 0;
  }
`;

export const CardView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 375px;

  img {
    max-width: 100%;
    height: auto;
  }

  .image {
    position: relative;
    width: 100%;
    height: 150px;

    max-height: 200px;
    background-size: cover;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.2);
    .player {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      background: transparent;
      cursor: pointer;
      transition: all 0.5s;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        transition: all 0.2s;
      }
      svg {
        width: 15%;
        height: 25%;
      }
      &:hover {
        background: rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        svg {
          position: relative;
          transform: scale(1.2);
          path {
            fill: var(--white);
          }
        }
      }
    }
  }

  .cardInfo {
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
    width: 100%;
    position: relative;
    > img {
      width: 40px;
      height: 40px;
      border-radius: 4px;
    }

    footer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .header {
        display: flex;
        justify-content: space-evenly;
        gap: 20px;
        width: 100%;
        .photo {
          width: 40px;
          height: 40px;
          background-size: cover;
          background-position: center;
          border-radius: 4px;
        }
        .title {
          width: calc(100% - 60px);
          h3 {
            font-size: 16px;
            font-weight: 700;
            line-height: 18px;
            color: var(--purple4);
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 250px;
          }
          p {
            margin-bottom: 0px;
          }
        }
      }
      a {
        text-decoration: none;
      }

      p {
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        color: var(--purple6);

        margin: 8px 0;
      }

      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        color: var(--dark-grey);
      }
    }

    .settings {
      position: absolute;
      top: -210px;
      right: 15px;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        transform: scale(1.4);
        svg {
          path {
            fill: var(--green);
          }
        }
      }
      svg {
        path {
          fill: var(--background);
        }
      }
    }
  }

  @media (max-width: 425px) {
    max-width: 100%;
    width: 100%;
    .image {
      border-radius: 0;
    }
    .player {
      &:hover {
        border-radius: 0;
      }
    }
    .cardInfo {
      margin-left: 30px;
      width: 90%;
    }
  }
`;

export const GuideArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 420px;
  padding: 15px;
  padding-top: 40px;
  gap: 20px;
  z-index: 3;
`;

export const ContainerContent = styled.div`
  display: flex;
  margin-top: 30px;
  gap: 27px;
  .section-left {
    width: calc(100% - 205px);
    height: 100%;
    .areaButton {
      margin-top: 10px;
    }
  }
  .section-right {
    width: 205px;
    height: 100%;
  }

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;

    .section-left {
      width: 100%;
    }
    .section-right {
      width: 100%;
    }
  }
`;

export const ContainerFilter = styled.div`
  display: flex;
  gap: 20px;
  .order {
    min-width: 178px;
    .hashMobile {
      display: none;
    }
  }
  .where {
    max-width: 100%;
    overflow: hidden;
  }
  @media (max-width: 800px) {
    .order {
      max-width: 100%;
      display: flex;
      align-items: center;
      gap: 20px;
      .areaSelect {
        max-width: 120px;
        min-width: 120px;
      }

      .hashMobile {
        width: 100%;
        display: flex;
        overflow-x: scroll;

        ::-webkit-scrollbar {
          display: none;
          position: relative;
        }
      }
    }
  }
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
  }
`;
