import styled, { css } from 'styled-components';

interface HProps {}

export const Container = styled.div`
  margin-top: 10px;
`;
export const Title = styled.h2`
  color: var(--paleta2C);
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
`;
export const AreaHash = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;
export const Hashtag = styled.button<HProps>`
  border: none;
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
  line-height: 14px;
  background-color: var(--paleta1C);
  padding: 0 10px;
  height: 26px;
  border-radius: 50px;
  color: var(--branco);
  display: flex;
  align-items: center;
  transition: all 0.2s;
  min-width: 100px;
  align-items: center;
  justify-content: center;

  &:hover {
    transition: all 0.2s;
    transform: scale(1.1);
    background-color: var(--offline);
  }
`;
