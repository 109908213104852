import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import UserContext from '../../../contexts/UserContext';
import apiDoubts from '../../../services/api';
import ButttonDefault from '../../ButtonDefault';
import AddMessage from '../AddMessage';
import { CardConversa, Container, ElementLine } from './styles';

interface props {
  data: any;
  newMessage: (message: string) => void;
}

export default function ChatMailBox({ data, newMessage }: props) {
  const [completeConversa, setCompleteConversa] = useState<any>();
  const [novaMensagen, setNovaMensagem] = useState<string>('');
  const [loadingSendingMessage, setLoadingSendingMessage] =
    useState<boolean>(false);

  const [reverseData, setReverseData] = useState<Array<any>>();

  const parentRef: any = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (reverseData) {
      parentRef.current.scrollTop = parentRef.current.clientHeight * 100000;
    }
  }, [reverseData]);

  useEffect(() => {
    if (!reverseData && data) {
      setReverseData(data?.mensagens?.reverse());
    }
  }, [data]);

  const { user } = useContext<any>(UserContext);

  async function handleSubmitNewEmailMessage(newEmailMessage: string) {
    setLoadingSendingMessage(true);
    try {
      await apiDoubts.post(`/conversa/enviar-mensagem`, {
        mensagem: newEmailMessage,
        idConversa: data?.conversa?._id,
      });
      setNovaMensagem('');

      // inserir valor da nova mensagem
      let cloneReverseData = [...(reverseData as any)];
      cloneReverseData.push({
        mensagem: newEmailMessage,
        remetente: {
          id: user?._id,
          tipo: user?.tipo,
        },
        createdAt: new Date(),
      });

      setReverseData(cloneReverseData);
      setLoadingSendingMessage(false);
    } catch (error) {
      setLoadingSendingMessage(false);
    }
  }

  async function handleStatusConvite(conviteId: string, status: boolean) {
    try {
      await apiDoubts
        .put(`/mensagem-mailbox-responder-convite/${conviteId}`, {
          statusConvite: `${status ? 'Aceito' : 'Recusado'}`,
        })
        .then((response: any) => {
          console.log(response);
          toast.success(
            `Convite ${status ? 'Aceito' : 'Recusado'} com Sucesso !!!`,
            {
              className: 'toast-sucess',
              theme: 'colored',
            }
          );
        });
    } catch (error) {}
  }

  function handleNickNameDestinatario(mensagem: any) {
    let userPhoto = data.usuariosConversando.filter((user: any) => {
      if (user?.conta?._id === mensagem?.remetente?.id) {
        return user;
      }
    });

    return userPhoto[0]?.conta?.nickName;
  }

  function handleImageDestinatario(mensagem: any): string {
    let userPhoto = data.usuariosConversando.filter((user: any) => {
      if (user?.conta?._id === mensagem?.remetente?.id) {
        return user;
      }
    });

    return userPhoto[0]?.conta?.foto;
  }

  return (
    <Container>
      {/* <p className="mensagemPrincipal">{data?.mensagens[0]?.mensagem}</p> */}

      {data?.tipoConversa === 'conviteFacaParte' &&
        data?.mensagens[0]?.statusConvite == 'Pendente' &&
        data?.mensagens[0]?.remetenteId !== user?.id && (
          <div className="areaButtonsAceitarOuRecusar">
            <ButttonDefault
              title={'Aceitar'}
              tipo={'10'}
              onClick={() => {
                handleStatusConvite(completeConversa?.mensagens[0].id, true);
              }}
            />
            <ButttonDefault title={'Recusar'} tipo={'8'} />
          </div>
        )}

      <div className="areaCardsConversa" ref={parentRef}>
        {reverseData?.map((row: any, index: number) => {
          handleImageDestinatario(row);
          return (
            <>
              <CardConversa key={index} user={row?.remetente?.id === user?._id}>
                <div className="textCardMailBox">
                  <div className="topTextCardMailBox">
                    <p>{moment(row?.createdAt).format('DD/MM/YYYY - HH:mm')}</p>
                    <span className="nickNameUser">
                      {handleNickNameDestinatario(row)}
                    </span>
                  </div>
                  <div className="bottomTextCardMailBox">{row?.mensagem}</div>
                </div>
                <div
                  className="photo"
                  style={{
                    backgroundImage: `url(${handleImageDestinatario(row)})`,
                  }}
                ></div>
              </CardConversa>
              {row.link ? (
                <ButttonDefault
                  title="Acessar"
                  onClick={() =>
                    window.open('/consultoria/' + row.link, '_blank')
                  }
                />
              ) : null}
            </>
          );
        })}
      </div>

      <div className="messageArea">
        <AddMessage
          attMessage={(message: string) => {
            setNovaMensagem(message);
          }}
          valueMessage={novaMensagen}
        />
        <div className="buttonSubmitNewEmailMessage">
          <ButttonDefault
            title={'Enviar'}
            icon={loadingSendingMessage ? 'Loading-White' : ''}
            tipo={'6'}
            onClick={() => {
              handleSubmitNewEmailMessage(novaMensagen);
            }}
          />
        </div>
      </div>
    </Container>
  );
}
