import * as S from './styles';
import Calendar from 'react-calendar';
import dayjs from 'dayjs';
import SelectHour from '../SelectHour';
import { useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import ButttonDefault from '../ButtonDefault';

interface ICalendarRangeProps {
  sendDates: (e: Array<string | null> | string) => Promise<boolean>;
  freeConsultancies: boolean;
}

export default function CalendarRange({
  sendDates,
  freeConsultancies,
}: ICalendarRangeProps) {
  const [datesSelected, setDatesSelected] = useState<
    Date | [Date | null, Date | null] | null | undefined
  >(null);

  const [hoursSelected, setHoursSelected] = useState<
    Array<string | null> | string
  >([]);

  const [sendSchedules, setSendSchedules] = useState<boolean>(false);

  function roundMinutesAndReturnPercentage(minutes: number): number {
    const roundedMinutes = Math.ceil(minutes / (freeConsultancies ? 10 : 30)) * (freeConsultancies ? 10 : 30);
    return roundedMinutes;
  }

  function handleHorariosDisponiveis(): Array<any> {
    // Calcula o número total de minutos correspondente a 07:00
    let startMinutes = 7 * 60;

    if (
      datesSelected &&
      moment(datesSelected as Date).day() === moment(new Date()).day()
    ) {
      startMinutes = (moment(new Date()).hour() * 60 ) + roundMinutesAndReturnPercentage(moment(new Date()).minute())
    }

    const hours: Array<any> = [];

    // Calcula o número total de minutos correspondente a 23:30
    const endMinutes = 23 * 60 + 30;

    for (let minutos = startMinutes; minutos <= endMinutes; minutos += freeConsultancies ? 10 : 30) {
      const horas = Math.floor(minutos / 60)
        .toString()
        .padStart(2, '0');
      const minutosRestantes = (minutos % 60).toString().padStart(2, '0');
      hours.push({ hora: `${horas}:${minutosRestantes}` });
    }
    return hours;
  }

  function validateDay(day: Date) {
    if (
      moment(day).isBefore(
        moment(new Date()),
        `${moment(day).hour() === 0 ? 'days' : 'hours'}`
      )
    ) {
      throw new Error('A data escolhida já passou');
    }
  }

  function changeDate(e: Date) {
    try {
      validateDay(e);
      if (datesSelected === null) {
        // ainda não selecionou nenhuma data
        setDatesSelected(moment(e).toDate());
      } else if (Array.isArray(datesSelected) && datesSelected.length > 1) {
        // esta com o range selecionado e agora vai mudar para data única
        setDatesSelected(moment(e).toDate());
      } else if (
        moment(e).format() !== moment(datesSelected as Date).format()
      ) {
        // esta com data única e vai mudar para o array
        console.log('date');
        setDatesSelected((oldValue) =>
          moment(oldValue as Date).isBefore(moment(e).toDate())
            ? [oldValue as Date, moment(e).add(1, 'day').toDate()]
            : [
                moment(e).toDate(),
                moment(oldValue as Date)
                  .add(1, 'day')
                  .toDate() as Date,
              ]
        );
      }
    } catch (error: any) {
      toast.error(error.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  function handleHours(e: string) {
    try {
      if (!datesSelected)
        throw new Error('Antes de escolher um horário escolha um dia');
      if (hoursSelected.length === 0) {
        // Ainda não foi adicionada nenhuma data
        setHoursSelected([e]);
      } else if (Array.isArray(hoursSelected) && !hoursSelected.includes(e)) {
        // O valor clicado ainda não está no Array
        setHoursSelected((oldArray) => oldArray.concat(e));
      } else if (Array.isArray(hoursSelected) && hoursSelected.includes(e)) {
        // O valor clicado já esta no Array
        console.log('ultima validação');
        let ArrayClone: Array<string | null> = hoursSelected;
        ArrayClone = ArrayClone.filter((row: string | null) => {
          if (row !== e) return row;
        });
        console.log(ArrayClone, e);
        setHoursSelected(ArrayClone);
      }
    } catch (error: any) {
      toast.error(error.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  function getIntermediateDates(startDate: string, endDate: string): string[] {
    const start = moment(startDate, 'YYYY-MM-DD');
    const end = moment(endDate, 'YYYY-MM-DD');
    const intermediateDates: string[] = [];

    let current = moment(start);
    while (current.isBefore(end)) {
      intermediateDates.push(moment(current).format());
      current.add(1, 'day');
    }

    return intermediateDates;
  }

  async function sendHoursArray() {
    try {
      setSendSchedules(true);
      if (hoursSelected.length === 0)
        throw new Error('É necessário adicionar ao menos um horário');

      if (Array.isArray(datesSelected)) {
        let allDates: Array<string> = getIntermediateDates(
          moment(datesSelected[0]).format(),
          moment(datesSelected[1]).format()
        );

        let sendHoursArray: any = [];

        allDates.forEach((date: string) => {
          if (Array.isArray(hoursSelected)) {
            // existe mais de um horário adicionada
            hoursSelected.forEach((hour: string | null) => {
              let hourSplit: Array<string> | any = hour?.split(':');
              let newDate = moment(date).set({
                hour: parseInt(hourSplit[0]),
                minute: parseInt(hourSplit[1]),
                seconds: 0,
              });
              sendHoursArray.push(
                moment(newDate).format('YYYY-MM-DD HH:mm:ss')
              );
            });
          } else {
            let hourSplit: Array<string> | any = hoursSelected?.split(':');
            let newDate = moment(date).set({
              hour: parseInt(hourSplit[0]),
              minute: parseInt(hourSplit[1]),
              seconds: 0,
            });
            sendHoursArray.push(moment(newDate).format('YYYY-MM-DD HH:mm:ss'));
          }
        });

        await sendDates(sendHoursArray).then(() => {
          console.log('parar de carregar');
          setSendSchedules(false);
        });
      } else {
        let sendHoursArray: any = [];

        if (Array.isArray(hoursSelected)) {
          // existe mais de um horário adicionada
          hoursSelected.forEach((hour: string | null) => {
            let hourSplit: Array<string> | any = hour?.split(':');
            let newDate = moment(datesSelected).set({
              hour: parseInt(hourSplit[0]),
              minute: parseInt(hourSplit[1]),
              seconds: 0,
            });
            sendHoursArray.push(moment(newDate).format('YYYY-MM-DD HH:mm:ss'));
          });
        } else {
          // horario único
          let hourSplit: Array<string> | any = hoursSelected?.split(':');
          let newDate = moment(datesSelected).set({
            hour: parseInt(hourSplit[0]),
            minute: parseInt(hourSplit[1]),
            seconds: 0,
          });
          sendHoursArray.push(moment(newDate).format('YYYY-MM-DD HH:mm:ss'));
        }

        await sendDates(sendHoursArray).then(() => {
          setSendSchedules(false);
        });
      }
    } catch (error: any) {
      setSendSchedules(false);
      toast.error(error.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  return (
    <S.ContainerCalendar>
      <div className="calendar">
        <div className="content">
          <Calendar
            minDate={new Date()}
            onClickDay={(e: any) => {
              changeDate(e);
            }}
            formatDay={(locale, date) => dayjs(date).format('DD')}
            value={datesSelected}
          />
          <div className="selectHour">
            <SelectHour
              horarios={handleHorariosDisponiveis()}
              click={(hour) => {
                handleHours(hour);
              }}
              selected={hoursSelected}
            />
          </div>
        </div>
      </div>
      <ButttonDefault
        title={'Adicionar horários'}
        tipo={'2'}
        full={true}
        onClick={sendHoursArray}
        hover="pouco"
        icon={sendSchedules ? 'Loading' : ''}
      />
    </S.ContainerCalendar>
  );
}
