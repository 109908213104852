import { AxiosResponse } from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ButttonDefault from '../../components/ButtonDefault';
import apiDoubts from '../../services/api';
import { CardHistoric, Container } from './styles';

export default function Historico() {
  const [historic, setHistoric] = useState<any[]>();

  useEffect(() => {
    getHistoric();
  }, []);

  async function getHistoric() {
    try {
      await apiDoubts.get(`wallet-historic`).then((response: AxiosResponse) => {
        setHistoric(response?.data?.data);
      });
    } catch (error) {}
  }

  return (
    <Container>
      {historic?.map((row: any, index: number) => {
        let value = row.saldoAtual - row?.saldoAnterior;
        console.log(row);
        return (
          <CardHistoric key={index}>
            <div className="leftItem">
              <p className="textCard">
                {value < 0 ? '- ' : '+ '}
                {row?.saldoAtual} moedas
              </p>
              <p className="dateHistoric">{` ${moment(row.data).format(
                'DD [de] MMMM YYYY hh:mm'
              )} `}</p>
            </div>
            <ButttonDefault
              title={row?.transition == 'insert' ? 'Inserção' : 'Transferência'}
            />
          </CardHistoric>
        );
      })}
    </Container>
  );
}
