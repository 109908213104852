import React, { createContext, useContext, useEffect, useState } from 'react';
import { IconLoading } from '../components/Icons';
import apiDoubts from '../services/api';

interface IUser {
  listaEmpresas: any;
  listaConsultores: string[];
  createdAt: string;
  deleted: boolean;
  deletedAt: any;
  foto: string;
  id: string;
  interesses: any[];
  listaInteresses: any[];
  seguidores: string[];
  login: string;
  nickName: string;
  senha: string;
  termosUso: boolean;
  tipo: string;
  updatedAt: string;
  __v: number;
  _id: string;
  streamKey: string;
  streamLink: string;
}

interface IUserContext {
  loading: boolean;
  user: IUser;
  setUser(user: IUser): void;
  getProfile(): void;
  loadingVideo: any;
  setLoadingVideo: any;
  reloadComment: any;
  setReloadComment: any;
  sideBar: boolean;
  setSideBar(e: boolean): void;
  interaction: boolean;
  setInteraction(interaction: boolean): void;
}

const UserContext = createContext<IUserContext>({} as IUserContext);

export const UserProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<IUser>({} as IUser);
  const [loadingVideo, setLoadingVideo] = useState<boolean>(true);
  const [reloadComment, setReloadComment] = useState<boolean>(false);
  const [sideBar, setSideBar] = useState<boolean>(false);

  const INTERACTION_DEFAULT = '@dbts-interaction';

  const [interaction, setInteraction] = useState<boolean>(false);

  useEffect(() => {
    getProfile();
  }, []);

  async function getProfile() {
    try {
      setLoading(true);
      const response = await apiDoubts.get('profile');
      setUser({ ...response.data.data });
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  }

  return (
    <UserContext.Provider
      value={{
        loading,
        user,
        setUser,
        getProfile,
        loadingVideo,
        setLoadingVideo,
        reloadComment,
        setReloadComment,
        sideBar,
        setSideBar,
        interaction,
        setInteraction,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export function useUser() {
  const context = useContext(UserContext);
  return context;
}

export default UserContext;
