import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import apiDoubts from '../../../services/api';
import ButttonDefault from '../../ButtonDefault';
import ModalAttProduto from '../../Empresa/ModalAttProduto';
import ModalDefault from '../../Modal Default';
import { AreaMessageConsultor, Container, ConviteConsultor } from './styles';
import UserContext from '../../../contexts/UserContext';

interface props {
  consultorData: any;
  invite: (id: string) => void;
}

export default function CardMenuOportunidadeAgencia({
  consultorData,
  invite,
}: props) {
  const [modalConvidarConsultor, setModalConvidarConsultor] =
    useState<boolean>(false);
  const [messageConviteConsultor, setMessageConviteConsultor] =
    useState<string>('');
  const [loadInvite, setLoadInvite] = useState<boolean>(false);

  const { user } = useContext(UserContext);

  async function handleInviteConsultor() {
    if (loadInvite === false) {
      try {
        setLoadInvite(true);
        if (!messageConviteConsultor)
          throw new Error('Por favor, digite uma mensagem para o consultor');

        await apiDoubts
          .post(`/conversa/enviar-mensagem`, {
            tipoConversa: 'convite',
            titulo: `convite - ${user?.nickName} `,
            mensagem: messageConviteConsultor,
            destinatarioId: consultorData?.id,
          })
          .then(() => {
            setModalConvidarConsultor(false);
            setMessageConviteConsultor('');
            setLoadInvite(false);
            toast.success('Consultor convidado com sucesso', {
              className: 'toast-sucess',
              theme: 'colored',
            });
          });
      } catch (error: any) {
        setLoadInvite(false);
        toast.error(error.message, {
          className: 'toast-error',
          theme: 'colored',
        });
      }
    }
  }

  return (
    <Container>
      <ModalAttProduto
        id="modalConvidarConsultor"
        width="450"
        onClose={() => {
          setModalConvidarConsultor(false);
        }}
        openModal={modalConvidarConsultor}
        title="Convidar Agência"
        scroll={false}
      >
        <ConviteConsultor>
          <div className="areaLeftConsultorConvite">
            <div
              className="imageProfileConsultor"
              style={{ backgroundImage: `urL(${consultorData?.foto})` }}
            ></div>
          </div>
          <div className="areaRightConsultorConvite">
            <h4 className="nameConsultor">{consultorData?.nickName}</h4>
            <p className="descriptionConsultor">
              {consultorData?.descricaoCanal}
            </p>
          </div>
        </ConviteConsultor>
        <AreaMessageConsultor>
          <h3 className="titleAreaMessageConsultor">Mensagem</h3>
          <textarea
            value={messageConviteConsultor}
            className="input"
            placeholder="Assuntos..."
            onChange={(e) => {
              setMessageConviteConsultor(e.target.value);
            }}
          />
          <div className="areaButtonsConsultor">
            <ButttonDefault title={'Cancelar'} tipo={'11'} />
            <ButttonDefault
              icon={loadInvite ? 'Loading' : ''}
              title={'Enviar'}
              tipo={'6'}
              onClick={() => {
                handleInviteConsultor();
              }}
            />
          </div>
        </AreaMessageConsultor>
      </ModalAttProduto>

      <div
        className="imageProfileConsultor"
        style={{ backgroundImage: `url(${consultorData?.foto})` }}
      ></div>
      <h3 className="nickNameConsultor">{consultorData?.nickName}</h3>
      <h4 className="consultorDescription">{consultorData?.descricaoCanal}</h4>
      <ButttonDefault
        onClick={() => {
          setModalConvidarConsultor(true);
        }}
        title={
          consultorData?.convidado ? 'Agência Convidada' : 'Convidar Agência'
        }
        tipo={consultorData?.convidado ? '12' : '7'}
        hover={'medio'}
      />
    </Container>
  );
}
