import styled, { keyframes } from 'styled-components';

type SkeletonProps = {
  height?: number;
  width?: number;
  shape?: 'rounded' | 'round' | 'default';
};

const pulseAnimation = keyframes`
  0% {
    background-color: #f0f0f0;
  }

  50% {
    background-color: #e0e0e0;
  }

  100% {
    background-color: #f0f0f0;
  }
`;

export const StyledSkeleton = styled.div<SkeletonProps>`
  margin: 3px 0;
  height: ${({ height }) => (height ? `${height}px` : '17px')};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  background-color: gray;
  animation: ${pulseAnimation} 1.5s ease-in-out infinite;
  border-radius: ${({ shape = 'default' }) =>
    shape === 'rounded' ? '100%' : shape === 'round' ? '8px' : '4px'};
`;
