import { useEffect, useRef, useState } from 'react';
import { InputSearch } from './styles';
import SearchIcon from '../../assets/icons/searchIcon';

interface SearchProps extends React.HTMLProps<HTMLDivElement> {
  value: string;
  onChange: (value: any) => void;
  submit?: () => void;
  focus?: () => void;
  modal?: boolean;
}

export default function SearchInput({
  value,
  onChange,
  submit,
  focus,
  modal,
}: SearchProps) {
  const [search, setSearch] = useState<string>(value);
  const InputRef = useRef<any>();

  useEffect(() => {
    onChange(search);
  }, [search]);

  useEffect(() => {
    if (modal) {
      InputRef.current.focus();
    }
  }, [modal]);

  return (
    <InputSearch>
      <div onSubmit={submit}>
        <span className="icon" onClick={submit}>
          <SearchIcon />
        </span>
        <input
          ref={InputRef}
          onFocus={focus}
          type="text"
          placeholder="Pergunte para o Doubts"
          value={search}
          onChange={(e: any) => setSearch(e.target.value)}
        />
      </div>
    </InputSearch>
  );
}
