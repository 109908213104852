import { getAllByPlaceholderText } from '@testing-library/react';
import { useEffect, useState } from 'react';

import { Container, Title, Hashtag, AreaHash } from './styles';

interface HProps {
  remove: (e: any) => void;
  currentHashtags?: string;
  data: any[];
  tipo: string;
}

export default function ListInsteressesStateless({
  currentHashtags,
  data,
  remove,
  tipo,
}: HProps) {
  const [hashtags, setHashtags] = useState<any>();
  const [hover, setHover] = useState<boolean>(false);
  const [labelHover, setLabelHover] = useState<string>('');

  useEffect(() => {
    console.log('data', data)
  }, [data]);

  return (
    <Container>
      <AreaHash>
        {data?.map((response: any, key) => (
          <Hashtag
            onClick={() => {
              remove(response);
            }}
            key={key}
            onMouseOver={() => {
              setHover(true);
              setLabelHover(response);
            }}
            onMouseOut={() => {
              setHover(false);
              setLabelHover('');
            }}
          >
            {tipo === 'object' && response.label}
            {tipo === 'string' && response}
          </Hashtag>
        ))}
      </AreaHash>
    </Container>
  );
}
