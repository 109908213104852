import { Container } from './styles';
import moment from 'moment';
import Skeleton from '../Skeleton';

interface CProps {
  comentario: any;
  loading?: boolean;
}

export default function ComentariosCard({ comentario, loading }: CProps) {
  return (
    <Container className="animate__animated animate__fadeIn">
      {loading && (
        <div className="image loading">
          <Skeleton />
        </div>
      )}

      {comentario?.cadastros?.foto && (
        <div
          className="image"
          style={{ backgroundImage: `url(${comentario?.cadastros?.foto})` }}
        ></div>
      )}

      {comentario?.empresas?.foto && (
        <div
          className="image"
          style={{ backgroundImage: `url(${comentario?.empresas?.foto})` }}
        ></div>
      )}

      {comentario?.consultor?.foto && (
        <div
          className="image"
          style={{ backgroundImage: `url(${comentario?.consultor?.foto})` }}
        ></div>
      )}

      {comentario?.consultores?.foto && (
        <div
          className="image"
          style={{ backgroundImage: `url(${comentario?.consultores?.foto})` }}
        ></div>
      )}

      {comentario?.agencias?.foto && (
        <div
          className="image"
          style={{ backgroundImage: `url(${comentario?.agencias?.foto})` }}
        ></div>
      )}

      <div className="content">
        <div className="areaComment">
          {loading && (
            <p className="name">
              <Skeleton width={80} />
            </p>
          )}

          {comentario.cadastros?.nickName && (
            <p className="name">{comentario.cadastros?.nickName}</p>
          )}
          {comentario.agencias?.nickName && (
            <p className="name">{comentario.agencias?.nickName}</p>
          )}
          {comentario.empresas?.nickName && (
            <p className="name">{comentario.empresas?.nickName}</p>
          )}
          {comentario.consultor?.nickName && (
            <p className="name">{comentario.consultor?.nickName}</p>
          )}
          {comentario.consultores?.nickName && (
            <p className="name">{comentario.consultores?.nickName}</p>
          )}

          <div className="moment">
            {loading ? (
              <Skeleton />
            ) : (
              moment(comentario?.createdAt).locale('pt-br').fromNow()
            )}
          </div>
        </div>
        <div className="comment">
          {loading ? <Skeleton width={120} /> : <p>{comentario.comentario}</p>}
        </div>
      </div>
    </Container>
  );
}
