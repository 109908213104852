import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

:root {
  //nova paleta de coreas
  --bg-inputs: #9DFBFB;
  --paleta1A: #0661C7;
  --paleta1B: #069CD1;
  --paleta1C: #0FBABA;
  --paleta2A: #5C529C;
  --paleta2B: #595769;
  --paleta2C: #2E294E;
  --paleta2D: #85819C;
  --paleta2E: #8A7BE8;
  --branco: #FFFFFF;
  --texto: #7D7D7D;
  --texto2: #6D6D6D;
  --background: #FAFAFF;
  --background2 : #E7E7F3;
  --bgInput: #EEEEEE;
  --textoplaceholder: #AAAAAA;
  --warning: #FAAE42;
  --btn-aceitar: #06D6A0;
  --offline: #E62965;

  //antiga paleta de cores
  --green: #12B1AF;
  --purple1: #1E1B33;
  --purple2: #2E294E;
  --purple3: #36305B;
  --purple4: #4A427C;
  --purple5: #685EA9;
  --purple6: #9083E2;
  --purple7: #BEB4FF;

  --online: #0FBA53;
  
  
  --background: #FAFAFF;
  --white: #FFF;
  --mid-grey: #C4C4C4;
  --dark-grey: #8F8F8F;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
  }
  // font-size: 16px (desktop)
  html {
    @media (max-width: 1080px) {
      font-size: 93.75%; // 15px
    }
    @media (max-width: 720px) {
      font-size: 87.5%; // 14px
    }
  }
  // REM = 1rem = 16px
  body {
    background: var(--background);
    -webkit-font-smoothing: antialiased;
  }
  body, input, textarea, button {
    font-weight: 400;
  }
  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }
  button {
    cursor: pointer;
  }

  body {
    overflow-y: scroll;
    overflow-x: hidden;
  }

`;
