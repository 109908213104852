import styled from 'styled-components';

export const Container = styled.div`
  height: 130px;
  background-color: var(--background2);
  border-radius: 10px;
  border: 2px solid var(--paleta2E);
  position: relative;

  .inputCheckboxConsultorEmpresa {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: -2px;
    margin-right: -2px;
    z-index: 4;
  }

  .iconChecked {
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 2px;
    z-index: 5;
    cursor: pointer;
  }

  .contentCardProfileConsultor {
    padding: 0 20px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .bussinessInfo {
      display: flex;
      align-items: center;
      gap: 25px;

      .imageProfileConsultor {
        width: 90px;
        aspect-ratio: 4/4;
        border-radius: 50%;

        background-position: center;
        background-size: cover;
      }

      .profileInfo {
        line-height: 24px;

        .nickName {
          font-size: 18px;
          font-weight: 600;
          cursor: pointer;
        }

        .createdDate {
          font-size: 13px;
          color: rgba(0, 0, 0, 0.5);
        }

        .areaAtuacoes {
          display: flex;
          gap: 5px;
          font-size: 13px;
          color: rgba(0, 0, 0, 0.8);
          margin-top: 5px;
          max-width: 100%;

          > p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .removeVinculo {
      cursor: pointer;
    }
  }

  input[type='checkbox'] {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom checkbox appearance */
    display: inline-block;
    width: 25px;
    height: 25px;
    /* background-color only for content */
    background-clip: content-box;
    border: 2px solid var(--paleta2E);
    border-radius: 0 10px 0 10px;
    background-color: transparent;

    &:checked {
      background-color: var(--paleta2E);
      color: var(--branco);
    }

    &:focus {
      outline: none !important;
    }
  }
`;
