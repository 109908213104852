import React from 'react';
import { IconCheck, IconCheckbox } from '../Icons';
import { DivCheckbox } from './styles';

interface Props {
  name: string;
  value: boolean;
  onClick: (key: string, value: boolean) => void;
  placeholder?: string;
  label?: string;
  readOnly?: boolean;
  onChange?: any;
}

const Checkbox: React.FC<Props> = (Props) => {
  const handleCheckbox = () => {
    if (!Props.readOnly) {
      let value = Props.value;
      if (value) {
        Props.onClick(Props.name, false);
      } else {
        Props.onClick(Props.name, true);
      }
    }
  };

  return (
    <DivCheckbox className="divCheckbox">
      {Props.label && <div className="label">{Props.label}</div>}

      <div
        className={`checkbox ${Props.value && 'active'}`}
        onClick={handleCheckbox}
      >
        <div className="icon">{Props.value && <IconCheckbox size={14} />}</div>

        {Props.placeholder && <span className="text">{Props.placeholder}</span>}
      </div>
    </DivCheckbox>
  );
};

export default Checkbox;
