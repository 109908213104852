import styled from 'styled-components';

export const Container = styled.div`
  .containerModalConfurmation {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .consultoriaConfirmation {
      font-size: 16px;
      line-height: 26px;
      font-weight: 500;
      .consultoriaConfirmationBold {
        font-weight: 700;
      }
    }
  }
  .modal-finish {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    > p {
      font-size: 14px;
      color: var(--texto);
    }
  }
  .name-user {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 20px 0px;
    img {
      width: 20px;
      height: 20px;
      border-radius: 100px;
      background-size: cover;
    }
  }
  .date-hour {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 20px 0px;
  }
  .toast-error {
    background: var(--purple2);
    position: absolute;
  }

  .warningWrapper {
    width: 100%;
    max-height: min-content;
    position: relative;
    margin-top: 40px;
  }

  .minutesArea {
    display: flex;
    gap: 10px;
    overflow-y: hidden;
    min-width: 100%;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .aviso {
    margin-top: 17px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: var(--texto);
    max-width: 758px;
  }

  .alert {
    margin-top: 17px;

    padding: 15px 10px;
    background-color: var(--paleta1A);
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: var(--branco);
    text-align: center;
    min-height: 46px;
    border-radius: 8px;
  }

  .question {
    font-size: 13px;
    line-height: 15px;
    font-weight: 700;
    color: var(--texto2);
    padding: 10px 0px;
  }

  .inputAssunto {
    width: 100%;
    max-width: 758px;
  }

  .input {
    padding: 10px;
    border: none;
    width: 100%;
    max-width: 758px;
    background-color: var(--background2);
    border-radius: 10px;
    height: 150px;
    display: flex;
    outline: none;
    resize: none;
  }

  .areaButton {
    margin-top: 10px;
    max-width: 758px;
    display: flex;
    justify-content: flex-end;
  }

  .Rigth {
    background-color: lightcyan;
    width: 314px;
    height: calc(100% + 60px);
    margin-top: -60px;
  }

  .content-user-page {
    display: flex;
    gap: 30px;
    .render {
      flex: 1;
      .principal-info {
        display: flex;
        border-bottom: 3px solid var(--background2);
        padding-bottom: 25px;
        .principal-post {
          display: flex;
          gap: 25px;
          align-items: center;
          .video {
            width: 227px;
            height: 142px;
            border-radius: 10px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            background-size: cover;
            background-position: center;
          }
          .infos {
            display: flex;
            flex-direction: column;
            gap: 10px;
            .title {
              font-size: 24px;
              font-weight: 500;
            }
            .description {
            }
            .metrics {
              display: flex;
              align-items: center;
              gap: 5px;
              span {
                font-size: 14px;
                color: var(--purple4);
              }
              b {
                font-size: 18px;
                line-height: 5px;
              }
            }
          }
        }
      }
      .text-info {
        margin-bottom: 25px;
        .title {
          font-size: 28px;
          color: var(--purple1);
          font-weight: bold;
          margin-top: 25px;
        }
      }
      .request-user {
        display: flex;
        gap: 25px;
        background: var(--background2);
        border-radius: 10px;
        .sidebar {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 25px;
          width: 420px;
          > div {
            flex: unset;
          }
        }
        .content-hours {
          flex: 1;
          padding-top: 25px;
          .head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            h3 {
              font-size: 18px;
              color: #1e1b33;
              font-weight: 700;
            }
          }
          .overflow {
            overflow: auto;
            height: 446px;
            position: relative;

            .empty {
              display: flex;
              width: calc(100% - 20px);
              height: calc(100% - 35px);
              align-items: center;
              justify-content: center;
              background: var(--background);
              margin-top: 15px;
              left: 0;
              top: 0;
              border-radius: 8px;
              display: flex;
              flex-direction: column;
              gap: 20px;
              svg {
                width: 65px;
                height: 65px;
              }
              p {
                font-size: 16px;
                font-weight: 500;
                color: var(--purple2);
                width: 400px;
                text-align: center;
              }
            }

            ::-webkit-scrollbar {
              width: 4px;
            }

            /* Track */
            ::-webkit-scrollbar-track {
              background: rgba(0, 0, 0, 0.05);
              border-radius: 100px;
            }

            /* Handle */
            ::-webkit-scrollbar-thumb {
              background: var(--purple7);
            }

            /* Handle on hover */
            ::-webkit-scrollbar-thumb:hover {
              background: var(--purple2);
            }
          }
        }
      }
    }
    .sideRigth {
      min-width: 300px;
      .areaBanner {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }

  .canal {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 20px;

    .DescriptionConsultor {
      width: 100%;
      gap: 20px;

      display: flex;
      justify-content: space-between;

      .banner {
        min-width: 300px;
        max-width: 30%;
        height: 227px;
        background-size: cover;
        background-position: center;
        border-radius: 10px;

        margin-top: -60px;
      }
    }

    .contentCanal {
      width: 100%;
    }
  }

  @media (max-width: 1370px) {
    .generic {
      .sideRigth {
        display: none;
      }

      .content {
        min-width: 90%;
        .areaBanner {
          display: flex;
          .bannerTopMobile {
            display: flex;
          }
        }
      }
    }
  }

  @media (max-width: 800px) {
    .generic {
      .content {
        min-width: 100%;
        .calendar {
          flex-direction: column;
          .react-calendar {
            min-width: 100%;
            max-height: 330px;
            border-radius: 10px;
          }
          .selectHour {
            max-width: 100%;
            min-width: 100%;
            border: none;
          }
        }
      }
    }
  }
`;
