import styled, { css } from 'styled-components';

interface TypeEmailProps {
  type: string;
}

interface SelectedProps {
  isSelected: boolean;
}

export const Container = styled.button<SelectedProps>`
  border: none;
  background-color: transparent;

  width: 100%;
  height: 46px;
  border: 1px solid var(--background2);
  padding: 10px;

  display: flex;
  gap: 15px;
  align-items: center;
`;

export const ButtonArea = styled.div`
  width: min-content;
  display: flex;
  align-items: center;
  gap: 10px;

  .buttonEstrela {
    background-color: transparent;
    border: none;
    z-index: 2 !important;
  }
`;

export const RigthArea = styled.button`
  background-color: transparent;
  border: none;

  width: 100%;
  display: flex;
  gap: 15px;
  align-items: center;

  justify-content: space-between;

  overflow-x: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;

  .startRigthArea {
    display: flex;
    gap: 15px;
    align-items: center;
    P {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 14px;
      text-decoration: none;
    }
  }

  @media (max-width: 800px) {
    text-decoration: none;

    .startRigthArea {
      -webkit-scrollbar: {
        display: none;
      }
      p {
        white-space: nowrap;
      }
    }
  }
`;

export const NameArea = styled.div`
  width: 160px;

  display: flex;
  align-items: flex-start;
`;

export const EmailDate = styled.span`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
`;
