import styled from 'styled-components';

export const Container = styled.div`
  /* grid-gap: 10px; */
  /* display: grid; */
  /* grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); */
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;
