import {  useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AnunciosVerticais from '../../components/Anuncio/AnunciosVerticais';
import FiltrosInteresses from '../../components/FiltrosInteresses';
import SelectDefaultSearch from '../../components/Form/SelectDefaultSearch';
import Hashtags from '../../components/Hashtags';
import SearchPosts from '../../components/Posts/SearchPosts';
import TopConsultores from '../../components/TopConsultores';
import { Container, ContainerContent, ContainerFilter } from './styles';

const Home: React.FC = (props: any) => { 
  const ordenacaoOpcoes = [
    {
      label: 'Populares',
      value: 'popular',
    },
    {
      label: 'Recentes',
      value: 'data',
    },
  ];
  const [ordenacao, setOrdenacao] = useState<any>({
    label: 'Populares',
    value: 'popular',
  });
  const [interesse, setInteresse] = useState<string>('');
  const [hashtag, setHashtag] = useState<string>();
  const params = useParams<any>()

  return (
    <Container>
      <ContainerFilter>
        <div className="order">
          <div className="areaSelect">
            <SelectDefaultSearch
              options={ordenacaoOpcoes}
              value={ordenacao}
              onChange={(value) => setOrdenacao(value)}
            />
          </div>
          <div className="hashMobile">
            <Hashtags
              currentHashtags={hashtag}
              hash={(e) => setHashtag(e)}
              title={false}
              position="top"
            />
          </div>
        </div>

        <div className="where">
          <FiltrosInteresses
            selected={interesse}
            setSelected={(e) => setInteresse(e)}
          />
        </div>
      </ContainerFilter>

      <ContainerContent>
        <div className="section-left">
          <SearchPosts
            order={ordenacao.value}
            where={{
              interesse: interesse,
              hash: hashtag,
              search: params?.params              
            }}
          />
          <div className="areaButton"></div>
        </div>
        <div className="section-right">
          <AnunciosVerticais
            count={1}
            where={{
              interesse: interesse,
              search: '',
              hashs: [],
            }}
          />
          <TopConsultores direction={'colum'}/>
          <Hashtags
            currentHashtags={hashtag}
            hash={(e) => setHashtag(e)}
            title={true}
            position={'lateral'}
          />
        </div>
      </ContainerContent>
    </Container>
  );
};

export default Home;
