import ModalForm from '../../../components/Cadastrar/ModalForm';
import Inputdefault from '../../../components/Inputs/InputDefault';
import { IStepsProps } from './types';

import * as S from './styles';
import SelectDefaultCadastrar from '../../../components/Cadastrar/SelectDefaultCadastrar';
import ButttonDefault from '../../../components/ButtonDefault';
import StepsInSignUp from '../../../components/Cadastrar/StepsInSignUp';
import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  formatCel,
  formatCep,
  formatCNPJ,
  formatCPF,
  formatData,
  formatRG,
} from '../../../utils/format';
import { isAValidCPF } from '../../../utils/isAValidCPF';
import { isAValidDate } from '../../../utils/isAValidDate';

export default function StepTwo({
  nextStep,
  onChangeField,
  DTO,
  prevStep,
}: IStepsProps) {
  const [newInteresse, setNewInteresse] = useState<string>('');

  function addArrayAtuacao(newAtuacao: string) {
    try {
      if (newAtuacao === '') {
        throw new Error('Adicione uma área de atuação válida!');
      }

      DTO?.areaAtuacao?.forEach((AtuacaoPosition: string) => {
        if (AtuacaoPosition?.trim() === newAtuacao.trim())
          throw new Error('Essa área de atuação já foi adicionada');
      });

      let newArrayAtuacao: Array<string> = DTO.areaAtuacao ?? [];
      newArrayAtuacao?.push(newAtuacao);

      onChangeField({ position: 'areaAtuacao', value: newArrayAtuacao });
      setNewInteresse('');
    } catch (error: any) {
      toast.error(error.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  function removeArrayAtuacao(removeAtuacao: string) {
    let newArrayAtuacao: Array<string> = DTO?.areaAtuacao;
    newArrayAtuacao = newArrayAtuacao.filter((row: string) => {
      return row !== removeAtuacao;
    });
    onChangeField({ position: 'areaAtuacao', value: newArrayAtuacao });
  }

  function validateStepTwoFieldsCPF() {
    try {
      if (!DTO?.nickName)
        throw new Error('É necessário digitar um nome de usuário');
      if (!DTO?.cpf) throw new Error('O CPF é obrigatório');
      // if (!isAValidCPF(DTO?.cpf))
      //   throw new Error('É necessário digitar um CPF válido');
      if (!DTO?.rg) throw new Error('É necessário digitar um RG válido');
      if (!DTO?.sexo) throw new Error('Escolha o sexo');
      if (!DTO?.dataNascimento)
        throw new Error('Digite a sua data de nascimento');
      if (!isAValidDate(DTO?.dataNascimento))
        throw new Error('É necessário digitar uma data de nascimento válida');
      if (!DTO?.telefone) throw new Error('Digite o seu telefone de contato');
      if (!DTO?.endereco) throw new Error('É necessário digitar um CEP válido');
      if (DTO?.areaAtuacao?.length < 3 || !DTO.areaAtuacao)
        throw new Error('Escolha ao menos 3 áreas de atuação');

      nextStep();
    } catch (error: any) {
      toast.error(error.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  function validateStepTwoFieldsCNPJ() {
    try {
      if (!DTO?.razaoSocial)
        throw new Error('É necessário digitar um nome para a empresa');
      if (!DTO?.cnpjEmpresa)
        throw new Error('É necessário digitar um CNPJ válido');
      if (!DTO?.telefoneEmpresa)
        throw new Error('É necessário telefone para a empresa');
      if (!DTO?.enderecoEmpresa)
        throw new Error('É necessário adicionar o CEP da empresa');
      if (!DTO?.dataNascimento)
        if (DTO?.areaAtuacao?.length < 1)
          throw new Error('Escolha ao menos uma área de atuação');

      nextStep();
    } catch (error: any) {
      toast.error(error.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  return (
    <S.ContainerStepOne>
      <ModalForm>
        {/* caso o usuário tenha escolhido CPF essa etapa cadastra os dados do usuário PF */}
        {DTO?.tipo?.value === 'CPF' && (
          <>
            <StepsInSignUp currentStep={2} totalSteps={3} />

            <p className="titleStep">Perfil</p>

            <form
              className="signUpForm"
              onSubmit={(e: React.ChangeEvent<HTMLFormElement>) => {
                e.preventDefault();
              }}
            >
              <span className="inputField">
                <label>Apelido</label>
                <Inputdefault
                  value={DTO?.nickName}
                  color="transparent"
                  tipo="text"
                  placeholder="Apelido"
                  change={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChangeField({
                      position: 'nickName',
                      value: e.target.value,
                    });
                  }}
                />
              </span>

              <div className="twoInLine">
                <span className="inputField">
                  <label>Nome Completo</label>
                  <Inputdefault
                    value={DTO?.nome}
                    color="transparent"
                    tipo="text"
                    placeholder="Ex: André Sousa"
                    change={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onChangeField({
                        position: 'nome',
                        value: e.target.value,
                      });
                    }}
                  />
                </span>

                <span className="inputField">
                  <label>CPF</label>
                  <Inputdefault
                    color="transparent"
                    value={DTO?.cpf}
                    tipo="text"
                    placeholder="999.999.999-99"
                    change={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const formattedCPF = formatCPF(e.target.value);
                      onChangeField({
                        position: 'cpf',
                        value: formattedCPF,
                      });
                    }}
                  />
                </span>
              </div>

              <div className="twoInLine">
                <span className="inputField">
                  <label>RG</label>
                  <Inputdefault
                    value={DTO?.rg}
                    color="transparent"
                    tipo="text"
                    placeholder="99.999.999-3"
                    change={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const formattedRG = formatRG(e.target.value);
                      onChangeField({
                        position: 'rg',
                        value: formattedRG,
                      });
                    }}
                  />
                </span>

                <span className="inputField">
                  <label>Sexo</label>
                  <SelectDefaultCadastrar
                    onChange={(e: any) => {
                      onChangeField({ position: 'sexo', value: e });
                    }}
                    options={[
                      { label: 'Masculino', value: 'masculino' },
                      { label: 'Feminino', value: 'feminino' },
                      { label: 'Outro', value: 'outro' },
                    ]}
                    value={DTO?.sexo}
                  />
                </span>
              </div>

              <div className="twoInLine">
                <span className="inputField">
                  <label>Data de nascimento</label>
                  <Inputdefault
                    value={DTO?.dataNascimento}
                    color="transparent"
                    tipo="text"
                    placeholder="01/01/2001"
                    change={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const formattedDate = formatData(e.target.value);
                      onChangeField({
                        position: 'dataNascimento',
                        value: formattedDate,
                      });
                    }}
                  />
                </span>

                <span className="inputField">
                  <label>Telefone</label>
                  <Inputdefault
                    color="transparent"
                    tipo="text"
                    value={DTO?.telefone}
                    placeholder="(99) 99999 - 9999"
                    change={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const formattedCel = formatCel(e.target.value);
                      onChangeField({
                        position: 'telefone',
                        value: formattedCel,
                      });
                    }}
                  />
                </span>
              </div>

              <p className="titleStep">Perfil</p>

              <span className="inputField">
                <label>CEP</label>
                <Inputdefault
                  color="transparent"
                  tipo="text"
                  placeholder="99999-999"
                  value={DTO?.endereco}
                  change={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const formattedCEP = formatCep(e.target.value);
                    onChangeField({
                      position: 'endereco',
                      value: formattedCEP,
                    });
                  }}
                />
              </span>

              <label>Área de atuação</label>
              <Inputdefault
                color="transparent"
                tipo="text"
                keyDown={() => {
                  addArrayAtuacao(newInteresse);
                }}
                value={newInteresse}
                placeholder="Ex: Programação"
                change={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setNewInteresse(e.target.value);
                }}
              />
              <div className="ArrayAreaAtuacao">
                {DTO?.areaAtuacao?.map(
                  (areaAtuacaoPosition: string, index: number) => {
                    return (
                      <span
                        className="itemArrayAtuacao"
                        key={index}
                        onClick={() => {
                          removeArrayAtuacao(areaAtuacaoPosition);
                        }}
                      >
                        {areaAtuacaoPosition}
                      </span>
                    );
                  }
                )}
              </div>
            </form>
          </>
        )}

        {/* caso o usuário tenha escolhido CNPJ essa etapa cadastra os dados da empresa */}
        {DTO.tipo.value === 'CNPJ' && (
          <>
            <StepsInSignUp currentStep={2} totalSteps={4} />

            <p className="titleStep">Informações da empresa</p>

            <form className="signUpForm">
              <span className="inputField">
                <label>Nome da Empresa</label>
                <Inputdefault
                  value={DTO?.nickName}
                  color="transparent"
                  tipo="text"
                  placeholder="Apelido"
                  change={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChangeField({
                      position: 'nickName',
                      value: e.target.value,
                    });
                  }}
                />
              </span>

              <div className="twoInLine">
                <span className="inputField">
                  <label>Razão social</label>
                  <Inputdefault
                    color="transparent"
                    tipo="text"
                    value={DTO?.razaoSocial}
                    placeholder="Razão Social"
                    change={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onChangeField({
                        position: 'razaoSocial',
                        value: e.target.value,
                      });
                    }}
                  />
                </span>

                <span className="inputField">
                  <label>CNPJ</label>
                  <Inputdefault
                    color="transparent"
                    tipo="text"
                    value={DTO?.cnpjEmpresa}
                    placeholder="99.999.999/0001-99"
                    change={(e: React.ChangeEvent<HTMLInputElement>) => {
                      let formattedCnpj: string = formatCNPJ(e.target.value);
                      onChangeField({
                        position: 'cnpjEmpresa',
                        value: formattedCnpj,
                      });
                    }}
                  />
                </span>
              </div>

              <span className="inputField">
                <label>Telefone da empresa</label>
                <Inputdefault
                  color="transparent"
                  tipo="text"
                  value={DTO?.telefoneEmpresa}
                  placeholder="(11) 99999 - 9999"
                  change={(e: React.ChangeEvent<HTMLInputElement>) => {
                    let formattedTelefone: string = formatCel(e.target.value);
                    onChangeField({
                      position: 'telefoneEmpresa',
                      value: formattedTelefone,
                    });
                  }}
                />
              </span>

              <p className="titleStep">Endereço</p>

              <span className="inputField">
                <label>CEP</label>
                <Inputdefault
                  color="transparent"
                  tipo="text"
                  value={DTO?.enderecoEmpresa}
                  placeholder="99999 - 999"
                  change={(e: React.ChangeEvent<HTMLInputElement>) => {
                    let formattedCep: string = formatCep(e.target.value);
                    onChangeField({
                      position: 'enderecoEmpresa',
                      value: formattedCep,
                    });
                  }}
                />
              </span>

              <span className="inputField">
                <label>Area de atuação</label>
                <Inputdefault
                  color="transparent"
                  tipo="text"
                  keyDown={() => {
                    addArrayAtuacao(newInteresse);
                  }}
                  value={newInteresse}
                  placeholder="Ex: Programação"
                  change={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setNewInteresse(e.target.value);
                  }}
                />
                <div className="ArrayAreaAtuacao">
                  {DTO?.areaAtuacao?.map(
                    (areaAtuacaoPosition: string, index: number) => {
                      return (
                        <span
                          className="itemArrayAtuacao"
                          key={index}
                          onClick={() => {
                            removeArrayAtuacao(areaAtuacaoPosition);
                          }}
                        >
                          {areaAtuacaoPosition}
                        </span>
                      );
                    }
                  )}
                </div>
              </span>
            </form>
          </>
        )}

        <div className="twoButtons">
          <ButttonDefault
            tipo="14"
            onClick={() => {
              prevStep();
            }}
            title="Voltar"
            hover="pouco"
          />

          <ButttonDefault
            onClick={() => {
              if (DTO?.tipo?.value === 'CPF') {
                validateStepTwoFieldsCPF();
              } else {
                validateStepTwoFieldsCNPJ();
              }
            }}
            tipo={'13'}
            title="continuar"
            hover="pouco"
          />
        </div>
      </ModalForm>
    </S.ContainerStepOne>
  );
}
