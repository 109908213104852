import userEvent from '@testing-library/user-event';
import { useContext, useEffect, useState } from 'react';
import AnunciosVerticais from '../../components/Anuncio/AnunciosVerticais';
import ButttonDefault from '../../components/ButtonDefault';
import FiltrosInteresses from '../../components/FiltrosInteresses';
import SelectDefaultSearch from '../../components/Form/SelectDefaultSearch';
import Hashtags from '../../components/Hashtags';
import ListPostsDescubra from '../../components/Posts/ListPostDescubra';
import ListPosts from '../../components/Posts/ListPosts';
import TopConsultores from '../../components/TopConsultores';
import WarningLogout from '../../components/WarningLogout';
import UserContext from '../../contexts/UserContext';
import {
  Container,
  ContainerContent,
  ContainerFilter,
  ContainerWithOutLogin,
} from './styles';

const Descubra: React.FC = (props: any) => {
  const { user } = useContext(UserContext);
  const ordenacaoOpcoes = [
    {
      label: 'Populares',
      value: 'popular',
    },
    {
      label: 'Recentes',
      value: 'data',
    },
  ];
  const [ordenacao, setOrdenacao] = useState<any>({
    label: 'Populares',
    value: 'popular',
  });
  const [interesse, setInteresse] = useState<string>('');
  const [hashtag, setHashtag] = useState<string>();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [hashtag]);

  return (
    <>
      {user?.login ? (
        <Container>
          <ContainerFilter>
            <div className="order">
              <div className="areaSelect">
                <SelectDefaultSearch
                  options={ordenacaoOpcoes}
                  value={ordenacao}
                  onChange={(value) => setOrdenacao(value)}
                />
              </div>
              <div className="hashMobile">
                <Hashtags
                  currentHashtags={hashtag}
                  hash={(e) => setHashtag(e)}
                  title={false}
                  position="top"
                />
              </div>
            </div>

            <div className="where">
              <FiltrosInteresses
                selected={interesse}
                setSelected={(e) => setInteresse(e)}
              />
            </div>
          </ContainerFilter>

          <ContainerContent>
            <div className="section-left">
              <ListPostsDescubra
                order={ordenacao.value}
                where={{
                  interesse: interesse,
                  hash: hashtag,
                }}
              />
              <div className="areaButton"></div>
            </div>
            <div className="section-right">
              <AnunciosVerticais
                count={1}
                where={{
                  interesse: interesse,
                  search: '',
                  hashs: [],
                }}
              />
              <TopConsultores direction="colum" />
              <Hashtags
                currentHashtags={hashtag}
                hash={(e) => setHashtag(e)}
                title={true}
                position={'lateral'}
              />
            </div>
          </ContainerContent>
        </Container>
      ) : (
        <WarningLogout />
      )}
    </>
  );
};

export default Descubra;
