import { useEffect, useState } from 'react';
import Checkbox2 from '../../../components/Checkbox/CheckBox2';
import {
  IconArrowLeft,
  IconArrowRight,
  IconConversas,
  IconConvites,
  IconEmail,
  IconFavoritos,
  IconLoading,
  IconTrash,
  MoreIcon,
} from '../../../components/Icons';
import CardMailBox from '../../../components/MailBox/CardMailBox';
import InviteMessage from '../../../components/MailBox/SendMessage';
import apiDoubts from '../../../services/api';
import { ButtonNewEmail, Container, MenuHeader, Content } from './styles';
import { toast } from 'react-toastify';
import EmptyProducts from '../../../components/EmptyProducts';
import { EmailsFilter } from './types';
import { AxiosResponse } from 'axios';

export default function Email({ children }: any) {
  const [menuSelected, setMenuSelected] = useState<string>('Caixa de Entrada');
  const [menuEntradaSelected, setMenuEntradaSelected] =
    useState<string>('Todos');
  const [emails, setEmails] = useState<any>();

  const [modalInviteMessage, setModalInviteMessage] = useState<boolean>(false);

  const [loadingDeleteEmails, setLoadingDeleteEmails] =
    useState<boolean>(false);

  const [page, setPage] = useState<number>(1);

  const menuEntrada: any[] = [
    { title: 'Todos', icon: <IconEmail /> },
    { title: 'Favoritos', icon: <IconFavoritos /> },
    { title: 'Convites', icon: <IconConvites /> },
    { title: 'Conversas', icon: <IconConversas /> },
    { title: 'Excluidos', icon: <IconTrash size={20} /> },
  ];

  // --------------------- NEW STATES --------------------- //
  const [emailsPaginate, setEmailsPaginate] = useState<any>(null);
  const [emailsSelectedforDelete, setEmailsSelectedForDelete] = useState<
    Array<string>
  >([]);

  // --------------------- NEW USE EFFECTS --------------------- //
  useEffect(() => {
    getEmails({
      filtersArray: [],
    });
  }, [page]);

  // --------------------- NEW FUNCTIONS --------------------- //
  async function getEmails(filter?: EmailsFilter) {
    let completeFilter;

    if (filter) {
      const filterConstructor = filter?.filtersArray?.map(
        (filterOption) => `${filterOption}=true`
      );
      completeFilter = filterConstructor.toString().replaceAll(',', '&') + '&';
    }

    const apiResponse = await apiDoubts.get(
      `conversa/listar?${completeFilter ?? ''}limit=15&page=${page}`
    );
    setEmailsPaginate(null);
    setEmailsPaginate(apiResponse?.data?.data);
  }

  function handleFilterEmail(filter: string) {
    setMenuEntradaSelected(filter);
    filter = filter.toLowerCase();
    if (filter === 'todos') getEmails();
    else if (filter === 'convites') {
      getEmails({
        filtersArray: ['convite'],
      });
    } else if (filter === 'favoritos') {
      getEmails({
        filtersArray: ['favoritos'],
      });
    } else if (filter === 'conversas') {
      getEmails({
        filtersArray: ['email'],
      });
    } else if (filter === 'excluidos') {
      getEmails({
        filtersArray: ['excluidos'],
      });
    }
  }

  function selectAllEmails() {
    let selectedEmails: Array<string> = [];

    if (
      emailsSelectedforDelete.length > 0 &&
      emailsSelectedforDelete.length === emailsPaginate?.docs?.length
    ) {
      setEmailsSelectedForDelete([]);
      return;
    }

    for (let email of emailsPaginate.docs) {
      if (!selectedEmails.includes(email._id)) {
        selectedEmails.push(email._id);
      }
    }

    setEmailsSelectedForDelete(selectedEmails);
  }

  function handleSelection(id: string) {
    if (emailsSelectedforDelete.includes(id)) {
      let filterEmailSelectForDelete = emailsSelectedforDelete.filter(
        (emailId) => emailId !== id
      );
      setEmailsSelectedForDelete(filterEmailSelectForDelete);
    } else {
      setEmailsSelectedForDelete((oldValue) => [...oldValue, id]);
    }
  }

  async function deleteEmails(emailsIdToDelete: Array<string>) {
    try {
      if (!emailsIdToDelete) return;

      let promiseArrayToDelete: Array<AxiosResponse<any>> = [];

      for (let emailId of emailsIdToDelete) {
        const newPromise: AxiosResponse<any> = await apiDoubts.post(
          `/conversa/excluir`,
          {
            idConversa: emailId,
          }
        );
        promiseArrayToDelete.push(newPromise);
      }

      Promise.allSettled([promiseArrayToDelete]);

      setEmailsSelectedForDelete([]);
      setMenuEntradaSelected('Todos');
      getEmails();
    } catch (err: any) {
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  async function readConversation(conversationId: string) {
    apiDoubts
      .post(`/conversa/ler`, {
        idConversa: conversationId,
      })
      .then((response: any) => {})
      .catch((error: any) => {});
  }

  return (
    <Container>
      <ButtonNewEmail
        onClick={() => {
          setModalInviteMessage(true);
        }}
      >
        <MoreIcon color={'#fff'} />
      </ButtonNewEmail>

      <InviteMessage
        onClose={() => {
          setModalInviteMessage(false);
        }}
        openModal={modalInviteMessage}
        title="Enviar Email"
        id="ModalInviteMessage"
        scroll={false}
        reloadAllEmails={() => {
          getEmails();
        }}
      />

      <Content>
        <div className="topbar">
          <div className="areaInit">
            <div className="topBarInit">
              <Checkbox2
                name="Selecionar Tudo"
                onClick={() => {
                  selectAllEmails();
                }}
                value={
                  emailsSelectedforDelete.length ===
                    emailsPaginate?.docs?.length &&
                  emailsSelectedforDelete.length > 0
                    ? true
                    : false
                }
                placeholder="Selecionar Tudo"
              />
              {emailsSelectedforDelete.length > 0 && (
                <button
                  className="deleteEmails"
                  onClick={() => {
                    deleteEmails(emailsSelectedforDelete);
                  }}
                >
                  {loadingDeleteEmails ? (
                    <IconLoading color={'var(--branco)'} size={14} />
                  ) : (
                    <IconTrash color={'var(--branco)'} />
                  )}
                </button>
              )}
            </div>

            {emailsPaginate?.totalDocs > 0 && (
              <div className="topBarEnd">
                <p className="pages">
                  {(emailsPaginate?.prevPage == null
                    ? 1
                    : emailsPaginate?.prevPage * emailsPaginate?.limit) +
                    (emailsPaginate?.prevPage == null ? 0 : 1)}{' '}
                  - {emailsPaginate?.page * emailsPaginate?.docs.length} de{' '}
                  {emailsPaginate?.totalDocs}
                </p>

                {emailsPaginate?.hasPrevPage && (
                  <button
                    className="buttonPagination"
                    onClick={() => {
                      setPage(emailsPaginate?.prevPage);
                    }}
                  >
                    <IconArrowLeft />
                  </button>
                )}
                {emailsPaginate?.hasNextPage && (
                  <button
                    className="buttonPagination"
                    onClick={() => {
                      setPage(emailsPaginate?.nextPage);
                    }}
                  >
                    <IconArrowRight />
                  </button>
                )}
              </div>
            )}
          </div>
          <div className="areaEnd">
            {menuEntrada.map((row: any, key: number) => (
              <MenuHeader
                key={key}
                menuEntradaSelected={
                  menuEntradaSelected === row.title ? true : false
                }
                onClick={() => {
                  handleFilterEmail(row.title);
                }}
              >
                {row.icon}
                <p className="textMenuEntrada">{row.title}</p>
              </MenuHeader>
            ))}

            {menuSelected !== 'Caixa de Entrada' && (
              <MenuHeader menuEntradaSelected={true}>
                <IconEmail />
                <p className="textMenuEntrada">Todos</p>
              </MenuHeader>
            )}
          </div>
        </div>

        <div className="content">
          {emailsPaginate &&
            emailsPaginate?.docs?.length > 0 &&
            emailsPaginate.docs.map((email: any, key: number) => {
              return (
                <CardMailBox
                  key={key}
                  data={email}
                  isSelected={emailsSelectedforDelete.includes(email._id)}
                  openEmail={async () => {
                    readConversation(email?._id);
                  }}
                  select={() => {
                    handleSelection(email?._id);
                  }}
                />
              );
            })}

          {(!emailsPaginate?.docs || emailsPaginate?.docs?.length === 0) && (
            <EmptyProducts
              title="Você não tem nenhuma mensagem ainda..."
              description="Envie uma mensagem e ela aparecerá aqui"
            />
          )}
        </div>
      </Content>
    </Container>
  );
}
