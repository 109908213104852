import { IconFile } from '../Icons';
import { Container } from './styles';

interface VProps {
  placeholder: string;
  tipo: 'text' | 'file';
  change: (e: any) => void;
  file: string;
  accept?: any;
}

export default function InputFile({
  accept,
  placeholder,
  tipo,
  change,
  file,
}: VProps) {
  return (
    <Container placeholder={placeholder} type={tipo} onChange={change}>
      <div className="contents">
        <input accept={accept} type={tipo} />
        <div className="areaStart">
          <IconFile size={20} />
          <h3 className="arquivo">{file ? file : 'Arquivo...'}</h3>
        </div>
        <div className="areaEnd">
          <h3 className="selecionar">Selecionar Arquivo</h3>
        </div>
      </div>
    </Container>
  );
}
