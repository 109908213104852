import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 10px;
  width: fit-content;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(3px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  outline: none;
`;

export const TitleContainer = styled.div`
  margin-bottom: 4px;

  .label {
    font-size: 13px;
  }
`;

export const MetricsContainer = styled.div`
  .metric {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    &-value {
      font-size: 18px;
      font-weight: bolder;
    }

    &-label {
      font-size: 14px;
    }
  }
`;
