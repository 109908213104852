import { LoginCardLeft, LoginCardRight, LoginContainer } from "./styles";
import Logo from '../../assets/Logo.png'
import Screen from '../../assets/CompanyScreen.png'
import { useState } from "react";
import apiDoubts from "../../services/api";
import SyncLoader from "react-spinners/SyncLoader";
import EnterArrowIcon from "../../assets/icons/EnterArrowIcon";
import ArrowUpIcon from "../../assets/icons/ArrowUpIcon";
import ClosedEyeIcon from "../../assets/icons/ClosedEyeIcon";
import EyeIcon from "../../assets/icons/EyeIcon";

const CompanyLogin: React.FC<any> = () => {

    const [loading, setLoading] = useState<boolean>(false)
    const [DTO, setDTO] = useState({
        login: 'kaique.painel@tropa.digital',
        senha: '@Mudar123',
    })
    const [showPassword, setShowPassword] = useState<boolean>(false)

    function handleLogin(tipo: any) {
        if (tipo === 'cadastro') {
            window.location.href = "/"
        } 
        else if (tipo === 'consultor') {
            window.location.href = "/consultor"
        } 
        else if (tipo === 'admin') {
            window.location.href = "/admin"
        }
    }

    function handleOnChange(name: any, value: any) {
        const newDTO:any = DTO
            newDTO[name] = value
        setDTO({...newDTO})
    }
    
    async function handleOnSubmit(e:any)
    {
        try {
            e.preventDefault()

            if ( loading === true ) throw new Error("Aguarde enquanto processamos seus dados.");             

            setLoading(true)

            if (!DTO.login) throw new Error("Preencha seu email antes de prosseguir.");
            if (!DTO.senha) throw new Error("Preencha sua senha antes de prosseguir.");

            const response = await apiDoubts.post('login', {
                login: DTO.login,
                senha: DTO.senha,                
            })
            
            if ( response.data.status === 'error' ) throw response.data.message
            window.localStorage.setItem('token', response.data.data.token)
            setLoading(false);
            handleLogin(response.data.data.tipo);
            
        } catch ( err: any ){
            alert(err)
            setLoading(false)
        }

    }

    const togglePassword = () => {
        setShowPassword(!showPassword);
    };
    
    return (
        <LoginContainer>

            <LoginCardLeft>
                <div className="logo">
                    <img src={Logo} alt="Logo Doubts no lado esquerdo" />
                </div>

                <div className="middle">
                    <div className="text">
                        <h1>Crie o canal de sua empresa e apresente produtos e serviços.</h1>
                        <p>Monetize o canal de sua empresa através de publicidade online e muito mais.</p>
                    </div>

                    <div className="input">
                        <form onSubmit={handleOnSubmit}>

                            <input name="Email" onChange={(e) => handleOnChange('login', e.target.value)} value={DTO.login} placeholder="Username" />

                            
                            <div className="pass">
                                <input className="input-icon" name="Senha" type={showPassword ? "text" : "password"} value={DTO.senha} onChange={(e) => handleOnChange('senha', e.target.value)} placeholder="Senha" />
                                {
                                    showPassword
                                        ? <div onClick={togglePassword}><ClosedEyeIcon /></div>
                                        : <div onClick={togglePassword}><EyeIcon /></div>
                                }
                            </div>
                            
                            <button>
                                {
                                    loading === true
                                        ?
                                        <SyncLoader color={"#0FBABA"} loading={loading} size={10} margin={10} />
                                        :
                                        'Entrar'
                                }
                                <EnterArrowIcon />
                            </button>
                        </form>
                        <p>Venha para Doubts e veja tudo acontecer!</p>
                    </div>
                </div>

                <div className="footer">
                    <p>Já tem uma conta?</p>
                    <a href="/">
                        Acesse aqui
                        <span><ArrowUpIcon /></span>
                    </a>       
                </div>
            </LoginCardLeft>

            <LoginCardRight style={{ backgroundImage: `url(${Screen})` }} />
                

        </LoginContainer>
    )
}

export default CompanyLogin;