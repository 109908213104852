import styled, { css } from 'styled-components';

interface Props {
  compact: any;
}

export const Container = styled.div<Props>`
  ::-webkit-scrollbar {
    display: none;
    position: relative;
  }
  overflow: scroll;
  grid-area: menu;
  width: 235px;
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--purple2);
  color: var(--background);
  z-index: 3;
  position: fixed;
  width: 100%;

  ${(props) =>
    props.compact &&
    css`
      min-width: 110px;
      max-width: 110px;
      .userInfo {
        h2 {
          word-break: break-all;
          padding: 10px;
        }
        span {
          display: flex;
          flex-direction: column;
        }
      }
    `}

  @media (max-width: 425px) {
    transition: all 0.2s;
    ${(props) =>
      props.compact &&
      css`
        margin-left: 0;
      `}
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin-top: 30px;
  .logout {
    margin-top: 10px;
    font-size: 11px;
    cursor: pointer;
    text-decoration: underline;
  }
  > span {
    font-size: 11px;
    font-weight: 900;
    line-height: 13px;
    color: var(--purple7);
    text-transform: uppercase;
    text-align: center;
  }

  .userInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    margin-top: 17px;

    h2 {
      font-size: 14px;
      font-weight: 700;
      color: var(--background);
      margin-top: 16px;
      text-align: center;
    }

    span {
      font-size: 12px;
      font-weight: 400;
      color: var(--background);
      margin-top: 4px;
      text-align: center;
    }
  }
  .userImg {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 5px;

    background-size: cover;
    background-position: center;

    width: 70px;
    height: 70px;
  }
`;

export const SidebarNav = styled.div`
  width: 100%;
`;

export const NavbarLink = styled.div<Props>`
  margin-top: 30px;

  .exploreWrapper {
    font-size: 18px;
    padding: 0px 26px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  h2 {
    font-size: 12px;
    font-weight: 900;
    line-height: 12px;
    color: var(--green);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    opacity: 1;
    transition: all 0.2s;
  }

  ${(props) =>
    props.compact &&
    css`
      h2 {
        margin-left: 14px;
        margin-right: 10px;
      }
    `}
`;

export const Separator = styled.div`
  width: 100%;
  height: 2px;
  background: var(--purple3);

  margin: 30px 0;
`;

export const Footer = styled.div<Props>`
  display: block;
  padding-left: 22px;
  margin-right: auto;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;

  a {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: auto;
    text-decoration: none;
  }

  h3 {
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    color: var(--purple6);
    text-transform: uppercase;
  }

  span {
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    color: var(--purple6);
    text-transform: uppercase;
    margin: 5px 0;
  }

  ${(props) =>
    props.compact &&
    css`
      a {
        text-align: center;
      }
    `}
`;

export const Divisor = styled.div`
  height: 1px;
  width: calc(100% - 60px);
  background-color: var(--paleta2A);
  margin: 30px;
`;
