import styled from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  .generic {
    width: 100%;
    display: flex;
    gap: 20px;
    margin-top: 20px;

    .content {
      flex: 1;
      .bannerMobile {
        display: none;
        .bannerTop {
          display: none;
          cursor: pointer;
          width: 100%;
          height: 150px;
          background-color: var(--background2);
          background-position: center;
          background-size: cover;
          border-radius: 10px;
          margin-bottom: 15px;
          margin-top: -15px;

          @media (max-width: 1056px) {
            display: flex;
          }
        }
      }
    }
    .sideRigth {
      min-width: 300px;
      .areaBanner {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }

  .canal {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 20px;

    .DescriptionConsultor {
      width: 100%;
      gap: 20px;

      display: flex;
      justify-content: space-between;

      .banner {
        min-width: 300px;
        max-width: 30%;
        height: 227px;
        background-size: cover;
        background-position: center;
        border-radius: 10px;

        margin-top: -60px;
      }
    }

    .contentCanal {
      width: 100%;
    }
  }

  @media (max-width: 1050px) {
    .generic {
      flex-direction: column;
      .content {
        .bannerMobile {
          display: unset;
        }
      }
      .sideRigth {
        .areaBanner {
          display: none;
          .bannerTop {
            display: none;
          }
          .bannerDown {
            display: none;
          }
        }
      }
    }
  }
`;
