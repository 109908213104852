import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Banner from '../../components/Banner';
import ButttonDefault from '../../components/ButtonDefault';
import InputFile from '../../components/ButtonFile';
import AddimageCirculo from '../../components/ButtonFile/AddImagemCirculo';
import Checkbox from '../../components/Checkbox/Checkbox';
import SelectDefaultSearch from '../../components/Form/SelectDefaultSearch';
import {
  IconArrowRight,
  IconCases,
  IconDestaque,
  IconInformacoes,
  IconMarca,
  IconTrash,
} from '../../components/Icons';
import UserContext from '../../contexts/UserContext';
import apiDoubts from '../../services/api';
import InformacoesPessoais from './InformacoesPessoais';
import { Button, Container, Left, Right, Subtitle } from './styles';
import bucket from '../../services/bucket';
import Empresas from '../Agencia/Config/Empresas';
import Agencias from '../Agencia/Config/Agencias';

export default function ConsultorSettings() {
  const { user } = useContext(UserContext);

  const [selected, setSelected] = useState<string>('destaques');
  const [postagemPrincipal, setPostagemPrincipal] = useState<any>();
  const [optionsSelect, setOptionsSelect] = useState<any[]>([]);
  const [DTO, setDTO] = useState<any>({});
  const [banner, setBanner] = useState<any>();

  useEffect(() => {
    getPostagem(DTO?.postagemPrincipal);
  }, [DTO?.postagemPrincipal]);

  function handleOnChange(name: any, value: any) {
    const newDTO: any = DTO;
    newDTO[name] = value;
    setDTO({ ...newDTO });
  }

  useEffect(() => {
    if (user) {
      getConsultor();
      getPostagensSelect();
    }
  }, [user]);

  useEffect(() => {
    console.log(DTO);
  }, [DTO]);

  useEffect(() => {
    getBanner();
  }, []);

  async function getConsultor() {
    apiDoubts.get(`/consultor/get-by-id/${user.id}`).then((response: any) => {
      console.log(response.data.data[0]);
      handleConsultor(response?.data?.data[0]);
      getPostagem(response?.data?.data[0]?.postagemPrincipal);
    });
  }

  function handleConsultor(data: any) {
    let newDTO = {
      login: data?.login,
      nickName: data?.nickName,
      foto: data?.foto,
      termosUso: true,
      disponivelWhatsApp: data?.disponivelWhatsApp,
      disponivelEmail: data?.disponivelEmail,
      disponivelConferencia: data?.disponivelConferencia,
      visivelOportunidades: data?.visivelOportunidades,
      postagemPrincipal: data?.postagemPrincipal,
      valor10: data?.valor10,
      valor20: data?.valor20,
      valor30: data?.valor30,
      descricaoCanal: data?.descricaoCanal,
      capa: data?.capa
        ? data?.capa
        : 'https://images.unsplash.com/photo-1542273917363-3b1817f69a2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=874&q=80',
    };
    setDTO(newDTO);
  }

  async function getPostagem(id: string) {
    apiDoubts
      .get(`/postagens/getPostagem-logado/${id}`)
      .then((response: any) => {
        setPostagemPrincipal(response?.data?.data);
      });
  }

  async function getPostagensSelect() {
    apiDoubts
      .get(`/postagens/getPostagemConsultor/${user.id}`)
      .then((response: any) => {
        handleOptions(response.data.data);
      });
  }

  function handleOptions(data: any) {
    let newData: any[] = [];
    data.map((row: any) => {
      let option = {
        value: row.id,
        label: row.titulo,
      };
      newData.push(option);
    });
    setOptionsSelect(newData);
  }

  function valueSelect() {
    let newOption = optionsSelect.filter((value: any) => {
      return value.value === DTO?.postagemPrincipal;
    });
  }

  async function setCapa(file: any) {
    let formData = new FormData();
    formData.append('file', file.target.files[0]);
    const response: any = await bucket.post(`/upload`, formData);
    handleOnChange('capa', response.data.arquivo);
    console.log('capa', response.data.url);
  }

  async function setAvatar(file: any) {
    let formData = new FormData();
    formData.append('file', file.target.files[0]);
    const response: any = await bucket.post(`/upload`, formData);
    handleOnChange('foto', response.data.arquivo);
    console.log('foto', response.data.url);
  }

  function handleValue(pos: string, value: string) {
    let newValue = value.replace('R$ ', '');
    handleOnChange(pos, newValue);
  }

  function checkInfos() {
    console.log('check infos');
    try {
      if (!DTO.capa) throw new Error('Escolha uma capa para o seu canal');
      if (!DTO.foto) throw new Error('Escolha foto de perfil para o seu canal');
      if (!DTO.descricaoCanal.length)
        throw new Error('Digite uma descrição para o seu canal');
      if (!DTO.valor10)
        throw new Error('Digite valor para a sua consultoria de 10 minutos');
      if (!DTO.valor20)
        throw new Error('Digite valor para a sua consultoria de 20 minutos');
      if (!DTO.valor30)
        throw new Error('Digite valor para a sua consultoria de 30 minutos');
      handleUpdateConsultor();
    } catch (err: any) {
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  async function handleUpdateConsultor() {
    console.log('update consultor');
    await apiDoubts
      .put(`/consultor/editar/${user.id}`, DTO)
      .then((response: any) => {
        setTimeout(() => {
          getConsultor();
        }, 1000);
        toast.success('Perfil atualizado com sucesso', {
          className: 'toast-sucess',
          theme: 'colored',
        });
      })
      .catch((error: any) => {
        toast.error(error.message, {
          className: 'toast-error',
          theme: 'colored',
        });
      });
  }

  async function getBanner() {
    apiDoubts.get(`/banners?tipo=3&limit=1`).then((response: any) => {
      setBanner(response.data.data);
    });
  }

  return (
    <Container>
      <div className="header">
        <h3 className="title">Personalização do Canal</h3>
        <Subtitle className="subtitle">
          Personalize as informações visuais e apresentação do seu canal
        </Subtitle>

        <div className="buttons">
          <Button
            selected={selected === 'destaques'}
            onClick={() => {
              setSelected('destaques');
            }}
          >
            <IconDestaque />
            <p className="textButton">DESTAQUES</p>
          </Button>

          <Button
            selected={selected === 'informacoes'}
            onClick={() => {
              setSelected('informacoes');
            }}
          >
            <IconInformacoes color={'#069CD1'} />
            <p className="textButton">INFORMAÇÕES</p>
          </Button>
        </div>
        <div className="element" />
      </div>

      <div className="content">
        <Left buttonDeleteOn={!!DTO.foto} buttonDeleteOnCapa={!!DTO.capa}>
          {selected === 'destaques' && (
            <>
              {optionsSelect.length > 0 ? (
                <div className="videoApresentacao">
                  <div className="areaLeft">
                    <h3 className="videoApresentacaoText">
                      Video de apresentação
                    </h3>
                    <div className="responsiveBanner">
                      <div
                        className="thumb"
                        style={{
                          backgroundImage: `url(${postagemPrincipal?.thumbnail})`,
                        }}
                      ></div>
                      <div className="bannerResp">
                        {banner && (
                          <Banner peso="1" position="1" typeBanner="322x227" />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="areaRight">
                    <h3 className="videoTitle">{postagemPrincipal?.titulo}</h3>
                    <h4 className="visualizacoes">
                      {postagemPrincipal?.qtdVisualizacoes} visualizações -{' '}
                      {moment(postagemPrincipal?.data)
                        .locale('pt-br')
                        .fromNow()}
                    </h4>
                    <SelectDefaultSearch
                      value={valueSelect}
                      onChange={(e) => {
                        handleOnChange('postagemPrincipal', e.value);
                      }}
                      options={optionsSelect}
                      key={'select videos'}
                      tipo={'2'}
                    />
                  </div>
                </div>
              ) : (
                <div className="postNewVideo">
                  Poste um vídeo para poder selecioná-lo como vídeo de
                  apresentação
                </div>
              )}

              <div className="images">
                <h3 className="title">Avatar e capa do canal</h3>
                <div className="content">
                  <div
                    className="capa"
                    style={{ backgroundImage: `url(${DTO.capa})` }}
                  >
                    <div className="addAvatar">
                      {DTO.capa ? (
                        <button
                          className="removeImage"
                          onClick={() => {
                            handleOnChange('capa', '');
                          }}
                        >
                          <IconTrash />
                        </button>
                      ) : (
                        <button className="addImage">
                          <AddimageCirculo
                            change={(e: any) => {
                              console.log(e);
                              setCapa(e);
                            }}
                            file={'Foto de capa'}
                            tipo={'file'}
                            placeholder={'foto de perfil'}
                          />
                        </button>
                      )}
                    </div>
                  </div>
                  <div
                    className="avatar"
                    style={{ backgroundImage: `url(${DTO.foto})` }}
                  >
                    <div className="addAvatar">
                      {DTO.foto ? (
                        <button
                          className="removeImage"
                          onClick={() => {
                            handleOnChange('foto', '');
                          }}
                        >
                          <IconTrash />
                        </button>
                      ) : (
                        <button className="addImage">
                          <AddimageCirculo
                            change={(e: any) => {
                              setAvatar(e);
                            }}
                            file={'Foto de capa'}
                            tipo={'file'}
                            placeholder={'foto de perfil'}
                          />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="descricaoCanal">
                <h3 className="descricaoText">Descrição</h3>
                <textarea
                  value={DTO.descricaoCanal}
                  className="comment"
                  onChange={(e) => {
                    handleOnChange('descricaoCanal', e.target.value);
                  }}
                ></textarea>
              </div>

              <div className="disponibilidade">
                <div className="headerDisponibilidade">
                  <h3 className="disponibilidadeText">Disponibilidade</h3>
                  <h3 className="vejaMais">
                    veja mais <IconArrowRight />
                  </h3>
                </div>
                <div className="areaDisponibilidade">
                  <div className="cardDisponibilidade">
                    <Checkbox
                      name="disponivelConferencia"
                      value={DTO.disponivelConferencia}
                      onClick={(e: any) => {
                        handleOnChange(
                          'disponivelConferencia',
                          !DTO.disponivelConferencia
                        );
                      }}
                    />
                    <h4 className="textCardDisponibilidade">Conferência</h4>
                  </div>
                  <div className="cardDisponibilidade">
                    <Checkbox
                      name="disponivelWhatsApp"
                      value={DTO.disponivelWhatsApp}
                      onClick={(e: any) => {
                        handleOnChange(
                          'disponivelWhatsApp',
                          !DTO.disponivelWhatsApp
                        );
                      }}
                    />
                    <h4 className="textCardDisponibilidade">WhatsApp</h4>
                  </div>
                  <div className="cardDisponibilidade">
                    <Checkbox
                      name="disponivelEmail"
                      value={DTO.disponivelEmail}
                      onClick={(e: any) => {
                        handleOnChange('disponivelEmail', !DTO.disponivelEmail);
                      }}
                    />
                    <h4 className="textCardDisponibilidade">E-mail</h4>
                  </div>
                  <div className="cardDisponibilidade">
                    <Checkbox
                      name="disponível oportunidade"
                      value={DTO.visivelOportunidades}
                      onClick={(e: any) => {
                        handleOnChange(
                          'visivelOportunidades',
                          !DTO.visivelOportunidades
                        );
                      }}
                    />
                    <h4 className="textCardDisponibilidade">
                      Menu de oportunidades
                    </h4>
                  </div>
                </div>
              </div>

              <div
                className="valores"
                style={{
                  display: 'none',
                }}
              >
                <div className="headerValores">
                  <h3 className="textValores">Valor da sua consultoria</h3>
                  <h3 className="vejaMais">
                    veja mais <IconArrowRight />
                  </h3>
                </div>
                <div className="areaValores">
                  <div className="cardValor">
                    <h3 className="left">30 Minutos</h3>
                    <input
                      className="sideRight"
                      type="string"
                      value={`R$ ${DTO.valor30}`}
                      onChange={(e: any) => {
                        handleValue('valor30', e.target.value);
                      }}
                    />
                  </div>
                  {/* <div className="cardValor">
                    <h3 className="left">20 Minutos</h3>
                    <input
                      className="sideRight"
                      type="string"
                      value={`R$ ${DTO.valor20}`}
                      onChange={(e: any) => {
                        handleValue('valor20', e.target.value);
                      }}
                    />
                  </div>
                  <div className="cardValor">
                    <h3 className="left">30 Minutos</h3>
                    <input
                      className="sideRight"
                      type="string"
                      value={`R$ ${DTO.valor30}`}
                      onChange={(e: any) => {
                        handleValue('valor30', e.target.value);
                      }}
                    />
                  </div> */}
                </div>
              </div>

              <div className="buttons">
                <button className="cancelar" onClick={getConsultor}>
                  Cancelar
                </button>
                <ButttonDefault
                  title={'Salvar'}
                  tipo="6"
                  onClick={() => {
                    checkInfos();
                  }}
                />
              </div>
            </>
          )}

          {selected === 'informacoes' && <InformacoesPessoais />}

          {selected === 'empresas' && <Empresas />}

          {selected === 'agencias' && <Agencias />}
        </Left>
      </div>
    </Container>
  );
}
