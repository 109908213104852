import { Container } from './styles';

interface dataProps {
  consultorData: any;
  navigateToPage: () => any;
}

export default function CardUserSearch({
  consultorData,
  navigateToPage,
}: dataProps) {
  return (
    <Container onClick={() => navigateToPage()}>
      <div className="contentCardProfileConsultor">
        <div
          className="imageProfileConsultor"
          style={{
            backgroundImage: `url(${
              consultorData?.foto ??
              'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
            })`,
          }}
        ></div>
        <p className="nameCardConsultorProfile">{consultorData?.nickName}</p>
      </div>
    </Container>
  );
}
