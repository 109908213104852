/* eslint-disable no-lone-blocks */
import { useCallback, useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';

import { Container } from './styles';
import {
  Value,
  CalendarStatic,
  HoursStatic,
  IPropsHoursStaticData,
} from '../../components/Calendar';
import { formatarDataString } from '../../utils/format';
import ButttonDefault from '../../components/ButtonDefault';
import moment from 'moment';
import ModalAlert from '../../components/ModalAlert';
import { ContainerConsultoriaRequest } from '../../components/Consultoria/Containers';
import apiDoubts from '../../services/api';
import Checkbox from '../../components/Checkbox/Checkbox';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { encodeConsultoria } from '../Live/Consultoria';

export const COLOR_CANCELADO = 'var(--offline)';
export const COLOR_OCUPADO = 'var(--paleta2A)';
export const COLOR_SOLICITADO = 'var(--paleta1A)';

export default function AgendaUsuario() {
  const history = useHistory();
  const [filterDate, setFilterDate] = useState<Date>(new Date());
  const refScroll = useRef(null);

  const [loadingEvents, setLoadingEvents] = useState(true);
  const [dataEvents, setDataEvents] = useState<IPropsHoursStaticData[]>([]);
  const [showOnlyEvents, setShowOnlyEvents] = useState(false);

  const [loadingButtonModal, setLoadingButtonModal] = useState(false);
  const [showModalCancel, setShowModalCancel] = useState<any>({
    type: '',
    param: '',
    show: false,
  });

  const getEvents = useCallback(async () => {
    setLoadingEvents(true);

    const response = await apiDoubts.get(
      `consultoria-usuario?data=${moment(filterDate).format(
        'YYYY-MM-DD'
      )}&tipo=gratis`
    );

    let events: IPropsHoursStaticData[] = [];

    console.log('response.data.data', response.data.data);

    response.data.data.forEach((item: any) => {
      //requisições
      if (item?.status === 'Pendente') {
        events.push({
          color: COLOR_SOLICITADO,
          hora: moment(item.data).format('HH:mm'),
          title: 'Solicitação de consultoria com ' + item.consultor?.nickName,
          reference: {
            type: 'solicitacao',
            param: moment(item.data).format('YYYY-MM-DD HH:mm'),
            ...item,
          },
        });
      } else {
        events.push({
          color: item.status === 'Cancelada' ? COLOR_CANCELADO : COLOR_OCUPADO,
          hora: moment(item.data).format('HH:mm'),
          title:
            item.status === 'Cancelada'
              ? `Consultoria com  + ${
                  item?.consultor.nickName ?? 'Nome indisponivel'
                } cancelada`
              : 'Consultoria confirmada com ' + item?.consultor.nickName ??
                'Nome indisponivel',
          reference: {
            type:
              item.status === 'Aceita' || item.status === 'Expirada'
                ? 'consultoria'
                : 'cancelamento',
            param: moment(item.data).format('YYYY-MM-DD HH:mm'),
            ...item,
          },
        });
      }
    });

    setDataEvents([...events]);
    setLoadingEvents(false);
  }, [filterDate]);

  useEffect(() => {
    if (filterDate) getEvents();
  }, [filterDate, getEvents]);

  const handleCancel = async ({ param }: { param: string }) => {
    setLoadingButtonModal(true);
    await apiDoubts.delete('/cancelar-consultorias', {
      data: {
        horarios: [param],
      },
    });
    setDataEvents([
      ...dataEvents.filter((obj) => obj.hora !== moment(param).format('HH:mm')),
    ]);
    setShowModalCancel({ show: false, type: '', param: '' });
    setLoadingButtonModal(false);
  };

  return (
    <Container>
      <h1>Agenda</h1>
      <div className="row">
        <div className="sidebar">
          <CalendarStatic value={filterDate} onChange={setFilterDate} />
          {dataEvents.filter((obj) => obj.reference.type === 'solicitacao')
            .length > 0 && <h3>Solicitações pendentes</h3>}
          <div className="overflow-requests">
            {dataEvents
              .filter((obj) => obj.reference.type === 'solicitacao')
              .map((row) => (
                <ContainerConsultoriaRequest
                  textCancel="Cancelar solicitação"
                  onCancel={() => {
                    handleCancel({
                      param:
                        moment(filterDate).format('YYYY-MM-DD') +
                        ' ' +
                        row.hora +
                        ':00',
                    });
                  }}
                  styles={{
                    style: {
                      border: 'none',
                    },
                  }}
                  toggleOpened={true}
                  profile={{
                    image: row.reference?.consultor?.foto,
                    name: row.reference?.consultor?.nickName,
                  }}
                  item={{
                    date: moment(row.reference.data).format('YYYY-MM-DD HH:mm'),
                    title: row.reference.assunto,
                    description: row.reference.comentario,
                    type: row.reference.tipo === 'pago' ? 'Paga' : 'Grátis',
                  }}
                />
              ))}
          </div>
        </div>
        <div className="content">
          <div className="head">
            <h3>{formatarDataString(filterDate)}</h3>
            <div className="buttons">
              <Checkbox
                onClick={() => setShowOnlyEvents(!showOnlyEvents)}
                value={showOnlyEvents}
                name="somente_eventos"
                placeholder="Ocultar horarios vagos"
              />
            </div>
          </div>

          <div id="overflow-hours" ref={refScroll}>
            <HoursStatic
              hiddenDisable={showOnlyEvents}
              loading={loadingEvents}
              refScroll={refScroll}
              onClickEvent={(e) => {
                setShowModalCancel({ ...e.reference, show: true });
              }}
              filterDate={filterDate}
              data={dataEvents}
            />
          </div>
        </div>
      </div>

      {/***/}

      <ModalAlert
        hiddenFooter={true}
        id="cancelar-consultoria"
        hiddenIcon={true}
        prosseguir={() => {}}
        onClose={() => {
          setShowModalCancel({
            type: 'consultoria',
            param: '',
            show: false,
          });
        }}
        openModal={
          showModalCancel.type === 'consultoria' && showModalCancel.show
        }
      >
        {showModalCancel.type === 'consultoria' && showModalCancel.show && (
          <ContainerConsultoriaRequest
            styles={{
              style: {
                border: 'none',
              },
            }}
            toggleOpened={true}
            profile={{
              image: showModalCancel.consultor.foto,
              name: showModalCancel.consultor.nickName,
            }}
            item={{
              date: moment(showModalCancel.data).format('YYYY-MM-DD HH:mm'),
              title: showModalCancel.assunto,
              description: showModalCancel.comentario,
              type: showModalCancel.tipo === 'pago' ? 'Paga' : 'Grátis',
            }}
          />
        )}

        <a
          className="later-link"
          style={{
            color: 'var(--paleta2A)',
          }}
          onClick={() =>
            history.push(
              `/consultoria/${encodeConsultoria({
                consultoriaId: showModalCancel.id,
                start: moment(showModalCancel.data).format('YYYY-MM-DD HH:mm'),
                end: moment(showModalCancel.data)
                  .add(10, 'minutes')
                  .format('YYYY-MM-DD HH:mm'),
              })}`
            )
          }
        >
          Ir para a consultoria
        </a>
        <a
          className="later-link"
          onClick={() =>
            setShowModalCancel({
              type: 'consultoria',
              param: '',
              show: false,
            })
          }
        >
          Fechar
        </a>
      </ModalAlert>

      <ModalAlert
        id="conferir-solicitacao"
        hiddenFooter={true}
        hiddenIcon={true}
        prosseguir={() => {}}
        onClose={() => {
          setShowModalCancel({
            type: 'solicitacao',
            param: '',
            show: false,
          });
        }}
        openModal={
          showModalCancel.type === 'solicitacao' && showModalCancel.show
        }
      >
        {showModalCancel.disabled && (
          <p>Você não confirmou a consultoria a tempo.</p>
        )}
        {showModalCancel.type === 'solicitacao' && showModalCancel.show && (
          <ContainerConsultoriaRequest
            textCancel="Cancelar solicitação"
            onCancel={() => {
              handleCancel({
                param: showModalCancel.param + ':00',
              });
            }}
            styles={{
              style: {
                border: 'none',
              },
            }}
            toggleOpened={true}
            profile={{
              image: showModalCancel.consultor?.foto,
              name: showModalCancel.consultor?.nickName,
            }}
            item={{
              date: moment(showModalCancel.data).format('YYYY-MM-DD HH:mm'),
              title: showModalCancel.assunto,
              description: showModalCancel.comentario,
              type: showModalCancel.tipo === 'pago' ? 'Paga' : 'Grátis',
            }}
          />
        )}
        <a
          className="later-link"
          onClick={() =>
            setShowModalCancel({
              type: 'solicitacao',
              param: '',
              show: false,
            })
          }
        >
          {showModalCancel.disabled ? 'Fechar' : 'Aguardar'}
        </a>
      </ModalAlert>
    </Container>
  );
}
