/* eslint-disable react-hooks/exhaustive-deps */
import ModalForm from '../../../components/Cadastrar/ModalForm';
import Inputdefault from '../../../components/Inputs/InputDefault';
import { IStepsProps } from './types';

import * as S from './styles';
import SelectDefaultCadastrar from '../../../components/Cadastrar/SelectDefaultCadastrar';
import ButttonDefault from '../../../components/ButtonDefault';
import StepsInSignUp from '../../../components/Cadastrar/StepsInSignUp';
import apiDoubts from '../../../services/api';
import { useEffect, useState } from 'react';
import { formatAgencia, formatBankAccount } from '../../../utils/format';

export default function StepThree({
  nextStep,
  onChangeField,
  DTO,
  prevStep,
  submitForm,
}: IStepsProps) {
  const [bankData, setBankData] = useState<Array<any>>([]);

  useEffect(() => {
    if (DTO?.tipo.value === 'CNPJ') {
      getAllBanks();
    }
  }, [DTO]);

  async function getAllBanks() {
    await apiDoubts
      .get(`getAllBanks`)
      .then((response: any) => {
        handleBanksData(response.data.data);
      })
      .catch((error: any) => {
        console.error(error.message);
      });
  }

  function handleBanksData(banksArray: Array<any>) {
    let newBankArrayFormated: Array<any> = [];

    banksArray.forEach((rowBank: any) => {
      let newPositionArray = {
        label: rowBank.value + ' - ' + rowBank.label,
        value: rowBank.value + rowBank.label,
      };
      newBankArrayFormated.push(newPositionArray);
    });
    setBankData(newBankArrayFormated);
  }

  return (
    <S.ContainerStepThree>
      <ModalForm>
        <StepsInSignUp currentStep={4} totalSteps={4} />

        <form
          className="signUpForm"
          onSubmit={(e: any) => {
            e.preventDefault();
            submitForm();
          }}
        >
          <span className="inputField">
            <label>Banco (opcional)</label>
            <SelectDefaultCadastrar
              onChange={(e: any) => {
                onChangeField({ position: 'banco', value: e });
              }}
              options={bankData}
              value={DTO?.banco}
            />
          </span>

          <span className="inputField">
            <label>Tipo de conta (opcional)</label>
            <SelectDefaultCadastrar
              onChange={(e: any) => {
                onChangeField({ position: 'tipo_conta', value: e });
              }}
              options={[
                { label: 'Conta Corrente', value: 'corrente' },
                { label: 'Conta Poupança', value: 'poupança' },
                { label: 'Conta Salário', value: 'salario' },
              ]}
              value={DTO?.tipo_conta}
            />
          </span>

          <span className="inputField">
            <label>Agência (opcional)</label>
            <Inputdefault
              value={DTO?.agencia}
              color="transparent"
              tipo="text"
              placeholder="9999"
              change={(e: React.ChangeEvent<HTMLInputElement>) => {
                const agenciaFormatted = formatAgencia(e.target.value);
                onChangeField({
                  position: 'agencia',
                  value: agenciaFormatted,
                });
              }}
            />
          </span>

          <span className="inputField">
            <label>Número da conta (opcional)</label>
            <Inputdefault
              value={DTO.numeroConta}
              color="transparent"
              tipo="text"
              placeholder="99999-99"
              change={(e: React.ChangeEvent<HTMLInputElement>) => {
                const bankAccount = formatBankAccount(e.target.value)
                onChangeField({
                  position: 'numeroConta',
                  value: bankAccount,
                });
              }}
            />
          </span>

          <span className="inputField">
            <label>PIX (opcional)</label>
            <Inputdefault
              color="transparent"
              tipo="text"
              placeholder="99999999999"
              change={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChangeField({
                  position: 'nickname',
                  value: e.target.value,
                });
              }}
            />
          </span>

          <div className="twoButtons">
            <ButttonDefault
              tipo="14"
              onClick={() => {
                prevStep();
              }}
              title="Voltar"
              hover="pouco"
            />

            <ButttonDefault
              type="submit"
              tipo={'13'}
              title="Concluir Cadastro"
              hover="pouco"
            />
          </div>
        </form>
      </ModalForm>
    </S.ContainerStepThree>
  );
}
