import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    .list {
        display: flex;
        overflow: hidden;
        max-width: 100%;
        margin-right: 10px;
        position: relative;
        &:after {
            content: '';
            width: 40px;
            height: 39px;
            position: absolute;
            right: 0px;
            background: linear-gradient(270deg, #FAFAFF 0%, rgba(250, 250, 255, 0) 100%);
        }
        .move-slider {
            display: flex;
            transition: all .5s;
        }
        button {
            height: 40px;
            padding: 0px 15px;
            background: transparent;
            border: none;
            border-bottom: 1px solid var(--paleta1B);
            color: var(--paleta1B);
            white-space: nowrap;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 14px;
            transition: all .2s;
            &.active {
                border-bottom: 4px solid var(--paleta1B);
                font-weight: 700;
            }
        }
        
    }
    .controls {
        display: flex;
        align-items: center;
        button {
            background: transparent;
            border: none;
            outline: none;
            padding: 0px;
            display: flex;
            margin-left: 2px;
            transition: all .2s;
            &:hover {
                transform: scale(1.2)
            }
        }
    }

    @media (max-width: 800px){
        .list{
            .move-slider{
                overflow-x: scroll;
                &::-webkit-scrollbar{
                    display: none;
                }
            }
        }
        .controls{
            display: none;
        }
    }
    
`