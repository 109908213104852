import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Container } from './styles';
import apiDoubts from '../../../services/api';
import UserContext from '../../../contexts/UserContext';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import ComentariosCard from '../../../components/ComentarioCard';
import ComentarAreaConsultoria from '../../../components/ComentarAreaConsultoria';
import moment from 'moment';
import LottieAnimation from '../../../components/Lootie';
import ButttonDefault from '../../../components/ButtonDefault';

import { useBeforeunload } from 'react-beforeunload';
import { toast } from 'react-toastify';

export default function LiveConsultoria() {
  const [fullscreen, setFullScreen] = useState<boolean>(false);
  const [consultoria, setConsultoria] = useState<any>();
  const [consultoriaComments, setConsultoriaComments] = useState<Array<any>>(
    []
  );

  const [isConsultancieTime, setIsConsultancieTime] = useState<boolean>(true);

  const params: any = useParams();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const inicio = searchParams.get('inicio');
  const fim = searchParams.get('fim');
  const id = searchParams.get('id');

  const { user } = useContext(UserContext);

  const handle = useFullScreenHandle();

  const history = useHistory();

  var commentInterval: any;

  // pega quando o usuário saiu da página
  useBeforeunload(() => {
    clearInterval(commentInterval);
  });

  useEffect(() => {
    getConsultoriaParameters();
  }, []);

  console.log(params)

  useEffect(() => {
    if (consultoria) {
      handlePermissionPage();
      getCommentsConsultoria();
    }
  }, [consultoria]);

  async function getConsultoriaParameters() {
    try {
      await apiDoubts
        .get(
          `consultoriagratis-id/${params?.id}}`
        )
        .then((response: any) => {
          if (response?.data?.data[0]?.length == 0) {
            history.push('/');
          } else if ( response.data.message === 'Nenhuma consultoria encontrada' ){
            history.push('/')
          }else {
            console.log(response.data.data);

            let consultoriaAtual = response?.data?.data?.filter((row: any) => {
              console.log(row?.id, params?.id);
              if (row?.id === params?.id) {
                return row;
              }
            });

            setConsultoria(response.data.data[0]);
          }
        });
    } catch (error) {
      // history.push('/');
      console.log(error);
    }
  }

  function handleFullScreen() {
    if (fullscreen) {
      setFullScreen(false);
      handle.exit();
    } else {
      handle.enter();
      setFullScreen(true);
    }
  }

  useEffect(() => {
    console.log('criou evento de escutar');
    window?.addEventListener('beforeunload', function () {
      console.log('usuário saindo da página');
    });
  }, []);

  async function getCommentsConsultoria() {
    try {
      const response = await apiDoubts.get(
        `comentarios-consultoria?consultoriaId=${consultoria?.id}`
      );
      setConsultoriaComments(response?.data?.data);
    } catch (error: any) {}

    clearInterval(commentInterval);
    commentInterval = setInterval(async () => {
      try {
        const response = await apiDoubts.get(
          `comentarios-consultoria?consultoriaId=${consultoria?.id}`
        );
        setConsultoriaComments(response?.data?.data);
      } catch (error: any) {}
    }, 5000);
  }

  function handlePermissionPage() {
    if (
      !inicio ||
      !fim ||
      (consultoria?.cadastroId !== user.id &&
        consultoria?.consultorId !== user.id)
    ) {
      console.log('1 parametro');
      clearInterval(commentInterval);
      history.push('/');
      return;
    } else if (moment(moment(new Date()).format()).isAfter(fim)) {
      toast.success('O horário da sua consultoria acabou', {
        className: 'toast-sucess',
        theme: 'colored',
      });
      history.push('/');
      return;
    } else if (moment(moment(new Date()).format()).isBefore(inicio)) {
      console.log('ainda não começou');
      setIsConsultancieTime(false);
    } else {
      setIsConsultancieTime(true);
    }
    const intervalPermission = setInterval(() => {
      if (
        !inicio ||
        !fim ||
        (consultoria?.cadastroId !== user.id &&
          consultoria?.consultorId !== user.id)
      ) {
        console.log('primeira validação');
        clearInterval(intervalPermission);
        history.push('/');
      } else if (moment(moment(new Date()).format()).isAfter(fim)) {
        console.log('ja terminou');
        clearInterval(intervalPermission);
        toast.success('O horário da sua consultoria acabou', {
          className: 'toast-sucess',
          theme: 'colored',
        });
        history.push('/');
      } else if (moment(moment(new Date()).format()).isBefore(inicio)) {
        console.log('ainda não começou =============> interval');
        setIsConsultancieTime(false);
      } else {
        setIsConsultancieTime(true);
      }
    }, 5000);
  }

  async function submitNewComment(e: string) {
    const objectPost = {
      comentario: e,
      consultoriaId: consultoria.id,
    };
    console.log(objectPost);
    const response = await apiDoubts.post(
      `comentarios-consultoria`,
      objectPost
    );
  }

  return (
    <>
      {isConsultancieTime ? (
        <Container full={fullscreen}>
          <FullScreen handle={handle}>
            <div className="videoWidthWrapper">
              <div className="videoWrapper">
                <iframe
                  src={consultoria?.consultor?.streamLink}
                  className="iframeVideo"
                />

                {/* <button
            className="fullScreenButton"
            onClick={() => {
              handleFullScreen();
            }}
          >
            <IconFullScreen />
          </button> */}
              </div>
            </div>
          </FullScreen>
          <div className="commentsWrapper">
            {(consultoria?.cadastroId === user?.id ||
              consultoria?.consultorId === user?.id) && (
              <ComentarAreaConsultoria
                comment={async (e) => {
                  let response:any = await submitNewComment(e);
                  if (response){
                    setConsultoriaComments( oldArray => ([...oldArray, e]))
                  }
                  return response;
                }}
              />
            )}

            {consultoriaComments?.reverse().map((row: any, index: number) => {
              return <ComentariosCard comentario={row} />;
            })}
          </div>
        </Container>
      ) : (
        <>
          <Container full={false}>
            <div className="centerWrapperLoad">
              <div className="lootieContainer">
                <LottieAnimation lootiePath="https://lottie.host/77fa618e-3fa9-4d69-8500-7f80ce7f3fdf/EQwPG4hmpY.json" />
              </div>
              <div className="rigthSide">
                <h1 className="titlePage">
                  Ops, ainda não está no horário da sua consultoria...
                </h1>
                {user?.tipo !== 'consultor' && (
                  <p>
                    O Consultor {consultoria?.consultor?.nickName} vai te atender{' '}
                    {moment(consultoria?.data).locale('pt-br').format(
                      'dddd, DD [de] MMMM [de] YYYY [às] HH:mm'
                    )}
                  </p>
                )}

                <ButttonDefault
                  tipo="7"
                  title={'Voltar para minhas consultorias'}
                  onClick={() => {
                    if (user?.tipo === 'consultor') {
                      history.push('/consultor/listar');
                    } else if (user?.tipo === 'cadastro') {
                      history.push('/usuario/agenda');
                    } else {
                      history.push('/');
                    }
                  }}
                />
              </div>
            </div>
          </Container>
        </>
      )}
    </>
  );
}
