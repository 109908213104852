import styled from 'styled-components';

interface MenuSelected {
  isSelected: boolean;
}

export const Container = styled.div``;

export const Header = styled.div`
  .title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .pageConfigTitle {
      font-size: 28px;
      font-weight: 700;
      line-height: 42px;
      color: var(--paleta2C);
    }
  }
  .pageConfigSubtitle {
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
    color: var(--paleta1C);
  }

  @media (max-width: 769px) {
    .title {
      flex-direction: column;
      gap: 10px;
      margin-bottom: 20px;
    }
  }
`;

export const Menu = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--paleta1B);
  display: flex;
  gap: 20px;
  margin-top: 33px;

  overflow: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const SectionMenu = styled.button<MenuSelected>`
  background-color: transparent;
  border: none;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding-bottom: 5px;
  border-bottom: 4px solid transparent;
  border-bottom: ${({ isSelected }) =>
    isSelected && '4px solid var(--paleta1B)'};
  .labelMenu {
    font-size: 13px;
    font-weight: 700;
    line-height: 15px;
    color: var(--paleta1B);
  }
`;

export const Content = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 20px;
`;

export const Main = styled.div`
  width: calc(100% - 264px);

  @media (max-width: 769px) {
    width: 100%;
  }
`;

export const Banners = styled.div`
  width: 264px;
  height: 200px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 769px) {
    display: none;
  }
`;

export const AreaModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .inputArea {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .label {
      font-size: 13px;
      font-weight: 700;
      line-height: 15px;
      color: var(--paleta2C);
    }
    .SelectConsultor {
      width: 100%;
      border: none;
      background-color: var(--background2);
      padding: 5px;
      border-radius: 5px;
      outline: none;
    }
    .comment {
      border: none;
      width: 100%;
      height: 120px;
      background-color: var(--background2);
      resize: none;
      border-radius: 5px;
      padding: 10px;
      outline-width: 1px;
      border: 1px solid transparent;
      outline-color: var(--paleta1A);
    }
    .DatePickerInput {
      width: 100%;
      border: none;
      padding-right: 10px;
      margin-right: 10px;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      outline: none;
      ::-webkit-datetime-edit {
        height: 40px;
        background-color: var(--background2);
        border-radius: 5px;
        width: 100%;
        display: flex;
        align-items: center;
        padding-left: 10px;
      }
      ::-webkit-datetime-edit-fields-wrapper {
        background: transparent;
      }
      ::-webkit-datetime-edit-text {
        padding: 0 0.3em;
      }
      ::-webkit-datetime-edit-month-field {
      }
      ::-webkit-datetime-edit-day-field {
      }
      ::-webkit-datetime-edit-year-field {
      }
      ::-webkit-inner-spin-button {
        display: none;
      }
      ::-webkit-calendar-picker-indicator {
        margin-left: 10px;
        color: var(--branco);
        svg {
          path {
          }
        }
      }
    }
  }
  .areaButtons {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
`;
