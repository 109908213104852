import { useState } from 'react';
import Checkbox from '../../Checkbox/Checkbox';
import { IconCheck, IconTrash } from '../../Icons';
import ModalAlert from '../../ModalAlert';
import { Container } from './styles';

interface dataProps {
  consultorData: any;
  checked: boolean;
  setChecked: (id: string) => void;
  removeConsultor: (account: any) => void;
}

export default function CardConsultorConfigEmpresa({
  consultorData,
  checked,
  removeConsultor,
  setChecked,
}: dataProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      <ModalAlert
        prosseguir={() => removeConsultor(consultorData)}
        openModal={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        id=""
      >
        Tem certeza que desejar encerrar vínculos com o(a){' '}
        {consultorData?.nickName}?
      </ModalAlert>

      <Container
        onClick={() => {
          setChecked(consultorData?.id);
        }}
      >
        {/* <input
            type="checkbox"
            className="inputCheckboxConsultorEmpresa"
            onChange={()=>{setChecked(consultorData?.id)}}
            checked={checked}
            /> */}

        {/* {checked && (
                <div
                className="iconChecked"
                onClick={()=>{setChecked(consultorData?.id)}}
                >
                <IconCheck color={'#fff'}/>
                </div>
            )} */}

        <div className="contentCardProfileConsultor">
          <div
            className="imageProfileConsultor"
            style={{
              backgroundImage: `url(${
                consultorData?.foto ??
                'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
              })`,
            }}
          ></div>
          <p className="nameCardConsultorProfile">{consultorData?.nickName}</p>
        </div>
        <p
          onClick={() => setIsModalOpen(true)}
          className="removeConsultor"
          title="Remover Vínculo com Consultor"
        >
          <IconTrash color="var(--offline)" />
        </p>
      </Container>
    </>
  );
}
