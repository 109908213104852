import { useEffect } from "react";
import Lottie from "lottie-web";

interface ILootieProps {
    lootiePath: string
}

function LottieAnimation({
    lootiePath
}:ILootieProps) {

  useEffect(() => {
    fetch(`${lootiePath}`)
      .then((response) => response.json())
      .then((json) => {
        Lottie.loadAnimation({
          container: (document.querySelector("#lottie-container") as Element),
          animationData: json,
        })
      });
  }, []);

  return (
    <div>
      <div id="lottie-container"></div>
    </div>
  );
}

export default LottieAnimation;
