import CardAgendar from '../CardAgendar';
import emptyBox from '../../assets/icons/empty-box-icon.svg';
import { Container } from './styles';

interface SProps {
  click(e: any): void;
  selected?: string | Array<string | null>;
  horarios: any;
}

export default function SelectHour({ click, selected, horarios }: SProps) {

  return (
    <Container>
      {horarios?.length !== 0 && <h3>Horários Disponíveis</h3>}
      <div className="select">
        {horarios?.length === 0 && (
          <>
            <p className="emptySchedule">
              O consultor não possui horários cadastrados para esse dia
            </p>
            <img height={80} className="emptyBoxIcon" src={emptyBox} />
          </>
        )}
        {horarios?.map((row: any, key: number) => (
          <CardAgendar
            item={row}
            key={key}
            click={(e) => {
              click(e);
            }}
            tipo={row.tipo}
            selected={
              Array.isArray(selected)
                ? selected.includes(row.hora)
                : row?.hora === selected
            }
          />
        ))}
      </div>
    </Container>
  );
}
