import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  p {
    font-size: 18px;
    font-weight: 600;
    color: var(--paleta2C);
  }
`;

export const Element = styled.div`
  height: 1px;
  width: calc(100% + 320px);
  background-color: var(--paleta1B);
  margin-top: 30px;
  margin-bottom: 30px;
`;
