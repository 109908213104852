import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;

  margin-bottom: 100px;

  .divInputs {
    display: flex;
    gap: 20px;
    width: 100%;
    .areaInputsAoLado {
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: 100%;
      .DatePickerInput {
        width: 100%;
        border: none;
        padding-right: 10px;
        margin-right: 10px;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        outline: none;
        ::-webkit-datetime-edit {
          height: 40px;
          background-color: var(--background2);
          border-radius: 5px;
          width: 100%;
          display: flex;
          align-items: center;
          padding-left: 10px;
        }
        ::-webkit-datetime-edit-fields-wrapper {
          background: transparent;
        }
        ::-webkit-datetime-edit-text {
          padding: 0 0.3em;
        }
        ::-webkit-datetime-edit-month-field {
        }
        ::-webkit-datetime-edit-day-field {
        }
        ::-webkit-datetime-edit-year-field {
        }
        ::-webkit-inner-spin-button {
          display: none;
        }
        ::-webkit-calendar-picker-indicator {
          margin-left: 10px;
          color: var(--branco);
          svg {
            path {
            }
          }
        }
      }
      .label {
        font-size: 13px;
        font-weight: 700;
        line-height: 15px;
        color: var(--paleta2C);
      }
      .comment {
        width: 100%;
        height: 120px;
        background-color: var(--background2);
        border: none;
        border-radius: 4px;
        padding: 10px;
        outline: none;
        resize: none;
        margin-bottom: 15px;
      }
    }
  }

  .areaInputs {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    .label {
      font-size: 13px;
      font-weight: 700;
      line-height: 15px;
      color: var(--paleta2C);
    }
    .comment {
      width: 100%;
      height: 120px;
      background-color: var(--background2);
      border: none;
      border-radius: 4px;
      padding: 10px;
      outline: none;
      resize: none;
      &:focus {
        border: 1px solid var(--paleta1C);
      }
    }
    .hashtagsArea {
      display: flex;
      align-items: baseline;
      gap: 5px;
      flex-wrap: wrap;
    }
    .areaResults {
      position: absolute;
      top: 70px;
      max-height: 130px;
      overflow-y: scroll;
      z-index: 10;
      width: 100%;
      height: fit-content;
      padding: 10px 5px;
      border-radius: 5px;
      background: #e7e7f3;

      ::-webkit-scrollbar {
        width: 10px;
      }

      ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      .listContainer {
        display: flex;
        flex-direction: column;
        gap: 5px;
        list-style: none;
        list-style-position: inside;

        .option {
          padding: 5px 8px;
          font-weight: 400;
          transition: all 300ms;
          border-radius: 5px;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:hover {
            background-color: rgba(185, 185, 195, 0.4);
          }

          span {
            font-size: 10px;
            font-weight: bolder;
            color: var(--paleta2C);
          }
        }
      }
    }
    .selectIndex {
      position: relative;
    }
    .css-j8gyih-TwoThumbInputRange {
      margin-top: 35px;
      width: 100%;

      > div {
        width: 100%;
      }
      /* .css-10b19pu{
        width: 100%;
      } */
    }
  }

  .flexRow {
    width: 100%;
    justify-content: space-between;
    display: flex;
    gap: 10px;
    flex-direction: row;
    .inputWrapper {
      width: 100%;
    }
  }

  .areaButtons {
    width: 100%;
    display: flex;
    gap: 5px;
    justify-content: flex-end;

    > button {
      z-index: 0 !important;
    }
  }
`;

export const SuggestHashtag = styled.a`
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
  line-height: 14px;
  background-color: var(--paleta1A);
  opacity: 0.8;

  position: relative;
  margin-top: 5px;
  padding: 0 10px;
  height: 26px;
  border-radius: 50px;
  color: var(--branco);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  transition: all 0.2s;

  > span {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    transition: all 0.2s;
    transform: scale(1.04);

    ::before {
      opacity: 1;
    }
  }

  &::before {
    content: attr(data-suggest);
    background: var(--white);
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    bottom: 110%;
    width: max-content;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    opacity: 0;
    line-height: 20px;
    pointer-events: none;
    transition: all 150ms;
    max-width: 240px;
    color: #000;
  }

  .popularity {
    font-size: 10px;
    font-weight: bolder;
  }
`;
