import styled from "styled-components";

interface props{
isSelected:boolean
}

export const Container = styled.div`
display: flex;
gap: 20px;
`

export const Left = styled.div`
display: flex;
flex-direction: column;
width: calc(100% - 322px);
`

export const Right = styled.div`
display: flex;
gap: 10px;
width: 322px;
flex-direction: column;
.adsenseResume{
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    color: var(--paleta2C);
     
}
.titleCardCampaing{
        font-size: 13px;
        font-weight: 700;
        line-height: 15px;
        color: var(--paleta2C);
    }
    .ValueInvesting{
        font-size: 43px;
        line-height: 43px;
        font-weight: 700;
        color: var(--texto2);
        .cifrao{
            font-size: 13px;
            line-height: 13px;
        }
    }
    .valorPorDia{
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: var(--texto);

    }   
`

export const LeftHeader = styled.div`
.pageTitleAdsense{
    font-size: 28px;
    line-height: 42px;
    font-weight: 700;
    color: var(--paleta2C);
}
.pageSubtitleAdsense{
    font-size: 18px;
    line-height: 27px;
    font-weight: 700;
    color: var(--paleta1C);
}
.pagesMenu{
    margin-top: 20px;
    display: flex;
    width: 100%;
    border-bottom: 2px solid var(--paleta1B);
}
`

export const ItemMenu = styled.button<props>`
width: 180px;
display: flex;
align-items: center;
border: none;
background-color: transparent;
gap: 5px;
justify-content: center;
border-bottom: 4px solid ${({isSelected}) => isSelected ? 'var(--paleta1B)' : 'transparent'};
padding-bottom: 5px;
.textCardMenu{
    font-size: 13px;
    line-height: 15px;
    font-weight: 700;
    color: var(--paleta1B);
}

`