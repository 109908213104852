/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useContext, useEffect, useState } from 'react';

import { SidebarNav, NavbarLink, Divisor } from './styles';

import {
  menuFixo,
  menuUsuario,
  menuConsultor,
  menuAdministrador,
  singleMenu,
  menuEmpresa,
  menuAgencia,
} from '../Data/SidebarUserData';

import SubmenuButtons from '../SubmenuButtons';
import apiDoubts from '../../services/api';
import UserContext, { useUser } from '../../contexts/UserContext';
import { useLocation } from 'react-router-dom';

interface Iprops {
  hasCompact?: boolean;
}

interface Params {
  pathname: string;
}

export default function Menu({ hasCompact }: Iprops) {
  const [menus, setMenus] = useState<any>('');
  const [listaInteresses, setListaInteresses] = useState<any[]>([]);
  const { user } = useContext(UserContext);
  const { pathname } = useLocation<Params>();

  useEffect(() => {
    if (user.tipo === 'cadastro') {
      setMenus(menuUsuario);
    } else if (user.tipo === 'consultor') {
      setMenus(menuConsultor);
    } else if (user.tipo === 'admin') {
      setMenus(menuAdministrador);
    } else if (user.tipo === 'empresa') {
      setMenus(menuEmpresa);
    } else if (user.tipo === 'agencia') {
      setMenus(menuAgencia);
    } else {
      setMenus(singleMenu);
    }
    getInteresses();
  }, [user]);

  async function getInteresses() {
    const response = await apiDoubts.get('interesses');
    setListaInteresses(response.data.data);
  }

  return (
    <SidebarNav>
      <NavbarLink compact={hasCompact}>
        {menus &&
          menuFixo.map((item: any, index: any) => {
            if (user?.id && item?.title !== 'Explorar') {
              return (
                <SubmenuButtons
                  compact={hasCompact}
                  selected={item.path === pathname ? 'active' : ''}
                  item={item}
                  key={index}
                />
              );
            } else if (user?.id && item.title === 'Explorar' && !hasCompact) {
              return (
                <>
                  <Divisor />
                  <p className="exploreWrapper">Explorar</p>
                </>
              );
            }

            if (
              !user?.id &&
              item.title !== 'Favoritos' &&
              item.title !== 'Explorar'
            ) {
              return (
                <SubmenuButtons
                  compact={hasCompact}
                  selected={item.path === pathname ? 'active' : ''}
                  item={item}
                  key={index}
                />
              );
            } else if (!user?.id && item.title === 'Explorar' && !hasCompact) {
              return (
                <>
                  <Divisor />
                  <p className="exploreWrapper">Explorar</p>
                </>
              );
            }
          })}
        {user.login && <Divisor />}
        {menus &&
          menus.map((item: any, index: any) => {
            return (
              <SubmenuButtons
                compact={hasCompact}
                selected={item.path === pathname ? 'active' : ''}
                item={item}
                key={index}
              />
            );
          })}
        <Divisor />
      </NavbarLink>
    </SidebarNav>
  );
}
