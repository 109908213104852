import styled from 'styled-components';

export const Container = styled.div`
  grid-area: main;
  position: relative;

  .notfound {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    background-color: var(--background);
    padding: 100px 0px;
    img {
      width: 100px;
    }
    h3 {
      color: var(--purple2);
    }
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 31px;

  .no-video {
    color: var(--purple2);
    font-weight: bold;
    font-size: 18px;
    text-align: center;
  }
`;

export const CardView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 375px;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 25px;
  }

  .image {
    position: relative;
    width: 100%;
    height: 200px;
    background-size: cover;
    border-radius: 10px;
    .player {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      background: transparent;
      cursor: pointer;
      transition: all 0.5s;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        transition: all 0.2s;
        path {
          fill: var(--green);
        }
      }
      svg {
        width: 50px;
        height: 100%;
      }
      &:hover {
        background: rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        svg {
          position: relative;
          transform: scale(1.2);
          path {
            fill: var(--white);
          }
        }
      }
    }
  }
  .cardInfo {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    width: 100%;
    position: relative;
    footer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .header {
        display: flex;
        justify-content: space-evenly;
        gap: 20px;
        width: 100%;
        .photo {
          width: 40px;
          height: 40px;
          background-size: cover;
          background-position: center;
          border-radius: 50%;
        }
        .title {
          width: calc(100% - 60px);
          h3 {
            font-size: 16px;
            font-weight: 700;
            line-height: 18px;
            color: var(--purple4);
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 250px;
          }
          p {
            margin-bottom: 0px;
          }
        }
      }
      a {
        text-decoration: none;
      }

      p {
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        color: var(--purple6);

        margin: 8px 0;
      }

      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        color: var(--dark-grey);
      }
    }
  }
`;
