import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import UserContext from '../../../contexts/UserContext';
import apiDoubts from '../../../services/api';
import { IconLoading } from '../../Icons';
import CardPlaylist from '../CardPlaylist';
import { Container, Element } from './styles';

interface CProps {
  consultorId?: string;
  recarregar: boolean;
  empresaId?:string
}

export default function ListPlaylist({ consultorId, recarregar, empresaId }: CProps) {
  const params = useParams<any>();
  const history = useHistory()
  const [playlists, setPlaylist] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const {user} = useContext(UserContext)

  useEffect(() => {
    if (consultorId || empresaId) {
      getPlaylists();
    }
  }, [consultorId, recarregar]);

  async function getPlaylists() {
    try {
      const response = await apiDoubts.get(
        `/playlists?pagination=true&limit=20&page=1&ordenar=data&${consultorId ? `consultorId=${consultorId}` : `agenciaId=${empresaId}`}`
      );
      setPlaylist(response?.data.data.docs);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <Container>
      {loading && (
        <div className="iconLoading">
          <IconLoading size={40} />
        </div>
      )}
      {!loading && playlists.length === 0 && (
        <div className="notfound">
          <div className="info">
            <h3>
              Infelizmente esse usuário ainda não possui nenhuma playlist, mas
              você pode acompanhar os seus vídeos clicando
              <a
                href={`/${consultorId ? 'consultor' : 'empresa'}/visualizar/canal/${params.id}`}
                style={{
                  fontWeight: 700,
                  cursor: 'pointer',
                  textDecoration: 'none',
                  color: '#fff'
                }}
              >
                {' '}
                Aqui{' '}
              </a>
            </h3>
          </div>
        </div>
      )}
      {playlists && (
        <div className="areaCard">
          {playlists.map((row: any, key: number) => (
            <CardPlaylist data={row} key={key} consultor={user.tipo === 'consultor' && true}/>
          ))}
        </div>
      )}
    </Container>
  );
}
