import styled, { css } from 'styled-components';

interface PProps {
  posicao?: string;
  type?: string;
  playlist?: boolean;
  modal?: boolean;
}

export const Container = styled.div<PProps>`
  cursor: pointer;
  width: 290px;
  position: relative;
  overflow: hidden;

  .modalOptions {
    position: absolute;
    top: 0;
    right: 0;
    width: 90%;
    margin-top: 5px;
    height: 40px;
    margin-right: ${({ modal }) => (modal ? '0px' : '-450px')};
    background-color: var(--branco);
    border-radius: 5px 0 0 5px;
    z-index: 99999999999;
    transition: all 0.5s;

    visibility: ${({ modal }) => !modal && 'hidden'};
    display: flex;
    padding: 5px;
    justify-content: space-between;
    .options {
      display: flex;
      align-items: center;

      .remove {
        display: flex;
        align-items: center;
        gap: 5px;
        svg {
          stroke: var(--texto);
        }
        .text {
          font: 13px;
          line-height: 18px;
          font-weight: 500;
          color: var(--texto);
        }
      }
    }
  }

  ${(props) =>
    props.posicao === 'lateral' &&
    props.type !== 'anuncio' &&
    css`
      display: flex;
      gap: 10px;
      align-items: center;
    `}

  .image {
    &:hover {
      .overlay-play {
        opacity: 1;
        visibility: visible;
        svg {
          transform: scale(1);
        }
      }
      .overlayConfig {
        margin-right: 0;
        transition: all 0.5s;
      }
    }
    border-radius: 10px;

    height: ${(props) => (props.posicao === 'lateral' ? '84px' : '182px')};
    min-width: ${(props) => props.posicao === 'lateral' && '130px'};
    background-position: center;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;

    .react-loading-skeleton {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .overlay-play {
      position: relative;
      .dots {
        position: absolute;
        top: 0;
        right: 0;
        padding-top: 10px;
        z-index: 9999999;
      }
      .play {
        padding: 80px;
      }
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.5s;
      opacity: 0;
      visibility: hidden;
      svg {
        transition: all 0.5s;
        transform: scale(0);
      }
      ${(props) =>
        props.posicao === 'lateral' &&
        props.type !== 'anuncio' &&
        css`
          svg {
            width: 35px;
            height: 35px;
          }
        `}
    }

    /* .overlayConfig {
      transition: all 0.5s;
      position: absolute;
      width: 91px;
      height: 100%;
      background-color: var(--paleta2C);
      right: 0;
      margin-right: -95px;
      .play {
        flex-direction: column;
        height: 65%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 5px;
        .textClose {
          font-size: 13px;
          line-height: 18px;
          font-weight: bold;
          color: var(--branco);
        }
      }
      
      .remove {
        height: 35%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--offline);
        flex-direction: column;
        .textClose {
          font-size: 13px;
          line-height: 18px;
          font-weight: bold;
          color: var(--branco);
        }
      }
    } */
  }

  .infos {
    margin-top: ${(props) => (props.posicao === 'lateral' ? '0px' : '11px')};
    display: flex;
    width: 100%;

    .image-profile {
      width: 40px;
      height: 40px;
      min-width: 40px;
      border-radius: 100%;
      background-position: center;
      background-size: cover;
      margin-right: 7px;
      background-color: rgba(0, 0, 0, 0.1);
      position: relative;
      overflow: hidden;
      .react-loading-skeleton {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .info-post {
      display: flex;
      flex-direction: column;
      width: 100%;
      .title {
        font-size: ${(props) =>
          props.posicao === 'lateral' ? '12.5px' : '16px'};
        line-height: 20px;
        font-weight: 700;
        color: ${(props) =>
          props.playlist ? 'var(--branco)' : 'var(--paleta2C)'};

        width: 100%;
        min-height: 20px;
      }
      .author {
        font-size: 13px;
        line-height: 15px;
        font-weight: 700;
        color: ${(props) =>
          props.playlist ? 'var(--paleta2D)' : 'var(--paleta1B)'};
        margin-top: 6px;
        width: 100%;
        min-height: 15px;

        ${(props) =>
          props.posicao === 'lateral' &&
          props.type !== 'anuncio' &&
          css`
            font-size: 11px;
            line-height: 14px;
          `}
      }
      .date {
        font-size: 13px;
        line-height: 14px;
        font-weight: 400;
        color: #7d7d7d;
        margin-top: 3px;
        width: 100%;
        min-height: 14px;

        ${(props) =>
          props.posicao === 'lateral' &&
          props.playlist &&
          css`
            font-size: 11px;
            line-height: 14px;
            display: none;
          `}

        ${(props) =>
          props.posicao === 'lateral' &&
          props.type !== 'anuncio' &&
          css`
            font-size: 11px;
            line-height: 14px;
          `}
      }
    }
  }

  @media (max-width: 800px) {
    max-width: 100%;
    .image {
      min-height: 130px;
      min-width: 180px;
      .overlay-play {
        opacity: 1;
        visibility: visible;
        background-color: transparent;
        &:hover {
          background-color: rgba(0, 0, 0, 0.3);
        }
        .dots {
          svg {
            transform: scale(1);
          }
        }
      }
    }
  }

  @media (max-width: 425px) {
    max-width: 100%;
    .image {
      height: 220px;
      background-color: var(--background2);
      ${(props) =>
        props.posicao === 'lateral' &&
        props.type !== 'anuncio' &&
        css`
          height: 90px;
        `}
    }
  }
`;
