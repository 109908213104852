import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  svg {
    path {
      stroke: var(--paleta1B);
    }
  }
`;
export const Title = styled.h3`
  color: var(--paleta1B);
  font-size: 12px;
  margin-left: 5px;
  font-weight: 700;
`;
