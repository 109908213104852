import styled, { css } from 'styled-components';

interface Props {
  hasCompact: boolean;
}

export const CloseSideBar = styled.div<Props>`
  display: none;
  position: fixed;
  opacity: 0;
  z-index: 100 !important;
  width: 100%;
  height: 100%;
  @media (max-width: 800px) {
    background-color: ${({ hasCompact }) => !hasCompact && 'rgba(0,0,0,0.5)'};
    display: ${({ hasCompact }) => !hasCompact && 'flex'};
  }
`;

export const Container = styled.div<Props>`
  height: calc(100vh - 80px);
  grid-area: menu;
  width: 235px;
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--purple2);
  color: var(--background);
  z-index: 101 !important;
  position: fixed;
  .side {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;

    &:hover {
      ::-webkit-scrollbar {
        width: 4px;
      }
    }

    ::-webkit-scrollbar {
      width: 4px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: var(--paleta2E);
      border-radius: 4px;
    }
    ::-webkit-scrollbar-track {
      background-color: var(--branco);
    }
  }

  ${(props) =>
    props.hasCompact &&
    css`
      width: 110px;
      align-items: center;
      display: flex;
      .userInfo {
        h2 {
          word-break: break-all;
          padding: 10px;
        }
        span {
          display: flex;
          flex-direction: column;
        }
      }
    `}

  @media (max-width: 800px) {
    ${(props) =>
      props.hasCompact &&
      css`
        margin-left: -200px;
        /* display: none; */
      `}
    position: fixed;
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin-top: 30px; 
  .logout {
    margin-top: 10px;
    font-size: 11px;
    cursor: pointer;
    text-decoration: underline;
  }
  > span {
    font-size: 11px;
    font-weight: 900;
    line-height: 13px;
    color: var(--purple7);
    text-transform: uppercase;
    text-align: center;
  }

  .userInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    margin-top: 17px;

    h2 {
      font-size: 14px;
      font-weight: 700;
      color: var(--background);
      margin-top: 16px;
      text-align: center;
    }

    span {
      font-size: 12px;
      font-weight: 400;
      color: var(--background);
      margin-top: 4px;
      text-align: center;
    }
  }
  .userImg {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 5px;

    background-size: cover;
    background-position: center;

    width: 70px;
    height: 70px;
  }
`;

export const SidebarNav = styled.div`
  width: 100%;
`;

export const NavbarLink = styled.div<Props>`
  h2 {
    margin: 0 40px 16px;
    font-size: 12px;
    font-weight: 900;
    line-height: 12px;
    color: var(--green);
    white-space: nowrap;
    text-overflow: ellipsis;
    opacity: 1;
    transition: all 0.2s;
  }

  ${(props) =>
    props.hasCompact &&
    css`
      h2 {
        margin-left: 14px;
        margin-right: 10px;
      }
    `}
`;

export const Separator = styled.div`
  width: 100%;
  height: 2px;
  background: var(--purple3);

  margin: 30px 0;
`;

export const Footer = styled.div<Props>`
  display: block;
  padding-left: 22px;
  margin-right: auto;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;

  a {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: auto;
    text-decoration: none;
  }

  h3 {
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    color: var(--purple6);
    text-transform: uppercase;
  }

  span {
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    color: var(--purple6);
    text-transform: uppercase;
    margin: 5px 0;
  }

  ${(props) =>
    props.hasCompact &&
    css`
      a {
        text-align: center;
      }
    `}
`;
