import styled from 'styled-components';

interface props {}

export const Container = styled.div<props>`
  width: 1280px;
  height: 720px;
  background: black;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  @media (max-width: 1920px) {
    width: 960px;
    height: 540px;
  }
  @media (max-width: 1400px) {
    width: 640px;
    height: 360px;
  }

  .overlay-controls {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    background-size: cover;
    justify-content: center;
    background: rgba(138, 123, 232, 0.1);
    backdrop-filter: blur(10px);
    transition: all 0.2s;
    cursor: pointer;
    &.play {
      background-color: rgba(138, 123, 232, 0);
      backdrop-filter: blur(0px);
      button {
        transform: scale(0);
      }
    }
    &.play,
    &.pause {
      button {
        background: #8a7be8;
        &:hover {
          transform: scale(1.2);
          box-shadow: 0px 0px 0px 20px rgba(138, 123, 232, 0.5);
        }
      }
    }
    &.loading {
      button {
        background: #10baba;
        box-shadow: 0px 0px 0px 20px rgba(15, 186, 186, 0.5);
        animation: shadow 0.5s ease-in-out infinite alternate;
      }
      @keyframes shadow {
        0% {
          box-shadow: 0px 0px 0px 0px rgba(15, 186, 186, 0.5);
        }
        100% {
          box-shadow: 0px 0px 0px 20px rgba(15, 186, 186, 0.5);
        }
      }
    }
    button {
      display: flex;
      border: none;
      padding: 30px;
      cursor: pointer;
      cursor: pointer;
      border-radius: 100px;
      transition: all 0.2s;
      svg {
        * {
          fill: #fff;
        }
      }
    }
  }
  .controls-ads {
    position: absolute;
    cursor: pointer;
    z-index: 999;
    left: 0;
    bottom: 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background-position: center;
    background-size: cover;
    cursor: auto;
    padding: 20px 15px;
    .campgain {
      background: rgba(46, 41, 78, 0.5);
      display: flex;
      cursor: auto;
      align-items: center;
      border-radius: 8px;
      padding: 10px;
      gap: 10px;
      font-size: 13px;
      backdrop-filter: blur(5px);
      color: rgba(255, 255, 255, 0.8);
      .img {
        width: 50px;
        height: 50px;
        border-radius: 100px;
        background: rgba(0, 0, 0, 0.5);
        background-size: cover;
      }
      p {
        font-weight: bold;
      }
      a {
        color: #069cd1;
      }
    }
    .timer-skip {
      button {
        height: 100%;
        background: rgba(46, 41, 78, 0.8);
        backdrop-filter: blur(5px);
        color: rgba(255, 255, 255, 0.8);
        padding: 15px 20px;
        border-radius: 8px;
        border: none;
        animation: fadeInLeft 0.5s;
        &:hover {
          background: rgba(46, 41, 78, 1);
          color: #fff;
        }
      }
    }
  }

  @media (max-width: 800px) {
    height: 400px;
  }

  @media (max-width: 450px) {
    height: 195px;

    .timer-skip {
      position: absolute;
      right: 0;
      bottom: 0;
      margin-right: 20px;
      margin-bottom: 20px;
    }

    .controls-ads {
      .campgain {
        display: none;
      }
    }
  }
`;
