import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from './styles';
import VideoVisualizar from '../../../components/VideoVisualizar';
import Banner from '../../../components/Banner';
import AnunciosVerticais from '../../../components/Anuncio/AnunciosVerticais';
import FiltrosInteresses from '../../../components/FiltrosInteresses';
import ListPosts from '../../../components/Posts/ListPosts';
import Comentarios from '../../../components/Comentarios';
import apiDoubts from '../../../services/api';
import { IconLoading } from '../../../components/Icons';
import UserContext from '../../../contexts/UserContext';

export default function Visualizar() {
  const params = useParams<any>();
  const [playlist, setPlaylist] = useState<any>([]);
  const [reload, setReload] = useState('false');
  const [video, setVideo] = useState<any>('');
  const { loadingVideo, setLoadingVideo, reloadComment, setReloadComment } =
    useContext(UserContext);

  useEffect(() => {
    if (params) {
      handlePlaylist();
      handleVideo(params.videoId);
    }
  }, [params]);

  async function handlePlaylist() {
    await apiDoubts.get(`playlists/${params.playlistId}`).then((response)=>{
      try {
        console.log(response.data.data)
        setPlaylist(response.data.data)
      } catch (error) {
        console.log('error', error)
      }
    }).catch((err)=>{console.log('erro', err)})    
  }

  async function handleVideo(videoId: any) {
    console.log('recarregar comentários');
    const response = await apiDoubts.get(`/postagens/getPostagem/${videoId}`);
    setVideo(response.data.data);
    setLoadingVideo(false);
  }

  return (
    <Container>
      <div className="leftArea">
        {loadingVideo && (
          <div className="areaLoad">
            <IconLoading />
          </div>
        )}
        {!loadingVideo && <VideoVisualizar video={video} />}
        <div className="comentarios">
          <Comentarios video={video} videoId={video._id} />
        </div>
      </div>

      <div className="rigthArea">
        <ListPosts
          playlist={playlist}
          posicao="lateral"
          tipo="playlist"
          order=""
          where={{
            interesse: '',
            search: '',
          }}
        />
      </div>
    </Container>
  );
}
