import ButtonSeguir from "../../ButtonSeguir";
import { IconClips, IconFile } from "../../Icons";
import { AreaLeft, AreaRigth, Container } from "./styles";

interface props {
    editCard: (e:any)=>void,
    removeCard: (e:string)=>void,
    data: any
}

export default function CardProdutosEmpresa({editCard, removeCard, data}:props){

    return (
      <Container>
        <AreaLeft>
          <div
            className="image"
            style={{ backgroundImage: `url(${data?.imagem})` }}
          />
          <div className="areaButtons">
            <ButtonSeguir
              title={'Excluir'}
              click={() => {
                removeCard(data.id);
              }}
              tipo={'3'}
            />
            <ButtonSeguir
              title={'Editar'}
              click={() => {
                editCard(data);
              }}
              tipo={'4'}
            />
          </div>
        </AreaLeft>
        <AreaRigth>
          <div className="title">{data?.nome}</div>
          <span>
            <IconClips size={15}/>
            <a className="link" href={data?.link} target={'blank'}>
                {data.link}
            </a>
          </span>
          <div className="description">{data?.descricao}</div>
        </AreaRigth>
      </Container>
    );
}