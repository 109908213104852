import styled from "styled-components";

export const Container = styled.div`
padding-bottom: 100px;
display: flex;
flex-direction: column;
gap: 20px;
`;

export const Element = styled.div`
width: 100%;
height: 1px;
background-color: var(--background2);
`