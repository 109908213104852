import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  height: 100%;

  .selectAction {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .container-box {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 35px;
    margin-top 10px;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 18px !important;
      margin-bottom: 10px;
    }
    .resume {
      background: white;
      box-shadow: 0px 10px 10px rgba(0,0,0,.05);
      padding: 15px 20px;
      border-radius: 8px;
      display: flex;
      gap: 10px;
      align-items: center;
      svg {
        width: 16px;
        height: 16px;
        min-width: 16px;
      }
      p {
        font-size: 14px;
      }
    }
  }

  .main {
    width: 100%;
    display: flex;
    gap: 30px;
    .areaLeft {
      flex: 1;
      height: 100%;
      .title {
        font-size: 28px;
        line-height: 30px;
        font-weight: 700;
        color: var(--paleta2C);
      }
      .subtitle {
        margin-top: 20px;
        font-size: 13px;
        line-height: 15px;
        font-weight: 700;
        color: var(--texto);
        margin-bottom: 5px;
      }
      .comment {
        width: 100%;
        height: 120px;
        background-color: var(--background2);
        margin-top: 3px;
        border: none;
        border-radius: 4px;
        padding: 10px;
        outline: none;
        resize: none;
      }
      .areaButton {
        margin-top: 15px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding-bottom: 50px;
      }
    }
    .areaRight {
      width: 520px;
      height: 100%;
      .subtitle {
        font-size: 28px;
        line-height: 30px;
        font-weight: 700;
        color: var(--paleta2C);
        margin-bottom: 5px;
        margin-bottom: 10px;
      }
      .cardpost {
        display: flex;
        width: 100%;
      }
    }
  }

  @media (max-width: 1150px) {
    .main {
      display: flex;
      flex-direction: column;
      .areaLeft {
        width: 80%;
        .areaButton {
          padding: 0;
        }
      }
      .areaRight {
        width: 100%;
      }
    }
    @media (max-width: 800px) {
      gap: 30px;
      .main {
        display: flex;
        flex-direction: column;
        .areaLeft {
          width: 100%;
          gap: 0px;
          .areaButton {
            padding: 0px;
          }
        }
        .areaRight {
          width: 100%;
          margin-top: 0;

          padding-bottom: 100px;
          .cardpost {
            min-width: 100%;
          }
        }
      }
    }
  }
`;

export const LoadingWrapper = styled.div`
  .lottieWrapper {
    width: 400px;
    height: 400px;
  }
  p {
    text-align: center;
  }
  .subtitle {
    font-size: 22px;
    font-weight: 700;
  }
  .subdescription {
    font-size: 14px;
  }

  .center {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
  }
  .wave {
    width: 5px;
    height: 100px;
    background: var(--paleta2C);
    margin: 10px;
    animation: wave 1s linear infinite;
    border-radius: 20px;
  }
  .wave:nth-child(2) {
    animation-delay: 0.1s;
  }
  .wave:nth-child(3) {
    animation-delay: 0.2s;
  }
  .wave:nth-child(4) {
    animation-delay: 0.3s;
  }
  .wave:nth-child(5) {
    animation-delay: 0.4s;
  }
  .wave:nth-child(6) {
    animation-delay: 0.5s;
  }
  .wave:nth-child(7) {
    animation-delay: 0.6s;
  }
  .wave:nth-child(8) {
    animation-delay: 0.7s;
  }
  .wave:nth-child(9) {
    animation-delay: 0.8s;
  }
  .wave:nth-child(10) {
    animation-delay: 0.9s;
  }

  @keyframes wave {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
`;
