import styled from 'styled-components';

export const Container = styled.div`
  .headerEmpresaCompleta {
    display: flex;
    flex-direction: column;
    gap: 22px;
    .bannerHeaderEmpresaCompleta {
      width: 100%;
      height: 180px;
      background-position: center;
      background-size: cover;
      border-radius: 10px;
      position: relative;
      .areaLogoEmpresa {
        width: 286px;
        height: 100px;
        display: flex;
        background-color: var(--branco);
        border-radius: 10px;
        -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.6);
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.6);
        position: absolute;
        bottom: 0;
        margin-bottom: -50px;

        .areaLogoAndButtons {
          display: flex;
          align-items: flex-end;
          position: absolute;
          width: 100%;
          height: 100%;
          bottom: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          .logoEmpresa {
            min-width: 80%;
            min-height: 90%;
            align-self: center;
            margin-top: 5px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
      .buttons {
        position: absolute;
        bottom: 0;
        display: flex;
        left: 0;
        margin-left: calc(286px + 10px);
        gap: 5px;
        margin-bottom: -50px;
        .miniButtons {
          display: flex;
          gap: 5px;
        }
        .textButtons {
          display: flex;
          gap: 5px;
        }
      }
    }

    .textHeaderEmpresa {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 30px;
      .top {
        display: flex;
        justify-content: space-between;
        .left {
          display: flex;
          align-items: center;
          gap: 6px;
          .nomeEmpresa {
            font-size: 28px;
            line-height: 42px;
            font-weight: 700;
            color: var(--paleta2C);
          }
        }
      }
      .description {
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: var(--texto);
        text-align: justify;
      }
    }
  }

  @media (max-width: 1315px) {
    .headerEmpresaCompleta {
      .bannerHeaderEmpresaCompleta {
        .areaLogoEmpresa {
          .areaLogoAndButtons {
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
          }
        }
        .buttons {
          margin-left: 0px;
          margin-bottom: -100px;
        }
      }
      .textHeaderEmpresa {
        margin-top: 100px;
      }
    }
  }

  @media (max-width: 1020px) {
    .headerEmpresaCompleta {
      .bannerHeaderEmpresaCompleta {
        .areaLogoEmpresa {
          .areaLogoAndButtons {
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
          }
        }
        .buttons {
          margin-left: 0px;
          margin-bottom: -150px;
          flex-direction: column;
        }
      }
      .textHeaderEmpresa {
        margin-top: 150px;
      }
    }
  }

  @media (max-width: 800px) {
    .headerEmpresaCompleta {
      .bannerHeaderEmpresaCompleta {
        .areaLogoEmpresa {
          .areaLogoAndButtons {
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
          }
        }
        .buttons {
          max-width: 100%;
          margin-left: 0px;
          margin-bottom: -100px;
          flex-direction: row;
          overflow-y: scroll;
          &::-webkit-scrollbar {
            display: none;
          }
          .textButtons {
          }
        }
      }
      .textHeaderEmpresa {
        margin-top: 100px;
      }
    }
  }

  @media (max-width: 550px) {
    .headerEmpresaCompleta {
      .textHeaderEmpresa {
        margin-top: 100px;
        .top{
          flex-direction: column;
        }
      }
    }
  }
`;
