import { useEffect, useState } from 'react';
import apiDoubts from '../../services/api';
import { Container } from './styles';
import moment from 'moment';

interface IOnlineProps {
  consultorId: string;
}

export default function Online({ consultorId }: IOnlineProps) {
  const [isInConsultancies, setIsInConsultancies] = useState<boolean>(false);

  useEffect(() => {
    if (consultorId) {
      getConsultorConsultancies(consultorId);
    }
  }, [consultorId]);

  async function getConsultorConsultancies(consultorId: string) {
    apiDoubts
      .get(`/consultoria-all?status=Aceita&consultorId=${consultorId}`)
      .then((response: any) => {
        if (response.data.status === 'error') {
          throw new Error(response.data.message);
        }

        consultantIsOnLive(response.data.data);
      })
      .catch((error: any) => {
        console.error(error?.message);
      });
  }

  function consultantIsOnLive(consultingHours: any) {
    consultingHours.forEach((row: any) => {
      // se o momento de agora estiver entre o horario da consultoria + (10 min em caso de grátis ou 30 em caso de paga )
      if (
        moment(new Date()).isAfter(moment(row?.data)) &&
        moment(new Date()).isBefore(moment(row?.data).add('minutes', row?.tempo)) && row?.tipo !== 'Paga'
      ) {
        setIsInConsultancies(true);
      }
    });
  }

  return (
    <>
      {isInConsultancies && (
        <Container>
          <div>Ao vivo</div>
        </Container>
      )}
    </>
  );
}
