import { useEffect, useState } from 'react';
import EmptyPosts from '../../../../components/EmptyPosts';
import { IDTOConsultoria, IMarcarConsultoria } from './types';
import apiDoubts from '../../../../services/api';
import Calendar from 'react-calendar';

import * as S from './styles';
import moment from 'moment';
import SelectHour from '../../../../components/SelectHour';
import ButttonDefault from '../../../../components/ButtonDefault';
import Inputdefault from '../../../../components/Inputs/InputDefault';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';

export default function MarcarConsultoriaEmpresa({
  consultorId,
}: IMarcarConsultoria) {
  const params: any = useParams();

  const [daySelected, setDaySelected] = useState<string>(
    moment(new Date()).set('hour', 0).set('minute', 0).format()
  );
  const [consultorSchedule, setConsultorSchedule] = useState<Array<any>>();
  const [loadingBookingConsultancie, setLoadingBookingConsultancie] =
    useState<boolean>(false);

  const [DTO, setDTO] = useState<IDTOConsultoria>({
    assunto: '',
    comentario: '',
    consultorId: '',
    data: '',
    empresaId: params?.id,
    tempo: 0,
  });

  useEffect(() => {
    if (consultorId) {
      getScheuleByConsultor(consultorId);
      handleOnChange('consultorId', consultorId);
    }
  }, [consultorId]);

  useEffect(() => {
    if (DTO.data && moment(DTO.data).format('HH:MM') !== '00:00') {
      handleHourSelectIsPaidOrFree(DTO.data);
    }
  }, [DTO.data]);

  function handleOnChange(
    name:
      | 'assunto'
      | 'comentario'
      | 'consultorId'
      | 'data'
      | 'tempo'
      | 'empresaId',
    value: any
  ) {
    const newDTO: any = DTO;
    newDTO[name] = value;
    setDTO({ ...newDTO });
  }

  async function getScheuleByConsultor(consultorId: string) {
    const responsePaidHours = apiDoubts.get(
      `/consultoriagratis-horarios?consultorId=${consultorId}&status=livre`
    );
    const responseFreeHours = apiDoubts.get(
      `consultoriaPaga-horarios?consultorId=${consultorId}&status=livre`
    );

    Promise.all([responseFreeHours, responsePaidHours]).then(
      ([freeResult, paidResult]) => {
        let freeMoreType: any = [];
        let paidMoreType: any = [];

        paidResult.data.data.docs.forEach((row: any) => {
          row.type = 'gratis';
          freeMoreType.push(row);
        });

        freeResult.data.data.docs.forEach((row: any) => {
          row.type = 'paga';
          paidMoreType.push(row);
        });

        setConsultorSchedule([...freeMoreType, ...paidMoreType]);
      }
    );
  }

  function ordenarHorarios(horarios: Array<any>) {
    horarios.sort(function (a: any, b: any) {
      // converte cada horário para um número de minutos
      var minutosA =
        parseInt(a.hora.split(':')[0]) * 60 + parseInt(a.hora.split(':')[1]);
      var minutosB =
        parseInt(b.hora.split(':')[0]) * 60 + parseInt(b.hora.split(':')[1]);
      // compara os números de minutos para determinar a ordem de classificação
      return minutosA - minutosB;
    });
    return horarios;
  }

  function handleAvailableHours(date: Date): Array<any> {
    let filteresHoursByDate: Array<any> = [];
    consultorSchedule?.forEach((row: any) => {
      if (moment(row.data).format('DD') === moment(date).format('DD')) {
        filteresHoursByDate.push({ hora: moment(row?.data).format('HH:mm') });
      }
    });
    return ordenarHorarios(filteresHoursByDate);
  }

  function handleHour(hour: string) {
    let hourSplit = hour.split(':');
    let cloneDate = daySelected;
    cloneDate = moment(cloneDate)
      .set('hour', parseInt(hourSplit[0]))
      .set('minute', parseInt(hourSplit[1]))
      .format('YYYY-MM-DD HH:mm:ss');
    setDaySelected(cloneDate);
    handleOnChange('data', cloneDate);
  }

  function handleDate(date: Date) {
    setDaySelected(moment(date).format());
  }

  function handleHourSelectIsPaidOrFree(hourSelected: string) {
    let selectedConsultancie: any = consultorSchedule?.filter((row: any) => {
      if (moment(row.data).format() === moment(hourSelected).format()) {
        return row;
      }
    });

    handleOnChange('tempo', selectedConsultancie[0]?.type === 'gratis' ? 10 : 30);
  }

  function checkConsultoriaShields() {
    if (
      !DTO.assunto ||
      !DTO.comentario ||
      !DTO.consultorId ||
      !DTO.data ||
      !DTO.empresaId ||
      !DTO.tempo
    )
      throw new Error('Todos os campos são obrigatórios');

    if (moment(DTO.data).format('HH:MM') === '00:00')
      throw new Error('Escolha um horário para a sua consultoria');
  }

  async function bookConsultancie() {
    setLoadingBookingConsultancie(true);
    try {
      checkConsultoriaShields();

      apiDoubts
        .post(`/${DTO.tempo === 10 ? 'consultoriagratis' : 'consultoria'}`, DTO)
        .then((response: AxiosResponse<any>) => {
          toast.success('Consultoria agendada com sucesso, em breve o consultor deve estrar em contato com você !!!', {
            className: 'toast-sucess',
            theme: 'colored',
          });
          setLoadingBookingConsultancie(false);
        });
    } catch (err: any) {
      setLoadingBookingConsultancie(false);
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  useEffect(()=>{
    console.log(DTO)
  },[DTO])

  return (
    <S.Container>
      {consultorId ? (
        <>
          <div className="calendar">
            <Calendar
              className={'teste'}
              onChange={handleDate}
              value={daySelected ? moment(daySelected).toDate() : null}
              locale={'pt'}
              minDate={new Date()}
              activeStartDate={new Date()}
            />
            <div className="selectHour">
              <SelectHour
                horarios={handleAvailableHours(moment(daySelected).toDate())}
                selected={moment(daySelected).format('HH:mm')}
                click={(hour: string) => {
                  handleHour(hour);
                }}
              />
            </div>
          </div>

          <p className="aviso">
            Para maior aproveitamento, pedimos que deixe uma mensagem ao
            consultor com as suas dúvidas e assuntos que gostaria de tratar na
            consultoria.
          </p>

          <p className="alert">
            Atenção! A consultoria deverá seguir com o foco em sua dúvida, o
            consultor vai abordar somente as dúvidas e assuntos enviados nese
            formulário.
          </p>

          <p className="question">
            Qual o assunto a ser tratado na consultoria ?
          </p>

          <div className="inputAssunto">
            <Inputdefault
              tipo="text"
              value={DTO?.assunto}
              placeholder="Assuntos..."
              change={(e: any) => {
                handleOnChange('assunto', e.target.value);
              }}
            />
          </div>

          <p className="question">
            Descreva melhor o que você precisa saber para o consultor poder se
            preparar para a consultoria.
          </p>

          <textarea
            value={DTO?.comentario}
            className="input"
            placeholder="Assuntos..."
            onChange={(e) => {
              handleOnChange('comentario', e.target.value);
            }}
          />

          <div className="areaButton">
            <ButttonDefault
              title="Agendar Consultoria"
              tipo="1"
              onClick={async () => {
                bookConsultancie();
              }}
              icon={loadingBookingConsultancie ? 'Loading-White' : ''}
            />
          </div>
        </>
      ) : (
        <EmptyPosts
          title="Escolha um consultor"
          description="Para ver os horários disponíveis escolha um consultoras"
        />
      )}
    </S.Container>
  );
}
