import { useEffect, useState } from 'react';
import apiDoubts from '../../services/api';
import { IconVerify } from '../Icons';
import { Container } from './styles';
import moment from 'moment';

interface CProps {
  ConsultorId?: string;
  AgenciaId?: string;
  click?: () => void;  
}

export default function ConsultorCard({
  ConsultorId,
  click,
  AgenciaId,
}: CProps) {
  const [consultor, setConsultor] = useState<any>();
  const [agencia, setAgencia] = useState<any>();

  useEffect(() => {
    getConsultorOrAgency();
  }, [ConsultorId]);

  async function getConsultorOrAgency() {
    if (ConsultorId) {
      const response = await apiDoubts.get(
        `/consultor/get-by-id/${ConsultorId}`
      );
      setConsultor(response?.data?.data[0]);
    } else if (AgenciaId) {
      await apiDoubts
        .get(`/empresa/get-by-id/${AgenciaId}`)
        .then((response: any) => {
          // console.log(response?.data?.data[0]);
          setAgencia(response?.data?.data);
        });
    }
  }

  return (
    <Container onClick={click}>
      <div
        className="fotoPerfil"
        style={{
          backgroundImage: `url(${
            AgenciaId ? agencia?.foto : consultor?.foto
          })`,
        }}
      ></div>
      <div className="areaInfo">
        <div className="areaName">
          <p className="name">
            {AgenciaId ? agencia?.nickName : consultor?.nickName}
          </p>
          <IconVerify />
        </div>
        <p className="seguidores">
          {moment(consultor?.createdAt).locale('pt-br').fromNow()}
        </p>

        <p className="seguidores">
          {AgenciaId
            ? agencia?.seguidores?.length
            : consultor?.seguidores?.length}
          {AgenciaId
            ? agencia?.seguidores?.length > 1
              ? 'Seguidores'
              : 'Seguidor'
            : consultor?.seguidores?.length > 1
            ? ' Seguidores'
            : ' Seguidor'}
        </p>
      </div>
    </Container>
  );
}
