import styled, { css } from 'styled-components';

interface props {
  selected?: boolean;
  tipo: string;
}

export const Container = styled.div<props>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--background2);
  min-height: 40px;
  border-radius: 0px 10px 10px 0px;
  margin-right: 10px;
  padding-left: 18px;
  padding-right: 9px;

  ${({ tipo }) =>
    tipo === 'gratis' &&
    css`
      background-color: var(--warning);
    `}

  ${({ selected }) =>
    selected &&
    css`
      background-color: var(--paleta2A);
    `}

  p {
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: var(--paleta2C);

    ${({ selected }) =>
      selected &&
      css`
        color: var(--branco);
      `}
  }

  @media (max-width: 800px) {
    gap: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--background2);
    min-height: 40px;
    border-radius: 10px;
    margin-right: 10px;
    padding-left: 18px;
    padding-right: 9px;

    ${({ selected }) =>
      selected &&
      css`
        background-color: var(--paleta2A);
      `}

    p {
      font-size: 13px;
      line-height: 18px;
      font-weight: 400;
      color: var(--paleta2C);

      ${({ selected }) =>
        selected &&
        css`
          color: var(--branco);
        `}
    }
  }
`;
