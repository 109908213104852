import { useContext, useEffect, useState } from 'react';
import Banner from '../../components/Banner';
import CardMenuOportunidade from '../../components/CardMenuOportunidade';
import CardMenuOportunidadeAgencia from '../../components/Cards/CardMenuOportunidadeAgencia';
import CardOportunidadeEmpresa from '../../components/Cards/CardOportunidadeEmpresa';
import UserContext from '../../contexts/UserContext';
import apiDoubts from '../../services/api';
import { Container, Left, Right } from './styles';
import ButttonDefault from '../../components/ButtonDefault';
import Inputdefault from '../../components/Inputs/InputDefault';
import SearchIcon from '../../assets/icons/searchIcon';
import { useDebouncedCallback } from 'use-debounce';
import { IconLoading } from '../../components/Icons';

export default function Oportunidades() {
  const [banner, setBanner] = useState<any[]>([]);
  const [consultoresOportunidade, setConsultoresOportunidades] = useState<
    any[]
  >([]);
  const [empresaOportunidade, setEmpresaOportunidade] = useState<any[]>([]);
  const [agenciOportunidade, setAgenciaOportunidade] = useState<any[]>([]);

  const [numberOfConsultants, setNumberOfCounsultants] = useState<number>(4);
  const [numberOfAgencies, setNumberOfAgencies] = useState<number>(4);
  const [numberOfEnterprises, setNumberOfEnterprises] = useState<number>(4);

  const [nickNameSearch, setNickNameSearch] = useState<string>('');
  const [searchingUsersByName, setSearchingUsersByName] = useState<boolean>(false);

  
  const debounced = useDebouncedCallback(
    // function
    (value) => {
      setSearchingUsersByName(true)
      setNickNameSearch(value);
    },
    600
  );

  const { user } = useContext<any>(UserContext);

  useEffect(() => {
    if (banner?.length === 0) {
      getBanner();
    }
    getConsultoresOportunidades();
    getEmpresasOportunidades();
    getAgenciaOportunidades()

    setNumberOfCounsultants(4)
    setNumberOfAgencies(4)
    
  }, [nickNameSearch]);

  async function getBanner() {
    await apiDoubts.get(`/banners?limit=2`).then((response: any) => {
      setBanner(response?.data?.data);
    });
  }

  async function getConsultoresOportunidades() {
    apiDoubts
      .get(
        `/consultor/get-all?ordem=seguidores&limit=40&visivelOportunidades=true&search=${nickNameSearch ?? ''}`
      )
      .then((response: any) => {
        setSearchingUsersByName(false)
        setConsultoresOportunidades(response?.data?.data?.docs);
      });
  }

  async function getEmpresasOportunidades() {
    apiDoubts
      .get(`/empresa/get-all?limit=1000&visivelOportunidades=true&search=${nickNameSearch ?? ''}`)
      .then((response: any) => {
        setEmpresaOportunidade(response?.data?.data);
        setSearchingUsersByName(false)
      });
  }

  async function getAgenciaOportunidades() {
    apiDoubts
      .get(`/agencia/get-all?limit=40&visivelOportunidades=true&search=${nickNameSearch ?? ''}`)
      .then((response: any) => {
        setAgenciaOportunidade(response?.data?.data.docs);
        setSearchingUsersByName(false)
      });
  }

  return (
    <Container>
      <Left>
        <div className="topArea">
          <h1 className="titleOportunidades">Oportunidades</h1>
          <div className="inputContainer">
            <Inputdefault
              change={(e: React.ChangeEvent<HTMLInputElement>) => {
                debounced(e.target.value);
              }}
              placeholder="Pesquise pelo apelido"
              tipo="text"
              icon={ searchingUsersByName ? <IconLoading color="var(--dark-grey)" size={14}/> : <SearchIcon color="var(--dark-grey)" />}
            />
          </div>
        </div>

        {consultoresOportunidade?.length > 0 && user?.tipo !== 'consultor' && (
          <>
            <div className="topSectionOportunidades">
              <div className="textSectionOportunidades">
                <h2 className="DescriptionOportunidades">
                  Consultores que podem agregar à sua {user?.tipo}!
                </h2>
              </div>
            </div>
            <div className="mainSectionOportunidades">
              {consultoresOportunidade?.map((row: any, key: number) => {
                if (key < numberOfConsultants) {
                  return (
                    <CardMenuOportunidade
                      consultorData={row}
                      invite={(id: string) => {
                      }}
                    />
                  );
                }
              })}
            </div>
            {consultoresOportunidade.length >= numberOfConsultants && (
              <div className="buttonWrapper">
                <ButttonDefault
                  title={'Ver mais'}
                  tipo="2"
                  onClick={() => {
                    setNumberOfCounsultants((oldValue) => oldValue + 4);
                  }}
                />
              </div>
            )}
          </>
        )}

        {empresaOportunidade?.length > 0 && user?.tipo !== 'empresa' && (
          <>
            <div className="topSectionOportunidades">
              <div className="textSectionOportunidades">
                <h2 className="DescriptionOportunidades">
                  Empresas que podem agregar à sua {user?.tipo}!
                </h2>
              </div>
            </div>
            <div className="mainSectionOportunidades">
              {empresaOportunidade.map((row: any, key: number) => {
                if (key < numberOfEnterprises) {
                  return (
                    <CardOportunidadeEmpresa
                      consultorData={row}
                      invite={(id: string) => {
                      }}
                    />
                  );
                }
              })}
            </div>
            {empresaOportunidade.length >= numberOfEnterprises && (
              <div className="buttonWrapper">
                <ButttonDefault
                  title={'Ver mais'}
                  tipo="2"
                  onClick={() => {
                    setNumberOfEnterprises((oldValue) => oldValue + 6);
                  }}
                />
              </div>
            )}
          </>
        )}

        {agenciOportunidade?.length > 0 && user?.tipo !== 'agencia' && (
          <>
            <div className="topSectionOportunidades">
              <div className="textSectionOportunidades">
                <h2 className="DescriptionOportunidades">
                  Agências que podem agregar à sua {user?.tipo}!
                </h2>
              </div>
            </div>
            <div className="mainSectionOportunidades">
              {agenciOportunidade.map((row: any, key: number) => {
                if (key < numberOfAgencies) {
                  return (
                    <CardMenuOportunidadeAgencia
                      consultorData={row}
                      invite={(id: string) => {
                      }}
                    />
                  );
                }
              })}
            </div>
            {agenciOportunidade.length >= numberOfAgencies && (
              <div className="buttonWrapper">
                <ButttonDefault
                  title={'Ver mais'}
                  tipo="2"
                  onClick={() => {
                    setNumberOfAgencies((oldValue) => oldValue + 4);
                  }}
                />
              </div>
            )}
          </>
        )}
        
      </Left>
      <Right>
        <Banner peso="1" position="1" typeBanner="322x227" />
        <Banner peso="1" position="1" typeBanner="322x673" />
      </Right>
    </Container>
  );
}
