import { useState } from 'react';
import AnunciosVerticais from '../../components/Anuncio/AnunciosVerticais';
import ListPostsFavoritos from '../../components/Posts/Favoritos';
import TopConsultores from '../../components/TopConsultores';
import { Container, ContainerContent } from './styles';

const Home: React.FC = (props: any) => {
  const [ordenacao] = useState<any>({
    label: 'Populares',
    value: 'popular',
  });
  const [interesse] = useState<string>('');
  const [hashtag] = useState<string>();

  return (
    <Container>
      <ContainerContent>
        <div className="section-left">
          <ListPostsFavoritos
            order={ordenacao.value}
            where={{
              interesse: interesse,
              hash: hashtag,
            }}
          />
          <div className="areaButton"></div>
        </div>
        <div className="section-right">
          <AnunciosVerticais
            count={1}
            where={{
              interesse: interesse,
              search: '',
              hashs: [],
            }}
          />
          <TopConsultores direction="colum" />
          {/* <Hashtags
            currentHashtags={hashtag}
            hash={(e) => setHashtag(e)}
            title={true}
            position={'lateral'}
          /> */}
        </div>
      </ContainerContent>
    </Container>
  );
};

export default Home;
