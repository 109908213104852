import { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import UserContext from '../../contexts/UserContext';
import apiDoubts from '../../services/api';
import ButttonDefault from '../ButtonDefault';
import { IconLoading } from '../Icons';
import { Container } from './styles';

interface VProps {
  comment: (e: any) => void;
  videoId?: any;
  reload?: boolean;
  getComents?: () => void;
  consultorId?: string;
}

export default function ComentarArea({
  comment,
  consultorId,
  videoId, 
  reload,
  getComents = () => {},
}: VProps) {
  const { user } = useContext<any>(UserContext);
  const textAreaRef = useRef<any>();
  const [loadComment, setLoadComment] = useState(false);
  const [focus, setFocus] = useState(false);

  const [DTO, setDTO] = useState<any>({
    comentario: '',
    consultorId: consultorId,
  } as any);

  const [DTOVideo, setDTOVideo] = useState<any>({
    comentario: '',
    postagemId: videoId,
  } as any);

  useEffect(() => {
    if (videoId) handleOnChange('postagemId', videoId);
  }, [videoId]);

  function handleOnChange(name: any, value: any) {
    if (consultorId) {
      const newDTO: any = DTOVideo;
      newDTO[name] = value;
      setDTO({ ...newDTO });
      return;
    } else if (videoId.length > 0) {
      const newDTO: any = DTOVideo;
      newDTO[name] = value;
      setDTOVideo({ ...newDTO });
    }
  }

  useEffect(() => {
    console.log('user', user);
  }, [user]);

  async function handleSubmitComment(e: any) {
    {
      try {
        e.preventDefault();
        if (!DTOVideo.comentario)
          throw new Error('Por favor, digite um comentário');
        if (!user._id) {
          handleOnChange('comentario', '');
          throw new Error('É necessário ter uma conta para poder comentar...');
        }

        handleComment();
      } catch (err: any) {
        toast.error(err.message, {
          className: 'toast-error',
          theme: 'colored',
        });
      }
    }
  }

  async function handleComment() {
    if (consultorId) {
      console.log('caiu no consultor', consultorId);
      setLoadComment(true);
      const response = await apiDoubts.post('/comentarios-canal', DTOVideo);
      console.log('postou no canal', response);
      getComents();
      setLoadComment(false);
    } else {
      setLoadComment(true);
      console.log('caiu no comentário', DTOVideo);
      const response = await apiDoubts.post('/comentarios', DTOVideo);
      handleOnChange('comentario', '');
      console.log('else, comentar area');
      getComents();
      setLoadComment(false);
    }
  }

  function focusTextArea() {
    textAreaRef.current.focus();
  }

  return (
    <Container
      focus={focus}
      user={user}
      value={!!DTOVideo.comentario || !!DTO.comentario}
    >
      <h2>Comente</h2>
      <div className="areaComment">
        <div
          className="image"
          style={{ backgroundImage: `url(${user.foto})` }}
        />
        <div className="areaLeft">
          <h3>{user?.nickName}</h3>
          <textarea            
            value={DTOVideo.comentario}
            className="comment"
            onChange={(e) => {
              handleOnChange('comentario', e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSubmitComment(e)
                }
            }}
          ></textarea>

          <div className="load" onClick={focusTextArea}>
            <h4 className="fakePlaceholder">
              {loadComment && <IconLoading size="20" />}
              {!loadComment &&
                !focus &&
                DTO.comentario.length === 0 &&
                DTOVideo.comentario.length === 0 &&
                'comentar...'}
            </h4>
          </div>

          <div className="areaSubmit">
            <ButttonDefault
              icon={loadComment ? 'Loading' : ''}
              title="Enviar"
              tipo="6"
              onClick={handleSubmitComment}
            />
          </div>
        </div>
      </div>
    </Container>
  );
}
