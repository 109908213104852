import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import CardCaseConfig from '../../../../components/Cards/CardCaseConfig';
import UserContext from '../../../../contexts/UserContext';
import apiDoubts from '../../../../services/api';
import { Container } from './styles';
import EmptyPosts from '../../../../components/EmptyPosts';
import { IconLoading } from '../../../../components/Icons';

interface props {
  getCasesAtt: boolean;
}

export default function Cases({ getCasesAtt }: props) {
  const [cases, setCases] = useState<any[]>([]);
  const [loadingCases, setLoadingCases] = useState<boolean>(true);

  const { user } = useContext<any>(UserContext);

  useEffect(() => {
    if (user.id) {
      getCases();
    }
  }, [user, getCasesAtt]);

  async function getCases() {
    try {
      await apiDoubts
        .get(`/case/agencia?agenciaId=${user?.id}`)
        .then((response: any) => {
          setLoadingCases(false);
          if (response?.data?.status === 'error')
            throw new Error(response?.data?.message?.message);

          setCases(response?.data?.data);
        });
    } catch (err: any) {
      setLoadingCases(false);
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  async function deleteCaseDeSucesso(id: string): Promise<any> {
    try {
      await apiDoubts.delete(`/case/agencia/${id}`).then((response: any) => {
        if (response?.data?.status === 'error')
          throw new Error(response?.data?.message?.message);
        console.log(response);
        console.log(id);

        getCases();

        toast.success('Case de sucesso excluido', {
          className: 'toast-sucess',
          theme: 'colored',
        });

        return true;
      });
    } catch (err: any) {
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  return (
    <>
      <Container>
        {loadingCases && (
          <div className="loadingWrapper">
            <IconLoading color="var(--Paleta1A)" />
          </div>
        )}

        {cases.length === 0 && !loadingCases && (
          <EmptyPosts
            title="Ops, você ainda não possui Casos de sucesso"
            description="Preste serviços para uma empresa e adicione o caso ao seu perfil !!!"
          />
        )}

        {cases.length > 0 &&
          !loadingCases &&
          cases.map((row: any, key: number) => (
            <CardCaseConfig
              isConfig={false}
              data={row}
              key={key}
              deleteCaseDeSucesso={async (id: string) => {
                await deleteCaseDeSucesso(id);
              }}
            />
          ))}
      </Container>
    </>
  );
}
