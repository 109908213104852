// Libraries
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import moment from 'moment';
import 'moment/locale/pt-br';

// Types
import { IChartDashboard } from './types';

// Components
import { ChartDashboardTooltip } from '../ChartDashboardTooltip';

const ChartDashboard = ({
  chartData,
  isLoading,
  likes = true,
  views = true,
}: IChartDashboard) => {
  const { data, keys } = chartData;

  const areaColors = [
    {
      id: 'colorPv',
      color: 'var(--paleta1B)',
      stroke: ' #03667d',
    },
    {
      id: 'colorUv',
      color: 'var(--paleta2E)',
      stroke: '#5d529f',
    },
    {
      id: 'colorAv',
      color: 'var(--btn-aceitar)',
    },
  ];

  function handleDateTick(tick: string): string {
    let formattedDate = '';

    const dateFormat = /^\d{2}-\d{2}-\d{4}$/; // 'DD-MM-YYYY';

    if (dateFormat?.test(tick)) {
      formattedDate = moment(tick, 'DD-MM-YYYY')
        ?.locale('pt-br')
        ?.format('DD [de] MMM[.]');
    } else {
      formattedDate = moment(tick, 'MM-YYYY')
        ?.locale('pt-br')
        ?.format('MMM[.] [de] YYYY');
    }

    return formattedDate?.charAt(0)?.toUpperCase() + formattedDate?.slice(1);
  }

  function handleGroupingTick(tick: string) {
    const intTick = parseInt(tick);

    return intTick?.toLocaleString(undefined, { useGrouping: true });
  }

  console.log(data);

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={730}
          height={250}
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            {keys?.map((_, index) => (
              <linearGradient
                id={areaColors[index]?.id}
                key={index}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset="50%"
                  stopColor={`${areaColors[index]?.color}`}
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor={`${areaColors[index]?.color}`}
                  stopOpacity={0.1}
                />
              </linearGradient>
            ))}
          </defs>
          <XAxis
            dataKey="dia"
            tickMargin={10}
            tickFormatter={handleDateTick}
            interval="preserveStartEnd"
            minTickGap={20}
          />
          <YAxis
            dataKey={keys[0]?.dataKey}
            allowDecimals={false}
            tickMargin={10}
            tickFormatter={handleGroupingTick}
          />
          <Tooltip
            content={<ChartDashboardTooltip />}
            animationEasing="ease-in"
          />
          {keys &&
            keys?.map((key, index) => (
              <Area
                key={key?.dataKey}
                type="monotone"
                name={key?.label}
                dataKey={key?.dataKey}
                stroke={
                  views
                    ? `rgba(${areaColors[index]?.stroke})`
                    : `rgba(${areaColors[index]?.stroke}, 0.3)`
                }
                fillOpacity={views ? 1 : 0.4}
                fill={`url(#${areaColors[index]?.id})`}
                animationDuration={800}
              />
            ))}
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
};

export default ChartDashboard;
