import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ButttonDefault from '../../../components/ButtonDefault';
import Inputdefault from '../../../components/Inputs/InputDefault';
import CardExcluir from '../../../components/Posts/CardExcluir';
import apiDoubts from '../../../services/api';
import { Container } from './styles';
import { useDebouncedCallback } from 'use-debounce';
import ModalCancelarConsultoria from '../../../components/ModalCancelarConsultoria';
import ModalAlert from '../../../components/ModalAlert';
import UserContext from '../../../contexts/UserContext';
import SelectDefaultSearch from '../../../components/Form/SelectDefaultSearch';

export default function EditarPlaylist() {
  const params: any = useParams();
  const history = useHistory();
  const [data, setData] = useState<any[]>([]);
  const [videos, setVideos] = useState<any[]>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [completeData, setCompleteData] = useState<any[]>([]);
  const [selected, setSelected] = useState<any[]>();
  const { user } = useContext(UserContext);
  const [DTO, setDTO] = useState<any>({
    titulo: '',
    descricao: 'Essa é a segunda playlist do nosso canal',
    listaPostagens: [],
  });

  useEffect(() => {
    if (params.id) {
      getPlaylist(params.id);
    }
  }, [params]);

  useEffect(() => {
    if (user.id) {
      getData();
    }
  }, [user]);

  useEffect(() => {
    console.log('videos atualizou', videos);
  }, [videos]);

  useEffect(() => {
    console.log('DTO atualizou', DTO.listaPostagens);
  }, [DTO]);

  function handleOnChange(name: any, value: any) {
    const newDTO: any = DTO;
    newDTO[name] = value;
    setDTO({ ...newDTO });
  }

  async function getPlaylist(id: string) {
    apiDoubts.get(`playlists/${id}`).then((response: any) => {
      console.log(response.data.data);
      const newDTO = {
        titulo: response.data.data.titulo,
        descricao: response.data.data.descricao,
        listaPostagens: response.data.data.listaPostagens,
      };
      setDTO(newDTO);
      setVideos(response.data.data.postagens);
    });
  }

  function handleDelete(id: string) {
    const newArray = DTO.listaPostagens.filter((val: any) => {
      return val != id;
    });
    handleOnChange('listaPostagens', newArray);

    const newArrayVideoComplete = videos.filter((val: any) => {
      if (val.id != id) {
        return val;
      }
    });
    setVideos(newArrayVideoComplete);
  }

  function submit() {
    try {
      apiDoubts.put(`/playlists/${params.id}`, DTO).then((response: any) => {
        toast.success('Playlist Atualizada com sucesso.', {
          className: 'toast-sucess',
          theme: 'colored',
        });
        history.goBack();
      });
    } catch (err: any) {
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  async function deletePlaylist() {
    setModal(false);
    apiDoubts.delete(`/playlists/${params.id}`).then(() => {
      history.push('/consultor/playlist');
    });
  }

  async function getData() {
    apiDoubts
      .get(`/postagens/getPostagemConsultor/${user.id}`)
      .then((response: any) => {
        let data = response.data.data;
        setCompleteData(data);
        options(data);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  function options(data: any) {
    let newData: any[] = [];
    data.map((row: any) => {
      let option = {
        value: row.id,
        label: row.titulo,
      };
      newData.push(option);
    });
    setData(newData);
  }

  function videosSelect(e: any) {
    try {
      DTO?.listaPostagens.map((row: any) => {
        if (row === e.value)
          throw new Error('Esse vídeo já está inserido na sua playlist');
      });
      completeData.map((row: any) => {
        if (row.id === e.value) {
          setVideos((oldArray) => [row, ...oldArray]);
          let newListaPostagensDTO = DTO?.listaPostagens;
          newListaPostagensDTO.unshift(row?.id);
          handleOnChange('listaPostagens', newListaPostagensDTO);
        }
      });
    } catch (err: any) {
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  return (
    <Container>
      <ModalAlert
        prosseguir={deletePlaylist}
        id="modal Excluir playlist"
        onClose={() => {
          setModal(false);
        }}
        openModal={modal}
      >
        Tem certeza de que deseja excluir essa playlist ? você não poderá voltar
        atrás depois...
      </ModalAlert>
      <div className="areaLeft">
        <h2 className="title">Editar Playlist</h2>
        <div className="areaInput">
          <h3 className="subtitle">Título</h3>
          <Inputdefault
            value={DTO.titulo}
            placeholder="Digite um titulo para o video"
            tipo="text"
            change={(e: any) => {
              handleOnChange('titulo', e.target.value);
            }}
          />
          <h3 className="subtitle">Descrição</h3>
          <textarea
            placeholder="Descrição do vídeo..."
            value={DTO.descricao}
            className="comment"
            onChange={(e) => {
              handleOnChange('descricao', e.target.value);
            }}
          ></textarea>
        </div>
        <div className="areaButton">
          <h2 className="title">Adicionar vídeos</h2>
          <div style={{ zIndex: 2, position: 'relative' }}>
            <SelectDefaultSearch
              onChange={(e: any) => {
                videosSelect(e);
              }}
              options={data}
              key={'select para selecionar os videos da playlist'}
              tipo={'1'}
              value={{ label: 'Selecione um video...', value: 'tete' }}
            />
          </div>
        </div>
        <div className="videos">
          <h3 className="subtitle">Vídeos</h3>
          <div className="cards">
            {videos.map((row: any, key: number) => (
              <div className="card">
                <CardExcluir
                  key={key}
                  item={row}
                  deletar={(id: string) => {
                    handleDelete(id);
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="footer">
          <ButttonDefault
            title={'Excluir Playlist'}
            onClick={() => {
              setModal(true);
            }}
            tipo={'8'}
            hover={'medio'}
          />
          <ButttonDefault
            title={'Confirmar Alterações'}
            tipo={'3'}
            onClick={() => {
              submit();
            }}
            hover="medio"
          />
        </div>
      </div>
    </Container>
  );
}
