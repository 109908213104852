import { useContext, useEffect, useState } from 'react';
import UserContext from '../../contexts/UserContext';
import apiDoubts from '../../services/api';
import ComentariosCard from '../ComentarioCard';
import ComentarAreaConsultor from '../CommentAreaConsultor';
import Skeleton from '../Skeleton';
import WarningLogout from '../WarningLogout';
import { Container } from './styles';

interface VProps {
  videoId?: string;
  video?: any;
  consultorId?: string;
  reload?: boolean;
  setReload?: any;
}

export default function Comentarios({
  videoId,
  video,
  consultorId,
  reload,
  setReload,
}: VProps) {
  const [commentConsultor, setCommentConsultor] = useState<any[]>();
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState<any[]>([]);
  const { user } = useContext(UserContext);
  useEffect(() => {
    getData();
  }, [videoId]);

  useEffect(() => {
    if (consultorId) {
      handleConsultorId();
    }
  }, [consultorId]);

  async function getData() {
    setLoading(true);
    const response: any = await apiDoubts.get(
      `/comentarios-canal-consultor/${consultorId}?pagination=true&limit=20&page=1`
    );
    setData([...response.data.data.docs]);
    setLoading(false);
  }

  async function handleConsultorId() {
    const response = await apiDoubts.get(
      `/comentarios-canal-consultor/${consultorId}`
    );
    setCommentConsultor(response.data.data.docs);
  }

  return (
    <>
      {user.login && user.tipo === 'cadastro' && (
        <div className="comentar">
          <ComentarAreaConsultor
            loading={loading}
            getComents={getData}
            consultorId={consultorId}
          />
        </div>
      )}

      <Container>
        <p>
          {user.login
            ? data.length === 0
              ? 'Comentário'
              : ' Comentários'
            : null}
        </p>

        {loading &&
          [0, 1, 2, 3, 4].map((i) => (
            <ComentariosCard loading={true} key={i} comentario={{}} />
          ))}
        {loading === false &&
          data.map((row: any, key: number) => (
            <ComentariosCard key={key} comentario={row} />
          ))}
      </Container>
    </>
  );
}
