import styled, { css } from 'styled-components';

interface PProps {
  posicao?: string;
  type?: string;
  playlist?: boolean;
}

export const Container = styled.div<PProps>`
  cursor: pointer;
  width: calc(10% - 30px);
  position: relative;

  @media (max-width: 3100px) {
    width: calc(20% - 30px);
  }
  @media (max-width: 1919px) {
    width: calc(25% - 30px);
  }
  @media (max-width: 1600px) {
    width: calc(33.3% - 30px);
  }
  @media (max-width: 1279px) {
    width: calc(50% - 30px);
  }
  @media (max-width: 940px) {
    width: 100%;
  }

  ${(props) =>
    props.posicao === 'lateral' &&
    props.type !== 'anuncio' &&
    css`
      display: flex;
      gap: 10px;
      align-items: center;
    `}

  .image {
    &:hover {
      .overlay-play {
        opacity: 1;
        visibility: visible;
        svg {
          transform: scale(1);
        }
      }
    }
    border-radius: 10px;

    height: ${(props) => (props.posicao === 'lateral' ? '84px' : '182px')};
    min-width: ${(props) => props.posicao === 'lateral' && '130px'};

    background-position: center;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;

    .react-loading-skeleton {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .overlay-play {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.5s;
      opacity: 0;
      visibility: hidden;
      svg {
        transition: all 0.5s;
        transform: scale(0);
        width: 80px;
        height: 80px;
      }

      ${(props) =>
        props.posicao === 'lateral' &&
        props.type !== 'anuncio' &&
        css`
          svg {
            width: 35px;
            height: 35px;
          }
        `}
    }
  }

  .infos {
    margin-top: ${(props) => (props.posicao === 'lateral' ? '0px' : '11px')};
    display: flex;
    width: 100%;

    .image-profile {
      width: 40px;
      height: 40px;
      min-width: 40px;
      border-radius: 100%;
      background-position: center;
      background-size: cover;
      margin-right: 7px;
      background-color: rgba(0, 0, 0, 0.1);
      position: relative;
      overflow: hidden;
      .react-loading-skeleton {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .info-post {
      display: flex;
      flex-direction: column;
      width: 100%;
      .title {
        font-size: ${(props) =>
          props.posicao === 'lateral' ? '12.5px' : '16px'};
        line-height: 20px;
        font-weight: 700;
        color: ${(props) =>
          props.playlist ? 'var(--branco)' : 'var(--paleta2C)'};

        width: 100%;
        min-height: 20px;
      }
      .author {
        font-size: 13px;
        line-height: 15px;
        font-weight: 700;
        color: ${(props) =>
          props.playlist ? 'var(--paleta2D)' : 'var(--paleta1B)'};
        margin-top: 6px;
        width: 100%;
        min-height: 15px;

        ${(props) =>
          props.posicao === 'lateral' &&
          props.type !== 'anuncio' &&
          css`
            font-size: 11px;
            line-height: 14px;
          `}
      }
      .date {
        font-size: 13px;
        line-height: 14px;
        font-weight: 400;
        color: #7d7d7d;
        margin-top: 3px;
        width: 100%;
        min-height: 14px;

        ${(props) =>
          props.posicao === 'lateral' &&
          props.playlist &&
          css`
            font-size: 11px;
            line-height: 14px;
            display: none;
          `}

        ${(props) =>
          props.posicao === 'lateral' &&
          props.type !== 'anuncio' &&
          css`
            font-size: 11px;
            line-height: 14px;
          `}
      }
    }
  }

  @media (max-width: 800px) {
    max-width: 100%;
    .image {
      min-height: 130px;
      min-width: 180px;
    }
  }

  @media (max-width: 425px) {
    width: 100vw;

    .image {
      height: 220px;
      background-color: var(--background2);
      ${(props) =>
        props.posicao === 'lateral' &&
        props.type !== 'anuncio' &&
        css`
          height: 90px;
        `}
    }
  }

  .liveWrapper {
    width: min-content;
    height: min-content;
    padding: 5px;
    background-color: var(--warning);
    position: absolute;
    top: 0;
    right: 0;

    margin-top: 5px;
    margin-right: 5px;
    border-radius: 5px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    gap: 5px;

    .embreve {
      font-size: 12px;
      white-space: nowrap;
      font-weight: 500;
      color: var(--white);
    }

    ${({ posicao }) =>
      posicao === 'lateral' &&
      css`
        position: absolute;
        left: 0;
        top: 0;
        margin-left: -2px;
        transform: scale(0.8);
      `}
  }
`;

export const ContainerLateral = styled.a<PProps>`
  cursor: pointer;
  width: 100%;
  text-decoration: none;

  ${(props) =>
    props.posicao === 'lateral' &&
    props.type !== 'anuncio' &&
    css`
      display: flex;
      gap: 10px;
      align-items: center;
    `}

  .image {
    &:hover {
      .overlay-play {
        opacity: 1;
        visibility: visible;
        svg {
          transform: scale(1);
        }
      }
    }
    border-radius: 10px;

    height: ${(props) => (props.posicao === 'lateral' ? '84px' : '182px')};
    min-width: ${(props) => props.posicao === 'lateral' && '130px'};
    background-position: center;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;

    .react-loading-skeleton {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .overlay-play {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.5s;
      opacity: 0;
      visibility: hidden;
      svg {
        transition: all 0.5s;
        transform: scale(0);
        width: 80px;
        height: 80px;
      }

      ${(props) =>
        props.posicao === 'lateral' &&
        props.type !== 'anuncio' &&
        css`
          svg {
            width: 35px;
            height: 35px;
          }
        `}
    }
  }

  .infos {
    margin-top: ${(props) => (props.posicao === 'lateral' ? '0px' : '11px')};
    display: flex;
    width: 100%;

    .image-profile {
      width: 40px;
      height: 40px;
      min-width: 40px;
      border-radius: 100%;
      background-position: center;
      background-size: cover;
      margin-right: 7px;
      background-color: rgba(0, 0, 0, 0.1);
      position: relative;
      overflow: hidden;
      .react-loading-skeleton {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .info-post {
      display: flex;
      flex-direction: column;
      width: 100%;
      .title {
        font-size: ${(props) =>
          props.posicao === 'lateral' ? '12.5px' : '16px'};
        line-height: 20px;
        font-weight: 700;
        color: ${(props) =>
          props.playlist ? 'var(--branco)' : 'var(--paleta2C)'};

        width: 100%;
        min-height: 20px;
      }
      .author {
        font-size: 13px;
        line-height: 15px;
        font-weight: 700;
        color: ${(props) =>
          props.playlist ? 'var(--paleta2D)' : 'var(--paleta1B)'};
        margin-top: 6px;
        width: 100%;
        min-height: 15px;

        ${(props) =>
          props.posicao === 'lateral' &&
          props.type !== 'anuncio' &&
          css`
            font-size: 11px;
            line-height: 14px;
          `}
      }
      .date {
        font-size: 13px;
        line-height: 14px;
        font-weight: 400;
        color: #7d7d7d;
        margin-top: 3px;
        width: 100%;
        min-height: 14px;

        ${(props) =>
          props.posicao === 'lateral' &&
          props.playlist &&
          css`
            font-size: 11px;
            line-height: 14px;
            display: none;
          `}

        ${(props) =>
          props.posicao === 'lateral' &&
          props.type !== 'anuncio' &&
          css`
            font-size: 11px;
            line-height: 14px;
          `}
      }
    }
  }

  @media (max-width: 800px) {
    max-width: 100%;
    .image {
      min-height: 130px;
      min-width: 180px;
    }
  }

  @media (max-width: 425px) {
    max-width: 100%;
    .image {
      height: 220px;
      background-color: var(--background2);
      ${(props) =>
        props.posicao === 'lateral' &&
        props.type !== 'anuncio' &&
        css`
          height: 90px;
        `}
    }
  }
`;
