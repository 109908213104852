import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  .video {
    background-color: var(--background2);
    min-height: 182px;
    min-width: 283px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 100px;
      height: 100%;
      path {
        stroke: var(--paleta1B);
      }
    }
    max-width: 100%;
  }
  .info {
    margin-top: 10px;
    display: flex;
    gap: 7px;
    .profile {
      min-width: 40px;
      min-height: 40px;
      max-width: 40px;
      max-height: 40px;
      border-radius: 50%;
      background-position: center;
      background-size: cover;
    }
    .content {
      .title {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        color: var(--paleta2C);
      }
      .name {
        font-size: 13px;
        font-weight: 700;
        line-height: 15px;
        color: var(--paleta1B);
      }
      .visu {
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        color: var(--texto);
      }
    }
  }

  @media (max-width: 1150px) {
    .video {
      width: 520px;
      height: 220px;
    }
  }

  @media (max-width: 800px) {
    .video {
      height: 230px;
      width: 100%;
      max-width: 520px;
    }
  }

  @media (max-width: 440px) {
    max-height: 180px;
  }
`;
