import styled, { css } from 'styled-components';

interface menuProps {
  menuSelected: boolean;
}

interface menuEntradaProps {
  menuEntradaSelected: boolean;
}

export const Container = styled.div`
  min-height: calc(100vh - 110px);
  display: flex;
  gap: 20px;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
  }
`;



export const CardMenu = styled.button<menuProps>`
  border: none;
  background-color: transparent;
  display: flex;
  gap: 5px;

  @media(max-width: 800px){
    width: max-content;
    white-space: nowrap;
  }


  .titleMenu {
    ${({ menuSelected }) =>
      menuSelected
        ? css`
            font-size: 13px;
            font-weight: 700;
            color: var(--texto);
          `
        : css`
            font-size: 13px;
            font-weight: 400;
            color: var(--texto);
          `}
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;

  @media (max-width: 800px) {
    width: 100%;
  }

  .topbar {
    width: 100%;
    height: 84px;
    background-color: var(--paleta2C);
    border-radius: 10px 10px 0 0;
    padding: 10px;
    padding-right: 15px;
    position: relative;

    @media(max-width: 800px){
        border-radius: 10px;
    }

    .areaInit {
      height: 36px;
      display: flex;
      justify-content: space-between;

      .topBarInit{
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: flex-start;

        .deleteEmails{
          border: none;
          background-color: transparent;
        }

      }

      .topBarEnd {
        display: flex;
        gap: 5px;
        justify-content: center;

        .pages {
          color: var(--branco);
          font-size: 11px;
          font-weight: 400;
          line-height: 14px;
        }

        .buttonPagination {
          border: none;
          background-color: transparent;
          height: 10px;
        }

        svg {
          path {
            fill: var(--paleta2E);
          }
        }
      }
    }
    .areaEnd {
      position: absolute;
      width: calc(100% - 20px);
      height: 30px;
      bottom: 0;
      display: flex;
      @media(max-width: 800px){
        overflow: scroll;
        gap: 20px;
        ::-webkit-scrollbar{
            display: none;
        }
      }
    }

    .headerEmailSelected {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      .init {
        display: flex;
        gap: 15px;
        align-items: center;
        .emailSelectTitle {
          font-size: 18px;
          font-weight: 600;
          line-height: 27px;
          color: var(--branco);
        }
      }
      .dateEmailSelect {
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: var(--branco);
      }
    }
  }

  .content {
    background-color: var(--branco);
    padding-top: 10px;
    min-height: calc(100% - 84px);
    display: flex;
    align-items: center;
    flex-direction: column;
    .divLoad {
      width: 100%;
      height: 300px;
      display: flex;
      justify-content: center;
      span {
        gap: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .mailContent {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 20px;
      min-width: 100%;
      .buttonSubmitNewEmailMessage {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
`;

export const MenuHeader = styled.button<menuEntradaProps>`
  width: 144px;
  display: flex;
  justify-content: center;
  gap: 10px;
  border: none;
  background-color: transparent;

  svg {
    path {
      fill: var(--branco);
    }
  }
  .textMenuEntrada {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: var(--branco);
  }

  ${({ menuEntradaSelected }) =>
    menuEntradaSelected
      ? css`
          border-bottom: 6px solid var(--paleta2E);
          .textMenuEntrada {
            font-size: 13px;
            font-weight: 700;
          }
        `
      : css`
          border-bottom: 6px solid transparent;
          .textMenuEntrada {
            font-size: 13px;
            font-weight: 400;
            color: var(--branco);
          }
        `}
`;

export const ButtonNewEmail = styled.button`
position: fixed;
height: 40px;
width: 40px;
border-radius: 50%;
background-color: var(--paleta1A);
bottom: 0;
right: 0;
margin-right: 20px;
margin-bottom: 20px;
display: flex;
align-items: center;
justify-content: center;
z-index: 99999999;
`
