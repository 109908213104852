import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import IconPlay from '../../../assets/icons/IconPlay';
import UserContext from '../../../contexts/UserContext';
import { IconExternalLink, IconLive } from '../../Icons';
import { Container, ContainerLateral } from './styles';
import moment from 'moment';
import { encodeConsultoria } from '../../../pages/Live/Consultoria';

interface IItem {
  comentarios: any;
  consultorId: string;
  consultores: any;
  createdAt: any;
  data: string;
  deleted: boolean;
  deletedAt: any;
  destaque: number;
  hashtags: any;
  id: string;
  interesses: any;
  listaComentarios: any[];
  listaHashtags: any[];
  listaInteresses: any[];
  qtdComentarios: number;
  qtdFavoritos: number;
  qtdVisualizacoes: number;
  thumbnail: string;
  thumbnailFull: string;
  tipo: string;
  titulo: string;
  updatedAt: any;
  video: string;
  __v: number;
  _id: string;
  nickName?: string;
  agencias: any;
  agenciaId?: string;
}

interface IProps {
  playlist?: any;
  posicao?: string;
  item?: IItem | any;
  loading?: boolean;
  type?: 'anuncio' | 'consultor' | 'preview' | any;
}

export default function CardPost({
  item,
  loading,
  type,
  posicao,
  playlist,
}: IProps) {
  const history = useHistory();
  const { loadingVideo, setLoadingVideo } = useContext(UserContext);

  const { user } = useContext(UserContext);

  function handleVisualizar() {
    if (loading) return false;
    setLoadingVideo(true);

    if (playlist) {
      history.push(`/visualizar/playlist/${playlist._id}/${item?._id}`);
    }
    !playlist && type !== 'anuncio' && history.push(`/visualizar/${item?._id}`);
  }

  function goToLive(idConsultoria: string) {
    history.push(
      `/consultoria/${encodeConsultoria({
        consultoriaId: idConsultoria,
        start: moment(item.consultoriaGratis.data).format('YYYY-MM-DD HH:mm'),
        end: moment(item.consultoriaGratis.horarioFim).format(
          'YYYY-MM-DD HH:mm'
        ),
      })}`
    );

    // http://localhost:3000/consultoria/63ed11583b18492094ecd539?inicio=2023-04-27T07:50:00-03:00&fim=2023-04-27T08:00:00-03:00&id=644a52d60ae2cd7dc539e968
  }

  function handlePath(): any {
    if (loading) return false;
    setLoadingVideo(true);
    if (playlist && type !== 'anuncio') {
      if (process.env.NODE_ENV === 'development') {
        return `/visualizar/playlist/${playlist._id}/${item?._id}`;
      } else {
        return `/visualizar/playlist/${playlist._id}/${item?._id}`;
      }
    }
    if (!playlist && type !== 'anuncio') {
      if (process.env.NODE_ENV === 'development') {
        return `/visualizar/${item?._id}`;
      } else {
        return `/visualizar/${item?._id}`;
      }
    }
  }

  function handlePage() {
    if (loading) return false;
    if (item?.consultorId) {
      history.push(`/consultor/visualizar/canal/${item?.consultorId}`);
    } else {
      history.push(`/empresa/visualizar/canal/${item?.empresaId}`);
    }
  }

  function handleAnuncio() {
    window.open(`https://google.com`, '_blank');
  }

  const Skeleton = () => {
    return (
      <span
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          backgroundColor: '#eaeaea',
          borderRadius: 8,
        }}
      ></span>
    );
  };

  // console.log(item)

  return (
    <>
      {posicao === 'lateral' && !item.consultoriaGratis ? (
        <ContainerLateral posicao={posicao} type={type} playlist={playlist}>
          {item?.type === 'anuncio' ? (
            <div
              className="image"
              style={{
                backgroundImage: `url(${item?.thumbnail})`,
              }}
            >
              <a href={'https://google.com'} target="blank">
                <div className="overlay-play">
                  {type === 'anuncio' ? <IconExternalLink /> : <IconPlay />}
                </div>
              </a>
            </div>
          ) : (
            <div
              className="image"
              style={{
                backgroundImage: `url(${item?.thumbnail})`,
              }}
              onClick={handleVisualizar}
            >
              <div className="overlay-play">
                {type === 'anuncio' ? <IconExternalLink /> : <IconPlay />}
              </div>
              {loading === true && <Skeleton />}
            </div>
          )}

          <div className="infos">
            {type === 'agencia' && <div>Agencia</div>}

            {type !== 'consultor' && posicao !== 'lateral' && (
              <>
                <div
                  onClick={type === 'anuncio' ? handleAnuncio : handlePage}
                  className="image-profile"
                  style={{
                    backgroundImage: `url(${
                      item?.agenciaId
                        ? `https://doubts.backendtropa.com.br${item?.agencias?.foto}`
                        : item?.consultores?.foto
                    })`,
                  }}
                >
                  {loading === true && <Skeleton />}
                </div>
              </>
            )}

            {loading === true ? (
              <div className="info-post">
                <span className="title">
                  <Skeleton />
                </span>
                <span className="author">
                  <Skeleton />
                </span>
                <span className="date">
                  <Skeleton />
                </span>
              </div>
            ) : (
              <div className="info-post">
                <span
                  onClick={
                    type === 'anuncio' ? handleAnuncio : handleVisualizar
                  }
                  className="title"
                >
                  {item?.titulo ?? '...'}
                </span>

                {type !== 'consultor' && (
                  <>
                    <span
                      className="author"
                      onClick={type === 'anuncio' ? handleAnuncio : handlePage}
                    >
                      {item?.agenciaId
                        ? item?.agencias?.nickName
                        : item?.consultores?.nickName}
                    </span>
                  </>
                )}

                {type !== 'anuncio' && (
                  <span className="date" onClick={handleAnuncio}>
                    {item?.qtdVisualizacoes} visualizações •{' '}
                    {moment(item?.data).locale('pt-br').fromNow()}
                  </span>
                )}
              </div>
            )}
          </div>
        </ContainerLateral>
      ) : item?.tipo === 'live' ? (
        <Container posicao={posicao} type={type} playlist={playlist}>
          <div
            className="image"
            style={{
              backgroundImage: `url(${item?.thumbnail})`,
            }}
            onClick={() => {
              goToLive(item?.consultoriaGratis?._id);
            }}
          >
            <div className="overlay-play">
              {type === 'anuncio' ? <IconExternalLink /> : <IconPlay />}
            </div>
            {loading === true && <Skeleton />}
          </div>

          <div className="infos">
            {type === 'agencia' && <div>Agencia</div>}

            {type !== 'consultor' && posicao !== 'lateral' && (
              <>
                <div
                  onClick={type === 'anuncio' ? handleAnuncio : handlePage}
                  className="image-profile"
                  style={{
                    backgroundImage: `url(${
                      item?.agenciaId
                        ? `https://doubts.backendtropa.com.br${item?.agencias?.foto}`
                        : item?.consultores?.foto
                    })`,
                  }}
                >
                  {loading === true && <Skeleton />}
                </div>
              </>
            )}

            {loading === true ? (
              <div className="info-post">
                <span className="title">
                  <Skeleton />
                </span>
                <span className="author">
                  <Skeleton />
                </span>
                <span className="date">
                  <Skeleton />
                </span>
              </div>
            ) : (
              <div className="info-post" onClick={() => goToLive(item.id)}>
                <span className="title">{item?.titulo ?? '...'}</span>

                {type !== 'consultor' && (
                  <>
                    <span className="author">
                      {item?.agenciaId
                        ? item?.agencias?.nickName
                        : item?.consultores?.nickName}
                    </span>
                  </>
                )}

                {type !== 'anuncio' && (
                  <span className="date">
                    {moment(item?.data).locale('pt-br').fromNow()}
                  </span>
                )}
              </div>
            )}
          </div>

          <span className="liveWrapper">
            <IconLive color={'var(--white)'} size={16} />
            <p className="embreve">
              {moment(item?.consultoriaGratis?.data).isBefore(new Date())
                ? 'ao vivo'
                : 'em breve'}
            </p>
          </span>
        </Container>
      ) : (
        <Container posicao={posicao} type={type} playlist={playlist}>
          {item?.type === 'anuncio' ? (
            <div
              className="image"
              style={{
                backgroundImage: `url(${item?.thumbnail})`,
              }}
              onClick={type === 'anuncio' ? handleAnuncio : handleVisualizar}
            >
              <div className="overlay-play">
                {type === 'anuncio' ? <IconExternalLink /> : <IconPlay />}
              </div>
              {loading === true && <Skeleton />}
            </div>
          ) : (
            <div
              className="image"
              style={{
                backgroundImage: `url(${item?.thumbnail})`,
              }}
              onClick={handleVisualizar}
            >
              <div className="overlay-play">
                {type === 'anuncio' ? <IconExternalLink /> : <IconPlay />}
              </div>
              {loading === true && <Skeleton />}
            </div>
          )}

          <div className="infos">
            {type === 'agencia' && <div>Agencia</div>}

            {type !== 'consultor' && posicao !== 'lateral' && (
              <>
                <div
                  onClick={type === 'anuncio' ? handleAnuncio : handlePage}
                  className="image-profile"
                  style={{
                    backgroundImage: `url(${
                      item?.empresaId
                        ? `${item?.empresas?.foto}`
                        : item?.consultores?.foto
                    })`,
                  }}
                >
                  {loading === true && <Skeleton />}
                </div>
              </>
            )}

            {loading === true ? (
              <div className="info-post">
                <span className="title">
                  <Skeleton />
                </span>
                <span className="author">
                  <Skeleton />
                </span>
                <span className="date">
                  <Skeleton />
                </span>
              </div>
            ) : (
              <div className="info-post">
                <span
                  onClick={
                    type === 'anuncio' ? handleAnuncio : handleVisualizar
                  }
                  className="title"
                >
                  {item?.titulo ?? '...'}
                </span>

                {type !== 'consultor' && (
                  <>
                    <span
                      className="author"
                      onClick={type === 'anuncio' ? handleAnuncio : handlePage}
                    >
                      {item?.empresaId
                        ? item?.empresas?.nickName
                        : item?.consultores?.nickName}
                    </span>
                  </>
                )}

                {type !== 'anuncio' && (
                  <span className="date" onClick={handleVisualizar}>
                    {item?.qtdVisualizacoes} visualizações •{' '}
                    {moment(item?.data).locale('pt-br').fromNow()}
                  </span>
                )}
              </div>
            )}
          </div>
        </Container>
      )}
    </>
  );
}
