import styled from 'styled-components';

export const Container = styled.div`
  .generic {
    height: 300px;
    width: 100%;
    display: flex;
    gap: 20px;
    margin-top: 20px;

    .content {
      width: 100%;
    }
    .sideRigth {
      background-color: lightcyan;
      width: 314px;
      height: calc(100% + 60px);
      margin-top: -60px;
    }
  }

  .canal {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 20px;

    .DescriptionConsultor {
      width: 100%;
      gap: 20px;

      display: flex;
      justify-content: space-between;

      .banner {
        min-width: 300px;
        max-width: 30%;
        height: 227px;
        background-size: cover;
        background-position: center;
        border-radius: 10px;

        margin-top: -60px;
      }
    }

    .contentCanal {
      width: 100%;
    }
  }
`;

// import styled from 'styled-components';

// export const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   height: 100%;
//   .toast-sucess {
//     background: var(--green) !important;
//   }
//   .toast-error {
//     background: var(--purple2);
//     color: var(--green);
//   }
//   @media (max-width: 425px) {
//     flex-direction: column;
//     align-items: center;
//   }
// `;

// export const Content = styled.div`
//   padding: 40px;
// `;

// export const Header = styled.div`
//   background-color: var(--purple2);
//   width: 100%;
//   height: 180px;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 40px;
//   border-radius: 8px 8px 0 0;

//   .infos {
//     display: flex;
//     align-items: center;
//     gap: 40px;
//   }

//   .logo {
//     display: flex;
//     align-items: center;
//     width: 100px;
//     height: 100px;
//     border-radius: 100%;
//     background-size: cover;
//     background-position: center;
//   }

//   .title {
//     .name {
//       display: flex;
//       align-items: center;
//       gap: 10px;
//       color: var(--white);
//       font-weight: 800;
//       font-size: 22px;
//       margin-bottom: 10px;

//       .on,
//       .off {
//         width: 50px;
//         border-radius: 4px;
//         padding: 5px 10px;
//         font-size: 10px;
//         font-weight: 600;
//       }
//       .on {
//         background: var(--online);
//       }
//       .off {
//         background: var(--offline);
//       }
//     }
//     .inscriptions {
//       color: var(--mid-grey);
//       font-size: 14px;
//     }
//   }

//   .subscribe {
//     .follow,
//     .unfollow {
//       cursor: pointer;
//       svg {
//         width: 40px;
//         height: 40px;
//       }
//     }
//   }
// `;

// export const Main = styled.div`
//   width: 100%;
//   background-color: #f1f1f1;
//   border-radius: 0 0 4px 4px;
//   position: relative;

//   .menu {
//     display: flex;
//     gap: 30px;
//     position: absolute;
//     top: 0;
//     left: 5%;
//     margin-top: -30px;

//     .tab {
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       width: 80px;
//       height: 30px;
//       border-bottom: 4px solid transparent;
//       cursor: pointer;
//       transition: all 0.2s;
//       &.about {
//         border-bottom: 4px solid var(--green);
//       }
//       &.video {
//         border-bottom: 4px solid var(--green);
//       }
//     }
//   }

//   .containerCanal {
//     .consultorDescription {
//       padding-top: 20px;
//       padding-left: 40px;
//       width: 100%;
//       background-color: transparent;
//       display: flex;
//       gap: 3rem;
//       align-items: center;
//       justify-content: space-between;

//       .consultorModal {
//         width: 50%;
//       }

//       img {
//         width: 100%;
//         margin-right: 40px;
//       }
//     }
//   }

//   .videos-consultant {
//     display: grid;
//     grid-template-columns: repeat(auto-fit, minmax(19rem, 1fr));
//     gap: 25px;
//     padding: 40px;
//     cursor: pointer;

//     .image {
//       position: relative;
//       width: 100%;
//       height: 200px;
//       background-size: cover;
//       .player {
//         width: 100%;
//         height: 100%;
//         position: absolute;
//         top: 0px;
//         left: 0px;
//         background: transparent;
//         cursor: pointer;
//         transition: all 0.5s;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         svg {
//           transition: all 0.2s;
//           path {
//             fill: var(--white);
//           }
//         }
//         svg {
//           width: 50px;
//           height: 100%;
//         }
//         &:hover {
//           background: rgba(0, 0, 0, 0.5);
//           border-radius: 4px;
//           svg {
//             position: relative;
//             transform: scale(1.2);
//             path {
//               fill: var(--white);
//             }
//           }
//         }
//       }
//     }

//     .cardInfo {
//       display: flex;
//       align-items: flex-start;
//       margin-top: 20px;
//       width: 100%;
//       position: relative;
//       footer {
//         display: flex;
//         flex-direction: column;
//         align-items: flex-start;
//         .header {
//           display: flex;
//           justify-content: space-evenly;
//           gap: 20px;
//           width: 100%;
//           .title {
//             width: calc(100% - 60px);
//             h3 {
//               font-size: 16px;
//               font-weight: 700;
//               line-height: 18px;
//               color: var(--purple4);
//               text-overflow: ellipsis;
//               white-space: nowrap;
//               overflow: hidden;
//               width: 250px;
//             }
//             p {
//               margin-bottom: 0px;
//             }
//           }
//         }
//         a {
//           text-decoration: none;
//         }

//         p {
//           font-size: 12px;
//           font-weight: 700;
//           line-height: 14px;
//           color: var(--purple6);

//           margin: 8px 0;
//         }

//         .visual {
//           font-size: 12px;
//           font-weight: 400;
//           line-height: 12px;
//           color: var(--dark-grey);
//         }
//       }
//     }
//   }

//   .about-consultant {
//     display: flex;
//     gap: 20px;
//     padding: 40px;

//     .description {
//       width: 50%;
//       color: var(--dark-grey);
//       font-size: 18px;
//     }

//     .stats-consultant {
//       width: 50%;

//       .name-consultant {
//         display: flex;
//         gap: 10px;
//         color: var(--dark-grey);
//         font-weight: 600;
//         font-size: 18px;
//       }

//       .info-stats {
//         display: flex;
//         align-items: center;
//         gap: 15px;
//         height: 45px;
//         border-bottom: 2px solid var(--mid-grey);
//         color: var(--dark-grey);

//         .icon {
//           svg {
//             path {
//               fill: var(--green);
//             }
//           }
//         }
//       }
//       .info-title {
//         font-weight: 500;
//       }

//       .icon {
//         svg {
//           path {
//             fill: var(--green);
//           }
//         }
//       }
//     }
//   }
// `;
