import { useHistory } from 'react-router-dom';
import ButttonDefault from '../../ButtonDefault';
import ButtonSeguir from '../../ButtonSeguir';
import MiniButton from '../../Empresa/MiniButton';
import {
  IconFacebook,
  IconInstagram,
  IconTikTok,
  IconTwitter,
  IconWWW,
} from '../../Icons';
import { Container } from './styles';

interface IAgenciaProps {
  data: any;
}

export default function HeaderHomeAgencia({ data }: IAgenciaProps) {
  console.log('dataAgencia ====>', data);

  const history = useHistory();
  const agenciaId = history?.location?.pathname?.split('/')?.pop();

  return (
    <Container>
      {data && (
        <>
          <div
            className="bannerImageAgencia"
            style={{ backgroundImage: `url(${data?.capa})` }}
          >
            <div
              className="logoAgencia"
              style={{ backgroundImage: `url(${data?.foto})` }}
            >
              <div className="areaMiniButtons">
                <MiniButton
                  icon={<IconWWW />}
                  path={data?.link_site}
                  key={'Mini ButtonWWW'}
                />
                <MiniButton
                  icon={<IconTwitter />}
                  path={data?.link_twitter}
                  key={'Mini ButtonTwitter'}
                />
                <MiniButton
                  icon={<IconFacebook />}
                  path={data?.link_facebook}
                  key={'Mini Buttonfacebook'}
                />
                <MiniButton
                  icon={<IconInstagram />}
                  path={data?.link_instagram}
                  key={'Mini ButtonInstagram'}
                />
                <MiniButton
                  icon={<IconTikTok />}
                  path={data?.link_tiktok}
                  key={'Mini ButtonTiktok'}
                />
                <ButttonDefault title={'Agência'} tipo="7" />
              </div>
            </div>
          </div>
          <div className="textHeaderAgencia">
            <div className="topTextHeaderAgencia">
              <div className="textAndButtonSeguir">
                <h2 className="nameAgencia">{data?.nickName}</h2>
                {agenciaId !== data?._id && <ButtonSeguir click={() => {}} />}
              </div>
            </div>
            <p className="agenciaDescription">{data?.descricaoCanal}</p>
          </div>
        </>
      )}
    </Container>
  );
}
