import { Container, Title } from './styles';
import { IconCheck } from '../Icons';
import Close from '../../assets/icons/Close';

interface AvailabilityProps {
  title: string;
  value: boolean;
}

export default function Availability({ title, value }: AvailabilityProps) {
  return (
    <Container>
      {value === true ? <IconCheck /> : <Close />}
      <Title>{title}</Title>
    </Container>
  );
}
