import styled from 'styled-components';

export const Container = styled.div`
  .generic {
    height: 300px;
    width: 100%;
    display: flex;
    gap: 20px;
    margin-top: 20px;
    .content {
      width: 100%;
    }
    .sideRigth {
      background-color: lightcyan;
      width: 314px;
      height: calc(100% + 60px);
      margin-top: -60px;
    }
  }

  .playlist {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 20px;

    .DescriptionConsultor {
      width: 100%;
      gap: 20px;
      display: flex;
      justify-content: space-between;

      .banner {
        cursor: pointer;
        min-width: 300px;
        max-width: 30%;
        height: 227px;
        background-size: cover;
        background-position: center;
        border-radius: 10px;
        background-color: var(--background2);
        margin-top: -60px;
      }
    }

    .contentCanal {
      width: 100%;
    }
  }

  @media (max-width: 1050px) {
    .playlist {
      .DescriptionConsultor {
        flex-direction: column;
        .banner {
          min-width: 100%;
          height: 113.5px;
          margin-top: -30px;
        }
      }
    }
  }
`;
