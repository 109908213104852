import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Banner from '../../Banner';
import Comentarios from '../../Comentarios';
import HeaderConsultor from '../../HeaderConsultor';
import HeaderType from '../../HeaderType';
import ListPlaylist from '../../Playlist/ListPlaylist';
import apiDoubts from '../../../services/api';
import { Container } from './styles';
import HeaderEmpresa from '../HeaderEmpresa';

export default function Playlist() {
  const params = useParams<any>();
  const [banner, setBanner] = useState<any>('');

  useEffect(() => {
    getBanner();
  }, []);

  async function getBanner() {
    const response = await apiDoubts.get('/banners?tipo=2&randon=true');
    setBanner(response.data.data[0]);
    console.log(response.data.data[0]);
  }

  return (
    <Container>
      <HeaderEmpresa id={params?.id} page="playlist" />

      <main className="playlist">
        <div className="contentCanal">
          <ListPlaylist empresaId={params.id} recarregar={false} />
        </div>
      </main>
    </Container>
  );
}
