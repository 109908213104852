import { AxiosResponse } from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import apiDoubts from '../services/api';
import UserContext from './UserContext';

const CoinsContext = createContext<any>({} as any);

interface transferCoins {
  destinatarioId: string;
  destinatarioTipo: string;
  nickName: string;
  valor: number;
}

export const CoinsProvider: React.FC = ({ children }) => {
  const [coins, setCoins] = useState<number>(100);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user.id) {
      getCoins();
    }
  }, []);

  async function addCoins(value: number) {
    try {
      await apiDoubts
        .post(`wallet-insert`, { valor: value })
        .then((response: AxiosResponse<any>) => {
          console.log('adicinou saldo', response);
          toast.success('saldo adicionado com sucesso !', {
            className: 'toast-sucess',
            theme: 'colored',
          });
          setCoins(response?.data?.data?.saldoAtual);
          return true;
        })
        .catch(() => {});
    } catch (err: any) {
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
      return err;
    }
  }

  async function getCoins() {
    try {
      await apiDoubts
        .get(`wallet-balance`)
        .then((response: AxiosResponse<any>) => {
          setCoins(response?.data?.data[0]?.saldo);
        });
    } catch (err: any) {
    }
  }

  async function transferCoins(data: transferCoins) {
    await apiDoubts.post(`wallet-transfer`, data).then(() => {
      toast.success('Moedas transferidas com sucesso !', {
        className: 'toast-sucess',
        theme: 'colored',
      });
      getCoins();
      return true;
    });
  }

  return (
    <CoinsContext.Provider value={{ coins, addCoins, transferCoins }}>
      {children}
    </CoinsContext.Provider>
  );
};

export function useUser() {
  const context = useContext(CoinsContext);
  return context;
}

export default CoinsContext;
