/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, useRef } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import UserContext from '../../contexts/UserContext';
import apiDoubts from '../../services/api';
import ButttonDefault from '../ButtonDefault';
import ConsultorCard from '../ConsultorCard';
import VideoDescricao from '../VideoDescricao';
import VideoUi from '../VideoUi';
import { Container, Title, Component } from './styles';

import { WhatsappShareButton } from 'react-share';
import VideoPlayer from '../videoPlayer';
import { toast } from 'react-toastify';
import VideoADS from '../videoADS';
import moment from 'moment';
import axios from 'axios';

interface VProps {
  video?: any;
  like?: any;
  deslike?: any;
  loading?: boolean;
  videoProgress?: (video: any) => void;
  handleOnEndedVideo?: () => void;
}

export default function VideoVisualizar({
  video = '',
  like,
  deslike,
  loading,
  videoProgress,
  handleOnEndedVideo,
}: VProps) {
  const params = useParams<any>();
  const history = useHistory();
  const { user } = useContext(UserContext);
  const [seguidor, setSeguidor] = useState<boolean>(false);
  const [follower, setFollower] = useState<boolean>(false);
  const [buttonSkip, setButtonSkip] = useState<boolean>(false);
  const [tipoVideo, setTipoVideo] = useState<string>('ads');
  const [videoAds, setVideoAds] = useState<any>('');
  const [loadButton, setLoadButton] = useState<string>('');
  const [favorite, setFavorite] = useState<boolean>(false);
  const videoRef = useRef<any>();
  const [submitFollow, setSubmitFollow] = useState<any>();
  const [loadSeguir, setLoadSeguir] = useState<boolean>(false);

  // ------------- NEW STATES ------------- //
  const [nextVideo, setNextVideo] = useState<any>(null);
  const [nextVideoTimer, setNextVideoTimer] = useState<number>(5);

  const actionButtonsRef = useRef(null);
  const [isAdsPlaying, setIsAdsPlaying] = useState<boolean>(true);

  useEffect(() => {
    if (video) {
      handleLink(video?.video);
      getStatusSeguidor();
    }
  }, [video]);

  useEffect(() => {
    setFavorite(video?.isFavorite);
    if (video) {
      handleEmpresaOrConsultor(video);
    }
  }, [video]);

  useEffect(() => {
    isFollower();
  }, []);

  useEffect(() => {
    getAdsVideo();
  }, [video]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      handleAdsViews();
      setVideoAds('');
    });

    return () => {
      unlisten();
    };
  }, [history]);

  function handleLink(link: any) {
    if (link) {
      let convertLink = link
        .replace('/watch?v=', '/embed/')
        .replace('&', ' ')
        .split(' ');

      return convertLink[0];
    }
  }

  async function getStatusSeguidor() {
    if (!video?.consultorId) return;
    await apiDoubts
      .get(
        `/${video?.consultorId ? 'consultor' : 'empresa'}/seguidor/status/${
          video?.consultorId
        }`
      )
      .then((response) => {
        setSeguidor(response?.data?.data?.seguindo);
      });
  }

  function handleEmpresaOrConsultor(video: any) {
    if (video?.consultorId) {
      setSubmitFollow({ consultorId: video?.consultorId });
    } else {
      setSubmitFollow({ empresaId: video?.empresaId });
    }
  }

  async function handleFollower() {
    setLoadSeguir(true);
    if (user.id) {
      await apiDoubts
        .put(`/consultor/seguidor/novo`, submitFollow)
        .then((response) => {
          like();
          setLoadSeguir(false);
        })
        .catch(() => {
          setLoadSeguir(false);
        });
    } else {
      setLoadSeguir(false);
      toast.error('É preciso estar logado para seguir um consultor', {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  async function isFollower() {
    if (video) {
      setFollower(video?.seguindo);
    }
  }

  async function handleLike(e: React.MouseEvent<HTMLButtonElement>) {
    if (user.id) {
      setLoadButton('like');

      video.curtida = !video.curtida;
      if (video.desLike) video.desLike = !video.desLike;

      const handleLikeCallback = handleInstantFeedback(e);

      await apiDoubts
        .post(`/postagens-curtir-descurtir/${video.id}`)
        .then((response: any) => {
          like();
          if (handleLikeCallback) handleLikeCallback();
        });
    } else {
      setLoadButton('false');
      toast.error('É preciso estar logado para curtir um vídeo', {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  async function handleDeslike(e: React.MouseEvent<HTMLButtonElement>) {
    setLoadButton('deslike');

    if (user.id) {
      video.desLike = !video.desLike;
      if (video.curtida) video.curtida = !video.curtida;

      const handleDeslikeCallback = handleInstantFeedback(e);
      await apiDoubts.post(`/postagens-nao-gostei/${video.id}`).then(() => {
        deslike();
        if (handleDeslikeCallback) handleDeslikeCallback();
      });
    } else {
      setLoadButton('false');
      toast.error('É preciso estar logado para descurtir um vídeo', {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  async function handleFav() {
    if (user.id) {
      setLoadButton('favoritar');
      if (video?.isFavorite) {
        apiDoubts.delete(`/favoritos/${video?.id}`).then(() => {
          setFavorite(!favorite);
          like();
        });
      } else {
        await apiDoubts
          .post(`favoritos`, {
            postagemId: video.id,
          })
          .then(() => {
            setFavorite(!favorite);
            like();
          });
      }
    } else {
      setLoadButton('false');
      toast.error('É preciso estar logado para favoritar um vídeo', {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  async function getAdsVideo() {
    try {
      await apiDoubts
        .get(
          `/campanha-exibir?video=1&position=1&peso=1&random&consultorId=${video?.consultorId}`
        )
        .then((response: any) => {
          if (response?.data) {
            axios.get(response?.data?.data[0].video);
            setVideoAds(response?.data?.data[0]);
          } else {
            throw new Error('Nenhum anúncio.');
            setVideoAds(
              `https://doubts.backendtropa.com.br/uploads/postagens/1673984891104.mp4`
            );
          }
        });
    } catch (error) {
      handleSkipAds();
    }
  }

  function handleInstantFeedback(e: React.MouseEvent<HTMLButtonElement>) {
    if (!actionButtonsRef.current) return;

    const currentButton = e?.currentTarget;
    currentButton.disabled = true;

    const allButtonsRef: any = actionButtonsRef as any;

    const likeButton: HTMLButtonElement = allButtonsRef?.current?.childNodes[0];
    const deslikeButton: HTMLButtonElement =
      allButtonsRef?.current?.childNodes[1];

    if (likeButton.disabled) deslikeButton.disabled = true;
    if (deslikeButton.disabled) likeButton.disabled = true;

    setTimeout(() => {
      setLoadButton('');
    }, 300);

    function callbackFeedback() {
      setTimeout(() => {
        currentButton.disabled = false;
        likeButton.disabled = false;
        deslikeButton.disabled = false;
      }, 1000 * 1.5);
    }

    return callbackFeedback;
  }

  function handleSkipAds() {
    setTipoVideo('Video');
    handleAdsViews();
    localStorage?.removeItem('hasViewed');
  }

  async function handleAdsViews() {
    const playedSeconds = parseInt(
      localStorage?.getItem('adsVideoProgress') as string
    );

    const lastTimeViewed = localStorage?.getItem('hasViewed');

    if (moment(lastTimeViewed, 'HH:mm:ss')?.seconds() <= 120) return;

    apiDoubts
      ?.post(`/visualizar-campanha`, {
        campanhaId: videoAds?.id,
        autorCampanhaId: videoAds?.empresaId,
        usuarioId: user?._id ?? null,
        tipoUsuario: user?.tipo ?? null,
        tempoAssistido: playedSeconds,
      })
      ?.catch((err) => {
        console.log(err);
      })
      ?.finally(() => {
        localStorage?.removeItem('adsVideoProgress');
      });
  }

  async function handleAdsClick() {
    apiDoubts?.post(`/clicar-campanha`, {
      campanhaId: videoAds?.id,
      autorCampanhaId: videoAds?.empresaId,
      usuarioId: user?._id,
      tipoUsuario: user?.tipo,
    });

    handleAdsViews();
    localStorage?.setItem('hasViewed', moment()?.format('HH:mm:ss'));
  }

  async function getRelatedPost(id: string): Promise<any> {
    const relatedPost = await apiDoubts.post(
      `postagens/relacionadas?limit=10&page=1&pagination=true`,
      {
        postId: id,
      }
    );
    return relatedPost;
  }

  useEffect(() => {
    setButtonSkip(false);
    setTipoVideo('ads');
  }, [loading]);

  function gerarNumeroAleatorio(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  async function handleEndVideo() {
    let ramdomPosition = gerarNumeroAleatorio(0, 9);

    const relatedPost = await getRelatedPost(video?._id);
    setNextVideo(relatedPost.data.data[ramdomPosition]);

    const videoTimer = setInterval(() => {
      if (nextVideoTimer > 0) {
        setNextVideoTimer((actualValue) => actualValue - 1);
      }
    }, 1000);

    setTimeout(() => {
      clearInterval(videoTimer);
      history.push(
        `/visualizar/${relatedPost?.data?.data[ramdomPosition]?.id}`
      );
      setNextVideo(null);
      setNextVideoTimer(5);
    }, 5000);
  }

  function handlePostView() {
    const progressVideo = JSON.parse(
      localStorage?.getItem('videoProgress') as string
    );

    const viewedPercentage = Math.round(progressVideo?.played * 100) >= 30; // 30%
    const viewedSeconds = Math.round(progressVideo?.playedSeconds) >= 60 * 2; // 2 Minutes

    const currentVideo = JSON.parse(
      localStorage?.getItem('currentVideo') as string
    );

    if (viewedPercentage || viewedSeconds) {
      postView(currentVideo?.listaInteresses);
    }

    handleClearVideoStatistics();
  }

  function handleClearVideoStatistics() {
    const videoProgress = localStorage?.getItem('videoProgress');

    if (videoProgress) localStorage?.removeItem('videoProgress');

    localStorage?.removeItem('currentVideo');
  }

  useEffect(() => {
    const unlisten = history.listen(handlePostView);

    return () => {
      unlisten();
    };
  }, [history, params]);

  async function postView(listaInteresses: string[]) {
    try {
      await apiDoubts
        .post('/visualizacoes', {
          postagemId: params?.id,
          usuarioId: user?.id ?? null,
          tipoUsuario: user?.tipo,
          listaInteresses: listaInteresses,
        })
        .then((response) => {});
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Container tipo={tipoVideo} skip={buttonSkip} ref={videoRef}>
      <div className="content">
        {tipoVideo !== 'Video' && (
          <div className="adsVideo">
            <VideoADS
              thumbnail={video.thumbnail}
              ads={videoAds}
              onSkip={handleSkipAds}
              loading={loading}
              onAdClick={handleAdsClick}
            />

            {/* isso só vai ser mostrado em telas menores que 450 */}
            <div className="campaignOut ">
              <div
                className="img"
                style={{
                  backgroundImage: `url(${videoAds?.thumbnail ?? ''})`,
                }}
              />
              <div>
                <p>
                  {videoAds?.nome?.substring(0, 30)}{' '}
                  {videoAds?.nome?.length > 30 && '...'}
                </p>
                <Link
                  to={{ pathname: videoAds?.link }}
                  onClick={() => {}}
                  target="_blank"
                >
                  Saiba mais
                </Link>
              </div>
            </div>
          </div>
        )}

        {nextVideo !== null && (
          <div className="nextVideoWrapper">
            <h2>
              {nextVideoTimer > 0
                ? `Próximo vídeo em ${nextVideoTimer}s`
                : `Redirecionando...`}
            </h2>
            <div
              className="videoImage"
              style={{ backgroundImage: `url(${nextVideo?.thumbnail})` }}
            />
          </div>
        )}

        {tipoVideo === 'Video' && (
          <div className="videoOriginal">
            <VideoPlayer
              loading={loading}
              url={video.video}
              autoPlay={true}
              playing={true}
              onProgress={videoProgress}
              onEnded={handleEndVideo}
            />
          </div>
        )}
      </div>

      <Title>{!loading && video?.titulo}</Title>

      {!loading && (
        <>
          <div className="btnArea" ref={actionButtonsRef}>
            <ButttonDefault
              hover="medio"
              chosen={video?.curtida}
              title={window.screen.width > 620 ? 'Curtir' : ''}
              tipo={video?.curtida ? '5' : '2'}
              icon={loadButton === 'like' ? 'Loading' : 'Like'}
              onClick={handleLike}
              type="button"
            />
            <ButttonDefault
              hover="medio"
              chosen={video?.desLike}
              title={window?.screen?.width > 620 ? 'Não gostei' : ''}
              tipo={video?.desLike ? '8' : '2'}
              icon={loadButton === 'deslike' ? 'Loading' : 'Deslike'}
              onClick={handleDeslike}
              type="button"
            />

            <WhatsappShareButton
              type="button"
              url={`https://doubts.frontendtropa.com.br/`}
              title={
                'Dá uma olhada no vídeo que eu encontrei, lembrei de você e acho que você vai amar...'
              }
            >
              <ButttonDefault
                type="button"
                hover="medio"
                title={window?.screen?.width > 620 ? 'Compartilhar' : ''}
                tipo={'2'}
                icon="Compartilhar"
              />
            </WhatsappShareButton>

            <ButttonDefault
              type="button"
              hover="medio"
              title={
                window.screen.width > 620
                  ? favorite
                    ? 'Favorito'
                    : 'Favoritar'
                  : ''
              }
              tipo={favorite ? '8' : '2'}
              icon="Favoritar"
              onClick={handleFav}
              chosen={favorite ? true : false}
            />
            <div className="buttonSeguir">
              <ButttonDefault
                type="button"
                onClick={handleFollower}
                title={video?.seguindo ? 'Deixar de seguir' : 'seguir'}
                tipo={video?.seguindo ? '2' : '1'}
              />
            </div>
          </div>

          <Component />
          <div className="areaConsultor">
            <ConsultorCard
              ConsultorId={!video?.agencias ? video?.consultorId : null}
              AgenciaId={video?.empresas ? video?.empresas?.id : null}
              click={() => {
                history.push(
                  `/${
                    video?.consultorId ? 'consultor' : 'empresa'
                  }/visualizar/canal/${video?.consultorId ?? video?.empresaId}`
                );
              }}
            />
            <div className="btnSeguir">
              <ButttonDefault
                onClick={handleFollower}
                title={video?.seguindo ? 'Deixar de seguir' : 'Seguir'}
                icon={loadSeguir ? 'Loading' : ''}
                tipo={video?.seguindo ? '2' : '1'}
              />
            </div>
          </div>
          <div className="descricao">
            <VideoDescricao descricao={video?.descricao} />
          </div>
          <Component />
        </>
      )}
    </Container>
  );
}
