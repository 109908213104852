import styled from "styled-components";

interface props{
    buttonDeleteOnCapa:boolean
    buttonDeleteOn:boolean
}

export const Container = styled.div<props>`
display: flex;
flex-direction: column;
gap: 20px;
  .subtitleSection{
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      color: var(--paleta2C);
  }
  .UserImages{
      width: 100%;
      position: relative;
      .bannerImage{
          width: 100%;
          height: 124px;
          border-radius: 10px;
          background-color: var(--background2);
          background-position: center;
          background-size: cover;
      }
      .button {
            width: 100%;
            height: 100%;
            position: relative;
            .removeImage,
            .addImage {
              position: absolute;
              right: 0;
              bottom: 0;
              margin-right: 10px;
              margin-bottom: 10px;
              width: 40px;
              height: 40px;
              background-color: var(--warning);
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              border: none;
              outline: none;
              transition: all 0.2s;

              &:hover {
                transform: scale(1.1);
                transition: all 0.2s;
                background-color: var(--offline);
                svg {
                  path {
                    stroke: #fff;
                  }
                }
              }

              @media (max-width: 1250px) {
                margin-right: -5px;
                margin-bottom: -5px;
              }
            }

            .removeImage {
              display: ${({ buttonDeleteOnCapa }) =>
                buttonDeleteOnCapa ? 'flex' : 'none'};
            }

            .addImage {
              display: ${({ buttonDeleteOnCapa }) =>
                buttonDeleteOnCapa ? 'none' : 'flex'};
            }
      }
      .buttonImageProfile {
            width: 100%;
            height: 100%;
            position: relative;
            .removeImage,
            .addImage {
              position: absolute;
              right: 0;
              bottom: 0;
              margin-right: 10px;
              margin-bottom: 10px;
              width: 40px;
              height: 40px;
              background-color: var(--warning);
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              border: none;
              outline: none;
              transition: all 0.2s;

              &:hover {
                transform: scale(1.1);
                transition: all 0.2s;
                background-color: var(--offline);
                svg {
                  path {
                    stroke: #fff;
                  }
                }
              }

              @media (max-width: 1250px) {
                margin-right: -5px;
                margin-bottom: -5px;
              }
            }

            .removeImage {
              display: ${({ buttonDeleteOn }) =>
                buttonDeleteOn ? 'flex' : 'none'};
            }

            .addImage {
              display: ${({ buttonDeleteOn }) =>
                buttonDeleteOn ? 'none' : 'flex'};
            }
      }
      .ProfileImage{
          width: 162px;
          height: 162px;
          border-radius: 50%;
          background-color: var(--background2);
          position: absolute;
          top: 0;
          margin-top: 20px;
          margin-left: 20px;
          -webkit-box-shadow: 0px 0px 9px -4px rgba(0, 0, 0, 0.5);
          box-shadow: 0px 0px 9px -4px rgba(0, 0, 0, 0.8);
          background-position: center;
          background-size: cover;
      }
  }
  .inputNickname{
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .textNickName{
      font-size:13px;
      font-weight: 700;
      line-height: 15px;
      color: var(--texto)
    }
  }
  .inputDescricaoAgencia{
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    .textInputDescricao{
      font-size:13px;
      font-weight: 700;
      line-height: 15px;
      color: var(--texto)
    }
    .descricaoUsuario{
    border: none;
    width: 100%;
    height: 150px;
    background-color: var(--background2);
    resize: none;
    border-radius: 5px;
    padding: 10px;
    outline-width: 1px;
    border: 1px solid transparent;
    outline-color: var(--paleta1A);        

    &:focus {
      transition: 0.2s;
      transform: scale(1.006);
    }  
  }
  }
  .areaInputs{
    display: flex;
    flex-direction: column;
    gap: 10px;

    .inputItem{
      display: flex;
      flex-direction: column;
      gap: 5px;
      
      .labelLink{
        font-size: 13px;
        line-height: 15px;
        font-weight: 700;
        color: var(--texto);
      }
    }
  }
  .areaButton{
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
`