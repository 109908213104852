import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  max-height: min-content;
  color: var(--branco);
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 6px -1px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 6px -1px rgba(0, 0, 0, 0.3);
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .topCardCaseArea {
    display: flex;
    width: 100%;
    .leftTopCardCaseArea {
      min-width: 100%;
      display: flex;
      gap: 10px;
      justify-content: space-between;
      .imageEnterpreuer {
        height: 100px;
        width: 150px;
        aspect-ratio: 4/4;
        background-position: center;
        background-size: cover;
        background-color: var(--background2);
        border-radius: 0 10px 10px 10px;
      }
      .EnterpreuerInfos {
        flex: 1;
        margin-left: 10px;
        width: 50%;
        .EnterpreuerName {
          font-size: 28px;
          font-weight: 700;
          line-height: 42px;
          color: var(--paleta1A);
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
        }
        .CamapingDate {
          font-size: 13px;
          font-weight: 700;
          line-height: 15px;
          color: var(--paleta2E);
        }
      }
      .deleteCase,
      .editCase {
        cursor: pointer;
      }

      @media (max-width: 450px) {
        flex-direction: column;
        .imageEnterpreuer {
          width: clamp(200px, 100%, 100%);
        }
        .EnterpreuerInfos {
          width: 100%;
        }
      }
    }
  }
  .bottomCardCaseArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    .AgencyCommentArea {
      display: flex;
      flex-direction: column;
      gap: 5px;
      .subtitleAgencyComment {
        font-size: 13px;
        font-weight: 700;
        line-height: 15px;
        color: var(--texto);
        position: relative;
        margin-bottom: 5px;

        ::before {
          content: '';
          position: absolute;
          bottom: -3px;
          left: 0;
          height: 1px;
          width: 16px;
          border-radius: 5px;
          background-color: var(--paleta2D);
        }
      }
      .contentAgencyComment {
        font-size: 13px;
        font-weight: 400;
        line-height: 15px;
        color: var(--texto);
        white-space: break-spaces;
      }
    }
    .enterpreuerCommentArea {
      width: calc(100% - 15px);
      position: relative;
      background-color: var(--background2);
      border-radius: 5px;
      padding: 10px;
      margin-left: 10px;
      margin-top: 10px;
      .ButtonEnterpreuerComment {
        height: 30px;
        border-radius: 5px;
        background-color: var(--paleta2D);
        color: var(--branco);
        font-size: 11px;
        font-weight: 700;
        line-height: 15px;
        width: 180px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        margin-top: -10px;
        margin-left: -10px;
      }
      .enterprauerComment {
        margin-top: 20px;
        font-size: 13px;
        font-weight: 400;
        line-height: 15px;
        color: var(--texto);
        white-space: break-spaces;
      }
    }
    .commentArea {
      max-height: 100px;
      width: 100%;
      padding: 5px;
      border-radius: 5px;
      text-overflow: ellipsis;
      overflow: hidden;

      &.longText {
        overflow-y: auto;
        box-shadow: inset 0px -8px 18px -10px rgba(0, 0, 0, 0.1);
      }

      ::-webkit-scrollbar {
        width: 5px;
      }

      ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 3px;
      }

      ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 3px;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
`;

export const AreaModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .inputArea {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .label {
      font-size: 13px;
      font-weight: 700;
      line-height: 15px;
      color: var(--paleta2C);
    }
    .SelectConsultor {
      width: 100%;
      border: none;
      background-color: var(--background2);
      padding: 5px;
      border-radius: 5px;
      outline: none;
    }
    .comment {
      border: none;
      width: 100%;
      height: 120px;
      background-color: var(--background2);
      resize: none;
      border-radius: 5px;
      padding: 10px;
      outline-width: 1px;
      border: 1px solid transparent;
      outline-color: var(--paleta1A);
    }
    .DatePickerInput {
      width: 100%;
      border: none;
      padding-right: 10px;
      margin-right: 10px;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      outline: none;
      ::-webkit-datetime-edit {
        height: 40px;
        background-color: var(--background2);
        border-radius: 5px;
        width: 100%;
        display: flex;
        align-items: center;
        padding-left: 10px;
      }
      ::-webkit-datetime-edit-fields-wrapper {
        background: transparent;
      }
      ::-webkit-datetime-edit-text {
        padding: 0 0.3em;
      }
      ::-webkit-datetime-edit-month-field {
      }
      ::-webkit-datetime-edit-day-field {
      }
      ::-webkit-datetime-edit-year-field {
      }
      ::-webkit-inner-spin-button {
        display: none;
      }
      ::-webkit-calendar-picker-indicator {
        margin-left: 10px;
        color: var(--branco);
        svg {
          path {
          }
        }
      }
    }
  }
  .areaButtons {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
`;
