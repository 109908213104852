import styled from 'styled-components';

export const InputSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 20px);
  margin-right: 40px;

  .icon {
    display: flex;
    align-items: center;
  }

  div {
    cursor: pointer;
    width: 100%;
    max-width: 676px;
    display: flex;
    align-items: center;
    height: 36px;
    background: var(--bg-inputs);
    border-radius: 5px;
    padding-left: 15px;
    input {
      width: 100%;
      background: none;
      color: var(--paleta1C);
      font-size: 13px;
      padding: 12px 0 12px 12px;
      border: none;
      outline: none;
      line-height: 14px;
    }

    input::placeholder {
      color: var(--paleta1C);
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      border-radius: 0 4px 4px 0;
      width: 61px;
      background: var(--bg-inputs);
      height: 36px;
    }
  }
  @media (max-width: 425px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-right: 10px;
    
    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
