import styled from "styled-components";

export const Container = styled.div`
display: flex;
flex-direction: column;
gap: 13px;
.titleConsultoresPage{
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
    color: var(--paleta2C);
}
.listAllConsultoresEmpresa{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    gap: 10px;
}
`