import { ButtonHTMLAttributes } from "react";
import ButtonSeguir from "../ButtonSeguir";
import { Container } from "./styles";

type IProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  item?: any;
  click(e: any): void;
  selected?: boolean
  tipo: string}

export default function CardAgendar({item, click, tipo,  selected}:IProps){
    return (
      <Container selected={selected} tipo={tipo}>
        <p>{item.hora}</p>
        {!selected && (
          <ButtonSeguir
            title="Agendar"
            tipo={tipo === 'gratis' ? '6' : '2'}
            click={() => {
              click(item.hora);
            }}
          />
        )}
        {selected && (
          <ButtonSeguir
            title="Selecionado"
            tipo="1"
            click={() => {
              click(item.hora);
            }}
          />
        )}
      </Container>
    );
} 