import { ButtonHTMLAttributes } from 'react';
import {
  IconCompartilhar,
  IconDeslike,
  IconFavoritar,
  IconInfo,
  IconLike,
  IconLoading,
  IconRelogio,
  IconPesquisar,
  IconTrash,
  IconPause,
  IconPencil,
} from '../Icons';
import { Container } from './styles';
import IconPlay from '../../assets/icons/IconPlay';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  title: any;
  tipo?:
    | '1'
    | '2'
    | '3'
    | '4'
    | '5'
    | '6'
    | '7'
    | '8'
    | '9'
    | '10'
    | '11'
    | '12'
    | '13'
    | '14'
    | '15'
    | '16';
  icon?: string;
  hover?: 'muito' | 'medio' | 'pouco' | 'none';
  chosen?: boolean;
  full?: boolean;
};

export default function ButttonDefault({
  title,
  tipo,
  icon,
  hover,
  chosen,
  full,
  ...props
}: ButtonProps) {
  return (
    <Container {...props} tipo={tipo} hover={hover} full={full}>
      {icon === 'Like' && <IconLike color={chosen ? '#fff' : '#069CD1'} />}
      {icon === 'Deslike' && (
        <IconDeslike color={chosen ? '#fff' : '#069CD1'} />
      )}
      {icon === 'Compartilhar' && <IconCompartilhar />}
      {icon === 'Favoritar' && (
        <IconFavoritar color={chosen ? '#fff' : '#069CD1'} />
      )}
      {icon === 'Relogio' && <IconRelogio />}
      {icon === 'Info' && <IconInfo />}
      {icon === 'InfoWhite' && <IconInfo color="#fff" />}
      {icon === 'Loading' && <IconLoading size={20} />}
      {icon === 'Loading-White' && <IconLoading color={'#fff'} size={20} />}
      {icon === 'Pesquisar' && <IconPesquisar size={15} />}
      {icon === 'Lixo' && <IconTrash size={15} color={'var(--branco)'} />}
      {icon === 'Play' && <IconPlay size={15} color={'var(--branco)'} />}
      {icon === 'Pause' && <IconPause size={15} color={'var(--branco)'} />}
      {icon === 'Editar' && <IconPencil size={15} color={'var(--branco)'} />}
      {title}
    </Container>
  );
}
