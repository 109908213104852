import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import apiDoubts from '../../services/api';
import { Container } from './styles';
import VideoVisualizar from '../../components/VideoVisualizar';
import AnunciosVerticais from '../../components/Anuncio/AnunciosVerticais';
import FiltrosInteresses from '../../components/FiltrosInteresses';
import ListPosts from '../../components/Posts/ListPosts';
import Comentarios from '../../components/Comentarios';
import { IconLoading } from '../../components/Icons';
import UserContext from '../../contexts/UserContext';
import WarningLogout from '../../components/WarningLogout';
import { AxiosResponse } from 'axios';

type IProgressVideo = {
  loaded: number;
  loadedSeconds: number;
  played: number;
  playedSeconds: number;
};

export default function Visualizar(props: any) {
  const params = useParams<any>();
  const history = useHistory();
  const { user } = useContext(UserContext);
  const [video, setVideo] = useState<any>({});
  const [loading, setLoading] = useState(true);

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    if (params?.id) {
      handleVideo(true);

      setTimeout(() => {
        scrollToTop();
      }, 1000);
    }
  }, [params]);

  useEffect(() => {
    localStorage?.setItem('currentVideo', JSON.stringify(video));
  }, [video]);

  async function handleVideo(load = false) {
    setLoading(load);
    if (user?.id) {
      try {
        const response = await apiDoubts.get(
          `/postagens/getPostagem-logado/${params?.id}`
        );
        setVideo(response?.data?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    } else {
      try {
        const response = await apiDoubts.get(
          `/postagens/getPostagem/${params?.id}`
        );
        setVideo(response?.data?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  }


  useEffect(() => {
    if (video.titulo) {
      document.title = video.titulo;
    }
    return () => {
      document.title = 'Doubts';
    };
  }, [video.titulo]);

  // useEffect(() => {
  //   async function checkUserIsLoggedIn(): Promise<void> {
  //     if (typeof window === 'undefined') return;

  //     const userToken = localStorage?.getItem('token');

  //     if (userToken) return;

  //     const hasGuestToken = localStorage.getItem('token-deslogado');

  //     if (hasGuestToken) {
  //       console.log('Já tem token deslogado');
  //       return;
  //     }

  //     async function getGuestToken(): Promise<string> {
  //       const { data } = await apiDoubts?.post(`/token-usuario-deslogado`);

  //       return data?.data?.token;
  //     }

  //     const guestToken = await getGuestToken();

  //     localStorage?.setItem('token-deslogado', guestToken);
  //     apiDoubts.defaults.headers.common['tokendeslogado'] = guestToken;
  //   }

  //   checkUserIsLoggedIn();
  // }, []);

  function getVideoProgress(videoProgress: IProgressVideo) {
    localStorage?.setItem('videoProgress', JSON.stringify(videoProgress));
  }

  return (
    <Container>
      <div className="leftArea">
        <VideoVisualizar
          video={video}
          like={handleVideo}
          deslike={handleVideo}
          loading={loading}
          videoProgress={getVideoProgress}
          // handleOnEndedVideo={handleOnEndedVideo}
        />

        {loading === false && (
          <div className="comentarios">
            {user?.login ? (
              <Comentarios videoId={video?._id} video={video} />
            ) : (
              <WarningLogout
                title={''}
                description="Para realizar um comentário é necessário fazer login."
                fullscreen={false}
              />
            )}
          </div>
        )}
      </div>

      <div className="rigthArea">
        <div className="bannerArea">
          <AnunciosVerticais
            count={1}
            load={loading}
            where={{
              interesse: '',
              search: '',
              hashs: [],
            }}
          />
        </div>
        <div className="videosArea">
          {/* <FiltrosInteresses
                selected={interesse}
                setSelected={(e) => setInteresse(e)}
              /> */}
          <div className="listPosts">
            <ListPosts
              posicao="lateral"
              load={loading}
              order=""
              where={{
                interesse: '',
                search: '',
              }}
            />
          </div>
        </div>
      </div>
    </Container>
  );
}
