import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const CardHistoric = styled.div`
  width: 100%;
  background-color: var(--white);
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 30px;
  justify-content: space-between;
  .leftItem {
    display: flex;
    flex-direction: row;
    gap: 10px;
    .dateHistoric {
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      color: var(--paleta2C);
    }
    .textCard {
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
      color: var(--paleta1A);
    }
  }
`;
