import styled from 'styled-components';

export const Container = styled.div`
  height: 130px;
  background-color: var(--background2);
  border-radius: 10px;
  border: 2px solid var(--paleta2E);
  position: relative;
  .inputCheckboxConsultorEmpresa {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: -2px;
    margin-right: -2px;
  }

  .iconChecked {
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 2px;
  }

  .removeConsultor {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }

  .contentCardProfileConsultor {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    .imageProfileConsultor {
      width: 88px;
      height: 88px;
      border-radius: 50%;
      background-position: center;
      background-size: cover;
      border: 4px solid var(--branco);
    }
    .nameCardConsultorProfile {
      font-size: 13px;
      font-weight: 700;
      line-height: 15px;
      color: var(--paleta1A);
      max-width: 80%;
      overflow: hidden;
    }
  }

  input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom checkbox appearance */
    display: inline-block;
    width: 25px;
    height: 25px;
    /* background-color only for content */
    background-clip: content-box;
    border: 2px solid var(--paleta2E);
    border-radius: 0 10px 0 10px;
    background-color: transparent;

    &:checked {
      background-color: var(--paleta2E);
      color: var(--branco);
    }

    &:focus {
      outline: none !important;
    }
  }
`;
