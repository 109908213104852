import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: calc(100vh - 110px);
  h1 {
    font-size: 28px;
    font-weight: 700;
  }
  .row {
    display: flex;
    gap: 30px;
    max-height: calc(100% - 70px);
  }
  .later-link {
    color: var(--paleta1B);
    font-size: 14px;
    text-align: center;
    margin-top: 34px;
    cursor: pointer;
    font-weight: 600;
    &:hover {
      text-decoration: underline;
    }
  }
  .sidebar {
    width: 380px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    h3 {
      font-size: 18px;
      font-weight: 700;
    }
    .overflow-requests {
      overflow: scroll;
      overflow-x: hidden;
      height: calc(100% - 341px - 30px);
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding-right: 10px;
      ::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.05);
        border-radius: 100px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: var(--purple7);
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: var(--purple2);
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h3 {
        font-size: 18px;
        color: #1e1b33;
        font-weight: 700;
      }
    }
    #overflow-hours {
      overflow: auto;
      max-height: 100%;
    }
  }
`;
