import { useContext, useEffect, useState } from 'react';
import apiDoubts from '../../../services/api';
import { Container } from './styles';
import CardConsultorConfigEmpresa from '../CardConsultorConfigEmpresa';
import UserContext from '../../../contexts/UserContext';
import EmptyPosts from '../../EmptyPosts';
import { toast } from 'react-toastify';

export default function ConsultoresEmpresa() {
  const [consultoresByEmpresa, setConsultoresByEmpresa] = useState<any[]>([]);
  const [consultoresSelecionados, setConsultoresSelecionados] = useState<any[]>(
    []
  );
  const [currentConsultants, setCurrentConsultants] = useState<any[]>([]);

  const { user } = useContext(UserContext);

  useEffect(() => {
    getConsultoresEmpresa();
  }, []);

  useEffect(() => {
    setCurrentConsultants((user as any)?.consultores);
  }, [user]);

  async function getConsultoresEmpresa() {
    await apiDoubts
      .get(`empresa/get-by-id/${user?._id}`)
      .then((response: any) => {
        setConsultoresByEmpresa(response?.data?.data?.consultores);
      });
  }

  function handleConsultoresSelecionados(id: string) {
    if (consultoresSelecionados.includes(id)) {
      let arrayConsultores = consultoresSelecionados;
      let newArray = arrayConsultores.filter((val: any) => {
        return val != id;
      });
      setConsultoresSelecionados([...newArray]);
      console.log('já tinha', newArray);
    } else {
      let newArray = consultoresSelecionados;
      newArray.push(id);
      setConsultoresSelecionados([...newArray]);
      console.log('ainda não tinha', newArray);
    }
  }

  useEffect(() => {
    console.log(consultoresSelecionados);
  }, [consultoresSelecionados]);

  console.log(consultoresByEmpresa);

  function removeVinculo(account: any) {
    let accountType;

    if (account?.listaAgencias && account?.listaEmpresas) {
      accountType = 'consultor';
    } else if (account?.listaConsultores && account?.listaEmpresas) {
      accountType = 'agencia';
    } else if (account?.listaAgencias && account?.listaConsultores) {
      accountType = 'empresa';
    }

    apiDoubts?.put(`/finalizarVinculo`, {
      idParaRemover: account?._id,
      tipo: accountType,
    });

    const newConsultants = currentConsultants?.filter(
      (consultants) => consultants?._id !== account?._id
    );
    setCurrentConsultants(newConsultants);

    toast.success('Vínculo removido com sucesso', {
      className: 'toast-sucess',
      theme: 'colored',
    });
  }

  const isEmptyConsultores =
    currentConsultants?.length === 0 || !currentConsultants;

  return (
    <Container>
      {currentConsultants?.length !== 0 && (
        <p className="titleConsultoresPage">
          Consultores que fazem parte da sua empresa !!!
        </p>
      )}

      {isEmptyConsultores && (
        <EmptyPosts
          title="Ops, você não esta associado com nenhum consultor"
          description="Envie convites para que eles possam aparecer"
        />
      )}

      <div className="listAllConsultoresEmpresa">
        {currentConsultants?.map((row: any, key: number) => (
          <CardConsultorConfigEmpresa
            key={key}
            consultorData={row}
            checked={consultoresSelecionados.includes(row?.id)}
            setChecked={(idConsultor: string) => {
              handleConsultoresSelecionados(idConsultor);
            }}
            removeConsultor={removeVinculo}
          />
        ))}
      </div>
    </Container>
  );
}
