import { Link } from "react-router-dom"
import { Separator } from "../HomeConsultant/styles"
import { BestConsultants, Campaign, Chart, Container, ContentLeft, ContentRight, Views, } from "./styles"
import ConsulterImg from '../../assets/Consultor.png'
import ArrowRightIcon from "../../assets/icons/ArrowRigthIcon"
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
  } from "recharts";


const Agency: React.FC<any> = () => {
    const data = [
        {
          name: "03/06",
          uv: 80,
          pv: 0,
        },
        {
          name: "04/06",
          uv: 30,
          pv: 50,
        },
        {
          name: "05/06",
          uv: 75,
          pv: 35,
        },
        {
          name: "06/06",
          uv: 45,
          pv: 60,
        },
        {
          name: "07/06",
          uv: 30,
          pv: 90,
        }
    ];
    
    return (
        <Container>
            <ContentLeft>
                
                <Views>
                    <header>
                    <h2>Visualizações</h2>
                    <Link to="#">
                        Por período
                    </Link>
                    <Link to="#">
                        Por vídeo
                    </Link>
                    <Link to="#">
                        Patrocinados
                    </Link>
                    </header>

                    <main>
                    <ResponsiveContainer>
                        <AreaChart
                            data={data}
                            margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0
                        }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis dataKey="number" domain={[0, 100]} />
                            <Tooltip />
                            <Area
                                type="monotone"
                                dataKey="uv"
                                stackId="1"
                                stroke="#2E294E"
                                fill="rgba(46, 41, 78, 1)"
                            />
                            <Area
                                type="monotone"
                                dataKey="pv"
                                stackId="2"
                                stroke="#0FBABA"
                                fill="rgba(15, 186, 186, 1)"
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                        
                    </main>
                </Views>

                <Campaign>
                    <div>
                        <header>
                            <h2>Campanhas</h2>
                            <p>Acompanhe as campanhas vigentes, vincule contas de empresa e obtenha os melhores resultados.</p>
                        </header>

                        <table>
                            <thead>
                                <tr>
                                    <th className="campaign">Campanha</th>
                                    <th>Custo diário</th>
                                    <th>Custo total</th>
                                    <th>Conversão</th>
                                    <th>Visualizações</th>
                                    <th>Tipo</th>
                                    <th>Status</th>
                                </tr>
                            </thead>    
                            <tbody>
                                <tr>
                                    <td className="consultant">Matheus Ilt e Leroy Merlin / Parceria</td>
                                    <td>R$ 98,30</td>
                                    <td>R$ 1.000,00</td>
                                    <td>20,54%</td>
                                    <td>5.142</td>
                                    <td>Bumper</td>
                                    <td>On</td>
                                </tr>
                                <tr>
                                    <td className="consultant">Matheus Ilt e Leroy Merlin / Parceria</td>
                                    <td>R$ 98,30</td>
                                    <td>R$ 1.000,00</td>
                                    <td>20,54%</td>
                                    <td>5.142</td>
                                    <td>Bumper</td>
                                    <td>On</td>
                                </tr>
                                <tr>
                                    <td className="consultant">Matheus Ilt e Leroy Merlin / Parceria</td>
                                    <td>R$ 98,30</td>
                                    <td>R$ 1.000,00</td>
                                    <td>20,54%</td>
                                    <td>5.142</td>
                                    <td>Bumper</td>
                                    <td>On</td>
                                </tr>
                                <tr>
                                    <td className="consultant">Matheus Ilt e Leroy Merlin / Parceria</td>
                                    <td>R$ 98,30</td>
                                    <td>R$ 1.000,00</td>
                                    <td>20,54%</td>
                                    <td>5.142</td>
                                    <td>Bumper</td>
                                    <td>On</td>
                                </tr>
                                <tr>
                                    <td className="consultant">Matheus Ilt e Leroy Merlin / Parceria</td>
                                    <td>R$ 98,30</td>
                                    <td>R$ 1.000,00</td>
                                    <td>20,54%</td>
                                    <td>5.142</td>
                                    <td>Bumper</td>
                                    <td>On</td>
                                </tr>
                                <tr>
                                    <td className="consultant">Matheus Ilt e Leroy Merlin / Parceria</td>
                                    <td>R$ 98,30</td>
                                    <td>R$ 1.000,00</td>
                                    <td>20,54%</td>
                                    <td>5.142</td>
                                    <td>Bumper</td>
                                    <td>On</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <footer>
                        <Link to="/consultores">
                            Criar nova campanha
                        </Link>
                        <Link to="/consultores">
                            Ver todas
                        </Link>
                    </footer>
                </Campaign>

            </ContentLeft>

            <ContentRight>

                <BestConsultants>
                    <div className="title">
                        <h2>Melhores consultores</h2>
                        <span>nos Ultimos 28 dias</span>
                    </div>

                    <div className="buttons">
                        <button>Geral</button>
                        <button>Mais views</button>
                        <button>Maior rendimento</button>
                    </div>

                    {[0, 1, 2, 3, 4, 5, 6].map((row:any, key: any) =>
                        <>
                            <Chart>                        
                                <div className="consultor">

                                    <div className="photo">
                                        <img src={ConsulterImg} alt="" />
                                    </div>

                                    <div className="details">
                                        <span>{row+1}º</span>
                                        
                                        <p className="name">
                                            Alexandre Rossi
                                        </p>

                                        <div className="follow">
                                            <span>90K seguidores |</span>
                                            <p>25 vídeos</p>
                                        </div>
                                    </div>

                                    <div className="arrow">
                                        <ArrowRightIcon />
                                    </div>
                                </div>
                            </Chart>

                            <Separator />
                        </>    
                    )}



                    <Link to="/consultores">
                        VER TODOS
                    </Link>
                </BestConsultants>

            </ContentRight>
        </Container>
    )
}

export default Agency