import { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import IconPlay from '../../assets/icons/IconPlay';
import UserContext from '../../contexts/UserContext';
import apiDoubts from '../../services/api';
import Availability from '../Availability';
import ButttonDefault from '../ButtonDefault';
import ButtonSeguir from '../ButtonSeguir';
import { Element } from '../Comentarios/styles';
import ModalAttProduto from '../Empresa/ModalAttProduto';
import Inscritos from '../Inscritos';
import {
  AreaMessageConsultor,
  Container,
  ContainerConsultoria,
  ConviteConsultor,
} from './styles';
import { IconLoading } from '../Icons';

interface HProps {
  tipo: string;
  consultorId: string;
}

export default function HeaderType({ tipo, consultorId }: HProps) {
  const [consultor, setConsultor] = useState<any>({});
  const [principalPost, setPrincipalPost] = useState<any>('');
  const history = useHistory();
  const { user } = useContext(UserContext);

  const [modalConvidarConsultor, setModalConvidarConsultor] =
    useState<boolean>(false);
  const [messageConviteConsultor, setMessageConviteConsultor] =
    useState<string>('');

  const [isFollower, setIsFollower] = useState<boolean>(false);
  const [loadingButtonFollower, setLoadingButtonFollower] =
    useState<boolean>(false);

  const [loadModal, setLoadModal] = useState<boolean>(false);

  useEffect(() => {
    try {
      getConsultor();
    } catch (error) {
      console.log('erro', error);
    }
  }, [consultorId]);

  useEffect(() => {
    if (consultor.postagemPrincipal) {
      getPrincipalVideo();
    }
    statusSeguidor();
  }, [consultor]);

  async function getConsultor() {
    await apiDoubts
      .get(`consultor/get-by-id/${consultorId}`)
      .then((response) => {
        setConsultor(response?.data?.data[0]);
      })
      .catch((error: any) => {
        console.log(error.message);
      });
  }

  async function getPrincipalVideo() {
    if (consultor) {
      const response = await apiDoubts.get(
        `/postagens/getPostagem/${consultor?.postagemPrincipal}`
      );
      setPrincipalPost(response?.data?.data);
      console.log('repsonse =>', response.data.data);
    }
  }

  async function statusSeguidor() {
    await apiDoubts
      .get(`/consultor/seguidor/status/${consultorId}`)
      .then((response: AxiosResponse<any>) => {
        setIsFollower(response.data.data.seguindo);
      });
  }

  function goToVideo() {
    try {
      if (!principalPost)
        throw new Error('parece que esse consultor não tem um vídeo principal');
      history.push(`/visualizar/${principalPost.id}`);
    } catch (error) {
      toast.error('Não foi possível encontrar postagens.', {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  async function handleInviteConsultor() {
    try {
      setLoadModal(true);

      if (!messageConviteConsultor)
        throw new Error('Por favor, digite uma mensagem para o consultor');

      await apiDoubts
        .post(`/conversa/enviar-mensagem`, {
          mensagem: messageConviteConsultor,
          tipoConversa: 'convite',
          titulo: `convite - ${user.nickName}`,
          destinatarioId: consultor?.id,
        })
        .then(() => {
          setModalConvidarConsultor(false);
          setMessageConviteConsultor('');
          setLoadModal(false);
          toast.success('Consultor convidado com sucesso', {
            className: 'toast-sucess',
            theme: 'colored',
          });
        });
    } catch (error: any) {
      setLoadModal(false);
      toast.error(error.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  async function handleFollower() {
    setLoadingButtonFollower(true);
    if (user?.id) {
      await apiDoubts
        .put(`/consultor/seguidor/novo`, { consultorId: consultorId })
        .then((response) => {
          setLoadingButtonFollower(false);
          statusSeguidor();
        });
    } else {
      setLoadingButtonFollower(false);

      toast.error('É preciso estar logado para seguir um consultor', {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  const currentConsultorId = history?.location?.pathname?.split('/')?.pop();

  return (
    <>
      {(tipo === 'Canal' || tipo === 'Playlist') && (
        <Container>
          <ModalAttProduto
            id="modalConvidarConsultor"
            width="450"
            onClose={() => {
              setModalConvidarConsultor(false);
            }}
            openModal={modalConvidarConsultor}
            title="Convidar Consultor"
            scroll={false}
          >
            <ConviteConsultor>
              <div className="areaLeftConsultorConvite">
                <div
                  className="imageProfileConsultor"
                  style={{ backgroundImage: `urL(${consultor?.fotoFull})` }}
                ></div>
              </div>
              <div className="areaRightConsultorConvite">
                <h4 className="nameConsultor">{consultor?.nickName}</h4>
                <p className="descriptionConsultor">
                  {consultor?.descricaoCanal}
                </p>
              </div>
            </ConviteConsultor>

            <AreaMessageConsultor>
              <h3 className="titleAreaMessageConsultor">Mensagem</h3>
              <textarea
                value={messageConviteConsultor}
                className="input"
                placeholder="Assuntos..."
                onChange={(e) => {
                  setMessageConviteConsultor(e.target.value);
                }}
              />
              <div className="areaButtonsConsultor">
                <ButttonDefault title={'Cancelar'} tipo={'11'} />
                <ButttonDefault
                  title={'Enviar'}
                  tipo={'6'}
                  onClick={handleInviteConsultor}
                  icon={loadModal ? 'Loading-White' : ''}
                />
              </div>
            </AreaMessageConsultor>
          </ModalAttProduto>

          <h2 className="descricao">{consultor?.descricaoCanal}</h2>

          {(user?.tipo === 'empresa' || user?.tipo === 'agencia') &&
            !user?.listaConsultores?.includes(
              history?.location?.pathname?.split('/')?.pop() as string
            ) && (
              <>
                <ButttonDefault
                  title={`Convidar consultor para fazer parte da ${user?.tipo}`}
                  full={true}
                  tipo={'1'}
                  hover={'pouco'}
                  onClick={() => {
                    setModalConvidarConsultor(true);
                  }}
                />
              </>
            )}
        </Container>
      )}

      {tipo === 'Social' && (
        <Container>
          <div className="name">
            <div className="areaLeft">
              <h3>{consultor?.nickName} </h3>
              {user?.login && (
                <ButtonSeguir
                  title={isFollower ? 'Deixar de Seguir' : 'Seguir'}
                  click={() => {
                    handleFollower();
                  }}
                />
              )}
            </div>
            <div className="inscritos">
              <Inscritos consultorId={consultor?.id} />
            </div>
          </div>
          <h2 className="descricao">{consultor.descricaoCanal}</h2>

          <Element />
        </Container>
      )}

      {tipo === 'Consultoria' && (
        <ContainerConsultoria>
          <div className="areaTop">
            {principalPost && (
              <div
                className="video"
                style={{
                  backgroundImage: `url(${principalPost?.thumbnail})`,
                }}
                onClick={goToVideo}
              >
                <div className="hover">
                  <IconPlay />
                </div>
              </div>
            )}
          </div>
          <div className="areaBottom">
            <p>Bate papo com {consultor.nickName}</p>
            <span>
              A Doubts quer conectar você a informação! Tire suas dúvidas e
              tenha uma consultoria personalizada agora mesmo !!!
            </span>
            <div className="values"></div>
          </div>
        </ContainerConsultoria>
      )}
    </>
  );
}
