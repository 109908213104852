import { IconCloseSquare } from '../../Icons';
import { ContainerOverlay, Container } from './styles';

interface ModalProps {
  id: string;
  children?: any;
  onClose: () => void;
  openModal?: boolean;
  width?: string;
  title?: string; 
  scroll?: boolean;
  heigth?: number
} 

export default function ModalAttProduto({
  id,
  onClose,
  width,
  openModal,
  children,
  title,
  scroll,
  heigth
}: ModalProps) {
  const handleCloseModal = (e: any) => {
    if (e.target.id === id) {
      onClose();
    }
  };

  return (
    <ContainerOverlay id={id} openModal={openModal} onClick={handleCloseModal}>
      <Container openModal={openModal} scroll={scroll} heigth={heigth} width={width}>
        <div className="header">
          <p className="titleModal">{title}</p>
          <button className="close" onClick={onClose}>
            <IconCloseSquare color={'#0FBABA'} />
          </button>
        </div>
        <div className="main">{children}</div>
      </Container>
    </ContainerOverlay>
  );
}
