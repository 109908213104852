import styled, { css } from 'styled-components';

interface props {
  type: string;
}

export const Container = styled.div<props>`
  cursor: pointer;
  z-index: 0;
  position: relative;
  border: none;
  background-color: var(--paleta1A);
  height: 40px;
  border-radius: 50%;
  outline: none;
  border: none;
  color: transparent;
  width: 40px;

  &:hover {
    transition: all 0.2s;
    transform: scale(1.1);
    .contents {
      .areaEnd {
        height: 38px;
        .selecionar {
        }
      }
    }
  }

  .contents {
    z-index: 1;
    display: flex;
    width: 40px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;

    .more {
      margin-bottom: -5px;
    }

    input::-webkit-file-upload-button {
      position: absolute;
      height: 40px;
      width: 100%;
      z-index: 3;
      top: 0;
      background-color: transparent;
      border: none;
      color: transparent;
      cursor: pointer;
    }

    P {
      position: absolute;
      color: #fff;
      z-index: 2;
      margin-top: 20px;
      font-size: 30px;
    }
  }
`;
