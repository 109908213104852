import styled from 'styled-components';

export const ContainerStepThree = styled.div`
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .titleStep {
    margin-top: 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2c2c2c;
  }

  .signUpForm {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;

    .twoInLine {
      display: flex;
      flex-direction: row;
      gap: 16px;
      
    }
    .inputField {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 6px;
        label {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: var(--color2A);
        }
      }
  }

  .twoButtons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .termosDeUso {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #666666;
    a {
      color: #1ea0ff;
    }
  }
`;
