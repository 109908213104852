import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 15px;
  .header {
    width: 40%;
    
  }
  .listPlaylist {
    width: 60%;
    .page-title {
      font-size: 28px;
      font-weight: 700;
      color: var(--paleta2C);
      margin-bottom: 15px;
    }
  }
  
  @media (max-width: 900px)
  {
    flex-direction: column-reverse;
    .header{
      width: 100%;
    }
    .listPlaylist{
      width: 100%;
    }
  }
`;