import styled, { css } from 'styled-components';

interface props {
  type: string;
}

export const Container = styled.div<props>`
  cursor: pointer;
  z-index: 0;
  position: relative;
  border: none;
  background-color: var(--background2);
  height: 40px;
  border-radius: 5px;
  outline: none;
  border: none;
  color: transparent;
  width: 100%;

  input::-webkit-file-upload-button {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9999999;
    top: 0;
    visibility: hidden;
    
  }

  /* &:hover {
    transition: all 0.2s;
    transform: scale(1.01);
    border: 1px solid var(--paleta1C);
    z-index: 2;
    .content {
      .areaEnd {
        height: 38px;
        z-index: -1;
        .selecionar {
        }
      }
    }
  } */

  .contents {
    z-index: 1;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;

    input::after {
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      content: 'Selecione o arquivo';
      border-radius: 5px;
      width: 100%;
      display: flex;
      height: 40px;
      color: #fff;
      /* width: 100%; */
      transition: all 0.2s;
      cursor: pointer;
    }

    .areaStart {
      display: flex;
      align-items: center;
      gap: 10px;
      position: absolute;
      margin-left: 15px;
      margin-top: 10px;
      top: 0;
      max-width:90% ;

      .arquivo {
        font-size: 13px;
        font-weight: 400;
        overflow: hidden;
        color: #aaaaaa;
        max-width: 300px;
        white-space: nowrap;
      }
    }

    .areaEnd {
      height: 40px;
      padding: 12px;
      min-width: 180px ;
      background-color: #ddd;
      border-radius: 5px 5px 5px 5px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;

      .selecionar {
        font-weight: 700;
        font-size: 11px;
        color: var(--texto);
      }
    }
  }
  @media (max-width: 800px) {
    .content {
      justify-content: flex-start;
      position: relative;
      padding-top: 8px;
      .areaStart {
      }
      .areaEnd {
        position: absolute;
        right: 0;
        top: 0;
        min-width: 180px;
        .selecionar {
          /* white-space: nowrap; */
        }
      }
    }
  }
`;
