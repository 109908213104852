import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 25px;

  .buttonAreaRemoveHours {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .areaLeft {
    width: 50%;
    .voltar {
      cursor: pointer;
      text-decoration: none;
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      p {
        font-size: 14px;
        color: var(--paleta1C);
        font-weight: 700;
      }
    }
    .title {
      font-size: 28px;
      line-height: 42px;
      font-weight: 700;
      color: var(--paleta2C);
    }
    .subtitle {
      font-size: 18px;
      line-height: 27px;
      font-weight: 600;
      color: var(--paleta1C);
    }
    .areaCards {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      gap: 15px;

      ul {
        list-style: none;
        li {
          margin-top: 20px;
          margin-bottom: 8px;
          color: var(--paleta1C);
          font-size: 18px;
          font-weight: 700;
          display: flex;
          gap: 10px;
          .selectHours {
            line-height: 30px;
            font-size: 12px;
            cursor: pointer;
            color: var(--paleta2C);
          }
        }
        .cardConsultoriaWrapper {
          display: flex;
          flex-direction: column;
          gap: 4px;
        }
      }
    }
  }
  .areaRight {
    min-width: 40%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--paleta2C);
    }
  }

  @media (min-width: 1500px) {
    .areaLeft {
      width: 60%;
    }
    .areaRight {
      width: 40%;
    }
  }

  @media (min-width: 1700px) {
    .areaLeft {
      width: 70%;
    }
    .areaRight {
      width: 30%;
    }
  }

  @media (max-width: 1150px) {
    flex-direction: column;
    .areaLeft {
      width: 100%;
    }
    .areaRight {
      width: 65%;
    }
  }

  @media (max-width: 950px) {
    flex-direction: column;
    .areaLeft {
      width: 100%;
    }
    .areaRight {
      width: 80%;
    }
  }

  @media (max-width: 800px) {
    .areaRight {
      width: 100%;
      display: flex;
      flex-direction: column;

      .calendar {
        .content {
          flex-direction: column;
          .react-calendar {
            min-width: 100%;
            border-radius: 10px;
          }
          .selectHour {
            max-width: 100%;
            min-width: 100%;
            border: none;
          }
        }
      }
    }
  }
`;
