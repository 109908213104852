import { ICardConsultoresProps } from './types';
import * as S from './styles';
import {
  IconCalendar,
} from '../../../../components/Icons';
import { IconClock } from '../../../../components/Icons';
import moment from 'moment';

export default function CardConsultores({
  completeData,
}: ICardConsultoresProps) {

  return (
    <S.CardConsultoresContainer>
      <div className="mainContainer">
        <S.ConsultoriaType typeHour={completeData.type}>
          {completeData.type === 'gratis' ? 'Grátis' : 'Paga'}
        </S.ConsultoriaType>
        <S.Appointment status={completeData?.status}>
          {' '}
          <IconClock size={16} color={`var(--branco)`} />{' '}
          {completeData?.status === 'livre' ? 'Livre' : 'Ocupado'}{' '}
        </S.Appointment>
        <a className="consultorWrapper" href={`/consultor/visualizar/canal/${completeData?.consultor?._id}`}>
          <div
            className="consultorPhoto"
            style={{ backgroundImage: `url(${completeData?.consultor?.foto})` }}
          ></div>
          {completeData?.consultor?.nickName}
        </a>
      </div>
      <S.Hour>
        <IconCalendar color={`var(--branco)`} size={16} />{' '}
        {moment(completeData?.data)
          .locale('pt-br')
          .format('DD [de] MMMM [de] YYYY - HH:mm')}{' '}
      </S.Hour>
    </S.CardConsultoresContainer>
  );
}
