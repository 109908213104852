export default function SearchIcon({ size = 14, color = '#0FBABA' }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.625 13.875L11.9062 10.125C12.7812 8.83333 13.125 7.32292 12.9375 5.59375C12.7083 4.13542 12.0833 2.90625 11.0625 1.90625C10.0417 0.885417 8.80208 0.270833 7.34375 0.0625C5.90625 -0.104167 4.60417 0.125 3.4375 0.75C2.29167 1.39583 1.39583 2.29167 0.75 3.4375C0.125 4.60417 -0.104167 5.90625 0.0625 7.34375C0.270833 8.80208 0.885417 10.0417 1.90625 11.0625C2.90625 12.1042 4.13542 12.7292 5.59375 12.9375C7.32292 13.125 8.83333 12.7812 10.125 11.9062L13.875 15.625C14.125 15.875 14.4167 16 14.75 16C15.0833 16 15.375 15.875 15.625 15.625C15.875 15.375 16 15.0833 16 14.75C16 14.4167 15.875 14.125 15.625 13.875ZM2.46875 6.5C2.48958 5.375 2.88542 4.4375 3.65625 3.6875C4.40625 2.91667 5.34375 2.52083 6.46875 2.5C7.59375 2.52083 8.54167 2.91667 9.3125 3.6875C10.0625 4.4375 10.4479 5.375 10.4688 6.5C10.4479 7.625 10.0625 8.5625 9.3125 9.3125C8.54167 10.0833 7.59375 10.4792 6.46875 10.5C5.34375 10.4792 4.40625 10.0833 3.65625 9.3125C2.88542 8.5625 2.48958 7.625 2.46875 6.5Z"
        fill={color}
      />
    </svg>
  );
}