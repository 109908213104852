import { useEffect, useState } from 'react';
import apiDoubts from '../../../services/api';
import { Container } from './styles';

interface IProps {
  title?: string;
  count: number;
  load?: boolean;
  where: {
    hashs: any[];
    interesse: string;
    search: string;
  };
}

export default function AnunciosVerticais({
  title = 'Patrocinado',
  count,
  load = false,
  where = {
    hashs: [],
    interesse: '',
    search: '',
  },
}: IProps) {
  const [loading, setLoading] = useState<boolean>(load);
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    console.log(where, count);
    getData();
  }, [where, count]);

  async function getData() {
    try {
      setLoading(true);
      const response: any = await apiDoubts.get(
        `/campanha-exibir?banner_70x70=1&position=1&peso=1&random`
      );
      response?.data?.data?.forEach((banner: any) => {
        apiDoubts?.post(`visualizar-campanha`, {
          campanhaId: banner?._id,
          autorCampanhaId: banner?.empresaId,
          usuarioId: banner?._id ?? null,
          tipoUsuario: banner?.tipo ?? null,
        });
      });

      setData(response?.data?.data);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  }

  async function handleAdsClick(campaignId: string) {
    console.log(campaignId);
    apiDoubts.put(`/campanha-click/${campaignId}?clicar=banner_70x70`);

    const [bannerData] = data as any;

    apiDoubts?.post(`/clicar-campanha`, {
      campanhaId: bannerData?._id,
      autorCampanhaId: bannerData?.empresaId,
      usuarioId: bannerData?._id ?? null,
      tipoUsuario: bannerData?.tipo ?? null,
    });
  }

  function handleCaminho(item: any) {
    window.open(`${item?.link}`, '_blank');
  }

  return (
    <Container>
      <div className="head">
        <h3>{title}</h3>
      </div>

      <div className="list">
        {(load || loading) && (
          <div className="ads loading">
            <div className="image" />
            <div className="infos">
              <span className="title">Titulo</span>
              <span className="description">Descrição</span>
            </div>
          </div>
        )}
        {!load &&
          !loading &&
          data?.map((row: any, key: number) => (
            <div className="ads" key={key}>
              <div
                className="image"
                style={{
                  backgroundImage: `url(${row?.banner_70x70})`,
                }}
              />
              <div className="infos">
                <span className="title">{row?.titulo ?? 'Anúncio'}</span>
                <button
                  onClick={() => {
                    handleCaminho(row);
                    handleAdsClick(row?.id);
                  }}
                >
                  Saiba mais
                </button>
              </div>
            </div>
          ))}
      </div>
    </Container>
  );
}
