import styled from 'styled-components';

export const Header = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const Title = styled.h2`
  font-size: 23px;
  font-weight: 500;
`;

export const HeaderInfos = styled.menu`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;

  .info-title {
    font-size: 14px;
    color: var(--dark-grey);
    list-style: none;
    display: flex;
    align-items: center;
    gap: 5px;

    .info-status {
      font-weight: 600;

      &::before {
        display: inline-block;
        content: '';
        width: 8px;
        border-radius: 50%;
        aspect-ratio: 4/4;
        margin-right: 3px;
      }

      &.ativa {
        color: var(--btn-aceitar);

        &::before {
          background-color: var(--btn-aceitar);
        }
      }

      &.pendente {
        color: var(--warning);

        &::before {
          background-color: var(--warning);
        }
      }

      &.pausada {
        color: var(--offline);

        &::before {
          background-color: var(--offline);
        }
      }
    }

    .info-money,
    .info-type {
      color: #000;
    }

    .info-type {
      position: relative;
      cursor: help;

      &:hover::before {
        opacity: 1;
      }

      &::before {
        pointer-events: none;
        opacity: 0;
        transition: all 180ms;
        content: attr(data-type);
        position: absolute;
        padding: 10px 20px;
        background-color: var(--background);
        top: 30px;
        width: max-content;
        max-width: 300px;
        right: 0;
        border-radius: 5px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
      }
    }

    .info-money {
      position: relative;
      cursor: help;

      &:hover::before {
        opacity: 1;
      }

      &::before {
        pointer-events: none;
        opacity: 0;
        transition: all 180ms;
        content: attr(data-limite-gasto);
        position: absolute;
        padding: 10px 20px;
        background-color: var(--background);
        top: 30px;
        width: max-content;
        max-width: 300px;
        right: 0;
        border-radius: 5px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
        z-index: 10;
      }
    }
  }
`;
