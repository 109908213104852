import { createRef, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Inputdefault from '../../../components/Inputs/InputDefault';
import InputFile from '../../../components/ButtonFile';
import SelectOption from './SelectOption';
import { Container, LoadingWrapper } from './styles';
import apiDoubts from '../../../services/api';
import ListHashStateless from '../../../components/Hashtags/ListStateless';
import ButttonDefault from '../../../components/ButtonDefault';
import VideoPreview from '../../../components/VideoPreview';
import CardPreview from '../../../components/CardPreview';
import ModalAlert from '../../../components/ModalAlert';
import { toast } from 'react-toastify';
import moment from 'moment';
import ModalSucess from '../../../components/ModalSucess';
import { useDebouncedCallback } from 'use-debounce';
import InputHash from '../../../components/Inputs/InputHash';
import bucket from '../../../services/bucket';
import Lottie from 'lottie-web';
import LottieAnimation from '../../../components/Lootie';
import UserContext from '../../../contexts/UserContext';
import { IconInfo } from '../../../components/Icons';

export default function CriarConteudo() {
  const history = useHistory();

  const maxDuration = 800;

  const [optionSelected, setOptionSelected] = useState<string>('Enviar Vídeo');
  const [categorias, setCategorias] = useState<any[]>([]);
  const [optionCategorias, setOptionsCategorias] = useState<any[]>([]);
  const [focus, setFocus] = useState(false);
  const [modal, setModal] = useState<boolean>(false);
  const [modalSucess, setModalSucess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [videoId, setVideoId] = useState<string>('');
  const [hash, setHash] = useState<string>('');
  const [categ, setCateg] = useState<string>('');
  const [DTO, setDTO] = useState<any>({
    titulo: '',
    video: '',
    thumbnail: '',
    data: moment(new Date()).format('DD-MM-yyyy hh:mm:00'),
    visualizacoes: 0,
    listaInteresses: [],
    tipo: 'comum',
    listaHashtags: [],
    descricao: '',
  });

  const { user } = useContext(UserContext);

  const [loadingButtonSendVideo, setLoadingButtonSendVideo] =
    useState<boolean>(false);

  useEffect(() => {
    goToPage();
  }, [optionSelected]);

  useEffect(() => {
    getCategorias();
  }, []);

  useEffect(() => {
    // console.log('optionCategorias', optionCategorias)
  }, [optionCategorias]);

  function goToTransmissao() {
    setModal(false);
    history.push('/live');
  }

  function handleOnChange(name: any, value: any) {
    const newDTO: any = DTO;

    newDTO[name] = value;
    setDTO({ ...newDTO });
    return;
  }

  function goToPage() {
    if (
      DTO.titulo !== '' ||
      DTO.video !== '' ||
      DTO.thumbnail !== '' ||
      DTO.listaInteresses.lenght > 0 ||
      DTO.listaHashtags.length > 0 ||
      DTO.descricao !== ''
    ) {
      setModal(true);
      setOptionSelected('Enviar Vídeo');
      return;
    }
    if (optionSelected === 'Transmissão ao Vivo') history.push('/live');
  }

  useEffect(() => {
    console.log(DTO);
  }, [DTO]);

  function handleHash(e: any) {
    if (e === '') return;

    let hashSelectd = DTO.listaHashtags;

    var newHash = `#${e}`;

    if (e.includes('#')) {
      newHash = e;
    }

    if (hashSelectd.length === 0) {
      setHash('');
      handleOnChange('listaHashtags', [newHash]);
    } else {
      const index = hashSelectd.indexOf(newHash);
      if (index !== -1) {
        hashSelectd.splice(index, 1);
      } else {
        hashSelectd.push(newHash);
      }
      setHash('');
      handleOnChange('listaHashtags', hashSelectd);
    }
  }

  function getCategorias() {
    apiDoubts.get(`/interesses`).then((response: any) => {
      const categorias = response.data.data;
      categorias.map((row: any) => {
        const data = {
          value: row.id,
          label: row.titulo,
        };
        setCategorias((oldArray) => [...oldArray, data]);
      });
    });
  }

  function handleCategorias(e: any) {
    if (e === '') return;

    let catSelected = DTO.listaInteresses;
    if (catSelected.length === 0) {
      setCateg('');
      handleOnChange('listaInteresses', [e]);
    } else {
      const index = catSelected.indexOf(e);
      if (index !== -1) {
        catSelected.splice(index, 1);
      } else {
        catSelected.push(e);
      }
      setCateg('');
      handleOnChange('listaInteresses', catSelected);
    }
  }

  const [videoFile, setVideoFile] = useState(null);

  const handleOnChangeVideo = (e: any) => {
    try {
      const file = e.target.files[0];
      const video = document.createElement('video');

      const acceptedExtensions: string[] = [
        'mp4',
        'mov',
        'avi',
        'wmv',
        'mkv',
        'flv',
      ];

      const videoExtension = file?.name?.split('.').pop();

      if (!acceptedExtensions?.includes(videoExtension)) {
        throw new Error('Esse não é um arquivo de vídeo válido!');
      }

      setVideoFile(file);

      let errorTime = false;
      video.onloadedmetadata = () => {
        if (video.duration <= 120) {
          setVideoFile(file);
        } else {
          setVideoFile(null);
          toast.error('O vídeo deve ter no máximo 2 minutos.');
        }
      };
      video.src = URL.createObjectURL(file);
    } catch (err: any) {
      setVideoFile(null);
      toast.error(err.message);
    }
  };

  async function getVideo(file: any) {
    try {
      let formData = new FormData();
      formData.append('file', file);
      const response: any = await bucket.post(`/upload`, formData);
      return response.data.arquivo;
    } catch (err: any) {
      return {
        error: true,
        message: err.message,
      };
    }
  }

  async function getThumb(file: any) {
    let formData = new FormData();
    formData.append('file', file.target.files[0]);
    const response: any = await bucket.post(`/upload`, formData);
    handleOnChange('thumbnail', response?.data?.arquivo);
  }

  async function handleSubmit() {
    try {
      setLoading(true);

      if (!videoFile) throw new Error('Adicione um Video, por favor');
      if (!DTO.thumbnail) throw new Error('Adicione uma Thumbnail, por favor');

      if (DTO.titulo === '')
        throw new Error('Dê um titulo para o seu vídeo, por gentileza');
      if (DTO.descricao === '')
        throw new Error('Adicione uma descrição, por favor');
      if (DTO.listaInteresses.length < 2)
        throw new Error('Escolha ao menos 2 categorias, por favor');
      if (DTO.listaHashtags.length < 2)
        throw new Error('Escolha ao menos 2 hashtags, por favor');

      setLoadingButtonSendVideo(true);

      DTO.video = await getVideo(videoFile);

      apiDoubts
        .post('/postagens', { ...DTO, titulo: DTO.titulo + '?' })
        .then((response) => {
          setVideoId(response?.data?.data?._id);
          history.push(`/visualizar/${response?.data?.data?._id}`);
          setDTO({
            titulo: '',
            video: '',
            thumbnail: '',
            data: moment(new Date()).format('DD-MM-yyyy hh:mm:00'),
            visualizacoes: 0,
            listaInteresses: [],
            tipo: 'comum',
            listaHashtags: [],
            descricao: '',
          });
          setLoadingButtonSendVideo(false);
        })
        .catch((error: any) => {
          setLoading(false);
          toast.error(error.message, {
            className: 'toast-error',
            theme: 'colored',
          });
        });
    } catch (err: any) {
      setLoading(false);
      setLoadingButtonSendVideo(false);
      toast.error(err.message);
    }
  }

  async function getHashtags(inputValue: string) {
    const response = await apiDoubts.get(`/hashtags-listar?hash=${inputValue}`);
    return response.data.data;
  }

  const debounced = useDebouncedCallback((value) => {
    handleHash(value.target.value);
    setHash(value.target.value);
  }, 1000);

  return (
    <Container>
      <ModalSucess
        prosseguir={() => {
          history.push(`/visualizar/${videoId}`);
        }}
        id="modalSucess"
        onClose={() => {
          setModalSucess(false);
        }}
        openModal={modalSucess}
        width={'200'}
      >
        <p>Muito bem !!! o seu vídeo foi postado</p>
      </ModalSucess>

      <ModalAlert
        prosseguir={goToTransmissao}
        id="modalAlert"
        onClose={() => {
          setModal(false);
        }}
        openModal={modal}
        width={'200'}
      >
        <p>
          tem certeza de que deseja ir para próxima página ? suas informações
          serão perdidas...
        </p>
      </ModalAlert>

      <ModalAlert
        prosseguir={() => {}}
        hiddenFooter={true}
        hiddenIcon={true}
        id="enviando-video"
        onClose={() => {}}
        openModal={loadingButtonSendVideo}
      >
        <LoadingWrapper>
          <p className="subtitle">Preparando o video</p>
          <p className="subdescription">
            Aguarde, isso pode levar alguns instantes.
          </p>
          <div className="center">
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
          </div>
        </LoadingWrapper>
      </ModalAlert>

      {user.tipo === 'consultor' && (
        <div className="selectAction">
          <SelectOption
            icon={true}
            opt1="Enviar Vídeo"
            opt2="Transmissão ao Vivo"
            select={(e: string) => {
              setOptionSelected(e);
            }}
            selected={optionSelected}
          />
        </div>
      )}
      <div className="main">
        <div className="areaLeft">
          <div className="container-box">
            <p className="title">Envio do Vídeo e Imagem de Destaque</p>

            <div className="resume">
              <IconInfo />
              <p>
                Aqui você carrega seu vídeo com até 2 minutos de duração. Não
                esqueça de adicionar uma imagem de destaque com dimensões
                640x360 pixels. Essa imagem será a vitrine do seu vídeo!
              </p>
            </div>
            <h3 className="subtitle">
              Selecione o video <span style={{ fontWeight: 400 }}>(mp4)</span>
            </h3>
            <InputFile
              accept="video/*"
              file={DTO.video}
              placeholder="Selecionar Arquivo"
              tipo="file"
              change={(e: any) => {
                setVideoFile(null);
                setTimeout(() => {
                  handleOnChangeVideo(e);
                }, 100);
              }}
            />
            <h3 className="subtitle">
              Imagem da Thumbnail{' '}
              <span style={{ fontWeight: 400 }}>(640x360)</span>
            </h3>
            <InputFile
              accept="image/*"
              file={DTO.thumbnail}
              placeholder="Selecionar Arquivo"
              tipo="file"
              change={(e: any) => getThumb(e)}
            />
          </div>

          <div className="container-box">
            <p className="title">Detalhes do Conteúdo</p>
            <div className="resume">
              <IconInfo />
              <p>
                Adicione informações cruciais! Escolha um título cativante para
                seu vídeo, como uma pergunta envolvente. Depois, acrescente uma
                descrição breve e informativa sobre o que os espectadores podem
                esperar.
              </p>
            </div>
            <h3 className="subtitle">Titulo</h3>
            <Inputdefault
              placeholder={`Por exemplo, "Como consertar uma torneira vazando?"`}
              tipo="text"
              value={DTO.titulo}
              change={(e: any) => {
                handleOnChange('titulo', e.target.value);
              }}
              changeBlur={(e: any) => {
                handleOnChange(
                  'titulo',
                  `${e.target.value}${
                    e.target.value.indexOf('?') === -1 ? '?' : ''
                  }`
                );
              }}
            />
            <h3 className="subtitle">Descrição</h3>

            <textarea
              placeholder="Informações adicionais sobre o vídeo, como uma breve visão geral do conteúdo."
              onFocus={() => {
                setFocus(true);
              }}
              onBlur={() => {
                setFocus(false);
              }}
              value={DTO.descricao}
              className="comment"
              onChange={(e) => {
                handleOnChange('descricao', e.target.value);
              }}
            ></textarea>
          </div>

          <div className="container-box">
            <p className="title">Categorização e Visibilidade</p>
            <div className="resume">
              <IconInfo />
              <p>
                Defina sua posição! Selecione a categoria que melhor descreve
                seu vídeo e adicione hashtags relevantes para alcançar mais
                pessoas. Quanto mais específicas, melhor será para os
                espectadores encontrarem seu conteúdo!
              </p>
            </div>
            <div style={{ zIndex: 2 }}>
              <h3 className="subtitle">Digite as categorias desejadas</h3>

              <InputHash
                add={() => {
                  handleCategorias(categ);
                }}
                placeholder="Ex: Carro"
                tipo="text"
                change={(e: any) => {
                  setCateg(e.target.value);
                }}
                value={categ}
              />

              {/* <SelectDefaultSearch
              tipo="2"
              value={{ label: 'Selecione...', value: 'selecione' }}
              options={categorias}
              onChange={(e: any) => {
                handleCategorias(e);
              }}
              searchOptions={() => {
                console.log('buscar');
              }}
            /> */}
              {DTO.listaInteresses.length > 0 && (
                <ListHashStateless
                  tipo="string"
                  data={DTO.listaInteresses}
                  remove={(e: any) => {
                    handleCategorias(e);
                  }}
                />
              )}
            </div>
            <div>
              <h3 className="subtitle">Digite as hashtags</h3>
              <InputHash
                add={() => {
                  handleHash(hash);
                }}
                placeholder="Ex: Carro"
                tipo="text"
                change={(e: any) => {
                  setHash(e?.target?.value);
                }}
                value={hash}
              />

              {/* <SelectDefaultSearch
              searchOptions={(inputValue:string) => {
                  return getHashtags(inputValue)
              }}
              tipo="2"
              value={{ label: 'Selecione...', value: 'selecione' }}
              options={hashtags}
              onChange={(e: any) => {
                handleHash(e.label);
              }}
            /> */}

              <ListHashStateless
                tipo="string"
                data={DTO.listaHashtags}
                remove={(e: any) => {
                  handleHash(e);
                }}
              />
            </div>
          </div>

          <div className="areaButton">
            <ButttonDefault
              title="Enviar Vídeo"
              tipo="6"
              onClick={() => {
                handleSubmit();
              }}
              icon={loading ? 'Loading' : ''}
            />
          </div>
        </div>
        <div className="areaRight">
          <span className="video">
            {videoFile && (
              <>
                <h3 className="subtitle">Pré-visualizar Vídeo</h3>
                <VideoPreview
                  video={videoFile ? URL.createObjectURL(videoFile) : null}
                />
              </>
            )}
          </span>
          <h3 style={{ marginTop: '20px' }} className="subtitle">
            Pré-visualizar Postagem
          </h3>
          <span className="cardpost">
            <CardPreview data={DTO} />
          </span>
        </div>
      </div>
    </Container>
  );
}
