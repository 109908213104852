import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import ButttonDefault from '../../components/ButtonDefault';
import { Container } from './styles';
import { toast } from 'react-toastify';
import apiDoubts from '../../services/api';
import EmptyProducts from '../../components/EmptyProducts';
import CalendarRange from '../../components/CalendarRange';
import CardConsultoriaPaga from '../../components/CardConsultoriaPaga';
import UserContext from '../../contexts/UserContext';
import ModalAttProduto from '../../components/Empresa/ModalAttProduto';
import CardConsultoriaGratis from '../../components/CardConsultoriaGratis';
import { IconArrowLeft } from '../../components/Icons';

export default function ConsultoriaPaga() {
  const [unavailableHoursFormated, setUnavailableHoursFormated] = useState<any>(
    []
  );
  const [datesToRemove, setDatesToRemove] = useState<Array<string>>([]);
  const [removeSchedules, setRemoveSchedule] = useState<boolean>(false);

  const [modalDeleteConsultant, setModalDeleteConsultant] =
    useState<boolean>(false);

  const [typeRemoveHours, setTypeRemoveHours] = useState<
    'removeAll' | 'removeOnlyEmpty' | null
  >(null);

  const days = Object.keys(unavailableHoursFormated);

  const { user } = useContext(UserContext);

  useEffect(() => {
    getUnavailableHours();
  }, []);

  async function getUnavailableHours() {
    await apiDoubts
      .get(`consultoriagratis-horarios?consultorId=${user?._id}`)
      .then(async (response: any) => {
        let newUnavailableArray: any = [];

        response?.data?.data?.docs?.forEach((row: any) => {
          newUnavailableArray.push(row);
        });

        handleDateOrdenate(newUnavailableArray);
      });
  }

  function validateDay(day: any) {
    if (
      moment(day).isBefore(
        moment(new Date()),
        `${moment(day).hour() === 0 ? 'days' : 'hours'}`
      )
    ) {
      return false;
    } else {
      return true;
    }
  }

  async function saveChanges(e: any) {
    let newArrayValidate = e.filter((date: any) => {
      if (validateDay(date) === true) {
        let newDateFormat = moment(e).format('YYYY-MM-DD HH:mm:ss');
        return newDateFormat;
      }
    });

    if (newArrayValidate.length !== e.length) {
      toast.error(
        'Ops, infelizmente 1 ou mais horários não puderam ser adicionados',
        {
          className: 'toast-error',
          theme: 'colored',
        }
      );
    }

    try {
      let response = await apiDoubts
        .post(`consultoriagratis-horarios`, { horarios: newArrayValidate })
        .then((response: any) => {
          if (response?.data?.status === 'error') {
            throw new Error(
              'Ops, tivemos um problema para atualizar a sua agenda, tente novamente mais tarde'
            );
          }

          toast.success('Agenda Atualizada', {
            className: 'toast-sucess',
            theme: 'colored',
          });
          getUnavailableHours();
        });
      if (response !== null) {
        return true;
      }
    } catch (error: any) {
      toast.error(error.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }

    return true;
  }

  async function removeHours(removeAllHours: boolean) {
    setRemoveSchedule(false);

    if (removeAllHours) {
      setTypeRemoveHours('removeAll');
    } else {
      setTypeRemoveHours('removeOnlyEmpty');
    }

    try {
      if (!datesToRemove)
        throw new Error('Por favor, selecione um horário para remover');

      await apiDoubts
        .delete(`consultoriagratis-horarios`, {
          data: {
            horarios: datesToRemove,
            cancelarConsultorias: removeAllHours,
          },
        })
        .then((response) => {
          getUnavailableHours();
          setTypeRemoveHours(null);

          setModalDeleteConsultant(false);

          setDatesToRemove([]);

          if (response?.data?.status === 'error') {
            throw new Error(
              'Ops, tivemos um problema para atualizar a sua agenda, tente novamente mais tarde'
            );
          }

          toast.success('Agenda atualizada', {
            className: 'toast-sucess',
            theme: 'colored',
          });
        });

      setRemoveSchedule(false);
    } catch (error: any) {
      setDatesToRemove([]);
      setTypeRemoveHours(null);
      setModalDeleteConsultant(false);

      setRemoveSchedule(false);
      toast.error(error.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  // ------------------- NEW FUNCTIONS -------------------  //

  function handleDateOrdenate(dates: Date[]) {
    const result: Record<string, Date[]> = {};

    dates.reverse().forEach((date: any) => {
      const day = moment(date?.data).format('YYYY-MM-DD');
      if (!result[day]) {
        result[day] = [];
      }
      result[day].unshift(date);
    });

    setUnavailableHoursFormated(result);
  }

  function handleDatesToRemove(e: string) {
    if (datesToRemove.includes(e)) {
      let newDates = datesToRemove.filter((row: string) => {
        return e !== row;
      });
      setDatesToRemove(newDates);
    } else {
      setDatesToRemove([e, ...datesToRemove]);
    }
  }

  function handleDeleteAllDatesInDay(day: string) {
    let hoursToAddInDay = unavailableHoursFormated[day]
      ?.map((row: any) => {
        if (row.data) return row.data;
      })
      .filter(Boolean);

    setDatesToRemove((oldArray) => {
      let newArray = [...oldArray];
      hoursToAddInDay.forEach((hour: string) => {
        if (!newArray.includes(hour)) {
          newArray.push(hour);
        }
      });
      return newArray;
    });
  }

  return (
    <>
      <Container>
        <ModalAttProduto
          id="modalAlertDeleteConsultant"
          onClose={() => {
            setModalDeleteConsultant(false);
          }}
          openModal={modalDeleteConsultant}
          width={'400px'}
          scroll={false}
          title="Cancelamento de horários"
        >
          <h3>
            Você selecionou {datesToRemove.length}{' '}
            {datesToRemove.length > 1
              ? 'horários para serem removidos'
              : 'horário para ser removido'}
          </h3>
          <span className="buttonAreaRemoveHours">
            <ButttonDefault
              title={'Remover apenas horários livres'}
              tipo="9"
              full
              hover="pouco"
              onClick={() => {
                removeHours(false);
              }}
              icon={typeRemoveHours === 'removeOnlyEmpty' ? 'Loading' : ''}
            />
            <ButttonDefault
              title={'Remover todos os horários'}
              tipo="8"
              full
              hover={'pouco'}
              onClick={() => {
                removeHours(true);
              }}
              icon={typeRemoveHours === 'removeAll' ? 'Loading' : ''}
            />
          </span>
        </ModalAttProduto>

        <div className="areaLeft">
        <a className="voltar" href='/consultor/listar' >
            <IconArrowLeft color={'var(--paleta1C)'}/>
            <p>Voltar</p>
          </a>
          <h3 className="title">Consultoria Grátis</h3>
          <h4 className="subtitle">
            Dias e horários disponíveis para consulto ias Consultorias Grátis
          </h4>
          {unavailableHoursFormated ? (
            <div className="areaCards">
              <ul>
                {days?.map((day) => {
                  return (
                    <>
                      <li key={day}>
                        {moment(day).format('DD/MM/YYYY')}{' '}
                        <span
                          className="selectHours"
                          onClick={() => {
                            handleDeleteAllDatesInDay(day);
                          }}
                        >
                          selecionar todos os horários
                        </span>
                      </li>
                      <span className="cardConsultoriaWrapper">
                        {unavailableHoursFormated[day]?.map(
                          (row: any, key: number) => (
                            <CardConsultoriaGratis
                              data={row}
                              type="consultor"
                              busyHour={row.status === 'ocupado' ? true : false}
                              selected={datesToRemove.includes(row?.data)}
                              select={(e: any) => {
                                handleDatesToRemove(e);
                              }}
                            />
                          )
                        )}
                      </span>
                    </>
                  );
                })}
              </ul>
            </div>
          ) : (
            <EmptyProducts
              description="Selecione no calendários quais os melhores horários para você atender"
              title="Ops, você não tem horários disponíveis"
            />
          )}
        </div>
        <div className="areaRight">
          <h3 className="title">Adicionar nova disponibilidade</h3>

          <CalendarRange
            sendDates={(e: Array<string | null> | string) => {
              return saveChanges(e);
            }}
            freeConsultancies
          />

          <ButttonDefault
            title={'Remover horários'}
            tipo={'8'}
            full={true}
            onClick={() => {
              setModalDeleteConsultant(true);
            }}
            hover="pouco"
            icon={removeSchedules ? 'Loading' : ''}
          />
        </div>
      </Container>
    </>
  );
}
