import React from "react"
import {DivCheckbox} from "./styles"

interface Props {
    name: string
    value: boolean
    onClick: (key: string, value: boolean) => void
    placeholder?: string
    label?: string
    readOnly?: boolean
    onChange?: any
}

const Checkbox2: React.FC<Props> = (Props) => {

    const handleCheckbox = () => {
        if( !Props.readOnly ){

            let value = Props.value
            if( value ){
                Props.onClick(Props.name, false)
            } else {
                Props.onClick(Props.name, true)
            }

        }
    }
    
    return (
        <DivCheckbox className="divCheckbox">
            {Props.label && <div className="label">{Props.label}</div>}

            <div className={`checkbox ${Props.value && "active"}`} onClick={handleCheckbox}>
                <span className="square"></span>
                {Props.placeholder && <span className="text">{Props.placeholder}</span>}
            </div>
        </DivCheckbox>
    )
    
}

export default Checkbox2