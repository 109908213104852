import styled from "styled-components";

export const Container = styled.div`
height: 288px;
border-radius: 10px;
background-color: var(--background2);
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 10px;

.imageProfileConsultor{
    width: 116px;
    height: 116px;
    border-radius: 55px;
    border: 3px solid var(--branco);
    background-position: center;
    background-size: cover;
    background-color: var(--background2);
}
.nickNameConsultor{
    font-size:16px;
    font-weight: 700;
    line-height: 20px;
    color: var(--paleta2E);
    max-width: 80%;
    overflow: hidden;
}
.consultorDescription{
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: var(--texto);
    max-width: 80%;
    max-height: 54px;
    overflow: hidden;
}
`

export const ConviteConsultor = styled.div`
padding: 10px;
background-color: var(--background2);
border-radius: 10px;
display: flex;
gap: 20px;
align-items: center;
.areaLeftConsultorConvite{
    
    .imageProfileConsultor{
        width: 116px;
        height: 116px;
        border-radius: 50%;
        border: 3px solid var(--branco);
        @media(max-width: 800px){
            width: 80px;
            height: 80px;
        }
    }
}
.areaRightConsultorConvite{
    display: flex;
    flex-direction: column;
    gap: 5px;
    .nameConsultor{
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        color: var(--paleta2E);
    }
    .descriptionConsultor{
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: var(--texto);
        max-height: 90px;
        overflow-y: scroll;
        text-align: justify;
        padding-right: 5px;
    }
}

`

export const AreaMessageConsultor = styled.div`
margin-top: 20px;
display: flex;
flex-direction: column;
gap: 10px;
.titleAreaMessageConsultor{
font-size: 13px;
line-height: 15px;
font-weight: 700;
color: var(--paleta2C)
}
.input {
    padding: 10px;
    border: none;
    width: 100%;
    max-width: 758px;
    background-color: var(--background2);
    border-radius: 10px;
    height: 150px;
    display: flex;
    outline: none;
    resize: none;
}
.areaButtonsConsultor{
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: flex-end;
}

`