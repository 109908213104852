export default function ArrowUpIcon({ size = 14, color = "#FFF" }) {
    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 1.1875V10.5625C12.9479 11.1354 12.6354 11.4479 12.0625 11.5C11.4896 11.4479 11.1771 11.1354 11.125 10.5625V3.45312L2.10156 12.4766C1.91927 12.6589 1.69792 12.75 1.4375 12.75C1.17708 12.75 0.955729 12.6589 0.773438 12.4766C0.408854 12.0339 0.408854 11.5911 0.773438 11.1484L9.79688 2.125H2.6875C2.11458 2.07292 1.80208 1.76042 1.75 1.1875C1.80208 0.614583 2.11458 0.302083 2.6875 0.25H12.0625C12.6354 0.302083 12.9479 0.614583 13 1.1875Z" fill="white" />
        </svg>
    )
}





