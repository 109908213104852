import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HeaderEmpresa from '../../../components/Empresa/HeaderEmpresa';
import ListPostCanalEmpresa from '../../../components/ListPostCanalEmpresa';
import TopConsultores from '../../../components/TopConsultores';
import UserContext from '../../../contexts/UserContext';
import apiDoubts from '../../../services/api';
import { Container, Element } from './styles';

export default function EmpresaCanal({ data }: any) {
  const [perfilCompletoEmpresa, setPerfilCompletoEmpresa] = useState<any>();
  const { user } = useContext(UserContext);
  const params = useParams<any>();

  useEffect(() => {
    if (params.id) {
      getPerfilEmpresa();
    }
  }, [params]);

  async function getPerfilEmpresa() {
    apiDoubts.get(`empresa/get-by-id/${params?.id}`).then((response: any) => {
      setPerfilCompletoEmpresa(response?.data?.data[0]);
      postItensLocalStorage(response?.data?.data[0]);
    });
  }

  async function postItensLocalStorage(item: any) {
    localStorage.setItem('empresa', JSON.stringify(item));
  }

  // async function getItensLocalStorage() {
  //   let response = localStorage.getItem('empresa');
  //   response = JSON.parse(response as any);
  //   setPerfilCompletoEmpresa(response);
  // }

  return (
    <Container>
      <HeaderEmpresa id={params?.id} page="canal" />
      {perfilCompletoEmpresa?.consultores?.length > 0 && (
        <>
          <Element />
          <div className="areaConsultores">
            <TopConsultores direction="row" empresaId={params?.id} />
          </div>
          <Element />
        </>
      )}
      <ListPostCanalEmpresa
        order={''}
        where={{
          interesse: '',
          hash: '',
        }}
      />
    </Container>
  );
}
