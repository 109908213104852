import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Banner from '../../components/Banner';
import ComentarioConsultor from '../../components/ComentariosConsultor';
import HeaderConsultor from '../../components/HeaderConsultor';
import HeaderType from '../../components/HeaderType';
import UserContext from '../../contexts/UserContext';
import apiDoubts from '../../services/api';
import { Container } from './styles';

export default function Playlist() {
  const params = useParams<any>();
  const [bannerTop, setBannerTop] = useState<any>('');
  const [bannerDown, setBannerDown] = useState<any>('');

  useEffect(() => {
    getBanners();
  }, []);

  async function getBanners() {
    const bannerTop = await apiDoubts.get('/banners?tipo=2&randon=true');
    const bannerDown = await apiDoubts.get('/banners?tipo=3&randon=true');
    setBannerDown(bannerDown.data.data[0]);
    setBannerTop(bannerTop.data.data[0]);
  }

  return (
    <Container>
      <HeaderConsultor consultorId={params.id} page="Social" />
      <main className="generic">
        <div className="content">
          <div className="bannerMobile">
            <div>
              <Banner peso="2" position="1" typeBanner="1024x90" />
            </div>
          </div>
          <ComentarioConsultor consultorId={params.id} />
        </div>
        <div className="sideRigth">
          <div className="areaBanner">
            <Banner peso="2" position="1" typeBanner="322x227" />
            <Banner peso="2" position="1" typeBanner="322x673" />
          </div>
        </div>
      </main>
    </Container>
  );
}
