/* eslint-disable @typescript-eslint/no-unused-vars */
import styled, { css } from 'styled-components';

interface props {
  tipo: string;
  skip: boolean;
}

export const Container = styled.div<props>`
  display: flex;
  flex-direction: column;
  position: relative;

  .btnArea {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    width: 100%;
    padding-left: 2px;
  }

  .areaConsultor {
    display: flex;
    justify-content: space-between;
  }

  .content {
    width: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    position: relative;

    &:hover {
      .overlay {
        opacity: 1;
        visibility: visible;
        svg {
          transform: scale(1);
        }
      }
    }
    .videoOriginal {
    }

    .adsVideo {
      display: ${({ tipo }) => tipo !== 'ads' && 'none'};
      top: 0;
      left: 0;
      z-index: 1 !important;
      width: 100%;
      border-radius: 10px;
      overflow-x: hidden;
      position: relative;

      .campaignOut {

        @media (min-width:450px){
          display: none;
        };

        display: flex;
        cursor: auto;
        align-items: center;
        border-radius: 8px;
        padding: 10px;
        gap: 10px;
        font-size: 13px;
        backdrop-filter: blur(5px);
        color: rgba(0, 0, 0, 0.8);
        .img {
          width: 50px;
          height: 50px;
          border-radius: 100px;
          background: rgba(0, 0, 0, 0.5);
          background-size: cover;
        }
        p {
          font-weight: bold;
        }
        a {
          color: #069cd1;
        }
      }

      .sc-JsfZP,
      .goNfMV {
        min-height: 100%;
        .video {
          min-height: 100%;
        }
      }

      .skip {
        margin-right: -150px;
        transition: all 0.4s;
        display: flex;
        position: absolute;
        width: ${({ skip }) => (skip ? '170px' : '80px')};
        height: 40px;
        bottom: 0;
        right: 0;
        margin-bottom: 40px;
        transition: all 0.4s;
        background-color: var(--paleta2C);
        border: none;
        align-items: center;
        justify-content: center;
        border-radius: 5px 0 0 5px;
        display: none;
        margin-right: 0px;
        display: flex;
        overflow: hidden;
        .text {
          color: var(--branco);
          font-weight: 700;
          font-size: 13px;
          line-height: 15px;

          white-space: nowrap;

          @media (max-width: 800px) {
            margin-bottom: 50px;
          }
        }
      }
    }

    .nextVideoWrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;

      top: 0;
      left: 0;
      z-index: 1 !important;
      border-radius: 10px;
      overflow-x: hidden;
      position: absolute;
      z-index: 999;
      background-color: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(20px);

      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;

      animation: fadeIn 0.5s;

      h2 {
        font-size: 26px;
        color: var(--branco);
      }

      .videoImage {
        width: 350px;
        height: 220px;
        background-color: red;
        border-radius: 6px;

        background-position: center;
        background-size: cover;
      }
    }
  }

  .descricao {
    margin-top: 20px;
    white-space: break-spaces;
  }

  @media (max-width: 830px) {
    .btnArea {
      justify-content: flex-start;
    }
  }
  @media (max-width: 630px) {
    .areaConsultor {
      .btnSeguir {
        display: none;
      }
    }
  }

  @media (min-width: 630px) {
    .btnArea {
      .buttonSeguir {
        display: none;
      }
    }
  }
`;

export const Title = styled.p`
  font-size: 28px;
  font-weight: 700;
  line-height: 42px;
  margin-top: 38px;
  @media (max-width: 800px) {
    margin-top: 18px;
  }
`;

export const Component = styled.div`
  background-color: var(--paleta1B);
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const TimeToJumpAnnounce = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 200px;
  height: fit-content;
  border-radius: 10px;
  background: rgba(46, 41, 78, 0.8);
  margin-bottom: 40px;
  padding: 6px 20px;

  position: absolute;
  bottom: 0;
  right: 0;

  color: var(--branco);
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
`;

export const AddLink = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  width: fit-content;
  height: fit-content;
  padding: 6px 12px;

  background: var(--background);
  border-radius: 6px;

  position: absolute;
  left: 10px;
  bottom: 40px;
`;

export const AddLogo = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;

  background: var(--paleta1A);

  img {
    width: 100%;
    height: 100%;
  }
`;

export const ButtonKnowMore = styled.button`
  height: 40px;
  background: var(--paleta1A);
  border: none;
  outline: none;
  border-radius: 10px;

  padding: 8px;

  color: var(--branco);
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
`;

export const AddTimer = styled.div``;
