import styled, { css } from 'styled-components';

interface props{
    copyed:boolean
}

export const Container = styled.div<props>`
  p {
    font-size: 13px;
    line-height: 15px;
    font-weight: 700;
    color: var(--paleta2C);
    margin-bottom: 5px;
  }
  .button {
    z-index: 1;
    position: relative;
    width: 90%;
    height: 40px;
    border-radius: 5px;
    background-color: var(--bgInput);
    border: none;
    display: flex;
    align-items: center;
    padding-left: 15px;
    border-radius: 10px;

    span {
      overflow-x: hidden;
      font-size: 13px;
      line-height: 18px;
      font-weight: 400;
      color: var(--textoplaceholder);
    }
    .copy {
      border-radius: 10px;
      width: 25%;
      height: 40px;
      background-color: var(--background2);
      border: none;
      position: absolute;
      top: 0;
      right: 0;
      margin-right: -10%;
      z-index: 0;
      color: var(--texto);
      font-size: 11px;
      font-weight: 700;
      transition: all 0.5s;

      &:hover {
        transition: scale 0.5s;
        transform: scale(1.05);
      }

      ${({ copyed }) =>
        copyed &&
        css`
          transition: all 0.5s;
          background-color: var(--paleta1C);
          color: var(--branco);
          transform: scale(1.08);
        `}
    }
  }
`;
