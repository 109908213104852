import React from 'react';

import { Container } from './styles';

export default function SelectDefault({ 
  children, 
  ...rest
}: any) {

  return (
      <Container>
        <select 
            name="select"
            {...rest}    
        >
            {children}
        </select>
      </Container>
  ) 
}