/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Link } from 'react-router-dom';

import { Container, Content, CardView } from './styles';
import { useEffect, useState } from 'react';
import apiDoubts from '../../../services/api';
import Moment from 'react-moment';
import Filter from '../../../components/Filter';
import { SettingsIcon } from '../../../components/Header/styles';
import NotFoundGif from '../../../assets/notfound.gif';
import NoImg from '../../../assets/no-image.png';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';

function HomeConsultor() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);
  // const [page, setpage] = useState(1)
  const [selected, setSelected] = useState({});
  const [order, setOrder] = useState<any>('data');
  const [filter, setFilter] = useState<any>('');
  const [profile, setProfile] = useState({
    id: '',
    nickName: '',
    foto: '',
  });

  useEffect(() => {
    getProfile();
    return () => {
      setProfile({
        id: '',
        nickName: '',
        foto: '',
      });
    };
  }, []);

  useEffect(() => {
    if (loading === false) {
      getVideos(profile);
    }
  }, [order, filter]);

  useEffect(() => {
    getProfile();
  }, [order, filter]);

  async function getProfile() {
    try {
      setLoading(true);
      apiDoubts.get('profile').then((response) => {
        setProfile(response.data.data);
        getVideos(response.data.data);
      });
    } catch (err: any) {}
  }

  async function getVideos(profile: any) {
    console.log(profile?.tipo);
    const endpointType =
      profile?.tipo === 'consultor'
        ? 'getPostagemConsultor'
        : 'getPostagemEmpresa';

    const response = await apiDoubts.get(
      `postagens/${endpointType}/${profile.id}`
    );
    setData(response.data.data.reverse());
    setLoading(false);
  }

  function handleOnSelected(item: any) {
    setSelected({ ...item });
  }

  console.log(data);

  return (
    <>
      {/* <Filter order={order} setOrder={setOrder} filter={filter} setFilter={setFilter} /> */}

      <Container>
        {loading === false && data.length === 0 && (
          <div className="notfound">
            <h3>Nenhuma postagem encontrada.</h3>
            <img src={NotFoundGif} alt="Gif de arquivo não encontrado" />
          </div>
        )}

        {loading === true && (
          <Content>
            {[0, 1, 2, 3, 4, 5].map((row, key) => (
              <Skeleton key={key} style={{ width: '100%', height: 200 }} />
            ))}
          </Content>
        )}

        {loading === false && (
          <Content>
            {data.map((item: any, key: any) => (
              <CardView key={key} onClick={() => handleOnSelected(item)}>
                <div
                  className="image"
                  style={{ backgroundImage: `url(${item.thumbnail})` }}
                >
                  <Link to={'/videos/editar/' + item.id}>
                    <div className="player">
                      <SettingsIcon />
                    </div>
                  </Link>
                </div>

                <div className="cardInfo">
                  <footer>
                    <Link to={`/visualizar/${item.id}`}>
                      <div className="header">
                        {profile?.foto.includes('https://') ? (
                          <div
                            className="photo"
                            style={{ backgroundImage: `url(${profile.foto})` }}
                          />
                        ) : (
                          <div
                            className="photo"
                            style={{ backgroundImage: `url(${NoImg})` }}
                          />
                        )}
                        <div className="title">
                          <h3>{item.titulo}</h3>
                          <span className="visual">
                            {item.qtdVisualizacoes > 0
                              ? item.qtdVisualizacoes
                              : 0}{' '}
                            visualizações |{' '}
                            {moment(item.data)
                              .add('hours', 12)
                              .locale('pt-br')
                              .fromNow()}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </footer>
                </div>
              </CardView>
            ))}
          </Content>
        )}
      </Container>
    </>
  );
}

export default HomeConsultor;
