import { useContext } from 'react';
import UserContext from '../../contexts/UserContext';
import { IconImage } from '../Icons';
import { Container } from './styles';

interface VProps {
  data: any;
}

export default function CardPreview({ data }: VProps) {
  const { user } = useContext<any>(UserContext);

  return (
    <Container>
      <div
        className="video"
        style={{ backgroundImage: `url(${data?.thumbnail})` }}
      >
        {!data.thumbnail && <IconImage />}
      </div>
      <div className="info">
        <div
          className="profile"
          style={{ backgroundImage: `url(${user?.foto})` }}
        ></div>
        <div className="content">
          <h3 className="title">{data.titulo}</h3>
          <h4 className="name">{user?.nickName}</h4>
          <p className="visu">83 visualizações • há 1 minuto </p>
        </div>
      </div>
    </Container>
  );
}
