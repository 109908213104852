import styled from 'styled-components';

interface props {
  tipo?: string;
}

export const Container = styled.div<props>`
  height: 40px;
  width: 100%;

  .css-tlfecz-indicatorContainer {
    svg {
      * {
        fill: #667085;
        fill: ${({ tipo }) => tipo === '2' && '#AAAAAA'};
      }
    }
  }
`;
