import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useDebouncedCallback } from 'use-debounce';
import apiDoubts from '../../../services/api';
import ButttonDefault from '../../ButtonDefault';
import {
  IconArrowDown,
  IconArrowUp,
  IconCloseSquare,
  IconLoading,
} from '../../Icons';
import Inputdefault from '../../Inputs/InputDefault';
import { ContainerOverlay, Container, ModalContacts } from './styles';
import { AxiosResponse } from 'axios';

interface ModalProps {
  id?: string;
  onClose: () => void;
  openModal?: boolean;
  width?: string;
  title?: string;
  scroll?: boolean;
  reloadAllEmails?: () => void;
}

export default function SendMessage({
  id,
  onClose,
  width,
  openModal,
  title,
  scroll,
  reloadAllEmails,
}: ModalProps) {
  const [contactsList, setContactsList] = useState<any[]>([]);
  const [modalContacts, setModalContacts] = useState<boolean>(false);
  const [contactSelected, setContactSelected] = useState<any>({});
  const [isSearchForUSer, setIsSearchForUser] = useState<boolean>(false);
  const [isSendingEmail, setIsSendingEmail] = useState<boolean>(false);
  const [DTO, setDTO] = useState<any>({
    mensagem: '',
    destinatarioId: '',
    tipoConversa: 'email',
    titulo: '',
  });

  const debounced = useDebouncedCallback(
    // function
    (value: any) => {
      getContacts(value);
    },
    // delay in ms
    1000
  );

  function handleOnChange(name: any, value: any) {
    const newDTO: any = DTO;
    newDTO[name] = value;
    setDTO({ ...newDTO });
  }

  async function getContacts(search: string) {
    setIsSearchForUser(true);
    if (search) {
      apiDoubts
        .get(`/search-email?email=${search}&limit=10`)
        .then((response: any) => {
          // setContactsList(response?.data?.data)
          integrateEmails(response?.data?.data);

          setModalContacts(true);
          console.log(response.data.data);
          setIsSearchForUser(false);
        })
        .catch((err: any) => {
          setIsSearchForUser(false);
          toast.error('não encontramos esse usuário', {
            className: 'toast-error',
            theme: 'colored',
          });
        });
    }
  }

  function integrateEmails(data: any) {
    console.log(data);
    let completeData: any[] = [];
    data?.agencia?.map((row: any) => {
      completeData.push(row);
    });
    data?.consultor?.map((row: any) => {
      completeData.push(row);
    });
    data?.empresa?.map((row: any) => {
      completeData.push(row);
    });
    data?.user?.map((row: any) => {
      completeData.push(row);
    });
    console.log(completeData);
    setContactsList(completeData);
  } 

  function handleOpenOrCloseModal() {
    if (modalContacts === true && contactsList.length > 0) {
      setModalContacts(false);
    } else if (modalContacts === false && contactsList.length > 0) {
      setModalContacts(true);
    }
  }

  async function sendMessage() {
    try {
      if (!DTO.destinatarioId || !DTO.mensagem || !DTO.titulo) {
        throw new Error('Todos os campos são obrigatórios');
      }

      setIsSendingEmail(true);

      const apiResponse:AxiosResponse<any> = await apiDoubts.post(`/conversa/enviar-mensagem`, DTO)

      if (apiResponse?.data?.status === 'error'){
        throw new Error (apiResponse?.data?.message)
      }

      setModalContacts(false)
      setDTO({
            mensagem: '',
            titulo: '',
            destinatarioId: '',
            tipoConversa: 'email',
          });
      reloadAllEmails && reloadAllEmails()
      setContactSelected('')
      setIsSendingEmail(false)
      onClose()

      // await apiDoubts.post(`/mensagem-mailbox`, DTO).then((response: any) => {
      //   setModalContacts(false);
      //   reloadAllEmails && reloadAllEmails();
      //   setDTO({
      //     mensagem: '',
      //     titulo: '',
      //     destinatarioId: '',
      //     tipoMensagem: 'email',
      //   });
      //   setContactSelected('');
      //   setIsSendingEmail(false);
      //   onClose();
      // });

    } catch (err: any) {
      setIsSendingEmail(false);
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          onClose();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(()=>{
    console.log(DTO)
  },[DTO])

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <ContainerOverlay id={id} openModal={openModal}>
      <Container
        openModal={openModal}
        scroll={scroll}
        isOpen={modalContacts}
        ref={wrapperRef}
      >
        <div className="header">
          <p className="titleModal">{title}</p>
          <button className="close" onClick={onClose}>
            <IconCloseSquare color={'#0FBABA'} />
          </button>
        </div>

        <form
          className="main"
          onSubmit={(e: any) => {
            e.preventDefault();
            console.log('submeteu');
          }}
        >
          <div className="areaInput">
            <p className="textInputSendMessage">Destinatário</p>
            <Inputdefault
              change={(e: any) => {
                setContactSelected(e.target.value);
                setModalContacts(false);
                debounced(e.target.value);
              }}
              placeholder={'Ex: Consultor Doubts'}
              value={contactSelected && contactSelected?.nickName}
              tipo={'text'}
              icon={
                modalContacts ? (
                  <IconArrowUp />
                ) : isSearchForUSer ? (
                  <IconLoading size={20} />
                ) : (
                  <IconArrowDown />
                )
              }
              onClickIcon={() => {
                handleOpenOrCloseModal();
              }}
            />
            <ModalContacts isOpen={modalContacts}>
              {contactsList?.length > 0 &&
                contactsList.map((row: any, key: number) => (
                  <div
                    className="cardContact"
                    onClick={() => {
                      setContactSelected(row);
                      handleOnChange('destinatarioId', row.id);
                      setModalContacts(false);
                    }}
                  >
                    <div
                      className="imageProfile"
                      style={{ backgroundImage: `url(${row?.foto})` }}
                    ></div>
                    <p className="contactName">{row?.nickName}</p>
                  </div>
                ))}
            </ModalContacts>
          </div>

          <div className="areaInputComment">
            <p className="textInputSendMessage">Assunto</p>
            <Inputdefault
              change={(e: any) => {
                handleOnChange('titulo', e.target.value);
              }}
              value={DTO.titulo}
              tipo={'text'}
              placeholder={'Ex: Nova Mentoria Doubts'}
            />
          </div>

          <div className="areaInputMessage">
            <p className="textInputSendMessage">Mensagem</p>
            <textarea
              value={DTO?.mensagem}
              className="input"
              placeholder="Assuntos..."
              onChange={(e) => {
                handleOnChange('mensagem', e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  sendMessage();
                }
              }}
            />
          </div>

          <div className="buttons">
            <ButttonDefault
              title={'Cancelar'}
              tipo={'11'}
              onClick={() => {
                onClose();
              }}
            />
            <ButttonDefault
              icon={isSendingEmail ? 'Loading' : ''}
              title={'Enviar'}
              tipo={'6'}
              onClick={() => {
                sendMessage();
              }}
            />
          </div>
        </form>
      </Container>
    </ContainerOverlay>
  );
}
