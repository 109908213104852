import moment from 'moment';
import { getDaysInRange } from './getDaysInRange';

type IObject = {
  [data: string]: number;
};

export function calculatePercentageInAnObject(object: IObject): number {
  const lastMonthDay = moment().subtract(30, 'days').format('DD-MM-YYYY');
  const beforeLastMonth = moment().subtract(60, 'days').format('DD-MM-YYYY');
  const currentDay = moment().format('DD-MM-YYYY');

  const lastMonthRange = getDaysInRange(lastMonthDay, currentDay);
  const beforeLastMonthRange = getDaysInRange(beforeLastMonth, lastMonthDay);

  let allViewsLastMonth: number = 0;
  let allViewsBeforeLastMonth: number = 0;

  lastMonthRange?.forEach((day) => {
    const dayViews = object[day] ?? 0;
    allViewsLastMonth = allViewsLastMonth + dayViews;
  });

  beforeLastMonthRange?.forEach((day) => {
    const dayViews = object[day] ?? 0;
    allViewsBeforeLastMonth = allViewsBeforeLastMonth + dayViews;
  });

  const increase = allViewsLastMonth - allViewsBeforeLastMonth;
  const percentualIncrease = (increase / allViewsBeforeLastMonth) * 100;

  const percentualFormatado =
    percentualIncrease === Infinity ? 100 : percentualIncrease;

  return percentualFormatado;
}
