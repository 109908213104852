import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  cursor: pointer;
  .image {
    position: relative;
    height: 80px;
    width: 190px;
    background-position: center;
    background-size: cover;
    border-radius: 5px;

    .overlayRemove {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      svg {
        transform: scale(0);
      }

      &:hover {
        opacity: 1;
        display: flex;
        transition: all 0.3s;
        svg {
          transform: scale(1);
        }
      }
    }
  }
  .text {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-height: 78px;
    overflow: hidden;
    
    .title {
      font-size: 13px;
      line-height: 15px;
      font-weight: bold;
      color: var(--paleta2C);
      margin: 0;
    }
    .description {
      margin: 0;
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      color: var(--texto);
    }
  }
`;
