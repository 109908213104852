import { Container } from './styles';

interface VProps extends React.InputHTMLAttributes<HTMLInputElement> {
  placeholder: string;
  tipo: 'text' | 'file';
  change: (e: any) => void;
  value: string;
  add: (e: any) => void;
}

export default function InputHash({
  placeholder,
  tipo,
  change,
  value,
  add,
  ...rest
}: VProps) {
  function handleSubmit(e: any) {
    if (e.key === 'Enter') {
      add(value);
    }
  }

  return (
    <>
      <Container type={tipo}>
        <input
          className="input"
          placeholder={placeholder}
          type={tipo}
          onChange={change}
          value={value}
          onKeyDown={handleSubmit}
          {...rest}
        />
        <div className="adicionar" onClick={add}>
          <p>Adicionar Hashtag</p>
        </div>
      </Container>
    </>
  );
}
