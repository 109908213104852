import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Container } from './styles';
import apiDoubts from '../../../services/api';
import UserContext from '../../../contexts/UserContext';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import ComentariosCard from '../../../components/ComentarioCard';
import ComentarAreaConsultoria from '../../../components/ComentarAreaConsultoria';
import moment from 'moment';
import LottieAnimation from '../../../components/Lootie';
import ButttonDefault from '../../../components/ButtonDefault';
import ModalAlert from '../../../components/ModalAlert';

import { useBeforeunload } from 'react-beforeunload';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { IconRelogio } from '../../../components/Icons';
import { time } from 'console';

//usuario dentro do periodo
//usuario dono da consultoria
//usuario sair pós horario

//consultor assitir sua consultoria
//consultor saber quando acabar consultoria

interface IPropsConsulta {
  assunto: string;
  cadastro: {
    id: string;
    nickName: string;
    // Outros campos de cadastro se houver
  };
  foto: string;
  id: string;
  comentario: string;
  consultor: {
    id: string;
    nickName: string;
    // Outros campos de consultor se houver
  };
  data: string; // Formato: "YYYY-MM-DD HH:mm:ss"
  status: string;
  tempo: number;
  tipo: string;
}

interface IPropsParams {
  consultoriaId: string;
  start: string;
  end: string;
}

export const encodeConsultoria = ({
  consultoriaId,
  start,
  end,
}: IPropsParams) => {
  const jsonStr = JSON.stringify({
    consultoriaId,
    start,
    end,
  });
  return btoa(jsonStr);
};

export const decodeConsultoria = (code: string) => {
  const jsonStr = atob(code);
  let obj = JSON.parse(jsonStr);
  return obj;
};

export default function LiveConsultoria() {
  let intervalComents: any;
  const { user } = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [modalLive, setModalLive] = useState(false);
  const [consultoria, setConsultoria] = useState<any>({});
  const [consultoriaComments, setConsultoriaComments] = useState<Array<any>>(
    []
  );
  const params: any = useParams();
  const paramsConsultoria: IPropsParams = decodeConsultoria(params.id);
  const history = useHistory();

  const [timesConsultoria, setTimesConsultoria] = useState<any>({
    start: {},
    end: {},
  });

  const handlePermissionPageTimeReload = useCallback(() => {
    let timeFormated = calculateTime(
      paramsConsultoria.start,
      paramsConsultoria.end
    );

    setTimesConsultoria({ ...timeFormated });
  }, [paramsConsultoria]);

  useEffect(() => {
    const interval = setInterval(() => {
      let timeFormated = calculateTime(
        paramsConsultoria.start,
        paramsConsultoria.end
      );

      if (timeFormated.end.expire) {
        console.log('debug', timeFormated);
        toast.error('Essa consultoria atingiu o tempo limite.');
        history.push('/');
      }

      setTimesConsultoria({ ...timeFormated });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const handlePermissionPage = useCallback(async () => {
    try {
      setLoading(true);

      const response = await apiDoubts(
        'consultoria/' + paramsConsultoria.consultoriaId
      );

      if (
        calculateTime(paramsConsultoria.start, paramsConsultoria.end).end.expire
      )
        throw new Error('Essa consultoria atingiu o tempo limite.');

      if (user.tipo === 'consultor') setModalLive(true);

      //chama a função para calcular quanto tempo restante ainda tem
      handlePermissionPageTimeReload();

      let responseConsultoria: IPropsConsulta = response.data.data;
      setConsultoria({ ...responseConsultoria });

      //carregamento de comentarios após tudo validado
      getComments(paramsConsultoria.consultoriaId);

      //inicia-se um intervalo de segundos para verificar comentarios
      intervalComents = setInterval(() => {
        getComments(paramsConsultoria.consultoriaId);
      }, 5000);

      setLoading(false);
    } catch (error: any) {
      if (!window.localStorage.getItem('token')) {
        toast.error(
          'Para assistir a live é necessário realizar o login antes.'
        );
        history.push('/login');
      } else {
        toast.error(error.message);
        history.push('/');
      }
    }

    return () => clearInterval(intervalComents);
  }, [paramsConsultoria]);

  useEffect(() => {
    handlePermissionPage();
  }, []);

  async function getComments(id: string) {
    const response = await apiDoubts.get(
      `comentarios-consultoria?consultoriaId=${id}`
    );
    setConsultoriaComments(response?.data?.data);
  }

  async function submitNewComment(e: string) {
    const objectPost = {
      comentario: e,
      consultoriaId: consultoria.id,
    };

    const response = await apiDoubts.post(
      `comentarios-consultoria`,
      objectPost
    );
    getComments(consultoria.id);
  }

  async function payConsultant(): Promise<void> {
    if (user?.tipo !== 'cadastro') return;

    try {
      apiDoubts?.put(`/wallet-finalizar-consultoria`, {
        consultoriaId: consultoria?.id,
      });
    } catch (err) {
      console.error(err);
    }
  }

  console.log('timesConsultoria', timesConsultoria);

  return (
    <Container>
      {user.tipo === 'consultor' && (
        <ModalAlert
          id="modal-consultor"
          prosseguir={() => window.open('/live', '_blank')}
          textProsseguir={'Configurações de Live'}
          textCancel="Já estou em live"
          onClose={() => setModalLive(false)}
          openModal={modalLive}
        >
          <div className="modal-alerta-consultor">
            <h3 className="subtitle">Atenção</h3>

            {timesConsultoria.start.expire ? (
              <p>
                A consultoria já começou e está em andamento. Certifique-se de
                manter a transmissão ativa para que a consultoria prossiga sem
                interrupções. É importante ressaltar que a consultoria será
                encerrada automaticamente ao término do horário.
              </p>
            ) : (
              <p>
                A consultoria está marcada para começar em{' '}
                <b>{timesConsultoria.start.string}</b>. Certifique-se de iniciar
                a transmissão para que a consultoria comece pontualmente. É
                importante ressaltar que a consultoria será encerrada
                automaticamente ao término do horário.
              </p>
            )}
            <ul>
              <li>
                Se você já iniciou a transmissão e está pronto para começar a
                consultoria, clique em <b>"Já estou em live"</b>.
              </li>
              <li>
                Se você ainda não iniciou a transmissão, clique em{' '}
                <b>"Configurações de Live"</b> para configurar sua transmissão
                ao vivo.
              </li>
            </ul>
          </div>
        </ModalAlert>
      )}

      <div className="center-content">
        <div className="leftArea">
          <div className="video">
            {timesConsultoria.start.expire === false ? (
              <div className="overlay-time">
                {user.tipo === 'consultor' && (
                  <iframe src={consultoria?.streamLink} />
                )}
                <div className="full">
                  <iframe src="https://lottie.host/embed/48e6b833-7528-4293-9b65-b76c7442bd25/hxZNdKsMck.json"></iframe>

                  <div className="content-time">
                    <span>
                      A consultoria começa em {timesConsultoria.start.string}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <iframe src={consultoria?.streamLink} />
            )}
          </div>

          {timesConsultoria.start.expire !== false && (
            <div className="date-infos">
              <div className="time">
                <IconRelogio />
                <span>
                  Faltam {timesConsultoria.end.string} para encerrar a
                  consultoria.
                </span>
              </div>
            </div>
          )}
          <div className="infos">
            <div
              className="photo"
              style={{
                backgroundImage: `url(${
                  consultoria?.consultor?.foto ??
                  'https://bucket.backendtropa.com.br/file/41e85a3a-dd51-405b-b265-73a4b2455199'
                })`,
              }}
            />
            <div className="user">
              <h1>{consultoria.assunto ?? 'Assunto'}</h1>
              <p>
                {consultoria.comentario ??
                  'Comentário sobre a consultoria solicitada.'}
              </p>
            </div>
          </div>
        </div>

        <div className="rightArea">
          {consultoria?.cadastro?.id === user?.id ||
          consultoria?.consultor?.id === user?.id ? (
            <ComentarAreaConsultoria
              comment={async (e) => {
                let response: any = await submitNewComment(e);
                if (response) {
                  setConsultoriaComments((oldArray) => [...oldArray, e]);
                }
                return response;
              }}
            />
          ) : (
            <>
              {!loading && (
                <div className="aviso">
                  Apenas o consultor e o usuário que solicitou a consultoria têm
                  permissão para comentar neste espaço.
                </div>
              )}
            </>
          )}

          <div className="overflow">
            {!loading && consultoriaComments.length === 0 && (
              <ComentariosCard
                comentario={{
                  cadastros: {
                    nickName: 'Doubts',
                    foto: '/favicon.ico',
                  },
                  comentario:
                    'Nenhuma observação foi feita até o momento nesta consultoria.',
                }}
              />
            )}
            {consultoriaComments?.map((row: any, index: number) => (
              <ComentariosCard comentario={row} />
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
}

export const calculateTime = (start: string, end: string) => {
  const agora = moment(); // Obtém o horário atual
  const inicio = moment(start, 'YYYY-MM-DD HH:mm'); // Converte a string de horário de início para um objeto Moment
  const termino = moment(end, 'YYYY-MM-DD HH:mm'); // Converte a string de horário de término para um objeto Moment

  // Calcula o tempo restante até o início e o término
  const tempoAteInicio = moment.duration(inicio.diff(agora));
  const tempoAteTermino = moment.duration(termino.diff(agora));

  // Formata o tempo restante em string 'HH:mm:ss', omitindo valores zero
  const formatString = (duration: any) => {
    let dias = duration.days();
    let horas = duration.hours();
    let minutos = duration.minutes();
    let segundos = duration.seconds();
    let formattedString = '';

    // Adiciona dias se houver
    if (dias > 0) {
      formattedString += `${dias} dia${dias > 1 ? 's' : ''} `;
    }

    // Adiciona horas se houver ou se já tem alguma informação
    if (horas > 0 || formattedString.length > 0) {
      formattedString += `${horas} hora${horas > 1 ? 's' : ''} `;
    }

    // Adiciona minutos se houver ou se já tem alguma informação
    if (minutos > 0 || formattedString.length > 0) {
      formattedString += `${minutos} minuto${minutos > 1 ? 's' : ''} `;
    }

    // Adiciona segundos se houver ou se já tem alguma informação
    if (segundos > 0 || formattedString.length === 0) {
      formattedString += `${segundos} segundo${segundos > 1 ? 's' : ''}`;
    }

    return formattedString.trim();
  };

  return {
    start: {
      seg: tempoAteInicio.seconds(),
      min: tempoAteInicio.minutes(),
      hours: tempoAteInicio.hours(),
      days: tempoAteInicio.days(),
      string: formatString(tempoAteInicio),
      expire:
        tempoAteInicio.seconds() <= 0 &&
        tempoAteInicio.minutes() <= 0 &&
        tempoAteInicio.hours() <= 0 &&
        tempoAteInicio.days() <= 0
          ? true
          : false,
    },
    end: {
      seg: tempoAteTermino.seconds(),
      min: tempoAteTermino.minutes(),
      hours: tempoAteTermino.hours(),
      days: tempoAteTermino.days(),
      string: formatString(tempoAteTermino),
      expire:
        tempoAteTermino.seconds() <= 0 &&
        tempoAteTermino.minutes() <= 0 &&
        tempoAteTermino.hours() <= 0 &&
        tempoAteTermino.days() <= 0
          ? true
          : false,
    },
  };
};
