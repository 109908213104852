import { IDTOAdsense } from '../../../pages/Campanha/Criar';
import Home from '../../../pages/Home';
import apiDoubts from '../../../services/api';
import InputFile from '../../ButtonFile';
import { Container } from './styles';

import bannerExample from '../../../assets/bannerExample.png';
import videoExample from '../../../assets/videoExample.png';
import ButttonDefault from '../../ButtonDefault';
import bucket from '../../../services/bucket';
import { useState } from 'react';
import { LoadingWrapper } from '../../../pages/Videos/CriarConteudo/styles';
import LottieAnimation from '../../Lootie';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

interface props {
  handleOnChangeDTO: (
    key: string,
    typeAdsense: string | number | any[]
  ) => void;
  data: IDTOAdsense;
  setPage: (page: string) => void;
  sendCampaing: () => any;
}

export default function Midias({
  handleOnChangeDTO,
  data,
  sendCampaing,
  setPage,
}: props) {
  const [postingVideo, setPostingVideo] = useState<boolean>(false);
  const [sendingCampaing, setSendingCampaing] = useState<boolean>(false);

  const history = useHistory();

  async function postVideo(file: any) {
    setPostingVideo(true);
    let formData = new FormData();
    formData.append('file', file.target.files[0]);

    const acceptedExtensions = [
      'mp4',
      'avi',
      'mkv',
      'mov',
      'wmv',
      'flv',
      'mpeg',
      '3gp',
      'webm',
    ];
    const currentExtension = file?.target?.files[0]?.name
      ?.split('.')
      ?.pop()
      ?.toLowerCase();

    if (!acceptedExtensions?.includes(currentExtension)) {
      setPostingVideo(false);
      toast.error('Formato de arquivo inválido.', {
        autoClose: 2000,
        className: 'toast-error',
        theme: 'colored',
      });
      return;
    }

    const response: any = await bucket.post(`/upload`, formData);
    setPostingVideo(false);
    handleOnChangeDTO('video', response.data.arquivo);
    console.log(response);
  }

  async function postImage(index: string, file: any) {
    setPostingVideo(true);
    let formData = new FormData();
    formData.append('file', file.target.files[0]);

    const acceptedFiles = ['jpg', 'jpeg', 'png', 'bmp', 'tiff'];
    const currentExtension = file?.target?.files[0]?.name
      ?.split('.')
      ?.pop()
      ?.toLowerCase();

    if (!acceptedFiles?.includes(currentExtension)) {
      setPostingVideo(false);
      toast?.error('Tipo de arquivo inválido.', {
        autoClose: 2000,
        className: 'toast-error',
        theme: 'colored',
      });
      return;
    }

    const response: any = await bucket.post(`/upload`, formData);
    setPostingVideo(false);
    handleOnChangeDTO(index, response.data.arquivo);
    console.log(response);
  }

  function validateVideo() {
    try {
      if (data.tipo === 'video' && !data.video)
        throw new Error('É necessário adicionar um vídeo à sua campanha');
      if (data.tipo !== 'video') {
        if (
          !data.banner_1024x90 ||
          !data.banner_322x227 ||
          !data.banner_322x673 ||
          !data.banner_70x70
        ) {
          throw new Error(
            'É necessário adicionar todos os tipos de banner antes de prosseguir'
          );
        }
      }
      return true;
    } catch (err: any) {
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
      return false;
    }
  }

  const dataExample: any = {
    postagem: {
      titulo: 'exemplo banner',
      empresaId: 'empresaBanner',
      subtitulo: 'exemplo banner',
      comentarioEmpresa: 'exemplo banner',
      texto: 'exemplo banner',
    },
  };

  return (
    <Container>
      {postingVideo && (
        <LoadingWrapper>
          <h2>Enviando...</h2>
          {/* <div className="lottieWrapper">
            <LottieAnimation lootiePath="https://lottie.host/4e9dde9b-a523-489f-87e1-3b252a65e005/7Um9BaOiMl.json" />
          </div> */}
          <div className="center">
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
          </div>
        </LoadingWrapper>
      )}

      {data?.tipo === 'banner' && (
        <>
        <div className="inputArea">
            <h2 className="labelInput">Thumbnail da campanha</h2>
            <InputFile
              file={data?.video}
              placeholder="Selecionar Arquivo"
              tipo="file"
              change={(e: any) => postImage('thumbnail', e)}
            />
          </div>
          <div className="inputArea">
            <h2 className="labelInput">Foto 70x70</h2>
            <InputFile
              file={data?.banner_70x70}
              placeholder="Selecionar Arquivo"
              tipo="file"
              change={(e: any) => postImage('banner_70x70', e)}
            />
          </div>
          <div className="inputArea">
            <h2 className="labelInput">Foto 322x227</h2>
            <InputFile
              file={data?.banner_322x227}
              placeholder="Selecionar Arquivo"
              tipo="file"
              change={(e: any) => postImage('banner_322x227', e)}
            />
          </div>
          <div className="inputArea">
            <h2 className="labelInput">Foto 322x673</h2>
            <InputFile
              file={data?.banner_322x673}
              placeholder="Selecionar Arquivo"
              tipo="file"
              change={(e: any) => postImage('banner_322x673', e)}
            />
          </div>
          <div className="inputArea">
            <h2 className="labelInput">Foto 1024x90</h2>
            <InputFile
              file={data?.banner_1024x90}
              placeholder="Selecionar Arquivo"
              tipo="file"
              change={(e: any) => postImage('banner_1024x90', e)}
            />
          </div>
          <div className="exampleBannerArea">
            <h2 className="textExampleBannerArea">Exemplo de aplicação</h2>
            <div className="mainContainerExampleBannerArea">
              <div
                className="imageBanenrExampleBannerArea"
                style={{ backgroundImage: `url(${bannerExample})` }}
              ></div>
              {/* <div className="topMainContainerExample">
                                {
                                    [1,2,3,4,5,6,7,8,9].map(()=>(
                                        <div className="cardExampleConsultor"></div>
                                    ))
                                }
                            </div>
                            <div className="contentMainContainerExample">
                                <div className="leftContentContainerExample">
                                    <CardCaseConfig
                                    data={dataExample}
                                    deleteCaseDeSucesso={()=>{}}
                                    />
                                    <CardCaseConfig
                                    data={dataExample}
                                    deleteCaseDeSucesso={()=>{}}
                                    />
                                    <CardCaseConfig
                                    data={dataExample}
                                    deleteCaseDeSucesso={()=>{}}
                                    />                                      
                                </div>
                                <div className="rightContentContainerExample">
                                    <div className="banner322x227px" style={{backgroundImage: `url(${data?.banner_322x227})`}}></div>    
                                    <div className="banner322x673px" style={{backgroundImage: `url(${data?.banner_322x673})`}}></div>    
                                </div>    
                            </div>                             */}
            </div>
          </div>
        </>
      )}

      {data?.tipo === 'video' && (
        <>
          <div className="inputArea">
            <h2 className="labelInput">Thumbnail da campanha</h2>
            <InputFile
              file={data?.video}
              placeholder="Selecionar Arquivo"
              tipo="file"
              change={(e: any) => postImage('thumbnail', e)}
            />
          </div>
          <div className="inputArea">
            <h2 className="labelInput">
              Vídeo da campanha<span className="videoTime"> (30 segundos)</span>
            </h2>
            <InputFile
              file={data?.video}
              placeholder="Selecionar Arquivo"
              tipo="file"
              change={(e: any) => postVideo(e)}
            />
          </div>
          <div className="exampleBannerArea">
            <h2 className="textExampleBannerArea">Exemplo de aplicação</h2>
            <div className="mainContainerExampleBannerArea">
              <div
                className="imageBanenrExampleBannerArea"
                style={{ backgroundImage: `url(${videoExample})` }}
              ></div>
            </div>
          </div>
        </>
      )}

      <div className="areaButtons">
        <ButttonDefault
          title={'Voltar'}
          tipo={'11'}
          onClick={() => {
            setPage('Informações');
          }}
        />
        <ButttonDefault
          title={'Enviar Campanha'}
          tipo={'6'}
          icon={sendingCampaing ? 'Loading-White' : ''}
          onClick={async () => {
            if (validateVideo()) {
              setSendingCampaing(true);
              sendCampaing().then(() => {
                setSendingCampaing(false);
                history.push('/campanha');
              });
            }
          }}
        />
      </div>
    </Container>
  );
}
