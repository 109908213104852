import { AxiosError, AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import UserContext from '../../../contexts/UserContext';
import apiDoubts from '../../../services/api';
import ButttonDefault from '../../ButtonDefault';
import ButtonSeguir from '../../ButtonSeguir';
import { Container } from './styles';
import ChartIcon from '../../../assets/icons/ChartIcon';
import ModalAlert from '../../ModalAlert';

export interface ICardCampanha {
  data: any;
  getNewCampaingArray: () => void;
}
 
export default function CardCampanha({
  data,
  getNewCampaingArray,
}: ICardCampanha) {
  const [botao, setBotao] = useState<any>();
  const [campanhaIsAccepted, setCampanhaIsAccepted] = useState<boolean>(false);
  const { user } = useContext(UserContext);
  const history = useHistory();

  const [deletingCampaing, setDeletingCampaing] = useState<boolean>(false);
  const [changingCampaingStatus, setChangingCampaignStatus] =
    useState<boolean>(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (data.status) handleStatusBotao();
  }, [data]);

  function handleStatusBotao() {
    if (data?.status === 'Pendente_Aprovacao') {
      const botaoConfig = {
        tipo: '5',
        text: 'Aguardando Aprovação',
      };
      setBotao(botaoConfig);
    } else if (data?.status === 'Ativa') {
      const botaoConfig = {
        tipo: '4',
        text: 'Ativa',
      };
      setBotao(botaoConfig);
    } else if (data?.status === 'Pausada') {
      const botaoConfig = {
        tipo: '3',
        text: 'Pausada',
      };
      setBotao(botaoConfig);
    }
  }

  async function statusCampanha(response: boolean) {
    setChangingCampaignStatus(true);
    await apiDoubts
      .put(`/campanha-aprovar/${data?._id}`, {
        aceitar: response,
      })
      .then((response: AxiosResponse) => {
        setCampanhaIsAccepted(!campanhaIsAccepted);
        getNewCampaingArray();
        setChangingCampaignStatus(false);

        toast.success('Campanha Aprovada com sucesso!', {
          className: 'toast-sucess',
          theme: 'colored',
        });
      })
      .catch((error: AxiosError) => {
        toast.error(error.message, {
          className: 'toast-error',
          theme: 'colored',
        });
        setChangingCampaignStatus(false);
      });
  }

  const handleCampaignStatus = () => setIsModalOpen(true);

  async function handlePlayPauseCampaign() {
    try {
      if (data?.status === 'Pendente_Aprovacao') {
        handleCampaignStatus();
        return;
      }

      setChangingCampaignStatus(true);
      if (data?.status === 'Ativa') {
        await apiDoubts.put(`/campanhas-playpause/${data._id}`, {
          // pausa a campanha
          status: 'Pausada',
        });
      } else {
        await apiDoubts.put(`/campanhas-playpause/${data._id}`, {
          // ativa a campanha
          status: 'Ativa',
        });
      }
      setChangingCampaignStatus(false);
      getNewCampaingArray();
    } catch (err: any) {
      toast.error(err?.message, {
        className: 'toast-error',
        theme: 'colored',
      });
      setChangingCampaignStatus(false);
    }
  }

  async function deleteCampaing() {
    try {
      setDeletingCampaing(true);
      const response = await apiDoubts.delete(`campanha/${data?._id}`);
      toast.success('Campanha deletada com sucesso', {
        className: 'toast-sucess',
        theme: 'colored',
      });
      getNewCampaingArray();
      setDeletingCampaing(false);
    } catch (err: any) {
      setDeletingCampaing(false);
      toast.error(err?.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  return (
    <>
      <ModalAlert
        onClose={() => setIsModalOpen(false)}
        openModal={isModalOpen}
        id=""
        prosseguir={() => statusCampanha(true)}
        style={{
          background: 'rgba(0,0,0,0.03)',
          backdropFilter: 'blur(1px)',
        }}
      >
        <p>Gostaria de aprovar a campanha: {data?.nome}?</p>
      </ModalAlert>

      <Container>
        <div className="leftAreaCardCampanha">
          <div className="titleAndStatus">
            <span className="nomeCampanha">{data?.nome}</span>
            <ButtonSeguir
              title={botao?.text}
              click={() => {}}
              tipo={botao?.tipo}
            />
          </div>
          {/* <p className="campanhaDescricao">{data?.descricao}</p> */}
        </div>
        <div className="buttonsCampanha">
          <ButttonDefault
            title={(<ChartIcon color="#FFFFFF" size={18} />) as any}
            onClick={() => history?.push(`/campanha/dashboard/${data?.id}`)}
          />

          <ButttonDefault
            title={'Editar Campanha'}
            onClick={() => {
              history.push(`/campanha/editar/${data?.id}`);
            }}
          />
          {/* {data?.empresaId === user._id && data?.agencia && data?.status === 'Pendente_Aprovacao' && (
          <>
            <ButttonDefault
              title={'aceitar'}
              onClick={() => {
                statusCampanha('sim', data?._id);
              }}
              tipo="3"
            />
            <ButttonDefault
              title={'recusar'}
              onClick={() => {
                statusCampanha('nao', data?._id);
              }}
              tipo="8"
            />
          </>
        )} */}
          {user.tipo === 'empresa' && (
            <>
              <ButttonDefault
                title={''}
                tipo={data.status === 'Ativa' ? '8' : '10'}
                icon={
                  changingCampaingStatus
                    ? 'Loading-White'
                    : data.status === 'Ativa'
                    ? 'Pause'
                    : 'Play'
                }
                onClick={() => {
                  handlePlayPauseCampaign();
                }}
              />
              <ButttonDefault
                title={''}
                tipo={'8'}
                icon={deletingCampaing ? 'Loading-White' : 'Lixo'}
                onClick={() => {
                  deleteCampaing();
                }}
              />
            </>
          )}
        </div>
      </Container>
    </>
  );
}
