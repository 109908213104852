import { useEffect, useState } from 'react';
import { IconCheck, IconTrash } from '../../Icons';
import { Container } from './styles';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { ConsultData } from '../../Data/ConsultData';
import ModalAlert from '../../ModalAlert';

interface dataProps {
  agenciaData: any;
  checked: boolean;
  setChecked: (id: string) => void;
  deleteAgencia: (account: any) => void;
}

export default function CardAgenciaConfig({
  agenciaData,
  checked,
  setChecked,
  deleteAgencia,
}: dataProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <ModalAlert
        prosseguir={() => deleteAgencia(agenciaData)}
        openModal={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        id=""
      >
        Tem certeza que desejar encerrar vínculos com o(a){' '}
        {agenciaData?.nickName}?
      </ModalAlert>

      <Container>
        {/* <input
            type="checkbox"
            className="inputCheckboxConsultorEmpresa" 
            onClick={()=>{
                setChecked(consultorData?.id)
            }}
            checked={checked}
            /> */}

        {checked && (
          <div
            className="iconChecked"
            onClick={() => {
              setChecked(agenciaData?.id);
            }}
          >
            <IconCheck color={'#fff'} />
          </div>
        )}

        <div className="contentCardProfileConsultor">
          <div className="bussinessInfo">
            <div
              className="imageProfileConsultor"
              style={{
                backgroundImage: `url(${
                  agenciaData?.foto ??
                  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
                })`,
              }}
            ></div>

            <div className="profileInfo">
              <p className="nickName">{agenciaData?.nickName}</p>
              <p className="createdDate">
                {moment(agenciaData?.createdAt)?.locale('pt-br')?.fromNow()}
              </p>
            </div>
          </div>

          <div
            className="removeVinculo"
            onClick={() => setIsModalOpen(true)}
            title="Remover Vínculo com Agência"
          >
            <IconTrash color={'var(--offline)'} size={18} />
          </div>
        </div>
      </Container>
    </>
  );
}
