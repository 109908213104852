import styled from "styled-components";

interface props{
    foto: any
}

export const Container = styled.div<props>`
  .modal {
    .itemForm {
      margin-bottom: 10px;
      .label {
        font-size: 13px;
        line-height: 15px;
        font-weight: 700;
        color: var(--paleta2C);
        margin-bottom: 6px;
        .tamanhoDaImagem {
          font-size: 11px;
          font-weight: 600;
          color: var(--texto);
        }
      }
      .comment {
        width: 100%;
        height: 120px;
        background-color: var(--background2);
        border: none;
        border-radius: 4px;
        padding: 10px;
        outline: none;
        resize: none;
      }
      .imageProdutoServico {
        width: 240px;
        height: 120px;
        background-color: var(--background2);
        border-radius: 5px;
        position: relative;
        background-position: center;
        background-size: cover;
        .addImage,
        .removeImage {
          position: absolute;
          right: 0;
          bottom: 0;
          margin-right: -5px;
          margin-bottom: -5px;
          width: 40px;
          height: 40px;
          background-color: var(--warning);
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          border: none;
          outline: none;
          transition: all 0.2s;

          &:hover {
            transform: scale(1.1);
            transition: all 0.2s;
            background-color: var(--offline);
            svg {
              path {
                stroke: #fff;
              }
            }
          }

          @media (max-width: 1250px) {
            margin-right: -5px;
            margin-bottom: -5px;
          }
        }
        .removeImage {
          display: ${({ foto }) =>
            foto ? 'flex' : 'none'};
        }

        .addImage {
          display: ${({ foto }) =>
            foto ? 'none' : 'flex'};
        }
      }
    }
    .areaButtons{
        display: flex;
        gap: 5px;
        justify-content: flex-end;
    }
  }
`;

export const HeaderProdutosServiços = styled.div`
display: flex;
width: 100%;
justify-content: space-between;
.titleProdutosServiços{
    font-weight: 700;
    font-size: 28px;
    color: var(--paleta2C);
}
`;

export const BodyProdutosServiços = styled.div`
display: flex;
flex-direction: column;
gap: 20px
`