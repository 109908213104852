import { useContext, useEffect, useState } from 'react';
import apiDoubts from '../../../services/api';
import { Container } from './styles';
import GIF from '../../../assets/not-found.gif';
import { toast } from 'react-toastify';
import CardPostFavoritos from '../../CardPostFavoritos';
import UserContext from '../../../contexts/UserContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IconLoading } from '../../Icons';

interface IProps {
  playlist?: any;
  posicao?: string;
  where?: {
    interesse?: string;
    search?: string;
    hash?: string;
  };
  order?: string;
  consultorId?: string;
  tipo?: string;
}

export default function ListPostsFavoritos({
  playlist,
  posicao,
  where = {
    hash: '',
    search: '',
    interesse: '',
  },
  order = 'popular',
  consultorId,
  tipo,
}: IProps) {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [completeData, setCompleteData] = useState<any>();
  const { user } = useContext(UserContext);

  useEffect(() => {
    getData(completeData?.docs);
  }, [playlist, page]);

  useEffect(() => {
    if (!consultorId && tipo !== 'playlist') {
      getData([]);
    }
  }, [where, order]);

  async function getData(dados?: any) {
    console.log('pegou os videos do padrões', dados);
    setLoading(true);
    try {
      if (!page) {
        setLoading(false);
        return;
      }

      if (page === 1 || !dados[0]) {
        console.log('zerou data');
        setData([]);
        setCompleteData([]);
      }

      await apiDoubts
        .get(`/postagens-favoritas?pagination=true&limit=30&page=${page}`)
        .then((response: any) => {
          console.log(response.data.data);
          setCompleteData(response.data.data);

          //quando eu quero que apareça apenas os vídeos que foram puxados agora
          setData([...response.data.data.docs]);

          // setData( [...data, ...newData]);
          setLoading(false);
        })
        .catch((error: any) => {});
    } catch (error: any) {
      setLoading(false);
      console.log('error', error);
      toast.error(error, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  async function updateFavoritos(id: string) {
    apiDoubts.delete(`/favoritos/${id}`).then((response: any) => {
      console.log('data ==>', data);
      const newData = data.filter((val: any) => {
        console.log(val.postagemId, id);
        return val.postagemId != id;
      });
      console.log('new data ==>', newData);
      setData(newData);
    });
  }

  return (
    <Container
      loading={loading}
      lenght={data.length}
      posicao={posicao}
      playlist={playlist}
    >
      <InfiniteScroll
        dataLength={data?.length} //This is important field to render the next data
        next={() => {
          setPage(completeData?.nextPage);
        }}
        hasMore={true}
        loader={<h4></h4>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            ''
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        {data.length > 0 &&
          data.map((row: any, key) => (
            <CardPostFavoritos
              remove={(id: string) => {
                updateFavoritos(id);
              }}
              playlist={playlist}
              key={key}
              item={row.postagens ? row.postagens : row}
              type={row.type ? 'anuncio' : tipo}
              posicao={posicao}
            />
          ))}

        {loading === false && data.length === 0 && user && (
          <div className="empty-posts">
            <img src={GIF} />
            <p>Nenhum registro encontrado.</p>
          </div>
        )}

        {loading === false && data.length === 0 && !user && (
          <div className="empty-posts">
            <img src={GIF} />
            <p>faça login para poder adicionar vídeos aos seus favoritos !!!</p>
          </div>
        )}

        {loading && (
          <div className="loadWrapper">
            <IconLoading />
          </div>
        )}
      </InfiniteScroll>
    </Container>
  );
}
