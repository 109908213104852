import moment from 'moment';
import { Container } from './styles';

interface props {
  data: any;
  deleteHour?: (a: any) => void;
  deleting?: boolean;
  type: 'consultor' | 'user';
  select: (hour: any) => void;
  selected?: boolean;
  temporary?: boolean;
  busyHour?:boolean
}

export default function CardConsultoriaPaga({
  data,
  select, 
  selected,
  busyHour
}: props) {
  function handleSelect(data: any) {
    if (select) {
      select(data);
    }
  }

  return (
    <>
      <Container
        onClick={() => {
          handleSelect(data?.data);
        }}
        selected={selected as boolean}
        busyHour={busyHour}
      >
        <p>
          {moment(data.data).format('dddd[, ]DD [de] MMMM [de] yyyy - HH:mm')} às{' '}
          {moment(data.data).add(10, 'minutes').format('HH:mm')} - {data?.status === 'ocupado' ? 'ocupado' : 'disponível'}
        </p>
        <input type="checkbox" checked={selected} onClick={()=>{select(data.data)}} />
      </Container>
    </>
  );
}
