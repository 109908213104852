import { useEffect, useRef, useState } from 'react';
import apiDoubts from '../../services/api';
import { IconArrowLeft, IconArrowRight } from '../Icons';
import { Container } from './styles';

interface IProps {
  selected: string;
  setSelected(id: string): void;
}

export default function FiltrosInteresses({ selected, setSelected }: IProps) {
  const RefSlider = useRef<any>();
  const RefSliderMove = useRef<any>();
  const WidthMove = 200;

  const [interesses, setInteresses] = useState<any>([
    {
      titulo: 'Todos',
      id: '',
    },
    {
      titulo: 'Ao vivo',
      id: 'aovivo',
    },
  ]);
  const [interesseSelecionado, setInteresseSelecionado] =
    useState<string>(selected);
  const [left, setLeft] = useState<any>(0);

  useEffect(() => {
    setInteresseSelecionado(selected);
  }, [selected]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const limit =
      RefSliderMove?.current?.clientWidth - RefSlider?.current?.clientWidth;

    if (left > 0) setLeft(0);

    if (left * -1 >= limit) setLeft(limit * -1);
  }, [left]);

  async function getData() {
    const response = await apiDoubts.get('interesses');
    response?.data?.data?.map((row: any) => {
      interesses.push(row);
    });
    setInteresses([...interesses]);
  }

  function handleOnClick(id: string) {
    setInteresseSelecionado(id);
    setSelected(id);
  }

  function handlePrevScroll() {
    try {
      if (left >= 0) throw 'Você já está na posição 0.';
      setLeft(left + WidthMove);
    } catch (err: any) {}
  }

  function handleNextScroll() {
    try {
      const limit =
        RefSliderMove?.current?.clientWidth - RefSlider?.current?.clientWidth;
      if (left * -1 >= limit) throw 'Você já está no final do slider.';
      setLeft(left - WidthMove);
    } catch (err: any) {}
  }

  console.log(selected)

  return (
    <Container>
      <div className="list" ref={RefSlider}>
        <div
          id="teste"
          ref={RefSliderMove}
          className="move-slider"
          style={{ transform: `translateX(${left}px)` }}
        >
          {interesses.map((row: any, key: number) => (
            <button
              className={
                interesseSelecionado === row?.id ? 'active' : 'inactive'
              }
              onClick={() => handleOnClick(row?.id)}
              key={key}
            >
              {row?.titulo}
            </button>
          ))}
        </div>
      </div>
      <div className="controls">
        <button onClick={handlePrevScroll}>
          <IconArrowLeft />
        </button>
        <button onClick={handleNextScroll}>
          <IconArrowRight />
        </button>
      </div>
    </Container>
  );
}
