import styled from "styled-components";

export const Container = styled.div`
display: flex;
flex-direction: column;
gap: 10px;
margin-top: 20px;
.areaButtons{
    display: flex;
    justify-content: flex-end;
}
`