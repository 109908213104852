import axios from 'axios';

const token = `eyJhbGciOiJIUzI1NiJ9.Z3VpbGhlcm1lQHRyb3BhLmRpZ2l0YWx8fDIwMjMtMDMtMDFUMTU6Mjg6MTEuNDExWg.tcU6FFTeKR3re2Do-oIIAncgMJm4B1j5T6muHrs5-30`


const bucket = axios.create({
  baseURL: `https://bucket.backendtropa.com.br`,
  headers: {
    Authorization: token,
    Destination: 'Doubts'
  },
});

export default bucket;

