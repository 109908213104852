import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Banner from '../../../components/Banner';
import ButttonDefault from '../../../components/ButtonDefault';
import AddimageCirculo from '../../../components/ButtonFile/AddImagemCirculo';
import SelectDefaultSearch from '../../../components/Form/SelectDefaultSearch';
import {
  IconFacebook,
  IconInstagram,
  IconTikTok,
  IconTrash,
  IconTwitter,
  IconWWW,
} from '../../../components/Icons';
import UserContext from '../../../contexts/UserContext';
import apiDoubts from '../../../services/api';
import Checkbox from '../../Checkbox/Checkbox';
import InputLink from '../../Inputs/InputLink';
import { Container, Left} from './styles';
import bucket from '../../../services/bucket';

export default function EmpresaConfigDestaques() {
  const { user } = useContext(UserContext);

  const [postagemPrincipal, setPostagemPrincipal] = useState<any>();
  const [optionsSelect, setOptionsSelect] = useState<any[]>([]);
  const [DTO, setDTO] = useState<any>({});
  const [banner, setBanner] = useState<any>();

  const [savingInfos, setSavingInfos] = useState<boolean>(false);

  useEffect(() => {
    getPostagem(DTO.postagemPrincipal);
  }, [DTO?.postagemPrincipal]);

  function handleOnChange(name: any, value: any) {
    const newDTO: any = DTO;
    newDTO[name] = value;
    setDTO({ ...newDTO });
  }

  useEffect(() => {
    if (user) {
      getEmpresa();
      getPostagensSelect();
    }
  }, [user]);

  useEffect(() => {
    console.log(DTO);
  }, [DTO]);

  useEffect(() => {
    getBanner();
  }, []);

  async function getEmpresa() {
    apiDoubts.get(`/empresa/get-by-id/${user.id}`).then((response: any) => {
      handleConsultor(response?.data?.data);
      getPostagem(response?.data?.data[0]?.postagemPrincipal);
    });
  }

  function handleConsultor(data: any) {
    let newDTO = {
      foto: data?.foto,
      termosUso: true,
      disponivelWhatsApp: data?.disponivelWhatsApp,
      disponivelEmail: data?.disponivelEmail,
      disponivelConferencia: data?.disponivelConferencia,
      valor10: data?.valor10,
      valor20: data?.valor20,
      valor30: data?.valor30,
      descricaoCanal: data?.descricaoCanal,
      link_site: data?.link_site,
      link_twitter: data?.link_twitter,
      link_facebook: data?.link_facebook,
      link_instagram: data?.link_instagram,
      link_tiktok: data.link_tiktok,
      capa: data?.capa
        ? data?.capa
        : 'https://images.unsplash.com/photo-1542273917363-3b1817f69a2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=874&q=80',
    };
    setDTO(newDTO);
  }

  async function getPostagem(id: string) {
    apiDoubts
      .get(`/postagens/getPostagem-logado/${id}`)
      .then((response: any) => {
        setPostagemPrincipal(response.data.data);
      });
  }

  async function getPostagensSelect() {
    apiDoubts
      .get(`/postagens/getPostagemConsultor/${user.id}`)
      .then((response: any) => {
        handleOptions(response.data.data);
      });
  }

  function handleOptions(data: any) {
    let newData: any[] = [];
    data.map((row: any) => {
      let option = {
        value: row.id,
        label: row.titulo,
      };
      newData.push(option);
    });
    setOptionsSelect(newData);
  }

  function valueSelect() {
    let newOption = optionsSelect.filter((value: any) => {
      return value.value === DTO?.postagemPrincipal;
    });
  }

  async function setCapa(file: any) {
    let formData = new FormData();
    formData.append('file', file.target.files[0]);
    const response: any = await bucket.post(`/upload`, formData);
    handleOnChange('capa', response?.data?.arquivo);
  }

  async function setAvatar(file: any) {
    let formData = new FormData();
    formData.append('file', file.target.files[0]);
    const response: any = await bucket.post(`/upload`, formData);
    handleOnChange('foto', response.data.arquivo);
  }

  async function checkInfos() {
    setSavingInfos(true);
    try {
      if (!DTO.capa) throw new Error('Escolha uma capa para o seu canal');
      if (!DTO.foto) throw new Error('Escolha foto de perfil para o seu canal');
      if (!DTO.descricaoCanal.length)
        throw new Error('Digite uma descrição para o seu canal');
      await handleUpdateEmpresa();
      setSavingInfos(false);
    } catch (err: any) {
      setSavingInfos(false);
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  async function handleUpdateEmpresa() {
    await apiDoubts
      .put(`/empresa/editar/${user.id}`, DTO)
      .then((response: any) => {
        console.log(response);
        setTimeout(() => {
          getEmpresa();
        }, 1000);
        toast.success('Perfil atualizado com sucesso', {
          className: 'toast-sucess',
          theme: 'colored',
        });
      })
      .catch((error: any) => {
        toast.error(error.message, {
          className: 'toast-error',
          theme: 'colored',
        });
      });
  }

  async function getBanner() {
    apiDoubts.get(`/banners?tipo=3&limit=1`).then((response: any) => {
      setBanner(response.data.data);
    });
  }

  function handleValue(pos: string, value: string) {
    let newValue = value.replace('R$ ', '');
    handleOnChange(pos, newValue);
  }

  return (
    <Container>
      <div className="content">
        <Left buttonDeleteOn={!!DTO.foto} buttonDeleteOnCapa={!!DTO.capa}>
          {optionsSelect?.length > 0 && (
            <div className="videoApresentacao">
              <div className="areaLeft">
                <h3 className="videoApresentacaoText">Video de apresentação</h3>
                <div className="responsiveBanner">
                  <div
                    className="thumb"
                    style={{
                      backgroundImage: `url(${postagemPrincipal?.thumbnail})`,
                    }}
                  ></div>
                  <div className="bannerResp">
                    {banner && (
                      <Banner peso="1" position="1" typeBanner="322x227" />
                    )}
                  </div>
                </div>
              </div>

              <div className="areaRight">
                <h3 className="videoTitle">{postagemPrincipal?.titulo}</h3>
                <h4 className="visualizacoes">
                  {postagemPrincipal?.qtdVisualizacoes} visualizações -{' '}
                  {moment(postagemPrincipal?.data).locale('pt-br').fromNow()}
                </h4>
                <SelectDefaultSearch
                  value={valueSelect}
                  onChange={(e: any) => {
                    handleOnChange('postagemPrincipal', e.value);
                  }}
                  options={optionsSelect}
                  key={'select videos'}
                  tipo={'2'}
                />
              </div>
            </div>
          )}

          <div className="images">
            <h3 className="title">Avatar e capa do canal</h3>
            <div className="content">
              <div
                className="capa"
                style={{ backgroundImage: `url(${DTO.capa})` }}
              >
                <div className="addAvatar">
                  {DTO.capa ? (
                    <button
                      className="removeImage"
                      onClick={() => {
                        handleOnChange('capa', '');
                      }}
                    >
                      <IconTrash />
                    </button>
                  ) : (
                    <button className="addImage">
                      <AddimageCirculo
                        change={(e: any) => {
                          setCapa(e);
                        }}
                        file={'Foto de capa'}
                        tipo={'file'}
                        placeholder={'foto de perfil'}
                      />
                    </button>
                  )}
                </div>
              </div>
              <div
                className="avatar"
                style={{ backgroundImage: `url(${DTO.foto})` }}
              >
                <div className="addAvatar">
                  {DTO.foto ? (
                    <button
                      className="removeImage"
                      onClick={() => {
                        handleOnChange('foto', '');
                      }}
                    >
                      <IconTrash />
                    </button>
                  ) : (
                    <button className="addImage">
                      <AddimageCirculo
                        change={(e: any) => {
                          setAvatar(e);
                        }}
                        file={'Foto de capa'}
                        tipo={'file'}
                        placeholder={'foto de perfil'}
                      />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="descricaoCanal">
            <h3 className="descricaoText">Descrição</h3>
            <textarea
              value={DTO.descricaoCanal}
              className="comment"
              onChange={(e) => {
                handleOnChange('descricaoCanal', e.target.value);
              }}
            ></textarea>
          </div>

          {/* <div className="disponibilidade">
            <h3 className="disponibilidadeText">Disponibilidade</h3>
            <div className="areaDisponibilidade">
              <div className="cardDisponibilidade">
                <Checkbox
                  name="disponivelConferencia"
                  value={DTO.disponivelConferencia}
                  onClick={(e: any) => {
                    handleOnChange(
                      'disponivelConferencia',
                      !DTO.disponivelConferencia
                    );
                  }}
                />
                <h4 className="textCardDisponibilidade">Conferência</h4>
              </div>
              <div className="cardDisponibilidade">
                <Checkbox
                  name="disponivelWhatsApp"
                  value={DTO.disponivelWhatsApp}
                  onClick={(e: any) => {
                    handleOnChange(
                      'disponivelWhatsApp',
                      !DTO.disponivelWhatsApp
                    );
                  }}
                />
                <h4 className="textCardDisponibilidade">WhatsApp</h4>
              </div>
              <div className="cardDisponibilidade">
                <Checkbox
                  name="disponivelEmail"
                  value={DTO.disponivelEmail}
                  onClick={(e: any) => {
                    handleOnChange('disponivelEmail', !DTO.disponivelEmail);
                  }}
                />
                <h4 className="textCardDisponibilidade">E-mail</h4>
              </div>
            </div>
          </div> */}

          {/* <div className="valores">
            <div className="headerValores">
              <h3 className="textValores">Valores</h3>
              <h3 className="vejaMais">
                veja mais <IconArrowRight />
              </h3>
            </div>
            <div className="areaValores">
              <div className="cardValor">
                <h3 className="left">10 Minutos</h3>
                <input
                  className="sideRight"
                  type="string"
                  value={`R$ ${DTO?.valor10 ? DTO.valor10 : ''}`}
                  onChange={(e: any) => {
                    handleValue('valor10', e.target.value);
                  }}
                />
              </div>
              <div className="cardValor">
                <h3 className="left">20 Minutos</h3>
                <input
                  className="sideRight"
                  type="string"
                  value={`R$ ${DTO?.valor20 ? DTO.valor20 : ''}`}
                  onChange={(e: any) => {
                    handleValue('valor20', e.target.value);
                  }}
                />
              </div>
              <div className="cardValor">
                <h3 className="left">30 Minutos</h3>
                <input
                  className="sideRight"
                  type="string"
                  value={`R$ ${DTO?.valor30 ? DTO.valor30 : ''}`}
                  onChange={(e: any) => {
                    handleValue('valor30', e.target.value);
                  }}
                />
              </div>
            </div>
            </div> */}

          <div className="inputLinks">
            <div className="headerLinks">
              <h3 className="textLinks">Links de referência</h3>
            </div>
            <div className="bodyLinks">
              <div className="inputItem">
                <h3 className="labelLink">Site</h3>
                <InputLink
                  icon={<IconWWW size={30} />}
                  link={DTO?.link_site}
                  value={DTO?.link_site}
                  changeText={(text: string) => {
                    handleOnChange('link_site', text);
                  }}
                />
              </div>
              <div className="inputItem">
                <h3 className="labelLink">Instagram</h3>
                <InputLink
                  icon={<IconInstagram size={25} />}
                  link={DTO?.link_instagram}
                  value={DTO?.link_instagram}
                  changeText={(text: string) => {
                    handleOnChange('link_instagram', text);
                  }}
                />
              </div>
              <div className="inputItem">
                <h3 className="labelLink">Facebook</h3>
                <InputLink
                  icon={<IconFacebook size={30} />}
                  link={DTO?.link_facebook}
                  value={DTO?.link_facebook}
                  changeText={(text: string) => {
                    handleOnChange('link_facebook', text);
                  }}
                />
              </div>
              <div className="inputItem">
                <h3 className="labelLink">Twitter</h3>
                <InputLink
                  icon={<IconTwitter size={30} />}
                  link={DTO?.link_twitter}
                  value={DTO?.link_twitter}
                  changeText={(text: string) => {
                    handleOnChange('link_twitter', text);
                  }}
                />
              </div>
              <div className="inputItem">
                <h3 className="labelLink">Tik Tok</h3>
                <InputLink
                  icon={<IconTikTok size={30} />}
                  link={DTO?.link_tiktok}
                  value={DTO?.link_tiktok}
                  changeText={(text: string) => {
                    handleOnChange('link_tiktok', text);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="buttons">
            <button className="cancelar" onClick={getEmpresa}>
              Cancelar
            </button>
            <ButttonDefault
              title={'Salvar'}
              tipo="6"
              icon={savingInfos ? 'Loading-White' : ''}
              onClick={() => {
                checkInfos();
              }}
            />
          </div>
        </Left>

        {/* <Right>
            {banner && (
              <>
                <Banner type="1" banner={banner[0]} />
                <Banner type="2" banner={banner[0]} />
              </>
            )}
          </Right> */}
      </div>
    </Container>
  );
}
