import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  .content {
    width: 100%;
    display: flex;
    gap: 30px;
    .areaLeft {
      width: 65%;
      height: 100%;
      .title {
        font-size: 28px;
        font-weight: 700;
        color: var(--paleta2C);
      }
      .date {
        margin-top: 10px;
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        color: var(--paleta1C);
        display: flex;
        align-items: center;
        gap: 5px;

        .selectedHour {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
        }

      }
      .cardConsultoria {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
    .areaRight {
      width: 35%;
      height: 100%;
      .react-calendar {
        background-color: var(--background2);
        min-width: 100%;
        max-width: 70%;
        border-radius: 10px;
        border: none;
        padding: 15px;
        padding-top: 0px;
        text-decoration: none;
        border-radius: 10px;

        .react-calendar__navigation__label__labelText {
          color: var(--paleta2A);
          font-size: 18px;
          line-height: 27px;
          font-weight: 600;
        }

        .react-calendar__navigation {
          display: flex;
          align-items: center;
          padding-left: 28px;
          padding-right: 28px;
          padding-top: 18px;
          height: 62px;

          .react-calendar__navigation__prev2-button,
          .react-calendar__navigation__next2-button {
            display: none;
          }

          .react-calendar__navigation__prev-button,
          .react-calendar__navigation__next-button {
            max-width: 38px;
            max-height: 38px;
            min-height: 38px;
            min-width: 38px;
            background-color: var(--branco);
            border-radius: 50%;

            font-size: 30px;
            line-height: 30px;
            color: var(--paleta2A);
          }
        }

        .react-calendar__month-view__weekdays__weekday {
          font-size: 13px;
          line-height: 15px;
          font-weight: 700;
          color: var(--paleta2A);
          text-decoration: none;
        }

        .react-calendar__tile {
          counter-reset: var(--paleta2C);
          border-radius: 10px;
        }

        .react-calendar__month-view__days__day--weekend {
          color: var(--paleta2C);
        }
        .react-calendar__month-view__days__day--neighboringMonth {
          font-size: 13px;
          line-height: 18px;
          font-weight: 400;
          color: var(--paleta1C);
        }
        .react-calendar__tile--now {
          background-color: transparent;
        }
        .react-calendar__tile--active {
          background: var(--paleta2A);
          font-weight: 700;
          color: white;
        }
      }
      .areaButton {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin-top: 10px;
        flex-direction: column;
        gap: 10px;
      }
    }

    @media (max-width: 1200px) {
      .areaRight {
        max-width: 450px;
      }
    }
    @media (max-width: 1000px) {
      display: flex;
      flex-direction: column-reverse;
      height: auto;
      gap: 0;
      .areaRight {
        width: 100%;
        margin: 0;
        padding: 0;
        margin-bottom: 30px;
      }
      .areaLeft {
        width: 100%;
        .date {
          font-size: 14px;
        }
      }
    }
  }
`;
