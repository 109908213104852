import styled from 'styled-components';

export const Container = styled.div`
  border-bottom: 1px solid #e7e7f3;
  padding-bottom: 22px;
  .head {
    margin-bottom: 9px;
    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 15px;
      color: var(--paleta2D);
      margin: 0px;
      padding: 0px;
    }
  }
  .list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .ads {
      width: 100%;
      display: flex;
      justify-content: space-between;
      &.loading {
        .title,
        .description {
          background: #eaeaea;
          color: transparent !important;
        }
      }
      .image {
        width: 70px;
        height: 70px;
        border-radius: 5px;
        background-position: center;
        background-size: cover;
        background-color: #eaeaea;
      }
      .infos {
        width: calc(100% - 79px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        .title {
          font-style: normal;
          font-weight: 700;
          font-size: 13px;
          line-height: 15px;
          color: var(--paleta1A);
          margin-top: 6px;
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .description {
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 15px;
          color: #7d7d7d;
          margin-top: 3px;
          width: 100%;
        }
        button {
          width: 92px;
          height: 20px;
          background: var(--paleta2E);
          color: #fff;
          text-transform: uppercase;
          font-style: normal;
          font-weight: 700;
          font-size: 10px;
          line-height: 15px;
          border: none;
          border-radius: 5px;
          transition: all 0.2s;
          margin-top: 11px;
        }
      }
    }
  }

  @media (max-width: 800px) {
    display: none;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    bottom: -20px;
  }
`;
