import styled from 'styled-components';

interface props {
  focus?: boolean;
  user?:any
  value?: boolean
}

export const Container = styled.div<props>`
  margin-bottom: -30px;
  h2 {
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
    color: var(--paleta2C);
    margin-bottom: 10px;
  }

  .areaComment {
    display: flex;
    gap: 10px;
    width: 100%;
    .image {
      min-width: 60px;
      min-height: 60px;
      max-width: 60px;
      max-height: 60px;
      border-radius: 50%;
      background-color: var(--background2);
      background-position: center;
      background-size: cover;
    }
    .areaLeft {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      position: relative;

      .load {
        cursor: text;
        display: ${({ focus }) => focus && 'none'}; 
        top: 0;
        left: 0;
        margin-top: ${({ user }) => (user._id ? '35px' : '20px')};
        margin-left: 10px;
        position: absolute;
        width: calc(100% - 10px);
        height: 40px;
        background-color: ${({ focus }) =>
          focus ? 'transparent' : 'var(--background2)'};
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        .fakePlaceholder {
          display: ${({ focus }) => focus && 'none'};
          font-size: 14px;
          font-weight: 400;
          line-height: 15px;
          color: #aaaaaa;
          /* width: calc(100% - 10px);
        height: 60px; */
        }
      }

      h3 {
        font-size: 13px;
        font-weight: 700;
        line-height: 15px;
        color: var(--paleta1A);
      }
      .comment {
        border: none;
        width: 100%;
        height: 70px;
        background-color: var(--background2);
        resize: none;
        border-radius: 5px;
        padding: 10px;
        outline-width: 1px;
        border: 1px solid transparent;
        outline-color: var(--paleta1A);
        z-index: ${({ value }) => value ? '99' : '1'};
      }
      .areaSubmit {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
`;
