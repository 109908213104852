import styled from "styled-components";

export const Container = styled.div`
display: flex;
gap: 20px;
`

export const AreaLeft = styled.div`
width: 240px;
display: flex;
flex-direction: column;
gap: 5px;
.image{
    width: 100%;
    height: 120px;
    background-position: center;
    background-size: cover;
    border-radius: 5px;
}
.areaButtons{
    display: flex;
    gap: 5px;
}

`;

export const AreaRigth = styled.div`
  width: calc(100% - 240px);
  display: flex;
  flex-direction: column;
  gap: 5px;
  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: var(--paleta1A);
  }
  span{
    display: flex;
    align-items: center;
    gap: 5px;
    .link {
          text-decoration: none;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          color: #039be5;
        }
    }
  .description {
    font-size: 13px;
    line-height: 18px;
    color: var(--texto);
  }
`;