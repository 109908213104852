import styled from "styled-components";

export const Toasted = styled.div`
  .toast-error {
    background: var(--purple2);
  }
  .toast-sucess {
    background: var(--green);
  }
  .progress {
    background: var(--mid-grey);
  }
` 