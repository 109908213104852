import styled from 'styled-components';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  gap: 30px;

  .cardWrapper {
    max-width: 400px;
  }

  .multiplesColumns {
    display: flex;
    gap: 20px;
    margin: 21.5px 0;
    width: 100%;

    @media (max-width: 1400px) {
      flex-wrap: wrap;
    }
  }
`;

export const SettingsCampaign = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ChartContainer = styled.div`
  width: 100%;
  height: 200px;
`;

export const CardDashboard = styled.div`
  min-height: 300px;
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);

  .legendContainer {
    font-size: 13px;
    display: flex;
    flex-direction: row-reverse;
    gap: 15px;
    justify-content: flex-end;
    margin: 0 0 12px 20px;

    .legend {
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: help;

      .line {
        height: 2px;
        width: 17px;

        &.purple {
          background-color: purple;
        }

        &.blue {
          background-color: blue;
        }

        &.green {
          background-color: green;
        }
      }
    }
  }

  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    margin-bottom: 30px;
  }

  .value {
    font-size: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }
`;

export const HashtagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 15px;
`;

export const Hashtag = styled.a`
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
  line-height: 14px;
  background-color: var(--paleta1C);

  padding: 0 10px;
  height: 26px;
  border-radius: 50px;
  color: var(--branco);
  display: flex;
  align-items: center;
  transition: all 0.2s;

  &:hover {
    transition: all 0.2s;
    transform: scale(1.03);
  }
`;

export const CardsContainer = styled.section`
  display: flex;
  justify-content: center;
  gap: 30px;
  width: 100%;
`;

export const InterestContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const RowStatistics = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 350px;
  margin: 5px 0;

  > p {
    color: var(--texto);
    font-size: 15px;
  }

  > span {
    font-weight: bolder;
    font-size: 14px;
  }
`;

export const Analytics = styled.div`
  grid-area: analytics;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  flex-direction: column;

  background: var(--white);
  /* border: 1px solid var(--mid-grey); */
  border-radius: 10px;
  box-shadow: 0px 0px 16px -4px rgba(0, 0, 0, 0.1);

  padding: 25px 22px;
  width: 100%;

  @media (max-width: 950px) {
    width: 100%;
    margin-top: 20px;
  }

  header {
    width: 100%;
    h2 {
      text-align: start;
      font-size: 20px;
      font-weight: 900;
      line-height: 23px;
      color: var(--purple2);
    }
  }
  main {
    width: 100%;
    .seguidores {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      width: 100%;
      height: 171px;
      border: none;
      border-radius: 4px;
      background: var(--background);

      margin-top: 20px;

      span {
        font-size: 16px;
        color: var(--purple2);
      }

      strong {
        font-size: 40px;
        font-weight: 900;
        color: var(--purple2);
        margin-top: 8px;
      }
      p {
        font-size: 12px;
        color: var(--dark-grey);
        margin-top: 8px;
        span {
          font-size: 12px;
          font-weight: bold;
          color: #0fba53;
          margin-top: 8px;
        }
      }
    }

    .resume {
      margin-bottom: -5px;
      h2 {
        margin-top: 20px;
        font-size: 16px;
        color: #0fbaba;
        span {
          font-weight: 700;
        }
      }
      table {
        width: 100%;
        margin-top: 20px;
      }
      tr {
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* margin-bottom: 10px; */
        font-size: 12px;
        line-height: 30px;
        color: #7d7d7d;
      }
      td {
      }
      td.value {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: end;
        font-size: 12px;
        font-weight: 700;
        color: var(--purple2);
      }
      td div.positive {
        color: #0fba53;
      }
      td div.negative {
        color: #df2f2f;
      }
    }
    .topVideos {
      margin-bottom: -10px;
      h2 {
        margin-top: 20px;
        font-size: 16px;
        color: #0fbaba;
        span {
          font-weight: 700;
        }
      }
      table {
        width: 100%;
        margin-top: 20px;
      }
      tr {
        display: flex;
        align-items: center;
        /* justify-content: space-between; */
        gap: 5px;
        flex-wrap: wrap;
        /* margin-bottom: 10px; */
        font-size: 12px;
        color: #7d7d7d;
        line-height: 30px;
      }
      .title {
        max-width: 350px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      td {
      }
      td.value {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: end;
        font-size: 12px;
        font-weight: 700;
        color: var(--purple2);
      }
      td div.positive {
        color: #0fba53;
      }
      td div.negative {
        color: #df2f2f;
      }
    }
  }
  .Info {
    margin-bottom: -5px;
    table {
      width: 100%;
    }
    tr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      /* margin-bottom: 10px; */
      font-size: 12px;
      line-height: 30px;
      color: #7d7d7d;
    }
    td {
    }
    td.value {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-align: end;
      font-size: 12px;
      font-weight: 700;
      color: var(--purple2);
    }
    td div.positive {
      color: #0fba53;
    }
    td div.negative {
      color: #df2f2f;
    }
  }

  footer {
    width: 100%;
    a {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      text-decoration: none;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--purple6);
    }
  }
`;

export const Upload = styled.div`
  grid-area: upload;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  flex-direction: column;

  background: var(--white);
  /* border: 1px solid var(--mid-grey); */
  border-radius: 10px;
  box-shadow: 0px 0px 16px -4px rgba(0, 0, 0, 0.1);

  padding: 25px 22px;
  width: 100%;

  @media (max-width: 950px) {
    width: 100%;
  }

  header {
    width: 100%;
    p {
      font-size: 16px;
      color: #7d7d7d;
      margin-top: 20px;
      .bold {
        font-weight: 600;
      }
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        font-size: 20px;
        font-weight: 900;
        line-height: 23px;
        color: var(--purple2);
      }
      a {
        text-decoration: none;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        color: var(--paleta1B);
      }
    }
  }
  main {
    width: 100%;

    .videoContainer {
      max-width: 600px;
      margin-top: 15px;

      .videoWrapper {
        position: relative;

        img {
          pointer-events: none;
          object-fit: cover;
        }

        .videoThumbnail {
          width: 100%;
          height: fit-content;
          aspect-ratio: 16/9;
          border-radius: 10px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
        }

        .playButton {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .CardVideo {
      margin-top: 23px;

      a {
        text-decoration: none;
      }

      img {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 23px;
        border-radius: 4px;
      }

      span {
        font-size: 16px;
        line-height: 18px;
        color: var(--purple2);
      }
    }

    .Info {
      margin-bottom: -5px;
      h2 {
        margin-top: 20px;
        font-size: 16px;
        color: #0fbaba;
        margin-bottom: 20px;
        span {
          font-weight: 700;
        }
      }
      table {
        width: 100%;
      }
      tr {
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* margin-bottom: 10px; */
        font-size: 12px;
        line-height: 30px;
        color: #7d7d7d;
      }
      td {
      }
      td.value {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: end;
        font-size: 12px;
        font-weight: 700;
        color: var(--purple2);
      }
      td div.positive {
        color: #0fba53;
      }
      td div.negative {
        color: #df2f2f;
      }
    }
  }

  footer {
    width: 100%;
    a {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      text-decoration: none;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--purple6);

      span + span {
        margin-top: 20px;
      }
    }
    .first {
      margin-bottom: 20px;
    }
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: var(--mid-grey);
  margin: 20px 0;
`;
