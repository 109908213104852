import { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import SearchInput from '../SearchInput';
import UserImage from '../../assets/icons/user.svg';

import { Container, Logo, MenuRight, ModalSearch } from './styles';
import LogoIcon from '../../assets/icons/logoIcon';
import { useUser } from '../../contexts/UserContext';
import { IconChevronLeft, IconClose } from '../Icons';
import ButttonDefault from '../ButtonDefault';
import Coins from '../Coins';

interface HasProps {
  hasCompact: boolean;
  setHasCompact: (value: boolean) => void;
}

function Header({ hasCompact, setHasCompact }: HasProps) {
  const history = useHistory();
  const [search, setSearch] = useState<string>('');
  const [hidden, setHidden] = useState<boolean>(true);
  const [handleFocus, setHandleFocus] = useState<boolean>(true);
  const [webView, setWebView] = useState<boolean>(false);
  const pageRef = useRef<any>();
  const { user } = useUser();
  var iswebview = require('is-webview');

  useEffect(() => {
    setSearch('');
    isWebView();
  }, []);

  useEffect(() => {
    if (!hidden) {
      setHidden(true);
    }
  }, [window.scrollY]);

  async function handleSubmit(e: any) {
    setHidden(true);
    if (search.length > 0) {
      setHidden(true);
      e.preventDefault();
      history.push(`/search/${search.trim()}`);
    } else if (search.length === 0) {
      history.push('/');
    }
  }

  function handleOnLogin() {
    if (!user.id) {
      history.push('/login');
    } else if (user.tipo === 'consultor') {
      history.push('/consultor/visualizar/canal/' + user.id);
    } else if (user.tipo === 'empresa') {
      history.push('/empresa/visualizar/canal/' + user.id);
    } else if (user?.tipo === 'agencia') {
      history.push(`/agencia/visualizar/${user?.id}`);
    } else if (user?.tipo === 'cadastro') {
      history.push('/usuario/configuracoes');
    }
  }

  function handlePopUp() {
    setHidden(false);
    setTimeout(() => {
      setHandleFocus(true);
    }, 500);
  }

  function isWebView() {
    let userAgent = window.navigator.userAgent.toLowerCase(),
      safari = /safari/.test(userAgent),
      ios = /iphone|ipod|ipad/.test(userAgent);
    if (ios) {
      if (safari) {
        setWebView(false);
      } else if (!safari) {
        setWebView(true);
      }
    } else {
      if (userAgent.includes('wv')) {
        setWebView(true);
      } else {
        setWebView(false);
      }
    }
  }

  return (
    <Container ref={pageRef}>
      <ModalSearch isHidden={hidden}>
        <LogoIcon width={120} height={40} />
        <SearchInput
          value={search}
          onChange={(value) => setSearch(value)}
          submit={handleSubmit as any}
          modal={handleFocus}
        />
        <div
          className="close"
          onClick={() => {
            setHidden(true);
          }}
        >
          <IconClose />
        </div>
        <div
          className="overlay"
          onClick={() => {
            setHidden(true);
          }}
        ></div>
      </ModalSearch>

      <Logo hasCompact={hasCompact}>
        <button
          className="buttonToggleMenu"
          onClick={() => setHasCompact(!hasCompact)}
        >
          <span className="hamburger"></span>
        </button>

        {webView && (
          <div
            className="return"
            style={{ marginRight: 20 }}
            onClick={() => {
              history.goBack();
            }}
          >
            <IconChevronLeft size={19} color={'#000'} />
          </div>
        )}

        <Link to="/">
          <LogoIcon width={window.screen.width > 500 ? 120 : 80} height={40} />
        </Link>
      </Logo>

      {window.innerWidth > 600 && (
        <form onSubmit={handleSubmit}>
          <SearchInput
            value={search}
            onChange={(value) => setSearch(value)}
            submit={handleSubmit as any}
            focus={() => {
              handlePopUp();
            }}
          />
        </form>
      )}

      {window.innerWidth <= 600 && (
        <div className="areaSearch">
          <ButttonDefault
            title={''}
            icon={'Pesquisar'}
            tipo={'2'}
            onClick={() => {
              handlePopUp();
            }}
          />
        </div>
      )}

      {/**
 * 
      {user?.id && <Coins />}
 */}

      <MenuRight>
        <div>
          {window.innerWidth > 600 && (
            <>
              {/* {user.tipo === 'consultor' && (
                <button className="camera">
                  <Link to="/videos/criar">
                    <CameraIcon />
                  </Link>
                </button>
              )}

              <button className="notification">
                <Link to="/settings">
                  <img src={Sino} />
                </Link>
              </button> */}

              <button onClick={handleOnLogin}>
                <div className="areaUser">
                  <img src={UserImage} style={{ height: '20px' }} />
                  <h3>{user.nickName ?? 'Entrar'}</h3>
                </div>
              </button>
            </>
          )}
        </div>
      </MenuRight>
    </Container>
  );
}

export default Header;
