import styled from 'styled-components';

interface Props {
  openModal: boolean;
  width?: string;
}

export const ContainerOverlay = styled.div<Props>`
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  opacity: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: ${(props) => (props.openModal ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 100;  
  @media (max-width: 425px) {
    height: 100vh;
    max-height: 100vh;
    margin-top: 0;
    overflow: hidden;
  }

`;

export const Container = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  transition: transform 0.15s;
  transform: scale(1);

  .container-modal {
    width: 300px;
    max-height: 80vh;
    position: relative;
    background-color: var(--background2);
    padding: 20px;
    border-radius: 10px;
  }
  .close {
    position: absolute;
    top: 0px;
    left: 0px;
    margin: -20px -20px;
    padding: 10px;
    display: flex;
    align-items: center;
    background: var(--white);
    border-radius: 50%;
    border: 2px solid var(--white);
    cursor: pointer;
    transition: ease-in-out 0.2s;
    z-index: 99;
    &:hover {
      background: var(--green);
      border: 2px solid var(--green);
      svg {
      }
    }
    svg {
      width: 20px;
      height: 20px;
      path {
        stroke: var(--green);
      }
    }
    .hidden {
      display: none;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    text-align: justify;
    gap: 10px;
  }
  @media (max-width: 425px) {
    width: 100vw;
    height: 100vh;
    .container-modal {
      min-width: 80vw;
      height: 100vh;
      max-height: 100vh;
    }
    .close {
      background: var(--white);
      width: 100%;
      border: none !important;
      -webkit-border: none !important;
      -webkit-border-radius: 0 !important;
      -moz-border-radius: 0 !important;
      border-radius: 0 !important;
      margin: 15px -10px;
      &:hover {
        background: transparent;
        border: none;
        svg {
          path {
            stroke: var(--green);
          }
        }
      }
      svg {
        width: 25px;
        height: 25px;
      }
      .hidden {
        display: block;
        color: var(--purple2);
        font-weight: 600;
        font-size: 12px;
        text-transform: uppercase;
        margin-left: 5px;
      }
    }
  }

  @media (max-width: 800px) {
    min-height: 50px;
    max-height: 80px;
    min-width: 80%;
    .container-modal {
      width: 50%;
    }
  }
`;
