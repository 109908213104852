import styled from 'styled-components';

export const ContainerStepOne = styled.div`
  .titleStepOne {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    margin-bottom: 16px;
  }

  .signUpForm {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .inputField {
    display: flex;
    flex-direction: column;
    gap: 6px;
    position: relative;
    label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: var(--color2A);
    }
    .eyeIcon {
      position: absolute;
      bottom: 0;
      right: 10px;
      background-color: transparent;
      border: none;
    }
  }

  .submitButton {
    margin-top: 10px;
  }

  .termosDeUso {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #666666;
    a {
      color: #1ea0ff;
    }
  }
`;
