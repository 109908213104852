import styled from 'styled-components';

interface props {
  full?: boolean;
}

export const Container = styled.div<props>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 30px;
  .videoWidthWrapper {
    width: 70%;
    @media (max-width: 800px) {
      width: 100%;
    }

    .videoWrapper {
      position: relative;
      padding-bottom: 62.45%;
      height: 0;
    }
  }
  .videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .fullScreenButton {
    position: absolute;
    bottom: 0;
    right: 0;
    border: none;
    background-color: transparent;
    margin-bottom: 15px;
    margin-right: 15px;
  }
  .commentsWrapper {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
`;

export const AdsContainer = styled.div`
  width: 100%;
  height: calc(100vh - 115px);
  display: flex;
  align-items: center;
  justify-content: center;

  .backgroundImage {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
`;
