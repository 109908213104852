import styled, { css } from 'styled-components';

interface HProps {
  estilo: string;
}

interface props {
  pos: string;
}

export const Container = styled.div``;
export const Title = styled.h2`
  color: var(--paleta2C);
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const AreaHash = styled.div<props>`
  display: flex;
  flex-wrap: ${({ pos }) => (pos === 'top' ? 'nowrap' : 'wrap')};
  gap: 5px;
  display: ${({ pos }) => pos === 'top' && 'flex'};
`;

export const Hashtag = styled.a<HProps>`
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
  line-height: 14px;
  background-color: var(--paleta1C);

  white-space: nowrap;

  ${(props) =>
    props.estilo &&
    css`
      background-color: var(--paleta1A);
    `}

  padding: 0 10px;
  height: 26px;
  border-radius: 50px;
  color: var(--branco);
  display: flex;
  align-items: center;
  transition: all 0.2s;

  &:hover {
    transition: all 0.2s;
    transform: scale(1.1);
  }
`;
