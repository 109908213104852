import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.fullscreen {
    height: calc(100vh - 120px);
  }
  &.normal {
    padding: 40px 0px;
  }
  .emptyBoxIcon {
    width: 100px;
    height: 100px;
  }
  h1 {
    font-weight: 800;
    font-size: 20px;
    text-align: center;
  }
  h2 {
    font-size: 13px;
  }
  svg {
    font-size: 100px;
  }
  button {
    margin-top: 20px;
  }
`;
