import { Container } from './styles';
import ReactPlayer from 'react-player';

export default function VideoPlayer({ ...rest }) {
  return (
    <Container>
      <ReactPlayer
        width="100%"
        height="100%"
        controls={true}
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload', // Remover a opção de download
            },
          },
        }}
        {...rest}
      />
    </Container>
  );
}
