import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 15px;
  .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    position: relative;
    z-index: 1;
  }
  .areaLeft {
    width: 100%;
    .title {
      font-family: 'Roboto', sans-serif;
      font-size: 28px;
      line-height: 30px;
      font-weight: 700;
      color: var(--paleta2C);
    }

    .areaInput {
      display: flex;
      flex-direction: column;
      .subtitle {
        margin-top: 20px;
        font-size: 13px;
        line-height: 15px;
        font-weight: 700;
        color: var(--texto);
        margin-bottom: 5px;
      }
      .comment {
        width: 100%;
        height: 120px;
        background-color: var(--background2);
        margin-top: 3px;
        border: none;
        border-radius: 4px;
        padding: 10px;
        outline: none;
        resize: none;
      }
    }
    .videos {
      .subtitle {
        margin-top: 20px;
        font-size: 13px;
        line-height: 15px;
        font-weight: 700;
        color: var(--texto);
        margin-bottom: 10px;
      }
      .cards {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        .card {
          display: flex;
          flex-direction: column;
          width: 290px;
          justify-content: flex-end;
          align-items: flex-end;
          gap: 5px;
          .btnDeletar {
            width: 100%;
          }
        }
      }
    }
  }
  .areaButton {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    margin-top: 20px;
    .title {
      font-family: 'Roboto', sans-serif;
      font-size: 28px;
      line-height: 30px;
      font-weight: 700;
      color: var(--paleta2C);
    }
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    .areaLeft {
      width: 100%;
    }
    .areaButton {
      width: 100%;
    }
  }
`;
