import React from 'react';
import { ToastContainer } from 'react-toastify';
import CoinsContext, { CoinsProvider } from './contexts/coinsContext';
import { UserProvider } from './contexts/UserContext';
import Routes from './routes';
import { GlobalStyle } from './styles/global';
import { Toasted } from './styles/Toasted';

function App() {
  return (
    <UserProvider>
      <CoinsProvider>
        <Routes />
        <GlobalStyle />
        <Toasted>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
          />
        </Toasted>
      </CoinsProvider>
    </UserProvider>
  );
}

export default App;
