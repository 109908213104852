import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
  padding-bottom: 200px;

  .notfound {
    .info {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
      padding: 50px;
      background: linear-gradient(
        90deg,
        rgba(54, 127, 210, 1) 0%,
        rgba(6, 97, 199, 1) 100%
      );
      border-radius: 10px;

      h3 {
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        color: var(--branco);
      }
    }
  }

  .iconLoading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
  }

  .areaCard {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
`;

export const Element = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--paleta1B);
  margin-top: 30px;
  margin-bottom: 30px;
`;
