import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: -30px;
  h2 {
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
    color: var(--paleta2C);
    margin-bottom: 10px;
  }

  .areaComment {
    display: flex;
    gap: 10px;
    width: 100%;
    .image {
      min-width: 60px;
      min-height: 60px;
      max-width: 60px;
      max-height: 60px;
      border-radius: 50%;
      background-color: var(--background2);
      background-position: center;
      background-size: cover;
    }
    .areaLeft {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      h3 {
        font-size: 13px;
        font-weight: 700;
        line-height: 15px;
        color: var(--paleta1A);
      }
      .comment {
        padding: 9px;
        background: var(--background2);
        border: 1px solid var(--background2);
        border-radius: 0px 10px 10px 10px;
      }
      .areaSubmit {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
`;
