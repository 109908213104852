import { LoginCardLeft, LoginCardRight, LoginContainer } from './styles';
import Logo from '../../assets/Logo.png';
import Screen from '../../assets/UserScreen.png';
import { useEffect, useRef, useState } from 'react';
import apiDoubts from '../../services/api';
import SyncLoader from 'react-spinners/SyncLoader';
import EnterArrowIcon from '../../assets/icons/EnterArrowIcon';
import EyeIcon from '../../assets/icons/EyeIcon';
import ClosedEyeIcon from '../../assets/icons/ClosedEyeIcon';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { AxiosResponse } from 'axios';
import { getDifferenceBetweenTwoMoments } from '../../utils/getDifferenceBetweenTwoMoments';
import { useHistory, useLocation } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';

const UserLogin: React.FC<any> = () => {
  const history = useHistory();

  const { getProfile } = useUser();

  const [loading, setLoading] = useState<boolean>(false);
  const [DTO, setDTO] = useState({
    login: '',
    senha: '',
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [recuperarSenha, setRecuperarSenha] = useState<boolean>(false);
  const recoverButtonRef = useRef(null);

  async function handleLogin(tipo: any) {
    const urlAnterior = document.referrer;

    if (urlAnterior) {
      window.location.href = urlAnterior;
    } else {
      window.location.href = '/';
    }
  }

  function handleOnChange(name: any, value: any) {
    const newDTO: any = DTO;
    newDTO[name] = value;
    setDTO({ ...newDTO });
  }

  async function handleOnSubmit(e: any) {
    try {
      e.preventDefault();

      if (loading === true)
        throw new Error('Aguarde enquanto processamos seus dados.');

      setLoading(true);

      if (!DTO.login)
        throw new Error('Preencha seu email antes de prosseguir.');
      if (!DTO.senha)
        throw new Error('Preencha sua senha antes de prosseguir.');

      const response = await apiDoubts.post('/login', {
        login: DTO.login,
        senha: DTO.senha,
      });

      if (response.data.status === 'error') throw response.data.message;
      window.localStorage.setItem('token', response.data.data.token);
      window.localStorage.removeItem('token-deslogado');
      setLoading(false);
      handleLogin(response.data.data.tipo);
    } catch (err: any) {
      toast.error(err, {
        className: 'toast-error',
        theme: 'colored',
      });
      setLoading(false);
    }
  }

  async function forgetPassword(e: any) {
    e.preventDefault();

    if (!e?.target?.[0]?.value) {
      toast.error(`Insira um e-mail para recuperação de senha`, {
        className: 'toast-error',
        theme: 'colored',
      });
      return;
    }

    const recoverTimer = isPossibleRecoverPassword();

    if (!recoverTimer?.isPossible) {
      toast.error(recoverTimer?.message, {
        className: 'toast-error',
        theme: 'colored',
      });

      handleDisableButton(recoverTimer?.minutes, recoverTimer?.seconds);

      return;
    }

    setLoading(true);

    let failedRecoverCount = 0;
    let arrayOfRecoverPasswordPromises: Promise<void | AxiosResponse<
      any,
      any
    >>[] = [];

    const allProfileTypes = ['cadastros', 'consultor', 'empresa', 'agencia'];

    allProfileTypes?.forEach((profile) => {
      arrayOfRecoverPasswordPromises?.push(
        apiDoubts
          ?.post(`/${profile}/senhaEsquecida`, {
            email: DTO?.login,
            horario: moment().format('YYYY-MM-DD HH:mm:ss'),
          })
          ?.then(() => handleSetAccountsAttempts())
          ?.catch(() => {
            failedRecoverCount++;
          })
      );
    });

    await Promise.all(arrayOfRecoverPasswordPromises);

    if (failedRecoverCount === 3) {
      toast.success(
        'Uma mensagem foi enviada para o seu email... siga o passo a passo para recuperar a sua senha',
        {
          className: 'toast-sucess',
          theme: 'colored',
        }
      );
    } else {
      e?.target[1]?.removeAttribute('disabled');

      toast.error('Ops, tente novamente mais tarde...', {
        className: 'toast-error',
        theme: 'colored',
      });
    }

    setLoading(false);

    // apiDoubts
    //   .post(`cadastros/senhaEsquecida`, { email: DTO.login })
    //   .then((response: any) => {
    //     toast.success(
    //       'Uma mensagem foi enviada para o seu email... siga o passo a passo para recuperar a sua senha',
    //       {
    //         className: 'toast-sucess',
    //         theme: 'colored',
    //       }
    //     );
    //     setLoading(false)
    //   })
    //   .catch((error: any) => {
    //     setLoading(false)
    //     toast.error(error?.response?.data?.message, {
    //       className: 'toast-error',
    //       theme: 'colored',
    //     });
    //   });
  }

  function isPossibleRecoverPassword() {
    const lastsAttempts = localStorage?.getItem('lastsAttempts');

    let allAttempts = [];

    if (lastsAttempts) {
      const allLastRecoverPasswordAttempts = JSON.parse(lastsAttempts);

      allAttempts?.push(...allLastRecoverPasswordAttempts);
    }

    const isCurrentUser = allAttempts?.find(
      (attempt) => attempt?.login === DTO?.login
    );

    console.log(isCurrentUser);

    if (!isCurrentUser || isCurrentUser?.login !== DTO?.login) {
      return { isPossible: true };
    }

    const currentTime = moment(moment().format('HH:mm:ss'), 'HH:mm:ss');

    const { minutes, seconds } = getDifferenceBetweenTwoMoments(
      currentTime,
      moment(isCurrentUser?.timer, 'HH:mm:ss')
    );

    if (minutes <= 0 && seconds <= 0) return { isPossible: true };

    if (minutes < 30) {
      return {
        isPossible: false,
        minutes: minutes,
        seconds: seconds,
        message: `Aguarde ${minutes} minutos e ${seconds} segundos para recuperar senha novamente`,
      };
    }

    return {
      isPossible: true,
    };
  }

  function handleSetAccountsAttempts() {
    const lastsAttempts = localStorage?.getItem('lastsAttempts');

    let allAttempts = [];

    if (lastsAttempts) {
      const allLastRecoverPasswordAttempts = JSON.parse(lastsAttempts);

      allAttempts?.push(...allLastRecoverPasswordAttempts);
    }

    const isCurrentUser = allAttempts?.find(
      (attempt) => attempt?.login === DTO?.login
    );

    if (isCurrentUser) {
      handleDisableButton();
      return;
    }

    const currentUserData = {
      login: DTO?.login,
      timer: moment().add(30, 'minutes').format('HH:mm:ss'),
    };

    allAttempts?.push(currentUserData);

    localStorage?.setItem('lastsAttempts', JSON.stringify(allAttempts));
  }

  function handleDisableButton(minutes?: number, seconds?: number) {
    const timeoutTimer = minutes
      ? 1000 * 60 * minutes
      : seconds
      ? 1000 * seconds
      : 0;

    if (recoverButtonRef.current) {
      const buttonRecover = recoverButtonRef.current as any;

      buttonRecover.disabled = true;

      setTimeout(() => {
        buttonRecover.disabled = false;
      }, timeoutTimer);
    }
  }

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (window.localStorage.getItem('token')) history.push('/');
  }, []);

  return (
    <LoginContainer>
      <LoginCardLeft>
        <div className="logo">
          <img src={Logo} alt="Logo Doubts no lado esquerdo" />
        </div>

        <div className="middle">
          {recuperarSenha ? (
            <div className="text">
              <h1>Recuperação de senha</h1>
              <p className="bold">
                Digite Abaixo o email cadastrado na plataforma para que possamos
                validar a sua identidade
              </p>
            </div>
          ) : (
            <div className="text">
              <h1>
                Uma nova forma de tirar dúvidas e encontrar soluções para as
                questões do dia a dia de forma rápida e prática.
              </h1>
              <p className="bold">
                Agende um atendimento personalizado com o canal de sua
                preferência.
              </p>
              <p>
                Muitas vezes precisamos de meia hora para tirar aquela dúvida e,
                com a Doubts, você pode reservar alguns minutos com um
                profissional para sanar essas dúvidas.
              </p>
            </div>
          )}

          {recuperarSenha ? (
            <>
              <div className="input">
                <form onSubmit={forgetPassword}>
                  <input
                    name="Email"
                    onChange={(e) => handleOnChange('login', e.target.value)}
                    value={DTO.login}
                    placeholder="Email"
                  />
                  <button type="submit" ref={recoverButtonRef}>
                    {loading === true ? (
                      <SyncLoader
                        color={'#0FBABA'}
                        loading={loading}
                        size={10}
                        margin={10}
                      />
                    ) : (
                      'Recuperar Senha'
                    )}
                    <EnterArrowIcon />
                  </button>
                  <div className="linkArea">
                    <div></div>
                    <span
                      onClick={() => {
                        setRecuperarSenha((oldState) => !oldState);
                      }}
                      className="link"
                    >
                      <span
                        style={{
                          fontWeight: 700,
                          cursor: 'pointer',
                        }}
                      >
                        Voltar
                      </span>
                    </span>
                  </div>
                </form>
              </div>
            </>
          ) : (
            <>
              <div className="input">
                <form onSubmit={handleOnSubmit}>
                  <input
                    name="Email"
                    onChange={(e) => handleOnChange('login', e.target.value)}
                    value={DTO.login}
                    placeholder="Email"
                  />

                  <div className="pass">
                    <input
                      className="input-icon"
                      name="Senha"
                      type={showPassword ? 'text' : 'password'}
                      value={DTO.senha}
                      onChange={(e) => handleOnChange('senha', e.target.value)}
                      placeholder="Senha"
                    />
                    {showPassword ? (
                      <div className="pass-icon" onClick={togglePassword}>
                        <ClosedEyeIcon />
                      </div>
                    ) : (
                      <div className="pass-icon" onClick={togglePassword}>
                        <EyeIcon />
                      </div>
                    )}
                  </div>

                  <button>
                    {loading === true ? (
                      <SyncLoader
                        color={'#0FBABA'}
                        loading={loading}
                        size={10}
                        margin={10}
                      />
                    ) : (
                      'Entrar'
                    )}
                    <EnterArrowIcon />
                  </button>
                  <div className="linkArea">
                    <a href="/cadastrar" className="link">
                      Ainda não possui uma conta ?{' '}
                      <span style={{ fontWeight: 700 }}>Cadastrar</span>
                    </a>
                    <span
                      onClick={() => {
                        setRecuperarSenha((oldState) => !oldState);
                      }}
                      className="link"
                    >
                      <span
                        style={{
                          fontWeight: 700,
                          color: '#E62965',
                          cursor: 'pointer',
                        }}
                      >
                        Recuperar Senha
                      </span>
                    </span>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </LoginCardLeft>
      <LoginCardRight>
        <div
          className="user-photo"
          style={{ backgroundImage: `url(${Screen})` }}
        />
      </LoginCardRight>
    </LoginContainer>
  );
};

export default UserLogin;
