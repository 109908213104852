import { Container, Input } from './styles';

interface VProps {
  placeholder: string;
  tipo: 'text' | 'file' | 'password';
  change?: (e: any) => void;
  changeBlur?(e: any): void;
  value?: string;
  color?: 'branco' | 'azul' | 'vermelho' | 'transparent' | any;
  icon?: any;
  onClickIcon?: () => void;
  children?: any;
  keyDown?: () => void;
}

export default function Inputdefault({
  placeholder,
  tipo,
  value,
  change,
  changeBlur,
  icon,
  color,
  onClickIcon,
  children,
  keyDown,
  ...rest
}: VProps) {
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      // 👇 Get input value
      keyDown && keyDown();
    }
  };

  return (
    <Container>
      <Input
        autoComplete="false"
        placeholder={placeholder}
        type={tipo}
        onChange={change}
        onBlur={changeBlur}
        value={value}
        color={color}
        {...rest}
        onKeyDown={handleKeyDown}
      />
      <div className="icon" onClick={onClickIcon}>
        {icon && icon}
      </div>
    </Container>
  );
}
