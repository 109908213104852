import React from 'react';
import { Container } from './styles';

interface props {
  link?: any;
  icon: any;
  value: string;
  changeText: (e: string) => void;
}

export default function InputLink({ link, icon, value, changeText }: props) {
  return (
    <Container>
      <div className="iconButton">{icon}</div>
      <input
        type="text"
        className="linkButton"
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          changeText(e?.target?.value);
        }}
      />
    </Container>
  );
}
