/* eslint-disable no-lone-blocks */
import { Ref, useCallback, useEffect, useState } from 'react';
import Calendar from 'react-calendar';

import { ContainerCalendar, ContainerHours } from './styles';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import Skeleton from '../Skeleton';

export type ValuePiece = Date | null;

export type Value = ValuePiece | [ValuePiece, ValuePiece];

interface IPropsCalendarStatic {
  value: Value;
  onChange(value: Date): void;
}

interface IPropsHoursStatic {
  hiddenDisable?: boolean;
  refScroll?: any;
  filterDate: Date;
  data: IPropsHoursStaticData[];
  onClickEvent(data: IPropsHoursStaticData): void;
  loading: boolean;
  textNewEvent?: string;
  onNewEvent?(hour: string, description: string): void;
}

export interface IPropsHoursStaticData {
  title: string;
  hora: string;
  color: string;
  reference?: any;
}

interface IPropsDataHours {
  hora: string;
  active: boolean;
  old: boolean;
}

export function CalendarStatic({ value, onChange }: IPropsCalendarStatic) {
  return (
    <ContainerCalendar>
      <Calendar onChange={onChange} value={value} />
    </ContainerCalendar>
  );
}

const MINUTES_SEPARATE = 10;

export function HoursStatic({
  loading,
  hiddenDisable = false,
  refScroll,
  data,
  filterDate,
  textNewEvent = 'Adicionar',
  onClickEvent,
  onNewEvent,
}: IPropsHoursStatic) {
  const [hours, setHours] = useState<IPropsDataHours[]>([]);
  const [clicando, setClicando] = useState(false);

  const mountHours = useCallback(() => {
    const horas: IPropsDataHours[] = [];
    const agora = new Date();
    const horaAtual = agora.getHours();
    const minutoAtual = agora.getMinutes();

    for (let hora = 0; hora < 24; hora++) {
      for (let minuto = 0; minuto < 60; minuto += MINUTES_SEPARATE) {
        const horaFormatada = `${hora.toString().padStart(2, '0')}:${minuto
          .toString()
          .padStart(2, '0')}`;
        const isPassada =
          moment(filterDate).format('YYYY-MM-DD') >
          moment().format('YYYY-MM-DD')
            ? false
            : moment(filterDate).format('YYYY-MM-DD') <
              moment().format('YYYY-MM-DD')
            ? true
            : hora < horaAtual ||
              (hora === horaAtual &&
                minuto < minutoAtual &&
                moment(filterDate).format('YYYY-MM-DD') >=
                  moment().format('YYYY-MM-DD'));
        const isActive =
          moment(filterDate).format('YYYY-MM-DD') ===
          moment().format('YYYY-MM-DD')
            ? hora * 60 + minuto <= horaAtual * 60 + minutoAtual &&
              hora * 60 + minuto + 10 > horaAtual * 60 + minutoAtual
            : false;

        horas.push({ hora: horaFormatada, active: isActive, old: isPassada });
      }
    }

    setHours([...horas]);
  }, [filterDate]);

  useEffect(() => {
    mountHours();

    const interval = setInterval(() => {
      mountHours();
    }, 5000);

    return () => clearInterval(interval);
  }, [mountHours]);

  useEffect(() => {
    if (refScroll) {
      const activeRow: any = document.querySelector(
        '#overflow-hours .row-hour.active'
      );
      if (
        activeRow &&
        moment(filterDate).format('YYYY-MM-DD') ===
          moment().format('YYYY-MM-DD')
      ) {
        const offsetTop = activeRow.offsetTop;
        refScroll.current.scrollTop = offsetTop - 75 * 5;
      } else {
        refScroll.current.scrollTop = 0;
      }
    }
  }, [refScroll, loading, filterDate]);

  const handleOnNewEvent = (hour: string, nextHour: string) => {
    if (onNewEvent) onNewEvent(hour, `${hour} até ${nextHour}`);
  };

  const handleMouseDown = () => {
    setClicando(true);
  };

  const handleMouseUp = () => {
    setClicando(false);
  };

  useEffect(() => {
    const handleWindowMouseUp = () => {
      if (clicando) {
        setClicando(false);
      }
    };
    window.addEventListener('mouseup', handleWindowMouseUp);
    return () => {
      window.removeEventListener('mouseup', handleWindowMouseUp);
    };
  }, [clicando]);

  const handleMouseOver = useCallback(
    (hour: any, indice: any) => {
      if (clicando && onNewEvent) {
        if (data.filter((obj) => obj.hora === hour.hora).length === 0) {
          handleOnNewEvent(
            hour.hora,
            indice === hours.length - 1 ? hours[0].hora : hours[indice + 1].hora
          );
        }
      }
    },
    [data, clicando, onNewEvent]
  );

  return (
    <ContainerHours onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
      {hours.map((hour, indice) =>
        (hour.old === true && hiddenDisable === true) ||
        (hiddenDisable === true &&
          data.filter((obj) => obj.hora === hour.hora).length === 0) ? null : (
          <div
            onMouseOver={() => handleMouseOver(hour, indice)}
            className={`row-hour ${hour.active ? 'active' : ''} ${
              hour.old ? 'old' : ''
            }`}
          >
            <span className="hour-text">{hour.hora}</span>
            <div className="events">
              {loading && <Skeleton height={50} />}

              {loading === false &&
                hour.old === false &&
                data.filter((obj) => obj.hora === hour.hora).length === 0 &&
                onNewEvent && (
                  <div
                    className="event hover"
                    style={{
                      backgroundColor: 'var(--paleta1C)',
                    }}
                    onClick={() =>
                      handleOnNewEvent(
                        hour.hora,
                        indice === hours.length - 1
                          ? hours[0].hora
                          : hours[indice + 1].hora
                      )
                    }
                  >
                    <span className="title">{textNewEvent}</span>
                  </div>
                )}

              {loading === false &&
                data
                  .filter((obj) => obj.hora === hour.hora)
                  .map((event) => (
                    <div
                      className="event"
                      style={{
                        backgroundColor: event.color,
                      }}
                      onClick={() => {
                        onClickEvent({
                          ...event,
                          reference: {
                            ...event.reference,
                            disabled: hour.old,
                          },
                        });
                      }}
                    >
                      <span className="title">{event.title}</span>
                      <span className="description">
                        {hour.hora} até{' '}
                        {indice === hours.length - 1
                          ? hours[0].hora
                          : hours[indice + 1].hora}
                      </span>
                    </div>
                  ))}
            </div>
          </div>
        )
      )}
    </ContainerHours>
  );
}
