import styled from 'styled-components';

export const Container = styled.div`
  grid-area: main;
  /* display: flex;
  flex-wrap: wrap; */

  grid-template-areas:
    'dailyViews dailyViews dailyViews'
    'upload analytics videos';

  .multiplesColumns {
    display: flex;
    gap: 20px;
    margin: 21.5px 0;

    @media (max-width: 1400px) {
      flex-wrap: wrap;
    }
  }

  @media (max-width: 1199px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const ContentLeft = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 1199px) {
    width: 100%;
  }
`;

export const Upload = styled.div`
  grid-area: upload;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  background: var(--white);
  /* border: 1px solid var(--mid-grey); */
  border-radius: 10px;
  box-shadow: 0px 0px 16px -4px rgba(0, 0, 0, 0.1);

  padding: 25px 22px;
  width: 100%;

  @media (max-width: 950px) {
    width: 100%;
  }

  header {
    width: 100%;
    p {
      font-size: 16px;
      color: #7d7d7d;
      margin-top: 20px;
      .bold {
        font-weight: 600;
      }
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        font-size: 20px;
        font-weight: 900;
        line-height: 23px;
        color: var(--purple2);
      }
      a {
        text-decoration: none;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        color: var(--paleta1B);
      }
    }
  }
  main {
    width: 100%;

    .videoContainer {
      max-width: 600px;
      margin-top: 15px;

      .videoWrapper {
        position: relative;

        max-width: 100%;

        img {
          pointer-events: none;
          object-fit: cover;
        }

        .videoThumbnail {
          width: 100%;
          height: fit-content;
          aspect-ratio: 16/9;
          border-radius: 10px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
        }

        .playButton {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .skeletonWrapper {
        width: clamp(200px, 100%, 422px);
      }
    }

    .CardVideo {
      margin-top: 23px;

      a {
        text-decoration: none;
      }

      img {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 23px;
        border-radius: 4px;
      }

      span {
        font-size: 16px;
        line-height: 18px;
        color: var(--purple2);
      }
    }

    .Info {
      margin-bottom: -5px;
      table {
        width: 100%;
      }
      tr {
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* margin-bottom: 10px; */
        font-size: 12px;
        line-height: 30px;
        color: #7d7d7d;
      }
      td {
      }
      td.value {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: end;
        font-size: 12px;
        font-weight: 700;
        color: var(--purple2);
      }
      td div.positive {
        color: #0fba53;
      }
      td div.negative {
        color: #df2f2f;
      }
    }
  }

  footer {
    width: 100%;
    a {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      text-decoration: none;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--purple6);

      span + span {
        margin-top: 20px;
      }
    }
    .first {
      margin-bottom: 20px;
    }
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: var(--mid-grey);
  margin: 20px 0;
`;

export const Analytics = styled.div`
  grid-area: analytics;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  background: var(--white);
  /* border: 1px solid var(--mid-grey); */
  border-radius: 10px;
  box-shadow: 0px 0px 16px -4px rgba(0, 0, 0, 0.1);

  padding: 25px 22px;
  width: 100%;

  @media (max-width: 950px) {
    width: 100%;
    margin-top: 20px;
  }

  header {
    width: 100%;
    h2 {
      text-align: start;
      font-size: 20px;
      font-weight: 900;
      line-height: 23px;
      color: var(--purple2);
    }
  }
  main {
    width: 100%;
    .seguidores {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      width: 100%;
      height: 171px;
      border: none;
      border-radius: 4px;
      background: var(--background);

      margin-top: 20px;

      span {
        font-size: 16px;
        color: var(--purple2);
      }

      strong {
        font-size: 40px;
        font-weight: 900;
        color: var(--purple2);
        margin-top: 8px;
      }
      p {
        font-size: 12px;
        color: var(--dark-grey);
        margin-top: 8px;
        span {
          font-size: 12px;
          font-weight: bold;
          color: #0fba53;
          margin-top: 8px;
        }
      }
    }

    .resume {
      margin-bottom: -5px;
      h2 {
        margin-top: 20px;
        font-size: 16px;
        color: #0fbaba;
        span {
          font-weight: 700;
        }
      }
      table {
        width: 100%;
        margin-top: 20px;
      }
      tr {
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* margin-bottom: 10px; */
        font-size: 12px;
        line-height: 30px;
        color: #7d7d7d;
      }
      td {
      }
      td.value {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: end;
        font-size: 12px;
        font-weight: 700;
        color: var(--purple2);
      }
      td div.positive {
        color: #0fba53;
      }
      td div.negative {
        color: #df2f2f;
      }
    }
    .topVideos {
      margin-bottom: -10px;
      h2 {
        margin-top: 20px;
        font-size: 16px;
        color: #0fbaba;
        span {
          font-weight: 700;
        }
      }
      table {
        width: 100%;
        margin-top: 20px;
      }
      tr {
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* margin-bottom: 10px; */
        font-size: 12px;
        color: #7d7d7d;
        line-height: 30px;
      }
      .title {
        max-width: 350px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      td {
      }
      td.value {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: end;
        font-size: 12px;
        font-weight: 700;
        color: var(--purple2);
      }
      td div.positive {
        color: #0fba53;
      }
      td div.negative {
        color: #df2f2f;
      }
    }
  }
  footer {
    width: 100%;
    a {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      text-decoration: none;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--purple6);
    }
  }
`;

export const Videos = styled.div`
  grid-area: videos;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  background: var(--white);
  /* border: 1px solid var(--mid-grey); */
  border-radius: 10px;
  box-shadow: 0px 0px 16px -4px rgba(0, 0, 0, 0.1);

  padding: 25px 22px;
  width: 100%;

  @media (max-width: 950px) {
    width: 100%;
    margin-top: 20px;
  }

  h2 {
    margin-top: 20px;
    font-size: 16px;
    color: #0fbaba;
    cursor: pointer;
    span {
      font-weight: 700;
    }
  }
  table {
    width: 100%;
    margin-top: 20px;
  }
  tbody {
    max-height: 400px;
    overflow-y: scroll;
  }
  tr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    color: #7d7d7d;
    line-height: 30px;
    padding: 5px;
    margin-bottom: 10px;
    border-radius: 10px;

    :hover {
      background: rgba(5, 5, 5, 0.1);
      cursor: pointer;
    }
  }
  .title {
    max-width: 350px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  td {
  }
  td.value {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: end;
    font-size: 12px;
    font-weight: 700;
    color: var(--purple2);
  }
  td div.positive {
    color: #0fba53;
  }
  td div.negative {
    color: #df2f2f;
  }

  .videoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    p {
      font-size: 13px;
      max-width: 200px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .imageWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      aspect-ratio: 16/9;
      width: 90px;
      border-radius: 10px;
      background-position: center center;
      background-size: cover;
    }
  }

  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      text-align: start;
      font-size: 20px;
      font-weight: 900;
      line-height: 23px;
      color: var(--purple2);
    }

    .showAllVideos {
      color: var(--paleta1B);
      text-decoration: none;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
  main {
    width: 100%;
    max-height: 450px;
    overflow-y: scroll;
    padding-right: 15px;

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  footer {
    width: 100%;
    a {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      text-decoration: none;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--purple6);
    }
  }
`;

export const DailyViews = styled.div`
  grid-area: dailyViews;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: relative;

  background: var(--white);
  /* border: 1px solid var(--mid-grey); */
  border-radius: 10px;

  padding: 25px 22px;
  width: 100%;
  height: 320px;
  /* flex: 1; */
  /* margin-bottom: 25px; */
  box-shadow: 0px 0px 16px -4px rgba(0, 0, 0, 0.1);

  @media (max-width: 950px) {
    width: 100%;
  }

  header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 17px;
    h2 {
      font-size: 20px;
      font-weight: 900;
      line-height: 23px;
      color: var(--purple2);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
    a {
      text-decoration: none;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--purple6);
    }
  }

  main {
    width: 100%;
    height: 90%;
    position: relative;
  }

  .loading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* backdrop-filter: blur(1px); */
    background: rgba(255, 255, 255, 0.2);

    &::before {
      content: attr(data-loading);
      font-weight: bolder;
      text-transform: uppercase;
      font-size: 48px;
      color: rgba(14, 144, 255, 0.2);
      letter-spacing: 8px;
    }
  }

  .tinyText {
    position: absolute;
    margin-top: 100px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* backdrop-filter: blur(1px); */
    background: rgba(255, 255, 255, 0.2);

    &::before {
      content: attr(data-loading);
      font-weight: bolder;
      text-transform: uppercase;
      font-size: 48px;
      color: rgba(14, 144, 255, 0.2);
      letter-spacing: 8px;
    }
  }

  .legendContainer {
    font-size: 13px;
    align-self: flex-start;
    margin: 0 0 12px 20px;

    .legend {
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: help;

      .line {
        height: 2px;
        width: 17px;

        &.purple {
          background-color: purple;
        }

        &.blue {
          background-color: blue;
        }
      }
    }
  }

  @media (max-width: 800px) {
    .tinyText {
      right: 0;
      bottom: unset;
      left: unset;
      margin-top: 170px;
      width: calc(100% - 100px);
      height: min-content;
      align-items: center;
      margin-right: 20px;

      &::before {
        font-size: 18px;
      }
    }
  }

  @media (max-width: 450px) {
    .tinyText {
      right: 0;
      bottom: unset;
      left: unset;
      margin-top: 170px;
      width: min-content;
      height: min-content;
      align-items: center;
      margin-right: 15px;
      &::before {
        font-size: 11px;
      }
    }
  }
`;

export const ContentRight = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 30%;
  gap: 35px;
  margin-left: 20px;

  @media (max-width: 1199px) {
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    gap: 20px;
    margin-left: 0px;
    margin-top: 20px;
  }

  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

export const Finance = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  background: var(--white);
  border: 1px solid var(--mid-grey);
  border-radius: 4px;

  padding: 25px 22px;
  width: 100%;

  header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
      font-size: 20px;
      font-weight: 900;
      line-height: 23px;
      color: var(--purple2);
    }
    a {
      text-decoration: none;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--purple6);
    }
  }

  main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 100%;
    height: 171px;
    border: none;
    border-radius: 4px;
    background: var(--background);

    margin-top: 20px;

    span {
      font-size: 16px;
      color: var(--purple2);
    }

    strong {
      font-size: 40px;
      font-weight: 900;
      color: var(--purple2);
      margin-top: 8px;
    }
    p {
      font-size: 12px;
      color: var(--dark-grey);
      margin-top: 8px;
      span {
        font-size: 12px;
        font-weight: bold;
        color: #0fba53;
        margin-top: 8px;
      }
    }
  }

  footer {
    width: 100%;
    margin-top: 15px;
    a {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      text-decoration: none;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--purple6);

      span + span {
        margin-top: 20px;
      }
    }
  }
`;

export const Notification = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  background: var(--white);
  border: 1px solid var(--mid-grey);
  border-radius: 4px;

  width: 100%;
  max-height: 690px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--green) var(--purple2);
  &::-webkit-scrollbar {
    width: 8px;
    background: var(--purple2);
    border-radius: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--green);
    border-radius: 12px;
  }

  .separatorData {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    padding: 15px 20px;
    background: var(--background);
    border-bottom: 1px solid var(--mid-grey);

    span {
      font-size: 10px;
      font-weight: 500;
      color: var(--dark-grey);
    }
  }

  header {
    padding: 25px 22px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
      font-size: 20px;
      font-weight: 900;
      line-height: 23px;
      color: var(--purple2);
    }
    button {
      background: none;
      border: none;
      text-decoration: none;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--purple6);
      transition: all 0.3s;
      &:hover {
        color: var(--purple2);
      }
    }
  }
  main {
    width: 100%;
    padding: 13px 20px;
    border-bottom: 1px solid var(--mid-grey);

    &.active {
      background: #cff1f1;
    }

    .users {
      display: flex;
      align-items: center;
    }

    .no-users {
      display: flex;
      align-items: center;
      justify-content: center;
      .not-found {
        display: flex;
        align-items: center;
        flex-direction: column;
        p {
          color: var(--purple6);
        }
        img {
          width: 80px;
          height: 80px;
        }
      }
    }

    .userImg {
      width: 54px;
      height: 54px;
      margin-right: 10px;
    }

    img {
      width: 100%;
    }
    .userInfo {
      display: flex;
      flex-direction: column;

      span {
        font-size: 12px;
        font-weight: bold;
        color: var(--purple2);
        margin-bottom: 5px;
      }
      p {
        font-size: 10px;
        color: var(--dark-grey);
      }
    }
  }
`;
