import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import UserContext from '../../contexts/UserContext';
import apiDoubts from '../../services/api';
import ButttonDefault from '../ButtonDefault';
import { Container } from './styles';

interface VProps {
  getComents?: () => void;
  consultorId?: string;
  loading: boolean;
}

export default function ComentarAreaConsultor({
  consultorId,
  getComents = () => {},
  loading,
}: VProps) {
  const { user } = useContext(UserContext);
  const params = useParams<any>();

  const [DTO, setDTO] = useState<any>({
    comentario: '',
    consultorId: consultorId,
  } as any);

  useEffect(() => {
    console.log('DTO normal', DTO);
  }, [DTO]);

  function handleOnChange(name: any, value: any) {
    if (consultorId) {
      const newDTO: any = DTO;
      newDTO[name] = value;
      setDTO({ ...newDTO });
      return;
    }
  }

  async function handleSubmitComment(e: any) {
    {
      try {
        e.preventDefault();
        if (!DTO.comentario) throw new Error('Por favor, digite um comentário');

        handleComment();
        handleOnChange('comentario', '');
      } catch (err: any) {
        toast.error(err.message, {
          className: 'toast-error',
          theme: 'colored',
        });
      }
    }
  }

  async function handleComment() {
    const response = await apiDoubts.post('/comentarios-canal', DTO);
    if (response?.data?.status === 'error') {
      toast.error(response?.data?.message);
    } else {
      toast.success('Comentário postado com sucesso', {
        className: 'toast-sucess',
        theme: 'colored',
      });
    }
    getComents();
    return;
  }

  return (
    <Container>
      <h2>Comente</h2>
      <div className="areaComment">
        <div
          className="image"
          style={{ backgroundImage: `url(${user?.foto})` }}
        />
        <div className="areaLeft">
          <h3>{user?.nickName}</h3>
          <textarea
            value={DTO?.comentario}
            className="comment"
            placeholder="Comentário..."
            onChange={(e) => {
              handleOnChange('comentario', e?.target?.value);
            }}
          />
          <div className="areaSubmit">
            <ButttonDefault
              title="Enviar"
              tipo="6"
              onClick={handleSubmitComment}
            />
          </div>
        </div>
      </div>
    </Container>
  );
}
