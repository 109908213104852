import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ButtonCopy from '../../../components/ButtonCopy';
import ButttonDefault from '../../../components/ButtonDefault';
import Inputdefault from '../../../components/Inputs/InputDefault';
import UserContext from '../../../contexts/UserContext';
import SelectOption from '../CriarConteudo/SelectOption';

import { Container } from './styles';

export default function CriarConteudo() {
  const history = useHistory();
  const [optionSelected, setOptionSelected] = useState<string>(
    'Transmissão ao vivo'
  );

  useEffect(() => {
    goToPage();
  }, [optionSelected]);

  function goToPage() {
    if (optionSelected === 'Enviar Video') history.push('/videos/criar');
  }

  const { user } = useContext(UserContext);

  console.log(user);

  return (
    <Container>
      <div className="selectAction">
        <SelectOption
          icon={true}
          opt1="Enviar Video"
          opt2="Transmissão ao vivo"
          select={(e: string) => {
            setOptionSelected(e);
          }}
          selected={optionSelected}
        />
      </div>
      <div className="main">
        <div className="preview">
          <iframe src={user.streamLink} />
        </div>
        <div className="areaInput">
          <ButtonCopy label="StreamKey do usuário" content={user?.streamKey} />
          <ButtonCopy
            label="Servidor de vídeos"
            content="rtmp://broadcast.api.video/s"
          />
        </div>
      </div>
      {/* <ButttonDefault title="Entrar ao Vivo" hover="pouco" /> */}
      <h3 className="title">
        O que você vai precisar para fazer uma transmissão
      </h3>
      <div className="requisitos">
        <div className="areaLeft">
          <h4 className="necessidades">
            No seu primeiro acesso, você irá configurar o programa OBS dessa
            maneira:
          </h4>
          <ol>
            <li>Abra o programa OBS</li>
            <li>Clique em Configurações</li>
            <li>Clique em transmissão</li>
            <li>Clique em Serviço e altere para personalizado</li>
            <li>
              Em servidor cole o{' '}
              <span className="bold">"Servidor de vídeos"</span>
            </li>
            <li>
              Em chave de transmissão cole a{' '}
              <span className="bold">"StreamKey do usuário"</span>
            </li>
            <li>Clique em aplicar e em seguida em OK</li>
            <li>
              Clique em <span className="bold">"Iniciar transmissão"</span>
            </li>
            <li>Clique no botão abaixo Entrar ao vivo</li>
          </ol>
        </div>
        <div className="areaRight">
          <h4 className="necessidades">
            Do segundo acesso em diante, faça somente os passos seguintes:
          </h4>
          <ol>
            <li>Abra o programa OBS</li>
            <li>
              Clique em <span className="bold">"Iniciar transmissão"</span>
            </li>
            <li>
              Clique no botão abaixo{' '}
              <span className="bold">Entrar ao vivo</span>
            </li>
          </ol>
        </div>
      </div>
    </Container>
  );
}
