import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import ButttonDefault from '../../components/ButtonDefault';
import CardCampanha from '../../components/Cards/CardCampanha';
import UserContext from '../../contexts/UserContext';
import apiDoubts from '../../services/api';
import { Container } from './styles';
import EmptyProducts from '../../components/EmptyProducts';
import { IconLoading } from '../../components/Icons';

export default function Campanha() {
  const [campaings, setCampaings] = useState<any[]>([]);
  const [loadCampaings, setLoadingCampaings] = useState<boolean>(true);

  const history = useHistory();
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user?.id) getCampaing();
  }, [user]);

  async function getCampaing() {
    try {
      if (!campaings) setLoadingCampaings(true);
      await apiDoubts.get(`campanha-listar`).then((response: any) => {
        setLoadingCampaings(false);
        if (user?.tipo === 'empresa') {
          setCampaings(response?.data?.data?.empresa.reverse());
        } else if (user?.tipo === 'agencia') {
          setCampaings(response?.data?.data?.agencia.reverse());
        }
      });
    } catch (err: any) {
      setLoadingCampaings(false);
      toast.error('Erro ao receber infos do profile.', {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  return (
    <Container>
      <div className="headerCampanhas"> 
        <div className="areaLeft">
          <h1 className="titleCampanhas">Campanhas</h1>
          <h2 className="subtitleCampanhas">
            Campanhas para aumentar o seu engajamento
          </h2>
        </div>
        <ButttonDefault
          tipo="3"
          hover="medio"
          title={'Criar nova Campanha'}
          onClick={() => {
            history.push('/campanha/criar');
          }}
        />
      </div>
      <main className="mainCardsContainer">
        {loadCampaings && (
          <span className="loadingWrapper">
            <IconLoading size={40} />
          </span>
        )}
        {campaings?.length > 0 &&
          !loadCampaings &&
          campaings.map((row: any, key: number) => (
            <CardCampanha
              key={key}
              data={row}
              getNewCampaingArray={() => {
                getCampaing();
              }}
            />
          ))}
        {campaings.length === 0 && !loadCampaings && (
          <div className="emptyWrapper">
            <EmptyProducts
              fullscreen={false}
              description="Cadastre uma campanha e ela aparecerá aqui !!!"
              title="Ops, você ainda não possui nenhuma campanha"
            />
          </div>
        )}
      </main>
    </Container>
  );
}
