import styled from "styled-components";

interface props{
    selected?: string
    opt1: string
    opt2: string
}

export const Container = styled.div<props>`
  width: 790px;
  display: flex;
  margin-top: 15px;

  h3 {
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    color: var(--paleta1B);
    cursor: pointer;
  }

  svg {
    cursor: pointer;
  }

  a {
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 5px;
  }

  .enviarVideo {
    gap: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 50%;
    border-bottom: ${({ selected, opt1 }) =>
      selected === opt1
        ? '4px solid var(--paleta1B)'
        : '1px solid var(--paleta1B)'};
    padding-bottom: 14px;
  }
  .transmissao {
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding-bottom: 14px;
    border-bottom: ${({ selected, opt2 }) =>
      selected === opt2
        ? '4px solid var(--paleta1B)'
        : '1px solid var(--paleta1B)'};
  }

  @media (max-width: 1000px){
    max-width: 90vw
  }
`;