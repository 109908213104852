import styled from 'styled-components';

interface props {
  selected?: boolean;
  disp?: string;
  temporary?: boolean;
  busyHour?:boolean
}

export const Container = styled.div<props>`
  width: 100%;
  height: 54px;
  background-color: ${({ busyHour }) =>
    busyHour  ? 'var(--paleta1C)' : 'var(--warning)'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  p {
    margin: 0px;
    padding: 0px;
    font-size: 13px;
    line-height: 15px;
    font-weight: 500;
    color: var(--branco);
  }
  .close {
    display: ${({ disp }) => (disp === 'ocupado' ? 'none' : 'flex')};
    border: none;
    background-color: transparent;
    &:active {
      transform: scale(1.1);
      transition: all 0.1s;
    }
  }
`;


