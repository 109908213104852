import styled, { css } from "styled-components";

interface Props {
  tipo?: '1' | '2' | '3' | '4' | '5' | '6'
}

export const Container = styled.button<Props>`
  background-color: var(--paleta2E);
  padding: 0 15px;
  height: 20px;

  border: none;
  border-radius: 5px;

  color: var(--branco);
  font-size: 10px;
  font-weight: 700;

  display: flex;
  gap: 10px;

  align-items: center;
  white-space: nowrap;

  &:hover {
    transition: all 0.2s;
    transform: scale(1.1);
  }

  ${(props) =>
    props.tipo === '2' &&
    css`
      background-color: var(--paleta2D);
    `}

  ${(props) =>
    props.tipo === '3' &&
    css`
      background-color: var(--offline);
    `}

  ${(props) =>
    props.tipo === '4' &&
    css`
      background-color: var(--paleta1A);
  `}

  ${(props) =>
    props.tipo === '5' &&
    css`
      background-color: var(--texto);
  `}

  ${(props) =>
    props.tipo === '6' &&
    css`
      background-color: transparent;
      border: 1px solid #fff;
  `}
`;