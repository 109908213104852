import styled from 'styled-components';

interface Props {
  openModal?: boolean;
  width?: string;
  scroll?: boolean
  isOpen?: boolean

}

export const ContainerOverlay = styled.div<Props>`
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  opacity: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: ${(props) => (props.openModal ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 100;
  @media (max-width: 425px) {
    height: 100vh;
    max-height: 100vh;
    margin-top: 0;
    overflow: hidden;
  }
`;

export const Container = styled.div<Props>`
  background-color: var(--white);
  padding: 20px;
  border-radius: 10px;
  width: clamp(250px, 700px, 70vw);
  height: clamp(400px, 450px, 80vh);
  overflow-y: scroll;
  .header{
    display: flex;
    justify-content: space-between;
    .titleModal{
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: var(--paleta2C)
    }
    .close{
        border: none;
        background-color: transparent;
    }
  }
  .main{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .areaInput{
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 5px;
      .textInputSendMessage{
        font-size: 13px;
        font-weight: 700;
        line-height: 15px;
        color: var(--paleta2C)
      }
      .input {
        padding: 10px;
        border: none;
        width: 100%;
        max-width: 758px;
        background-color: var(--background2);
        border-radius: 10px;
        height: 150px;
        display: flex;
        outline: none;
        resize: none;
      }
    }
    .areaInputComment{
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-top: ${({isOpen})=>isOpen ? '150px' : '0px'};
      .textInputSendMessage{
        font-size: 13px;
        font-weight: 700;
        line-height: 15px;
        color: var(--paleta2C)
      }
      .input {
        padding: 10px;
        border: none;
        width: 100%;
        max-width: 758px;
        background-color: var(--background2);
        border-radius: 10px;
        height: 150px;
        display: flex;
        outline: none;
        resize: none;
      }
    }

    .areaInputMessage{
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-top: 0px;
      .textInputSendMessage{
        font-size: 13px;
        font-weight: 700;
        line-height: 15px;
        color: var(--paleta2C)
      }
      .input {
        padding: 10px;
        border: none;
        width: 100%;
        max-width: 758px;
        background-color: var(--background2);
        border-radius: 10px;
        height: 150px;
        display: flex;
        outline: none;
        resize: none;
      }
    }
    .buttons{
      display: flex;
      gap: 10px;
      justify-content: flex-end;
    }
  }

  @media (max-width: 590px){
    width: 90vw;
  }
`;

export const ModalContacts = styled.div<Props>`
position: absolute;
top: 0;
left: 0;
width: 100%;
background-color: var(--background2);
border-radius: 0 0 5px 5px;
z-index: 90;
max-height: ${({isOpen}) => isOpen ? '155px' : '0px'};
display: ${({isOpen}) => isOpen ? 'unset' : 'none'};
margin-top: 55px;
overflow-y: scroll;
margin-bottom: 20px;
.cardContact{
  padding: 5px 20px;
  background-color: transparent;
  display: flex;
  gap: 10px;
  cursor: pointer;
  height: 70px;
  align-items: center;
  &:hover{
    background-color: var(--paleta1B);
    .contactName{
      color: var(--branco);
    }
  }
  .imageProfile{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: var(--branco);
    background-size: cover;
    background-position: center;
  }
  .contactName{
    font-size: 13px;
    font-weight: 700;
    line-height: 15px;
    color: var(--paleta1B);
    
  }
}

`
