import { IconTransmissao, IconVideo } from '../../../../components/Icons';
import { Container } from './styles';

interface SProps {
  select?: any;
  selected?: string;
  opt1: string;
  opt2: string;
  icon?: boolean;
}

export default function SelectOption({
  select,
  selected,
  opt1,
  opt2,
  icon,
}: SProps) {
  return (
    <Container selected={selected} opt1={opt1} opt2={opt2}>
      <div className="enviarVideo">
        <a onClick={() => select(opt1)}>
          {icon === true && <IconVideo color={'#069CD1'} />}
          <h3>{opt1}</h3>
        </a>
      </div>
      <div className="transmissao">
        <a
          onClick={() => {
            select(opt2);
          }}
        >
          {icon && <IconTransmissao />}
          <h3>{opt2}</h3>
        </a>
      </div>
    </Container>
  );
}
