import styled from 'styled-components';

interface Props {
  openModal?: boolean;
  width?: string;
  scroll?: boolean;
  heigth?: number;
}

export const ContainerOverlay = styled.div<Props>`
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  opacity: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: ${(props) => (props.openModal ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 100;

  @media (max-width: 425px) {
    height: 100vh;
    max-height: 100vh;
    margin-top: 0;
    overflow: hidden;
  }
`;

export const Container = styled.div<Props>`
  background-color: var(--white);
  padding: 20px;
  border-radius: 10px;
  width: ${({ width }) => (width ? width : '540px')};
  max-width: 45%;
  min-height: ${({ heigth }) => `${heigth}px`};
  max-height: 80vh;
  overflow-y: ${({ scroll }) => (scroll === false ? 'hidden' : 'scroll')};
  .header {
    display: flex;
    justify-content: space-between;
    .titleModal {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: var(--paleta2C);
    }
    .close {
      border: none;
      background-color: transparent;
    }
  }
  .main {
    margin-top: 10px;
  }

  @media (max-width: 768px) {
    max-width: 90vw;
    width: 90vw;
  }

  @media(min-width: 2000px){
    max-width: 650px
  }
`;
