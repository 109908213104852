import { useEffect, useState } from 'react';
import apiDoubts from '../../services/api';
import { Container, Card, Title, Profile } from './styles';
import Moment from 'react-moment';
import { IconVerify } from '../Icons';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

interface props {
  direction: 'row' | 'colum' | any;
  empresaId?: string;
}

export default function TopConsultores({ direction, empresaId }: props) {
  const [topConsultores, setTopConsultores] = useState<any>([]);
  const history = useHistory();

  useEffect(() => {
    if (empresaId) {
      getConsultoresWithEmpresa(empresaId);
    } else {
      getConsultores();
    }
  }, []);

  async function getConsultores() {
    await apiDoubts
      .get('/consultor/get-all?ordem=seguidores&limit=5')
      .then((response) => {
        setTopConsultores(response?.data?.data);
      });
  }

  async function getConsultoresWithEmpresa(id: string) {
    await apiDoubts.get(`/empresa/get-by-id/${id}`).then((response: any) => {
      setTopConsultores(response?.data?.data?.consultores);
    });
  }

  function handleConsultor(consultor: any) {
    history.push(`/consultor/visualizar/canal/${consultor.id}`);
  }

  return (
    <Container direction={direction}>
      {direction && (
        <div className="areaTitle">
          <Title>
            {direction === 'row' ? 'Nossos Consultores' : 'Mais seguidos'}
          </Title>
        </div>
      )}

      <div className="areaCard">
        {topConsultores?.docs?.map((row: any, key: number) => (
          <div
            className="topConsultores"
            key={key}
            onClick={() => {
              handleConsultor(row);
            }}
          >
            <Profile style={{ backgroundImage: `url(${row.foto})` }} />
            <div className="TextArea">
              <Card>
                <span className="nick">{row.nickName}</span>
                <IconVerify />
              </Card>
              <span className="moment">
                  {moment(row.createdAt).locale('pt-br').fromNow()}
              </span>
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
}
