import styled from 'styled-components';

interface props {
  active: boolean;
}

export const Container = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  .header {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    .selectConsultor {
      font-size: 28px;
      line-height: 42px;
      color: var(--paleta2C);
      font-weight: 700;
    }
    .more {
      display: flex;
      gap: 10px;
      .arrow {
        background-color: transparent;
        border: none;
        transition: all 0.2s;
        &:hover {
          transform: scale(1.2);
          transition: all 0.2s;
        }
      }
    }
  }

  .consultoresCard {
    display: flex;
    overflow: hidden;
    max-width: 100%;
    margin-right: 10px;
    position: relative;

    .cards {
      transition: all 0.5s;
      display: flex;
      gap: 20px;
    }
  }
`;

export const CardConsultorAgenda = styled.button<props>`
  width: 200px;
  background-color: transparent;
  border: none;
  outline: none;
  background-color: ${({ active }) =>
    active ? 'var(--paleta2A)' : 'var(--background2)'};
  padding: 15px;
  border: 2px solid var(--paleta2A);
  -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  .image {
    width: 80px;
    height: 80px;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border: 2px solid var(--branco);
  }
  .text {
    font-size: 13px;
    line-height: 15px;
    font-weight: 700;
    color: ${({ active }) => (active ? 'var(--branco)' : 'var(--paleta1A)')};
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
