import styled from "styled-components";

export const Container = styled.div`
height: 40px;
display: flex;
background-color: var(--background2);
padding: 5px;
padding-left: 10px;
gap: 10px;
border-radius: 5px;
align-items: center;
.iconButton{
display: flex;
}
.linkButton{
background-color: transparent;
border: none;
width: 100%;
outline: none;
}
`