import {useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AnunciosVerticais from '../../../components/Anuncio/AnunciosVerticais';
import Hashtags from '../../../components/Hashtags';
import ListPosts from '../../../components/Posts/ListPosts';
import TopConsultores from '../../../components/TopConsultores';
import { Container, ContainerContent } from './styles';

const Explorar: React.FC = (props: any) => {

  let { explorar }:any = useParams();
  
  const [hashtag, setHashtag] = useState<string>(explorar);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [hashtag]);

  return (
    <Container>    
      <ContainerContent>
        <div className="section-left">
          <ListPosts
            order={'popular'}
            where={{
              interesse: explorar,
            }}
          />
          <div className="areaButton"></div>
        </div>

        <div className="section-right">
          <AnunciosVerticais
            count={1}
            where={{
              interesse: explorar !== 'em-alta' ? explorar : '',
              search: '',
              hashs: [],
            }}
          />
          <TopConsultores direction={'colum'} />
          
        </div>

      </ContainerContent>
    </Container>
  );
};

export default Explorar;
