import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  .left {
    width: 60px;
    .imagePerfil {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-position: center;
      background-size: cover;
      background-color: var(--background2);
    }
  }
  .right {
    width: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    gap: 10px;
    .nameUser {
      font-size: 13px;
      font-weight: 700;
      line-height: 15px;
      color: var(--paleta1A);
    }
    .newMessage {
      border: none;
      width: 100%;
      height: min-content;
      background-color: var(--background2);
      resize: none;
      border-radius: 5px;
      padding-left: 10px;
      padding-top: 10px;
      outline: none;
      border: 1px solid transparent;
      outline-color: var(--paleta1A);
      font-size: 12px;
      line-height: 22px;

      height: clamp(unset, 100px, 10%);

      &:focus {
        transition: 0.2s;
        transform: scale(1.006);
      }
    }
  }
`;
