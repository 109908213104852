import apiDoubts from '../../../services/api';
import * as S from './styles';
import { IconCalendar } from '../../../components/Icons';
import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import ButttonDefault from '../../../components/ButtonDefault';
import { AxiosResponse } from 'axios';
import CardConsultores from './CardConsultores';
import moment from 'moment';
import EmptyPosts from '../../../components/EmptyPosts';

export default function AgendaEmpresa() {
  const [consultorias, setConsultorias] = useState<any[]>([]);
  const [dateToFilter, setDateToFilter] = useState<string>('');
  const [typeHoursToFilter, setTypeHoursToFilter] = useState<
    'all' | 'free' | 'paid'
  >('all');

  useEffect(() => {
    if (consultorias.length === 0) {
      getHoursEmpresa();
    }
  }, [consultorias.length]);

  async function getHoursEmpresa() {
    await apiDoubts
      .get(`/empresa/consultoria`)
      .then((response: AxiosResponse<any>) => {
        let arrayPaidHours = response.data.data.horariosGratis.map(
          (row: any) => {
            row.type = 'gratis';
            return row;
          }
        );

        let arrayFreeHours = response.data.data.horariosPagos.map(
          (row: any) => {
            row.type = 'pago';
            return row;
          }
        );

        setConsultorias([...arrayPaidHours, ...arrayFreeHours]);
      });
  }

  function handleDate(e: string) {
    setDateToFilter(moment(e).format());
  }

  function filterConsultancies(
    dateToFilter: string,
    consultanciesToFilter: Array<any>,
    typeHour: 'all' | 'free' | 'paid'
  ): Array<any> | null | undefined {
    let filteredConsultanciesArray: Array<any> = [...consultanciesToFilter];

    filteredConsultanciesArray = filteredConsultanciesArray.filter(
      (row: any) => {
        if (typeHour === 'all') return row;
        else if (typeHour === 'free' && row.type === 'gratis') {
          return row;
        } else if (typeHour === 'paid' && row.type === 'pago') {
          return row;
        } else return false;
      }
    );

    if (dateToFilter === '' || !dateToFilter) {
      return filteredConsultanciesArray;
    } else if (dateToFilter) {
      let filterConsultancies = filteredConsultanciesArray.filter(
        (row: any) => {
          if (
            moment(row.data).format('DD-MM-YYYY') ===
            moment(dateToFilter).format('DD-MM-YYYY')
          ) {
            return row;
          } else {
            return false;
          }
        }
      );
      return filterConsultancies;
    }
  }

  return (
    <S.Container>
      <div className="content">
        <div className="areaLeft">
          <h1 className="title">Agenda</h1>

          <span className="date">
            <IconCalendar />
            {dateToFilter ? (
              <span className="selectedHour">
                {moment(dateToFilter)
                  .locale('pt-br')
                  .format('DD [de] MMMM [de] YYYY')}
                <ButttonDefault
                  title={`Ver todos os horários`}
                  onClick={() => {
                    setTypeHoursToFilter('all');
                    setDateToFilter('');
                  }}
                />
              </span>
            ) : (
              `Todos os horários ${
                typeHoursToFilter === 'free'
                  ? ' - Grátis'
                  : typeHoursToFilter === 'paid'
                  ? '- Pagos'
                  : ''
              }`
            )}
          </span>
          <div className="cardConsultoria">
            {filterConsultancies(
              dateToFilter,
              consultorias,
              typeHoursToFilter
            ) &&
            (filterConsultancies(dateToFilter, consultorias, typeHoursToFilter) as any)?.length > 0 ? (
              filterConsultancies(
                dateToFilter,
                consultorias,
                typeHoursToFilter
              )?.map((row: any, key: number) => (
                <CardConsultores key={key} completeData={row} />
              ))
            ) : (
              <EmptyPosts
              title='Você não possui nenhuma consultoria agendada'
              description='Marque uma consultoria e ela aparecerá nessa página...'
              />
            )}
          </div>
        </div>
        <div className="areaRight">
          <Calendar
            minDate={new Date()}
            onChange={(e: any) => {
              handleDate(e);
            }}
            locale="pt-br"
            // defaultValue={moment(moment(dateFormat2).isValid() ? dateFormat2 : new Date()).toDate()}
          />
          <div className="areaButton">
            <ButttonDefault
              title={'Horários para Consultoria Paga'}
              tipo="5"
              onClick={() => {
                setTypeHoursToFilter('paid');
              }}
              full={true}
              hover="pouco"
            />
            <ButttonDefault
              title={'Horários para Consultorias Grátis'}
              tipo="9"
              onClick={() => {
                setTypeHoursToFilter('free');
              }}
              full={true}
              hover="pouco"
            />
          </div>
        </div>
      </div>
    </S.Container>
  );
}
