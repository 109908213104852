import styled, { css } from 'styled-components';

interface props{
    type: string
    color?: string
}

export const Container = styled.div`
  position: relative;
  .icon {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 10px;
    margin-right: 10px;
  }
  
`;

export const Input = styled.input<props>`
  border: none;
  background-color: var(--background2);
  width: 100%;
  height: 40px;
  border-radius: 5px;
  padding-left: 15px;
  outline: none;

  &:focus {
    ${({ color }) =>
      color === null &&
      css`
        border: 1px solid var(--paleta1C);
      `}
  }


  ${({ color }) =>
    color === 'branco' &&
    css`
      background-color: var(--branco);
    `}

    ${({ color }) =>
    color === 'transparent' &&
    css`
      background-color: transparent;
      border: 1px solid #D0D5DD;
    `}

  ${({ color }) =>
    color === 'azul' &&
    css`
      background-color: var(--branco);
      border: 1px solid var(--paleta1C);
    `}

    ${({ color }) =>
    color === 'vermelho' &&
    css`
      background-color: var(--branco);
      border: 1px solid var(--offline);
    `}
`;
