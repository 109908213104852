import styled from 'styled-components';

export const LoginContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  background: white;

  .toast-error {
    background: var(--purple2);
  }
  .progress {
    background: var(--mid-grey);
  }
`;

export const LoginCardLeft = styled.div`
  min-width: 40%;
  max-width: 40%;
  height: 100vh;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  
  background: var(--white);
  
  /* overflow-y: auto;
  scrollbar-width: thin;         
  scrollbar-color: var(--green) var(--purple2);
  &::-webkit-scrollbar {
    width: 8px;
    background: var(--purple2);
    border-radius: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--green);
    border-radius: 12px;
  } */

  .logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 35px;
    height: 100px;
    width: 100%;
    border-bottom: 1px solid var(--mid-grey);
  }

  .middle {
    height: 795px;
    padding: 35px;
    .text {
      text-align: left;
      margin-bottom: 30px;
      h1 {
        color: var(--purple2);
        font-weight: 900;
        margin-bottom: 30px;
        font-size: 2.6vw;
        line-height: 3rem;
      }
      .bold {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 20px;
      }
      p {
        color: var(--purple6);
        font-size: 16px;
        line-height: 22px;
      }
    }

    .input {
      form {
        display: flex;
        flex-direction: column;
        input {
          width: 100%;
          height: 55px;
          border-radius: 4px;
          border: 1px solid var(--green);
          background: rgba(15, 186, 186, 0.1);
          color: var(--dark-grey);
          font-size: 16px;
          padding: 15px;
          margin-bottom: 15px;
          &:focus {
            border: 2px solid var(--green);
            outline: none;
          }
        }
        button {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 15px;
          margin-top: 15px;
          width: 100%;
          height: 55px;
          background: var(--green);
          border: none;
          border-radius: 4px;
          color: var(--white);
          font-size: 18px;
          font-weight: 800;
          transition: ease-in-out .2s;
          
          &:hover,
          &:focus,
          &:active {
            background: var(--purple2);
          }
        }
        .linkArea {
          margin-top: 10px;
          width: 100%;
          display: flex;
          justify-content: center;
          gap: 20px;
          width: 100%;
          justify-content: space-between;
          .link {
            font-size: 15px;
            text-decoration: none;
          }
        }
        .pass {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 55px;
          border-radius: 4px;
          border: 1px solid var(--green);
          background: rgba(15, 186, 186, 0.1);
          color: var(--dark-grey);
          font-size: 16px;
          padding: 15px;
          margin-bottom: 15px;
          .input-icon {
            width: 100%;
            height: 55px;
            border-radius: 0;
            border: none;
            background: none;
            color: var(--dark-grey);
            font-size: 16px;
            padding: 0;
            margin-bottom: 0;
            &:focus {
              border: none;
              outline: none;
            }
          }

          .pass-icon {
            display: flex;
            align-items: center;
            justify-content: center;

            cursor: pointer;
          }
        }
      }
      p {
        color: var(--green);
        font-weight: 700;
        font-size: 19px;
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    padding: 35px;
    width: 100%;
    height: 130px;
    background: var(--purple2);
    bottom: 0;
    p {
      font-weight: 400px;
      font-size: 16px;
      color: var(--purple6);
    }
    a {
      color: var(--white);
      margin-left: 5px;
    }
    span {
      margin-left: 5px;
    }
  }

  @media (max-width: 425px) {
    margin-top: 40px;
    .middle {
      .text {
        .bold {
          font-size: 1.4rem;
        }
        p {
          font-size: 1.15rem;
        }
      }
    }
  }
`;

export const LoginCardRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: calc(100% - 435px);
  height: 100vh;
  background: var(--background);

  .user-photo {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;    
  }

  @media (max-width: 425px) {
    display: none;
  }
`;
