import styled, { css } from 'styled-components';

interface UserProps {
  user: boolean;
}

export const Container = styled.div`
  padding: 20px;
  height: calc(100vh - 214px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;

  .areaButtonsAceitarOuRecusar {
    width: 100%;
    display: flex;
    gap: 10px;
    margin-top: 15px;
  }
  .areaCardsConversa {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: calc(100% - 94px);
    overflow-y: scroll;

    ::-webkit-scrollbar {
      display: none;
    }
  }
  .messageArea {
    display: flex;
    gap: 10px;
    align-items: flex-end;
  }
`;

export const ElementLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--background2);
  margin-top: 25px;
  margin-bottom: 25px;
`;

export const CardConversa = styled.div<UserProps>`
  display: flex;
  gap: 10px;
  .textCardMailBox {
    width: calc(100% - 60px);
    .topTextCardMailBox {
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      color: var(--texto);
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      .nickNameUser {
        color: var(--paleta1A);
        font-size: 13px;
        font-weight: 700;
        line-height: 15px;
      }
    }
    .bottomTextCardMailBox {
      border: 1px solid var(--background2);
      padding: 10px;
      border-radius: 10px 0 10px 10px;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      color: var(--texto);
    }
  }
  .photo {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    background-color: var(--background2);
  }

  ${({ user }) =>
    user === false &&
    css`
      flex-direction: row-reverse;
      .textCardMailBox {
        .topTextCardMailBox {
          justify-content: flex-start;
        }
      }
    `}
`;
