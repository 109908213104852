import styled from "styled-components";

export const Container = styled.div`
    padding: 40px;
    display: flex;
`

export const ContentLeft = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    width: 70%;

    @media(max-width: 1199px) {
        width: 100%;
    }
`;

export const Views = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  background: var(--white);
  border: 1px solid var(--mid-grey);
  border-radius: 8px;

  padding: 25px 22px;
  width: 100%;
  height: 400px;
  
  @media(max-width: 950px) {
    width: 100%;
  }

  header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
      font-size: 20px;
      font-weight: 900;
      line-height: 23px;
      color: var(--purple2);
    }
    a {
      text-decoration: none;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--purple6);
    }
  }

  main {
    width: 100%;
    height: 310px;
  }
`

export const Campaign = styled.div`
    background: var(--white);
    border: 1px solid var(--mid-grey);
    width: 100%;
    height: 74%;
    padding: 25px 22px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    header {
        h2 {
            font-size: 20px;
            font-weight: 900;
            line-height: 23px;
            color: var(--purple2);
            margin-bottom: 20px;
        }
        p {
            color: #7d7d7d;
            font-size: 16px;
            margin-bottom: 20px;
        }
    }
    table {
        overflow-x: auto;
        margin-bottom: 20px;
        width: 100%;
        border-collapse: collapse;
        thead {
            background: #E5E5E5;
            height: 60px;
            .campaign {
                color: var(--purple2);
                font-size: 12px;
                font-weight: bold;
            }
            th {
                color: var(--purple2);
                font-size: 12px;
                font-weight: normal;
                text-align: center;
                padding: 10px;
            }
        }
        tbody {
            height: 60px;
            tr {
                width: 100%;
                height: 60px;
                border-bottom: 1px solid var(--mid-grey);
                &:hover {
                    background: #F4F4F4;
                }
                .consultant {
                    color: var(--green);
                }
            }
            td {
                color: #7d7d7d;
                text-align: center;
            }
        }
    }
    footer {
        display: flex;
        justify-content: space-between;
        a {
            text-decoration: none;
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
            color: var(--purple6);
        }
    }
`

export const ContentRight = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 30%;
  height: 100%;
  gap: 20px;
  margin-left: 20px;

  @media(max-width: 1199px) {
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    gap: 20px;
    margin-left: 0px;
    margin-top: 20px;
  }

  @media(max-width: 950px) {
    flex-direction: column;
  }
`

export const BestConsultants = styled.div`
    display: flex;
    flex-direction: column;
    background: var(--white);
    width: 100%;
    height: 100%;
    background: var(--white);
    border: 1px solid var(--mid-grey);
    border-radius: 8px;
    padding: 25px 22px;
    h2 {
        font-size: 20px;
        font-weight: 900;
        line-height: 23px;
        color: var(--purple2);
        margin-bottom: 10px;
    }
    span {
        font-size: 12px;
        color: var(--green);
    }
    a {
      text-decoration: none;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--purple6);
      &:hover {
          color: var(--purple4);
      }
    }
    .buttons {
        display: flex;
        margin-top: 20px;
        margin-bottom: 25px;
        button {
            padding: 15px;
            margin-left: 15px;
            font-size: 10px;
            background: transparent;
            border-radius: 4px;
            border: 1px solid var(--purple6);
            color: var(--purple6);
            text-transform: uppercase;
            width: 100%;
            outline: none;
            &:active {
                background: var(--purple6);
                color: var(--white);
            }
            &:first-child {
                margin-left: 0;
            }
        }
    }  
`

export const Chart = styled.div`
    .consultor {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding: 20px 15px;
        height: 100px;
        width: 100%;
        cursor: pointer;
        &:hover {
            background: #F4F4F7;
        }
        .photo {
            width: 20%;
            height: 100%;
            text-align: center;
            img {
                border-radius: 8px;
                width: 60px;
            }
        }
        .details {
            width: 60%;
            height: 100%;
            align-self: flex-start;
            p {
                color: var(--green);
            }
            .follow {
                display: flex;
                span,
                p {
                    color: #7d7d7d;
                    font-size: 10px;
                    margin-right: 5px;
                }
            }
        }
        .arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20%;
            height: 100%;
        }
    }    
`