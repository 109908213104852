import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 20px;

`;

export const Left = styled.div`
  width: calc(100% - 227px);
  display: flex;
  flex-direction: column;
  gap: 10px;

  .topArea {

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .titleOportunidades {
      font-size: 28px;
      line-height: 42px;
      font-weight: 700;
      color: var(--paleta2C);
    }
    .inputContainer{
        width: 40%;
    }
  }

  .topSectionOportunidades {
    .textSectionOportunidades {
      .DescriptionOportunidades {
        font-size: 22px;
        line-height: 27px;
        font-weight: 600;
        color: var(--paleta1C);
      }
    }
  }
  .mainSectionOportunidades {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(248px, 1fr));
    gap: 10px;
  }
  .buttonWrapper {
    align-self: flex-end;
  }

  @media (max-width: 800px) {

    .topArea {
      flex-direction: column;
      gap: 10px;
      .inputContainer {
        width: 100%;
      }
    }

    width: 100%;
  }
`;

export const Right = styled.div`
  width: 227px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 500px) {
    display: none;
  }
`;
