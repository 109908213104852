import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useHistory, useParams } from 'react-router-dom';
import HeaderHomeAgencia from '../../../components/Agencia/HeaderHome';
import CardCaseConfig from '../../../components/Cards/CardCaseConfig';
import { IconVerify } from '../../../components/Icons';
import { ElementLine } from '../../../components/MailBox/ChatmailBox/styles';
import apiDoubts from '../../../services/api';
import { Card, Container, Profile } from './styles';
import moment from 'moment';

export default function VisualizarAgencia() {
  const [agenciaData, setAgenciaData] = useState<any>();
  const [casesDeSucesso, setCasesDeSucesso] = useState<Array<any>>([]);

  const params: any = useParams();
  const history: any = useHistory();
  useEffect(() => {
    if (params.id) {
      getAgenciaByID(params?.id);
      getCasesDeSucesso(params?.id);
    }
  }, [params]);

  async function getAgenciaByID(agenciaId: string) {
    await apiDoubts
      .get(`/agencia/get-by-id/${agenciaId}`)
      .then((response: any) => {
        setAgenciaData(response?.data?.data[0]);
      });
  }

  async function getCasesDeSucesso(agenciaId: string) {
    await apiDoubts
      .get(`/case/agencia?agenciaId=${agenciaId && agenciaId}`)
      .then((response: any) => {
        setCasesDeSucesso(response.data.data);
      });
  }

  console.log(agenciaData);

  return (
    <Container id="ContainerVisualizarAgencia">
      <HeaderHomeAgencia data={agenciaData} />
      <ElementLine />
      <h2 className="TextNossosConsultores">Nossos Consultores</h2>
      <div className="divConsultoresAgencia">
        {agenciaData?.consultores?.map((row: any, key: number) => (
          <div
            className="topConsultores"
            key={key}
            onClick={() => {
              history.push(`/consultor/visualizar/canal/${row.id}`);
            }}
          >
            <Profile style={{ backgroundImage: `url(${row.foto})` }} />
            <div className="TextArea">
              <Card>
                <span className="nick">{row.nickName}</span>
                <IconVerify />
              </Card>
              <span className="moment">
                {moment(row.createdAt).locale('pt-br').fromNow()}
              </span>
            </div>
          </div>
        ))}
      </div>
      <ElementLine />
      <h2 className="TextNossosConsultores">Nossas Empresas</h2>
      <div className="divConsultoresAgencia">
        {agenciaData?.empresas?.map((row: any, key: number) => (
          <div
            className="topConsultores"
            key={key}
            onClick={() => {
              history.push(`/empresa/visualizar/canal/${row.id}`);
            }}
          >
            <Profile style={{ backgroundImage: `url(${row.foto})` }} />
            <div className="TextArea">
              <Card>
                <span className="nick">{row.nickName}</span>
                <IconVerify />
              </Card>
              <span className="moment">
                {moment(row.createdAt).locale('pt-br').fromNow()}
              </span>
            </div>
          </div>
        ))}
      </div>
      <ElementLine />
      <h2 className="TextNossosConsultores">Nossas Cases de sucesso</h2>

      <div className="areaCasesDeSucesso">
        {casesDeSucesso.map((row: any, key: number) => (
          <CardCaseConfig
            data={row}
            deleteCaseDeSucesso={() => {}}
            isConfig={false}
          />
        ))}
      </div>
    </Container>
  );
}
