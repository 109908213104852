import { Container } from "./styles";

interface dataProps{
    children:any
}

export default function CardResumo({children}:dataProps){
    return(
        <Container>
            {children}
        </Container>
    )
}