import { useContext, useEffect, useRef, useState } from 'react';
import Inputdefault from '../../../components/Inputs/InputDefault';
import { Container } from './styles';
import apiDoubts from '../../../services/api';
import ButttonDefault from '../../../components/ButtonDefault';
import { toast } from 'react-toastify';
import { IconClose, IconCorrect } from '../../../components/Icons';
import TrocaSenha from '../../../components/MudarSenha';
import { useDebouncedCallback } from 'use-debounce';
import UserContext from '../../../contexts/UserContext';
import moment from 'moment';

interface interessesProps {
  listaInteresses: any[];
}

interface userProps {
  login: string;
  foto: string;
  dataNascimento: string;
  nickName: string;
  telefone: string;
  termosUso: boolean;
}

export default function InformacoesPessoais() {
  const { user } = useContext<any>(UserContext);

  const [passwordIsCorrect, setPasswordIsCorrect] = useState<boolean | any>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<any>(null);
  const [alertRepetida, setAlertRepetida] = useState<any>(null);
  const imageRef = useRef<any>();
  const [DTO, setDTO] = useState<userProps>({
    login: '',
    foto: '',
    dataNascimento: '',
    nickName: '',
    telefone: '',
    termosUso: true,
  });

  const [DTOInteresses, setDTOInteresses] = useState<interessesProps>({
    listaInteresses: [],
  });

  const [senhaAtual, setSenhaAtual] = useState<string>();
  const [DTOSenha, setDTOSenha] = useState<any>({
    senha: '',
    senhaRepetida: '',
  });
  const [redefiningPassword, setRedefiningPassword] = useState<boolean>(false);

  const debounced = useDebouncedCallback(() => {
    handlePasswordIsCorrect();
  }, 600);

  const debouncedNewPassword = useDebouncedCallback(() => {
    ValidaSenha(DTOSenha.senha);
  }, 600);

  const debouncedNewPasswordRepetida = useDebouncedCallback(() => {
    ValidaSenhaRepetida(DTOSenha.senha);
  }, 600);

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    setPasswordIsCorrect(null);
    if (senhaAtual) {
      debounced();
    }
  }, [senhaAtual]);

  useEffect(() => {
    setAlert(null);
    if (DTOSenha?.senha) {
      debouncedNewPassword();
    }
  }, [DTOSenha?.senha]);

  useEffect(() => {
    setAlertRepetida(null);
    if (DTOSenha?.senhaRepetida) {
      debouncedNewPasswordRepetida();
    }
  }, [DTOSenha?.senhaRepetida]);

  async function getUser() {
    await apiDoubts.get(`profile`).then((response: any) => {
      try {
        let data = response.data.data;
        let newDTO = {
          login: data?.login,
          nickName: data?.nickName,
          foto: data?.foto,
          dataNascimento: data?.dataNascimento,
          telefone: data?.telefone,
          termosUso: true,
        };
        setDTO(newDTO);

        let interesses: any[] = [];

        response?.data?.data?.interesses?.map((row: any) => {
          let newInteresse = {
            label: row?.titulo,
            id: row?._id,
          };
          interesses.push(newInteresse);
        });

        handleOnChangeInteresse('listaInteresses', interesses);
      } catch (err: any) {
        toast.error(err.message, {
          className: 'toast-error',
          theme: 'colored',
        });
      }
    });
  }

  function handleOnChange(name: any, value: any) {
    const newDTO: any = DTO;
    newDTO[name] = value;
    setDTO({ ...newDTO });
    return;
  }

  function ValidaSenha(senha: string) {
    var numeros = /([0-9])/;
    var alfabeto = /([A-Z])/;
    var chEspeciais = /([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/;
    if (senha.length < 6) {
      setAlert('A senha deve ter mais que 6 digitos');
      return;
    }

    if (!senha.match(numeros)) {
      setAlert('A senha precisa ter pelo menos 1 número');
      return;
    }

    if (!senha.match(alfabeto)) {
      setAlert('A senha precisa ter pelo menos 1 letra maiuscula');
      return;
    }

    if (!senha.match(chEspeciais)) {
      setAlert('A senha precisa ter pelo menos 1 caracter especial');
      return;
    }
  }

  function ValidaSenhaRepetida(senha: string) {
    if (DTOSenha?.senha !== DTOSenha?.senhaRepetida) {
      setAlertRepetida('As 2 senhas precisam ser iguais');
    }
  }

  function handleOnChangeInteresse(name: any, value: any) {
    const newDTO: any = DTOInteresses;
    newDTO[name] = value;
    setDTOInteresses({ ...newDTO });
    return;
  }

  function handleOnChangeSenha(name: any, value: any) {
    const newDTO: any = DTOSenha;
    newDTO[name] = value;
    setDTOSenha({ ...newDTO });
    return;
  }

  async function handleSubmit() {
    if (user?.tipo === 'usuario') {
      setLoading(true);

      try {
        if (!DTO?.nickName)
          throw new Error('Por favor, Preencha o campo de Nome');
        if (!DTO?.login)
          throw new Error('Por favor, Preencha o campo de Email');
        if (!DTO?.dataNascimento)
          throw new Error('Por favor, Preencha o campo de data de nascimento');
        if (!DTO?.telefone)
          throw new Error('Por favor, Preencha o campo de Telefone');

        let arrayInteresses: any[] = [];

        DTOInteresses?.listaInteresses.map((row: any) => {
          arrayInteresses.push(row.id);
        });

        let interesses = {
          listaInteresses: arrayInteresses,
        };

        await apiDoubts
          .put(`/cadastros/update`, DTO)
          .then(async (response: any) => {
            await apiDoubts
              .put(`/cadastros/interesses`, interesses)
              .then((response: any) => {
                toast.success('Perfil atualizado com sucesso', {
                  className: 'toast-sucess',
                  theme: 'colored',
                });
              });
          });

        setLoading(false);
      } catch (err: any) {
        toast.error(err.message, {
          className: 'toast-error',
          theme: 'colored',
        });

        setLoading(false);
      }
    } else if (user?.tipo === 'consultor') {
      setLoading(true);

      const DTOConsultor = {
        login: DTO?.login,
        foto: DTO?.foto,
        dataNascimento: DTO?.dataNascimento,
        nickName: DTO.nickName,
        telefone: DTO.telefone,
        termosUso: true,
      };

      try {
        if (!DTOConsultor?.nickName)
          throw new Error('Por favor, Preencha o campo de Nome');
        if (!DTOConsultor?.login)
          throw new Error('Por favor, Preencha o campo de Email');
        if (!DTOConsultor?.dataNascimento)
          throw new Error('Por favor, Preencha o campo de data de nascimento');
        if (!DTOConsultor?.telefone)
          throw new Error('Por favor, Preencha o campo de Telefone');

        let arrayInteresses: any[] = [];

        await apiDoubts
          .put(`/consultor/editar`, DTOConsultor)
          .then(async (response: any) => {
            toast.success('Perfil atualizado com sucesso', {
              className: 'toast-sucess',
              theme: 'colored',
            });
          });

        setLoading(false);
      } catch (err: any) {
        toast.error(err.message, {
          className: 'toast-error',
          theme: 'colored',
        });

        setLoading(false);
      }
    } else if (user.tipo === 'empresa') {
      setLoading(true);

      try {
        if (!DTO?.nickName?.length)
          throw new Error('Por favor, Preencha o campo de Nome');
        if (!DTO?.login)
          throw new Error('Por favor, Preencha o campo de Email');
        if (!DTO?.dataNascimento)
          throw new Error('Por favor, Preencha o campo de data de nascimento');
        if (!DTO?.telefone)
          throw new Error('Por favor, Preencha o campo de Telefone');

        await apiDoubts
          .put(`/empresa/editar/${user._id}`, DTO)
          .then((response) => {
            toast.success('Empresa atualizada com sucesso', {
              className: 'toast-sucess',
              theme: 'colored',
            });
            setLoading(false);
          });
      } catch (err: any) {
        toast.error(err.message, {
          className: 'toast-error',
          theme: 'colored',
        });

        setLoading(false);
      }
    }
  }

  function formatarData(e: any) {
    handleOnChange('dataNascimento', e?.target?.value);
  }

  function maskPhone(params: any) {
    if (params.length <= 14) {
      let value = params;
      value = value.replace(/\D/g, '');
      value = value.replace(/(\d{2})(\d)/, '($1) $2');
      value = value.replace(/(\d{5})(\d{4})/, '$1-$2');
      handleOnChange('telefone', value);
    }
  }

  async function handleSubmitSenha() {
    setRedefiningPassword(true);
    try {
      if (!senhaAtual) throw new Error('Digite a senha atual');
      if (!passwordIsCorrect) throw new Error('A senha atual esta incorreta');
      if (!DTOSenha.senha) throw new Error('Por favor, insira a nova senha');
      if (alert) throw new Error('A senha atual contém algum erro...');
      if (!DTOSenha.senhaRepetida)
        throw new Error('Por favor, insira a nova senha novamente');
      if (DTOSenha.senha !== DTOSenha.senhaRepetida)
        throw new Error('Digite duas senhas idênticas');
      if (
        senhaAtual === DTOSenha.senha &&
        senhaAtual === DTOSenha.senhaRepetida
      )
        throw new Error('Você digitou a mesma senha em todos os campos');

      await apiDoubts
        .post(`senha-alterar`, {
          senha: DTOSenha.senha,
          confirmeSenha: DTOSenha.senhaRepetida,
        })
        .then((response: any) => {
          setDTOSenha({ senha: '', senhaRepetida: '' });
          setSenhaAtual('');
          setRedefiningPassword(false);
          toast.success('Senha atualizada com sucesso', {
            className: 'toast-sucess',
            theme: 'colored',
          });
        });
    } catch (err: any) {
      setRedefiningPassword(false);
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  async function handlePasswordIsCorrect() {
    await apiDoubts
      .post(`senha-validar`, {
        senha: senhaAtual,
      })
      .then((response: any) => {
        if (response?.data?.data === false) {
          setPasswordIsCorrect(false);
        } else if (response?.data?.data === true) {
          setPasswordIsCorrect(true);
        }
      })
      .catch((error: any) => {
        console.log(error.message);
      });
  }

  useEffect(() => {
    console.log('mudou a senha atual', senhaAtual);
  }, [senhaAtual]);

  return (
    <Container
      imageHeigth={imageRef?.current?.clientWidth}
      buttonDeleteOn={!!DTO.foto}
    >
      <div className="main">
        <div className="areaLeft">
          <h2 className="title">Configurações de usuário</h2>
          <h3 className="subtitle">Nome</h3>
          <Inputdefault
            placeholder="Seu Nome"
            tipo="text"
            change={(e: any) => {
              handleOnChange('nickName', e.target.value);
            }}
            value={DTO?.nickName}
          />

          {/* <h3 className="subtitle">Email</h3>
          <Inputdefault
            placeholder="Seu Email aqui"
            tipo="text"
            change={(e: any) => {
              handleOnChange('login', e.target.value);
            }}
            value={DTO?.login}
          /> */}

          <h3 className="subtitle">Data de Nascimento</h3>

          <input
            type="date"
            className="DatePickerInput"
            onChange={(e: any) => {
              formatarData(e);
            }}
            value={moment(DTO?.dataNascimento).format('YYYY-MM-DD')}
          />
          {/* <Inputdefault
            placeholder="Exemplo: 26/03/2000"
            tipo="text"
            change={(e: any) => {
              formatarData(e);
            }}
            value={DTO?.dataNascimento}
          /> */}

          <h3 className="subtitle">Telefone</h3>
          <Inputdefault
            placeholder="Exemplo: (99) 99999-9999"
            tipo="text"
            change={(e: any) => {
              maskPhone(e.target.value);
            }}
            value={DTO?.telefone}
          />
          <div className="areaButton">
            <ButttonDefault
              full={true}
              title="Salvar informações"
              tipo="6"
              onClick={() => {
                handleSubmit();
              }}
              icon={loading ? 'Loading-White' : ''}
              hover={'pouco'}
            />

            <TrocaSenha>
              <div className="areaInputs">
                <div className="cardInputSenha">
                  <p className="label">Digite a senha atual</p>
                  <Inputdefault
                    change={(e: any) => {
                      setSenhaAtual(e?.target?.value);
                    }}
                    placeholder={'Digite a senha atual'}
                    tipo={'text'}
                    key={'TextInputSenha'}
                    value={senhaAtual}
                    color={
                      senhaAtual
                        ? passwordIsCorrect === null
                          ? null
                          : passwordIsCorrect
                          ? 'azul'
                          : 'vermelho'
                        : null
                    }
                    icon={
                      senhaAtual ? (
                        passwordIsCorrect ===
                        null ? null : passwordIsCorrect ? (
                          <IconCorrect />
                        ) : (
                          <IconClose color={'#E62965'} />
                        )
                      ) : null
                    }
                  />
                </div>

                <div className="cardInputSenha">
                  <p className="label">Digite a nova senha</p>
                  <Inputdefault
                    change={(e: any) => {
                      handleOnChangeSenha('senha', e?.target?.value);
                    }}
                    placeholder={'Digite a nova senha'}
                    tipo={'text'}
                    key={'TextInputSenha'}
                    value={DTOSenha?.senha}
                    color={
                      DTOSenha?.senha
                        ? alert !== null
                          ? 'vermelho'
                          : alert === null
                          ? 'azul'
                          : 'vermelho'
                        : null
                    }
                    icon={
                      DTOSenha?.senha?.length >= 6 && alert === null ? (
                        <IconCorrect />
                      ) : alert !== null ? (
                        <IconClose color={'#E62965'} />
                      ) : null
                    }
                  />
                  <div className="alert">
                    {alert && <p className="alertSenha">{alert}</p>}
                  </div>
                </div>

                <div className="cardInputSenha">
                  <p className="label">Repita a nova senha</p>
                  <Inputdefault
                    change={(e: any) => {
                      handleOnChangeSenha('senhaRepetida', e?.target?.value);
                    }}
                    placeholder={'Repita a nova senha'}
                    tipo={'text'}
                    key={'TextInputSenha'}
                    value={DTOSenha?.senhaRepetida}
                    color={
                      DTOSenha?.senhaRepetida
                        ? alertRepetida !== null
                          ? 'vermelho'
                          : alertRepetida === null
                          ? 'azul'
                          : 'vermelho'
                        : null
                    }
                    icon={
                      DTOSenha?.senhaRepetida?.length >= 6 &&
                      alertRepetida === null ? (
                        <IconCorrect />
                      ) : alertRepetida !== null ? (
                        <IconClose color={'#E62965'} />
                      ) : null
                    }
                  />
                  <div className="alert">
                    {alertRepetida && (
                      <p className="alertSenha">{alertRepetida}</p>
                    )}
                  </div>
                </div>

                <ButttonDefault
                  title={'Salvar Senha'}
                  full={true}
                  onClick={() => {
                    handleSubmitSenha();
                  }}
                  icon={redefiningPassword ? 'Loading' : ''}
                  hover={'pouco'}
                  tipo={'3'}
                />
              </div>
            </TrocaSenha>
          </div>
        </div>
      </div>
    </Container>
  );
}
