import { IDTOAdsense } from '../../../pages/Campanha/Criar';
import ButttonDefault from '../../ButtonDefault';
import { IconImage, IconVideo } from '../../Icons';
import CardTypesAdsense from '../CardTypeAdsense';
import { Container } from './styles';

interface props {
  handleOnChangeDTO: (key: string, typeAdsense: string) => void;
  data: IDTOAdsense;
  setPage: (page: string) => void;
}

export default function TypeAdsense({
  handleOnChangeDTO,
  setPage,
  data,
}: props) {
  const cards = [
    {
      icon: <IconVideo size={'30'} color={'#0661C7'} />,
      label: 'Anúncio em vídeo',
      description: 'Indicado para alcançar público já ativo no site',
    },
    {
      icon: <IconImage />,
      label: 'Anúncio em Imagens',
      description: 'Indicado para alcançar público que navega no site',
    },
  ];
  return (
    <Container>
      {cards?.map((row: any, key: number) => (
        <CardTypesAdsense
          key={key}
          data={row}
          onClick={() => {
            handleOnChangeDTO(
              'tipo',
              row?.label === 'Anúncio em vídeo' ? 'video' : 'banner'
            );
          }}
        />
      ))}
      <div className="areaButtons">
        {data.tipo && (
          <ButttonDefault
            title={'Avançar'}
            tipo={'6'}
            onClick={() => {
              setPage('Informações');
            }}
          />
        )}
      </div>
    </Container>
  );
}
