import styled from "styled-components";

export const Container = styled.div`
    height: 32px;
    select {
        border: 1px solid var(--purple2);
        border-radius: 4px;
        margin-left: 16px;
        height: 32px;
        outline: none;
        padding: 5px;
    }
    option {
        font-size: 14px;
        font-weight: 500;
        line-height: 11px;
        
        text-transform: uppercase;
        background-color: transparent;
        color: var(--purple2);
    }
`;