import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import HeaderEmpresa from '../../../components/Empresa/HeaderEmpresa';
import { IconArrowLeft, IconArrowRight } from '../../../components/Icons';
import WarningLogout from '../../../components/WarningLogout';
import UserContext from '../../../contexts/UserContext';
import apiDoubts from '../../../services/api';
import { Element } from '../Canal/styles';
import { CardConsultorAgenda, Container } from './styles';
import MarcarConsultoriaEmpresa from './MarcarConsultoria';
import { AxiosResponse } from 'axios';

export default function EmpresaConsultoria() {
  const { user } = useContext(UserContext);
  const [consultores, setConsultores] = useState<any>();
  const [consultorSelecionado, setConsultorSelecionado] = useState<any>('');
  const cardRef = useRef<any>();
  const refSlider = useRef<any>();
  const [left, setLeft] = useState<any>(0);
  const WidthMove = 200;
  const params = useParams<any>();

  useEffect(() => {
    if (params.id) {
      getConsultoresWithEmpresa(params?.id);
      getConsultoriaByEmpresa(params?.id);
    }
  }, [params.id]);

  useEffect(() => {
    if (left !== 0) {
      const limit = cardRef.current.clientWidth - refSlider.current.clientWidth;
      if (left > 0) setLeft(0);
      if (left * -1 >= limit) setLeft(limit * -1);
    }
  }, [left]);

  // useEffect(() => {
  //   if (consultores) {
  //     setConsultorSelecionado(consultores[0]?.id);
  //   }
  // }, [consultores]);

  async function getConsultoresWithEmpresa(id: string) {
    await apiDoubts.get(`/empresa/get-by-id/${id}`).then((response: any) => {
      setConsultores(response?.data?.data?.consultores);
    });
  }

  async function getConsultoriaByEmpresa(empresaId: string) {
    await apiDoubts
      .get(`/empresa/consultoria`)
      .then((response: AxiosResponse) => {
        console.log(response);
      });
  }

  function handleConsultorSelecionado(id: string) {
    setConsultorSelecionado(id);
  }

  function handlePrevScroll() {
    try {
      if (left >= 0) throw 'Você já está na posição 0.';
      setLeft(left + WidthMove);
    } catch (err: any) {}
  }

  function handleNextScroll() {
    try {
      const limit =
        cardRef?.current?.clientWidth - refSlider?.current?.clientWidth;
      if (left * -1 >= limit) throw 'Você já está no final do slider.';
      setLeft(left - WidthMove);
    } catch (err: any) {
      console.log(err);
    }
  }

  return (
    <Container>
      <HeaderEmpresa id={params.id} page="consultoria" />
      <Element />

      {user.login ? (
        <>
          <div className="header">
            <h2 className="selectConsultor">Selecione o Consultor</h2>
            <div className="more">
              <button className="arrow" onClick={handlePrevScroll}>
                <IconArrowLeft size={18} />
              </button>
              <button className="arrow" onClick={handleNextScroll}>
                <IconArrowRight size={18} />
              </button>
            </div>
          </div>

          <div className="consultoresCard" ref={refSlider}>
            <div
              className="cards"
              style={{ transform: `translateX(${left}px)` }}
              ref={cardRef}
            >
              {consultores?.map((row: any, key: number) => {
                console.log(row)
                return (
                  <CardConsultorAgenda
                    active={consultorSelecionado === row?.id}
                    onClick={() => {
                      handleConsultorSelecionado(row?.id);
                    }}
                  >
                    <div
                      className="image"
                      style={{ backgroundImage: `url(${row?.foto})` }}
                    />
                    <div className="text">{row?.nickName}</div>
                  </CardConsultorAgenda>
                );
              })}
            </div>
          </div>

          <div className="consultoria">
            <MarcarConsultoriaEmpresa consultorId={consultorSelecionado} />
          </div>
        </>
      ) : (
        <WarningLogout fullscreen={false} />
      )}
    </Container>
  );
}
