import styled from "styled-components";

export const Container = styled.div`
width: 100%;
background-color:var(--background2);
border-radius: 10px;
padding: 15px 20px;

p{
    font-size: 13px;
    line-height: 14px;
    font-size: 400;
    color: var(--paleta2C);
}
`

export const MostrarMais = styled.button`
background-color: transparent;
border: 1px solid var(--paleta2D);
padding: 3px 15px;
border-radius: 5px;
margin-top: 12px;

font-size: 10px;
color: var(--paleta2D);
line-height: 15px;
font-weight: 700;
`