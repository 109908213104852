import { ButtonHTMLAttributes } from "react";
import { Container } from "./styles";

interface props extends ButtonHTMLAttributes<HTMLButtonElement>{
    data:any
}

export default function CardTypesAdsense({data, ...rest}:props){
    return(
        <Container {...rest}>
            <div className="areaIconCardTypesAdsense">
                {data?.icon}
            </div>
            <div className="areaTextCardTypesAdsense">
                <p className="TitleCardTypesAdsense">{data?.label}</p>
                <p className="DescriptionCardTypesAdsense">{data?.description}</p>
            </div>
        </Container>
    )
}