import styled from "styled-components";

export const Container = styled.video`
border-radius: 10px;
width: 100%;
height: 249px;
background-position: center;
background-size: cover;

@media(max-width){
  min-width: 350px;
}

@media (max-width: 440px){
  max-height: 180px
}
`

export const NotVideo = styled.div`
  border-radius: 10px;
  background-color: var(--paleta1C);
  width: 100%;
  max-width: 520px;
  min-height: 249px;
  display: flex;
  align-items: center;
  justify-content: center; 
`;