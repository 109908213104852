import { useState } from 'react';
import { Container, MostrarMais } from './styles';

interface DProps {
  descricao?: string;
}

export default function VideoDescricao({ descricao }: DProps) {
  const [videoOffset, setVideoOffset] = useState<number>(200);

  return (
    <Container> 
      <p>{descricao?.substring(0, videoOffset)}</p>
      {descricao && descricao?.length > 200 && (
        <MostrarMais
          onClick={() => {
            if (videoOffset === 200) {
              setVideoOffset(100000000);
            } else {
              setVideoOffset(200);
            }
          }}
        >
          {videoOffset === 200 ? 'MOSTRAR MAIS' : 'MOSTRAR MENOS'}
        </MostrarMais>
      )}
    </Container>
  );
}
