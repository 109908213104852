import { createRef, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Inputdefault from '../../../components/Inputs/InputDefault';
import { Container } from './styles';
import apiDoubts from '../../../services/api';
import ButttonDefault from '../../../components/ButtonDefault';
import { toast } from 'react-toastify';
import InputHash from '../../../components/Inputs/InputHash';
import ListInsteressesStateless from '../../../components/Interesses/ListInteresses';
import {
  IconClose,
  IconCorrect,
  IconTrash,
  IconUser,
} from '../../../components/Icons';
import AddimageCirculo from '../../../components/ButtonFile/AddImagemCirculo';
import TrocaSenha from '../../../components/MudarSenha';
import { useDebouncedCallback } from 'use-debounce';
import bucket from '../../../services/bucket';

interface interessesProps {
  listaInteresses: any[];
}

interface userProps {
  login: string;
  foto: string;
  dataNascimento: string;
  nickName: string;
  sexo: string;
  telefone: string;
}

export default function CriarConteudo() {
  const history = useHistory();
  const [modal, setModal] = useState<boolean>(false);
  const [passwordIsCorrect, setPasswordIsCorrect] = useState<boolean | any>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [categ, setCateg] = useState<string>('');
  const [alert, setAlert] = useState<any>(null);
  const [alertRepetida, setAlertRepetida] = useState<any>(null);
  const imageRef = useRef<any>();
  const [DTO, setDTO] = useState<userProps>({
    login: '',
    foto: '',
    dataNascimento: '',
    nickName: '',
    sexo: '',
    telefone: '',
  });

  const [DTOInteresses, setDTOInteresses] = useState<interessesProps>({
    listaInteresses: [],
  });

  const [senhaAtual, setSenhaAtual] = useState<string>('');
  const [DTOSenha, setDTOSenha] = useState<any>({
    senha: '',
    senhaRepetida: '',
  });

  const debounced = useDebouncedCallback(() => {
    handlePasswordIsCorrect();
  }, 600);

  const debouncedNewPassword = useDebouncedCallback(() => {
    ValidaSenha(DTOSenha.senha);
  }, 600);

  const debouncedNewPasswordRepetida = useDebouncedCallback(() => {
    ValidaSenhaRepetida(DTOSenha.senha);
  }, 600);

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    setPasswordIsCorrect(null);
    if (senhaAtual) {
      debounced();
    }
  }, [senhaAtual]);

  useEffect(() => {
    setAlert(null);
    if (DTOSenha?.senha) {
      debouncedNewPassword();
    }
  }, [DTOSenha?.senha]);

  useEffect(() => {
    setAlertRepetida(null);
    if (DTOSenha?.senhaRepetida) {
      debouncedNewPasswordRepetida();
    }
  }, [DTOSenha?.senhaRepetida]);

  async function getUser() {
    await apiDoubts.get(`cadastros/profile`).then((response: any) => {
      console.log(response.data.data.interesses);
      try {
        let data = response.data.data;
        let newDTO = {
          login: data?.login,
          nickName: data?.nickName,
          foto: data?.foto,
          dataNascimento: data?.dataNascimento,
          sexo: data?.sexo,
          telefone: data?.telefone,
        };
        setDTO(newDTO);

        let interesses: any[] = [];

        response?.data?.data?.interesses?.map((row: any) => {
          let newInteresse = {
            label: row?.titulo,
            id: row?._id,
          };
          interesses.push(newInteresse);
        });

        handleOnChangeInteresse('listaInteresses', interesses);
      } catch (err: any) {
        toast.error(err.message, {
          className: 'toast-error',
          theme: 'colored',
        });
      }
    });
  }

  function handleOnChange(name: any, value: any) {
    const newDTO: any = DTO;
    newDTO[name] = value;
    setDTO({ ...newDTO });
    return;
  }

  function handleOnChangeInteresse(name: any, value: any) {
    const newDTO: any = DTOInteresses;
    newDTO[name] = value;
    setDTOInteresses({ ...newDTO });
    return;
  }

  function handleOnChangeSenha(name: any, value: any) {
    const newDTO: any = DTOSenha;
    newDTO[name] = value;
    setDTOSenha({ ...newDTO });
    return;
  }

  function ValidaSenha(senha: string) {
    var numeros = /([0-9])/;
    var alfabeto = /([A-Z])/;
    var chEspeciais = /([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/;

    if (senha.length < 6) {
      setAlert('A senha deve ter mais que 6 digitos');
      return;
    }

    if (!senha.match(numeros)) {
      setAlert('A senha precisa ter pelo menos 1 número');
      return;
    }

    if (!senha.match(alfabeto)) {
      setAlert('A senha precisa ter pelo menos 1 letra maiuscula');
      return;
    }

    if (!senha.match(chEspeciais)) {
      setAlert('A senha precisa ter pelo menos 1 caracter especial');
      return;
    }
  }

  function ValidaSenhaRepetida(senha: string) {
    if (DTOSenha?.senha !== DTOSenha?.senhaRepetida) {
      setAlertRepetida('As 2 senhas precisam ser iguais');
    }
  }

  function handleCategorias(e: any) {
    let catSelected = DTOInteresses.listaInteresses;
    if (catSelected?.length === 0) {
      setCateg('');
      handleOnChangeInteresse('listaInteresses', [e]);
    } else {
      const index = catSelected.indexOf(e);
      if (index !== -1) {
        catSelected.splice(index, 1);
      } else {
        catSelected.push(e);
      }
      setCateg('');
      handleOnChange('listaInteresses', catSelected);
    }
  }

  async function cadastraInteresse(interesse: string) {
    return await apiDoubts
      .post(`/interesses`, {
        titulo: interesse,
        hexadecimal: '#000000',
      })
      .then((response) => {
        return response?.data?.data;
      });
  }

  async function categoriaAdd(e: any) {
    try {
      if (!e) throw new Error('Por favor digite algo antes de cadastrar');

      await apiDoubts
        .get(`/interesses?titulo=${categ}`)
        .then(async (response: any) => {
          if (response?.data?.data.length === 0) {
            const cadastra = await cadastraInteresse(e);
            let newInteresse = {
              id: cadastra?._id,
              label: cadastra?.titulo,
            };
            let newDTO = DTOInteresses.listaInteresses;
            newDTO.unshift(newInteresse);
            handleOnChangeInteresse('listaInteresses', newDTO);
            setCateg('');
          } else if (response?.data?.data.length > 0) {
            DTOInteresses.listaInteresses.forEach((row: any) => {
              if (row.label === e) {
                setCateg('');
                throw new Error('Esse interesse já foi adicionado');
              }
            });

            response?.data?.data.map(async (response: any) => {
              console.log(response, e);

              if (response.titulo === e) {
                console.log('adiconando palavra', response.titulo, e);
                let newInteresse = {
                  id: response?._id,
                  label: response?.titulo,
                };

                let newDTO = DTOInteresses.listaInteresses;
                newDTO.unshift(newInteresse);
                handleOnChangeInteresse('listaInteresses', newDTO);
                setCateg('');
              }
            });

            let index = response.data.data.indexOf(e);
            if (index !== -1) {
              const cadastra = await cadastraInteresse(e);
              console.log('CADASTRAR', e, cadastra);
              let newInteresse = {
                id: cadastra?._id,
                label: cadastra?.titulo,
              };
              let newDTO = DTOInteresses.listaInteresses;
              newDTO.unshift(newInteresse);
              handleOnChangeInteresse('listaInteresses', newDTO);
              setCateg('');
              return;
            }
          }
        });
    } catch (err: any) {
      setLoading(false);
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  async function getThumb(file: any) {
    let formData = new FormData();
    formData.append('file', file.target.files[0]);
    const response = await bucket.post(`/upload`, formData);
    handleOnChange('foto', response.data.arquivo);
    console.log(response);
  }

  async function handleSubmit() {
    setLoading(true);

    try {
      if (!DTO.nickName) throw new Error('Por favor, Preencha o campo de Nome');
      if (!DTO.login) throw new Error('Por favor, Preencha o campo de Email');
      let arrayInteresses: any[] = [];

      DTOInteresses?.listaInteresses.map((row: any) => {
        arrayInteresses.push(row.id);
      });

      let interesses = {
        listaInteresses: arrayInteresses,
      };

      console.log(interesses);

      await apiDoubts
        .put(`/cadastros/update`, DTO)
        .then(async (response: any) => {
          await apiDoubts
            .put(`/cadastros/interesses`, interesses)
            .then((response: any) => {
              console.log(response);
              toast.success('Perfil atualizado com sucesso', {
                className: 'toast-sucess',
                theme: 'colored',
              });
            });
        });

      setLoading(false);
    } catch (err: any) {
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });

      setLoading(false);
    }
  }

  function formatarData(e: any) {
    if (e.target.value.length < 11) {
      var v = e.target.value.replace(/\D/g, '');
      v = v.replace(/(\d{2})(\d)/, '$1/$2');
      v = v.replace(/(\d{2})(\d)/, '$1/$2');
      e.target.value = v;
      handleOnChange('dataNascimento', v);
    }
  }

  function maskPhone(params: any) {
    if (params.length <= 14) {
      let value = params;
      value = value.replace(/\D/g, '');
      value = value.replace(/(\d{2})(\d)/, '($1) $2');
      value = value.replace(/(\d{5})(\d{4})/, '$1-$2');
      handleOnChange('telefone', value);
    }
  }

  async function handleSubmitSenha() {
    try {
      if (!senhaAtual) throw new Error('Digite a senha atual');
      if (!passwordIsCorrect) throw new Error('A senha atual esta incorreta');
      if (!DTOSenha.senha) throw new Error('Por favor, insira a nova senha');
      if (alert) throw new Error('A senha atual contém algum erro...');
      if (!DTOSenha.senhaRepetida)
        throw new Error('Por favor, insira a nova senha novamente');
      if (DTOSenha.senha !== DTOSenha.senhaRepetida)
        throw new Error('Digite duas senhas idênticas');
      if (
        senhaAtual === DTOSenha.senha &&
        senhaAtual === DTOSenha.senhaRepetida
      )
        throw new Error('Você digitou a mesma senha em todos os campos');

      await apiDoubts
        .post(`senha-alterar`, {
          senha: DTOSenha.senha,
          confirmeSenha: DTOSenha.senhaRepetida,
        })
        .then((response: any) => {
          setDTOSenha({ senha: '', senhaRepetida: '' });
          setSenhaAtual('');
          toast.success('Senha atualizada com sucesso', {
            className: 'toast-sucess',
            theme: 'colored',
          });
        });
    } catch (err: any) {
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  async function handlePasswordIsCorrect() {
    await apiDoubts
      .post(`senha-validar`, {
        senha: senhaAtual,
      })
      .then((response: any) => {
        if (response?.data?.data === false) {
          setPasswordIsCorrect(false);
        } else if (response?.data?.data === true) {
          setPasswordIsCorrect(true);
        }
      })
      .catch((error: any) => {
        console.log(error.message);
      });
  }

  return (
    <Container
      imageHeigth={imageRef?.current?.clientWidth}
      buttonDeleteOn={!!DTO.foto}
    >
      <div className="main">
        <div className="areaLeft">
          <h2 className="title">Configurações de usuário</h2>
          <h3 className="subtitle">Nome</h3>
          <Inputdefault
            placeholder="Seu Nome"
            tipo="text"
            change={(e: any) => {
              handleOnChange('nickName', e.target.value);
            }}
            value={DTO?.nickName}
          />

          {/* <h3 className="subtitle">Email</h3>
          <Inputdefault
            placeholder="Seu Email aqui"
            tipo="text"
            change={(e: any) => {
              handleOnChange('login', e.target.value);
            }}
            value={DTO?.login}
          /> */}

          <h3 className="subtitle">Data de Nascimento</h3>
          <Inputdefault
            placeholder="Exemplo: 26/03/2000"
            tipo="text"
            change={(e: any) => {
              formatarData(e);
            }}
            value={DTO?.dataNascimento}
          />

          <h3 className="subtitle">Telefone</h3>
          <Inputdefault
            placeholder="Exemplo: (99) 99999-9999"
            tipo="text"
            change={(e: any) => {
              maskPhone(e.target.value);
            }}
            value={DTO?.telefone}
          />

          <div style={{ zIndex: 2 }}>
            <h3 className="subtitle">Quais tipos de vídeos você quer ver ?</h3>

            <InputHash
              add={() => {
                categoriaAdd(categ);
              }}
              placeholder="Ex: Carro"
              tipo="text"
              change={(e: any) => {
                setCateg(e.target.value);
              }}
              value={categ}
            />

            {/* <SelectDefaultSearch
              tipo="2"
              value={{ label: 'Selecione...', value: 'selecione' }}
              options={categorias}
              onChange={(e: any) => {
                handleCategorias(e);
              }}
              searchOptions={() => {
                console.log('buscar');
              }}
            /> */}

            {DTOInteresses.listaInteresses?.length > 0 && (
              <ListInsteressesStateless
                tipo="object"
                data={DTOInteresses.listaInteresses}
                remove={(e: any) => {
                  handleCategorias(e);
                }}
              />
            )}
          </div>
        </div>
        <div className="areaRight">
          <h2 className="title">Sua foto</h2>
          <div
            className="image"
            style={{
              backgroundImage: `url(${
                DTO.foto
                  ? DTO.foto
                  : 'https://cdn-icons-png.flaticon.com/512/149/149071.png'
              })`,
            }}
            ref={imageRef}
          >
            {DTO.foto ? (
              <button
                className="removeImage"
                onClick={() => {
                  handleOnChange('foto', '');
                }}
              >
                <IconTrash />
              </button>
            ) : (
              <button className="addImage">
                <AddimageCirculo
                  file={DTO.foto}
                  placeholder="Selecionar Arquivo"
                  tipo="file"
                  change={(e: any) => getThumb(e)}
                />
              </button>
            )}
          </div>

          <div className="areaButton">
            <ButttonDefault
              full={true}
              title="Salvar informações"
              tipo="6"
              onClick={() => {
                handleSubmit();
              }}
              icon={loading ? 'Loading' : ''}
              hover={'pouco'}
            />
            <TrocaSenha>
              <div className="areaInputs">
                <div className="cardInputSenha">
                  <p className="label">Digite a senha atual</p>
                  <Inputdefault
                    change={(e: any) => {
                      setSenhaAtual(e?.target?.value);
                    }}
                    placeholder={'Digite a senha atual'}
                    tipo={'text'}
                    key={'TextInputSenha'}
                    value={senhaAtual}
                    color={
                      senhaAtual
                        ? passwordIsCorrect === null
                          ? null
                          : passwordIsCorrect
                          ? 'azul'
                          : 'vermelho'
                        : null
                    }
                    icon={
                      senhaAtual ? (
                        passwordIsCorrect ===
                        null ? null : passwordIsCorrect ? (
                          <IconCorrect />
                        ) : (
                          <IconClose color={'#E62965'} />
                        )
                      ) : null
                    }
                  />
                </div>

                <div className="cardInputSenha">
                  <p className="label">Digite a nova senha</p>
                  <Inputdefault
                    change={(e: any) => {
                      handleOnChangeSenha('senha', e?.target?.value);
                    }}
                    placeholder={'Digite a nova senha'}
                    tipo={'text'}
                    key={'TextInputSenha'}
                    value={DTOSenha?.senha}
                    color={
                      DTOSenha?.senha
                        ? alert !== null
                          ? 'vermelho'
                          : alert === null
                          ? 'azul'
                          : 'vermelho'
                        : null
                    }
                    icon={
                      DTOSenha?.senha?.length >= 6 && alert === null ? (
                        <IconCorrect />
                      ) : alert !== null ? (
                        <IconClose color={'#E62965'} />
                      ) : null
                    }
                  />
                  <div className="alert">
                    {alert && <p className="alertSenha">{alert}</p>}
                  </div>
                </div>

                <div className="cardInputSenha">
                  <p className="label">Repita a nova senha</p>
                  <Inputdefault
                    change={(e: any) => {
                      handleOnChangeSenha('senhaRepetida', e?.target?.value);
                    }}
                    placeholder={'Repita a nova senha'}
                    tipo={'text'}
                    key={'TextInputSenha'}
                    value={DTOSenha?.senhaRepetida}
                    color={
                      DTOSenha?.senhaRepetida
                        ? alertRepetida !== null
                          ? 'vermelho'
                          : alertRepetida === null
                          ? 'azul'
                          : 'vermelho'
                        : null
                    }
                    icon={
                      DTOSenha?.senhaRepetida?.length >= 6 &&
                      alertRepetida === null ? (
                        <IconCorrect />
                      ) : alertRepetida !== null ? (
                        <IconClose color={'#E62965'} />
                      ) : null
                    }
                  />
                  <div className="alert">
                    {alertRepetida && (
                      <p className="alertSenha">{alertRepetida}</p>
                    )}
                  </div>
                </div>

                <ButttonDefault
                  title={'Salvar Senha'}
                  full={true}
                  onClick={() => {
                    handleSubmitSenha();
                  }}
                  hover={'pouco'}
                  tipo={'3'}
                />
              </div>
            </TrocaSenha>
          </div>
        </div>
      </div>
    </Container>
  );
}
