import styled from 'styled-components';

interface props {
  imageHeigth: number;
  buttonDeleteOn:boolean
}

export const Container = styled.div<props>`
  display: flex;
  flex-direction: column;
  gap: 30px;
  .selectAction {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .main {
    width: 100%;
    display: flex;
    gap: 30px;
    .areaLeft {
      width: 60%;
      height: 100%;
      .title {
        font-size: 28px;
        line-height: 30px;
        font-weight: 700;
        color: var(--paleta2C);
      }
      .subtitle {
        margin-top: 20px;
        font-size: 13px;
        line-height: 15px;
        font-weight: 700;
        color: var(--texto);
        margin-bottom: 5px;
      }
      .comment {
        width: 100%;
        height: 120px;
        background-color: var(--background2);
        margin-top: 3px;
        border: none;
        border-radius: 4px;
        padding: 10px;
        outline: none;
        resize: none;
      }
    }
    .areaRight {
      width: 40%;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 30px;
      .title {
        font-size: 28px;
        line-height: 30px;
        font-weight: 700;
        color: var(--paleta2C);
      }
      .areaButton {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: flex-end;
        padding-bottom: 50px;
        .areaInputs {
          display: flex;
          flex-direction: column;
          margin-top: 20px;
          margin-bottom: 20px;
          gap: 10px;
          width: 90%;
          margin-left: 5%;
          .label {
            font-size: 13px;
            line-height: 15px;
            font-weight: 700;
            color: var(--texto);
            margin-bottom: 5px;
          }
          .cardInputSenha{
            .alertSenha{
              color: var(--offline);
              font-size: 13px;
              font-weight: 600;
            }
          }
        }
      }
      .image {
        width: 50%;
        height: ${({ imageHeigth }) => imageHeigth && `${imageHeigth}px`};
        border-radius: 50%;
        background-position: center;
        background-size: cover;
        position: relative;

        .removeImage,
        .addImage {
          position: absolute;
          right: 0;
          bottom: 0;
          margin-right: 10px;
          margin-bottom: 10px;
          width: 40px;
          height: 40px;
          background-color: var(--warning);
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          border: none;
          outline: none;
          transition: all 0.2s;

          &:hover {
            transform: scale(1.1);
            transition: all 0.2s;
            background-color: var(--offline);
            svg {
              path {
                stroke: #fff;
              }
            }
          }
        }

        .removeImage {
          display: ${({ buttonDeleteOn }) =>
            buttonDeleteOn ? 'flex' : 'none'};
        }

        .addImage {
          display: ${({ buttonDeleteOn }) =>
            buttonDeleteOn ? 'none' : 'flex'};
        }
      }
    }

    @media (max-width: 1150px) {
      .main {
        display: flex;
        flex-direction: column;
        .areaLeft {
          width: 80%;
          .areaButton {
            padding: 0;
          }
        }
        .areaRight {
          width: 100%;
        }
      }

      @media (max-width: 800px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        .areaLeft {
          width: 100%;
          gap: 0px;
          .areaButton {
            padding: 0px;
          }
        }
        .areaRight {
          width: 100%;
          margin-top: 0;
          .cardpost {
            min-width: 100%;
          }
        }
      }
    }
  }
`;
