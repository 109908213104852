import styled from 'styled-components';

export const Container = styled.div`
  .content-user-page {
    display: flex;
    gap: 30px;
    .render {
      flex: 1;
    }
  }
  .sideRigth {
    min-width: 300px;
    .areaBanner {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
`;
