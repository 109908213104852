import {
  Route,
  Redirect
} from 'react-router-dom'

function PrivateRoute({ children, ...rest }:any) {


  return (
    <Route
        {...rest}
        render={({ location }) =>
          window.localStorage.getItem('token') ? (
              children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
    />
  )
}

export default PrivateRoute