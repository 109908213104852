import styled from "styled-components";

export const Container = styled.div`
  padding-top: 18px;
  width: calc(200px - 10px);

  h3 {
    height: 30px;
    font-size: 13px;
    line-height: 15px;
    font-weight: 700;
    color: var(--paleta2A);
    width: 100%;
    text-align: center;
  }

  .emptySchedule{
    height: 30px;
    font-size: 13px;
    line-height: 15px;
    font-weight: 700;
    color: var(--paleta2C);
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }

  .select {
    height: calc(100% - 35px);
    overflow-y: scroll;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    ::-webkit-scrollbar {
      width: 15px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: var(--paleta1C);
      border-radius: 7px;
    }
    ::-webkit-scrollbar-track {
      background-color: var(--background2);
      border-radius: 7px;
    }
  }

  @media (max-width: 800px) {
    width: 100%;
    display: flex;
    gap: 5px;

    h3 {
      display: none;
    }

    .select {
      height: 100%;
      overflow-x: scroll;
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 5px;

      ::-webkit-scrollbar {
        display: none;
      }
      
    }
  }
`;