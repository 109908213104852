import { useEffect } from 'react';
import { IconCarregaVideo } from '../Icons';
import { Container, NotVideo } from './styles';

interface VProps {
  video: any;
}

export default function VideoPreview({ video }: VProps) {
  return (
    <>
      {!video && (
        <NotVideo>
          <IconCarregaVideo />
        </NotVideo>
      )}
      {video && (
        <Container controls>
          <source src={video} type="video/mp4" />
          <source src={video} type="video/ogg" />
          <source src={video} type="video/mov" />
        </Container>
      )}
    </>
  );
}
