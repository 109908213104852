
import { IconCLoseCircle } from "../Icons";
import { Container } from "./styles";

interface props {
    data: any
    remove: (id:string)=>void
}
 
export default function VideoCardPlaylist({data, remove}:props){
    console.log(data)
    return (
      <Container onClick={()=>{remove(data.id)}}>
        <div
          className="image"
          style={{ backgroundImage: `url(${data.thumbnail})` }}
        >
            <div className="overlayRemove">
                <IconCLoseCircle color={"#fff"} size={40}/>
            </div>
        </div>
        <div className="text">
          <div className="title">{data.titulo}</div>
          <div className="description">{data.descricao}</div>
        </div>
      </Container>
    );
}