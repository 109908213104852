// React
import React, { SelectHTMLAttributes, useEffect, useState } from 'react';

// Libraries
import { Link, useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

// Styles
import * as S from './styles';

// Types
import { AxiosResponse } from 'axios';
import * as I from './types';

// Services
import apiDoubts from '../../../services/api';

// Components
import DashboardHeader from '../../../components/Campanha/Dashboard/Header';
import ChartDashboard from '../../../components/ChartDashboard';
import ButttonDefault from '../../../components/ButtonDefault';
import SelectDefault from '../../../components/Form/SelectDefault';
import { getDaysInRange } from '../../../utils/getDaysInRange';
import { getMonthInRange } from '../../../utils/getMonthInRange';
import { firstLetterToUppercase } from '../../../utils/firstLetterToUppercase';

const CampanhaDashboard = () => {
  const [currentCampaign, setCurrentCampaign] = useState<I.ICampaign>();
  const [chartStatistics, setChartStatistics] = useState<I.IChartStatistics>();
  const [filterDate, setFilterDates] = useState<number>(6);
  const [usersStatistics, setUserStatistics] = useState<I.IUserStatistics>();
  const [chartData, setChartData] = useState<any>();

  const { id }: { id: string } = useParams();
  const history = useHistory();

  useEffect(() => {
    getCampaignData(id);
    getAllStatistics(id);
    getRelevantInterests(id);
  }, [id]);

  useEffect(() => {
    handleChartData(filterDate);
  }, [chartStatistics]);

  useEffect(() => {
    handleChartData(filterDate);
  }, [filterDate]);

  useEffect(() => {
    console.log(usersStatistics);
  }, [usersStatistics]);

  async function getCampaignData(idCampaign: string) {
    try {
      const { data }: AxiosResponse<any> = await apiDoubts?.get(
        `campanha?_id=${idCampaign}`
      );

      setCurrentCampaign(data?.data?.[0]);
    } catch (err) {
      console.log(err);
    }
  }

  async function getRelevantInterests(idCampaign: string) {
    try {
      const { data } = await apiDoubts?.get(
        `/campanha/dashboard/informacao-usuarios/${idCampaign}?limit=99999`
      );

      setUserStatistics(data?.data);
    } catch (err) {
      console.log(err);
    }
  }

  async function getAllStatistics(idCampaign: string) {
    try {
      const viewsEndpoint = `/campanha/dashboard/tempo-assistido/${idCampaign}`;
      const clicksEndpoint = `/campanha/dashboard/cliques/${idCampaign}`;

      const viewsPromises = [
        apiDoubts?.get(viewsEndpoint),
        apiDoubts?.get(`${viewsEndpoint}?mes=true`),
      ];

      const clicksPromises = [
        apiDoubts?.get(clicksEndpoint),
        apiDoubts?.get(`${clicksEndpoint}?mes=true`),
      ];

      const [dailyViews, monthlyViews] = await Promise.all(viewsPromises);
      const [dailyClicks, monthlyClicks] = await Promise.all(clicksPromises);

      const {
        tempoDiarioMedioAssistido,
        visualizacoesDiarias,
        totalVisualizacoes,
      }: I.IPlayedTime = dailyViews?.data?.data;

      const monthlyView = monthlyViews?.data?.data;

      const { cliquesDiarios, totalCliques } = dailyClicks?.data?.data;
      const monthlyClick = monthlyClicks?.data?.data;

      let result: number = 0;

      for (const key in tempoDiarioMedioAssistido) {
        result += tempoDiarioMedioAssistido[key];
      }

      setChartStatistics({
        tempoMedioAssistidoDiario: tempoDiarioMedioAssistido,
        tempoMedioAssistidoMensal: monthlyView?.tempoDiarioMedioAssistido,
        tempoMedioAssistidoTotal: result,
        visualizacoesDiarias: visualizacoesDiarias,
        visualizacoesMensais: monthlyView?.visualizacoesDiarias,
        totalVisualizacoes: totalVisualizacoes,
        cliquesDiarios: cliquesDiarios,
        cliquesMensais: monthlyClick?.cliquesDiarios,
        totalCliques: totalCliques,
      });
    } catch (err) {
      console.log(err);
    }
  }

  function handleChartData(filterDays: number) {
    const isMonthly = filterDays > 30;
    const months = filterDays / 30;

    !isMonthly && getDailyStats();
    isMonthly && getMonthlyStats();

    function getDailyStats() {
      const currentDay = moment()?.format('DD-MM-YYYY');
      const filterDate = moment()
        ?.subtract(filterDays, isMonthly ? 'months' : 'days')
        ?.format('DD-MM-YYYY');

      const daysInRange = getDaysInRange(filterDate, currentDay);

      let chartDataArray: any = [];

      daysInRange?.forEach((day) => {
        if (!chartStatistics) return;

        const valorGastoView =
          (chartStatistics?.visualizacoesDiarias[day] ?? 0) * 0.2;

        const valorGastoClick =
          (chartStatistics?.cliquesDiarios[day] ?? 0) * 1.5;

        let objectData = {
          dia: day,
          valor_gasto: valorGastoView + valorGastoClick,
          cliques: chartStatistics?.cliquesDiarios[day] ?? 0,
          impressoes: chartStatistics?.visualizacoesDiarias[day] ?? 0,
        };

        chartDataArray?.push(objectData);
      });

      setChartData(chartDataArray);
    }

    function getMonthlyStats() {
      const currentMonth = moment()?.format('MM-YYYY');
      const filterDate = moment()
        ?.subtract(months, 'months')
        ?.format('MM-YYYY');

      const monthsInRange = getMonthInRange(filterDate, currentMonth);

      let chartDataArray: any = [];

      monthsInRange?.forEach((month) => {
        let objectData = {
          dia: month,
          cliques: chartStatistics?.cliquesMensais[month] ?? 0,
          impressoes: chartStatistics?.visualizacoesMensais[month] ?? 0,
        };

        chartDataArray?.push(objectData);
      });

      console.log('PASSOU AQUI', chartDataArray);

      setChartData(chartDataArray);
    }
  }

  function handleFilterChart(e: React.ChangeEvent<HTMLSelectElement>) {
    setFilterDates(parseInt(e?.currentTarget?.value));
  }

  function formatBrlPrice(value: number) {
    const format = {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    };

    return value?.toLocaleString('pt-BR', format);
  }

  const chartKeys = [
    {
      label: 'Impressões',
      dataKey: 'impressoes',
    },
    {
      label: 'Cliques',
      dataKey: 'cliques',
    },
  ];

  const impressionsMap = [
    {
      label: 'Usuários',
      value: usersStatistics?.qtdCadastrosVeiculado ?? 0,
    },
    {
      label: 'Consultores',
      value: usersStatistics?.qtdConsultoresVeiculado ?? 0,
    },
    {
      label: 'Empresas',
      value: usersStatistics?.qtdEmpresasVeiculadas ?? 0,
    },
    {
      label: 'Agências',
      value: usersStatistics?.qtdAgenciaVeiculadas ?? 0,
    },
    {
      label: 'Usuários Deslogados',
      value: usersStatistics?.qtdUsuariosDeslogadosVeiculados ?? 0,
    },
  ];

  const campaignSex: { [key: string]: string } = {
    m: 'Masculino',
    f: 'Feminino',
    todos: 'Todos',
  };

  return (
    <>
      {currentCampaign && (
        <React.Fragment>
          <DashboardHeader campaignData={currentCampaign} />
          <S.Container>
            <S.SettingsCampaign>
              <h2>{currentCampaign?.nome}</h2>
              <ButttonDefault
                title={'Editar Campanha'}
                icon="Editar"
                onClick={() => history?.push(`/campanha/editar/${id}`)}
              />
            </S.SettingsCampaign>

            <S.CardDashboard>
              <div className="cardHeader">
                <h2>Estatísticas {filterDate >= 30 ? 'Mensais' : 'Diárias'}</h2>

                <SelectDefault onChange={handleFilterChart}>
                  <option value="6">Última semana</option>
                  <option value="13">Últimos 14 dias</option>
                  <option value="29">Último mês</option>
                  <option value="160">Último 6 meses</option>
                </SelectDefault>
              </div>

              <div className="legendContainer">
                {/* <p
                  className="legend"
                  title={`Total de gasto: ${formatBrlPrice(
                    currentCampaign?.valor_gasto
                  )}`}
                >
                  <span className="line purple" />
                  Gasto
                </p> */}
                <p
                  className="legend"
                  title={`Total de Impressões: ${chartStatistics?.totalVisualizacoes}`}
                >
                  <span className="line blue" />
                  Impressões
                </p>
                <p
                  className="legend"
                  title={`Total de Cliques: ${chartStatistics?.totalCliques}`}
                >
                  <span className="line purple" />
                  Cliques
                </p>
              </div>

              <S.ChartContainer>
                <ChartDashboard
                  chartData={{
                    data: chartData,
                    keys: chartKeys,
                  }}
                  isLoading={true}
                  likes={true}
                  views={true}
                />
              </S.ChartContainer>
            </S.CardDashboard>

            <div className="multiplesColumns">
              <S.Upload>
                <header>
                  <div className="cardVideo">
                    <h2>Analytics</h2>
                    <Link to="/campanha">
                      <span>Ver todas as campanhas</span>
                    </Link>
                  </div>
                </header>

                <main>
                  <div className="videoContainer">
                    <div className="videoWrapper">
                      <img
                        src={
                          currentCampaign?.thumbnail ??
                          'https://t4.ftcdn.net/jpg/04/73/25/49/360_F_473254957_bxG9yf4ly7OBO5I0O5KABlN930GwaMQz.jpg'
                        }
                        alt="Imagem"
                        className="videoThumbnail"
                      />
                    </div>
                  </div>

                  <S.Separator />

                  <div className="Info">
                    <table>
                      <tbody>
                        <tr>
                          <td>Quantidade de Impressões</td>
                          <td className="value">
                            {chartStatistics?.totalVisualizacoes}
                          </td>
                        </tr>
                        <tr>
                          <td>Quantidade de Cliques</td>
                          <td className="value">
                            {chartStatistics?.totalCliques}
                          </td>
                        </tr>
                        <tr>
                          <td>Tipo de Campanha</td>
                          <td className="value">
                            {firstLetterToUppercase(currentCampaign?.tipo)}
                          </td>
                        </tr>
                        <tr>
                          <td>Data de Início</td>
                          <td className="value">
                            {moment(currentCampaign?.data_inicio)
                              ?.locale('pt-br')
                              ?.format('DD [de] MMMM')}
                          </td>
                        </tr>
                        <tr>
                          <td>Data de Fim</td>
                          <td className="value">
                            {moment(currentCampaign?.data_fim)
                              ?.locale('pt-br')
                              ?.format('DD [de] MMMM')}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </main>

                <S.Separator />

                <footer style={{ display: 'none' }}>
                  <Link to="#" className="first">
                    <span>Ver Analytics</span>
                  </Link>
                  <Link to="#">
                    <span>Ver Comentarios</span>
                  </Link>
                </footer>
              </S.Upload>

              <S.Analytics>
                <header>
                  <h2>Orçamento</h2>
                </header>

                <main>
                  <div style={{ display: 'flex', gap: '15px' }}>
                    <div className="seguidores">
                      <span>Total Gasto Hoje</span>
                      <strong>
                        {formatBrlPrice(currentCampaign?.gasto_dia)}
                      </strong>

                      {/* <p>
                  <span>+ {consultorInfo?.analytics?.seguidoresRecent}</span>{' '}
                  nos últimos 28 dias
                </p> */}
                    </div>
                  </div>

                  <S.Separator />

                  <div className="resume">
                    <h2>
                      <span>Valores</span> da Campanha
                    </h2>
                    <div className="Info">
                      <table>
                        <tbody>
                          <tr>
                            <td>Orçamento Total</td>
                            <td className="value">
                              {formatBrlPrice(currentCampaign?.limite_gasto)}
                            </td>
                          </tr>
                          <tr>
                            <td>Orçamento Total Diário</td>
                            <td className="value">
                              {formatBrlPrice(currentCampaign?.valor_dia)}
                            </td>
                          </tr>
                          <tr>
                            <td>Gasto de Orçamento Hoje</td>
                            <td className="value">
                              {formatBrlPrice(currentCampaign?.gasto_dia)}
                            </td>
                          </tr>
                          <tr>
                            <td>Gasto de Orçamento Total</td>
                            <td className="value">
                              {formatBrlPrice(currentCampaign?.valor_gasto)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <S.Separator />

                    <div className="Info">
                      <h2>
                        <span>Impressões</span>
                      </h2>

                      <table>
                        <tbody>
                          {impressionsMap?.map(({ label, value }, index) => (
                            <tr key={index}>
                              <td>{label}</td>
                              <td className="value">{value}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </main>

                <S.Separator />
              </S.Analytics>

              <S.Analytics>
                <header>
                  <h2>Usuários</h2>
                </header>

                <main>
                  <div style={{ display: 'flex', gap: '15px' }}>
                    <div className="seguidores">
                      <span>Idade Média de Usuários</span>
                      <strong>
                        {usersStatistics?.idadeMediaUsuario ? (
                          <>
                            {usersStatistics?.idadeMediaUsuario
                              ?.toString()
                              ?.replace('.', ',')}{' '}
                            anos
                          </>
                        ) : (
                          <>
                            <strong>Sem impressões</strong>
                          </>
                        )}
                      </strong>

                      {/* <p>
                  <span>+ {consultorInfo?.analytics?.seguidoresRecent}</span>{' '}
                  nos últimos 28 dias
                </p> */}
                    </div>
                  </div>

                  <S.Separator />

                  {!currentCampaign?.consultorId && (
                    <>
                      <div className="resume">
                        <h2>
                          <span>Interesses</span> da Campanha
                        </h2>
                        <table>
                          <tbody
                            style={{
                              display: 'flex',
                              gap: '5px',
                              flexWrap: 'wrap',
                            }}
                          >
                            {currentCampaign?.listaInteresses?.map(
                              (interest, key) => (
                                <tr key={key}>
                                  <S.Hashtag>{interest}</S.Hashtag>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                      <S.Separator />{' '}
                    </>
                  )}

                  <div className="topVideos">
                    <h2>
                      <span>Interesses</span> de usuários que visualizaram
                    </h2>
                    <table>
                      <tbody>
                        <tr>
                          {usersStatistics?.interessesMaisRelevantes?.length ? (
                            <>
                              {usersStatistics?.interessesMaisRelevantes?.map(
                                (interest, key) => (
                                  <td key={key}>
                                    <S.Hashtag>{interest?.titulo}</S.Hashtag>
                                  </td>
                                )
                              )}
                            </>
                          ) : usersStatistics?.interessesGearaisUsuarios
                              ?.length ? (
                            <>
                              {usersStatistics?.interessesGearaisUsuarios?.map(
                                (interest, key) => (
                                  <td key={key}>
                                    <S.Hashtag>{interest?.titulo}</S.Hashtag>
                                  </td>
                                )
                              )}
                            </>
                          ) : (
                            <td>
                              <span>Nenhum interesse foi encontrado.</span>
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </table>

                    <S.Separator />

                    <div className="Info">
                      <table>
                        <tbody>
                          <tr>
                            <td>Idade Máxima</td>
                            <td className="value">
                              {currentCampaign?.idade_max}
                            </td>
                          </tr>
                          <tr>
                            <td>Idade Mínima</td>
                            <td className="value">
                              {currentCampaign?.idade_mim}
                            </td>
                          </tr>
                          <tr>
                            <td>Sexo da Campanha</td>
                            <td className="value">
                              {
                                campaignSex[
                                  currentCampaign?.sexo?.toLowerCase()
                                ]
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </main>

                <S.Separator />
              </S.Analytics>
            </div>
          </S.Container>
        </React.Fragment>
      )}
    </>
  );
};

export default CampanhaDashboard;
