import { ButtonHTMLAttributes } from 'react';
import { Container } from './styles';

type BProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  tipo?: '1' | '2' | '3' | '4' | '5' | '6';
  title?: string;
  click(): void;
  icon?: any;
};

export default function ButtonSeguir({ tipo, icon, title, click }: BProps) {
  return (
    <Container
      tipo={tipo}
      onClick={() => {
        click();
      }}
    >
      {icon ?? ''}
      {title ? title : 'Seguir'}
    </Container>
  );
}
