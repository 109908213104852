import styled, { css } from 'styled-components';

interface Bprops {
  selected: boolean;
}

interface props {
  buttonDeleteOn: boolean;
  buttonDeleteOnCapa: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 100px;

  .header {
    position: relative;
    .title {
      font-weight: 700;
      font-size: 28px;
      line-height: 42px;
      color: var(--paleta2C);
    }
    .vejaMais{
      margin-top: 33px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      .textAreaVejaMais{
        font-size: 13px;
        line-height: 15px;
        font-weight: 700;
        color: var(--paleta1B);
      }
      @media (min-width: 570px){
        display: none;
      }
    }
    .buttons {
      margin-top: 10px;
      display: flex;
      overflow-x: scroll;
      gap: 25px;
      ::-webkit-scrollbar {
        display: none;
      }
    }
    .element {
      position: absolute;
      height: 2px;
      width: 100%;
      background-color: var(--paleta1B);
    }
  }
  .content {
    display: flex;
    gap: 30px;
  }
`;

export const Subtitle = styled.h3`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: var(--paleta1C);
`;

export const Button = styled.button<Bprops>`
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 5px;
  width: 250px;
  justify-content: center;
  padding-bottom: 13px;

  ${({ selected }) =>
    selected &&
    css`
      border-bottom: 4px solid var(--paleta1B);
    `}

  .textButton {
    font-size: 13px;
    line-height: 15px;
    font-weight: 700;
    color: var(--paleta1B);
  }
`;

export const Left = styled.div<props>`
  width: calc(100% - 322px);
  .videoApresentacao {
    width: 100%;
    display: flex;
    gap: 20px;
    .responsiveBanner {
    }
    .areaLeft {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 13px;
      .responsiveBanner {
        .thumb {
          width: 100%;
          height: 234px;
          background-position: center;
          background-size: cover;
          border-radius: 10px;
        }
        .bannerResp {
          display: none;
        }
      }
      .videoApresentacaoText {
        font-size: 18px;
        line-height: 27px;
        font-weight: 600;
        color: var(--paleta2C);
      }
    }
    .areaRight {
      padding-top: 33px;
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .videoTitle {
        font-size: 16px;
        line-height: 28px;
        font-weight: 700;
        color: var(--paleta2C);
      }
      .visualizacoes {
        margin-top: -10px;
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        color: var(--texto);
      }
    }
  }
  .images {
    margin-top: 20px;
    .title {
      font-size: 18px;
      line-height: 27px;
      font-weight: 600;
      color: var(--paleta2C);
    }
    .content {
      margin-top: 10px;
      position: relative;
      display: flex;
      flex-direction: column;
      .capa {
        min-width: 100%;
        height: 124px;
        background-position: center;
        background-size: cover;
        border-radius: 10px;
        background-color: var(--background2);
        .addAvatar {
          width: 100%;
          height: 100%;
          position: relative;
          .removeImage,
          .addImage {
            position: absolute;
            right: 0;
            bottom: 0;
            margin-right: 10px;
            margin-bottom: 10px;
            width: 40px;
            height: 40px;
            background-color: var(--warning);
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: none;
            outline: none;
            transition: all 0.2s;

            &:hover {
              transform: scale(1.1);
              transition: all 0.2s;
              background-color: var(--offline);
              svg {
                path {
                  stroke: #fff;
                }
              }
            }

            @media (max-width: 1250px) {
              margin-right: -5px;
              margin-bottom: -5px;
            }
          }

          .removeImage {
            display: ${({ buttonDeleteOnCapa }) =>
              buttonDeleteOnCapa ? 'flex' : 'none'};
          }

          .addImage {
            display: ${({ buttonDeleteOnCapa }) =>
              buttonDeleteOnCapa ? 'none' : 'flex'};
          }
        }
      }
      .avatar {
        -webkit-box-shadow: 0px 0px 6px -1px #000000;
        box-shadow: 0px 0px 6px -1px #000000;
        position: absolute;
        background-color: var(--background2);
        height: 162px;
        width: 162px;
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 20px;
        background-position: center;
        background-size: cover;
        .addAvatar {
          width: 100%;
          height: 100%;
          position: relative;
          .removeImage,
          .addImage {
            position: absolute;
            right: 0;
            bottom: 0;
            margin-right: 10px;
            margin-bottom: 10px;
            width: 40px;
            height: 40px;
            background-color: var(--warning);
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: none;
            outline: none;
            transition: all 0.2s;

            &:hover {
              transform: scale(1.1);
              transition: all 0.2s;
              background-color: var(--offline);
              svg {
                path {
                  stroke: #fff;
                }
              }
            }

            @media (max-width: 1250px) {
              margin-right: -5px;
              margin-bottom: -5px;
            }
          }

          .removeImage {
            display: ${({ buttonDeleteOn }) =>
              buttonDeleteOn ? 'flex' : 'none'};
          }

          .addImage {
            display: ${({ buttonDeleteOn }) =>
              buttonDeleteOn ? 'none' : 'flex'};
          }
        }
      }
    }
  }
  .descricaoCanal {
    margin-top: 80px;
    .descricaoText {
      font-size: 18px;
      line-height: 27px;
      font-weight: 600;
      color: var(--paleta2C);
    }
    .comment {
      margin-top: 10px;
      border: none;
      width: 100%;
      height: 150px;
      background-color: var(--background2);
      resize: none;
      border-radius: 5px;
      padding: 10px;
      outline-width: 1px;
      border: 1px solid transparent;
      outline-color: var(--paleta1A);
      color: var(--texto);
    }
  }
  .disponibilidade {
    margin-top: 30px;

    .disponibilidadeText {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 27px;
      font-weight: 600;
      color: var(--paleta2C);
    }
    .areaDisponibilidade {
      display: flex;
      gap: 18px;
      .cardDisponibilidade {
        display: flex;
        align-items: center;
        gap: 5px;
        .textCardDisponibilidade {
          font-size: 13px;
          line-height: 15px;
          font-weight: 700;
          color: var(--paleta1B);
        }
      }
    }
  }
  .valores {
    margin-top: 30px;
    .headerValores {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .textValores {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 18px;
        line-height: 27px;
        font-weight: 600;
        color: var(--paleta2C);
      }
      .vejaMais {
        display: none;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 15px;
        font-weight: 600;
        color: var(--paleta2C);
      }
    }
    .areaValores {
      display: flex;
      gap: 15px;
      .cardValor {
        height: 40px;
        display: flex;
        gap: 2px;
        .left {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          padding-left: 15px;
          padding-right: 15px;
          background-color: var(--paleta1B);
          white-space: nowrap;

          color: var(--branco);
          font-size: 13px;
          font-weight: 700;
          line-height: 15px;
          border-radius: 5px;
        }
        .sideRight {
          border: none;
          background-color: var(--bgInput);
          height: 100%;
          padding-left: 15px;
          padding-right: 15px;
          max-width: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          outline: none;

          color: var(--texto2);
          font-size: 13px;
          font-weight: 400;
          line-height: 18px;
          border-radius: 5px;
        }
      }
    }
  }
  .buttons {
    margin-top: 35px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    .cancelar {
      border: none;
      background-color: transparent;
      font-size: 13px;
      font-weight: 700;
      line-height: 15px;
      color: var(--paleta2C);
      outline: none;
    }
  }
  @media (max-width: 1250px) {
    width: 100%;
    .videoApresentacao {
      display: flex;
      flex-direction: column;
      .areaLeft {
        width: 100%;
        .responsiveBanner {
          width: 100%;
          display: flex;
          flex-direction: row;
          gap: 30px;
          .thumb {
            width: 50%;
            display: flex;
          }
          .bannerResp {
            width: 50%;
            display: unset;
          }
        }
      }
      .areaRight {
        padding: 0;
        width: 100%;
      }
    }
    .images {
      .content {
        .avatar {
          width: 100px;
          height: 100px;
          margin-top: 60px;
        }
      }
    }
    .descricaoCanal {
      margin-top: 50px;
    }
  }
  @media (max-width: 1050px) {
    .images {
      .content {
        .inputs {
          flex-direction: column;
          max-width: 400px;
          margin-bottom: -100px;
        }
      }
    }
    .descricaoCanal {
      margin-top: 50px;
    }
  }

  @media (max-width: 650px) {
    .descricaoCanal {
      margin-top: 50px;
    }
  }
  @media (max-width: 700px) {
    .videoApresentacao {
      .areaLeft {
        .responsiveBanner {
          .thumb {
            width: 100%;
          }
          .bannerResp {
            display: none;
          }
        }
      }
    }
  }

  @media (max-width: 800px) {
    .valores {
      .headerValores {
        .vejaMais {
          display: unset;
        }
      }
      .areaValores {
        overflow-x: scroll;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
`;
export const Right = styled.div`
  width: 322px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  @media (max-width: 1250px) {
    display: none;
  }
`;
