import { useContext, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import Moment from 'react-moment';
import { Link, useHistory } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import IconPlay from '../../assets/icons/IconPlay';
import UserContext from '../../contexts/UserContext';
import ButttonDefault from '../ButtonDefault';
import {
  IconCloseSquare,
  IconDots,
  IconExternalLink,
  IconPlaylist,
  IconTrash,
  IconVideo,
} from '../Icons';
import { Container } from './styles';

interface IItem {
  comentarios: any;
  consultorId: string;
  consultores: any;
  createdAt: any;
  data: string;
  deleted: boolean;
  deletedAt: any;
  destaque: number;
  hashtags: any;
  id: string;
  interesses: any;
  listaComentarios: any[];
  listaHashtags: any[];
  listaInteresses: any[];
  qtdComentarios: number;
  qtdFavoritos: number;
  qtdVisualizacoes: number;
  thumbnail: string;
  thumbnailFull: string;
  tipo: string;
  titulo: string;
  updatedAt: any;
  video: string;
  __v: number;
  _id: string;
  nickName?: string;
}

interface IProps {
  playlist?: any;
  posicao?: string;
  item?: IItem;
  loading?: boolean;
  type?: 'anuncio' | 'consultor' | 'preview' | any;
  remove: (iud:string)=>void
}

export default function CardPostFavoritos({
  item,
  loading,
  type,
  posicao,
  playlist,
  remove
}: IProps) {
  const history = useHistory();
  const { loadingVideo, setLoadingVideo } = useContext(UserContext);
  const [modalOptions, setModalOptions] = useState<boolean>(false);

  function handleVisualizar() {
    setLoadingVideo(true);
    playlist &&
      history.push(`/visualizar/playlist/${playlist._id}/${item?._id}`);
    !playlist && type !== 'anuncio' && history.push(`/visualizar/${item?._id}`);
  }

  function handleConsultorPage() {
    history.push(`/consultor/visualizar/${item?.consultorId}`);
  }

  function handleAnuncio() {
    window.open(`https://google.com`, '_blank');
  }

   const debounced = useDebouncedCallback(
     // function
     (value) => {
      setModalOptions(value)
     },
     // delay in ms
     300
   );

  return (
    <Container
      posicao={posicao}
      type={type}
      playlist={playlist}
      modal={modalOptions}
      onMouseLeave={() => {
        debounced(false);
      }}
    >
      <div className="modalOptions">
        <div className="options">
          <div className="remove"
          onClick={()=>{remove(item?.id as string)}}
          >
            <IconTrash color={'#7d7d7d'} />
            <p className='text'>Remover dos favoritos</p>
          </div>
        </div>
        <div
          className="closeModal"
          onClick={() => {
            setModalOptions(false);
          }}
        >
          <IconCloseSquare />
        </div>
      </div>

      <div
        className="image"
        style={{ backgroundImage: `url(${item?.thumbnail})` }}
      >
        <div className="overlay-play">
          <div
            className="dots"
            onClick={() => {
              setModalOptions(true);
            }}
          >
            {type !== 'anuncio' && <IconDots size={30} color="#fff" />}
          </div>
          <div
            className="play"
            onClick={type === 'anuncio' ? handleAnuncio : handleVisualizar}
          >
            {type === 'anuncio' ? <IconExternalLink size={80} /> : <IconPlay />}
          </div>
        </div>

        {/* <div className="overlayConfig">
          <div
            className="play"
            onClick={type === 'anuncio' ? handleAnuncio : handleVisualizar}
          >
            <IconPlay />
            <p className="textClose">Assistir</p>
          </div>
          <div className="remove">
            {/* <IconCloseSquare size={22} color={'#fff'} /> 
            <p className="textClose">Remover</p>
          </div>
        </div> */}

        {loading === true && <Skeleton />}
      </div>

      <div className="infos">
        {type !== 'consultor' && posicao !== 'lateral' && (
          <>
            <div
              onClick={type === 'anuncio' ? handleAnuncio : handleConsultorPage}
              className="image-profile"
              style={{ backgroundImage: `url(${item?.consultores?.foto})` }}
            >
              {loading === true && <Skeleton />}
            </div>
          </>
        )}

        {loading === true ? (
          <div className="info-post">
            <span className="title">
              <Skeleton />
            </span>
            <span className="author">
              <Skeleton />
            </span>
            <span className="date">
              <Skeleton />
            </span>
          </div>
        ) : (
          <div className="info-post">
            <span
              onClick={type === 'anuncio' ? handleAnuncio : handleVisualizar}
              className="title"
            >
              {item?.titulo ?? '...'}
            </span>

            {type !== 'consultor' && (
              <>
                <span
                  className="author"
                  onClick={
                    type === 'anuncio' ? handleAnuncio : handleConsultorPage
                  }
                >
                  {item?.consultores?.nickName ?? item?.nickName}
                </span>
              </>
            )}
          </div>
        )}
      </div>
    </Container>
  );
}
