// Types
import { IHeader } from './types';

// Styles
import * as S from './styles';

// Utils
import { firstLetterToUppercase } from '../../../../utils/firstLetterToUppercase';

// Libraries
import moment from 'moment';

const DashboardHeader = ({ campaignData }: IHeader) => {
  function handleMoneyAdvert(valorDia: number) {
    const limiteMensal = handleBRLConvert(valorDia * moment()?.daysInMonth());
    const currentMonth = firstLetterToUppercase(
      moment()?.locale('pt-br')?.format('MMMM')
    );

    return `Em ${currentMonth}, sua cobrança não ultrapassará ${limiteMensal} para essa campanha.`;
  }

  function handleStatusData() {
    if (campaignData?.status === 'Pendente_Aprovacao') {
      return 'Pendente de Aprovação';
    }

    return campaignData?.status;
  }

  function handleVideoAdvert() {
    const videoType = 'antes de um vídeo iniciar na plataforma.';
    const bannerType = 'em diversas partes do site.';

    return `Esse anúncio é do tipo ${campaignData?.tipo} e aparecerá ${
      videoType ? videoType : bannerType
    }`;
  }

  function handleBRLConvert(value: number) {
    const format = {
      minimumFractionDigits: 2,
      style: 'currency',
      currency: 'BRL',
    };

    return value?.toLocaleString('pt-BR', format);
  }

  return (
    <S.Header>
      <S.Title>Visão Geral</S.Title>

      <S.HeaderInfos>
        <li className="info-title">
          Status:{' '}
          <span className={`info-status ${handleStatusData().toLowerCase()}`}>
            {handleStatusData()}
          </span>
        </li>
        <li className="info-title">
          Tipo:{' '}
          <span className={`info-type`} data-type={handleVideoAdvert()}>
            {firstLetterToUppercase(campaignData?.tipo)}
          </span>
        </li>
        <li className="info-title">
          Orçamento:{' '}
          <span
            className="info-money"
            data-limite-gasto={handleMoneyAdvert(campaignData?.valor_dia)}
          >
            {handleBRLConvert(campaignData?.valor_dia)}
            /dia
          </span>
        </li>
      </S.HeaderInfos>
    </S.Header>
  );
};

export default DashboardHeader;
