import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import apiDoubts from '../../services/api';
import { Container } from './styles';

export default function ValidateEmail() {
  const history = useHistory<any>();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const id: any = searchParams.get('id');
  const tipo: any = searchParams.get('tipo');

  useEffect(() => {
    validateEmail(id, tipo);
  }, [id, tipo]);

  async function validateEmail(id: string, tipo: string) {
    try {
      const objectValidateEmail = {
        id: id,
        tipo: tipo,
      };
      const response = await apiDoubts.post(
        `confirmar-email`,
        objectValidateEmail
      );
      if (response.status === 200) {
        toast.success(`email validado com sucesso`);
        history.push('/login');
      } 
    } catch (error) {
      
      toast.error('A conta ja foi validada', {
        className: 'toast-error',
        theme: 'colored',
      });
      history.push('/login')
    }
  }

  return (
    <Container>
      <p>Página de validação de email dos usuários</p>
    </Container>
  );
}
