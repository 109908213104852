export default function LogoIcon({ height= 20, width = 14, color = "#252428" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 72 20`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.08387 19.726H12.7656C13.3677 19.726 13.8495 19.2216 13.8495 18.5911V1.13492C13.8495 0.50441 13.3677 0 12.7656 0H1.08387C0.481721 0 0 0.50441 0 1.13492V18.5911C0 19.2216 0.481721 19.726 1.08387 19.726Z"
        fill="#0FBABA"
      />
      <path
        d="M42.2795 1.28806V8.95328C43.0709 7.82737 44.258 7.45807 45.3333 7.45807C46.6752 7.45807 47.8795 7.9895 48.7398 8.83619C49.686 9.78196 50.2796 11.1871 50.2796 12.8895C50.2796 14.4748 49.7548 15.952 48.7398 17.0058C47.8795 17.9246 46.8301 18.411 45.3763 18.411C43.6387 18.411 42.7613 17.5553 42.2795 16.8257V18.0687H39.114V1.28806H42.2795ZM42.7871 11.061C42.4172 11.4753 42.0817 12.0698 42.0817 12.9525C42.0817 13.8082 42.4344 14.4297 42.8043 14.79C43.3118 15.2944 44.0172 15.5466 44.6107 15.5466C45.1613 15.5466 45.772 15.3124 46.2623 14.8351C46.7269 14.3757 47.0279 13.7091 47.0279 12.9525C47.0279 12.1239 46.7183 11.4573 46.2623 10.9979C45.7548 10.4935 45.2043 10.3044 44.5677 10.3044C43.8881 10.3044 43.2946 10.5115 42.7871 11.061Z"
        fill="#2E294E"
      />
      <path
        d="M55.5097 10.8088V18.4019H52.3441V10.8088H51.243V8.1426H52.3441V4.82791H55.5097V8.1426H57.4624V10.8088H55.5097Z"
        fill="#2E294E"
      />
      <path
        d="M64.8344 10.4215C64.3527 10.1242 63.7161 9.84499 63.0968 9.84499C62.7699 9.84499 62.3914 9.93506 62.1936 10.1422C62.0817 10.2593 61.9957 10.4395 61.9957 10.6016C61.9957 10.8358 62.1505 10.9709 62.3054 11.061C62.529 11.1961 62.8559 11.2681 63.2516 11.4033L64.086 11.6825C64.6366 11.8626 65.2043 12.0968 65.7118 12.5832C66.2796 13.1327 66.4774 13.7542 66.4774 14.5378C66.4774 15.8979 65.9097 16.7716 65.4624 17.231C64.4989 18.2398 63.3118 18.4019 62.2796 18.4019C60.9635 18.4019 59.5355 18.1047 58.2538 16.9247L59.5957 14.6909C59.9054 14.9701 60.3441 15.2854 60.671 15.4475C61.1097 15.6817 61.5742 15.7718 61.9871 15.7718C62.185 15.7718 62.6925 15.7718 62.9764 15.5376C63.1742 15.3755 63.3032 15.1233 63.3032 14.8711C63.3032 14.6909 63.2344 14.4567 62.9505 14.2766C62.7269 14.1415 62.443 14.0424 61.9871 13.9073L61.1957 13.6551C60.628 13.4749 60.0344 13.1507 59.6129 12.7093C59.1484 12.2049 58.9075 11.6284 58.9075 10.8448C58.9075 9.85399 59.2774 9.02532 59.8538 8.47587C60.7312 7.62018 61.9183 7.44003 62.8215 7.44003C64.2753 7.44003 65.2817 7.82735 66.0731 8.31374L64.8344 10.4215Z"
        fill="#2E294E"
      />
      <path
        d="M12.3269 18.0597H9.16132V16.8167C8.6796 17.5553 7.80218 18.402 6.06454 18.402C4.61078 18.402 3.56132 17.9156 2.7011 16.9968C1.69465 15.943 1.16132 14.4748 1.16132 12.8805C1.16132 11.1781 1.75486 9.77295 2.7011 8.82718C3.56132 7.97149 4.76562 7.44906 6.10755 7.44906C7.18282 7.44906 8.36992 7.81836 9.16132 8.94428V1.28806H12.3269V18.0597ZM5.18712 10.9979C4.72261 11.4573 4.42153 12.1239 4.42153 12.9525C4.42153 13.7091 4.73121 14.3757 5.18712 14.8351C5.66884 15.3215 6.2882 15.5466 6.83874 15.5466C7.43228 15.5466 8.13766 15.2944 8.63659 14.79C9.00648 14.4207 9.35917 13.7992 9.35917 12.9525C9.35917 12.0788 9.03228 11.4753 8.65379 11.061C8.14626 10.5115 7.55271 10.3044 6.87315 10.3044C6.23659 10.3044 5.69465 10.4935 5.18712 10.9979Z"
        fill="white"
      />
      <path
        d="M25.0925 16.7446C24.2151 17.6634 22.7183 18.402 20.8086 18.402C18.8989 18.402 17.4022 17.6634 16.5247 16.7446C15.5355 15.7088 15.0538 14.2406 15.0538 12.9255C15.0538 11.6104 15.5355 10.1423 16.5247 9.10641C17.4022 8.18767 18.8989 7.44907 20.8086 7.44907C22.7183 7.44907 24.2151 8.18767 25.0925 9.10641C26.0817 10.1423 26.5635 11.6104 26.5635 12.9255C26.5635 14.2406 26.0817 15.7088 25.0925 16.7446ZM19.028 10.9979C18.5635 11.4843 18.3054 12.1058 18.3054 12.9345C18.3054 13.8803 18.6581 14.4748 19.028 14.8711C19.4237 15.2854 19.9914 15.6097 20.8258 15.6097C21.5312 15.6097 22.1247 15.3575 22.5807 14.8711C23.0452 14.3847 23.3032 13.7632 23.3032 12.9345C23.3032 12.1058 23.0366 11.4843 22.5807 10.9979C22.1161 10.5116 21.5226 10.2593 20.8 10.2593C20.0774 10.2593 19.4839 10.5116 19.028 10.9979Z"
        fill="#2E294E"
      />
      <path
        d="M30.8989 7.80032V13.4569C30.8989 13.9163 30.9419 14.6279 31.4495 15.1143C31.6731 15.3214 32.0688 15.6007 32.7656 15.6007C33.3591 15.6007 33.8237 15.3935 34.1075 15.1143C34.5721 14.6549 34.6323 13.9613 34.6323 13.4569V7.80032H37.7979V13.7812C37.7979 14.9521 37.686 16.06 36.6968 17.0689C35.5785 18.2218 33.9269 18.4019 32.7656 18.4019C31.5527 18.4019 29.9269 18.2218 28.8086 17.0689C27.9054 16.1501 27.7333 15.1143 27.7333 13.9884V7.80032H30.8989Z"
        fill="#2E294E"
      />
      <path
        d="M69.8924 18.402C71.0564 18.402 72 17.414 72 16.1952C72 14.9764 71.0564 13.9884 69.8924 13.9884C68.7285 13.9884 67.7849 14.9764 67.7849 16.1952C67.7849 17.414 68.7285 18.402 69.8924 18.402Z"
        fill="#0FBABA"
      />

      <defs>
        <clipPath id="clip0">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}