import styled from "styled-components";

export const Container = styled.button`
  width: 40px;
  height: 40px;
  border: 1px solid var(--paleta1B);
  background-color: transparent;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;

  &:hover {
    transform: scale(1.05);
    transition: all 0.3s;
  }
`;