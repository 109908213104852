import { useEffect, useState } from 'react';
import { IconCheck, IconTrash } from '../../Icons';
import { Container } from './styles';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import ModalAlert from '../../ModalAlert';

interface dataProps {
  empresaData: any;
  checked: boolean;
  setChecked: (id: string) => void;
  deleteEmpresa: (account: any) => void;
}

export default function CardEmpresaConfig({
  empresaData,
  checked,
  setChecked,
  deleteEmpresa,
}: dataProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const history = useHistory();

  return (
    <>
      <ModalAlert
        prosseguir={() => deleteEmpresa(empresaData)}
        openModal={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        id=""
      >
        Tem certeza que desejar encerrar vínculos com o(a){' '}
        {empresaData?.nickName}?
      </ModalAlert>

      <Container>
        {/* <input
            type="checkbox"
            className="inputCheckboxConsultorEmpresa" 
            onClick={()=>{
                setChecked(consultorData?.id)
            }}
            checked={checked}
            /> */}

        {checked && (
          <div
            className="iconChecked"
            onClick={() => {
              setChecked(empresaData?.id);
            }}
          >
            <IconCheck color={'#fff'} />
          </div>
        )}

        <div className="contentCardProfileConsultor">
          <div className="bussinessInfo">
            <div
              className="imageProfileConsultor"
              style={{
                backgroundImage: `url(${
                  empresaData?.foto ??
                  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
                })`,
              }}
            ></div>

            <div className="profileInfo">
              <p
                className="nickName"
                onClick={() =>
                  history?.push(`/empresa/visualizar/canal/${empresaData?._id}`)
                }
              >
                {empresaData?.nickName}
              </p>

              <div className="areaAtuacoes">
                {empresaData?.areaAtuacao?.map(
                  (area: string, index: number) => (
                    <p id={index.toString()}>{area}{index + 1!== empresaData?.areaAtuacao?.length && ', '}</p>
                  )
                )}
              </div>

              <p className="createdDate">
                {moment(empresaData?.createdAt)?.locale('pt-br')?.fromNow()}
              </p>
            </div>
          </div>

          <div
            className="removeVinculo"
            onClick={() => setIsModalOpen(true)}
            title="Remover Vínculo com Empresa"
          >
            <IconTrash color={'var(--offline)'} size={18} />
          </div>
        </div>
      </Container>
    </>
  );
}
