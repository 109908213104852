import { useHistory, useParams, useLocation } from 'react-router-dom';
import HeaderConsultor from '../../components/HeaderConsultor';
import { Container } from './styles';
import { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import ButttonDefault from '../../components/ButtonDefault';
import apiDoubts from '../../services/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserContext from '../../contexts/UserContext';
import WarningLogout from '../../components/WarningLogout';
import Inputdefault from '../../components/Inputs/InputDefault';
import IconPlay from '../../assets/icons/IconPlay';
import {
  CalendarStatic,
  HoursStatic,
  IPropsHoursStaticData,
} from '../../components/Calendar';
import { formatarDataString } from '../../utils/format';
import { COLOR_LIVRE } from '../Agenda/consultor';
import { IconRelogio, IconWarning } from '../../components/Icons';
import ModalDefault from '../../components/Modal Default/index';
import Banner from '../../components/Banner';

export default function Consultoria() {
  const refScroll = useRef(null);
  const params = useParams<any>();
  const location = useLocation<any>();
  const history = useHistory();
  const [consultor, setConsultor] = useState<any>(
    location.state && location.state.consultor ? location.state.consultor : {}
  );

  const [principalPost, setPrincipalPost] = useState<any>({});
  const { user } = useContext(UserContext);

  const [filterDate, setFilterDate] = useState<Date>(new Date());
  const [dataAvalilableDates, setDataAvalilableDates] = useState([]);
  const [loadingEvents, setLoadingEvents] = useState(true);
  const [dataEvents, setDataEvents] = useState<IPropsHoursStaticData[]>([]);

  const [DTO, setDTO] = useState({
    assunto: '',
    consultorId: params.id,
    data: '',
    tempo: 10,
    comentario: '',
  });

  useEffect(() => {
    getConsultor();
  }, []);

  async function getConsultor() {
    const response = await apiDoubts.get(`consultor/get-by-id/${params.id}`);

    setConsultor(response?.data?.data[0]);
    getAvalilableDates(response?.data?.data[0]);

    if (response?.data?.data[0]?.postagemPrincipal) {
      const responsePrincipal = await apiDoubts.get(
        `/postagens/getPostagem/${response?.data?.data[0]?.postagemPrincipal}`
      );
      setPrincipalPost(responsePrincipal?.data?.data);
    }
  }

  function handleOnChange(name: string, value: any) {
    const newDTO: any = DTO;
    newDTO[name] = value;
    setDTO({ ...newDTO });
  }

  async function getAvalilableDates(consultor: any) {
    try {
      setLoadingEvents(true);
      const response = await apiDoubts.get(
        `consultoria-horarios?consultorId=${consultor._id}&pagination=true&page=1&limit=1000`
      );
      setDataAvalilableDates(response.data.data);
      setLoadingEvents(false);
    } catch (error) {
      setLoadingEvents(false);
    }
  }

  useEffect(() => {
    if (filterDate && dataAvalilableDates.length) {
      let events: IPropsHoursStaticData[] = [];
      dataAvalilableDates
        .filter(
          (obj: any) =>
            obj.status === 'livre' &&
            moment(obj.data).format('YYYY-MM-DD') ===
              moment(filterDate).format('YYYY-MM-DD')
        )
        .map((row: any) => {
          events.push({
            color: COLOR_LIVRE,
            hora: moment(row.data).format('HH:mm'),
            title: 'Consultoria disponivel',
            reference: row,
          });
        });
      setDataEvents([...events]);
    }
  }, [dataAvalilableDates, filterDate]);

  const [showModalConfirmFinish, setShowModalConfirmFinish] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState<{
    show: boolean;
    params: any;
  }>({
    show: false,
    params: {},
  });
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleOnSubmit = async (e: any) => {
    try {
      setLoadingSubmit(true);
      e.preventDefault();

      if (loadingSubmit)
        throw new Error('Aguarde enquanto finalizamos a solicitação.');
      if (user.id === params?.id)
        throw new Error('Você não pode marcar uma consultoria com você mesmo');
      if (user?.tipo === 'consultor')
        throw new Error(
          'Consultores não podem requisitar consultorias de outros consultores'
        );
      if (DTO.data.length && moment(DTO.data).format('HH:mm') === '00:00')
        throw new Error('Por favor, escolha uma data e horario');
      if (DTO.assunto === '')
        throw new Error(
          'Por favor, digite o assunto a ser tratado com o consultor'
        );
      if (DTO.comentario === '')
        throw new Error(
          'Por favor, descreva melhor sobre o que a consultoria vai se tratar'
        );

      const response = await apiDoubts.post('consultoria', {
        assunto: DTO.assunto,
        comentario: DTO.comentario,
        consultorId: consultor.id,
        data: `${moment(filterDate).format('YYYY-MM-DD')} ${
          showModalConfirm.params.hora + ':00'
        }`,
        tipo: showModalConfirm.params.paga ? 'paga' : 'gratis',
      });

      if (response.data.status === 'error')
        throw new Error(response.data.message);

      setDTO({
        ...DTO,
        assunto: '',
        comentario: '',
      });

      getAvalilableDates(consultor);

      setShowModalConfirm({
        show: false,
        params: {
          ...showModalConfirm.params,
        },
      });
      setShowModalConfirmFinish(true);

      setLoadingSubmit(false);
    } catch (error: any) {
      setLoadingSubmit(false);
      toast.error(error.message);
    }
  };

  return (
    <Container>
      <HeaderConsultor consultorId={params.id} page="Consultoria" />

      <ModalDefault
        title={`Consultoria solicitada`}
        id="modal-confirm-finish"
        openModal={showModalConfirmFinish}
        onClose={() => setShowModalConfirmFinish(false)}
      >
        <div className="modal-finish">
          <p>
            Sua solicitação de consultoria foi enviada com sucesso. O consultor
            designado agora irá avaliá-la, podendo aceitar ou recusar.
          </p>
          <p>
            Você será informado sobre a decisão por meio da caixa de mensagens
            do site ou pelo e-mail cadastrado em sua conta. Agradecemos pela sua
            paciência e aguardamos seu contato em breve
          </p>
          <p>
            Recomendamos que acesse o link fornecido com antecedência,
            preferencialmente 10 minutos antes do horário agendado, para
            garantir uma experiência tranquila e eficiente durante a
            consultoria. Agradecemos por escolher nossos serviços e estamos
            ansiosos para atendê-lo em breve.
          </p>
          <div className="date-hour">
            <IconRelogio color="var(--purple3)" />
            <span>
              {formatarDataString(filterDate)} {showModalConfirm.params?.hora}
            </span>
          </div>
        </div>
      </ModalDefault>

      <ModalDefault
        title={`Agendar consultoria`}
        id="modal-confirm"
        openModal={showModalConfirm.show}
        onClose={() => setShowModalConfirm({ show: false, params: {} })}
      >
        {user?.tipo === 'cadastro' ? (
          <form onSubmit={handleOnSubmit}>
            <div className="name-user">
              <img src={consultor.foto} />
              <span>{consultor.nickName}</span>
            </div>
            <div className="date-hour">
              <IconRelogio color="var(--purple3)" />
              <span>
                {formatarDataString(filterDate)} {showModalConfirm.params?.hora}
              </span>
            </div>
            <p className="aviso">
              Para maior aproveitamento, pedimos que deixe uma mensagem ao
              consultor com as suas dúvidas e assuntos que gostaria de tratar na
              consultoria.
            </p>
            <p className="aviso">
              Adicione informações cruciais! Escolha um título cativante para
              seu vídeo, como uma pergunta envolvente. Depois, acrescente uma
              descrição breve e informativa sobre o que os espectadores podem
              esperar.
            </p>
            <p className="question">
              Qual o assunto a ser tratado na consultoria?
            </p>
            <div className="inputAssunto">
              <Inputdefault
                tipo="text"
                value={DTO?.assunto}
                placeholder="Por exemplo, Como consertar uma torneira vazando?"
                change={(e: any) => {
                  handleOnChange('assunto', e.target.value);
                }}
                changeBlur={(e: any) => {
                  handleOnChange(
                    'assunto',
                    `${e.target.value}${
                      e.target.value.indexOf('?') === -1 ? '?' : ''
                    }`
                  );
                }}
              />
            </div>
            <p className="question">
              Descreva melhor o que você precisa saber para o consultor poder se
              preparar para a consultoria.
            </p>
            <textarea
              value={DTO?.comentario}
              className="input"
              placeholder="Informações adicionais sobre a consultoria, como uma breve explicação geral da dúvida."
              onChange={(e) => {
                handleOnChange('comentario', e.target.value);
              }}
            />
            <div className="footer">
              <ButttonDefault
                type="button"
                tipo={'15'}
                title="Cancelar"
                onClick={() => setShowModalConfirm({ show: false, params: {} })}
              />
              <ButttonDefault
                icon={loadingSubmit ? 'Loading' : ''}
                tipo={'16'}
                title="Confirmar"
                onClick={handleOnSubmit}
              />
            </div>
          </form>
        ) : (
          <div className="warningWrapper">
            <WarningLogout
              title="Ops..."
              description="Para agendar uma consultoria é necessário ser um usuário comum."
              button={false}
              fullscreen={false}
            />
          </div>
        )}
      </ModalDefault>

      <div className="content-user-page">
        <div className="render">
          {principalPost.id && (
            <div className="principal-info">
              <div className="principal-post">
                <div
                  className="video"
                  style={{
                    backgroundImage: `url(${principalPost?.thumbnail})`,
                  }}
                  onClick={() =>
                    history.push(`/visualizar/${principalPost.id}`)
                  }
                >
                  <div className="hover">
                    <IconPlay />
                  </div>
                </div>
                <div className="infos">
                  <p className="title">{principalPost.titulo}</p>
                  <p className="description">{principalPost.descricao}</p>
                  <div className="metrics">
                    <span>{principalPost.qtdVisualizacoes} visualizações</span>
                    <b>‧</b>
                    <span>{principalPost.curtidas} curtidas</span>
                    <b>‧</b>
                    <span>{principalPost.qtdComentarios} comentários</span>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="text-info">
            <p className="title">Bate papo com {consultor.nickName}</p>
            <p className="description">
              A Doubts quer conectar você a informação! Tire suas dúvidas e
              tenha uma consultoria personalizada agora mesmo.
            </p>
          </div>

          <div className="request-user">
            <div className="sidebar">
              <CalendarStatic onChange={setFilterDate} value={filterDate} />
              <p className="alert">
                Atenção! A consultoria deverá seguir com o foco em sua dúvida, o
                consultor vai abordar somente as dúvidas e assuntos enviados
                nese formulário.
              </p>
            </div>
            <div className="content-hours">
              <div className="head">
                <h3>{formatarDataString(filterDate)}</h3>
              </div>
              <div className="overflow" ref={refScroll}>
                <HoursStatic
                  hiddenDisable={loadingEvents ? false : true}
                  loading={loadingEvents}
                  data={dataEvents}
                  refScroll={refScroll}
                  onClickEvent={(e) => {
                    console.log('e', e);
                    setShowModalConfirm({
                      show: true,
                      params: e,
                    });
                  }}
                  filterDate={filterDate}
                />
                {dataEvents.length === 0 && loadingEvents === false && (
                  <div className="empty">
                    <IconWarning color="var(--paleta2A)" />
                    <p>
                      O consultor não possui horários disponíveis para
                      consultorias em {formatarDataString(filterDate)}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="sideRigth">
          <div className="areaBanner">
            <Banner peso="2" position="1" typeBanner="322x227" />
            <Banner peso="2" position="1" typeBanner="322x673" />
          </div>
        </div>
      </div>
    </Container>
  );
}
