import { url } from 'inspector';
import { useEffect, useState } from 'react';
import apiDoubts from '../../services/api';
import { IconMore } from '../Icons';
import { Container } from './styles';

interface props {
  consultorId: string;
}

export default function Inscritos({ consultorId }: props) {
  const [usersConsultoria, setUsersConsultoria] = useState<any[]>([]);

  useEffect(() => {
    getConsultoriasConsultor(consultorId);
  }, [consultorId]);

  async function getConsultoriasConsultor(consultorId: string) {
    if (!consultorId) return;
    await apiDoubts
      .get(
        `consultoria-all?pagination=true&page=1&consultorId=${consultorId}&limit=5`
      )
      .then((response: any) => {
        setUsersConsultoria(response?.data?.data);
      });
  }

  return (
    <Container>
      {/* {usersConsultoria?.length > 0 &&
        usersConsultoria?.map((row: any, key: number) => {
          return (
            <div
              className="perfil"
              style={{ backgroundImage: `url(${row?.cadastros?.foto})` }}
            >
              {' '}
            </div>
          );
        })}

      {usersConsultoria?.length > 0 && (
        <button
          onClick={() => {
            alert('clicou');
          }}
        >
          <IconMore />
        </button>
      )} */}
    </Container>
  );
}
