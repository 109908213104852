import BackArrowIcon from '../../assets/icons/BackArrow';
import { ContainerOverlay, Container } from './styles';

interface ModalProps {
  id: string;
  children: any;
  onClose: () => void;
  openModal: boolean;
  width?: string;
  title?: string;
}

export default function ModalDefault({
  id,
  onClose,
  width,
  title,
  openModal,
  children,
}: ModalProps) {
  const handleCloseModal = (e: any) => {
    if (e.target.id === id) {
      onClose();
    }
  };

  return (
    <ContainerOverlay id={id} openModal={openModal} onClick={handleCloseModal}>
      <Container width={width} openModal={openModal}>
        <div className="container-modal">
          <div className="head-modal">
            <h2>{title ?? ''}</h2>

            <button onClick={onClose}>Fechar</button>
          </div>
          <div className="content-modal">{children}</div>
        </div>
      </Container>
    </ContainerOverlay>
  );
}
