import moment from 'moment';

export function getDaysInRange(startDate: string, endDate: string): string[] {
  const start = moment(startDate, 'DD-MM-YYYY');
  const end = moment(endDate, 'DD-MM-YYYY');
  const daysInRange: string[] = [];

  let currentDate = moment(start);

  while (currentDate <= end) {
    daysInRange.push(currentDate.format('DD-MM-YYYY'));
    currentDate = moment(currentDate).add(1, 'days');
  }

  return daysInRange;
}
