import styled, { css } from 'styled-components';

interface Props {
  tipo?: string;
  hover?: string;
  full?: boolean;
}

export const Container = styled.button<Props>`
  z-index: 2 !important;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  background-color: var(--paleta2A);
  border: none;
  min-height: 40px;
  padding: 0px 15px;
  border-radius: 5px;
  color: var(--branco);
  font-size: 13px;
  line-height: 15px;
  font-weight: 700;
  white-space: nowrap;
  width: ${({ full }) => full === true && '100%'};
  position: relative;

  &:after {
    display: flex;
    width: 0%;
    height: 0%;
    content: '';
    position: absolute;
    z-index: -1;
    border-radius: 100px;
    transition: all 0.2s;
    background: rgba(0, 0, 0, 0);
  }

  &:hover {
    border-color: transparent;
    &:after {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.1);
    }
  }

  ${(props) =>
    props.tipo === '2' &&
    css`
      background-color: transparent;
      border: 1px solid var(--paleta1B);
      color: var(--paleta1B);
    `}

  ${(props) =>
    props.tipo === '3' &&
    css`
      background-color: transparent;
      border: 1px solid var(--paleta1A);
      color: var(--paleta1A);
    `}

   ${(props) =>
    props.tipo === '4' &&
    css`
      background-color: var(--paleta2D);
      border: none;
      color: var(--branco);
    `}

    ${(props) =>
    props.tipo === '5' &&
    css`
      background-color: var(--paleta1A);
      border: none;
      color: var(--branco);
    `}

    ${(props) =>
    props.tipo === '6' &&
    css`
      background-color: var(--paleta2C);
      border: none;
    `}

    
    ${(props) =>
    props.tipo === '7' &&
    css`
      background-color: var(--paleta1B);
      border: none;
    `}

    ${(props) =>
    props.tipo === '8' &&
    css`
      background-color: var(--offline);
      border: none;
    `}

    ${(props) =>
    props.tipo === '9' &&
    css`
      background-color: var(--warning);
      border: none;
    `}

    ${(props) =>
    props.tipo === '10' &&
    css`
      background-color: var(--btn-aceitar);
      border: none;
    `}

    ${(props) =>
    props.tipo === '11' &&
    css`
      background-color: transparent;
      color: var(--paleta2C);
    `}

    ${(props) =>
    props.tipo === '12' &&
    css`
      background-color: var(--branco);
      color: var(--texto);
    `}

    ${(props) =>
    props.tipo === '13' &&
    css`
      background-color: #0fbaba;
      color: var(--white);
    `}

    ${(props) =>
    props.tipo === '14' &&
    css`
      background-color: transparent;
      color: #0fbaba;
      border: 1px solid #0fbaba;
    `}

    ${(props) =>
    props.tipo === '15' &&
    css`
      background-color: transparent;
      color: var(--purple2);
      border: 1px solid var(--purple2);
      svg {
        path,
        circle {
          fill: var(--purple2);
        }
      }
    `}

    ${(props) =>
    props.tipo === '16' &&
    css`
      background-color: var(--purple2);
      color: #ffffff;
      svg {
        path,
        circle {
          fill: white;
        }
      }
    `}

    ${(props) => props.hover === 'none' && css``}

    

    @media (max-width: 800px) {
    &:hover {
      transform: none;
    }
  }
`;
