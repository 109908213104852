import { ButtonHTMLAttributes, useContext, useEffect, useState } from 'react';
import UserContext from '../../../contexts/UserContext';

import * as S from './styles';
import moment from 'moment';
import { IconEstrela, IconEstrelaAmarela } from '../../Icons';
import apiDoubts from '../../../services/api';
import { useHistory } from 'react-router-dom';
import ButtonSeguir from '../../ButtonSeguir';
import { AxiosResponse } from 'axios';

interface dataProps {
  isFavorite: boolean;
  type: string;
  remetente: string;
  title: string;
  data: any;
}

interface props extends ButtonHTMLAttributes<HTMLButtonElement> {
  data: any;
  select: any;
  isSelected: boolean;
  openEmail: () => void;
}

export default function CardMailBox({
  data,
  select,
  isSelected,
  openEmail,
  ...rest
}: props) {
  const history = useHistory();
  const { user } = useContext(UserContext);

  // state para manipular
  const [email, setEmail] = useState<any>(data);
  const [favoring, setFavoring] = useState<boolean>(false);
  const [nickNameDestinatario, setNickNameDestinatario] =
    useState<string>('carregando...');

  useEffect(() => {
    if (nickNameDestinatario === 'carregando...') {
      handleNickNameDestinatario();
    }
  }, []);

  async function handleEmailFavorite() {
    if (favoring) return;
    setFavoring(true);

    apiDoubts
      .post(`conversa/favoritar`, {
        idConversa: data?._id ?? '',
      })
      .then(() => {
        setFavoring(false);
      })
      .catch(() => {
        setFavoring(false);
      });

    if (email?.marcadoFavorito?.includes(user.id)) {
      let emailClone = { ...email };
      emailClone['marcadoFavorito'] = emailClone['marcadoFavorito'].filter(
        (id: string) => id !== user?._id
      );
      setEmail(emailClone);
    } else {
      let emailClone = { ...email };
      emailClone.marcadoFavorito = [user?._id];
      setEmail(emailClone);
    }
  }

  async function handleNickNameDestinatario() {
    const recipient = email?.users.filter(
      (userTalk: any) => userTalk?._id !== user?.id
    )[0];
    if (!data) return 'Carregando...';

    let userApiResponse: AxiosResponse<any> = await apiDoubts.get(
      `${recipient?.tipo}/get-by-id/${recipient?._id}`
    );

    // validação pois alguns perfis vem dentro de um array
    if (userApiResponse?.data?.data?.nickName) {
      setNickNameDestinatario(userApiResponse?.data?.data?.nickName);
    } else {
      setNickNameDestinatario(userApiResponse?.data?.data[0]?.nickName);
    }
  }

  function limitName(name: string): string {
    if (name?.length > 15) {
      name = name.substring(0, 15) + '...';
    }
    return name;
  }

  return ( 
    <S.Container
      {...rest}
      isSelected={isSelected}
      style={{ backgroundColor: `${data?.marcadoLida.includes(user?._id) ? 'var(--bgInput)' : '#fff'}` }}
    >
      <S.ButtonArea>
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => {
            select();
          }}
        />
        <button className="buttonEstrela" onClick={handleEmailFavorite}>
          {email?.marcadoFavorito?.includes(user?.id) ? (
            <IconEstrelaAmarela size={20} />
          ) : (
            <IconEstrela size={20} color={'var(--mid-grey)'} />
          )}
        </button>
      </S.ButtonArea>
      <S.RigthArea
        onClick={() => {
          openEmail();
          history.push(`email/${data?._id}`);
        }}
      >
        <div className="startRigthArea">
          <S.NameArea>
            <p>{limitName(nickNameDestinatario)}</p>
          </S.NameArea>
          <ButtonSeguir
            click={() => {}}
            title={email?.tipoConversa}
            tipo={email?.tipoConversa === 'email' ? '1' : '4'}
          />
          <p>{email.titulo.substring(0, 40)}</p>
        </div>

        <S.EmailDate>
          {moment(email?.updatedAt).locale('pt-br').format('DD/MM/YYYY')}
        </S.EmailDate>
      </S.RigthArea>
    </S.Container>
  );
}
