export default function EyeIcon({ size = 25, color = '#0FBABA' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 3.25C7.895 3.25 9.585 4.315 10.41 6C9.585 7.685 7.9 8.75 6 8.75C4.1 8.75 2.415 7.685 1.59 6C2.415 4.315 4.105 3.25 6 3.25ZM6 2.25C3.5 2.25 1.365 3.805 0.5 6C1.365 8.195 3.5 9.75 6 9.75C8.5 9.75 10.635 8.195 11.5 6C10.635 3.805 8.5 2.25 6 2.25ZM6 4.75C6.69 4.75 7.25 5.31 7.25 6C7.25 6.69 6.69 7.25 6 7.25C5.31 7.25 4.75 6.69 4.75 6C4.75 5.31 5.31 4.75 6 4.75ZM6 3.75C4.76 3.75 3.75 4.76 3.75 6C3.75 7.24 4.76 8.25 6 8.25C7.24 8.25 8.25 7.24 8.25 6C8.25 4.76 7.24 3.75 6 3.75Z"
        fill={color}
      />
    </svg>
  );
}
