import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import VideoPlayer from '../videoPlayer';
import { Link } from 'react-router-dom';
import IconPlay from '../../assets/icons/IconPlay';
import { IconLoading } from '../Icons';
import { fireEvent } from '@testing-library/react';
import { useUser } from '../../contexts/UserContext';
import apiDoubts from '../../services/api';

interface VProps {
  loading?: boolean;
  ads: any;
  thumbnail: string;
  onSkip(): void;
  onAdClick(): void;
}

export const DEFAULT_TIME_ADS = 5;

export default function VideoADS({
  loading,
  ads,
  thumbnail,
  onSkip,
  onAdClick,
}: VProps) {
  const videoRef = useRef<any>();
  const { interaction } = useUser();

  const [skipEnabled, setSkipEnabled] = useState(false);
  const [progress, setProgress] = useState(0);
  const [counterToJump, setCounterToJump] = useState<number>(DEFAULT_TIME_ADS);
  const [playing, setPlaying] = useState(true);
  const [volume, setVolume] = useState(0);
  const [startAd, setStartAd] = useState(false);
  const [loadingVideo, setLoadingVideo] = useState<any>(true);

  useEffect(() => {
    if (interaction) setVolume(1);
  }, [interaction]);

  const handleProgress = (e: any) => {
    if (e.playedSeconds) {
      localStorage?.setItem(
        'adsVideoProgress',
        Math.round(e.playedSeconds).toString()
      );
      setLoadingVideo(false);
      setProgress(e.playedSeconds.toFixed(0));
      if (e.playedSeconds >= 5) {
        setSkipEnabled(true);
      }
    }
  };

  function formatSeconds(secondsValue: number) {
    var minutes = Math.floor(secondsValue / 60);
    var seconds = Math.floor(secondsValue % 60);
    return (
      minutes.toString().padStart(2, '0') +
      ':' +
      seconds.toString().padStart(2, '0')
    );
  }

  const counterJump = useCallback(() => {
    setCounterToJump(counterToJump - 1);
  }, [counterToJump]);

  useEffect(() => {
    if (progress > 0) {
      counterJump();
      setStartAd(true);
    }
  }, [progress]);

  function handlePlayVideoAds() {
    if (startAd === false || volume === 0) {
      setVolume(1);
      setPlaying(true);
    } else {
      setPlaying(!playing);
    }
  }

  useEffect(() => {
    setLoadingVideo(loading);
    setCounterToJump(DEFAULT_TIME_ADS);
    setProgress(0);
    setSkipEnabled(false);
    setStartAd(false);
    if (!playing) setStartAd(false);
  }, [loading]);

  return (
    <Container>
      {loading ? (
        <VideoPlayer className="video" />
      ) : (
        <VideoPlayer
          playing={playing}
          controls={false}
          autoPlay={true}
          volume={volume}
          className="video"
          ref={videoRef}
          onEnded={() => onSkip()}
          url={ads.video}
          onProgress={handleProgress}
          onSeek={(e: any) => {
            return false;
          }}
        />
      )}

      {!loading && (
        <div
          onClick={() => handlePlayVideoAds()}
          className={`overlay-controls ${playing ? 'play' : 'pause'}`}
        >
          <button>
            <IconPlay />
          </button>
        </div>
      )}

      {loadingVideo && (
        <div className={`overlay-controls loading`}>
          <button>
            <IconLoading />
          </button>
        </div>
      )} 

      <div className="controls-ads">
        {startAd && (
          <div className="campgain animate__animated animate__zoomIn">
            <div
              className="img"
              style={{
                backgroundImage: `url(${ads?.thumbnail ?? ''})`,
              }}
            />
            <div>
              <p>{ads?.nome}</p>
              <Link
                to={{ pathname: ads?.link }}
                onClick={onAdClick}
                target="_blank"
              >
                Saiba mais
              </Link>
            </div>
          </div>
        )}
        {startAd && (
          <div className="timer-skip">
            {!skipEnabled ? (
              <button className="disabled">
                Você poderá pular o anúncio em {counterToJump}
              </button>
            ) : (
              <button
                className="enabled"
                onClick={() => {
                  onSkip();
                }}
              >
                {formatSeconds(progress)} {` Pular Anuncio`}
              </button>
            )}
          </div>
        )}
      </div>
    </Container>
  );
}
