import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  .selectAction {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .main {
    width: 100%;
    display: flex;
    gap: 30px;

    align-items: center;
    .preview {
      width: 480px;
      height: 270px;
      > iframe {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 8px;
      }
    }
    .areaInput {
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 220px;
      justify-content: center;
      gap: 20px;
    }
  }
  .title {
    width: 100%;
    font-size: 28px;
    font-weight: 700;
    line-height: 42px;
    color: var(--paleta2C);
    text-align: left;
  }
  .requisitos {
    width: 100%;
    display: flex;
    gap: 30px;

    .bold {
      font-weight: 700;
    }

    .areaLeft,
    .areaRight {
      flex: 1;
      background-color: var(--bgInput);
      padding: 15px;
      border-radius: 10px;
      .necessidades {
        margin-bottom: 10px;
        width: 100%;
        color: var(--paleta1B);
      }

      ol {
        li {
          margin-left: 15px;
          font-size: 13px;
          line-height: 17px;
          font-weight: 400;
          color: var(--texto);
        }
      }
    }
  }

  @media (max-width: 800px) {
    .main {
      display: flex;
      flex-direction: column;
      .info {
        width: 100%;
        padding-top: 15px;
        padding-bottom: 15px;
      }
      .areaInput {
        width: 100%;
      }
    }
    .requisitos {
      flex-direction: column;
      .areaLeft {
        width: 100%;
      }
      .areaRight {
        width: 100%;
      }
    }
  }
`;
