import { useEffect, useState } from 'react';
import apiDoubts from '../../services/api';
import { Container, Title, Hashtag, AreaHash } from './styles';

interface HProps {
  hash: (e: string) => void;
  currentHashtags?: string;
  title?: boolean;
  position: 'top' | 'lateral' 
}

export default function Hashtags({ hash, currentHashtags, title, position }: HProps) {
  const [data, setData] = useState<any>([]);
  const [hashtags, setHashtags] = useState<any>();

  useEffect(() => {
    getHash();
  }, []);

  async function getHash() {
    await apiDoubts
      .get('/hashtags-listar?limit=20')
      .then((response) => {
        setData(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleHashtags(hashtag: string) {
    try {
      var newArray: any[] = [];
      currentHashtags?.split(',').map((row: string) => {
        newArray.push(row);
      });

      const index = newArray.indexOf(hashtag);
      if (index !== -1) {
        newArray.splice(index, 1);
      } else {
        newArray.push(hashtag);
      }

      setHashtags(newArray);

      let hashs = String(newArray);
      if (hashs[0] === ',') {
        let newhashs = hashs.replace(',', '');
        hash(newhashs);
        return;
      } else {
        hash(hashs);
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  useEffect(() => {}, [currentHashtags]);

  return (
    <Container>
      {title && <Title># Top Hashs</Title>}
      <AreaHash pos={position}>
        {data?.map((response: any) => (
          <Hashtag
            key={response.id}
            estilo={hashtags?.includes(response.id)}
            onClick={() => handleHashtags(response.id)}
          >
            {response.hashtag}
          </Hashtag>
        ))}
      </AreaHash>
    </Container>
  );
}
