// Styles
import { Container, TermsListUl, TermsSubtitle, TermsText, TermsTextParagraph, TermsTitle } from "./styles";

export default function TermosUso() {
    return (
        <Container>
            <TermsTitle>Termos de uso da plataforma</TermsTitle>

            <TermsText>
                {/* Exemplo do P comum de texto */}
                <TermsTextParagraph>
                    Mussum Ipsum, cacilds vidis litro abertis. Tá deprimidis, eu conheço uma cachacis que pode alegrar sua vidis.Per aumento de cachacis, eu reclamis.Paisis, filhis, espiritis santis.Admodum accumsan disputationi eu sit. Vide electram sadipscing et per.
                </TermsTextParagraph>
                
                {/* Exemplo de subtitulo comum */}
                <TermsSubtitle>
                    Lorem, ipsum dolor sit amet consectetur adipisicing.
                </TermsSubtitle>

                {/* Adicionei um margem média aqui, de exemplo */}
                <TermsTextParagraph
                    className="mid-margin"
                >
                    Si num tem leite então bota uma pinga aí cumpadi!Paisis, filhis, espiritis santis.Posuere libero varius. Nullam a nisl ut ante blandit hendrerit. Aenean sit amet nisi.Não sou faixa preta cumpadi, sou preto inteiris, inteiris.
                </TermsTextParagraph>

                <TermsTextParagraph>
                    Viva Forevis aptent taciti sociosqu ad litora torquent.Em pé sem cair, deitado sem dormir, sentado sem cochilar e fazendo pose.Nec orci ornare consequat. Praesent lacinia ultrices consectetur. Sed non ipsum felis.Mais vale um bebadis conhecidiss, que um alcoolatra anonimis. Cevadis im ampola pa arma uma pindureta.Admodum accumsan disputationi eu sit. Vide electram sadipscing et per.Interagi no mé, cursus quis, vehicula ac nisi.Casamentiss faiz malandris se pirulitá.
                </TermsTextParagraph>

                <TermsTextParagraph>
                    Nulla, beatae voluptas nostrum dignissimos blanditiis cum quia laudantium voluptatum tempore ipsa deleniti quod quae veritatis explicabo corrupti, itaque vel expedita, reiciendis deserunt repudiandae obcaecati doloremque? Autem, nisi repellat illo amet pariatur ut exercitationem aut facilis harum incidunt, ipsum eaque distinctio nam id ex quis tenetur, expedita aliquam doloremque.
                </TermsTextParagraph>
                
                {/* Adicionei um margem grande aqui, de exemplo */}
                <TermsTextParagraph
                    className="big-margin"
                >
                    Ab non sunt expedita nulla itaque et, cumque velit sequi eius quasi soluta quod quidem illo totam obcaecati omnis. Placeat cumque ut incidunt soluta impedit error. Quam vero illum nam deserunt fugiat laborum quis quos exercitationem iste dolorum sit quidem, ratione delectus harum id vel officia aliquid corrupti modi repellat quibusdam odit veritatis autem ipsam! Consectetur totam eos ea quos exercitationem eius nemo quasi, iure facilis ratione iste quam, officia expedita? Nisi officiis culpa ducimus delectus rem nobis provident cum aut harum nemo perspiciatis dignissimos eum aliquid fugiat, totam impedit neque consequatur enim eius facilis ea aspernatur eligendi, maiores quidem.
                </TermsTextParagraph>

                <TermsSubtitle>
                    Mussum Ipsum, cacilds vidis litrus abertis.
                </TermsSubtitle>

                <TermsTextParagraph>
                    Ipsam minima quidem eligendi ad assumenda modi repellat nulla, aliquam soluta delectus eaque vitae voluptate qui totam accusamus tempore nisi doloribus incidunt dolorem velit inventore natus! Eligendi iure nesciunt excepturi perspiciatis dolores nam inventore laborum quo ea quasi aliquid sit explicabo architecto tempore itaque consequuntur repellendus dolorem maxime cupiditate voluptatibus, rerum qui aspernatur suscipit! Assumenda, magnam! Alias ut quibusdam tempora dolores excepturi, ex a suscipit eos numquam sapiente magni eaque aliquam, qui et tempore, ab repellat non eius dolorem! Hic.
                </TermsTextParagraph>

                {/* Exemplo de lista comum*/}
                <TermsListUl>
                    <div className="title-list">Documentos pessoais [Obrigatório 1 &#40;um&#41; dos documentos abaixo]: </div>
                    <li>RG e CPF/ME;</li>
                    <li>Carteira Nacional de Habilitação &#40;“CNH”&#41; válida;</li>
                    <li>Carteira das Forças Armadas;</li>
                    <li>Carteira de Conselho de Classe &#40;CRM, OAB e etc.&#41;, válida; ou</li>
                    <li>Registro Nacional de Estrangeiro &#40;“RNE”&#41;.</li>
                </TermsListUl>

                <TermsTextParagraph>
                    Si u mundo tá muito paradis? Toma um mé que o mundo vai girarzis! Nullam volutpat risus nec leo commodo, ut interdum diam laoreet. Sed non consequat odio.Per aumento de cachacis, eu reclamis. Tá deprimidis, eu conheço uma cachacis que pode alegrar sua vidis. Posuere libero varius. Nullam a nisl ut ante blandit hendrerit. Aenean sit amet nisi.Quem num gosta di mim que vai caçá sua turmis! Em pé sem cair, deitado sem dormir, sentado sem cochilar e fazendo pose. Nec orci ornare consequat. Praesent lacinia ultrices consectetur. Sed non ipsum felis.
                </TermsTextParagraph>
                  
            </TermsText>
        </Container>
    )
}