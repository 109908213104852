import styled from "styled-components";

export const Container = styled.div`
.bannerImageAgencia{
    width: 100%;
    height: 180px;
    background-position: center;
    background-size: cover;
    background-color: var(--background2);
    border-radius: 10px;
    position: relative;
    .logoAgencia{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 286px;
        height: 102px;
        background-position: center;
        background-size: cover;
        background-color: var(--background2);
        border-radius: 10px;
        margin-bottom: -50px;
        .areaMiniButtons{
            display: flex;
            gap: 10px;
            position: absolute;
            bottom: 0;
            margin-left: 296px;
        }
    }
}
.textHeaderAgencia{
    margin-top: 70px;

    .topTextHeaderAgencia{
        display: flex;
        justify-content: space-between;
        .textAndButtonSeguir{
            display: flex;
            align-items: center;
            gap: 10px;
        }
        .nameAgencia{
            font-size: 28px;
            line-height: 42px;
            color: var(--paleta2C);
            font-weight: 700;
        }

    }
    .agenciaDescription{
        font-size: 13px;
        line-height: 18px;
        color: var(--texto);
        font-weight: 400;
    }
}
`