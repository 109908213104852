import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: calc(100vh - 110px);

  .headerEmailSelected {
    width: 100%;
    height: 84px;
    border-radius: 10px;
    padding: 30px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: var(--paleta2C);
    margin-bottom: 20px;

    .init {
      display: flex;
      gap: 15px;
      align-items: center;
      .emailSelectTitle {
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        color: var(--branco);
      }
    }
    .dateEmailSelect {
      font-size: 11px;
      line-height: 14px;
      font-weight: 400;
      color: var(--branco);
    }
  }

  @media (max-width: 800px){
    flex-direction: column;
    gap: 10px;
  }
`;

export const Left = styled.div`
  width: 208px;
  background-color: var(--background2);
  border-radius: 10px;
  padding: 10px;

  .buttonsArea{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
  }

  .areaConsultor {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    @media (max-width: 800px) {
    }
    .imageProfile {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      border: 3px solid var(--branco);
      background-position: center;
      background-size: cover;
    }
    .nameMailRemetente {
      font-size: 16px;
      font-weight: 700;
      color: var(--paleta2E);
      line-height: 20px;
      max-width: 85%;
      overflow: hidden;
    }
  }

  @media (max-width: 800px){
   width: 100%;
  }
`;

export const Rigth = styled.div`
  width: calc(100% - 208px);
  .loadingWrapper {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 800px){
   width: 100%;
  }
`;
