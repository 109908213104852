import { useEffect, useState } from 'react';
import apiDoubts from '../../../services/api';
import CardPost from '../CardPost';
import { Container } from './styles';
import GIF from '../../../assets/not-found.gif';
import { toast } from 'react-toastify';
import { IconLoading } from '../../Icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AxiosResponse } from 'axios';
import CardConsultorConfigEmpresa from '../../Empresa/CardUserSearch';
import CardUserSearch from '../../Empresa/CardUserSearch';
import { useHistory } from 'react-router-dom';

interface IProps {
  playlist?: any;
  posicao?: string;
  where?: {
    interesse?: string;
    search?: string;
    hash?: string;
  };
  order?: string;
  consultorId?: string;
  tipo?: string;
}

export default function SearchPosts({
  playlist,
  posicao,
  where = {
    hash: '',
    search: '',
    interesse: '',
  },
  order = 'popular',
  consultorId,
  tipo,
}: IProps) {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [completeData, setCompleteData] = useState<any>();

  const [usersSearch, setUsersSearch] = useState<any>();

  const [consultorNumber, setConsultorNumber] = useState<number>(10);
  const [empresaNumber, setEmpresaNumber] = useState<number>(10);

  const history = useHistory();

  useEffect(() => {
    getData();
  }, [playlist, page]);

  useEffect(() => {
    if (!consultorId && tipo !== 'playlist') {
      getData([]);
    }

    if (where?.search) {
      getUsers(where.search);
    }
  }, [where, order]);

  async function getData(dados?: any) {
    setLoading(true);
    try {
      {
        page === 1 && setData([]);
      }

      let response: any;

      response = await apiDoubts.get(
        `/postagens/pesquisa/?limit=15&order=${order}&page=${page}&pagination=true&pesquisa=${where.search}`
      );

      setCompleteData(response);
      const newData: any = [];


      response?.data?.data?.allPosts?.map((row: any, key: number) => {
        newData.push(row);
      });

      if (!dados[0]) {
        //quando eu quero que apareça apenas os vídeos que foram puxados agora
        setData([...newData]);
      }

      if (dados[0]) {
        //quando eu quero carregar novas postagens
        console.log('adiciona postagens');
        setData([...dados, ...newData]);
      }

      // setData( [...data, ...newData]);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  const handleScroll = (event: any) => {
    console.log('scrollTop: ', event.currentTarget.scrollTop);
    console.log('offsetHeight: ', event.currentTarget.offsetHeight);
  };

  async function getUsers(userName: string) {
    await apiDoubts
      .get(`/postagens/consultores-empresa?pesquisa=${userName}`)
      .then((response: AxiosResponse<any>) => {
        setUsersSearch(response?.data?.data);
      });
  }

  return (
    <Container
      loading={loading}
      lenght={data?.length}
      posicao={posicao}
      playlist={playlist}
      onScroll={handleScroll}
    >
      {usersSearch?.consultores?.length > 0 && (
        <>
          <h2 className="titleUserType">Consultores Encontrados...</h2>
          <div className="userWrapper">
            {usersSearch?.consultores.map((row: any, key: number) => {
              if (key + 1 <= consultorNumber) {
                return (
                  <CardUserSearch
                    consultorData={row}
                    navigateToPage={() => {
                      history.push(`/consultor/visualizar/canal/${row?._id}`);
                    }}
                  />
                );
              }
            })}
          </div>
        </>
      )}

      {usersSearch?.empresas?.length > 0 && (
        <>
          <h2 className="titleUserType">Empresas Encontrados...</h2>
          <div className="userWrapper">
            {usersSearch?.empresas.map((row: any, key: number) => {
              if (key + 1 <= consultorNumber) {
                return (
                  <CardUserSearch
                    consultorData={row}
                    navigateToPage={() => {
                      history.push(`/empresa/visualizar/canal/${row?._id}`);
                    }}
                  />
                );
              }
            })}
          </div>
        </>
      )}

      <InfiniteScroll
        dataLength={data?.length} //This is important field to render the next data
        next={() => {
          setPage(completeData?.data?.data?.nextPage);
        }}
        hasMore={true}
        loader={<h4></h4>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        {data?.length > 1 &&
          data?.map((row: any, key) => (
            <CardPost
              playlist={playlist}
              key={key}
              item={row}
              type={row?.type ? 'anuncio' : tipo}
              posicao={posicao}
            />
          ))}

        {loading == false && data?.length === 1 && (
          <div className="empty-posts">
            <img src={GIF} />
            <p>Nenhuma postagem encontrado.</p>
          </div>
        )}

        {loading && (
          <div className="divLoading">
            <IconLoading />
          </div>
        )}
      </InfiniteScroll>
    </Container>
  );
}
