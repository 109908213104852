import { useContext, useState } from 'react';
import CardEmpresaConfig from '../../../../components/Cards/CardEmpresaConfig';
import UserContext from '../../../../contexts/UserContext';
import { Container } from './styles';
import EmptyPosts from '../../../../components/EmptyPosts';
import apiDoubts from '../../../../services/api';

export default function Empresas() {
  const { user }: any = useContext(UserContext);
  const [empresasSelecionadas, setEmpresasSelecionadas] = useState<any[]>([]);

  function handleAgenciaSelecionadas(id: string) {
    if (empresasSelecionadas.includes(id)) {
      let arrayConsultores = empresasSelecionadas;
      let newArray = arrayConsultores.filter((val: any) => {
        return val != id;
      });
      setEmpresasSelecionadas([...newArray]);
      console.log('já tinha', newArray);
    } else {
      let newArray = empresasSelecionadas;
      newArray.push(id);
      setEmpresasSelecionadas([...newArray]);
      console.log('ainda não tinha', newArray);
    }
  }

  function removeVinculo(account: any) {
    let accountType;

    if (account?.listaAgencias && account?.listaEmpresas) {
      accountType = 'consultor';
    } else if (account?.listaConsultores && account?.listaEmpresas) {
      accountType = 'agencia';
    } else if (account?.listaAgencias && account?.listaConsultores) {
      accountType = 'empresa';
    }

    apiDoubts?.put(`/finalizarVinculo`, {
      idParaRemover: account?._id,
      tipo: accountType,
    });
  }

  function handleMessage() {
    const isAnConsultor = user?.listaEmpresas;

    let message;

    if (isAnConsultor) {
      message = 'Associe-se com empresas para que elas possam aparecer aqui';
      return message;
    }

    message = 'Envie convite para que elas possam aparecer aqui';
    return message;
  }

  const isEmptyBussines = user?.empresas?.length === 0 || !user?.empresas;

  return (
    <Container>
      {isEmptyBussines && (
        <EmptyPosts
          title="Ops, você não esta associado com nenhuma empresa"
          description={handleMessage()}
        />
      )}

      {user?.empresas?.map((row: any) => (
        <CardEmpresaConfig
          checked={empresasSelecionadas?.includes(row?.id)}
          empresaData={row}
          setChecked={(idEmpresa: string) => {
            handleAgenciaSelecionadas(idEmpresa);
          }}
          deleteEmpresa={removeVinculo}
          key={row.id}
        />
      ))}
      {user?.empresas?.map((row: any) => (
        <CardEmpresaConfig
          checked={empresasSelecionadas?.includes(row?.id)}
          empresaData={row}
          setChecked={(idEmpresa: string) => {
            handleAgenciaSelecionadas(idEmpresa);
          }}
          deleteEmpresa={removeVinculo}
          key={row.id}
        />
      ))}
    </Container>
  );
}
