import { HTMLAttributes } from 'react';
import BackArrowIcon from '../../assets/icons/BackArrow';
import ButttonDefault from '../ButtonDefault';
import { IconWarning } from '../Icons';
import { ContainerOverlay, Container } from './styles';

interface ModalProps extends HTMLAttributes<HTMLDivElement> {
  id: string;
  children: any;
  onClose: () => void;
  openModal: boolean;
  width?: string;
  prosseguir: () => void;
  textProsseguir?: string;
  textCancel?: string;
  hiddenIcon?: boolean;
  loadingProsseguir?: boolean;
  hiddenFooter?: boolean;
}

export default function ModalAlert({
  prosseguir,
  loadingProsseguir,
  textProsseguir = 'Prosseguir',
  textCancel = 'Cancelar',
  id,
  onClose,
  hiddenIcon,
  width,
  openModal,
  children,
  hiddenFooter = false,
  ...rest
}: ModalProps) {
  const handleCloseModal = (e: any) => {
    if (e.target.id === id) {
      onClose();
    }
  };

  return (
    <ContainerOverlay
      id={id}
      openModal={openModal}
      onClick={handleCloseModal}
      {...rest}
    >
      <Container width={width} openModal={openModal}>
        <div className="container-modal">
          <div className="head-icon">
            {openModal && hiddenIcon !== true && <IconWarning size={80} />}
          </div>
          <div className="content-modal">{children}</div>
          {hiddenFooter === false && (
            <div className="footer">
              <ButttonDefault
                tipo={'15'}
                title={textCancel}
                onClick={onClose}
              />
              <ButttonDefault
                icon={loadingProsseguir ? 'Loading' : ''}
                tipo={'16'}
                title={textProsseguir}
                onClick={prosseguir}
              />
            </div>
          )}
        </div>
      </Container>
    </ContainerOverlay>
  );
}
