import { useContext, useEffect, useRef, useState } from 'react';
import UserContext from '../../../contexts/UserContext';
import { Container } from './styles';

interface messageProps {
  attMessage: (message: string) => void;
  valueMessage: string;
}

export default function AddMessage({ attMessage, valueMessage }: messageProps) {
  const { user } = useContext<any>(UserContext);
  const [textAreaRows, setTextAreaRows] = useState<number>(2);

  const textAreaRef: any = useRef();

  function handleMessage(message: string) {
    attMessage(message);
  }

  useEffect(() => {
    console.log(typeof(valueMessage), valueMessage);
    if (
      !valueMessage     
    ) {
      setTextAreaRows(2);
    }
  }, [valueMessage]);

  useEffect(() => {
    textAreaRef.current?.addEventListener('scroll', () => {
      let actualValue = textAreaRows;
      if (actualValue <= 8) {
        setTextAreaRows(actualValue + 4);
      } else {
        setTextAreaRows(8);
      }
    });
  }, []);

  return (
    <Container>
      <div className="left">
        <div
          className="imagePerfil"
          style={{ backgroundImage: `url(${user?.foto})` }}
        />
      </div>
      <div className="right">
        <p className="nameUser">{user?.nickName}</p>
        <textarea
          ref={textAreaRef}
          rows={textAreaRows}
          className="newMessage"
          onChange={(e: any) => {
            handleMessage(e.target.value);
          }}
          value={valueMessage}
          placeholder={'Sua mensagem aqui...'}
        
        />
      </div>
    </Container>
  );
}
