import ButttonDefault from '../../ButtonDefault';
import { Container } from './styles';

interface props {
  data: any;
}

export default function CardProdutosSevicos({ data }: props) {
  return (
    <Container>
      <div
        className="top"
        style={{ backgroundImage: `url(${data?.imagem})` }}
      ></div>
      <div className="bottom">
        <p className="titleCard">{data.nome}</p>
        <ButttonDefault
          title={data.textBotao}
          full={true}
          tipo="7"
          hover="medio"
          onClick={() => {
            window.location.href = data.link;
          }}
        />
      </div>
    </Container>
  );
}
