import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import CardResumo from '../../../components/Campanha/CardResumo';
import InfosAdsense from '../../../components/Campanha/InfosAdsense';
import Midias from '../../../components/Campanha/Midias';
import TypeAdsense from '../../../components/Campanha/TypesAdsense';
import {
  IconCampanha,
  IconInfo,
  IconInformacoes,
} from '../../../components/Icons';
import apiDoubts from '../../../services/api';
import { Container, ItemMenu, Left, LeftHeader, Right } from './styles';
import { useDebouncedCallback } from 'use-debounce';
import UserContext from '../../../contexts/UserContext';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

export interface IDTOAdsense {
  nome: string;
  descricao: string;
  link: string;
  valor_dia: string;
  data_inicio: string;
  data_fim: string;
  idade_mim: number;
  idade_max: number;
  sexo: string;
  consultorId: string;
  listaInteresses: any[];
  listaInteressesID: string[];
  banner_70x70: string;
  banner_322x227: string;
  banner_322x673: string;
  banner_1024x90: string;
  video: string;
  tipo: string;
  limite_gasto: string;
  empresaId: string;
  interesses: any;
}

export default function CampanhaEditar() {
  const [actualPage, setActualPage] = useState<
    'Tipo de Campanha' | 'Informações' | 'Mídias'
  >('Tipo de Campanha');
  const [totalInvest, setTotalInvest] = useState<number>(0);
  const [resumeCampaing, setResumeCampaing] = useState<number>(0);

  const menu = [
    { label: 'Tipo de Campanha', icon: <IconCampanha color={'#069CD1'} /> },
    { label: 'Informações', icon: <IconInfo color={'#069CD1'} size="20" /> },
    { label: 'Mídias', icon: <IconInformacoes color={'#069CD1'} /> },
  ];

  const { user } = useContext(UserContext);
  const params = useParams<any>();

  const debounced = useDebouncedCallback(() => {
    getResumeCampaing();
  }, 600);

  const [DTO, setDTO] = useState<IDTOAdsense>({
    nome: '',
    descricao: '',
    link: '',
    valor_dia: '',
    data_inicio: '',
    data_fim: '',
    idade_mim: 25,
    idade_max: 75,
    sexo: '',
    consultorId: '',
    listaInteresses: [],
    listaInteressesID: [],
    banner_70x70: '',
    banner_322x227: '',
    banner_322x673: '',
    banner_1024x90: '',
    video: '',
    tipo: '',
    limite_gasto: '',
    empresaId: user?.tipo === 'empresa' ? user?.id : '',
    interesses: '',
  });

  useEffect(() => {
    debounced();
  }, [
    DTO.idade_max,
    DTO.idade_mim,
    DTO.sexo,
    DTO.listaInteresses,
    DTO.consultorId,
  ]);

  useEffect(() => {
    if (DTO.valor_dia && DTO.data_fim && DTO.data_inicio) {
      handleDifferencerDate();
    }
  }, [DTO.valor_dia, DTO.data_fim, DTO.data_inicio]);

  useEffect(() => {
    checkInitDate();
  }, [DTO.data_inicio]);

  useEffect(() => {
    if (DTO.data_fim && DTO.data_inicio) {
      checkEndDate();
    }
  }, [DTO.data_fim, DTO.data_inicio]);

  useEffect(() => {
    if (DTO.tipo) {
      setActualPage('Informações');
    }
  }, [DTO?.tipo]);

  useEffect(() => {
    getCampaing();
  }, []);

  useEffect(() => {
    if (DTO?.consultorId) {
      getConsultorByID(DTO.consultorId);
    }
  }, [DTO?.consultorId]);

  useEffect(() => {
    console.log(DTO);
  }, [DTO]);

  function handleOnChange(name: any, value: any) {
    const newDTO: any = DTO;
    newDTO[name] = value;
    setDTO({ ...newDTO });
  }

  async function checkInfosCampaing() {
    try {
      if (!DTO?.tipo) throw new Error('Escolha o tipo da campanha');
      if (!DTO.nome) throw new Error(`Escolha o nome da campanha`);
      if (!DTO?.consultorId && !DTO.listaInteresses)
        throw new Error(
          'A campanha precisa ou de um consultor ou de uma lista de interesses para ser veiculada'
        );
      if (!DTO?.descricao)
        throw new Error('A campanha precisa de uma descrição');
      if (!DTO?.data_inicio)
        throw new Error(
          'A campanha precisa de uma data para começar a ser veiculada'
        );
      if (!DTO?.data_fim)
        throw new Error('A campanha precisa de uma data para encerrar');
      if (!DTO?.valor_dia)
        throw new Error('Escolha um orçamento diário para a campanha');
      if (!DTO?.limite_gasto)
        throw new Error('É necessário selecionar um limite para ser gasto');
      if (totalInvest - parseFloat(DTO?.limite_gasto) < 0)
        throw new Error(
          `os gastos da sua campanha são de R$${totalInvest}, você não pode reduzir esse valor. Caso queira, você poder reduzir o valor diário`
        );
      if (!DTO?.link)
        throw new Error('A campanha precisa de um link de redirecionamento');
      if (handleValidateURL() === false)
        throw new Error('A URL digitada não está em um formato válido');
      if (!DTO.sexo) throw new Error('Selecione um sexo para a campanha');
      if (!DTO?.empresaId && user?.tipo === 'agencia')
        throw new Error('Escolha para qual empresa será feita essa campanha');

      if (DTO.tipo === 'banner') {
        if (!DTO.banner_70x70)
          throw new Error('Não identicamos o banner 70 x 70px');
        if (!DTO.banner_322x227)
          throw new Error('Não identicamos o banner 322 x 227px');
        if (!DTO.banner_322x673)
          throw new Error('Não identicamos o banner 322 x 673px');
        if (!DTO.banner_1024x90)
          throw new Error('Não identicamos o banner 1024 x 90px');
      }

      if (DTO.tipo === 'video') {
        if (!DTO.video)
          throw new Error('Não identificamos o vídeo da campanha');
      }

      return await postCampaing();
    } catch (error: any) {
      toast.error(error.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  function checkInitDate() {
   return
  }

  function checkEndDate() {
    try {
      if (moment(DTO?.data_fim).isBefore(DTO?.data_inicio)) {
        handleOnChange('data_fim', '');
        throw new Error('A Campanha deve terminar após o seu início');
      }
    } catch (error: any) {
      toast.error(error.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  function handleDifferencerDate() {
    try {
      var date1 = new Date(DTO?.data_inicio);
      var date2 = new Date(DTO?.data_fim);
      var timeDiff = Math.abs(date2.getTime() - date1.getTime());
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      let totalInvest: number = diffDays * parseFloat(DTO?.valor_dia);
      setTotalInvest(totalInvest);
      handleOnChange('limite_gasto', totalInvest.toString());
    } catch (error: any) {
      toast.error(error.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  function handleValidateURL() {
    try {
      let newURL = new URL(DTO.link);
      return true;
    } catch (error) {
      return false;
    }
  }

  async function postCampaing() {
    const response = await apiDoubts.put(`campanha/${params.campanhaId}`, DTO);
    toast.success('Campanha enviada para análise com sucesso', {
      className: 'toast-sucess',
      theme: 'colored',
    });
    return response;
  }

  async function getResumeCampaing() {
    if (
      (DTO.consultorId || DTO?.listaInteresses.length > 0) &&
      DTO.idade_max &&
      DTO.idade_mim &&
      DTO.sexo
    ) {
      const filter = {
        tipo: DTO.consultorId ? 'consultor' : 'interesses',
        id: DTO.listaInteresses.length ? DTO.listaInteresses : DTO.consultorId,
        idadeMin: DTO.idade_mim,
        idadeMax: DTO.idade_max,
        sexo:
          DTO?.sexo === 'M'
            ? 'masculno'
            : DTO?.sexo === 'F'
            ? 'feminino'
            : 'todos',
      };
      // await apiDoubts
      //   .post(`campanha-resumo`, filter)
      //   .then((response: any) => {
      //     setResumeCampaing(response.data.data);
      //   })
      //   .catch((error: any) => {
      //     console.error(error.message);
      //   });
    }
  }

  async function getCampaing() {
    await apiDoubts
      .get(`campanha-listar`)
      .then((response: AxiosResponse) => {
        let allCampaing: Array<any> = [];
        if (user.tipo === 'empresa') {
          allCampaing = response?.data?.data.empresa;
        } else {
          allCampaing = response?.data?.data.agencia;
        }
        allCampaing = allCampaing.filter((row: any) => {
          if (row.id === params.campanhaId) {
            return row;
          }
        });
        handleCampaingAndDTO(allCampaing[0]);
      })
      .catch((error: any) => {
        console.error(error);
      });
  }

  function handleCampaingAndDTO(campaing: IDTOAdsense) {
    console.log('========> campaings', campaing);

    let newDTO = {
      nome: campaing?.nome ?? '',
      descricao: campaing?.descricao ?? '',
      link: campaing?.link ?? '',
      valor_dia: campaing?.valor_dia ?? '',
      data_inicio: campaing?.data_inicio ?? '',
      data_fim: campaing?.data_fim ?? '',
      idade_mim: campaing?.idade_mim ?? 25,
      idade_max: campaing?.idade_max ?? 45,
      sexo: campaing?.sexo ?? '',
      consultorId: campaing?.consultorId ?? '',
      listaInteresses: campaing?.listaInteresses ?? [],
      listaInteressesID: campaing?.listaInteressesID ?? [],
      banner_70x70: campaing?.banner_70x70 ?? '',
      banner_322x227: campaing?.banner_322x227 ?? '',
      banner_322x673: campaing?.banner_322x673 ?? '',
      banner_1024x90: campaing?.banner_1024x90 ?? '',
      video: campaing?.video ?? '',
      tipo: campaing?.tipo ?? '',
      limite_gasto: campaing?.limite_gasto ?? '',
      empresaId: user?.tipo === 'empresa' ? user?.id : '',
      interesses: campaing.interesses,
    };
    setDTO(newDTO);
  }

  async function getConsultorByID(id: string) {
    await apiDoubts
      .get(`/consultor/get-by-id/${id}`)
      .then((response: AxiosResponse) => {})
      .catch((error: AxiosError) => {
        console.error(error.message);
      });
  }

  return (
    <Container>
      <Left>
        <LeftHeader>
          <h1 className="pageTitleAdsense">Nova Campanha</h1>
          <h2 className="pageSubtitleAdsense">
            Personalize sua campanha para alcançar o máximo possível
          </h2>

          <div className="pagesMenu">
            {menu.map((row: any, key: number) => {
              if (row.label !== 'Mídias') {
                return (
                  <ItemMenu
                    key={key}
                    isSelected={row?.label === actualPage}
                    onClick={() => {
                      setActualPage(row?.label);
                    }}
                  >
                    {row?.icon}
                    <span className="textCardMenu">{row?.label}</span>
                  </ItemMenu>
                );
              } else {
                if (DTO?.tipo) {
                  return (
                    <ItemMenu
                      key={key}
                      isSelected={row?.label === actualPage}
                      onClick={() => {
                        setActualPage(row?.label);
                      }}
                    >
                      {row?.icon}
                      <span className="textCardMenu">{row?.label}</span>
                    </ItemMenu>
                  );
                }
              }
            })}
          </div>
        </LeftHeader>

        <div className="main">
          {actualPage === 'Tipo de Campanha' && (
            <TypeAdsense
              data={DTO}
              setPage={(page) => {
                setActualPage(
                  page as 'Tipo de Campanha' | 'Informações' | 'Mídias'
                );
              }}
              handleOnChangeDTO={(name: string, value: any) => {
                const newDTO: any = DTO;
                newDTO[name] = value;
                setDTO({ ...newDTO });
              }}
            />
          )}

          {actualPage === 'Informações' && (
            <InfosAdsense
              page="edit"
              data={DTO}
              setPage={(page) => {
                setActualPage(
                  page as 'Tipo de Campanha' | 'Informações' | 'Mídias'
                );
              }}
              handleOnChangeDTO={(name: string, value: any) => {
                const newDTO: any = DTO;
                newDTO[name] = value;
                setDTO({ ...newDTO });
              }}
            />
          )}

          {actualPage === 'Mídias' && (
            <Midias
              data={DTO}
              setPage={(page) => {
                setActualPage(
                  page as 'Tipo de Campanha' | 'Informações' | 'Mídias'
                );
              }}
              handleOnChangeDTO={(name: string, value: any) => {
                const newDTO: any = DTO;
                newDTO[name] = value;
                setDTO({ ...newDTO });
              }}
              sendCampaing={async () => {
                return await checkInfosCampaing();
              }}
            />
          )}
        </div>
      </Left>

      <Right>
        <h2 className="adsenseResume">Resumo da Campanha</h2>
        <CardResumo>
          <h3 className="titleCardCampaing">Total Investido</h3>
          <h3 className="ValueInvesting">
            <span className="cifrao">R$</span>
            {DTO.limite_gasto ? DTO.limite_gasto : ' --'}
            {DTO.limite_gasto && ',00'}
          </h3>
          <h3 className="valorPorDia">R$ {DTO?.valor_dia} por dia</h3>
        </CardResumo>
        {/* <CardResumo>
          <h3 className="titleCardCampaing">Possível Alcance</h3>
          <h3 className="ValueInvesting"><span className="cifrao">até</span>{resumeCampaing ? resumeCampaing : ' --'}</h3>
        </CardResumo> */}
      </Right>
    </Container>
  );
}
