import styled, { css } from 'styled-components';

interface props {
  direction: string
}

export const Container = styled.div<props>`
  ${({ direction }) =>
    direction !== 'row' &&
    css`
      border-bottom: 1px solid #e7e7f3;
    `}

  .areaTitle {
    display: flex;
    align-items: center;
  }

  .areaCard {
    margin-top: 12px;
    ${({ direction }) =>
      direction === 'row' &&
      css`
        display: flex;
        gap: 10px;
      `}
  }

  .topConsultores {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      transform: scale(1.05);
      transition: all 0.2s;
    }

    .TextArea {
      margin-left: 5px;
      display: flex;
      flex-direction: column;

      .moment {
        font-size: 13px;
        font-weight: 400;
        line-height: 14px;
        margin-top: 1.5px;
        color: #7d7d7d;
      }

      .nick {
        color: var(--paleta1B);
        font-size: 13px;
        font-weight: 700;
        line-height: 15px;
        margin-right: 2px;
      }
    }

    .btnArea {
      margin-left: 8px;
      display: flex;
      margin-top: 4px;

      a {
        text-decoration: none;
      }
    }
  }

  @media (max-width: 800px) {
    .areaCard {
      display: flex;
      gap: 20px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
        position: relative;
      }
    }
  }
`;

export const Title = styled.h2`
  color: var(--purple2);
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 4px;
  margin-top: 12px;
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  span {
    max-width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const Profile = styled.div`
  min-height: 50px;
  min-width: 50px;
  border-radius: 100%;
  background-position: center;
  background-size: cover;
  background-color: #E1E1E5
`;
