// import { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
// import { PuffLoader } from "react-spinners";
// import Checkbox from "../../../components/Checkbox/Checkbox";
// import apiDoubts from "../../../services/api";
// import { Container, Content, ContentLeft, ContentRight } from "./styles"
// import { toast } from 'react-toastify';

// const EditVideo: React.FC = (props:any) => {
//     const history = useHistory()

//     const [loading, setLoading] = useState(true)
//     const id = props.match.params.id
//     const [item, setItem] = useState<any>({})
//     const [checked, setChecked] = useState<boolean>(false);
//     // const [setEditVideo] = useState<any>();
//     const [DTO, setDTO] = useState<any>({
//         titulo: item.titulo,
//         video: item.video,
//         thumbnail: item.thumbnail,
//         destaque: 0,
//     })

//     useEffect(() => {

//         async function getPost()
//         {
//             try {
//                 setLoading(true)
//                 const response:any = await apiDoubts.get('postagens/getPostagem/'+id)
//                 if ( response.data.status !== 'success' ) throw new Error ('Não foi possível encontrar as postagens')
//                 setItem({...response.data.data})
//                 setDTO({...response.data.data})
//                 setLoading(false)
//             }
//             catch {
//                 toast.error('Não foi possível encontrar postagens.', {
//                     className: "toast-error",
//                     theme: "colored"
//                 })
//             }
//         }

//         getPost()

//     }, [id])

//     async function changePost() {
//         try {
//             setLoading(true)
//             const response: any = await apiDoubts.put('postagens/'+id, {
//                 titulo: DTO.titulo,
//                 video: DTO.video,
//                 thumbnail: DTO.thumbnail,
//                 destaque: DTO.destaque,
//             })

//             console.log(response.data)

//             if (response.data.status === 'success') {
//                 toast.success("Alterações enviadas com sucesso.", {
//                     className: "toast-sucess",
//                     theme: 'colored'
//                 });
//             }

//             if (response.data.status === 'error') throw new Error(response.data.message)

//             //setEditVideo({ ...response.data.data })
//             setLoading(false)
//             history.goBack()
//         } catch (err:any) {
//             toast.error(err.toString(), {
//                 className: "toast-error",
//                 theme: "colored"
//             })
//             setLoading(false)
//         }

//     }

//     function handleSubmit(e: any) {
//         e.preventDefault();

//         if (DTO.titulo === "") {
//             toast.error('Campo "Título" não pode estar vazio', {
//                 className: "toast-error",
//                 theme: "colored"
//             })
//         }
//         else if (DTO.video === "") {
//             toast.error('Campo "URL do vídeo" não pode estar vazio', {
//                 className: "toast-error",
//                 theme: "colored"
//             })
//         }
//         else if (DTO.thumbnail === "") {
//             toast.error('Campo "Thumbnail" não pode estar vazio', {
//                 className: "toast-error",
//                 theme: "colored"
//             })
//         }
//         else {
//             changePost();
//         }
//     }

//     function handleOnChange(name: any, value: any) {
//         const newDTO: any = DTO
//         newDTO[name] = value
//         setDTO({ ...newDTO })
//     }

//     function changeChecked() {
//         setChecked(!checked)
//         if(checked === true) {
//             setDTO({
//                 titulo: DTO.titulo,
//                 video: DTO.video,
//                 thumbnail: DTO.thumbnail,
//                 destaque: 0,
//             })
//         }
//         else {
//             setDTO({
//                 titulo: DTO.titulo,
//                 video: DTO.video,
//                 thumbnail: DTO.thumbnail,
//                 destaque: 1,
//             })
//         }
//     }

//     function cancelChanges() {
//         setDTO({
//             titulo: '',
//             video: '',
//             thumbnail: '',
//             destaque: 0,
//         })
//         history.push('/')
//     }

//     return (
//         <Container>
//             <Content>

//                 <ContentLeft>
//                     <h2>Detalhes do vídeo</h2>
//                     <form onSubmit={(e) => handleSubmit(e)}>
//                         <div className="title">
//                             <label>Título</label>
//                             <input
//                                 type="text"
//                                 name="Título"
//                                 value={DTO.titulo}
//                                 placeholder={item.titulo}
//                                 onChange={(e) => handleOnChange('titulo', e.target.value)}
//                             />
//                         </div>

//                         <div className="link">
//                             <label>URL do vídeo</label>
//                             <input
//                                 type="text"
//                                 name="Vídeo"
//                                 value={DTO.video}
//                                 placeholder={item.video}
//                                 onChange={(e) => handleOnChange('video', e.target.value)}
//                             />
//                         </div>

//                         <div className="link">
//                             <label>Thumbnail</label>
//                             <input
//                                 type="text"
//                                 name="Thumbnail"
//                                 value={DTO.thumbnail}
//                                 placeholder={item.thumbnail}
//                                 onChange={(e) => handleOnChange('thumbnail', e.target.value)}
//                             />
//                         </div>

//                         <div className="highlights" style={{display: 'none'}}>
//                             <label>Destaque</label>
//                             <Checkbox
//                                 name="Destaque"
//                                 value={checked}
//                                 onClick={() => changeChecked()}
//                             />
//                         </div>

//                         <button>
//                             Enviar
//                         </button>

//                         <button
//                             type="button"
//                             className="cancel"
//                             onClick={() => cancelChanges()}
//                         >
//                             Cancelar
//                         </button>
//                     </form>
//                 </ContentLeft>

//                 <ContentRight>
//                     <h2>{item.titulo}</h2>

//                     <div className="video">
//                         {loading !== true
//                             ?
//                             <iframe title="titulo genérico" src={item.video.replace('/watch?v=', '/embed/')} />
//                             :
//                             <div className='loader'>
//                                 <PuffLoader size={120} />
//                             </div>
//                         }
//                     </div>

//                     <div className="highlights">
//                         {
//                             item.destaque > 0
//                             ?
//                             <p className="with">
//                                 Vídeo com destaque
//                             </p>
//                             :
//                             <p className="without">
//                                 Vídeo sem destaque
//                             </p>
//                         }
//                     </div>
//                 </ContentRight>

//             </Content>
//         </Container>
//     )
// }

// export default EditVideo;

import { createRef, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import SelectDefaultSearch from '../../../components/Form/SelectDefaultSearch';
import Inputdefault from '../../../components/Inputs/InputDefault';
import InputFile from '../../../components/ButtonFile';
import SelectOption from '../CriarConteudo/SelectOption';
import { Container } from './styles';
import apiDoubts from '../../../services/api';
import ListHashStateless from '../../../components/Hashtags/ListStateless';
import ComentarArea from '../../../components/ComentarArea';
import ButttonDefault from '../../../components/ButtonDefault';
import VideoPreview from '../../../components/VideoPreview';
import CardPost from '../../../components/Posts/CardPost';
import CardPreview from '../../../components/CardPreview';
import ModalAlert from '../../../components/ModalAlert';
import { toast } from 'react-toastify';
import moment from 'moment';
import ModalSucess from '../../../components/ModalSucess';
import { useDebouncedCallback } from 'use-debounce';
import InputHash from '../../../components/Inputs/InputHash';
import bucket from '../../../services/bucket';
import { IconInfo } from '../../../components/Icons';

export default function CriarConteudo() {
  const params = useParams<any>();
  const history = useHistory();
  const [optionSelected, setOptionSelected] = useState<string>('Enviar Vídeo');
  const [hashtags, setHashtags] = useState<any[]>([]);
  const [categorias, setCategorias] = useState<any[]>([]);
  const [optionCategorias, setOptionsCategorias] = useState<any[]>([]);
  const [focus, setFocus] = useState(false);
  const [modal, setModal] = useState<boolean>(false);
  const [modalSucess, setModalSucess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [videoId, setVideoId] = useState<string>('');
  const [hash, setHash] = useState<string>('');
  const [categ, setCateg] = useState<string>('');
  const [DTO, setDTO] = useState<any>({
    titulo: '',
    video: '',
    thumbnail: '',
    data: moment(new Date()).format('DD-MM-yyyy hh:mm:00'),
    visualizacoes: 0,
    listaInteresses: [],
    tipo: 'comum',
    listaHashtags: [],
    descricao: '',
  });

  useEffect(() => {
    goToPage();
  }, [optionSelected]);

  useEffect(() => {
    getCategorias();
  }, []);

  useEffect(() => {
    if (params.id) {
      getVideo();
    }
  }, []);

  useEffect(() => {
    // console.log('optionCategorias', optionCategorias)
  }, [optionCategorias]);

  function goToTransmissao() {
    setModal(false);
    history.push('/live');
  }

  function handleOnChange(name: any, value: any) {
    const newDTO: any = DTO;
    newDTO[name] = value;
    setDTO({ ...newDTO });
    return;
  }

  function goToPage() {
    if (
      DTO.titulo !== '' ||
      DTO.video !== '' ||
      DTO.thumbnail !== '' ||
      DTO.listaInteresses.lenght > 0 ||
      DTO.listaHashtags.length > 0 ||
      DTO.descricao !== ''
    ) {
      setModal(true);
      setOptionSelected('Enviar Vídeo');
      return;
    }
    if (optionSelected === 'Transmissão ao Vivo') history.push('/live');
  }

  useEffect(() => {
    console.log(DTO);
  }, [DTO]);

  function handleHash(e: any) {
    if (e === '') return;

    let hashSelectd = DTO.listaHashtags;

    var newHash = `#${e}`;

    if (e.includes('#')) {
      newHash = e;
    }

    if (hashSelectd.length === 0) {
      setHash('');
      handleOnChange('listaHashtags', [newHash]);
    } else {
      const index = hashSelectd.indexOf(newHash);
      if (index !== -1) {
        hashSelectd.splice(index, 1);
      } else {
        hashSelectd.push(newHash);
      }
      setHash('');
      handleOnChange('listaHashtags', hashSelectd);
    }
  }

  function getCategorias() {
    apiDoubts.get(`/interesses`).then((response: any) => {
      const categorias = response.data.data;
      categorias.map((row: any) => {
        const data = {
          value: row.id,
          label: row.titulo,
        };
        setCategorias((oldArray) => [...oldArray, data]);
      });
    });
  }

  function handleCategorias(e: any) {
    if (e === '') return;

    let catSelected = DTO.listaInteresses;
    if (catSelected.length === 0) {
      setCateg('');
      handleOnChange('listaInteresses', [e]);
    } else {
      const index = catSelected.indexOf(e);
      if (index !== -1) {
        catSelected.splice(index, 1);
      } else {
        catSelected.push(e);
      }
      setCateg('');
      handleOnChange('listaInteresses', catSelected);
    }

    // setOptionsCategorias([])

    // if (optionCategorias.length === 0) {
    //   setOptionsCategorias([e]);
    //   handleOnChange('listaInteresses', [e.value]);
    // } else {
    //   const index = optionCategorias.indexOf(e);
    //   if (index !== -1) {
    //     let removeState = optionCategorias;
    //     removeState.splice(index, 1);
    //     setOptionsCategorias(removeState)
    //     let remove = DTO.listaInteresses;
    //     remove.splice(index, 1)
    //     handleOnChange('listaInteresses', remove);
    //   } else {
    //     let addState = optionCategorias;
    //     addState.push(e);
    //     setOptionsCategorias(addState);
    //     let add = DTO.listaInteresses;
    //     add.push(e.value);
    //     handleOnChange('listaInteresses', add);
    //   }

    // }
  }

  async function getVideo() {
    console.log('tem id do vídeo a ser pego', params.id);
    await apiDoubts
      .get(`/postagens/getPostagem/${params.id}`)
      .then((response: any) => {
        console.log(response);
        const video = response?.data?.data;
        setDTO({
          titulo: video?.titulo,
          thumbnail: video?.thumbnail,
          listaInteresses: handleInteresses(video?.interesses),
          listaHashtags: handleHashtags(video?.hashtags),
          descricao: video?.descricao,
        });
      });
  }

  console.log(DTO);

  function handleInteresses(e: []) {
    let newArray: any[] = [];
    e.map((row: any, key: number) => {
      newArray.push(row?.titulo);
    });
    return newArray;
  }

  function handleHashtags(e: []) {
    let newArray: any[] = [];
    e.map((row: any, key: number) => {
      console.log(row);
      newArray.push(row?.hashtag);
    });
    return newArray;
  }

  async function getThumb(file: any) {
    let formData = new FormData();
    formData.append('file', file.target.files[0]);
    const response: any = await bucket.post(`/upload`, formData);
    handleOnChange('thumbnail', response.data.arquivo);
    console.log(response);
  }

  async function handleSubmit() {
    try {
      setLoading(true);
      if (DTO.titulo === '')
        throw new Error('Dê um titulo para o seu vídeo, por gentileza');
      if (DTO.descricao === '')
        throw new Error('Adicione uma descrição, por favor');
      if (!DTO.thumbnail) throw new Error('Adicione uma Thumbnail, por favor');
      if (DTO.listaInteresses.length < 2)
        throw new Error('Escolha ao menos 2 categorias, por favor');
      if (DTO.listaHashtags.length < 2)
        throw new Error('Escolha ao menos 2 hashtags, por favor');

      apiDoubts
        .put(`/postagens/${params?.id}`, DTO)
        .then((response) => {
          console.log('resposta da api', response);
          setVideoId(response?.data?.data?._id);
          history.push(`/visualizar/${params?.id}`);
          setDTO({
            titulo: '',
            video: '',
            thumbnail: '',
            data: moment(new Date()).format('DD-MM-yyyy hh:mm:00'),
            visualizacoes: 0,
            listaInteresses: [],
            tipo: 'comum',
            listaHashtags: [],
            descricao: '',
          });
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
          toast.error(error.message, {
            className: 'toast-error',
            theme: 'colored',
          });
        });
    } catch (err: any) {
      setLoading(false);
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  async function handleDelete() {
    try {
      setLoading(true);
      apiDoubts?.delete(`/postagens/${params?.id}`).then((res: any) => {
        toast?.success('Vídeo excluido com sucesso.', {
          className: 'toast-success',
          theme: 'colored',
        });

        setLoading(false);

        history?.push('/consultor');
      });
    } catch (err: any) {
      setLoading(false);
      toast?.error(err?.data?.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  const debounced = useDebouncedCallback((value) => {
    handleHash(value.target.value);
    setHash(value.target.value);
  }, 1000);

  return (
    <Container>
      <ModalSucess
        prosseguir={() => {
          history.push(`/visualizar/${videoId}`);
        }}
        id="modalSucess"
        onClose={() => {
          setModalSucess(false);
        }}
        openModal={modalSucess}
        width={'200'}
      >
        <p>Muito bem !!! o seu vídeo foi postado</p>
      </ModalSucess>
      <ModalAlert
        prosseguir={goToTransmissao}
        id="modalAlert"
        onClose={() => {
          setModal(false);
        }}
        openModal={modal}
        width={'200'}
      >
        <p>
          tem certeza de que deseja ir para próxima página ? suas informações
          serão perdidas...
        </p>
      </ModalAlert>

      <div className="main">
        <div className="areaLeft">
          <div className="container-box">
            <p className="title">Envio da Imagem de Destaque</p>

            <div className="resume">
              <IconInfo />
              <p>Essa imagem será a vitrine do seu vídeo!</p>
            </div>

            <h3 className="subtitle">
              Imagem da Thumbnail{' '}
              <span style={{ fontWeight: 400 }}>(640x360)</span>
            </h3>
            <InputFile
              file={DTO.thumbnail}
              placeholder="Selecionar Arquivo"
              tipo="file"
              change={(e: any) => getThumb(e)}
            />
          </div>
          <div className="container-box">
            <p className="title">Detalhes do Conteúdo</p>
            <div className="resume">
              <IconInfo />
              <p>
                Adicione informações cruciais! Escolha um título cativante para
                seu vídeo, como uma pergunta envolvente. Depois, acrescente uma
                descrição breve e informativa sobre o que os espectadores podem
                esperar.
              </p>
            </div>
            <h3 className="subtitle">Titulo</h3>
            <Inputdefault
              placeholder={`Por exemplo, "Como consertar uma torneira vazando?"`}
              tipo="text"
              value={DTO.titulo}
              change={(e: any) => {
                handleOnChange('titulo', e.target.value);
              }}
              changeBlur={(e: any) => {
                handleOnChange(
                  'titulo',
                  `${e.target.value}${
                    e.target.value.indexOf('?') === -1 ? '?' : ''
                  }`
                );
              }}
            />
            <h3 className="subtitle">Descrição</h3>

            <textarea
              placeholder="Informações adicionais sobre o vídeo, como uma breve visão geral do conteúdo."
              onFocus={() => {
                setFocus(true);
              }}
              onBlur={() => {
                setFocus(false);
              }}
              value={DTO.descricao}
              className="comment"
              onChange={(e) => {
                handleOnChange('descricao', e.target.value);
              }}
            ></textarea>
          </div>

          <div className="container-box">
            <p className="title">Categorização e Visibilidade</p>
            <div className="resume">
              <IconInfo />
              <p>
                Defina sua posição! Selecione a categoria que melhor descreve
                seu vídeo e adicione hashtags relevantes para alcançar mais
                pessoas. Quanto mais específicas, melhor será para os
                espectadores encontrarem seu conteúdo!
              </p>
            </div>
            <div style={{ zIndex: 2 }}>
              <h3 className="subtitle">Digite as categorias desejadas</h3>

              <InputHash
                add={() => {
                  handleCategorias(categ);
                }}
                placeholder="Ex: Carro"
                tipo="text"
                change={(e: any) => {
                  setCateg(e?.target?.value);
                }}
                value={categ}
              />

              {/* <SelectDefaultSearch
              tipo="2"
              value={{ label: 'Selecione...', value: 'selecione' }}
              options={categorias}
              onChange={(e: any) => {
                handleCategorias(e);
              }}
              searchOptions={() => {
                console.log('buscar');
              }}
            /> */}
              {DTO?.listaInteresses?.length > 0 && (
                <ListHashStateless
                  tipo="string"
                  data={DTO.listaInteresses}
                  remove={(e: any) => {
                    handleCategorias(e);
                  }}
                />
              )}
            </div>
            <div>
              <h3 className="subtitle">Digite as hashtags</h3>
              <InputHash
                add={() => {
                  handleHash(hash);
                }}
                placeholder="Ex: Carro"
                tipo="text"
                change={(e: any) => {
                  setHash(e.target.value);
                }}
                value={hash}
              />

              {/* <SelectDefaultSearch
              searchOptions={(inputValue:string) => {
                  return getHashtags(inputValue)
              }}
              tipo="2"
              value={{ label: 'Selecione...', value: 'selecione' }}
              options={hashtags}
              onChange={(e: any) => {
                handleHash(e.label);
              }}
            /> */}

              <ListHashStateless
                tipo="string"
                data={DTO.listaHashtags}
                remove={(e: any) => {
                  handleHash(e);
                }}
              />
            </div>
          </div>

          <div className="areaButton">
            {!loading && (
              <ButttonDefault
                title="Excluir Vídeo"
                tipo="8"
                onClick={() => {
                  handleDelete();
                }}
                icon={loading ? 'Loading' : ''}
              />
            )}
            {!loading && (
              <ButttonDefault
                title="Confirmar alterações"
                tipo="6"
                onClick={() => {
                  handleSubmit();
                }}
                icon={loading ? 'Loading' : ''}
              />
            )}
          </div>
        </div>
        <div className="areaRight">
          <h3 style={{ marginTop: '20px' }} className="subtitle">
            Pré-visualizar Postagem
          </h3>
          <span className="cardpost">
            <CardPreview data={DTO} />
          </span>
        </div>
      </div>
    </Container>
  );
}
