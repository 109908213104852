import styled from 'styled-components';

export const ContainerStepOne = styled.div`
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .titleStep {
    margin-top: 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2c2c2c;
  }

  .ArrayAreaAtuacao {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 4px;
    .itemArrayAtuacao {
      background-color: #def3f4;
      color: #0fbaba;
      border-radius: 16px;
      padding: 2px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      cursor: pointer;

      transition: all 0.4s;

      &:hover {
        transition: all 0.4s;
        background-color: var(--offline);
        opacity: 0.8;
        color: var(--white);
      }
    }
  }

  .signUpForm {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;

    .twoInLine {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }
    .inputField {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 6px;
      label {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--color2A);
      }
    }
  }

  .twoButtons {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .termosDeUso {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #666666;
    a {
      color: #1ea0ff;
    }
  }
`;
