/* eslint-disable jsx-a11y/anchor-is-valid */
// import SubmenuButtons from './../SubmenuButtons';
import {
  menuUsuario,
  menuConsultor,
  menuAdministrador,
  singleMenu,
} from './../Data/SidebarUserData';
// import { useLocation } from 'react-router-dom';

import { Container, CloseSideBar } from './styles';
// import UserGeneric from '../../assets/UserGeneric.jpg';
import { useEffect, useState } from 'react';
import { SidebarItems } from '../models/SidebarItems';
import apiDoubts from '../../services/api';
import { useUser } from '../../contexts/UserContext';
import SideBarAreaUser from '../SideBarAreaUser';
import Menu from '../Menu';

// interface Params {
//   pathname: string;
// }

interface PropsHasCompact {
  hasCompact: boolean;
  setCompact: (e: boolean) => void;
}

function SidebarUser({ hasCompact, setCompact }: PropsHasCompact) {
  const [menus, setMenus] = useState<SidebarItems[]>([]);
  const [listaInteresses, setListaInteresses] = useState<any[]>([]);
  const { user, sideBar, setSideBar } = useUser();

  useEffect(() => {
    if (user.tipo === 'cadastro') {
      setMenus(menuUsuario);
    } else if (user.tipo === 'consultor') {
      setMenus(menuConsultor);
    } else if (user.tipo === 'admin') {
      setMenus(menuAdministrador);
    } else {
      setMenus(singleMenu);
    }
    getInteresses();
  }, [user]);

  async function getInteresses() {
    const response = await apiDoubts.get('interesses');
    setListaInteresses(response.data.data);
  }

  return (
    <>
      <CloseSideBar
        onClick={() => {
          setSideBar(!sideBar);
        }}
        hasCompact={hasCompact}
      />
      <Container
        hasCompact={hasCompact}
        onMouseOver={() => {
          setCompact(false);
        }}
        onMouseLeave={() => {
          setCompact(true);
        }}
      >
        <div className="side">
          <SideBarAreaUser hasCompact={hasCompact} />
          <Menu hasCompact={hasCompact} />
        </div>
      </Container> 
    </>
  );
}

export default SidebarUser;
