import styled, { css } from 'styled-components';

interface props {}

export const Container = styled.div<props>`
  .modal-alerta-consultor {
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      font-size: 22px;
    }
    ul {
      margin: 0px 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      li {
        font-size: 14px;
        color: var(--texto3);
      }
    }
    p {
      margin: 20px;
      font-size: 14px;
      color: var(--texto3);
    }
  }
  .center-content {
    display: flex;
    gap: 25px;
    width: 1780px;
    margin: 0px auto;
    max-width: 100%;
  }
  .leftArea {
    width: 1280px;
    @media (max-width: 1919px) {
      width: 960px;
    }
    @media (max-width: 1400px) {
      width: 640px;
    }
    .video {
      background: rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      overflow: hidden;
      width: 1280px;
      height: 720px;
      @media (max-width: 1919px) {
        width: 960px;
        height: 540px;
      }
      @media (max-width: 1400px) {
        width: 640px;
        height: 360px;
      }
      .overlay-time {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        > iframe {
          width: 100%;
          height: 100%;
          position: absolute;
          border: none;
          border-radius: 8px;
        }
        .full {
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          backdrop-filter: blur(20px);
          gap: 25px;
          > iframe {
            width: 100%;
            height: 80%;
            border: none;
            margin-top: -10%;
            background: transparent;
          }
          .content-time {
            padding: 15px 25px;
            border-radius: 8px;
            font-size: 18px;
            background: var(--purple6);
            color: #ffffff;
          }
        }
      }

      > iframe {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 8px;
      }
    }
    .infos {
      padding: 20px 0px;
      display: flex;
      align-items: center;
      gap: 15px;
      .photo {
        min-width: 60px;
        width: 60px;
        height: 60px;
        border-radius: 100px;
        background-size: cover;
      }
      .user {
        h1 {
          font-size: 28px;
          font-weight: 700;
          color: var(--texto3);
        }
        p {
          font-size: 14px;
          color: var(--texto);
        }
      }
    }
    .date-infos {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      .time {
        display: flex;
        background-color: var(--purple6);
        padding: 10px 15px;
        border-radius: 8px;
        align-items: center;
        gap: 5px;
        span {
          font-size: 14px;
          color: #ffffff;
        }
      }
    }
  }
  .rightArea {
    flex: 1;
    height: 100%;
    background: white;
    height: calc(100vh - 110px);
    border-radius: 8px;
    .aviso {
      background: var(--warning);
      border-radius: 8px;
      margin: 15px;
      padding: 15px;
      font-size: 13px;
    }
    .box-comment {
      margin-bottom: 0px;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 8px 8px 0px 0px;
      padding: 20px;
    }
    .overflow {
      display: flex;
      flex-direction: column;
      padding: 20px;
      overflow: auto;
      max-height: calc(100% - 222px);
      gap: 20px;
      ::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #0fbaba;
        border-radius: 0px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
`;
