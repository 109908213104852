import ModalForm from '../../../components/Cadastrar/ModalForm';
import Inputdefault from '../../../components/Inputs/InputDefault';
import { IStepsProps } from './types';

import * as S from './styles';
import SelectDefaultCadastrar from '../../../components/Cadastrar/SelectDefaultCadastrar';
import ButttonDefault from '../../../components/ButtonDefault';
import { toast } from 'react-toastify';
import EyeIcon from '../../../assets/icons/EyeIcon';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

export default function StepOne({
  nextStep,
  onChangeField,
  DTO,
  submitForm,
}: IStepsProps) {
  const [inputType, setInputType] = useState<'text' | 'file' | 'password'>(
    'password'
  );
  const [loading, setLoading] = useState<boolean>(false);

  function validateStepOneFields() {
    try {
      if (!DTO?.tipo_user)
        throw new Error('É necessário escolher um tipo de conta');
      if (!DTO?.tipo && DTO?.tipo_user?.value !== 'usuario')
        throw new Error('É necessário escolher o tipo de pessoa');
      if (!DTO?.login || !DTO?.login?.includes('@'))
        throw new Error('Digite um email válido');
      if (!DTO?.senha) throw new Error('Digite uma senha');

      if (DTO?.tipo_user?.value !== 'usuario') {
        nextStep();
      } else {
        handleSubmitUserForm();
      }
    } catch (error: any) {
      toast.error(error.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  async function handleSubmitUserForm() {
    setLoading(true);
    try {
      await submitForm();
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  function clickButton() {
    if (DTO?.tipo_user?.value === 'usuario') {
      submitForm();
    } else {
      validateStepOneFields();
    }
  }

  return (
    <S.ContainerStepOne>
      <ModalForm>
        <h1 className="titleStepOne">Crie uma conta</h1>
        <form
          className="signUpForm"
          onSubmit={(e: any) => {
            e.preventDefault();
            clickButton();
          }}
        >
          <span className="inputField">
            <label>Apelido</label>
            <Inputdefault
              value={DTO?.nickName}
              color="transparent"
              tipo="text"
              placeholder="Ex: felipesan"
              change={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChangeField({ position: 'nickName', value: e.target.value });
              }}
            />
          </span>

          <span className="inputField">
            <label>Tipo de conta</label>

            <SelectDefaultCadastrar
              onChange={(e: any) => {
                onChangeField({ position: 'tipo_user', value: e });
              }}
              options={[
                { label: 'Usuário', value: 'usuario' },
                { label: 'Consultor', value: 'consultor' },
              ]}
              value={DTO?.tipo_user}
              placeholderValue="Ex: Usuário"
            />
          </span>

          {DTO?.tipo_user && DTO?.tipo_user?.value !== 'usuario' && (
            <span className="inputField">
              <label>Tipo de pessoa</label>
              <SelectDefaultCadastrar
                onChange={(e: any) => {
                  onChangeField({ position: 'tipo', value: e });
                }}
                options={[
                  { label: 'Pessoa Física', value: 'CPF' },
                  { label: 'Pessoa Jurídica', value: 'CNPJ' },
                ]}
                placeholderValue="Ex: Pessoa Física"
                value={DTO?.tipo}
              />
            </span>
          )}

          <span className="inputField">
            <label>Email</label>
            <Inputdefault
              value={DTO?.login}
              color="transparent"
              tipo="text"
              placeholder="email@exemplo.com  "
              change={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChangeField({ position: 'login', value: e.target.value });
              }}
            />
          </span>

          <span className="inputField">
            <label>Senha</label>
            <Inputdefault
              value={DTO?.senha}
              color="transparent"
              tipo={inputType}
              placeholder="*************"
              change={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChangeField({ position: 'senha', value: e.target.value });
              }}
            />
            <button
              type="button"
              className="eyeIcon"
              onClick={() => {
                if (inputType === 'password') setInputType('text');
                else setInputType('password');
              }}
            >
              <EyeIcon />
            </button>
          </span>

          <ButttonDefault
            type={'submit'}
            title="continuar"
            tipo="2"
            hover="pouco"
            icon={loading ? 'Loading' : ''}
            className="submitButton"
          />

          <p className="termosDeUso">
            Ao continuar você aceita os nossos{' '}
            <a href={'/termos-de-uso'}>Termos de Uso</a>
          </p>
        </form>
      </ModalForm>
    </S.ContainerStepOne>
  );
}
