import { useRef, useState } from 'react';
import { Container } from './styles';

interface BProps {
  label?: string;
  content?: string;
}

export default function ButtonCopy({ label, content }: BProps) {
  const [isCopy, setIsCopy] = useState(false);
  const textAreaRef = useRef(null);

  function handleCopy(e: string) {
    navigator.clipboard
      .writeText(e)
      .then(() => {
        setIsCopy(true);
        setTimeout(() => {
          setIsCopy(false);
        }, 1000);
      })
      .catch((err) => {
        alert(err);
      });
  }

  return (
    <Container copyed={isCopy}>
      <p>{label}</p>
      <div className="button">
        <span>{content}</span>
        <button onClick={() => handleCopy(content as string)} className="copy">
          {isCopy ? 'Copiado' : 'Copiar'}
        </button>
      </div>
    </Container>
  );
}
