import { useContext, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import Moment from 'react-moment';
import { Link, useHistory } from 'react-router-dom';
import IconPlay from '../../../assets/icons/IconPlay';
import UserContext from '../../../contexts/UserContext';
import ButttonDefault from '../../ButtonDefault';
import { IconClose, IconCLoseCircle, IconExternalLink } from '../../Icons';
import { Container } from './styles';
import moment from 'moment';

interface IItem {
  comentarios: any;
  consultorId: string;
  consultores: any;
  createdAt: any;
  data: string;
  deleted: boolean;
  deletedAt: any;
  destaque: number;
  hashtags: any;
  id: string;
  interesses: any;
  listaComentarios: any[];
  listaHashtags: any[];
  listaInteresses: any[];
  qtdComentarios: number;
  qtdFavoritos: number;
  qtdVisualizacoes: number;
  thumbnail: string;
  thumbnailFull: string;
  tipo: string;
  titulo: string;
  updatedAt: any;
  video: string;
  __v: number;
  _id: string;
  nickName?: string;
}

interface IProps {
  playlist?: any;
  posicao?: string;
  item?: IItem;
  loading?: boolean;
  type?: 'anuncio' | 'consultor' | 'preview' | any;
  deletar: (e:any)=>any;
}

export default function CardExcluir({
  item,
  loading,
  type,
  posicao,
  deletar,
  playlist,
  
}: IProps) {
  return (
    <Container posicao={posicao} type={type} playlist={playlist}>
      <div
        className="image"
        style={{ backgroundImage: `url(${item?.thumbnail})` }}
        onClick={() => {
          deletar(item?.id);
        }}
      >
        <div className="btnDeletar">
          <ButttonDefault title={'Tirar da playlist'} full={true} tipo={'8'} />
        </div>
        
        <div className="overlay-play">
          {type === 'anuncio' ? (
            <IconExternalLink />
          ) : (
            <IconCLoseCircle color={'#FFF'} />
          )}
        </div>
        {loading === true && <Skeleton />}
      </div>

      <div className="infos">
        {type !== 'consultor' && posicao !== 'lateral' && (
          <>
            <div
              className="image-profile"
              style={{ backgroundImage: `url(${item?.consultores?.foto})` }}
            >
              {loading === true && <Skeleton />}
            </div>
          </>
        )}

        {loading === true ? (
          <div className="info-post">
            <span className="title">
              <Skeleton />
            </span>
            <span className="author">
              <Skeleton />
            </span>
            <span className="date">
              <Skeleton />
            </span>
          </div>
        ) : (
          <div className="info-post">
            <span className="title">{item?.titulo ?? '...'}</span>

            {type !== 'consultor' && (
              <>
                <span className="author">
                  {item?.consultores?.nickName ?? item?.nickName}
                </span>
              </>
            )}

            {type !== 'anuncio' && (
              <span className="date">
                {item?.qtdVisualizacoes} visualizações •{' '}
                  {moment(item?.data).fromNow()}
              </span>
            )}
          </div>
        )}
      </div>
    </Container>
  );
}
