import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import UserContext from '../../../contexts/UserContext';
import { IDTOAdsense } from '../../../pages/Campanha/Criar';
import { IconInfo, IconWWW } from '../../Icons';
import Inputdefault from '../../Inputs/InputDefault';
import InputLink from '../../Inputs/InputLink';
import { Container, SuggestHashtag } from './styles';
import ButttonDefault from '../../ButtonDefault';
import { TwoThumbInputRange } from 'react-two-thumb-input-range';
import InputHash from '../../Inputs/InputHash';
import ListHashStateless from '../../Hashtags/ListStateless';
import apiDoubts from '../../../services/api';
import { toast } from 'react-toastify';
import { AxiosError, AxiosResponse } from 'axios';
import { useDebounce } from 'use-debounce';

interface props {
  handleOnChangeDTO: (
    key: string,
    typeAdsense: string | number | any[]
  ) => void;
  data: IDTOAdsense;
  setPage: (page: string) => void;
  page?: 'create' | 'edit';
}

interface IInterest {
  createdAt: string;
  deleted: boolean;
  deletedAt: null | string;
  id: string;
  popularidade: number;
  titulo: string;
  updatedAt: string;
  __v: number;
  _id: string;
}

export default function InfosAdsense({
  data,
  setPage,
  handleOnChangeDTO,
  page,
}: props) {
  const [optionsConsultores, setOptionsConsultores] = useState<any[]>([]);
  const [optionsEmpresas, setOptionsEmpresas] = useState<any[]>([]);
  const [categ, setCateg] = useState<string>('');
  const [listCateg, setListCateg] = useState<Array<any>>([]);
  const [relatedInterests, setRelatedInterests] = useState<IInterest[]>();
  const [searchInterests, setSearchInterests] = useState<IInterest[]>();
  const [showInterests, setShowInterests] = useState<boolean>(false);
  const [interest] = useDebounce(categ, 400);

  const { user } = useContext<any>(UserContext);

  const colourStyles = {
    control: (provided: Record<string, unknown>) => ({
      ...provided,
      color: '#ff0000',
      height: 40,
      width: '100%',
      zIndex: 1,
      boxShadow: 'none',
      cursor: 'pointer',
      backgroundColor: '#E7E7F3',
      border: 'none',

      '&:hover': {
        border: '1px solid #0661C7',
      },
    }),
    menu: (provided: any) => ({ ...provided, zIndex: 1 }),
    input: (provided: any) => ({
      ...provided,
      fontSize: 13,
      color: '#7D7D7D',
      fontWeight: 400,
    }),
    placeholder: (provided: any) => ({
      ...provided,
      fontSize: 13,
      color: '#7D7D7D',
      fontWeight: 400,
    }),
    singleValue: (provided: any) => ({
      ...provided,
      fontSize: 11,
      color: '#000',
      fontWeight: 400,
    }),
  };

  const optionsSelect: any[] = [
    { label: 'Masculino', value: 'M' },
    { label: 'Feminino', value: 'F' },
    { label: 'Todos', value: 'Todos' },
  ];

  useEffect(() => {
    if (user?.consultores) {
      handleOptionsConsultores(user.consultores);
    }
    if (user?.empresas) {
      handleOptionsEmpresas(user.empresas);
    }
  }, [user]);

  useEffect(() => {
    getAllInterests(interest);
  }, [interest]);

  useEffect(() => {
    if (!data?.listaInteresses?.length) setRelatedInterests([]);
  }, [data?.listaInteresses]);

  function formatarData(key: string, e: string) {
    handleOnChangeDTO(key, e);
  }

  function removeCurrencyFormat(
    key: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    let value = '0';
    if (event.target.value !== 'R') {
      value = event.target.value;
    }
    let valueNumber = value?.replaceAll('R$', '');
    if (valueNumber[0] === '0') {
      valueNumber = valueNumber.slice(0, 0);
    }
    handleOnChangeDTO(key, valueNumber);
  }

  function handleEvent(
    newValue: string,
    e: React.ChangeEvent<HTMLDataElement>
  ) {
    let newEvent: any = e;
    newEvent.target.value = newValue;
    return newEvent;
  }

  function handleOptionsConsultores(data: any) {
    if (data) {
      let optionsConsultores: any[] = [];
      data.map((row: any) => {
        let objectConsultores = {
          label: row?.nickName,
          value: row?.id,
        };
        optionsConsultores.push(objectConsultores);
      });
      setOptionsConsultores(optionsConsultores);
    }
  }

  function handleOptionsEmpresas(data: any) {
    if (data) {
      let optionsEmpresas: any[] = [];
      data.map((row: any) => {
        let objectEmpresas = {
          label: row?.nickName,
          value: row?.id,
        };
        optionsEmpresas.push(objectEmpresas);
      });
      setOptionsEmpresas(optionsEmpresas);
    }
  }

  function onValueChange(values: any[]) {
    if (values[0] < values[1]) {
      handleOnChangeDTO('idade_mim', values[0]);
      handleOnChangeDTO('idade_max', values[1]);
    }
  }

  async function handleCategorias(interesse: any) {
    try {
      await apiDoubts
        .get(`/interesses?titulo=${interesse}`)
        .then((response: any) => {
          if (response?.data?.data.length === 0) {
            setCateg('');
            throw new Error('Esse interesse não existe na plataforma');
          }

          let interesses = response?.data?.data?.filter((row: any) => {
            if (row.titulo === interesse) {
              return row.titulo;
            }
          });

          if (data?.listaInteresses?.includes(interesse)) {
            setCateg('');
            throw new Error('Esse interesse já foi adicionado');
          }

          if (interesses.length === 0) {
            setCateg('');
            throw new Error('Esse interesse não existe na plataforma');
          }

          setCateg('');
          addInteresse(interesses[0]?.titulo, interesses[0]?._id);
        });
    } catch (err: any) {
      setCateg('');
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  async function getRelatedInterests(interesse: string) {
    await apiDoubts
      ?.get(`/interesses?titulo=${interesse}`)
      ?.then((res) => getInterest(res?.data?.data[0]?._id))
      ?.catch((err) => console.log(err));

    async function getInterest(id: string) {
      await apiDoubts?.get(`/interesses-relacionados/${id}`)?.then((res) => {
        const allSuggests: IInterest[] = res?.data?.data;

        const filteredList = allSuggests?.filter((row) => {
          if (data?.listaInteresses?.indexOf(row?.titulo) === -1) return row;
        });

        setRelatedInterests(filteredList?.slice(0, 3));
      });
    }
  }

  function addInteresse(titulo: string, id: string) {
    handleOnChangeDTO('listaInteresses', [...data.listaInteresses, titulo]);
    handleOnChangeDTO('listaInteressesID', [...data.listaInteressesID, id]);
  }

  function removeInteresse(e: any) {
    console.log(e);
    setCateg('');
    let cloneData = data.listaInteresses;
    cloneData = cloneData.filter((row) => {
      return row !== e;
    });
    console.log(cloneData);
    handleOnChangeDTO('listaInteresses', cloneData);
  }

  async function removeInteresseId(e: any) {
    const response = await apiDoubts?.get(`/interesses?titulo=${e}`);

    console.log(e);
    setCateg('');
    let cloneData = data.listaInteressesID;
    cloneData = cloneData.filter((row) => {
      return row !== response?.data?.data[0]?._id;
    });
    console.log(cloneData);
    handleOnChangeDTO('listaInteressesID', cloneData);
  }

  async function getCategorias(categ: Array<any>) {
    let stringCateg = '';
    categ.map((row) => {
      if (!stringCateg || stringCateg === '') {
        stringCateg = row;
      } else {
        stringCateg = stringCateg + '-' + row;
      }
    });
    await apiDoubts
      .get(`/interesses?id=${stringCateg}`)
      .then((response: AxiosResponse) => {
        handleListCateg(response.data.data);
      })
      .catch((error: AxiosError) => {
        console.error(error);
      });
  }

  function handleListCateg(data: any) {
    data.map((row: any) => {
      let newInteresse = {
        value: row?.id,
        label: row?.titulo,
      };
      setListCateg((oldArray) => [...oldArray, newInteresse]);
    });
  }

  function validateSecondStep() {
    try {
      if (!data.nome)
        throw new Error('É necessário preencher o nome da campanha');
      if (!data.descricao)
        throw new Error('É necessário preencher a descrição da campanha');
      if (!data.data_inicio)
        throw new Error('É necessário preencher a data de início da campanha');
      if (!data.data_fim)
        throw new Error('É necessário preencher a data de fim da campanha');
      if (!data.valor_dia)
        throw new Error(
          'É necessário preencher o investimento diário da campanha'
        );
      if (!data.limite_gasto)
        throw new Error('É necessário preencher o investimento total');
      if (!data.link)
        throw new Error('É necessário preencher um link para a campanha');

      if (!data?.empresaId && user?.tipo === 'agencia')
        throw new Error(
          'É necessário selecionar uma empresa destinada para a campanha'
        );

      if (handleValidateURL() === false)
        throw new Error('O link não é um formato válido');

      if (!data.sexo)
        throw new Error(
          'É necessário preencher todos os campos antes de proseguir'
        );
      if (
        data?.listaInteresses?.length === 0 &&
        !user?.listaEmpresas &&
        !data?.consultorId
      )
        throw new Error(
          'Escolha um tipo de campanha, por lista de interesse ou consultores'
        );
      if (!data?.consultorId && data.listaInteresses?.length < 3) {
        throw new Error('Escolha ao menos 3 interesses');
      }
      setPage('Mídias');
    } catch (err: any) {
      setCateg('');
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  async function getAllInterests(query: string) {
    const response = await apiDoubts?.get(`/interesses?titulo=${query}`);

    const allInterests: IInterest[] = response?.data?.data;

    const filteredInterests = allInterests?.filter((interest) => {
      if (!data?.listaInteresses?.includes(interest?.titulo)) return interest;
    });

    setSearchInterests(filteredInterests);
  }

  function selectConsultor(consultorId?: string) {
    if (!consultorId || consultorId === '') return false;

    let response = optionsConsultores.map((row: any) => {
      console.log(row.value === consultorId);
      if (row.value === consultorId) {
        return row;
      }
    });

    return response;
  }

  function formatUrl(str: string): string {
    // Verifica se a string já começa com "https://" ou "http://"
    if (str.startsWith('https://') || str.startsWith('http://')) {
      return str;
    } else if (str.length > 1) {
      // Adiciona "https://" na frente da string e retorna
      return `https://`;
    } else {
      return `https://${str}`;
    }
  }

  // NEW FUNCTION

  function handleInteressesName(listaInteresses: Array<any>) {
    let newArray = listaInteresses.map((row: any) => {
      return row.titulo;
    });
    return newArray;
  }

  function handleValidateURL() {
    try {
      let newURL = new URL(data?.link);
      console.log(newURL);
      return true;
    } catch (error) {
      return false;
    }
  }

  return (
    <Container>
      <div className="areaInputs">
        <h3 className="label">Nome da Campanha</h3>
        <Inputdefault
          change={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleOnChangeDTO('nome', e?.target?.value);
          }}
          placeholder={'Ex: Campanha para empresa Doubts'}
          tipo={'text'}
          value={data?.nome}
        />
      </div>

      {data?.listaInteresses?.length === 0 && user?.consultores?.length > 0 && (
        <>
          <div className="areaInputs">
            <h3 className="label">Consultor que fará parte dessa campanha</h3>
            <div className="flexRow">
              <div className="inputWrapper">
                <Select
                  options={optionsConsultores}
                  value={selectConsultor(data?.consultorId)}
                  styles={colourStyles}
                  onChange={(e: any) => {
                    handleOnChangeDTO('consultorId', e.value);
                  }}
                />
              </div>
              {data?.consultorId !== '' && (
                <ButttonDefault
                  title={''}
                  icon="Lixo"
                  tipo="8"
                  onClick={() => {
                    handleOnChangeDTO('consultorId', '');
                  }}
                />
              )}
            </div>
          </div>
        </>
      )}

      <div className="areaInputs">
        <h3 className="label">Descrição</h3>
        <textarea
          placeholder="Ex: essa é uma playlist focada em receitas simples"
          value={data?.descricao}
          className="comment"
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            handleOnChangeDTO('descricao', e?.target?.value);
          }}
        ></textarea>
      </div>

      <div className="divInputs">
        <div className="areaInputsAoLado">
          <h3 className="label">Data Início</h3>

          <input
            type="date"
            className="DatePickerInput"
            onChange={(e: any) => {
              formatarData('data_inicio', moment(e.target.value).format());
            }}
            value={moment(data?.data_inicio).format('YYYY-MM-DD')}
          />
        </div>
        <div className="areaInputsAoLado">
          <h3 className="label">Data fim</h3>
          <input
            type="date"
            className="DatePickerInput"
            onChange={(e: any) => {
              formatarData('data_fim', moment(e.target.value).format());
            }}
            value={moment(data?.data_fim).format('YYYY-MM-DD')}
          />
        </div>
      </div>

      <div className="divInputs">
        <div className="areaInputsAoLado">
          <h3 className="label">Investimento por dia</h3>
          <Inputdefault
            change={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e?.target?.value !== `R$`) {
                removeCurrencyFormat('valor_dia', e);
              } else {
                removeCurrencyFormat('valor_dia', handleEvent('0', e));
              }
            }}
            placeholder={'Ex: Campanha para empresa Doubts'}
            tipo={'text'}
            value={`R$${(data?.valor_dia).toString().replaceAll('R$', '')}`}
          />
        </div>

        <div className="areaInputsAoLado">
          <h3 className="label">Investimento total</h3>
          <Inputdefault
            change={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e?.target?.value !== `R$`) {
                removeCurrencyFormat('limite_gasto', e);
              } else {
                removeCurrencyFormat('limite_gasto', handleEvent('0', e));
              }
            }}
            placeholder={'Ex: Campanha para empresa Doubts'}
            tipo={'text'}
            value={`R$${(data?.limite_gasto).toString().replaceAll('R$', '')}`}
          />
        </div>
      </div>

      <div className="areaInputs">
        <h3 className="label">Idade</h3>
        <TwoThumbInputRange
          onChange={onValueChange}
          values={[data.idade_mim, data.idade_max]}
          min={18}
          max={100}
        />
      </div>

      <div className="areaInputs">
        <h3 className="label">Link de redirecionamento</h3>
        <InputLink
          changeText={(e: string) => {
            handleOnChangeDTO('link', formatUrl(e));
          }}
          icon={<IconWWW />}
          value={data?.link}
        />
      </div>

      <div className="areaInputs">
        <h3 className="label">Sexo</h3>
        <div className="selectIndex" style={{ zIndex: 2 }}>
          <Select
            value={
              data.sexo === 'F'
                ? { value: 'F', label: 'Feminino' }
                : data.sexo === 'M'
                ? { value: 'M', label: 'Masculino' }
                : { value: 'Todos', label: 'Todos' }
            }
            options={optionsSelect}
            styles={colourStyles}
            onChange={(e: any) => {
              handleOnChangeDTO('sexo', e.value);
            }}
          />
        </div>
      </div>

      {!data?.consultorId && (
        <div className="areaInputs">
          <h3 className="label">Interesses</h3>
          <div>
            <InputHash
              add={(e) => {
                handleCategorias(categ);
                getRelatedInterests(e);
              }}
              placeholder="Ex: Carro"
              tipo="text"
              change={(e: any) => {
                setCateg(e.target.value);
              }}
              value={categ}
              onBlur={() => setTimeout(() => setShowInterests(false), 300)}
              onFocus={() => setShowInterests(true)}
            />
            <div
              className="areaResults"
              style={showInterests ? {} : { display: 'none' }}
            >
              <ul className="listContainer">
                {searchInterests?.length ? (
                  searchInterests?.map((interest) => (
                    <li
                      key={interest?._id}
                      onClick={() => {
                        handleCategorias(interest?.titulo);
                        getRelatedInterests(interest?.titulo);
                        getAllInterests('');
                        setShowInterests(false);
                      }}
                      className="option"
                    >
                      {interest?.titulo}{' '}
                      <span>Popularidade: {interest?.popularidade}</span>
                    </li>
                  ))
                ) : (
                  <li>Nenhum interesse encontrado com esse título . . .</li>
                )}
              </ul>
            </div>
          </div>
          <div className="hashtagsArea">
            <ListHashStateless
              tipo={'string'}
              data={data.listaInteresses}
              remove={(e: any) => {
                removeInteresse(e);
                removeInteresseId(e);
                getRelatedInterests(
                  data?.listaInteresses[data?.listaInteresses?.length - 1]
                );
              }}
            />
            {relatedInterests?.map((suggest) => (
              <SuggestHashtag
                key={suggest?._id}
                className="animate__animated animate__fadeIn"
                data-suggest={`Essa é uma sugestão de Hashtag que tem uma popularidade de ${suggest?.popularidade}`}
                onClick={() => {
                  handleCategorias(suggest?.titulo);
                  getRelatedInterests(suggest?.titulo);
                }}
              >
                <p>{suggest?.titulo}</p>
                <span className="popularity">
                  <IconInfo size={14} color="#FFF" />
                </span>
              </SuggestHashtag>
            ))}
          </div>
        </div>
      )}

      {user?.tipo === 'agencia' && (
        <div className="areaInputs">
          <h3 className="label">Empresa que será destinada a Campanha</h3>
          <div className="selectIndex" style={{ zIndex: 1 }}>
            <Select
              options={optionsEmpresas}
              defaultValue={optionsEmpresas[0] && optionsEmpresas[0]}
              styles={colourStyles}
              onChange={(e: any) => {
                handleOnChangeDTO('empresaId', e.value);
              }}
            />
          </div>
        </div>
      )}

      {data?.tipo && (
        <div className="areaButtons">
          <ButttonDefault
            title={'Voltar'}
            tipo={'11'}
            onClick={() => {
              setPage('Tipo de Campanha');
            }}
          />
          <ButttonDefault
            title={'Avançar'}
            tipo={'6'}
            onClick={validateSecondStep}
          />
        </div>
      )}
    </Container>
  );
}
