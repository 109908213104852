import styled, { css } from 'styled-components';

interface props {
  bannerType: '70x70' | '322x227' | '322x673' | '1024x90' | any;
}

export const Container = styled.a<props>`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 0;
  background-color: var(--background2);
  border-radius: 10px;
  .bannerImageComponent {
    border-radius: 10px;

    ${({ bannerType }) =>
      bannerType === '322x227' &&
      css`
        width: 322px;
        height: 227px;
        min-width: 322px;
        min-height: 227px;
        background-position: center;
        background-size: cover;
      `}

    ${({ bannerType }) =>
      bannerType === '322x673' &&
      css`
        width: 322px;
        height: 673px;
        min-width: 322px;
        min-height: 673px;
        background-position: center;
        background-size: cover;
      `}

      ${({ bannerType }) =>
      bannerType === '1024x90' &&
      css`
        width: 1024px;
        height: 90px;
        min-width: 1024px;
        min-height: 90px;
        background-position: center;
        background-size: cover;
        background-color: red;
      `}
  }

  .text {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 1250px) {
    ${({ type }) =>
      type === '2' &&
      css`
        display: none;
      `}
  }
`;
