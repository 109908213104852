import * as S from './styles';
import { IStepsInterface } from './types';

export default function StepsInSignUp({
  currentStep,
  totalSteps,
}: IStepsInterface) {
  return (
    <S.Container>
      <span className={`steps ${currentStep > 1 ? 'active' : 'inactive'}`}>1</span>
      <span
        className={`line ${currentStep > 1 ? 'active' : 'inactive'} `}
      ></span>
      <span className={`steps ${currentStep >= 2 ? 'active' : 'inactive'} `}>2</span>
      <span
        className={`line ${currentStep > 2 ? 'active' : 'inactive'} `}
      ></span>
      <span className={`steps ${currentStep >= 3 ? 'active' : 'inactive'} `}>
        3
      </span>
      {totalSteps === 4 && (
        <>
          <span
            className={`line ${currentStep >= 4 ? 'active' : 'inactive'} `}
          ></span>
          <span
            className={`steps ${currentStep >= 4 ? 'active' : 'inactive'} `}
          >
            4
          </span>
        </>
      )}
    </S.Container>
  );
}
