import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 60px;
  border-radius: 10px;
  background-color: var(--background2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 10px;

  gap: 20px;

  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;

  .leftAreaCardCampanha {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    .titleAndStatus {
      display: flex;
      align-items: center;
      gap: 10px;
      .nomeCampanha {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        color: var(--paleta2E);
        margin-right: 10px;
      }
    }
    .campanhaDescricao {
      font-size: 13px;
      line-height: 18px;
      font-weight: 400;
      color: var(--texto);
    }
  }
  .buttonsCampanha {
    display: flex;
    gap: 10px;
  }
`;
