import moment from 'moment';

export function subtractDays(dias: number): string[] {
  const currentDate = moment();
  const newDate = currentDate?.subtract(dias, 'days');
  const arrayOfDays: string[] = [];

  for (let i = 0; i < dias; i++) {
    const dia = newDate.add(1, 'days').format('DD-MM-YYYY');
    arrayOfDays.push(dia);
  }

  return arrayOfDays;
}
