import styled, { css } from 'styled-components';

export const CardConsultoresContainer = styled.div`
  width: 100%;
  height: 60px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;

  -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);

  .consultorWrapper {
    font-size: 14px;
    font-weight: 500;
    color: var(--paleta2C);
    display: flex;
    align-items: center;
    gap: 5px;
    text-decoration: none;

    .consultorPhoto {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: var(--background2);

      background-position: center;
      background-size: cover;
    }
  }

  .mainContainer {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

interface IPropsConsultorType {
  typeHour: string;
}

export const ConsultoriaType = styled.div<IPropsConsultorType>`
  width: min-content;
  border-radius: 4px;
  padding: 5px 10px;
  color: var(--branco);
  font-size: 14px;
  font-weight: 600;
  ${({ typeHour }) =>
    typeHour === 'gratis'
      ? css`
          background-color: var(--warning);
        `
      : css`
          background-color: var(--paleta1A);
        `}
`;

interface IPropsAppointment {
  status: string;
}

export const Appointment = styled.div<IPropsAppointment>`
  width: min-content;
  border-radius: 4px;
  padding: 5px 10px;
  color: var(--branco);
  font-size: 14px;
  font-weight: 600;

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 5px;

  ${({ status }) =>
    status === 'livre'
      ? css`
          background-color: var(--paleta1A);
        `
      : css`
          background-color: var(--offline);
        `}
`;

export const Hour = styled.div`
  width: min-content;
  border-radius: 4px;
  padding: 5px 10px;
  color: var(--branco);
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;

  background-color: var(--paleta1A);

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 5px;

  -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
`;
