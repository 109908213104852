import { useContext, useEffect, useState } from 'react';
import AnunciosVerticais from '../../components/Anuncio/AnunciosVerticais';
import ListPostsDescubra from '../../components/Posts/ListPostDescubra';
import TopConsultores from '../../components/TopConsultores';
import WarningLogout from '../../components/WarningLogout';
import UserContext from '../../contexts/UserContext';
import { Container, ContainerContent } from './styles';

const Descubra: React.FC = (props: any) => {
  const { user } = useContext(UserContext);

  const [ordenacao, setOrdenacao] = useState<any>({
    label: 'Populares',
    value: 'popular',
  });
  const [interesse, setInteresse] = useState<string>('');
  const [hashtag, setHashtag] = useState<string>();

  // useEffect(() => {
  //   window.addEventListener('scroll', onScroll);
  //   return () => window.removeEventListener('scroll', onScroll);
  // }, []);

  // function onScroll(e: any) {
  //   const { scrollY: y, screen: a } = window;
  //   const myBody: any = document.getElementsByTagName('body');

  //   const scrollY = y + window.screen.height + 400;

  //   if (scrollY >= myBody[0].offsetHeight && loading == false) {
  //     setGetData(true);
  //     setPage(page + 1)
  //   }
  // }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [hashtag]);

  return (
    <>
      {user?.login ? (
        <Container>
          <ContainerContent>
            <div className="section-left">
              <ListPostsDescubra
                order={ordenacao?.value}
                where={{
                  interesse: interesse,
                  hash: hashtag,
                }}
                vistas={true}
              />
              <div className="areaButton"></div>
            </div>
            <div className="section-right">
              <AnunciosVerticais
                count={1}
                where={{
                  interesse: interesse,
                  search: '',
                  hashs: [],
                }}
              />
              <TopConsultores direction="colum" />
              {/* <Hashtags
                currentHashtags={hashtag}
                hash={(e) => setHashtag(e)}
                title={true}
                position={'lateral'}
              /> */}
            </div>
          </ContainerContent>
        </Container>
      ) : (
        <WarningLogout />
      )}
    </>
  );
};

export default Descubra;
