import Header from '../Header';

import { Container, ContainerMain } from './styles';
import { useContext, useEffect, useRef, useState } from 'react';
import SidebarUser from '../SidebarUser';
import Main from '../Main';
import UserContext, { useUser } from '../../contexts/UserContext';

const Layout: React.FC = (props) => {
  const [hasCompact, setHasCompact] = useState<boolean>(true);
  const refContainer = useRef<any>();
  const { sideBar } = useContext(UserContext);

  const { interaction, setInteraction } = useUser();

  useEffect(() => {
    scrollTop();
  }, [props]);

  useEffect(() => {
    setHasCompact(true);
  }, [sideBar]);

  function scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  return (
    <Container
      onClick={() => !interaction && setInteraction(true)}
      ref={refContainer}
    >
      <Header hasCompact={hasCompact} setHasCompact={setHasCompact} />
      <ContainerMain>
        <div className="sideBar">
          <SidebarUser hasCompact={hasCompact} setCompact={setHasCompact} /> 
        </div>
        <Main hasCompact={hasCompact}>{props.children}</Main>
      </ContainerMain>
    </Container>
  );
};

export default Layout;
