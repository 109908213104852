import styled from 'styled-components';

export const Container = styled.div`
  .page-title {
    font-size: 28px;
    font-weight: 700;
    color: var(--paleta2C);
    margin-bottom: 15px;
  }
  .subtitle {
    margin-bottom: 5px;
    font-size: 13px;
    line-height: 15px;
    color: var(--texto);
    font-weight: 700;
  }
  .title {
    margin-bottom: 15px;
  }
  .description {
    .comment {
      width: 100%;
      height: 120px;
      background-color: var(--background2);
      border: none;
      border-radius: 4px;
      padding: 10px;
      outline: none;
      resize: none;
      margin-bottom: 15px;
    }
  }
  .videos {
    margin-bottom: 15px;
  }
  .selecionados {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 15px;
  }
`;
