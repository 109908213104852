import { MainContainer } from './styles';

interface ProfileProps {
  profile?: any;
  children?: any;
  hasCompact: boolean;
}

function Main({ profile, hasCompact, children }: ProfileProps) {
  return (
    <MainContainer className={hasCompact ? 'active' : 'inactive'}>
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        {children}
      </div>
    </MainContainer>
  );
}

export default Main;
