import styled from "styled-components";

export const Container = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
.headerCampanhas{
    width: 100%;
    display: flex;
    justify-content: space-between;
    .areaLeft{
        .titleCampanhas{
            font-size: 28px;
            line-height: 42px;
            font-weight: 700;
            color: var(--paleta2C);
        }
        .subtitleCampanhas{
            font-size: 18px;
            line-height: 27px;
            font-weight: 600;
            color: var(--paleta1C);
        }
    }

    @media(max-width: 475px){
        flex-direction: column;
        gap: 10px;
    }

}
.mainCardsContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
    .loadingWrapper{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 200px;
    }
    .emptyWrapper{
        max-height: min-content;
    }
}
`