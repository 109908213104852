import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  overflow: hidden;
  min-height: calc(100vh - 80px);
`

export const ContainerMain = styled.div`
  display: flex;

  .sideBar{
    @media (max-width: 600px){
      position: absolute;
    }
  }
`
