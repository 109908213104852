import { useEffect, useState } from 'react';
import { Container } from './styles';

import LogoImage from '../../assets/Logo.png';
import StepOne from './StepOne';
import { IOnChangeField } from './StepOne/types';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import apiDoubts from '../../services/api';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const HEX_CHARS = '0123456789ABCDEF';

export default function Cadastrar() {
  const [formStep, setFormStep] = useState<number>(1);
  var [DTO, setDTO] = useState<any | 'tipar'>({
    termosUso: true,
  });

  const [tipoUser, setTipoUser] = useState<string>();

  const history = useHistory();

  function onChangeDTO(
    positon: string,
    value: string | number | Array<string>
  ) {
    let newDTO = { ...DTO };
    newDTO[positon] = value;
    setDTO({ ...newDTO });
  }

  useEffect(() => {
    console.log('mudou o DTO', DTO);
  }, [DTO]);

  useEffect(() => {
    let newDTOWhithOutProperties = { ...DTO };
    let propertiesToDelete = [
      'razaoSocial',
      'cnpjEmpresa',
      'telefoneEmpresa',
      'enderecoEmpresa',
    ];
    propertiesToDelete.forEach((propertyToDelete: string) => {
      Reflect.deleteProperty(newDTOWhithOutProperties, propertyToDelete);
      setDTO(newDTOWhithOutProperties);
    });
  }, [DTO?.tipo]);

  useEffect(() => {
    setTipoUser(DTO?.tipo_user?.value);
  }, [DTO.tipo_user]);

  useEffect(() => {
    if (DTO.nickName) {

      let DTOClone = DTO
      DTOClone['capa'] = `https://dummyimage.com/1000x300/${generateRandomHexCode()}/fff&text=${handleName(
        DTO.nickName
      )}`
      DTOClone['foto'] = `https://dummyimage.com/100x100/${generateRandomHexCode()}/fff&text=${handleName(
        DTO.nickName
      )}`
      setDTO(DTOClone)        
    }
  }, [DTO.nickName]);

  function handleName(nickNameUser: string) {
    const words = nickNameUser?.trim().split(' ');
    if (words?.length === 1) {
      const firstTwoLetters = words[0].substring(0, 2)?.toUpperCase();
      if (isConsonant(firstTwoLetters[0]) && isVowel(firstTwoLetters[1])) {
        return firstTwoLetters;
      } else {
        return words[0]?.substring(0, 3).toUpperCase();
      }
    } else {
      const firstInitial = words[0][0]?.toUpperCase();
      const secondInitial = words[1][0]?.toUpperCase();
      return `${firstInitial}${secondInitial}`;
    }
  }

  function generateRandomHexCode() {
    const hexCode = [];
    for (let i = 0; i < 6; i++) {
      hexCode.push(HEX_CHARS[(Math.random() * 16) | 0]);
    }
    return hexCode.join('');
  }

  function isConsonant(letter: string) {
    return /^[bcdfghjklmnpqrstvwxyz]$/i.test(letter);
  }

  function isVowel(letter: string) {
    return /^[aeiou]$/i.test(letter);
  }

  async function SubmitFormUser() {
    let newObjectPost = { ...DTO };
    Reflect.deleteProperty(newObjectPost, 'tipo_user');

    try {
      let response: any = await apiDoubts.post('cadastros', newObjectPost);
      if (response?.data?.status === 'error') {
        throw new Error(response?.data?.message);
      }

      toast.success(
        'Valide a sua conta pela mensagem que foi enviada ao seu email',
        {
          className: 'toast-sucess',
          theme: 'colored',
        }
      );
      history.push('/login');

      return response;
    } catch (error: any) {
      toast.error(error.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  async function submitCPF() {
    let newObjectPost = { ...DTO };
    Reflect.deleteProperty(newObjectPost, 'tipo_user');

    newObjectPost['sexo'] = newObjectPost['sexo']?.value;
    newObjectPost['tipo'] = newObjectPost['tipo']?.value;
    newObjectPost['dataNascimento'] = newObjectPost['dataNascimento'].replace(
      /^(\d{2})(\d{2})(\d{4})$/,
      '$1/$2/$3'
    );

    try {
      await apiDoubts
        .post(`${tipoUser}/cadastro`, newObjectPost)
        .then((response: any) => {
          if (response.data.status === 'error') {
            throw new Error(response?.data?.message);
          }

          history.push('/login');

          toast.success(
            'Valide a sua conta pela mensagem que foi enviada ao seu email',
            {
              className: 'toast-sucess',
              theme: 'colored',
            }
          );
          return response;
        });
    } catch (error: any) {
      toast.error(error.message, {
        className: 'toast-error',
        theme: 'colored',
      });
      return error;
    }
  }

  async function submitCNPJ() {
    let newObjectPost = { ...DTO };
    Reflect.deleteProperty(newObjectPost, 'tipo_user');

    newObjectPost['sexo'] = newObjectPost['sexo']?.value;
    newObjectPost['banco'] = newObjectPost['banco']?.value;
    newObjectPost['tipo'] = newObjectPost['tipo']?.value;
    newObjectPost['dataNascimento'] = newObjectPost['dataNascimento'].replace(
      /^(\d{2})(\d{2})(\d{4})$/,
      '$1/$2/$3'
    );

    try {
      await apiDoubts
        .post(`${tipoUser}/cadastro`, newObjectPost)
        .then((response: any) => {
          if (response.data.status === 'error') {
            throw new Error(response?.data?.message);
          }

          history.push('/login');

          toast.success(
            'Valide a sua conta pela mensagem que foi enviada ao seu email',
            {
              className: 'toast-sucess',
              theme: 'colored',
            }
          );
        });
    } catch (error: any) {
      toast.error(error.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  return (
    <Container>
      <img src={LogoImage} alt={'logotipo Doubts'} />

      {formStep === 1 && (
        <StepOne
          submitForm={async () => {
            await SubmitFormUser();
          }}
          DTO={DTO}
          nextStep={() => {
            setFormStep(formStep + 1);
          }}
          onChangeField={(changeEvent: IOnChangeField) => {
            onChangeDTO(changeEvent.position, changeEvent.value);
          }}
        />
      )}

      {formStep === 2 && (
        <StepTwo
          DTO={DTO}
          prevStep={() => {
            setFormStep(formStep - 1);
          }}
          nextStep={() => {
            setFormStep(formStep + 1);
          }}
          onChangeField={(changeEvent: IOnChangeField) => {
            onChangeDTO(changeEvent.position, changeEvent.value);
          }}
        />
      )}

      {formStep === 3 && (
        <StepThree
          submitForm={async () => {
            await submitCPF();
          }}
          DTO={DTO}
          prevStep={() => {
            setFormStep(formStep - 1);
          }}
          nextStep={() => {
            setFormStep(formStep + 1);
          }}
          onChangeField={(changeEvent: IOnChangeField) => {
            onChangeDTO(changeEvent.position, changeEvent.value);
          }}
        />
      )}

      {formStep === 4 && (
        <StepFour
          submitForm={() => {
            submitCNPJ();
          }}
          DTO={DTO}
          prevStep={() => {
            setFormStep(formStep - 1);
          }}
          nextStep={() => {
            setFormStep(formStep + 1);
          }}
          onChangeField={(changeEvent: IOnChangeField) => {
            onChangeDTO(changeEvent.position, changeEvent.value);
          }}
        />
      )}
    </Container>
  );
}
