import { useContext, useState } from 'react';
import AdicionarPlaylist from '../../../components/AdicionarPlaylist';
import ListPlaylist from '../../../components/Playlist/ListPlaylist';
import UserContext from '../../../contexts/UserContext';
import { Container } from './styles';

export default function ListarPlaylists() {
  const { user } = useContext(UserContext);
  const [reload, setReload] = useState<boolean>(false);
  return (
    <Container>
      <div className="header">
        <AdicionarPlaylist
          reload={() => {
            setReload(true);
          }}
        />
      </div>
      <div className="listPlaylist">
        <h3 className="page-title">Suas Playlists</h3>
        <ListPlaylist consultorId={user?.id} recarregar={!reload} />
      </div>
    </Container>
  );
}
