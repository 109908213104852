import styled from 'styled-components';

export const Container = styled.div`
    height: 100%;
    width: 100%;
    padding: 20px 0 40px 40px;

    @media(max-width: 600px){
        padding: 20px;
    }
`

export const TermsTitle = styled.h1`
    color: var(--paleta2C);
    font-size: 32px;
    font-weight: 700;

    margin-bottom: 12px;
`
 
export const TermsSubtitle = styled.h2`
    color: var(--paleta2C);
    font-size: 24px;
    font-weight: 600;

    margin-top: 6px;
`

export const TermsText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 70%;
    text-align: justify;

    @media(max-width: 600px){
        max-width: 100%;
    }
`

export const TermsTextParagraph = styled.p`
    font-size: 16px;
    color: var(--paleta2C);

    &.mid-margin {
        margin-top: 12px;
    }

    &.big-margin {
        margin-top: 24px;
    }
`

export const TermsListUl = styled.ul`
    margin: 6px 0;
    list-style: circle;
    list-style-position: inside;

    .title-list {
        font-weight: 600;
        padding-bottom: 12px;
    }

    li {
        padding-left: 12px;
    }
`
 