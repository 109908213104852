import styled, { css } from 'styled-components';

interface props {
  type: string;
}

export const Container = styled.div<props>`
  position: relative;
  border: none;
  background-color: var(--background2);
  width: 100%;
  height: 40px;
  border-radius: 5px;
  padding-left: 15px;
  outline: none;

  &:focus {
    border: 1px solid var(--paleta1C);
  }

  .input {
    border: none;
    background-color: transparent;
    padding-left: 10px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    width: 70%;
    height: 100%;
    outline: none;
  }

  .adicionar {
    cursor: pointer;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    position: absolute;
    height: 100%;
    background-color: #ddd;
    border-radius: 5px;
    p {
     font-weight: 700;
    font-size: 11px;
    color: var(--texto);

    }
  }
`;
