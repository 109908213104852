import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;

  .content {
    margin-left: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .areaComment {
      display: flex;
      gap: 5px;

      .name {
        font-size: 13px;
        font-weight: 700;
        line-height: 15px;
        color: var(--paleta1A);
      }

      .moment {
        font-size: 13px;
        line-height: 14px;
        font-weight: 400;
        color: #7d7d7d;
      }
    }

    .comment {
      padding: 9px;
      margin-top: 7.5px;
      background: var(--background2);
      border: 1px solid var(--background2);
      border-radius: 0px 10px 10px 10px;

      p {
        font-size: 13px;
        font-weight: 400;
        line-height: 14px;
        color: #7d7d7d;
      }
    }
  }

  .image {
    min-height: 60px;
    max-height: 60px;
    overflow: hidden;
    min-width: 60px;
    border-radius: 50%;
    background-color: var(--mid-grey);
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    &.loading {
      > div {
        height: 60px;
      }
    }
  }
`;
