import { useContext, useEffect, useState } from 'react';
import CadastrarProdutos from '../../components/Empresa/CadastrarProdutos';
import EmpresaConfigDestaques from '../../components/Empresa/ConfigDestaques';
import ConsultoresEmpresa from '../../components/Empresa/Consultores';
import {
  IconArrowRight,
  IconCases,
  IconConfigurações,
  IconDestaque,
  IconInformacoes,
  IconMarca,
} from '../../components/Icons';
import UserContext from '../../contexts/UserContext';
import apiDoubts from '../../services/api';
import InformacoesPessoais from '../ConsultorSettings/InformacoesPessoais';
import { Button, Container, Subtitle } from './styles';
import Agencias from '../Agencia/Config/Agencias';

export default function AgenciaConfiguracoes() {
  const { user } = useContext(UserContext);

  const [selected, setSelected] = useState<string>('destaque');
  const [postagemPrincipal, setPostagemPrincipal] = useState<any>();
  const [optionsSelect, setOptionsSelect] = useState<any[]>([]);
  const [DTO, setDTO] = useState<any>({});
  const [banner, setBanner] = useState<any>();

  useEffect(() => {
    getPostagem(DTO.postagemPrincipal);
  }, [DTO?.postagemPrincipal]);

  function handleOnChange(name: any, value: any) {
    const newDTO: any = DTO;
    newDTO[name] = value;
    setDTO({ ...newDTO });
  }

  useEffect(() => {
    if (user) {
      getEmpresa();
      getPostagensSelect();
    }
  }, [user]);

  useEffect(() => {
    console.log(DTO);
  }, [DTO]);

  useEffect(() => {
    getBanner();
  }, []);

  async function getEmpresa() {
    apiDoubts.get(`/empresa/get-by-id/${user.id}`).then((response: any) => {
      handleConsultor(response?.data?.data);
      getPostagem(response?.data?.data[0]?.postagemPrincipal);
    });
  }

  function handleConsultor(data: any) {
    let newDTO = {
      foto: data?.foto,
      termosUso: true,
      descricaoCanal: data?.descricaoCanal,
      capa: data?.capa
        ? data?.capa
        : 'https://images.unsplash.com/photo-1542273917363-3b1817f69a2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=874&q=80',
    };
    setDTO(newDTO);
  }

  async function getPostagem(id: string) {
    apiDoubts
      .get(`/postagens/getPostagem-logado/${id}`)
      .then((response: any) => {
        setPostagemPrincipal(response.data.data);
      });
  }

  async function getPostagensSelect() {
    apiDoubts
      .get(`/postagens/getPostagemConsultor/${user.id}`)
      .then((response: any) => {
        handleOptions(response.data.data);
      });
  }

  function handleOptions(data: any) {
    let newData: any[] = [];
    data.map((row: any) => {
      let option = {
        value: row.id,
        label: row.titulo,
      };
      newData.push(option);
    });
    setOptionsSelect(newData);
  }

  async function getBanner() {
    apiDoubts.get(`/banners?tipo=3&limit=1`).then((response: any) => {
      setBanner(response.data.data);
    });
  }

  return (
    <Container>
      <div className="header">
        <h3 className="title">Personalização da Empresa</h3>
        <Subtitle className="subtitle">
          Personalize as informações visuais e apresentação da sua empresa
        </Subtitle>
        <div className="buttons">
          <Button
            selected={selected === 'destaque'}
            onClick={() => {
              setSelected('destaque');
            }}
          >
            <IconDestaque />
            <p className="textButton">DESTAQUES</p>
          </Button>

          <Button
            selected={selected === 'informacoes'}
            onClick={() => {
              setSelected('informacoes');
            }}
          >
            <IconConfigurações />
            <p className="textButton">INFORMAÇÕES</p>
          </Button>

          <Button
            selected={selected === 'produtos'}
            onClick={() => {
              setSelected('produtos');
            }}
          >
            <IconMarca />
            <p className="textButton">PRODUTOS</p>
          </Button>

          <Button
            selected={selected === 'consultores'}
            onClick={() => {
              setSelected('consultores');
            }}
          >
            <IconInformacoes color={'#069CD1'} />
            <p className="textButton">CONSULTORES</p>
          </Button>

          <Button
            selected={selected === 'agencias'}
            onClick={() => {
              setSelected('agencias');
            }}
          >
            <IconCases color={'#069CD1'} />
            <p className="textButton">AGÊNCIAS</p>
          </Button>
        </div>
        <div className="element" />
      </div>

      {selected === 'produtos' && <CadastrarProdutos />}

      {selected === 'destaque' && <EmpresaConfigDestaques />}

      {selected === 'consultores' && <ConsultoresEmpresa />}

      {selected === 'informacoes' && <InformacoesPessoais />}

      {selected === 'agencias' && <Agencias />}
    </Container>
  );
}
