import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import img from '../../assets/anuncio1.png';
import Loading from '../../assets/icons/Loading';
import apiDoubts from '../../services/api';

import { Container } from './styles';

interface banner {
  clicks: number;
  listaInteresses: any[];
  _id: string;
  titulo: string;
  descricao: string;
  tipo: string;
  caminho: string;
  deleted: boolean;
  deletedAt: any;
  createdAt: string;
  updatedAt: string;
  __v: number;
  link: string;
  caminhoFull: string;
  id: string;
}

interface BannerProps {
  peso?: '1' | '2' | '3';
  position?: '1' | '2' | '3';
  typeBanner?: '70x70' | '322x227' | '322x673' | '1024x90';
}

export default function Banner({ peso, position, typeBanner }: BannerProps) {
  const [banner, setBanner] = useState<any | null>(null);

  useEffect(() => {
    if (banner === null) {
      getBanner();
    }
  }, []);

  async function getBanner() {
    const response: any = await apiDoubts
      .get(
        `/campanha-exibir?banner_${typeBanner}=1&position=${position}&peso=${peso}&random`
      )
      .then((response: AxiosResponse) => {
        console.log(response?.data?.data[0]);
        setBanner(response?.data?.data[0]);
      });

    response?.data?.data?.forEach((banner: any) => {
      apiDoubts?.post(`visualizar-campanha`, {
        campanhaId: banner?._id,
        autorCampanhaId: banner?.empresaId,
        usuarioId: banner?._id ?? null,
        tipoUsuario: banner?.tipo ?? null,
      });
    });
  }

  function handleLinkBanner() {
    if (typeBanner === '70x70') return banner.banner_70x70;
    else if (typeBanner === '322x227') return banner.banner_322x227;
    else if (typeBanner === '322x673') return banner.banner_322x673;
    else if (typeBanner === '1024x90') return banner.banner_1024x90;
  }

  async function clickCampaing() {
    await apiDoubts
      .put(
        `/campanha-click/${banner?.id}?clicar=banner_${typeBanner}&position=${position}&peso=${peso}`
      )
      .then((response: any) => {
        console.log('=============> clicou na campanha');
      });

    apiDoubts?.post(`/clicar-campanha`, {
      campanhaId: banner?._id,
      autorCampanhaId: banner?.empresaId,
      usuarioId: banner?._id ?? null,
      tipoUsuario: banner?.tipo ?? null,
    });
  }

  return (
    <Container
      bannerType={typeBanner}
      onClick={() => {
        clickCampaing();
        window.open(`${banner.link}`, '_blank');
      }}
      target={'blank'}
    >
      <div
        className="bannerImageComponent"
        style={{
          backgroundImage: `url(${banner ? handleLinkBanner() : null})`,
        }}
      />
    </Container>
  );
}
