import { Container } from './styles';
import emptyBox from '../../assets/icons/empty-box-icon.svg';

export default function EmptyProducts({
  fullscreen = true,
  title = 'Você ainda não possui produtos...',
  description = 'Cadastre um produto e ele será mostrado aqui',
}) {
  return (
    <Container className={fullscreen ? 'fullscreen' : 'normal'}>
      <img className="emptyBoxIcon" src={emptyBox} />
      <>
        {title && <h1>{title}</h1>}
        {description && <h2 className="takeLogin">{description}</h2>}
      </>
    </Container>
  );
}
