import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Banner from '../../../components/Banner';
import ButttonDefault from '../../../components/ButtonDefault';
import ModalAttProduto from '../../../components/Empresa/ModalAttProduto';
import {
  IconCases,
  IconConfigurações,
  IconDestaque,
  IconMarca,
  IconSelo,
} from '../../../components/Icons';
import Inputdefault from '../../../components/Inputs/InputDefault';
import apiDoubts from '../../../services/api';
import Cases from './Cases';
import Config from './Config';
import Destaques from './Destaques';
import Empresas from './Empresas';
import {
  AreaModal,
  Banners,
  Container,
  Content,
  Header,
  Main,
  Menu,
  SectionMenu,
} from './styles';
import UserContext from '../../../contexts/UserContext';
import Consultores from '../../Consultores';
import ConsultoresEmpresa from '../../../components/Empresa/Consultores';

export default function AgenciaConfig() {
  const [menuSelected, setMenuSelected] = useState<
    'Destaques' | 'Cases' | 'Empresas' | 'Configurações' | 'Consultores'
  >('Destaques');
  const [banner, setBanner] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { user } = useContext(UserContext);

  const menus = [
    {
      icon: <IconDestaque />,
      label: 'Destaques',
    },
    {
      icon: <IconCases />,
      label: 'Cases',
    },
    {
      icon: <IconSelo />,
      label: 'Empresas',
    },
    {
      icon: <IconMarca />,
      label: 'Consultores',
    },
    {
      icon: <IconConfigurações />,
      label: 'Configurações',
    },
  ];

  const [consultoresSelect, setConsultoresSelect] = useState<any[]>([]);
  const [consultorSelected, setConsultorSelected] = useState<any>();
  const [enterpreuerSettings, setEnterpreurSettings] = useState<any>();
  const [DTO, setDTO] = useState<{ [key: string]: string }>({
    titulo: '',
    empresaId: '',
    subtitulo: '',
    comentarioEmpresa: '',
    texto: '',
  });
  const colourStyles = {
    control: (provided: Record<string, unknown>) => ({
      ...provided,
      color: '#ff0000',
      height: 48,
      width: '100%',
      zIndex: 5,
      boxShadow: 'none',
      cursor: 'pointer',
      backgroundColor: '#E7E7F3',
      border: 'none',

      '&:hover': {
        border: '1px solid #0661C7',
      },
    }),
    menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
    input: (provided: any) => ({
      ...provided,
      fontSize: 13,
      color: '#7D7D7D',
      fontWeight: 400,
    }),
    placeholder: (provided: any) => ({
      ...provided,
      fontSize: 13,
      color: '#7D7D7D',
      fontWeight: 400,
    }),
    singleValue: (provided: any) => ({
      ...provided,
      fontSize: 13,
      color: '#000',
      fontWeight: 400,
    }),
  };

  const [getCases, setGetCases] = useState<boolean>(false);

  const [sendingCase, setSendingCase] = useState<boolean>(false);

  useEffect(() => {
    handleUsersToSelect((user as any).empresas);
  }, [(user as any).empresas]);

  useEffect(() => {
    console.log('CONSULTOR SELECTED ==========>', consultorSelected);
  }, [consultorSelected]);

  useEffect(() => {
    getBanner();
  }, []);

  function handleUsersToSelect(userList: any) {
    const filteredArray = userList?.map((row: any) => {
      const newData = {
        value: row?.id,
        label: row?.nickName,
      };
      return newData;
    });
    setConsultoresSelect(filteredArray);
  }

  function resetModalData() {
    const emptyDTO = {
      titulo: '',
      empresaId: '',
      subtitulo: '',
      comentarioEmpresa: '',
      texto: '',
    };

    const selectedConsultor = { label: '', value: '' };

    setDTO(emptyDTO);
    setConsultorSelected(selectedConsultor);
  }

  function handleMenuSelected(
    menu: 'Destaques' | 'Cases' | 'Empresas' | 'Configurações'
  ) {
    if (menuSelected !== menu) {
      setMenuSelected(menu);
    }
  }

  async function getBanner() {
    apiDoubts.get(`/banners?tipo=3&limit=1`).then((response: any) => {
      setBanner(response.data.data);
    });
  }

  function handleOnChange(name: any, value: any) {
    const newDTO: { [key: string]: string } = { ...DTO };
    newDTO[name] = value;
    setDTO({ ...newDTO });
    return;
  }

  function formatarData(e: any) {
    try {
      if (moment(e.target.value).isAfter(new Date())) {
        throw new Error('você precisa escolher uma data que ja passou');
      }
      handleOnChange('subtitulo', e?.target?.value);
    } catch (err: any) {
      setSendingCase(false);
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  function checkInfos(infos: any) {
    try {
      setSendingCase(true);
      if (!infos?.empresaId || infos?.empresaId === '')
        throw new Error(
          'É preciso adicionar uma empresa à esse case de sucesso'
        );
      if (!infos?.titulo || infos.titulo === '')
        throw new Error('Adicione um titulo ao case de sucesso');
      if (!infos?.texto || infos.texto === '')
        throw new Error('Adicione uma descrição ao case de sucesso');
      if (!infos?.comentarioEmpresa || infos.comentarioEmpresa === '')
        throw new Error('Adicione um comentário da empresa ao case de sucesso');
      if (!infos?.subtitulo || infos.subtitulo === '')
        throw new Error('Adicione a data que ocorreu o case de sucesso');

      updateInfos(infos);
    } catch (err: any) {
      setSendingCase(false);
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  async function updateInfos(infos: any) {
    try {
      await apiDoubts.post(`/case/agencia`, infos).then((response: any) => {
        if (response?.data?.status === 'error')
          throw new Error(response?.data?.message?.error);
        console.log(response);
        setOpenModal(false);
        setEnterpreurSettings(infos);
        setGetCases(!getCases);
        resetModalData();
        setSendingCase(false);
        toast.success('Case de sucesso atualizado com sucesso!', {
          className: 'toast-sucess',
          theme: 'colored',
        });
      });
    } catch (err: any) {
      setSendingCase(false);
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  function handleSelectConsultor(e: any) {
    setConsultorSelected(e);
    handleOnChange('empresaId', e.value);
  }

  return (
    <Container>
      <ModalAttProduto
        onClose={() => {
          setOpenModal(false);
          resetModalData();
        }}
        id="ModalAttCaseDeSucesso"
        openModal={openModal}
        title="Novo Case de Sucesso"
        scroll={true}
      >
        <AreaModal>
          <div className="inputArea">
            <h3 className="label">Empresa</h3>
            <Select
              options={consultoresSelect}
              defaultValue={consultorSelected}
              value={consultorSelected}
              styles={colourStyles}
              onChange={handleSelectConsultor}
            />
          </div>
          <div className="inputArea">
            <h3 className="label">Título</h3>
            <Inputdefault
              change={(e: any) => {
                handleOnChange('titulo', e.target.value);
              }}
              placeholder={'Título'}
              tipo={'text'}
              value={DTO?.titulo}
            />
          </div>
          <div className="inputArea">
            <h3 className="label">Descrição</h3>
            <textarea
              value={DTO?.texto}
              className="comment"
              placeholder="Descrição..."
              maxLength={1000}
              onChange={(e) => {
                handleOnChange('texto', e?.target?.value);
              }}
            />
          </div>
          <div className="inputArea">
            <h3 className="label">Comentário da Empresa</h3>
            <textarea
              value={DTO?.comentarioEmpresa}
              className="comment"
              placeholder="Comentário..."
              maxLength={1000}
              onChange={(e) => {
                handleOnChange('comentarioEmpresa', e?.target?.value);
              }}
            />
          </div>
          <div className="inputArea">
            <h3 className="label">Data da Campanha</h3>
            <input
              type="date"
              className="DatePickerInput"
              onChange={(e: any) => {
                formatarData(e);
              }}
              value={moment(DTO?.subtitulo).format('YYYY-MM-DD')}
            />
          </div>
          <div className="areaButtons">
            <ButttonDefault
              title={'Cancelar'}
              tipo={'11'}
              onClick={() => {
                resetModalData();
                setOpenModal(false);
              }}
            />
            <ButttonDefault
              title={'Salvar'}
              tipo={'6'}
              onClick={() => {
                checkInfos(DTO);
              }}
              icon={sendingCase ? 'Loading-White' : ''}
            />
          </div>
        </AreaModal>
      </ModalAttProduto>

      <Header>
        <div className="title">
          <h1 className="pageConfigTitle">Personalização da Agência</h1>
          {menuSelected === 'Cases' && user.listaEmpresas.length > 0 && (
            <ButttonDefault
              tipo="2"
              title={'Novo Case de Sucesso'}
              onClick={() => {
                setOpenModal(true);
              }}
            />
          )}
        </div>
        <h2 className="pageConfigSubtitle">
          Personalize as informações, cases de sucesso e empresas da sua agência
        </h2>
        
        <Menu>
          {menus.map((row: any, key: number) => (
            <SectionMenu
              key={key}
              onClick={() => {
                handleMenuSelected(row?.label);
              }}
              isSelected={menuSelected === row?.label}
            >
              {row?.icon}
              <p className="labelMenu">{row?.label}</p>
            </SectionMenu>
          ))}
        </Menu>
      </Header>

      <Content>
        <Main>
          {menuSelected === 'Destaques' && <Destaques />}

          {menuSelected === 'Cases' && <Cases getCasesAtt={getCases} />}

          {menuSelected === 'Empresas' && <Empresas />}

          {menuSelected === 'Consultores' && <ConsultoresEmpresa />}

          {menuSelected === 'Configurações' && <Config />}
        </Main>
        <Banners>
          <Banner peso="2" position={'1'} typeBanner="322x227" />
          <Banner peso="2" position={'1'} typeBanner="322x673" />
        </Banners>
      </Content>
    </Container>
  );
}
