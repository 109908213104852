import { useContext, useEffect, useState } from 'react';
import ChatMailBox from '../../../components/MailBox/ChatmailBox';
import * as S from './styles';
import UserContext from '../../../contexts/UserContext';
import moment from 'moment';
import apiDoubts from '../../../services/api';
import { useHistory, useParams } from 'react-router-dom';
import ButttonDefault from '../../../components/ButtonDefault';
import { IconLoading } from '../../../components/Icons';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';

export default function EmailDetails() {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const { id } = useParams<any>();

  const [emailSelected, setEmailSelected] = useState<any>();
  const [loadingNewEmail, setLoadingNewEmail] = useState<boolean>(true);

  const recipient = emailSelected?.usuariosConversando.filter(
    (userTalk: any) => userTalk?.conta?.id !== user?.id
  )[0];

  useEffect(() => {
    if (id) {
      getEmailDetails(id);
    }
  }, [id]);

  async function getEmailDetails(id: string) {
    try {
      const completeEmail = await apiDoubts.get(
        `conversa/getById/${id}?limit=10&page=1`
      );
      setLoadingNewEmail(false);
      setEmailSelected(completeEmail?.data?.data);
    } catch (error) {
      setLoadingNewEmail(false);
    }
  }

  function handleNickNameDestinatario(data: any) {
    if (!data) return 'Carregando...';
    return recipient?.conta?.nickName;
  }

  function handleUserPath(emailSelected: any) {
    if (!emailSelected) return false;

    if (recipient?.tipoDaConta === 'agencia') {
      history.push(
        `/${recipient?.tipoDaConta}/visualizar/${recipient?.conta?._id}`
      );
    } else if (recipient.tipoDaConta !== 'cadastro') {
      history.push(
        `/${recipient?.tipoDaConta}/visualizar/canal/${recipient?.conta?._id}`
      );
    }
  }

  function handlePhotoDestinatario(data: any) {
    if (!data) return 'Carregando...';
    return recipient?.conta?.foto;
  }

  async function handleStatusConvite(decision: boolean) {
    try {
      apiDoubts
        .put(`/conversa/responder-convite`, {
          idConvite: emailSelected?.convite?._id,
          aceitar: decision,
        })
        .then((apiResponse: AxiosResponse<any>) => {
          let confirmMessage: string = 'Convite aceito com sucesso!';

          if (!decision) confirmMessage = 'Convite recusado com sucesso!';

          toast.success(confirmMessage, {
            className: 'toast-sucess',
            theme: 'colored',
          });
        });
    } catch (err: any) {
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  return (
    <S.Container>
      <S.Left>
        {emailSelected?.convite &&
          emailSelected?.convite?.statusConvite === 'Pendente' &&
          emailSelected?.convite?.destinatario[0]?._id === user?.id && (
            <div className="buttonsArea">
              <ButttonDefault
                title={'Aceitar'}
                tipo="2"
                full
                onClick={() => {
                  handleStatusConvite(true);
                }}
              />
              <ButttonDefault
                title={'Recusar'}
                tipo="8"
                full
                onClick={() => {
                  handleStatusConvite(false);
                }}
              />
            </div>
          )}

        <div className="areaConsultor">
          <ButttonDefault
            title={'Voltar'}
            tipo={'5'}
            full={true}
            onClick={() => {
              history.goBack();
            }}
            hover="medio"
          />
          <div
            className="imageProfile"
            style={{
              backgroundImage: `url(${handlePhotoDestinatario(emailSelected)})`,
            }}
          ></div>
          <div className="nameMailRemetente">
            {handleNickNameDestinatario(emailSelected)}
          </div>
          <ButttonDefault
            title={'Ver Perfil'}
            tipo={'4'}
            full={true}
            onClick={() => {
              handleUserPath(emailSelected);
            }}
          />
        </div>
      </S.Left>

      <S.Rigth>
        <div className="headerEmailSelected">
          <div className="init">
            <p className="emailSelectTitle">
              {emailSelected?.conversa?.tipoConversa === 'email'
                ? 'Conversa com '
                : 'Convite de '}
              {handleNickNameDestinatario(emailSelected)}
            </p>
          </div>
          <p className="dateEmailSelect">
            {moment(new Date()).format('HH:mm - DD/MM/yyyy')}
          </p>
        </div>

        {loadingNewEmail ? (
          <div className="loadingWrapper">
            <IconLoading />
          </div>
        ) : (
          <ChatMailBox
            data={emailSelected}
            newMessage={(message: string) => {
              getEmailDetails(id);
            }}
          />
        )}
      </S.Rigth>
    </S.Container>
  );
}
 