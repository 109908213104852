import styled from 'styled-components';

export const LoginContainer = styled.div`
    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
`

export const LoginCardLeft = styled.div`
    width: 435px;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: var(--white);

    .logo {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 35px;
        height: 100px;
        width: 100%;
        border-bottom: 1px solid var(--mid-grey);
    }

    .middle {
        height: 795px;
        padding: 35px;
        .text {
            text-align: left;
            margin-bottom: 30px;
            h1 {
                color: var(--purple2);
                font-size: 2.3rem;
                font-weight: 900;  
                margin-bottom: 30px;  
            }
            p {
                font-size: 1.55rem;
                font-weight: 600;
                color: var(--purple6);
            }
        }
    
        .input {
            form {
                display: flex;
                flex-direction: column;
                input {
                    width: 100%;
                    height: 55px;
                    border-radius: 4px;
                    border: 1px solid var(--green);
                    background: rgba(15, 186, 186, .1);
                    color: var(--dark-grey); 
                    font-size: 16px;
                    padding: 15px;
                    margin-bottom: 15px;
                }
                button {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 15px;
                    margin-top: 15px;
                    margin-bottom: 30px;
                    width: 100%;
                    height: 55px;
                    background: var(--green);
                    border: none;
                    border-radius: 4px;
                    color: var(--white);
                    font-size: 18px;
                    font-weight: 800;
                    &:hover {
                       background: var(--purple2);
                    }
                    &:focus {
                        background: var(--purple2);
                    }
                }
                .pass {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    height: 55px;
                    border-radius: 4px;
                    border: 1px solid var(--green);
                    background: rgba(15, 186, 186, .1);
                    color: var(--dark-grey); 
                    font-size: 16px;
                    padding: 15px;
                    margin-bottom: 15px;
                    .input-icon {
                        width: 100%;
                        height: 55px;
                        border-radius: 0;
                        border: none;
                        background: none;
                        color: var(--dark-grey); 
                        font-size: 16px;
                        padding: 0;
                        margin-bottom: 0;
                        &:focus {
                            border: none;
                            outline: none;
                        }
                    }
                }
            }
            p {
                color: var(--green);
                font-weight: 700;
                font-size: 19px;
            }
        }
    }

    .footer {
        display: flex;
        align-items: center;
        padding: 35px;
        width: 100%;
        height: 130px;
        background: var(--purple2);
        bottom: 0;
        p {
            font-weight: 400px;
            font-size: 16px;
            color: var(--purple6);
        }
        a {
            color: var(--white);
            margin-left: 5px;
        }
        span {
            margin-left: 5px;
        }
    }

    @media (max-width: 425px) {
        .middle{
            .text {
                h1 {
                    font-size: 2.5rem;
                }
                p {
                    font-size: 1.7rem;
                }
            }
        }
    }
`
export const LoginCardRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: calc(100% - 430px);
    height: 100%;
    background: var(--background);
    background-size: cover;
    background-position: bottom center;

    filter: grayscale(1);
    -webkit-filter: grayscale(100%);
`
