import styled from 'styled-components';

export const DivCheckbox = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  .label {
    font-size: 90%;
    color: var(--purple2);
    margin-left: 10px;
  }
  .checkbox {
    display: flex;
    justify-content: left;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    .text {
      display: inline-block;
      font-size: 14px;
      color: var(--purple3);
    }
    .icon {
      display: flex;
      width: 18px;
      height: 18px;
      border-radius: 4px;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--background2);
    }
    &.active {
      .icon {
        background: #685ea9;
        border-color: #685ea9;
        svg {
          width: 16px;
          height: 16px;
          path {
            fill: white;
          }
        }
      }
    }
  }

  @media (min-width: 600px) {
    .checkbox {
      &:hover {
      }
    }
  }
`;
