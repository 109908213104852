import { useEffect, useState } from 'react';
import apiDoubts from '../../services/api';
import { Container } from './styles';
import GIF from '../../assets/not-found.gif';
import ButttonDefault from '../ButtonDefault';
import { toast } from 'react-toastify';
import { IconLoading } from '../Icons';
import CardPostAgencia from '../Posts/CardPostAgencia';
import { useParams } from 'react-router-dom';
import CardPost from '../Posts/CardPost';

interface IProps {
  playlist?: any;
  posicao?: string;
  where?: {
    interesse?: string;
    search?: string;
    hash?: string;
  };
  order?: string;
  consultorId?: string;
  tipo?: string;
  vistas?: boolean;
}

export default function ListPostCanalEmpresa({
  vistas,
  playlist,
  posicao,
  where = {
    hash: '',
    search: '',
    interesse: '',
  },
  order = 'popular',
  consultorId,
  tipo,
}: IProps) {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [completeData, setCompleteData] = useState<any>();

  // ------------ NEW STATES ----------- //

  const [page, setPage] = useState<number>(1);
  const [videos, setVideos] = useState<any>();

  const params: any = useParams();

  useEffect(() => {
    if (page == 1) {
      //quando abre a página pela primeira vez
      getData([]);
      GetDataVistas([]);
    }
    if (page > 1) {
      //quando carrega um novo video
      getData(data);
      GetDataVistas(data);
    }
  }, [playlist, page]);

  useEffect(() => {
    getData([]);
  }, [where]);

  async function GetDataVistas(dados: any) {
    if (vistas) {
      console.log('pegou os videos do vistas');
      setLoading(true);
      try {
        if (!page) {
          throw new Error('Não há mais vídeos para serem carregados');
        }

        page === 1 && setData([]);

        const response: any = await apiDoubts.get(
          `/postagens?empresa=6317a9284f04d310745f00e6&pagination=true&limit=20&page=${page}&ordenar=${order}`
        );
        setCompleteData(response);
        const newData: any = [];
        

        response?.data?.data?.docs?.reverse().map((row: any, key: number) => {
          newData.push(row);
        });

        if (!dados[0]) {
          //quando eu quero que apareça apenas os vídeos que foram puxados agora
          setData([...newData]);
        }
        if (dados[0]) {
          //quando eu quero carregar novas postagens
          setData([...dados, ...newData]);
        }

        // setData( [...data, ...newData]);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        toast.error(error, {
          className: 'toast-error',
          theme: 'colored',
        });
      }
    }
  }  

  async function getData(data: any) {
    try {
      const response: any = await apiDoubts.get(
        `/postagens/getPostagemEmpresa/${params?.id}?pagination=true&page=1&limit=1000`
      );

      setVideos(response.data.data.docs);
      setLoading(false);
    } catch (error) {}
  }

  const handleScroll = (event: any) => {
    console.log('scrollTop: ', event.currentTarget.scrollTop);
    console.log('offsetHeight: ', event.currentTarget.offsetHeight);
  };

  return (
    <Container
      loading={loading}
      lenght={data.length}
      posicao={posicao}
      playlist={playlist}
      onScroll={handleScroll}
    >
      {videos?.length > 1 &&
        videos?.map((row: any, key: any) => (
          <CardPost
            playlist={playlist}
            key={key}
            item={row}
            type={row.type ? 'anuncio' : tipo}
            posicao={posicao}
          />
        ))}

      {!loading && videos?.length <= 1 && (
        <div className="empty-posts">
          <img src={GIF} />
          <p>Nenhum registro encontrado.</p>
        </div>
      )}

      {loading && (
        <div className="divLoading">
          <IconLoading />
        </div>
      )}
    </Container>
  );
}
