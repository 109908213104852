import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HeaderEmpresa from '../../../components/Empresa/HeaderEmpresa';
import ListPostsDescubra from '../../../components/Posts/ListPostDescubra';
import ListPosts from '../../../components/Posts/ListPosts';
import TopConsultores from '../../../components/TopConsultores';
import UserContext from '../../../contexts/UserContext';
import apiDoubts from '../../../services/api';
import { Container, Element } from './styles';

export default function EmpresaLive({ data }: any) {
  const [perfilCompletoEmpresa, setPerfilCompletoEmpresa] = useState<any>();
  const params = useParams<any>();

  return (
    <Container>
      <HeaderEmpresa id={params?.id} page="lives" />
      <Element />
      <div className="areaConsultores">
        <TopConsultores direction="row" empresaId={params?.id} />
      </div>
      <Element />
      <ListPostsDescubra
        order={''}
        where={{
          interesse: '',
          hash: '',
        }}
      />
    </Container>
  );
}
