// import styled from 'styled-components';

// export const Container = styled.div`
//   width: 100%;
//   padding: 40px;

//   .toast-error {
//     background: var(--purple2);
//   }
//   .toast-sucess {
//     background: var(--green);
//   }
//   .progress {
//     background: var(--mid-grey);
//   }

// `

// export const Content = styled.div`
//   width: 100%;
//   height: 100%;
//   margin: 0 auto;
//   background: var(--background);
//   border-radius: 8px;
//   display: flex;
//   align-items: center;
//   gap: 20px;
//   position: relative;

//   .close {
//     position: absolute;
//     top: 0px;
//     right: 0px;
//     margin: -15px -15px 0 0;
//     background: transparent;
//     border-radius: 50%;
//     display: flex;
//     align-items: center;
//     border: 2px solid var(--background);
//     cursor: pointer;
//     transition: all .2s;
//    &:hover {
//       background: var(--green);
//       border: 2px solid var(--green);
//       svg {
//         path {
//           stroke: var(--white);
//           stroke-Width: 3px;
//         }
//       }
//     }
//     svg {
//       width: 30px;
//       height: 30px;
//       path {
//         stroke: var(--green);
//         strokeWidth: 3px;
//       }
//     }
//   }

//   @media (max-width: 425px) {
//     flex-direction: column;
//   }
// `

// export const ContentLeft = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-self: flex-start;
//   gap: 10px;
//   width: 45%;
//   h2 {
//     color: var(--purple2);
//     font-weight: 900;
//   }

//   form {
//     width: 100%;
//     border-radius: 4px;
//     .title,
//     .link,
//     .consultant {
//       width: 100%;
//       display: flex;
//       flex-direction: column;
//       label {
//         padding: 10px 0;
//         color: var(--purple2);
//         margin-right: 10px;
//       }
//       input {
//         width: 90%;
//         height: 40px;
//         margin-bottom: 20px;
//         border: 1px solid #c4c4c4;
//         border-radius: 4px;
//         padding: 10px;
//         font-size: 15px;
//         &:focus {
//           border: 1px solid var(--purple2);
//           outline: none;
//         }
//       }
//     }
//     .highlights {
//       width: 100%;
//       display: flex;
//       margin-bottom: 20px;
//       label {
//         padding: 10px 0;
//         color: var(--purple2);
//         margin-right: 10px;
//       }
//       textarea {
//         width: 90%;
//         height: 150px;
//         margin-bottom: 40px;
//         padding: 10px;
//         font-size: 16px;
//         border: 1px solid #c4c4c4;
//         border-radius: 4px;
//         line-height: 20px;
//          &:focus {
//           border: 1px solid var(--purple2);
//           outline: none;
//         }
//       }
//     }
//     button {
//       width: 90%;
//       height: 50px;
//       background: var(--green);
//       border: none;
//       border-radius: 4px;
//       padding: 10px;
//       margin-bottom: 20px;
//       color: var(--white);
//       font-size: 18px;
//       transition: all .5s;
//       &:hover {
//           background: var(--purple2);
//       }
//       &:focus {
//           background: var(--purple2);
//       }
//     }
//     .cancel {
//       background-color: var(--purple2);
//       &:hover {
//         background: var(--purple4);
//       }
//     }
//   }

//   @media(max-width: 1199px) {
//       width: 100%;
//       align-self: center;
//       form {
//         .title,
//         .link,
//         .consultant {
//           input {
//             width: 100%;
//           }
//         }
//         button {
//           width: 100%;
//         }
//       }
//   }
// `;

// export const ContentRight = styled.div`
//   display: flex;
//   align-items: center;
//   flex-direction: column;
//   width: 50%;
//   height: 100%;
//   gap: 20px;
//   margin-left: 10px;

//   h2 {
//     color: var(--purple2);
//     font-weight: 900;
//   }
//   .video {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     margin-bottom: 15px;
//     iframe {
//         width: 500px;
//         height: 300px;
//         border-radius: 8px;
//         border: 1px solid var(--purple2);
//     }
//     .loader {
//         width: 490px;
//         height: 300px;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//     }
//   }
//   .highlights {
//     width: 500px;
//     height: 100px;
//     p {
//       color: var(--purple2);
//       border: 1px solid #c4c4c4;
//       border-radius: 4px;
//       padding: 10px;
//       line-height: 20px;
//       text-align: center;
//     }
//     .with {
//       background: var(--green);
//       font-weight: 900;
//     }
//     .without {
//       font-weight: 900;
//     }
//   }

//   @media (max-width: 425px) {
//     width: 100%;
//     margin-left: 0;
//     .video {
//       iframe {
//         width: 425px;
//         border-radius: 0;
//       }
//     }
//     .highlights {
//       width: 100%;
//       height: 50px;
//       box-sizing: border-box;
//       p {
//         padding: 14px;
//       }
//     }
//   }
// `

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  .selectAction {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .container-box {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 35px;
    margin-top 10px;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 18px !important;
      margin-bottom: 10px;
    }
    .resume {
      background: white;
      box-shadow: 0px 10px 10px rgba(0,0,0,.05);
      padding: 15px 20px;
      border-radius: 8px;
      display: flex;
      gap: 10px;
      align-items: center;
      svg {
        width: 16px;
        height: 16px;
        min-width: 16px;
      }
      p {
        font-size: 14px;
      }
    }
  }

  .main {
    width: 100%;
    display: flex;
    gap: 30px;
    .areaLeft {
      flex: 1;
      height: 100%;
      .title {
        font-size: 28px;
        line-height: 30px;
        font-weight: 700;
        color: var(--paleta2C);
      }
      .subtitle {
        margin-top: 20px;
        font-size: 13px;
        line-height: 15px;
        font-weight: 700;
        color: var(--texto);
        margin-bottom: 5px;
      }
      .comment {
        width: 100%;
        height: 120px;
        background-color: var(--background2);
        margin-top: 3px;
        border: none;
        border-radius: 4px;
        padding: 10px;
        outline: none;
        resize: none;
      }
      .areaButton {
        margin-top: 15px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding-bottom: 50px;
        gap: 10px;
      }
    }
    .areaRight {
      width: 520px;
      height: 100%;
      .subtitle {
        font-size: 28px;
        line-height: 30px;
        font-weight: 700;
        color: var(--paleta2C);
        margin-bottom: 5px;
        margin-bottom: 10px;
      }
      .cardpost {
        display: flex;
        width: 100%;
      }
    }
  }

  @media (max-width: 1150px) {
    .main {
      display: flex;
      flex-direction: column;
      .areaLeft {
        width: 80%;
        .areaButton {
          padding: 0;
        }
      }
      .areaRight {
        width: 100%;
      }
    }
    @media (max-width: 800px) {
      gap: 30px;
      .main {
        display: flex;
        flex-direction: column;
        .areaLeft {
          width: 100%;
          gap: 0px;
          .areaButton {
            padding: 0px;
          }
        }
        .areaRight {
          width: 100%;
          margin-top: 0;
          .cardpost {
            min-width: 100%;
          }
        }
      }
    }
  }
`;
