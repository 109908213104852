import styled from 'styled-components';

interface Props {
  openModal: boolean;
  width?: string;
}

export const ContainerOverlay = styled.div<Props>`
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  opacity: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: ${(props) => (props.openModal ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 1000;
  @media (max-width: 425px) {
    height: 100vh;
    max-height: 100vh;
    margin-top: 0;
    overflow: hidden;
  }
`;

export const Container = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0px;

  .container-modal {
    width: 550px;
    max-width: 90%;
    max-height: 80vh;
    position: relative;
    background-color: #ffffff;
    padding: 25px;
    border-radius: 8px;
  }
  .head-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content-modal {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 400;
    padding: 20px 0px;
    > p {
      text-align: center;
      margin: 15px 0px;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    > button {
      flex: 1;
    }
  }
`;
