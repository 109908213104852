/* eslint-disable react-hooks/exhaustive-deps */
import ModalForm from '../../../components/Cadastrar/ModalForm';
import Inputdefault from '../../../components/Inputs/InputDefault';
import { IStepsProps } from './types';

import * as S from './styles';
import SelectDefaultCadastrar from '../../../components/Cadastrar/SelectDefaultCadastrar';
import ButttonDefault from '../../../components/ButtonDefault';
import StepsInSignUp from '../../../components/Cadastrar/StepsInSignUp';
import apiDoubts from '../../../services/api';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  formatAgencia,
  formatBankAccount,
  formatCel,
  formatCep,
  formatCPF,
  formatData,
  formatEmail,
  formatRG,
} from '../../../utils/format';

export default function StepThree({
  nextStep,
  onChangeField,
  DTO,
  prevStep,
  submitForm,
}: IStepsProps) {
  const [bankData, setBankData] = useState<Array<any>>([]);
  const [loadingSubmitForm, setLoadingSubmitForm] = useState<boolean>(false);

  useEffect(() => {
    console.log(DTO);
    if (DTO?.tipo?.value === 'CPF') {
      getAllBanks();
    }
  }, [DTO]);

  async function getAllBanks() {
    await apiDoubts
      .get(`getAllBanks`)
      .then((response: any) => {
        handleBanksData(response.data.data);
      })
      .catch((error: any) => {
        console.error(error.message);
      });
  }

  function handleBanksData(banksArray: Array<any>) {
    let newBankArrayFormated: Array<any> = [];

    banksArray.forEach((rowBank: any) => {
      let newPositionArray = {
        label: rowBank.value + ' - ' + rowBank.label,
        value: rowBank.value + rowBank.label,
      };
      newBankArrayFormated.push(newPositionArray);
    });
    setBankData(newBankArrayFormated);
  }

  async function validateStepThreeFieldsCPF() {
    setLoadingSubmitForm(true);
    await submitForm()
      .then(() => {
        setLoadingSubmitForm(false);
      })
      .catch(() => {
        setLoadingSubmitForm(false);
      });
  }

  function validateStepTwoFieldsCNPJ() {
    try {
      if (!DTO?.nome)
        throw new Error('É necessário digitar um nome de usuário');
      if (!DTO?.cpf) throw new Error('É necessário digitar um CPF válido');
      if (!DTO?.rg) throw new Error('É necessário digitar um RG válido');
      if (!DTO?.sexo) throw new Error('Escolha o sexo');
      if (!DTO?.dataNascimento)
        throw new Error('Digite a sua data de nascimento');
      if (!DTO?.telefone) throw new Error('Digite o seu telefone de contato');
      if (!DTO?.endereco) throw new Error('É necessário digitar um CEP válido');
      nextStep();
    } catch (error: any) {
      toast.error(error.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  return (
    <S.ContainerStepThree>
      <ModalForm>
        {/* caso o usuário tenha escolhido CPF essa etapa trata dos cadastros bancários */}
        {DTO?.tipo?.value === 'CPF' && (
          <>
            <StepsInSignUp currentStep={3} totalSteps={3} />

            <form
              className="signUpForm"
              onSubmit={(e: any) => {
                e.preventDefault();
                validateStepThreeFieldsCPF();
              }}
            >
              <span className="inputField">
                <label>Banco (opcional)</label>
                <SelectDefaultCadastrar
                  onChange={(e: any) => {
                    onChangeField({ position: 'tipo_conta', value: e.value });
                  }}
                  options={bankData}
                  value={DTO?.tipo_conta?.value}
                />
              </span>

              <span className="inputField">
                <label>Tipo de conta (opcional)</label>
                <SelectDefaultCadastrar
                  onChange={(e: any) => {
                    onChangeField({ position: 'tipo_conta', value: e.value });
                  }}
                  options={[
                    { label: 'Conta Corrente', value: 'corrente' },
                    { label: 'Conta Poupança', value: 'poupança' },
                    { label: 'Conta Salário', value: 'salario' },
                  ]}
                  value={DTO?.tipo_conta?.value}
                />
              </span>

              <span className="inputField">
                <label>Agência (opcional)</label>
                <Inputdefault
                  color="transparent"
                  tipo="text"
                  placeholder="9999"
                  value={DTO.agencia}
                  change={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const formattedAgency = formatAgencia(e.target.value);
                    onChangeField({
                      position: 'agencia',
                      value: formattedAgency,
                    });
                  }}
                />
              </span>

              <span className="inputField">
                <label>Número da conta (opcional)</label>
                <Inputdefault
                  color="transparent"
                  tipo="text"
                  placeholder="99999-99"
                  value={DTO.numeroConta}
                  change={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const formattedBankAccount = formatBankAccount(
                      e.target.value
                    );
                    onChangeField({
                      position: 'numeroConta',
                      value: formattedBankAccount,
                    });
                  }}
                />
              </span>

              <span className="inputField">
                <label>PIX (opcional)</label>
                <Inputdefault
                  color="transparent"
                  tipo="text"
                  placeholder="99999999999"
                  change={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChangeField({
                      position: 'nickname',
                      value: e.target.value,
                    });
                  }}
                />
              </span>

              <div className="twoButtons">
                <ButttonDefault
                  tipo="14"
                  onClick={() => {
                    prevStep();
                  }}
                  title="Voltar"
                  hover="pouco"
                />

                <ButttonDefault
                  type="submit"
                  tipo={'13'}
                  title="Concluir Cadastro"
                  hover="pouco"
                  icon={loadingSubmitForm ? 'Loading' : ''}
                />
              </div>
            </form>
          </>
        )}

        {/* caso o usuário tenha escolhido CNPJ essa etapa cadastra os dados do responsável PF pela empresa */}
        {DTO?.tipo?.value === 'CNPJ' && (
          <>
            <StepsInSignUp currentStep={3} totalSteps={4} />

            <p className="titleStep">Responsável pela empresa</p>

            <form className="signUpForm">
              <div className="twoInLine">
                <span className="inputField">
                  <label>Nome Completo</label>
                  <Inputdefault
                    color="transparent"
                    tipo="text"
                    placeholder="Apelido"
                    value={DTO?.nome}
                    change={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onChangeField({
                        position: 'nome',
                        value: e.target.value,
                      });
                    }}
                  />
                </span>

                <span className="inputField">
                  <label>CPF</label>
                  <Inputdefault
                    color="transparent"
                    tipo="text"
                    value={DTO?.cpf}
                    placeholder="999.999.999-99"
                    change={(e: React.ChangeEvent<HTMLInputElement>) => {
                      let formmatedCpf = formatCPF(e.target.value);
                      onChangeField({
                        position: 'cpf',
                        value: formmatedCpf,
                      });
                    }}
                  />
                </span>
              </div>

              <div className="twoInLine">
                <span className="inputField">
                  <label>RG</label>
                  <Inputdefault
                    color="transparent"
                    tipo="text"
                    value={DTO?.rg}
                    placeholder="99.999.999-3"
                    change={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const formattedRG = formatRG(e.target.value);
                      onChangeField({
                        position: 'rg',
                        value: formattedRG,
                      });
                    }}
                  />
                </span>

                <span className="inputField">
                  <label>Gênero</label>
                  <SelectDefaultCadastrar
                    onChange={(e: any) => {
                      onChangeField({ position: 'sexo', value: e });
                    }}
                    options={[
                      { label: 'Masculino', value: 'masculino' },
                      { label: 'Feminino', value: 'feminino' },
                      { label: 'Outro', value: 'outro' },
                    ]}
                    value={DTO?.sexo}
                  />
                </span>
              </div>

              <div className="twoInLine">
                <span className="inputField">
                  <label>Data de nascimento</label>
                  <Inputdefault
                    color="transparent"
                    tipo="text"
                    placeholder="01/01/2001"
                    value={DTO?.dataNascimento}
                    change={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const formattedDate = formatData(e.target.value);
                      onChangeField({
                        position: 'dataNascimento',
                        value: formattedDate,
                      });
                    }}
                  />
                </span>

                <span className="inputField">
                  <label>Telefone</label>
                  <Inputdefault
                    color="transparent"
                    tipo="text"
                    value={DTO?.telefone}
                    placeholder="(99) 99999 - 9999"
                    change={(e: React.ChangeEvent<HTMLInputElement>) => {
                      let formattedCel = formatCel(e.target.value);
                      onChangeField({
                        position: 'telefone',
                        value: formattedCel,
                      });
                    }}
                  />
                </span>
              </div>

              <span className="inputField">
                <label>Email Responsável</label>
                <Inputdefault
                  color="transparent"
                  tipo="text"
                  value={DTO?.emailEmpresa}
                  placeholder="teste@gmail.com"
                  change={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const formattedEmail: string = formatEmail(e.target.value);
                    onChangeField({
                      position: 'emailEmpresa',
                      value: formattedEmail,
                    });
                  }}
                />
              </span>

              <p className="titleStep">Perfil</p>

              <span className="inputField">
                <label>CEP</label>
                <Inputdefault
                  color="transparent"
                  tipo="text"
                  value={DTO?.endereco}
                  placeholder="99999-999"
                  change={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const formattedCep = formatCep(e.target.value);
                    onChangeField({
                      position: 'endereco',
                      value: formattedCep,
                    });
                  }}
                />
              </span>

              <div className="twoButtons">
                <ButttonDefault
                  tipo="14"
                  onClick={() => {
                    prevStep();
                  }}
                  title="Voltar"
                  hover="pouco"
                />

                <ButttonDefault
                  type="button"
                  onClick={() => {
                    validateStepTwoFieldsCNPJ();
                  }}
                  tipo={'13'}
                  title="continuar"
                  hover="pouco"
                />
              </div>
            </form>
          </>
        )}
      </ModalForm>
    </S.ContainerStepThree>
  );
}
