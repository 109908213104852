import { Container } from './styles';
import Select, { NonceProvider } from 'react-select';
import { useEffect, useState } from 'react';

export interface IOptions {
  label: any;
  value: any;
}

interface ISelect {
  tipo?: '1' | '2';
  options: IOptions[];
  value: any;
  onChange(value: IOptions): void;
}

export default function SelectDefaultSearch({
  options,
  onChange,
  value,
  tipo,
}: ISelect) {
  const [valueState, setValueState] = useState<any>(value);
  const [height, setHeight] = useState<string>('40px');
  const [width, setWidth] = useState<string>('100%');

  useEffect(() => {
    setValueState(value);
  }, [value]);

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      border: '1px solid #069CD1',
      height: '40px',
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: '#069CD1',
      fontSize: 13,
      fontWeight: 400,
    }),
  };

  const customStyles2 = {
    option: (provided: any, state: any) => ({
      ...provided,
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: '#E7E7F3',
      border: 'none',
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: '#AAA',
      fontSize: 13,
      fontWeight: 400,
    }),
  };

  function handleSize() {
    if (height == '40px') {
      setHeight('45px');
      setWidth('101%');
    } else {
      setHeight('40px');
      setWidth('100%');
    }
  }

  return (
    <Container tipo={tipo}>
      <Select
        placeholder="Selecione"
        options={options}
        onChange={(e: IOptions) => onChange(e)}
        value={valueState}
        styles={tipo === '2' ? customStyles2 : customStyles}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    </Container>
  );
}
