import { CardConsultor, Container } from "./styles"
import { Link } from 'react-router-dom'
import ConsulterImg from '../../assets/Consultor2.png'
import { Separator } from "../HomeConsultant/styles"


const Consultores: React.FC<any> = () => {
    return (
        <Container>
            
            {[0, 1, 2, 3, 4, 5].map((key: any) =>
                <CardConsultor key={key}>
                    <header>
                        <div>
                            <h2>Alexandre Rossi</h2>
                            <Link to="/">
                            <span>Ver o perfil</span>
                            </Link>
                        </div>
                    </header>

                    <main>
                    <div className="CardVideo">
                        <Link to="#">
                        <img src={ConsulterImg} alt="Imagem" />
                        <p>Especialista em comportamento animal, palestrante e apresentador de programas de TV e rádio brasileiros, que abordam dicas comportamentais para melhorar o relacionamento entre os pets e os donos.</p>
                        </Link>
                    </div>

                    <Separator />

                    <div className="Info">
                        <table>
                            <tbody>
                                <tr>
                                    <td>Ranking por visualizações</td>
                                    <td className="value">3 de 10</td>
                                </tr>
                                <tr>
                                    <td>Visualizações</td>
                                    <td className="value">1.3K &nbsp;<div className="positive">+ 32%</div></td>
                                </tr>
                                <tr>
                                    <td>Média de duração de visualização</td>
                                    <td className="value">1:42 &nbsp;<div className="positive">+ 8%</div> </td>
                                </tr>
                                <tr>
                                    <td>Tempo assistido (em minutos)</td>
                                    <td className="value">1:03 &nbsp;<div className="negative">- 5%</div></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </main>
                    <Separator />
                    <footer>
                    <Link to="/">
                        <span>Ver Analytics</span>
                        <span>Ver Comentarios</span>
                    </Link>
                    </footer>
                </CardConsultor>
            )}
        </Container>
    )
}

export default Consultores;