import styled from 'styled-components';

interface Props {
  pagina?: string;
}

export const Container = styled.div<Props>`
  width: 100%;

  .video {
    width: 440px;
    height: 248px;
    background-size: cover;
    background-position: center;
    background-color: var(--mid-grey);
    border-radius: 10px;

    cursor: pointer;
  }

  .name {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 12px;
    width: 100%;
    justify-content: space-between;

    .areaLeft {
      display: flex;
      align-items: center;
      gap: 10px;

      h3 {
        font-size: 28px;
        font-weight: 700;
        color: var(--paleta2C);
        line-height: 42px;
      }
    }

    .inscritos {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .descricao {
    font-size: 13px;
    font-weight: 400;
    line-height: 14px;
    color: #7d7d7d;
    margin-bottom: 16px;

    white-space: break-spaces;
  }
  .buttonWrapper {
    margin-bottom: 12px;
  }

  @media (max-width: 1400px) {
    .name {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @media (max-width: 550px) {
    .name {
      .areaLeft {
        h3 {
          font-size: 23px;
        }
      }
    }
  }
`;

export const ContainerConsultoria = styled.div`
  min-width: 100%;
  .areaTop {
    width: 100%;
    display: flex;
    gap: 30px;

    .video {
      min-width: 50%;
      height: 248px;
      background-size: cover;
      background-position: center;
      background-color: var(--mid-grey);
      border-radius: 10px;
      cursor: pointer;
      position: relative;

      .hover {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition: all 0.3s;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          transform: scale(0);
          transition: all 0.3s;
        }

        &:hover {
          opacity: 1;

          svg {
            transform: scale(1);
          }
        }
      }
    }

    .areaLeft {
      align-items: center;
      gap: 10px;
      margin-bottom: 12px;
      width: 100%;
      justify-content: space-between;

      .name {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 12px;
        width: 100%;

        h3 {
          font-size: 28px;
          font-weight: 700;
          color: var(--paleta2C);
        }
      }

      .areaLeft {
        display: flex;
        align-items: center;
        gap: 10px;

        h3 {
          font-size: 28px;
          font-weight: 700;
          color: var(--paleta2C);
          line-height: 42px;
        }
      }

      .inscritos {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    .descricao {
      font-size: 13px;
      font-weight: 400;
      line-height: 14px;
      color: #7d7d7d;
      margin-bottom: 16px;
      max-height: 198px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .areaBottom {
    margin-top: 12px;
    p {
      font-size: 28px;
      line-height: 42px;
      font-weight: 700;
      color: #6d6d6d;
    }
    span {
      font-size: 13px;
      line-height: 14px;
      font-weight: 400;
      color: var(--texto);
    }
    .values {
      margin-top: 10px;
      display: flex;
      gap: 10px;
    }
  }

  @media (max-width: 1050px) {
    min-width: 100%;
    .areaTop {
      flex-direction: column;
    }
  }
`;

export const ConviteConsultor = styled.div`
  padding: 10px;
  background-color: var(--background2);
  border-radius: 10px;
  display: flex;
  gap: 20px;
  align-items: center;
  .areaLeftConsultorConvite {
    .imageProfileConsultor {
      width: 116px;
      height: 116px;
      border-radius: 50%;
      border: 3px solid var(--branco);
      @media (max-width: 800px) {
        width: 80px;
        height: 80px;
      }
    }
  }
  .areaRightConsultorConvite {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .nameConsultor {
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      color: var(--paleta2E);
    }
    .descriptionConsultor {
      font-size: 13px;
      line-height: 18px;
      font-weight: 400;
      color: var(--texto);
      max-height: 90px;
      overflow-y: scroll;
      text-align: justify;
      padding-right: 5px;
    }
  }
`;

export const AreaMessageConsultor = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .titleAreaMessageConsultor {
    font-size: 13px;
    line-height: 15px;
    font-weight: 700;
    color: var(--paleta2C);
  }
  .input {
    padding: 10px;
    border: none;
    width: 100%;
    max-width: 758px;
    background-color: var(--background2);
    border-radius: 10px;
    height: 150px;
    display: flex;
    outline: none;
    resize: none;
  }
  .areaButtonsConsultor {
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: flex-end;
  }
`;
