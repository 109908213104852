import styled, { css } from 'styled-components';

interface IProps {
  posicao?: any;
  lenght: number;
  loading: boolean;
  playlist?: boolean;
}

export const Container = styled.div<IProps>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));

  ${(props) =>
    props.posicao === 'lateral' &&
    css`
      grid-template-columns: 1fr;
    `}

  width: 100%;

  grid-gap: ${(props) => (props.posicao === 'lateral' ? '10px' : '31px')};

  ${(props) =>
    props.playlist &&
    css`
      width: 100%;
    `}

  .divLoading {
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  .empty-posts {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 300px;
    }
  }
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
  }
`;
