import styled from 'styled-components';

export const Container = styled.div`
  .toast-error {
    background: var(--purple2);
    position: absolute;
  }
  .generic {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;

    .content {
      max-width: calc(100% - 350px);
      min-width: 200px;

      .areaBanner {
        display: none;
        margin-bottom: 15px;
        .bannerTopMobile {
          cursor: pointer;
          display: none;
          width: 100%;
          border-radius: 10px;
          background-color: var(--background2);
        }
      }

      .minutesArea {
        display: flex;
        gap: 10px;
        overflow-y: hidden;
        min-width: 100%;
        overflow-x: scroll;
        ::-webkit-scrollbar {
          display: none;
        }
      }

      .calendar {
        max-width: 758px;

        max-height: 330px;
        margin-top: 10px;
        display: flex;
        flex-direction: row;

        .selectHour {
          display: flex;
          max-width: 200px;
          padding-right: 5px;
          border: 2px solid var(--background2);
          border-radius: 0 10px 10px 0;
        }

        .react-calendar {
          background-color: var(--background2);
          min-width: 70%;
          border-radius: 10px 0 0 10px;
          border: none;
          padding: 15px;
          padding-top: 0px;
          text-decoration: none;

          .react-calendar__navigation__label__labelText {
            color: var(--paleta2A);
            font-size: 18px;
            line-height: 27px;
            font-weight: 600;
          }

          .react-calendar__navigation {
            display: flex;
            align-items: center;
            padding-left: 28px;
            padding-right: 28px;
            padding-top: 18px;
            height: 62px;

            .react-calendar__navigation__prev2-button,
            .react-calendar__navigation__next2-button {
              display: none;
            }

            .react-calendar__navigation__prev-button,
            .react-calendar__navigation__next-button {
              max-width: 38px;
              max-height: 38px;
              min-height: 38px;
              min-width: 38px;
              background-color: var(--branco);
              border-radius: 50%;

              font-size: 30px;
              line-height: 30px;
              color: var(--paleta2A);
            }
          }

          .react-calendar__month-view__weekdays__weekday {
            font-size: 13px;
            line-height: 15px;
            font-weight: 700;
            color: var(--paleta2A);
            text-decoration: none;
          }

          .react-calendar__tile {
            counter-reset: var(--paleta2C);
            border-radius: 10px;
          }

          .react-calendar__month-view__days__day--weekend {
            color: var(--paleta2C);
          }
          .react-calendar__month-view__days__day--neighboringMonth {
            font-size: 13px;
            line-height: 18px;
            font-weight: 400;
            color: var(--paleta1C);
          }
          .react-calendar__tile--now {
            background-color: var(--paleta2E);
          }
          .react-calendar__tile--active {
            background: var(--paleta2A);
            font-weight: 700;
            color: white;
          }
        }
      }

      .aviso {
        margin-top: 17px;
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: var(--texto);
        max-width: 758px;
      }

      .alert {
        margin-top: 17px;
        margin-bottom: 17px;
        padding: 20px 80px;
        background-color: var(--paleta1A);
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: var(--branco);
        text-align: center;
        min-height: 46px;
        border-radius: 23px;
        max-width: 758px;
      }

      .question {
        font-size: 13px;
        line-height: 15px;
        font-weight: 700;
        color: var(--texto2);
        padding: 10px;
      }

      .input {
        padding: 10px;
        border: none;
        width: 100%;
        max-width: 758px;
        background-color: #eeeeee;
        border-radius: 10px;
        height: 150px;
        display: flex;
        outline: none;
        resize: none;
      }
      .areaButton {
        margin-top: 10px;
        max-width: 758px;
        display: flex;
        justify-content: flex-end;
      }
      .Rigth {
        background-color: lightcyan;
        width: 314px;
        height: calc(100% + 60px);
        margin-top: -60px;
      }
    }
    .sideRigth {
      width: 300px;
      height: 200px;
      .areaBanner {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 10px;
        .bannerTop {
          cursor: pointer;
          width: 100%;
          height: 227px;
          border-radius: 10px;
          background-color: var(--background2);
        }
        .bannerDown {
          cursor: pointer;
          width: 100%;
          height: 673px;
          background-color: var(--background2);
          border-radius: 10px;
        }
      }
    }
    .canal {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      gap: 20px;

      .DescriptionConsultor {
        width: 100%;
        gap: 20px;

        display: flex;
        justify-content: space-between;

        .banner {
          min-width: 300px;
          max-width: 30%;
          height: 227px;
          background-size: cover;
          background-position: center;
          border-radius: 10px;
        }
      }

      .contentCanal {
        width: 100%;
      }
    }
  }

  @media (max-width: 1370px) {
    .generic {
      .sideRigth {
        display: none;
      }
      .content {
        min-width: 100%;
        .areaBanner {
          display: flex;
          min-width: 100%;
          .bannerTopMobile {
            display: unset;
          }
        }
      }
    }
  }

  @media (max-width: 800px) {
    .generic {
      .content {
        min-width: 100%;
        .calendar {
          flex-direction: column;
          .react-calendar {
            min-width: 100%;
            max-height: 330px;
            border-radius: 10px;
          }
          .selectHour {
            max-width: 100%;
            min-width: 100%;
            border: none;
          }
        }
      }
    }
  }
`;

export const ContainerBox = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid #c4c4c4;
  padding: 16px;
  gap: 16px;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .user-info {
      display: flex;
      align-items: center;
      gap: 8px;
      img {
        width: 18px;
        height: 18px;
        border-radius: 100px;
      }
      .name {
        font-size: 16px;
        font-weight: 700;
        color: var(--purple1);
      }
    }
    .right {
      span {
        background: var(--purple7);
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
        color: var(--purple1);
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .date-info {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .description {
      background: #f3f3f3;
      border-radius: 8px;
      gap: 8px;
      display: flex;
      flex-direction: column;
      padding: 12px;
      .head-desc {
        display: flex;
        cursor: pointer;
        justify-content: space-between;
        align-items: center;
        svg {
          transform: rotate(270deg);
          transition: all 0.2s;
        }
      }
      .fields {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .field {
          .label {
            font-size: 14px;
            font-weight: 400;
            color: var(--purple1);
          }
          .input-fake {
            border-radius: 8px;
            border: 1px solid #8f8f8f;
            display: flex;
            padding: 10px;
            font-size: 14px;
            font-weight: 400;
            color: var(--purple1);
          }
        }
      }
      &.closed {
        .head-desc {
          svg {
            transform: rotate(90deg);
          }
        }
        .fields {
          display: none;
        }
        &:hover {
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    > button {
      flex: 1;
    }
  }
`;
