import axios from 'axios';

const token = localStorage.getItem('token');
const guestToken = localStorage.getItem('token-deslogado') as string;

var baseUrl;

if (process.env.NODE_ENV === 'development') {
  baseUrl = 'http://192.168.15.7:3340/';
  baseUrl = 'https://doubts.backendtropa.com.br/';
  baseUrl = 'https://47lt9v9b-3340.brs.devtunnels.ms/';
} else {
  baseUrl = 'https://doubts.backendtropa.com.br/';
}

const apiDoubts = axios.create({
  baseURL: baseUrl,
  headers: {
    'ngrok-skip-browser-warning': '69420',
    Authorization: token ? `Bearer ${token}` : '',
    tokendeslogado: !token ? guestToken : '',
  },
});

export default apiDoubts;
