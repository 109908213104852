import styled from "styled-components";

export const Container = styled.div`
display: flex;
align-items: center;
margin-left: 15px;

svg{
    margin-left: -20px;
}

button{
    border: none;
    background-color: transparent;
}

.perfil{
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    width: 55px;
    height: 55px;

    margin-left: -20px;

    border: 5px solid var(--background);
}
`