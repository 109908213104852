import React, { useState } from 'react';
import { Switch, BrowserRouter, Route } from 'react-router-dom';

import Agency from '../pages/Agencia';
import Consultores from '../pages/Consultores';
import EditarConsultor from '../pages/Videos/ListarConsultor';
import Videos from '../pages/Videos/Listar';
import EditVideo from '../pages/Videos/Editar';
import HomeConsultant from '../pages/HomeConsultant';
import Layout from '../components/Layout';
import UserLogin from '../pages/UserLogin';
import AgencyLogin from '../pages/AgencyLogin';
import CompanyLogin from '../pages/CompanyLogin';
import ConsultantLogin from '../pages/ConsultantLogin';
import Cadastrar from '../pages/Cadastrar';
import PrivateRoute from './PrivateRoute';
import Create from '../pages/Videos/CriarConteudo';
import Stream from '../pages/Videos/Stream';
import Consultant from '../pages/Consultor';
import ConsultorSettings from '../pages/ConsultorSettings';
import Visualizar from '../pages/Visualizar';
import VisualizarPlaylist from '../pages/Playlist/Visualizar';
import ConsultoriaGratis from '../pages/ConsultoriaGratis';

import Canal from '../pages/Canal';
import Playlist from '../pages/Playlist';
import Social from '../pages/Social';
import Consultoria from '../pages/Consultoria';
import VideosSearch from '../pages/VideosSearch';
import ListarPlaylists from '../pages/ConsultorPlaylist/Listar';
import EditarPlaylist from '../pages/Playlist/Editar';
import Favoritos from '../pages/Favoritos';
import Descubra from '../pages/Descubra';
import EmpresaCanal from '../pages/Empresa/Canal';
import EmpresaLive from '../pages/Empresa/Lives';
import EmpresaConsultoria from '../pages/Empresa/Consultoria';
import Vistos from '../pages/Vistos';
import AgenciaConfiguracoes from '../pages/EmpresaConfiguracoes';
import UserSettings from '../pages/Usuario/Config';
import EmpresaProdutos from '../pages/Empresa/Produtos';
import Oportunidades from '../pages/Oportunidades';
import VisualizarAgencia from '../pages/Agencia/VisualizarAgencia';
import AgenciaConfig from '../pages/Agencia/Config';
import Campanha from '../pages/Campanha';
import CampanhaCriar from '../pages/Campanha/Criar';
import CampanhaEditar from '../pages/Campanha/Editar';
import Historico from '../pages/Historico';
import LiveConsultoria from '../pages/Live/Consultoria';
import LiveConsultoriaGratis from '../pages/Live/ConsultoriaGratis';
import ValidateEmail from '../pages/validateEmail';
import Explorar from '../pages/Explorar/Listar';
import ConsultoriaPaga from '../pages/ConsultoriPaga';
import TermosUso from '../pages/Termos';
import ChangePassword from '../pages/ChangePassword';
import AgendaEmpresa from '../pages/Empresa/Agenda';
import EmpresaVisualizar from '../components/Empresa/Playlist';
import EmpresaPlaylistConfig from '../pages/Empresa/PlaylistConfig';
import Email from '../pages/Email/allEmails';
import EmailDetails from '../pages/Email/emailDetails';
import CampanhaDashboard from '../pages/Campanha/Dashboard';
import AgendaUsuario from '../pages/Agenda/usuario';
import AgendaConsultor from '../pages/Agenda/consultor';

const Routes: React.FC = (props) => {
  const [ref] = useState<any>(window?.location?.pathname);
  console.log(ref?.includes('empresa'));

  return (
    <BrowserRouter>
      <Switch>
        {/* rotas publicas */}
        <Route exact path="/login" component={UserLogin} />
        <Route exact path="/login-agencia" component={AgencyLogin} />
        <Route exact path="/login-empresa" component={CompanyLogin} />
        <Route exact path="/login-consultor" component={ConsultantLogin} />
        <Route exact path="/cadastrar" component={Cadastrar} />
        <Route exact path="/validacao" component={ValidateEmail} />
        <Route exact path="/recuperacaoSenha" component={ChangePassword} />

        <Layout>
          <Route exact path="/termos-de-uso" component={TermosUso} />
          {/* rotas privadas */}
          <Route exact path="/" component={Videos} />
          <Route exact path="/explorar/:explorar" component={Explorar} />
          <Route exact path="/search/:params" component={VideosSearch} />
          <Route exact path="/vistos" component={Vistos} />
          <Route exact path="/campanha" component={Campanha} />
          <Route exact path="/campanha/criar" component={CampanhaCriar} />
          <Route
            exact
            path="/campanha/editar/:campanhaId"
            component={CampanhaEditar}
          />
          <Route
            exact
            path="/campanha/dashboard/:id"
            component={CampanhaDashboard}
          />

          <Route exact path="/consultoria/:id" component={LiveConsultoria} />

          <PrivateRoute exact path="/consultoria-gratis/:id">
            <Route component={LiveConsultoriaGratis} />
          </PrivateRoute>

          <Route
            exact
            path="/consultor/visualizar/:id"
            component={Consultant}
          />
          <Route exact path="/historico" component={Historico} />
          <Route
            exact
            path="/consultor/visualizar/canal/:id"
            component={Canal}
          />
          <Route
            exact
            path="/consultor/visualizar/playlist/:id"
            component={Playlist}
          />
          <Route
            exact
            path="/consultor/visualizar/social/:id"
            component={Social}
          />
          <Route
            exact
            path="/consultor/visualizar/consultoria/:id"
            component={Consultoria}
          />
          <Route exact path="/consultor/listar" component={AgendaConsultor} />
          <Route exact path="/descubra" component={Descubra} />

          <Route
            exact
            path="/visualizar/playlist/:playlistId/:videoId"
            component={VisualizarPlaylist}
          />
          <Route exact path="/visualizar/:id" component={Visualizar} />

          <PrivateRoute exact path="/live">
            <Route component={Stream} />
          </PrivateRoute>

          <PrivateRoute exact path="/oportunidades">
            <Route component={Oportunidades} />
          </PrivateRoute>

          <PrivateRoute exact path="/email">
            <Route component={Email} />
          </PrivateRoute>
          <PrivateRoute exact path="/email/:id">
            <Route component={EmailDetails} />
          </PrivateRoute>

          <PrivateRoute exact path="/usuario">
            <Route component={HomeConsultant} />
          </PrivateRoute>

          <PrivateRoute exact path="/usuario/configuracoes">
            <Route component={UserSettings} />
          </PrivateRoute>

          <PrivateRoute exact path="/usuario/agenda">
            <Route component={AgendaUsuario} />
          </PrivateRoute>

          <PrivateRoute exact path="/consultor">
            <Route component={EditarConsultor} />
          </PrivateRoute>

          <PrivateRoute exact path="/consultor/configuracoes">
            <Route component={ConsultorSettings} />
          </PrivateRoute>

          <PrivateRoute exact path="/consultor/playlist">
            <Route component={ListarPlaylists} />
          </PrivateRoute>

          <PrivateRoute exact path="/favoritos">
            <Route component={Favoritos} />
          </PrivateRoute>

          <PrivateRoute exact path="/consultor/editar-playlist/:id">
            <Route component={EditarPlaylist} />
          </PrivateRoute>

          <PrivateRoute exact path="/consultor/consultoria-gratis">
            <Route component={ConsultoriaGratis} />
          </PrivateRoute>

          <PrivateRoute exact path="/consultor/consultoria-paga">
            <Route component={ConsultoriaPaga} />
          </PrivateRoute>

          {/* <PrivateRoute exact path="/admin">
            <Route component={HomeAdmin} />
          </PrivateRoute> */}

          <PrivateRoute exact path="/videos/editar/:id">
            <Route component={EditVideo} />
          </PrivateRoute>

          <PrivateRoute exact path="/videos/criar">
            <Route component={Create} />
          </PrivateRoute>

          <PrivateRoute exact path="/consultores">
            <Route component={Consultores} />
          </PrivateRoute>

          {/* ---------------------- EMPRESAS ------------------------- */}

          <PrivateRoute exact path="/empresa">
            <Route component={Agency} />
          </PrivateRoute>

          <PrivateRoute exact path="/empresa/configuracoes">
            <Route component={AgenciaConfiguracoes} />
          </PrivateRoute>

          <PrivateRoute exact path="/empresa/agenda">
            <Route component={AgendaEmpresa} />
          </PrivateRoute>

          <PrivateRoute exact path="/empresa/playlist">
            <Route component={EmpresaPlaylistConfig} />
          </PrivateRoute>

          <Route
            exact
            path="/empresa/visualizar/canal/:id"
            component={EmpresaCanal}
          />
          <Route
            exact
            path="/empresa/visualizar/playlist/:id"
            component={EmpresaVisualizar}
          />
          <Route
            exact
            path="/empresa/visualizar/lives/:id"
            component={EmpresaLive}
          />
          <Route
            exact
            path="/empresa/visualizar/consultorias/:id"
            component={EmpresaConsultoria}
          />
          <Route
            exact
            path="/empresa/visualizar/produtos/:id"
            component={EmpresaProdutos}
          />

          {/* ------------------------- AGENCIAS ------------------------- */}

          <Route
            exact
            path="/agencia/visualizar/:id"
            component={VisualizarAgencia}
          />

          <Route
            exact
            path="/agencia/configuracoes"
            component={AgenciaConfig}
          />

          {/* ------------------------- DEFAULT PAGES ------------------------- */}

          {/* <Route component={NotFoundPage} /> */}
        </Layout>
      </Switch>
    </BrowserRouter>
  );
};
export default Routes;
