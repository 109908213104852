import { SidebarItems } from '../models/SidebarItems';

import HomeIcon from '../../assets/icons/homeIcon';
import VideoIcon from '../../assets/icons/videoIcon';
import SettingsIcon from '../../assets/icons/settingsIcon';
import {
  IconDescubra,
  IconFavoritos,
  IconOlho,
  IconConfigurações,
  IconHome,
  IconAgenda,
  IconPlaylist,
  IconVideo,
  IconEmail,
  IconEstrela,
  IconCampanha,
  FireIcon,
  MusicIcon,
  FilmIcon,
  GameIcon,
} from '../Icons';

export const menuFixo: SidebarItems[] = [
  {
    title: 'Home',
    path: '/',
    icon: <IconHome />,
  },
  {
    title: 'Favoritos',
    path: '/favoritos',
    icon: <IconFavoritos />,
  },
  {
    title: 'Vistos',
    path: '/vistos',
    icon: <IconOlho color="#4A427C" />,
  },
  {
    title: 'Explorar',
    path: '/explorar/alta',
  },
  {
    title: 'Em Alta',
    path: '/explorar/em-alta',
    icon: <FireIcon />,
  },
  {
    title: 'Músicas',
    path: '/explorar/64078ef87edc070268de325a',
    icon: <MusicIcon />,
  },
  {
    title: 'Filmes',
    path: '/explorar/640793e07edc070268de3ba2',
    icon: <FilmIcon />,
  },
  {
    title: 'Jogos',
    path: '/explorar/64078dc67edc070268de2f6a',
    icon: <GameIcon />,
  },
];

export const menuUsuario: SidebarItems[] = [
  {
    title: 'Agenda',
    path: '/usuario/agenda',
    icon: <IconAgenda />,
  },
  {
    title: 'Configurações',
    path: '/usuario/configuracoes',
    icon: <IconConfigurações />,
  },
  {
    title: 'mensagens',
    path: '/email',
    icon: <IconEmail size={20} color="#8A7BE8" />,
  },
  // {
  //   title: 'Reportar',
  //   path: '/premium',
  //   icon: <IconReportar />,
  // },
  // {
  //   title: 'Ajuda',
  //   path: '/premium',
  //   icon: <IconAjuda />,
  // },
];

export const menuConsultor: SidebarItems[] = [
  {
    title: 'Dashboard',
    path: '/usuario',
    icon: <HomeIcon />,
  },
  {
    title: 'Agenda',
    path: '/consultor/listar',
    icon: <IconAgenda color="#8A7BE8" />,
  },
  {
    title: 'mensagens',
    path: '/email',
    icon: <IconEmail size={20} color="#8A7BE8" />,
  },
  {
    title: 'Playlists',
    path: '/consultor/playlist',
    icon: <IconPlaylist size={25} />,
  },
  {
    title: 'Editar Vídeo',
    path: '/consultor',
    icon: <SettingsIcon />,
  },
  {
    title: 'Criar Conteúdo',
    path: '/videos/criar',
    icon: <VideoIcon />,
  },
  {
    title: 'Canal',
    path: '/consultor/configuracoes',
    icon: <SettingsIcon />,
  },
];

export const menuAdministrador: SidebarItems[] = [
  {
    title: 'Home',
    path: '/',
    icon: <HomeIcon />,
  },
  {
    title: 'Editar Vídeo',
    path: '/admin',
    icon: <VideoIcon />,
  },
  {
    title: 'mensagens',
    path: '/email',
    icon: <IconEmail size={20} color="#8A7BE8" />,
  },
];

export const singleMenu: SidebarItems[] = [
  // {
  //   title: 'Home',
  //   path: '/',
  //   icon: <HomeIcon />,
  // },
];

export const menuEmpresa: SidebarItems[] = [
  {
    title: 'Dashboard',
    path: '/usuario',
    icon: <HomeIcon />,
  },
  {
    title: 'Agenda',
    path: '/empresa/agenda',
    icon: <IconAgenda color="#8A7BE8" />,
  },
  {
    title: 'mensagens',
    path: '/email',
    icon: <IconEmail size={20} color="#8A7BE8" />,
  },
  {
    title: 'Campanhas',
    path: '/campanha',
    icon: <IconCampanha />,
  },
  {
    title: 'Oportunidades',
    path: '/oportunidades',
    icon: <IconEstrela />,
  },
  {
    title: 'Criar Conteúdo',
    path: '/videos/criar',
    icon: <IconVideo />,
  },
  {
    title: 'Editar Vídeo',
    path: '/consultor',
    icon: <SettingsIcon />,
  },
  {
    title: 'Playlists',
    path: '/empresa/playlist',
    icon: <IconPlaylist size={25} />,
  },
  {
    title: 'Configurações',
    path: '/empresa/configuracoes',
    icon: <IconConfigurações />,
  },
];

export const menuAgencia: SidebarItems[] = [
  {
    title: 'mensagens',
    path: '/email',
    icon: <IconEmail size={20} color="#8A7BE8" />,
  },
  {
    title: 'Campanhas',
    path: '/campanha',
    icon: <IconCampanha />,
  },
  {
    title: 'Oportunidades',
    path: '/oportunidades',
    icon: <IconEstrela />,
  },
  {
    title: 'Configurações',
    path: '/agencia/configuracoes',
    icon: <IconConfigurações />,
  },
];
