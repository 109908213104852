import styled from "styled-components"

export const DivCheckbox = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  .label {
    font-size: 90%;
    color: var(--purple2);
    margin-left: 10px;
  }
  .checkbox {
    display: flex;
    justify-content: left;
    cursor: pointer;
    gap: 10px;
    justify-content: center;
    align-items: center;
    .square {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      vertical-align: middle;
      display: inline-block;
      width: 18px;
      height: 18px;
      border: 1px solid var(--texto);
      background-color: var(--background2);
      border-radius: 3px;
      &:before {
        display: block;
        content: '✔';
        font-size: 80%;
        line-height: 18px;
        width: 18px;
        text-align: center;
        color: var(--background2);
        transition: 0.2s;
        opacity: 0;
      }
    }
    .text {
      display: inline-block;     
      vertical-align: middle;
      line-height: 14px;
      font-size: 11px;
      color: var(--background2);
      font-weight: 400;
    }
    &.active {
      .square {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--purple2);
        background-color: var(--green);
        &:before {
          opacity: 1;
          color: var(--white);
        }
      }
    }
  }

  @media (min-width: 600px) {
    .checkbox {
      &:hover {
        .square {
          &:before {
            opacity: 1;
          }
        }
      }
    }
  }
`;