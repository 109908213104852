/* eslint-disable no-lone-blocks */
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';

import { Container } from './styles';
import {
  Value,
  CalendarStatic,
  HoursStatic,
  IPropsHoursStaticData,
} from '../../components/Calendar';
import { formatarDataString } from '../../utils/format';
import ButttonDefault from '../../components/ButtonDefault';
import moment from 'moment';
import ModalAlert from '../../components/ModalAlert';
import { ContainerConsultoriaRequest } from '../../components/Consultoria/Containers';
import apiDoubts from '../../services/api';
import Checkbox from '../../components/Checkbox/Checkbox';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import UserContext from '../../contexts/UserContext';
import { encodeConsultoria } from '../Live/Consultoria';

export const COLOR_LIVRE = 'var(--paleta1C)';
export const COLOR_OCUPADO = 'var(--paleta2A)';
export const COLOR_SOLICITADO = 'var(--paleta1A)';

export default function AgendaConsultor() {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const [filterDate, setFilterDate] = useState<Date>(new Date());
  const refScroll = useRef(null);

  const [loadingEvents, setLoadingEvents] = useState(true);
  const [dataEvents, setDataEvents] = useState<IPropsHoursStaticData[]>([]);
  const [dataRequests, setDataRequests] = useState([]);
  const [showOnlyEvents, setShowOnlyEvents] = useState(false);

  const [loadingButtonModal, setLoadingButtonModal] = useState(false);
  const [showModalCancel, setShowModalCancel] = useState<any>({
    type: '',
    param: '',
    show: false,
  });

  const getEvents = useCallback(
    async (load = true) => {
      setLoadingEvents(load);

      const response = await apiDoubts.get(
        `consultoria-all-info?data=${moment(filterDate).format(
          'YYYY-MM-DD'
        )}&pagination=true&page=1&limit=500`
      );

      let events: IPropsHoursStaticData[] = [];

      response.data.data.forEach((item: any) => {
        //requisições
        if (
          item.status === 'ocupado' &&
          item.consultoria?.status === 'Pendente'
        ) {
          events.push({
            color: COLOR_SOLICITADO,
            hora: moment(item.data).format('HH:mm'),
            title:
              'Consultoria solicitada por ' +
              item.consultoria?.cadastro.nickName,
            reference: {
              type: 'solicitacao',
              param: moment(item.data).format('YYYY-MM-DD HH:mm'),
              ...item.consultoria,
            },
          });
        } else {
          events.push({
            color: item.status === 'livre' ? COLOR_LIVRE : COLOR_OCUPADO,
            hora: moment(item.data).format('HH:mm'),
            title:
              item.status === 'livre'
                ? 'Consultoria disponível'
                : 'Consultoria confirmada com ' +
                    item.consultoria?.cadastro.nickName ?? 'Nome indisponivel',
            reference: {
              type: item.status === 'livre' ? 'disponibilidade' : 'consultoria',
              param: moment(item.data).format('YYYY-MM-DD HH:mm'),
              ...item?.consultoria,
            },
          });
        }
      });

      setDataEvents([...events]);
      setLoadingEvents(false);
    },
    [filterDate]
  );

  useEffect(() => {
    getEvents();
  }, []);

  useEffect(() => {
    if (filterDate) {
      let interval = setInterval(() => {
        getEvents(false);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [filterDate, getEvents, loadingButtonModal]);

  const handleCancel = async ({
    param,
    cancelarConsultorias = false,
  }: {
    param: string;
    cancelarConsultorias: boolean;
  }) => {
    setLoadingButtonModal(true);
    await apiDoubts.delete('/deletar-horarios-consultoria', {
      data: {
        horarios: [param],
        cancelarConsultorias,
      },
    });
    setDataEvents([
      ...dataEvents.filter((obj) => obj.hora !== moment(param).format('HH:mm')),
    ]);
    setShowModalCancel({ show: false, type: '', param: '' });
    setLoadingButtonModal(false);
  };

  const handleRequest = async ({
    consultoriaId,
    aceitar,
    mensagem,
    tipo,
  }: {
    consultoriaId: string;
    aceitar: boolean;
    mensagem: string;
    tipo: 'gratis' | 'paga';
  }) => {
    setLoadingButtonModal(true);

    await apiDoubts.post('/consultoria-aceitarrecusar', {
      consultoriaId,
      aceitar,
      mensagem,
      tipo,
    });

    if (aceitar) {
      let consultoriaInfo = dataEvents.filter(
        (obj) => obj.reference.id === consultoriaId
      )[0];

      const response = await apiDoubts.post('/postagens', {
        titulo: consultoriaInfo.reference.assunto,
        descricao: consultoriaInfo.reference.comentario,
        data: moment(consultoriaInfo.reference.data).format(
          'DD-MM-YYYY HH:mm:ss'
        ),
        thumbnail:
          'https://netshow.me/wp-content/uploads/2023/02/O-que-e-live-e-como-fazer-transmissoes-ao-vivo-em-2023-1-1024x569.png',
        consultoriaId: consultoriaId,
        tipo: 'live',
      });
    }

    getEvents();
    setShowModalCancel({ show: false, type: '', param: '' });
    setLoadingButtonModal(false);
  };

  const handleAddHour = async (hour: any, tipo = 'grátis') => {
    try {
      setLoadingButtonModal(true);
      dataEvents.push({
        hora: hour,
        color: COLOR_LIVRE,
        title: 'Consultoria disponível',
        reference: {
          type: 'disponibilidade',
          param: `${moment(filterDate).format('YYYY-MM-DD')} ${hour}`,
        },
      });
      setDataEvents([...dataEvents]);

      const response = await apiDoubts.post('/consultoria-horarios', {
        horarios: [`${moment(filterDate).format('YYYY-MM-DD')} ${hour}:00`],
        tipo,
      });
      setLoadingButtonModal(false);
    } catch (err) {
      setLoadingButtonModal(false);
      toast.error('Erro ao disponibilizar o horario.');
      setDataEvents([...dataEvents.filter((obj) => obj.hora !== hour)]);
    }
  };

  return (
    <Container>
      <h1>Agenda</h1>
      <div className="row">
        <div className="sidebar">
          <CalendarStatic value={filterDate} onChange={setFilterDate} />

          {dataEvents.filter((obj) => obj.reference.type === 'solicitacao')
            .length > 0 && <h3>Solicitações pendentes</h3>}
          <div className="overflow-requests">
            {dataEvents
              .filter((obj) => obj.reference.type === 'solicitacao')
              .map((row) => (
                <ContainerConsultoriaRequest
                  onCancel={() => {
                    handleRequest({
                      consultoriaId: row.reference.id,
                      aceitar: false,
                      mensagem: row.reference.assunto,
                      tipo: row.reference.tipo,
                    });
                  }}
                  onConfirm={() =>
                    handleRequest({
                      consultoriaId: row.reference.id,
                      aceitar: true,
                      mensagem: row.reference.assunto,
                      tipo: row.reference.tipo,
                    })
                  }
                  profile={{
                    image: row.reference.cadastro.foto,
                    name: row.reference.cadastro.nickName,
                  }}
                  item={{
                    date: moment(row.reference.data).format('YYYY-MM-DD HH:mm'),
                    title: row.reference.assunto,
                    description: row.reference.comentario,
                    type: row.reference.tipo === 'pago' ? 'Paga' : 'Grátis',
                  }}
                />
              ))}
          </div>
        </div>
        <div className="content">
          <div className="head">
            <h3>{formatarDataString(filterDate)}</h3>
            <div className="buttons">
              <Checkbox
                onClick={() => setShowOnlyEvents(!showOnlyEvents)}
                value={showOnlyEvents}
                name="somente_eventos"
                placeholder="Ocultar horarios vagos"
              />
            </div>
          </div>

          <div id="overflow-hours" ref={refScroll}>
            <HoursStatic
              hiddenDisable={showOnlyEvents}
              loading={loadingEvents}
              refScroll={refScroll}
              onClickEvent={(e) => {
                setShowModalCancel({ ...e.reference, show: true });
              }}
              filterDate={filterDate}
              data={dataEvents}
              textNewEvent="Disponibilizar horario"
              onNewEvent={(hour) => handleAddHour(hour, 'grátis')}
            />
          </div>
        </div>
      </div>

      <ModalAlert
        id="cancelar-disponibilidade"
        loadingProsseguir={loadingButtonModal}
        onClose={() => {
          setShowModalCancel({
            type: 'disponibilidade',
            param: '',
            show: false,
          });
        }}
        openModal={
          showModalCancel.type === 'disponibilidade' && showModalCancel.show
        }
        prosseguir={() => {
          handleCancel(showModalCancel);
        }}
      >
        <p>Você tem certeza que deseja remover horario?</p>
        <p>
          <b>
            {formatarDataString(new Date(showModalCancel.param))}{' '}
            {moment(showModalCancel.param).format('HH:mm')}
          </b>
        </p>
      </ModalAlert>

      <ModalAlert
        id="conferir-solicitacao"
        hiddenFooter={true}
        hiddenIcon={true}
        prosseguir={() => {}}
        onClose={() => {
          setShowModalCancel({
            type: 'solicitacao',
            param: '',
            show: false,
          });
        }}
        openModal={
          showModalCancel.type === 'solicitacao' && showModalCancel.show
        }
      >
        {showModalCancel.disabled && (
          <p>Você não confirmou a consultoria a tempo.</p>
        )}
        {showModalCancel.type === 'solicitacao' && showModalCancel.show && (
          <ContainerConsultoriaRequest
            styles={{
              style: {
                border: 'none',
              },
            }}
            toggleOpened={true}
            onCancel={
              showModalCancel.disabled === false
                ? () => {
                    handleRequest({
                      consultoriaId: showModalCancel.id,
                      aceitar: false,
                      mensagem: showModalCancel.assunto,
                      tipo: showModalCancel.tipo,
                    });
                  }
                : undefined
            }
            onConfirm={
              showModalCancel.disabled === false
                ? () => {
                    handleRequest({
                      consultoriaId: showModalCancel.id,
                      aceitar: true,
                      mensagem: showModalCancel.assunto,
                      tipo: showModalCancel.tipo,
                    });
                  }
                : undefined
            }
            profile={{
              image: showModalCancel.cadastro.foto,
              name: showModalCancel.cadastro.nickName,
            }}
            item={{
              date: moment(showModalCancel.data).format('YYYY-MM-DD HH:mm'),
              title: showModalCancel.assunto,
              description: showModalCancel.comentario,
              type: showModalCancel.tipo === 'pago' ? 'Paga' : 'Grátis',
            }}
          />
        )}
        <a
          className="later-link"
          onClick={() =>
            setShowModalCancel({
              type: 'solicitacao',
              param: '',
              show: false,
            })
          }
        >
          {showModalCancel.disabled ? 'Fechar' : 'Decidir depois'}
        </a>
      </ModalAlert>

      <ModalAlert
        hiddenFooter={showModalCancel.disabled}
        id="cancelar-consultoria"
        prosseguir={() => {
          console.log(showModalCancel);
          handleCancel({
            param: showModalCancel.param + ':00',
            cancelarConsultorias: true,
          });
        }}
        onClose={() => {
          setShowModalCancel({
            type: 'consultoria',
            param: '',
            show: false,
          });
        }}
        openModal={
          showModalCancel.type === 'consultoria' && showModalCancel.show
        }
      >
        {!showModalCancel.disabled && (
          <p>
            Você tem certeza que deseja{' '}
            <b style={{ color: 'var(--toastify-color-progress-error)' }}>
              cancelar
            </b>{' '}
            a consultoria?
          </p>
        )}

        {showModalCancel.type === 'consultoria' && showModalCancel.show && (
          <ContainerConsultoriaRequest
            profile={{
              image: showModalCancel.cadastro.foto,
              name: showModalCancel.cadastro.nickName,
            }}
            item={{
              date: moment(showModalCancel.data).format('YYYY-MM-DD HH:mm'),
              title: showModalCancel.assunto,
              description: showModalCancel.comentario,
              type: showModalCancel.tipo === 'pago' ? 'Paga' : 'Grátis',
            }}
          />
        )}

        <a
          className="later-link"
          style={{
            color: 'var(--paleta2A)',
          }}
          onClick={() => {
            history.push(
              `/consultoria/${encodeConsultoria({
                consultoriaId: showModalCancel.id,
                start: moment(showModalCancel.data).format('YYYY-MM-DD HH:mm'),
                end: moment(showModalCancel.data)
                  .add(10, 'minutes')
                  .format('YYYY-MM-DD HH:mm'),
              })}`
            );
          }}
        >
          Ir para a consultoria
        </a>
        {showModalCancel.disabled && (
          <a
            className="later-link"
            onClick={() =>
              setShowModalCancel({
                type: 'consultoria',
                param: '',
                show: false,
              })
            }
          >
            Fechar
          </a>
        )}
      </ModalAlert>
    </Container>
  );
}
