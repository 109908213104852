import styled, { css } from 'styled-components';

interface Props {
  hover?: boolean;
}

export const Container = styled.div<Props>`
  width: 290px;
  .containerCard {
    .image {
      min-height: 200px;
      background-color: var(--background2);
      background-position: center;
      background-size: cover;
      border-radius: 10px;
      cursor: pointer;
      z-index: 1;
      position: relative;
      box-sizing: border-box;
      overflow-x: hidden;

      .hover {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 10px;
        transition: 0.3s;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
      }

      .info {
        position: absolute;
        width: 0px;
        height: 100%;
        background-color: var(--paleta2C);
        right: 0;
        transition: 0.3s;
        border-radius: 0 10px 10px 0;
        opacity: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        padding-bottom: 54px;
        z-index: 2;
        .areaVideo,
        .areaView {
          display: flex;
          flex-direction: column;
          align-items: center;
          .areaTop {
            display: flex;
            gap: 4px;
            align-items: center;
            .infos {
              color: var(--branco);
              font-size: 18px;
              font-weight: 600;
            }
          }
          span {
            font-size: 13px;
            font-weight: 400;
            color: var(--paleta2E);
          }
        }
        .play {
          position: absolute;
          width: 100%;
          height: 54px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: var(--paleta2E);
          bottom: 0;
          right: 0;
          span {
            font-size: 13px;
            font-weight: 400;
            color: var(--branco);
          }
        }
      }
    }
  }

  ${(props) =>
    props.hover &&
    css`
      .containerCard {
        .image {
          .info {
            width: 100px;
            opacity: 1;
          }

          .hover {
            opacity: 1;
          }
        }
      }
    `}

  .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: var(--paleta2C);
    display: flex;
    margin-top: 5px;
  }
  .description {
    font-size: 14px;
    font-weight: 500;
    color: var(--paleta2C);
    display: flex;
    margin-top: 5px;
  }

  @media (max-width: 800px) {
    .containerCard {
      .image {
        .info {
          width: 100px;
          opacity: 1;
        }

        .hover {
          opacity: 1;
        }
      }
    }
  }
`;
