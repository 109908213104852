import styled from "styled-components";

export const Container = styled.div`
width: 100%;
height: 104px;
border-radius: 10px;
background-color: var(--background2);
padding: 10px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

`