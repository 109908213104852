import styled, { css } from "styled-components";

interface AreaButtonProps {
    modalOpened: boolean
}

export const Container = styled.div<AreaButtonProps>`
  height: 370px;
  overflow: hidden;
  position: relative;
  .openModal {
    z-index: 1;
    cursor: pointer;
  }
`;

export const AreaButton = styled.div<AreaButtonProps>`
  margin-top: -350px;
  transition: all 0.4s;
  z-index: 0 !important;
  width: 98%;
  margin-left: 1%;
  background-color: var(--branco);
  border-radius: 0 0 10px 10px;
  -webkit-box-shadow: 0px 0px 11px -7px #000000;
  box-shadow: 0px 0px 11px -7px #000000;
  visibility: hidden;
  

  ${({ modalOpened }) =>
    modalOpened &&
    css`
    visibility: visible;        
      margin-top: 0px;
      padding-bottom: 5px;
      padding-top: 5px;
      transition: all 0.4s;
      z-index: 0 !important;
      width: 98%;
      background-color: var(--branco);
      border-radius: 0 0 10px 10px;
      -webkit-box-shadow: 0px 0px 11px -7px #000000;
      box-shadow: 0px 0px 11px -7px #000000;
    `}
`;