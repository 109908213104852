import styled, { css } from 'styled-components';

interface props {}

export const Container = styled.div<props>`
  width: 1280px;
  height: 720px;
  background: black;
  display: flex;
  border-radius: 8px;
  overflow: hidden;

  @media (max-width: 1920px) {
    width: 960px;
    height: 540px;
  }
  @media (max-width: 1400px) {
    width: 640px;
    height: 360px;
  }
`;
