import styled from 'styled-components';

export const Container = styled.div`
  .TextNossosConsultores {
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
    color: var(--paleta2C);
    margin-bottom: 10px;
  }
  .divConsultoresAgencia {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    .topConsultores {
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        transform: scale(1.01);
        transition: all 0.2s;
      }

      .TextArea {
        margin-left: 5px;
        display: flex;
        flex-direction: column;

        .moment {
          font-size: 13px;
          font-weight: 400;
          line-height: 14px;
          margin-top: 1.5px;
          color: #7d7d7d;
        }

        .nick {
          color: var(--paleta1B);
          font-size: 13px;
          font-weight: 700;
          line-height: 15px;
          margin-right: 2px;
        }
      }

      .btnArea {
        margin-left: 8px;
        display: flex;
        margin-top: 4px;

        a {
          text-decoration: none;
        }
      }
    }

    .cardEmpresas {
      height: 56px;
      width: 156px;
      border-radius: 10px;
      background-position: center;
      background-size: cover;
      background-color: var(--background2);
      cursor: pointer;
    }
  }
  .areaCasesDeSucesso {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 10px;
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  span {
    max-width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const Profile = styled.div`
  min-height: 50px;
  min-width: 50px;
  border-radius: 100%;
  background-position: center;
  background-size: cover;
  background-color: #e1e1e5;
`;
