import { createRef, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import UserContext from '../../contexts/UserContext';
import apiDoubts from '../../services/api';
import ButttonDefault from '../ButtonDefault';
import { IconLoading } from '../Icons';
import { Container } from './styles';

interface VProps {
  comment: (e: any) => any;
  videoId?: any;
  reload?: boolean;
  getComents?: () => void;
  consultorId?: string;
}

export default function ComentarAreaConsultoria({
  comment,
  consultorId,
  videoId,
  reload,
  getComents = () => {},
}: VProps) {
  const { user } = useContext<any>(UserContext);
  const textAreaRef = useRef<any>();
  const [loadComment, setLoadComment] = useState(false);
  const [focus, setFocus] = useState(false);

  const [DTO, setDTO] = useState<any>({
    comentario: '',
  } as any);

  function handleOnChange(name: any, value: any) {
    const newDTO: any = DTO;
    newDTO[name] = value;
    setDTO({ ...newDTO });
  }

  async function handleSubmitComment(e: any) {
    setLoadComment(true);
    try {
      e.preventDefault();
      if (!DTO?.comentario?.trim())
        throw new Error('Por favor, digite um comentário');

      await comment(DTO.comentario);
      handleOnChange('comentario', '');
      setLoadComment(false);
    } catch (err: any) {
      setLoadComment(false);
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  function focusTextArea() {
    textAreaRef.current.focus();
  }

  return (
    <Container
      className="box-comment"
      focus={focus}
      user={user}
      value={!!DTO.comentario}
    >
      <h2>Comente</h2>
      <div className="areaComment">
        <div
          className="image"
          style={{ backgroundImage: `url(${user.foto})` }}
        />
        <div className="areaLeft">
          <h3>{user?.nickName}</h3>
          <textarea
            placeholder="Digite aqui"
            ref={textAreaRef}
            onFocus={() => {
              setFocus(true);
            }}
            onBlur={() => {
              setFocus(false);
            }}
            value={DTO.comentario}
            className="comment"
            onChange={(e) => {
              handleOnChange('comentario', e.target.value);
            }}
          ></textarea>

          <div className="load" onClick={focusTextArea}>
            <h4 className="fakePlaceholder">
              {loadComment && <IconLoading size="20" />}
              {!loadComment &&
                !focus &&
                DTO.comentario.length === 0 &&
                DTO.comentario.length === 0 &&
                'comentar...'}
            </h4>
          </div>

          <div className="areaSubmit">
            <ButttonDefault
              icon={loadComment ? 'Loading' : ''}
              title="Enviar"
              tipo="6"
              onClick={handleSubmitComment}
            />
          </div>
        </div>
      </div>
    </Container>
  );
}
