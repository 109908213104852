import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 30px;
  overflow: hidden;
  max-width: 1920px;
  margin: 0px auto;

  .load {
    width: calc(100% - 231px);
    margin-top: 100px;
    display: flex;
    position: fixed;
    justify-content: center;
  }

  .leftArea {
    width: 1280px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .comentarios {
      width: 100%;
    }
    @media (max-width: 1920px) {
      width: 960px;
    }
    @media (max-width: 1400px) {
      width: 640px;
    }
  }

  .rigthArea {
    flex: 1;
    height: 100%;
    gap: 20px;
    display: flex;
    flex-direction: column;

    .bannerArea {
      width: 100%;
    }

    .videosArea {
      min-width: 100%;
      max-width: 274px;
    }
  }
  @media (max-width: 1150px) {
    display: flex;
    flex-direction: column;
    .leftArea {
      width: 100%;
    }
    .rigthArea {
      .bannerArea {
        display: none;
      }
    }
  }
`;
