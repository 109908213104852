// React
import { useEffect, useState } from 'react';

// Styles
import {
  Container,
  Upload,
  Separator,
  Analytics,
  DailyViews,
  Videos,
} from './styles';

// Services
import apiDoubts from '../../services/api';

// Libraries
import moment from 'moment';
import 'moment/locale/pt-br';

import { Link, useHistory } from 'react-router-dom';

// Contexts
import { useUser } from '../../contexts/UserContext';

// Types
import * as T from './types';

// Components
import ChartDashboard from '../../components/ChartDashboard';
import SelectDefault from '../../components/Form/SelectDefault';

// Utils
import { getDaysInRange } from '../../utils/getDaysInRange';
import { calculatePercentageInAnObject } from '../../utils/calculatePercentageInAnObject';

//Images
import IconPlay from '../../assets/icons/IconPlay';
import Loading from '../../assets/icons/Loading';
import Skeleton from '../../components/Skeleton';
import { AxiosResponse } from 'axios';
import { getMonthInRange } from '../../utils/getMonthInRange';
import { subtractDays } from '../../utils/subtractDays';

interface PropsPage {
  page: ChildNode;
}

const HomeConsultant: React.FC<PropsPage> = (page) => {
  const { user } = useUser();

  const [filteredVideos, setFilteredVideos] = useState<T.IFilteredVideos>();
  const [dashboardAreas, setDashboardAreas] = useState<any>({});
  const [analytics, setAnalytics] = useState<T.IAnalytics>();
  const [fullStatistic, setFullStatistic] = useState<T.IFullStatistics>();
  const [chartStatistics, setChartStatistics] = useState<T.IChartStatistics[]>(
    []
  );
  const [filterDays, setFilterDays] = useState<number>(7);
  const [isLoadingChart, setIsLoadingChart] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    getFilteredVideos();

    const initialData = subtractDays(7)?.map((day) => {
      return {
        dia: day,
        likes: 0,
        views: 0,
      };
    });

    setChartStatistics(initialData);

    getStatistics(user?.tipo, user?._id)?.then((fullStatistics) => {
      const filterRange = filterDays <= 30 ? 'days' : 'months';

      handleAnalytics(fullStatistics);
      setFullStatistic(fullStatistics);
      handleFilterChartStatistics(filterDays, filterRange, fullStatistics);
    });
  }, []);

  async function getFilteredVideos() {
    const viewsVideos = await getVideos('views');
    const dataVideos = await getVideos('data');

    if (!viewsVideos || !dataVideos) return;

    setFilteredVideos({ data: dataVideos, views: viewsVideos });
  }

  async function getVideos(filter: string) {
    try {
      const endpointType =
        user?.tipo === 'consultor'
          ? 'getPostagemConsultor'
          : 'getPostagemEmpresa';

      const response: AxiosResponse = await apiDoubts.get(
        `/postagens/${endpointType}/${user?._id}?pagination=true&page=1&limit=9999&${filter}=max`
      );

      let data: any;

      if (user?.tipo === 'consultor') {
        data = response?.data?.data;
      } else {
        data = response?.data?.data?.docs;
      }

      return data;
    } catch (err) {
      console.error(err);
    }
  }

  async function getVideoById(id: number | string) {
    const response = await apiDoubts?.get(
      `/postagens/getPostagem-logado/${id}`
    );

    return response?.data?.data;
  }

  async function getStatistics(type: string, id: number | string) {
    const viewsUrl = `/${type}/dashboard/visualizacoes/${id}`;
    const likesUrl = `/${type}/dashboard/curtida/${id}`;

    const likesStatistics = await Promise.all([
      apiDoubts?.get(likesUrl),
      apiDoubts?.get(`${likesUrl}?mes=true`),
    ]).then(([daily, monthly]) => [daily?.data?.data, monthly?.data?.data]);

    const viewsStatistics = await Promise.all([
      apiDoubts?.get(viewsUrl),
      apiDoubts?.get(`${viewsUrl}?mes=true`),
    ]).then(([daily, monthly]) => [daily?.data?.data, monthly?.data?.data]);

    return {
      likesStatistics: likesStatistics,
      viewsStatistics: viewsStatistics,
    };
  }

  async function getPostStatitics(id: number | string) {
    setIsLoadingChart(true);
    const rangeType = filterDays <= 30 ? 'days' : 'months';
    const daysCount = filterDays <= 30 ? filterDays : filterDays / 30;

    const video = await getVideoById(id);

    getStatistics('postagens', id).then((statistics) => {
      setFullStatistic({ ...statistics, title: video?.titulo });
      handleFilterChartStatistics(Math.ceil(daysCount), rangeType, statistics);
      setIsLoadingChart(false);
    });
  }

  function handleFilterChartStatistics(
    range: number,
    rangeType: 'months' | 'days',
    fullStatistics: T.IFullStatistics
  ) {
    const formatType = rangeType === 'months' ? 'MM-YYYY' : 'DD-MM-YYYY';
    const initialDate = moment()
      ?.subtract(range - 1, rangeType)
      ?.format(formatType);
    const endDate = moment()?.format(formatType);

    const rangeDate =
      rangeType === 'days'
        ? getDaysInRange(initialDate, endDate)
        : getMonthInRange(initialDate, endDate);

    handleConsultantStatistics({
      fullStatistics: fullStatistics,
      rangeType: rangeType,
      range: rangeDate,
    });
  }

  function handleAnalytics(statistics: T.IFullStatistics) {
    const { visualizacoesDiarias } = statistics?.viewsStatistics[0];
    const { curtidasDiarias } = statistics?.likesStatistics[0];

    const percentageViews = calculatePercentageInAnObject(visualizacoesDiarias);
    const percentageLikes = calculatePercentageInAnObject(curtidasDiarias);

    setAnalytics({
      likes: Math.round(percentageLikes),
      views: Math.round(percentageViews),
    });
  }

  function handleOnGetAllVideos() {
    setIsLoadingChart(true);
    getStatistics(user?.tipo, user?._id)?.then((fullStatistics) => {
      const valueInt = filterDays <= 30 ? filterDays : filterDays / 30;
      const filterRange = filterDays <= 30 ? 'days' : 'months';

      setFullStatistic(fullStatistics);
      handleFilterChartStatistics(valueInt, filterRange, fullStatistics);
      setIsLoadingChart(false);
    });
  }

  function handleConsultantStatistics(filterObject: T.IFilter) {
    const { likesStatistics, viewsStatistics } = filterObject?.fullStatistics;

    let chartData: T.IChartStatistics[] = [];

    const dailyViews = viewsStatistics[0]?.visualizacoesDiarias || [];
    const dailyLikes = likesStatistics[0]?.curtidasDiarias || [];

    const monthlyViews = viewsStatistics[1]?.visualizacoesDiarias || [];
    const monthlyLikes = likesStatistics[1]?.curtidasDiarias || [];

    filterObject?.range?.forEach((day: string) => {
      const chartStatistic = {
        dia: day,
        likes:
          filterObject?.rangeType === 'days'
            ? dailyLikes[day] ?? 0
            : monthlyLikes[day] ?? 0,
        views:
          filterObject?.rangeType === 'days'
            ? dailyViews[day] ?? 0
            : monthlyViews[day] ?? 0,
      };

      chartData?.push(chartStatistic);
    });

    setChartStatistics(chartData);
  }

  function handleOnChangeFilter(e: React.ChangeEvent<HTMLSelectElement>) {
    const valueInt = parseInt(e?.target?.value);
    const range = valueInt <= 30 ? valueInt : valueInt / 30;
    const rangeType = valueInt <= 30 ? 'days' : 'months';

    console.log('VALOR INTEIRO', valueInt);
    console.log('RANGE', range);

    setFilterDays(valueInt);
    handleFilterChartStatistics(
      Math.ceil(range),
      rangeType,
      fullStatistic as T.IFullStatistics
    );
  }

  function handleCompareObjectsByKey(arrayOfObjects: any[], key: string) {
    return arrayOfObjects.sort((a, b) => b[key] - a[key]);
  }

  function higligthDashboardArea(areaKey: string) {
    const actualDashboardAreas: { [key: string]: boolean } = {
      likes: dashboardAreas?.likes as boolean,
      views: dashboardAreas?.views as boolean,
    };

    for (const key in actualDashboardAreas) {
      actualDashboardAreas[key] = false;

      if (key === areaKey) actualDashboardAreas[areaKey] = true;
    }

    setDashboardAreas(actualDashboardAreas);
  }

  function resetDashboardAreas() {
    const copyDashboardAreas = { ...dashboardAreas };

    for (const key in copyDashboardAreas) {
      copyDashboardAreas[key] = true;
    }

    setDashboardAreas(copyDashboardAreas);
  }

  function redirect(redirectPath: string) {
    history?.push(redirectPath);
  }

  function checkWithUserWasView(data: { [key: string]: any }[]): boolean {
    for (let [key, value] of Object.entries(data)) {
      if (value.like || value.days) {
        return true;
      }

      console.log(key, value);
    }
    return false;
  }

  const totalViews =
    fullStatistic?.viewsStatistics[0]?.totalVisualizacoes?.toLocaleString(
      undefined,
      { useGrouping: true }
    );

  const totalLikes =
    fullStatistic?.likesStatistics[0]?.totalCurtidas?.toLocaleString(
      undefined,
      { useGrouping: true }
    );

  const totalFollowersBussiness = () => {
    if (!user) return;

    const userBussiness = user as any;

    const agenciesFollowers = userBussiness['seguidores_agencias']?.length;
    const usersFollowers = userBussiness['seguidores_cadastros']?.length;
    const consultantFollowers = userBussiness['seguidores_consultores']?.length;
    const bussinessFollowers = userBussiness['seguidores_empresas']?.length;

    return (
      agenciesFollowers +
      usersFollowers +
      consultantFollowers +
      bussinessFollowers
    );
  };

  const chartKeys = [
    {
      label: 'Visualizações',
      dataKey: 'views',
    },
    {
      label: 'Curtidas',
      dataKey: 'likes',
    },
  ];

  return (
    <>
      <Container>
        <DailyViews>
          <header>
            <h2>
              Estatísticas {filterDays <= 30 ? 'Diárias' : 'Mensais'} -{' '}
              {fullStatistic?.title ?? user?.nickName}
            </h2>
            <Link to="#" style={{ display: 'none' }}>
              Gerar Relatório
            </Link>
            <SelectDefault onChange={handleOnChangeFilter} value={filterDays}>
              <option value="7">Última semana</option>
              <option value="14">Últimos 14 dias</option>
              <option value="30">Último mês</option>
              <option value="180">Últimos 6 meses</option>
            </SelectDefault>
          </header>

          <div className="legendContainer">
            <p
              className="legend"
              onMouseEnter={() => higligthDashboardArea('views')}
              onMouseLeave={resetDashboardAreas}
              title={`Total de Visualizações: ${totalViews}`}
            >
              <span className="line blue"></span>Visualizações
            </p>
            <p
              className="legend"
              onMouseEnter={() => higligthDashboardArea('likes')}
              onMouseLeave={resetDashboardAreas}
              title={`Total de Curtidas: ${totalLikes}`}
            >
              <span className="line purple"></span>Curtidas
            </p>
          </div>

          <main>
            <ChartDashboard
              chartData={{
                data: chartStatistics,
                keys: chartKeys,
              }}
              isLoading={isLoadingChart}
              likes={dashboardAreas?.likes}
              views={dashboardAreas?.views}
            />
          </main>

          {isLoadingChart && (
            <div className="loading" data-loading="Carregando. . ." />
          )}
        </DailyViews>

        <div className="multiplesColumns">
          <Upload>
            <header>
              <div className="cardVideo">
                <h2>Ultimo Upload</h2>
                <Link to="/consultor">
                  <span>Ver todos os videos</span>
                </Link>
              </div>
            </header>

            <main>
              <div className="videoContainer">
                {filteredVideos?.data?.[0]?.thumbnail ? (
                  <div className="videoWrapper">
                    <div
                      className="playButton"
                      onClick={() =>
                        redirect(`/visualizar/${filteredVideos?.data?.[0]?.id}`)
                      }
                    >
                      {filteredVideos?.data?.[0]?.thumbnail ? (
                        <IconPlay />
                      ) : (
                        <Loading color="var(--white)" size={3} />
                      )}
                    </div>
                    <img
                      src={filteredVideos?.data?.[0]?.thumbnail}
                      alt="Imagem"
                      className="videoThumbnail"
                    />
                  </div>
                ) : (
                  <div className="skeletonWrapper">
                    <Skeleton height={220} shape="round" />
                  </div>
                )}

                {filteredVideos?.data?.[0]?.titulo ? (
                  <span>{filteredVideos?.data?.[0]?.titulo}</span>
                ) : (
                  <Skeleton />
                )}
              </div>

              <Separator />

              <div className="Info">
                <table>
                  <tbody>
                    {filteredVideos?.data !== undefined ? (
                      <tr>
                        <td>Ranking por visualizações</td>
                        <td className="value">
                          {filteredVideos?.views?.findIndex(
                            (currentVideo) =>
                              filteredVideos?.data?.[0]?.id === currentVideo?.id
                          ) + 1}{' '}
                          de {filteredVideos?.data?.length ?? 0}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                    )}
                    {filteredVideos?.data?.[0]?.qtdVisualizacoes !==
                    undefined ? (
                      <tr onClick={() => {}} style={{ cursor: 'pointer' }}>
                        <td>Visualizações</td>
                        <td className="value">
                          {filteredVideos?.data?.[0]?.qtdVisualizacoes?.toLocaleString(
                            undefined,
                            { useGrouping: true }
                          ) ?? 0}{' '}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                    )}

                    {filteredVideos?.data?.[0]?.curtidas !== undefined ? (
                      <tr>
                        <td>Curtidas</td>
                        <td className="value">
                          {filteredVideos?.data?.[0]?.curtidas}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                    )}

                    {filteredVideos?.data?.[0]?.qtdFavoritos !== undefined ? (
                      <tr>
                        <td>Favoritos</td>
                        <td className="value">
                          {filteredVideos?.data?.[0]?.qtdFavoritos}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                    )}

                    {filteredVideos?.data?.[0]?.qtdComentarios !== undefined ? (
                      <tr>
                        <td>Comentários</td>
                        <td className="value">
                          {filteredVideos?.data?.[0]?.qtdComentarios}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </main>

            <Separator />

            <footer style={{ display: 'none' }}>
              <Link to="#" className="first">
                <span>Ver Analytics</span>
              </Link>
              <Link to="#">
                <span>Ver Comentarios</span>
              </Link>
            </footer>
          </Upload>

          <Analytics>
            <header>
              <h2>Analytics</h2>
            </header>

            <main>
              <div className="seguidores">
                <span>Seguidores</span>
                {user?.tipo === 'empresa' ? (
                  <strong>{totalFollowersBussiness()}</strong>
                ) : user?.tipo === 'consultor' ? (
                  <strong>{user?.seguidores?.length}</strong>
                ) : (
                  <Skeleton width={180} height={45} />
                )}

                {/* <p>
                  <span>+ {consultorInfo?.analytics?.seguidoresRecent}</span>{' '}
                  nos últimos 28 dias
                </p> */}
              </div>

              <div className="resume">
                <h2>
                  <span>Resumo</span> do último mês
                </h2>
                <table>
                  <tbody>
                    {analytics?.views ? (
                      <tr>
                        <td>Visualizações</td>
                        <td className="value">
                          {/* {consultorInfo?.consultor?.viewsVizualizacoes}&nbsp; */}
                          <div
                            className={
                              analytics?.views > -1 ? 'positive' : 'negative'
                            }
                          >
                            {analytics?.views > -1 && '+'}
                            {analytics?.views}%
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                    )}

                    {analytics?.likes ? (
                      <tr>
                        <td>Curtidas</td>
                        <td className="value">
                          <div
                            className={
                              analytics?.likes >= 0 ? 'positive' : 'negative'
                            }
                          >
                            {analytics?.likes >= 0 && '+'}
                            {analytics?.likes}%
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <Separator />

              <div className="topVideos">
                {' '}
                {/* Mostrar 3 vídeos mais vistos */}
                <h2>
                  <span>Top Vídeos</span> nas ultimas 24 horas
                </h2>
                <table>
                  <tbody>
                    {filteredVideos?.data &&
                      handleCompareObjectsByKey(
                        filteredVideos?.data,
                        'qtdVisualizacoesDiarias'
                      )
                        ?.slice(0, 3)
                        ?.map((video: T.ILastVideosData, key: number) => (
                          <tr key={key}>
                            <td className="title">{video?.titulo}</td>
                            <td className="value">
                              {video?.qtdVisualizacoesDiarias}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </main>

            <Separator />

            <footer style={{ display: 'none' }}>
              <Link to="#">Acessar Analytics Do canal</Link>
            </footer>
          </Analytics>

          <Videos>
            <header>
              <h2>Meus Vídeos</h2>

              <span className="showAllVideos" onClick={handleOnGetAllVideos}>
                Todos os vídeos
              </span>
            </header>

            <main>
              <table>
                <tbody>
                  {filteredVideos?.data &&
                    filteredVideos?.data?.map((video) => (
                      <tr
                        key={video?.id}
                        onClick={() => getPostStatitics(video?._id)}
                      >
                        <td>
                          <div className="videoWrapper">
                            <div
                              className="imageWrapper"
                              style={{
                                backgroundImage: `url("${video?.thumbnail}")`,
                              }}
                            ></div>
                            <p>{video?.titulo}</p>
                          </div>
                        </td>
                        <td className="value">
                          {video?.qtdVisualizacoes?.toLocaleString(undefined, {
                            useGrouping: true,
                          })}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </main>

            <Separator />
          </Videos>
        </div>
      </Container>
    </>
  );
};

export default HomeConsultant;
