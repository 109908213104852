import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import apiDoubts from '../../../services/api';
import ButttonDefault from '../../ButtonDefault';
import ButtonSeguir from '../../ButtonSeguir';
import {
  IconFacebook,
  IconInstagram,
  IconTikTok,
  IconTwitter,
  IconWWW,
} from '../../Icons';
import Inscritos from '../../Inscritos';
import MiniButton from '../MiniButton';
import { Container } from './styles';

interface props {
  page: 'canal' | 'playlist' | 'lives' | 'consultoria' | 'produtos';
  id: string;
}

export default function HeaderEmpresa({ page, id }: props) {
  const history = useHistory();
  const [data, setData] = useState<any>();
  const pagina = useMemo(() => getPerfilEmpresa(), [id]);

  async function getPerfilEmpresa() {
    if (id) {
      apiDoubts.get(`/empresa/get-by-id/${id}`).then((response: any) => {
        setData(response?.data?.data);
      });
    }
  }

  function handleNavigateMenuEmpresa(pageEmpresa: any) {
    if (page !== pageEmpresa) {
      history.push({
        pathname: `/empresa/visualizar/${pageEmpresa}/${id}`,
        state: { id: 'page', empresa: id },
      });
    }
  }

  useEffect(() => {
    console.log('chegou id na empresa', id);
  }, [id]);

  return (
    <Container>
      <div className="headerEmpresaCompleta">
        <div
          className="bannerHeaderEmpresaCompleta"
          style={{ backgroundImage: `url(${data?.capa})` }}
        >
          <div className="areaLogoEmpresa">
            <div className="areaLogoAndButtons">
              <div
                className="logoEmpresa"
                style={{
                  backgroundImage: `url(${data?.foto})`,
                }}
              />
            </div>
          </div>
          <div className="buttons">
            <div className="miniButtons">
              <MiniButton icon={<IconWWW />} path={data?.link_site} />
              <MiniButton icon={<IconTwitter />} path={data?.link_twitter} />
              <MiniButton icon={<IconFacebook />} path={data?.link_facebook} />
              <MiniButton
                icon={<IconInstagram />}
                path={data?.link_instagram}
              />
              <MiniButton icon={<IconTikTok />} path={data?.link_tiktok} />
            </div>
            <div className="textButtons">
              <ButttonDefault
                title={'Canal'}
                tipo={page === 'canal' ? '5' : '2'}
                hover={'medio'}
                onClick={() => {
                  if (page !== 'canal') {
                    console.log(id, 'canal');
                    history.push({
                      pathname: `/empresa/visualizar/canal/${id}`,
                    });
                  }
                }}
              />
              <ButttonDefault
                title={'Playlist'}
                tipo={page === 'playlist' ? '5' : '2'}
                hover={'medio'}
                onClick={() => {
                  if (page !== 'playlist') {
                    history.push({
                      pathname: `/empresa/visualizar/playlist/${id}`,
                    });
                  }
                }}
              />
              {/* <ButttonDefault
                title={'Lives'}
                tipo={page === 'lives' ? '5' : '2'}
                hover={'medio'}
                onClick={() => {
                  console.log('live');
                  history.push({
                    pathname: `/empresa/visualizar/lives/${id}`,
                    state: { id: 'lives', empresa: id },
                  });
                }}
              /> */}
              <ButttonDefault
                title={'Consultoria'}
                tipo={page === 'consultoria' ? '5' : '2'}
                hover={'medio'}
                onClick={() => {
                  if (page !== 'consultoria') {
                    history.push({
                      pathname: `/empresa/visualizar/consultorias/${id}`,
                    });
                  }
                }}
              />
              <ButttonDefault
                title={'Produtos e Serviços'}
                tipo={page === 'produtos' ? '5' : '2'}
                hover={'medio'}
                onClick={() => {
                  history.push({
                    pathname: `/empresa/visualizar/produtos/${id}`,
                    state: { id: 'produtos', empresa: id ? id : '' },
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="textHeaderEmpresa">
          <div className="top">
            <div className="left">
              <h2 className="nomeEmpresa">{data?.nickName}</h2>
              {data?._id !== id && <ButtonSeguir click={() => {}} />}
            </div>
            <Inscritos consultorId={data?.id} />
          </div>
          <p className="description">{data?.descricaoCanal}</p>
        </div>
      </div>
    </Container>
  );
}
