import styled from 'styled-components';

export const Container = styled.div`
    padding: 40px;
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(500px, 1fr) );
    /* flex-wrap: wrap;
    justify-content: center; */
    gap: 20px;
    h1 {
        color: var(--purple2);
    }
`

export const CardConsultor = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  background: var(--white);
  border: 1px solid var(--mid-grey);
  border-radius: 4px;

  padding: 25px 22px;
  
  @media(max-width: 950px) {
    width: 100%;
  }

  header {
    width: 100%;
    p {
      font-size: 16px;
      color: #7d7d7d;
      margin-top: 20px;
    }
    >div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        font-size: 20px;
        font-weight: 900;
        line-height: 23px;
        color: var(--purple2);
      }
      a {
        text-decoration: none;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        color: var(--purple6);
      }
    }
  }
  main {
    width: 100%;
    .CardVideo {
      margin-top: 23px;

      a {
        text-decoration: none;
      }

      img {
        width: 100%;
        height: 250px;
        margin: 0 auto;
        margin-bottom: 23px;
        border-radius: 8px;
      }

      p {
        font-size: 16px;
        line-height: 18px;
        color: var(--purple2);
      }
    }

    .Info {
      table {
        width: 100%;
      }
      tr {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        font-size: 12px;
        color: #7d7d7d;
      }
      td {
      }
      td.value {

        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: end;
        font-size: 12px;
        font-weight: 700;
        color: var(--purple2);
      }
      td div.positive {
        color: #0FBA53;
      }
      td div.negative {
        color: #DF2F2F;
      }
    }
  }

  footer {
    width: 100%;
    a {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      text-decoration: none;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--purple6);

      span + span {
        margin-top: 20px;
      }
    }
  }
`