import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import UserContext from '../../contexts/UserContext';
import { SidebarItems } from '../models/SidebarItems';
import { Container, Button } from './styles';
import apiDoubts from '../../services/api';

export interface Props {
  item: SidebarItems;
  channelName?: string;
  selected?: string;
  compact?: boolean;
}

const SubmenuButtons: React.FC<Props> = ({
  channelName,
  compact,
  selected,
  item,
}) => {
  const history = useHistory();
  const { sideBar, setSideBar } = useContext(UserContext);
  const location = useLocation();

  const [unreadMessages, setUnreadMessages] = useState<number>(0);

  useEffect(() => {
    getUnreadMessages();
    setInterval(() => {
      getUnreadMessages();
    }, 5_000);
  }, []);

  function handleOnRedirect(item: SidebarItems) {
    if (location?.pathname !== item?.path) {
      history.push(item.path, item.state);
      setSideBar(!sideBar);
    }
  }

  async function getUnreadMessages() {
    try {
      if (item.title !== 'mensagens') return;
      const unreadMessages = await apiDoubts.get(`/conversa/naoLidas?limit=1`);
      setUnreadMessages(unreadMessages?.data?.data?.qtdNaoLidas);
      // console.log(unreadMessages.data.data)
    } catch (error) {}
  }

  function formatUnreadMessages(qttUnreadMessage: number): string | null {
    if (!qttUnreadMessage || qttUnreadMessage === 0) return null;
    if (qttUnreadMessage > 100) {
      return '99+';
    } else return qttUnreadMessage.toString();
  }

  return (
    <Container onClick={() => handleOnRedirect(item)}>
      <Button
        hasCompact={compact as any}
        item={item}
        channelName={channelName}
        className={selected ? 'active' : ''}
      >
        <div>{item.icon}</div>
        <span>{item.title}</span>
        {item.title === 'mensagens' && unreadMessages > 0 && (
          <span className="emailAlert">
            <p>{formatUnreadMessages(unreadMessages)}</p>
          </span>
        )}
      </Button>
    </Container>
  );
};

export default SubmenuButtons;
