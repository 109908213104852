import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import UserContext from '../../../contexts/UserContext';
import apiDoubts from '../../../services/api';
import ButttonDefault from '../../ButtonDefault';
import InputFile from '../../ButtonFile';
import AddimageCirculo from '../../ButtonFile/AddImagemCirculo';
import EmptyProducts from '../../EmptyProducts';
import { IconTrash } from '../../Icons';
import Inputdefault from '../../Inputs/InputDefault';
import ModalDefault from '../../Modal';
import CardProdutosEmpresa from '../../ProdutosServico/CardEmpresa';
import WarningLogout from '../../WarningLogout';
import ModalAttProduto from '../ModalAttProduto';
import {
  BodyProdutosServiços,
  Container,
  HeaderProdutosServiços,
} from './styles';
import bucket from '../../../services/bucket';

export default function CadastrarProdutos() {
  const { user } = useContext(UserContext);

  const [modalEditProdutoServiço, setModalEditProdutoServico] =
    useState<boolean>(false);
  const [modalAddProdutoServiço, setModalAddProdutoServiço] =
    useState<boolean>(false);
  const [produtosServicos, setProdutosServicos] = useState<any[]>([]);
  const [DTO, setDTO] = useState<any>({});

  const [updateProduct, setUpdateProduct] = useState<boolean>(false);

  useEffect(() => {
    if (user.id) {
      getProdutosServicos();
    }
  }, [user.id]);

  function handleOnChange(name: any, value: any) {
    const newDTO: any = DTO;
    newDTO[name] = value;
    setDTO({ ...newDTO });
  }

  async function getProdutosServicos() {
    await apiDoubts
      .get(`/produtosservicos?idEmpresa=${user.id}`)
      .then((response: any) => {
        setProdutosServicos(response?.data?.data);
      });
  }

  async function removeProdutoServico(id: string) {
    try {
      await apiDoubts
        .delete(`/produtosservicos/${id}`)
        .then((response: any) => {
          getProdutosServicos();
          toast.success('Produto atualizado com sucesso', {
            className: 'toast-sucess',
            theme: 'colored',
          });
        });
    } catch (error: any) {
      setModalEditProdutoServico(false);
      toast.error(error.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  async function setImageProdutoServico(file: any) {
    let formData = new FormData();
    formData.append('file', file.target.files[0]);
    const response: any = await bucket.post(`/upload`, formData);
    handleOnChange('imagem', response.data.arquivo);
  }

  async function handleUpdateProduto(id: string) {
    setUpdateProduct(true);

    try {
      if (!DTO?.nome)
        throw new Error('Seu produto ou serviço precisa de um nome');
      if (!DTO?.descricao)
        throw new Error('Seu produto ou serviço precisa de uma descrição');
      if (!DTO?.imagem)
        throw new Error('Seu produto ou serviço precisa de uma imagem');
      if (!DTO?.link)
        throw new Error('Por favor, informe um link de redirecionamento');
      if (!DTO?.link)
        throw new Error(
          'Seu produto ou serviço precisa de uma chamada para ação'
        );

      await apiDoubts
        .put(`/produtosservicos/${id}`, {
          nome: DTO?.nome,
          descricao: DTO?.descricao,
          link: DTO?.link,
          imagem: DTO?.imagem,
          textBotao: DTO?.textBotao,
        })
        .then((response: any) => {
          getProdutosServicos();
          setModalEditProdutoServico(false);
          setUpdateProduct(false);
          toast.success('Produto atualizado com sucesso', {
            className: 'toast-sucess',
            theme: 'colored',
          });
        });
    } catch (error: any) {
      setUpdateProduct(false);
      setModalEditProdutoServico(false);
      toast.error(error.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  async function addNewProductService() {
    try {
      if (!DTO?.textBotao) {
        handleOnChange('textBotao', 'Compre Agora');
      }
      await apiDoubts.post(`/produtosservicos`, DTO).then((response: any) => {
        getProdutosServicos();
        setModalAddProdutoServiço(false);
        toast.success('Produto atualizado com sucesso', {
          className: 'toast-sucess',
          theme: 'colored',
        });
      });
    } catch (error: any) {
      setModalEditProdutoServico(false);
      toast.error(error.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  return (
    <Container foto={!!DTO.imagem}>
      <div className="modal">
        <ModalAttProduto
          onClose={() => {
            setModalEditProdutoServico(false);
          }}
          id="modalEditProdutorServico"
          openModal={modalEditProdutoServiço}
          title="Editar Produto ou Serviço"
          width={'540px'}
        >
          <div className="itemForm">
            <p className="label">Nome</p>
            <Inputdefault
              change={(e: any) => {
                handleOnChange('nome', e.target.value);
              }}
              placeholder="Ex: Mentoria investidor iniciante"
              tipo="text"
              value={DTO?.nome}
            />
          </div>
          <div className="itemForm">
            <p className="label">Descrição</p>
            <textarea
              placeholder="Ex: essa é uma playlist focada em receitas simples"
              value={DTO.descricao}
              className="comment"
              onChange={(e) => {
                handleOnChange('descricao', e.target.value);
              }}
            ></textarea>
          </div>
          <div className="itemForm">
            <p className="label">
              Foto<span className="tamanhoDaImagem"> (240x120px)</span>
            </p>
            <div
              className="imageProdutoServico"
              style={{ backgroundImage: `url(${DTO.imagem})` }}
            >
              <div className="button">
                {DTO.imagem ? (
                  <button
                    className="removeImage"
                    onClick={() => {
                      handleOnChange('imagem', '');
                    }}
                  >
                    <IconTrash />
                  </button>
                ) : (
                  <button className="addImage">
                    <AddimageCirculo
                      change={(e: any) => {
                        setImageProdutoServico(e);
                      }}
                      file={'Foto de capa'}
                      tipo={'file'}
                      placeholder={'foto de perfil'}
                    />
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="itemForm">
            <p className="label">Link de redirecionamento</p>
            <Inputdefault
              change={(e: any) => {
                handleOnChange('link', e.target.value);
              }}
              placeholder="Ex: Mentoria investidor iniciante"
              tipo="text"
              value={DTO?.link}
            />
          </div>
          <div className="itemForm">
            <p className="label">Texto do botão</p>
            <Inputdefault
              change={(e: any) => {
                handleOnChange('textBotao', e.target.value);
              }}
              placeholder="Ex: Mentoria investidor iniciante"
              tipo="text"
              value={DTO?.textBotao}
            />
          </div>
          <div className="areaButtons">
            <ButttonDefault title={'Cancelar'} tipo={'11'} onClick={() => {setModalEditProdutoServico(false)}} />
            <ButttonDefault
              icon={updateProduct ? 'Loading-White' : ''}
              title={'Salvar'}
              tipo={'6'}
              onClick={() => {
                handleUpdateProduto(DTO?.id);
              }}
            />
          </div>
        </ModalAttProduto>
      </div>

      <div className="modal">
        <ModalAttProduto
          onClose={() => {
            setModalAddProdutoServiço(false);
          }}
          id="modalAddProdutoServiço"
          openModal={modalAddProdutoServiço}
          title="Adicionar Produto ou Serviço"
          width={'540px'}
        >
          <div className="itemForm">
            <p className="label">Nome</p>
            <Inputdefault
              change={(e: any) => {
                handleOnChange('nome', e.target.value);
              }}
              placeholder="Ex: Mentoria investidor iniciante"
              tipo="text"
              value={DTO?.nome ? DTO?.nome : ''}
            />
          </div>
          <div className="itemForm">
            <p className="label">Descrição</p>
            <textarea
              placeholder="Ex: essa é uma playlist focada em receitas simples"
              value={DTO?.descricao ? DTO.descricao : ''}
              className="comment"
              onChange={(e) => {
                handleOnChange('descricao', e.target.value);
              }}
            ></textarea>
          </div>
          <div className="itemForm">
            <p className="label">
              Foto<span className="tamanhoDaImagem"> (240x120px)</span>
            </p>
            <div
              className="imageProdutoServico"
              style={{
                backgroundImage: `url(${DTO?.imagem ? DTO?.imagem : ''})`,
              }}
            >
              <div className="button">
                {DTO.imagem ? (
                  <button
                    className="removeImage"
                    onClick={() => {
                      handleOnChange('imagem', '');
                    }}
                  >
                    <IconTrash />
                  </button>
                ) : (
                  <button className="addImage">
                    <AddimageCirculo
                      change={(e: any) => {
                        setImageProdutoServico(e);
                      }}
                      file={'Foto de capa'}
                      tipo={'file'}
                      placeholder={'foto de perfil'}
                    />
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="itemForm">
            <p className="label">Link de redirecionamento</p>
            <Inputdefault
              change={(e: any) => {
                handleOnChange('link', e.target.value);
              }}
              placeholder="Ex: https://google.com"
              tipo="text"
              value={DTO?.link ? DTO?.link : ''}
            />
          </div>
          <div className="itemForm">
            <p className="label">Texto do botão</p>
            <Inputdefault
              change={(e: any) => {
                handleOnChange('textBotao', e.target.value);
              }}
              placeholder="Ex: Compre Agora"
              tipo="text"
              value={DTO?.textBotao ? DTO?.textBotao : 'Compre Agora'}
            />
          </div>
          <div className="areaButtons">
            <ButttonDefault
              title={'Cancelar'}
              tipo={'11'}
              onClick={() => {
                setModalAddProdutoServiço(false);
                setDTO({});
              }}
            />
            <ButttonDefault
              title={'Adiconar'}
              tipo={'6'}
              onClick={() => {
                addNewProductService();
              }}
            />
          </div>
        </ModalAttProduto>
      </div>

      <HeaderProdutosServiços className="HeaderProdutosServiços">
        <h2 className="titleProdutosServiços">Produtos e Serviços</h2>
        <ButttonDefault
          title={'Novo Produto ou Serviço'}
          tipo={'2'}
          onClick={() => {
            setDTO({});
            setModalAddProdutoServiço(true);
          }}
        />
      </HeaderProdutosServiços>
      <BodyProdutosServiços>
        {produtosServicos?.length === 0 && <EmptyProducts />}

        {produtosServicos.length > 0 &&
          produtosServicos.map((row: any, key: number) => (
            <CardProdutosEmpresa
              data={row}
              key={key}
              removeCard={(id: string) => {
                removeProdutoServico(id);
              }}
              editCard={(id: string) => {
                setModalEditProdutoServico(row);
                setDTO(row);
              }}
            />
          ))}
      </BodyProdutosServiços>
    </Container>
  );
}
