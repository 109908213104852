import moment from 'moment';

export function isAValidDate(data: string): boolean {
  const dateFormat = 'DD/MM/YYYY';

  // Verificar se a data é válida e está no formato correto
  if (!moment(data, dateFormat, true).isValid()) {
    return false;
  }

  // Obter a data atual
  const dataAtual = moment();

  // Definir a data limite com no máximo 100 anos de diferença para o ano atual
  const dataLimite = moment().subtract(100, 'years');

  // Converter a data informada para o formato Moment.js
  const dataInformada = moment(data, dateFormat);

  // Verificar se a data está dentro do intervalo permitido
  if (
    dataInformada.isSameOrBefore(dataAtual) &&
    dataInformada.isSameOrAfter(dataLimite)
  ) {
    return true;
  }

  return false;
}
