import styled from "styled-components";

export const Container = styled.div`
`

export const Content = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  .left {
    width: calc(100% - 227px);
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .title{
        font-size: 28px;
        line-height: 42px;
        font-weight: 700;
        color: var(--paleta2C)
    }
    .listProdutosServicos{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        grid-gap: 20px;
    }
  }
  .rigth {
    width: 227px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;