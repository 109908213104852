import styled, { css } from 'styled-components';

interface Iprops {
  hasCompact: boolean;
  item: any;
  channelName: any;
  className: any;
}

export const Container = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  text-decoration: none;
  color: var(--white);
  margin-bottom: 5px;

  z-index: 9;
`;

export const Button = styled.button<Iprops>`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: var(--background);
  padding-right: 22px;
  height: 40px;
  width: 100%;
  padding-left: 30px;
  background: none;
  border: none;
  border-radius: 0 10px 10px 0;
  margin-right: 30px;
  transition: background-color 0.39s;

  svg {
    path {
      fill: var(--paleta2E);
    }
  }
  * {
    transition: all 0.2s;
  }

  &:hover {
    background-color: var(--paleta2A);
    svg {
      path {
        fill: var(--paleta2E);
      }
    }
  }

  &.active {
    background-color: var(--paleta2A);
  }

  span {
    font-size: 14px;
    margin-left: 10px;
    color: var(--branco);
    font-weight: 400;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s cubic-bezier(0.4, 0, 1, 1);
    transition: all 0.3s linear;
  }

  ${(props) =>
    props.hasCompact &&
    css`
      height: 60px;
      width: 90%;
      display: flex;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      svg {
      }
      span {
        margin: 0px;
        margin-top: 5px;
        font-weight: 500;
        font-size: 11px;
      }
    `}

  .emailAlert {
    position: absolute;
    top: -5px;
    right: 0px;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: var(--warning);

    display: flex;
    align-items: center;
    justify-content: center;

    ${(props) =>
    props.hasCompact &&
    css`
      /* top: 2px; */
      /* right: 5px; */
    `}
    p{
      font-weight: 700
    }
  }
`;
