import Moment from 'react-moment';
import { Link, useHistory } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import { IconUser } from '../Icons';
import { Container, Profile } from './styles';
import moment from 'moment';

interface Iprops {
  hasCompact?: boolean;
}

export default function SideBarAreaUser({ hasCompact }: Iprops) {
  const { user }: any = useUser();
  const history = useHistory();

  function handleLogout() {
    window.localStorage.removeItem('token');
    window.location.href = '/';
  }

  return (
    <Container compact={hasCompact}>
      {!hasCompact ? (
        <>
          <Profile>
            {user?.id && (
              <>
                <span>Studio Doubts</span>
                <div className="userInfo">
                  {user?.foto && user?.foto?.includes('https://') && (
                    <div
                      className="userImg"
                      style={{ backgroundImage: `url(${user.foto})` }}
                    />
                  )}

                  {user.tipo === 'consultor' ? (
                    <Link to={`/consultor/visualizar/canal/${user.id}`}>
                      <h2>{user.nickName?.split(' ')[0]}</h2>
                    </Link>
                  ) : (
                    <h2>{user.nickName?.split(' ')[0]}</h2>
                  )}

                  <span>
                    {user.tipo === 'cadastro'
                      ? 'Usuário'
                      : user.tipo === 'consultor'
                      ? 'Consultor'
                      : user.tipo === 'admin'
                      ? 'Admnistrador'
                      : 'Não logado'}
                    &#40;a&#41; {moment(user.createdAt).fromNow()}
                  </span>
                </div>
              </>
            )}

            {user.id ? (
              <span className="logout" onClick={() => handleLogout()}>
                Sair
              </span>
            ) : (
              <span
                className="logout"
                onClick={() => {
                  history.push('/login');
                }}
              >
                Entrar
              </span>
            )}
          </Profile>
        </>
      ) : (
        <Profile compact={hasCompact}>
          {user.login ? (
            user.foto && user.foto.includes('https://') ? (
              <div
                className="userImg"
                style={{ backgroundImage: `url(${user.foto})` }}
              />
            ) : (
              <div className="userImg">
                <IconUser size="50" />
              </div>
            )
          ) : (
            <></>
          )}
        </Profile>
      )}
    </Container>
  );
}
