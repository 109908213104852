import styled from 'styled-components';

export const MainContainer = styled.div`
  width: calc(100% - 110px);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.2s;
  margin-left: 110px;
  padding: 15px;
  box-sizing: border-box;
  background-color: #fafaff;
  /* &.active {
    width: 100%;
  } */

  @media (max-width: 800px) {
    &.active {
      width: 100%;
      margin-left: 0;
    }
    width: 100%;
    margin-left: 0px;
  }
`;
