import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UserContext from '../../../contexts/UserContext';
import { IconConfigurações, IconPlay, IconVideo } from '../../Icons';
import { Container } from './styles';

interface CProps {
  data: any;
  consultor?: boolean;
}

export default function CardPlaylist({ data, consultor }: CProps) {
  const [hover, setHover] = useState(false);
  const [viewPlaylist, setViewPlaylist] = useState(0);
  const history = useHistory();
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (data) {
      console.log(data);
      handleView();
    }
  }, [data]);
  //rebase

  function handleView() {
    let sum = 0;
    data.postagens.map((row: any) => {
      sum = sum + row.qtdVisualizacoes;
    });
    setViewPlaylist(sum - 1);
  }

  function goToPlaylist() {
    if (consultor) {
      history.push(`/consultor/editar-playlist/${data._id}`);
    } else {
      history.push(`/visualizar/playlist/${data.id}/${data.listaPostagens[0]}`);
    }
  }

  return (
    <Container hover={hover}>
      {data && (
        <div className="containerCard" onClick={goToPlaylist}>
          <div
            className="image"
            style={{
              backgroundImage: `url(${data?.postagens[0].thumbnail})`,
            }}
          >
            <div
              className="info"
              onMouseOver={() => {
                setHover(true);
              }}
              onMouseOut={() => {
                setHover(false);
              }}
            >
              <div className="areaVideo">
                <div className="areaTop">
                  <IconVideo />
                  <span className="infos">{data?.listaPostagens.length}</span>
                </div>
                <span>
                  {data?.listaPostagens.length > 1 ? 'Videos' : 'Video'}
                </span>
              </div>
              <div className="areaView">
                <div className="areaTop">
                  <span className="infos">+{viewPlaylist}</span>
                </div>
                <span>Views</span>
              </div>
              <div className="play" onClick={goToPlaylist}>
                {user?.id === data?.consultorId ? (
                  <IconConfigurações color={'#fff'} />
                ) : (
                  <IconPlay />
                )}

                <span>
                  {user?.id === data?.consultorId ? 'Editar' : 'Assistir'}
                </span>
              </div>
            </div>

            <div
              className="hover"
              onMouseOver={() => {
                setHover(!hover);
              }}
              onMouseOut={() => {
                setHover(!hover);
              }}
            ></div>
          </div>
          <span className="title">{data?.titulo}</span>
          <span className="description">{data?.descricao}</span>
        </div>
      )}
    </Container>
  );
}
