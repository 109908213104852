import styled from 'styled-components';

export const ContainerCalendar = styled.div`
  .calendar {
    margin-bottom: 10px;
    .startAndEnd {
      display: flex;
      width: 100%;
      background-color: var(--background2);
      padding: 20px;
      border-radius: 10px 10px 0 0;
      justify-content: space-between;
      .dateField {
        flex: 1;
        color: var(--paleta2C);
        font-size: 16px;
        font-weight: 600;
      }
    }
    .content {
      min-height: 299px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .selectHour {
        max-height: 330px;
        display: flex;
        min-width: 200px;
        padding-right: 5px;
        border: 2px solid var(--background2);
        border-radius: 0 0 10px 0;
      }

      .react-calendar__tile--range {
        background-color: transparent;
      }

      .react-calendar {
        background-color: var(--background2);
        border-radius: 0 0 0 10px;
        width: 70%;
        border: none;
        padding: 15px;
        max-height: 330px;
        padding-top: 0px;
        text-decoration: none;        

        .react-calendar__navigation__label__labelText {
          color: var(--paleta2A);
          font-size: 18px;
          line-height: 27px;
          font-weight: 600;
        }

        .react-calendar__navigation {
          display: flex;
          align-items: center;
          padding-left: 28px;
          padding-right: 28px;
          padding-top: 18px;
          height: 62px;

          .react-calendar__navigation__prev2-button,
          .react-calendar__navigation__next2-button {
            display: none;
          }

          .react-calendar__navigation__prev-button,
          .react-calendar__navigation__next-button {
            max-width: 38px;
            max-height: 38px;
            min-height: 38px;
            min-width: 38px;
            background-color: var(--branco);
            border-radius: 50%;

            font-size: 30px;
            line-height: 30px;
            color: var(--paleta2A);
          }
        }

        .react-calendar__month-view__weekdays__weekday {
          font-size: 13px;
          line-height: 15px;
          font-weight: 700;
          color: var(--paleta2A);
          text-decoration: none;
        }

        .react-calendar__tile {
          counter-reset: var(--paleta2C);
        }

        .react-calendar__month-view__days__day--weekend {
          color: var(--paleta2C);
        }
        .react-calendar__month-view__days__day--neighboringMonth {
          font-size: 13px;
          line-height: 18px;
          font-weight: 400;
          color: var(--paleta1C);
        }
        .react-calendar__tile--now {
          background-color: transparent;
        }
        .react-calendar__tile--active {
          background: var(--paleta2A);
          opacity: 0.8;
          font-weight: 700;
          color: white;
        }
      }
    }
    .areaButton {
      width: 100%;
      margin-top: 15px;
      display: flex;
      gap: 10px;
      justify-content: flex-end;
    }
  }
`;
