import styled from 'styled-components';

export const Container = styled.div`
  .calendar {
    max-width: 758px;

    max-height: 330px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;

    .selectHour {
      display: flex;
      max-width: 200px;
      padding-right: 5px;
      border: 2px solid var(--background2);
      border-radius: 0 10px 10px 0;
    }

    .react-calendar {
      background-color: var(--background2);
      min-width: 70%;
      border-radius: 10px 0 0 10px;
      border: none;
      padding: 15px;
      padding-top: 0px;
      text-decoration: none;

      .react-calendar__navigation__label__labelText {
        color: var(--paleta2A);
        font-size: 18px;
        line-height: 27px;
        font-weight: 600;
      }

      .react-calendar__navigation {
        display: flex;
        align-items: center;
        padding-left: 28px;
        padding-right: 28px;
        padding-top: 18px;
        height: 62px;

        .react-calendar__navigation__prev2-button,
        .react-calendar__navigation__next2-button {
          display: none;
        }

        .react-calendar__navigation__prev-button,
        .react-calendar__navigation__next-button {
          max-width: 38px;
          max-height: 38px;
          min-height: 38px;
          min-width: 38px;
          background-color: var(--branco);
          border-radius: 50%;

          font-size: 30px;
          line-height: 30px;
          color: var(--paleta2A);
        }
      }

      .react-calendar__month-view__weekdays__weekday {
        font-size: 13px;
        line-height: 15px;
        font-weight: 700;
        color: var(--paleta2A);
        text-decoration: none;
      }

      .react-calendar__tile {
        counter-reset: var(--paleta2C);
        border-radius: 10px;
      }

      .react-calendar__month-view__days__day--weekend {
        color: var(--paleta2C);
      }
      .react-calendar__month-view__days__day--neighboringMonth {
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: var(--paleta1C);
      }
      .react-calendar__tile--now {
        background-color: transparent;
      }
      .react-calendar__tile--active {
        background: var(--paleta2A);
        font-weight: 700;
        color: white;
      }
    }
  }

  .aviso {
    margin-top: 17px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: var(--texto);
    max-width: 758px;
  }

  .alert {
    margin-top: 17px;
    margin-bottom: 17px;
    padding: 20px 80px;
    background-color: var(--paleta1A);
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: var(--branco);
    text-align: center;
    min-height: 46px;
    border-radius: 23px;
    max-width: 758px;
  }

  .question {
    font-size: 13px;
    line-height: 15px;
    font-weight: 700;
    color: var(--texto2);
    padding: 10px;
  }

  .inputAssunto {
    width: 100%;
    max-width: 758px;
  }

  .input {
    padding: 10px;
    border: none;
    width: 100%;
    max-width: 758px;
    background-color: var(--background2);
    border-radius: 10px;
    height: 150px;
    display: flex;
    outline: none;
    resize: none;
  }

  .areaButton {
    margin-top: 10px;
    max-width: 758px;
    display: flex;
    justify-content: flex-end;
  }
`;
