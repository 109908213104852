import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 1500px;
  display: flex;
  gap: 30px;

  .leftArea {
    height: 100%;
    width: calc(100% - 274px);
    display: flex;
    flex-direction: column;
    gap: 20px;

    .areaLoad{
      width: 100%;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .comentarios {
      width: 100%;
      height: 200px;
    }
  }

  .rigthArea {
    width: 400px;
    height: 100%;
    gap: 20px;
    display: flex;
    flex-direction: column;
    background-color: var(--paleta2C);
    padding: 10px;
    border-radius: 10px 0 0 10px;
  }
`;
