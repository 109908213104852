import styled, { css } from 'styled-components';

interface Props {
  compact?: boolean;
}

export const Container = styled.div<Props>`
  grid-area: menu;
  width: 235px;
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--purple2);
  color: var(--background);
  z-index: 3;
  ${(props) =>
    props.compact &&
    css`
      max-width: 110px;
      min-width: 110px;
      width: 110px;
      .userInfo {
        h2 {
          word-break: break-all;
          padding: 10px;
        }
        span {
          display: flex;
          flex-direction: column;
        }
      }
    `}
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Profile = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 110px;
  margin-top: 30px;

  .logout {
    margin-top: 10px;
    font-size: 11px;
    cursor: pointer;
    text-decoration: underline;
  }
  > span {
    font-size: 11px;
    font-weight: 900;
    line-height: 13px;
    color: var(--purple7);
    text-transform: uppercase;
    text-align: center;
  }

  .userInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    margin-top: 17px;

    h2 {
      font-size: 14px;
      font-weight: 700;
      color: var(--background);
      margin-top: 16px;
      text-align: center;
    }

    span {
      font-size: 12px;
      font-weight: 400;
      color: var(--background);
      margin-top: 4px;
      text-align: center;
    }
  }
  .userImg {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 5px;
    background-size: cover;
    background-position: center;
    width: 70px;
    height: 70px;
    ${(props) =>
      props.compact &&
      css`
        width: 55px;
        height: 55px;
      `}
  }
`;

export const SidebarNav = styled.div`
  width: 100%;
`;
