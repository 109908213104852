import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 7px;
  cursor: pointer;

  .areaInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .areaName {
      display: flex;
      align-items: center;
      gap: 8px;

      .name {
        color: var(--paleta1B);
        font-size: 13px;
        line-height: 15px;
        font-weight: 700;
        align-self: center;
      }
    }

    .moment {
      margin-top: 1px;
      font-size: 13px;
      line-height: 14px;
      font-weight: 400;
      color: #7d7d7d;
    }

    .seguidores {
      margin-top: 6px;
      font-size: 13px;
      line-height: 14px;
      font-weight: 400;
      color: #7d7d7d;
    }
  }

  .fotoPerfil {
    min-width: 65px;
    min-height: 65px;
    border-radius: 100px;
    background-size: cover;
    background-position: center;
  }

  @media (max-width: 800px) {
    .fotoPerfil {
      min-height: 50px;
      min-width: 50px;
      max-height: 50px;
      max-width: 50px;
    }
  }
`;
