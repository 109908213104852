import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import ButttonDefault from '../../ButtonDefault';
import ModalAttProduto from '../../Empresa/ModalAttProduto';
import { AreaModal, Container } from './styles';
import UserContext from '../../../contexts/UserContext';
import Inputdefault from '../../Inputs/InputDefault';
import { toast } from 'react-toastify';
import apiDoubts from '../../../services/api';
import {
  IconAgenda,
  IconEstrela,
  IconLoading,
  IconPencil,
  IconTrash,
} from '../../Icons';
import { MostrarMais } from '../../VideoDescricao/styles';

interface props {
  data: any;
  deleteCaseDeSucesso: (id: string) => any;
  isConfig: boolean;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ data, deleteCaseDeSucesso, isConfig }: props) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [consultoresSelect, setConsultoresSelect] = useState<any[]>([]);
  const [consultorSelected, setConsultorSelected] = useState<any>({
    label: data?.empresa?.nickName,
    value: data?.empresa?.empresaId,
  });
  const [enterpreuerSettings, setEnterpreurSettings] = useState<any>(data);
  const [DTO, setDTO] = useState<any>({
    titulo: '',
    empresaId: '63582191be19292ea41cf6a4',
    subtitulo: '',
    comentarioEmpresa: '',
    texto: '',
  });
  const colourStyles = {
    control: (provided: Record<string, unknown>) => ({
      ...provided,
      color: '#ff0000',
      height: 48,
      width: '100%',
      zIndex: 5,
      boxShadow: 'none',
      cursor: 'pointer',
      backgroundColor: '#E7E7F3',
      border: 'none',

      '&:hover': {
        border: '1px solid #0661C7',
      },
    }),
    menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
    input: (provided: any) => ({
      ...provided,
      fontSize: 13,
      color: '#7D7D7D',
      fontWeight: 400,
    }),
    placeholder: (provided: any) => ({
      ...provided,
      fontSize: 13,
      color: '#7D7D7D',
      fontWeight: 400,
    }),
    singleValue: (provided: any) => ({
      ...provided,
      fontSize: 13,
      color: '#000',
      fontWeight: 400,
    }),
  };
  const [seeFullDescription, setSeeFullDescription] = useState<number>(500);
  const [seeFullEnterpreuerComment, setSeeFullEnterpreuerComment] =
    useState<number>(500);

  const [sendingCase, setSendingCase] = useState<boolean>(false);
  const [deletingCase, setDeletingCase] = useState<boolean>(false);

  const { user } = useContext<any>(UserContext);

  useEffect(() => {
    handleConsultoresSelect(user);
  }, [user]);

  useEffect(() => {
    if (data) {
      handleDTO(data);
    }
  }, [data]);

  function handleOnChange(name: any, value: any) {
    const newDTO: any = DTO;
    newDTO[name] = value;
    setDTO({ ...newDTO });
    return;
  }

  function handleDTO(postagem: any) {
    setDTO({
      titulo: postagem?.titulo,
      empresaId: postagem?.empresaId,
      subtitulo: postagem?.subtitulo,
      comentarioEmpresa: postagem?.comentarioEmpresa,
      texto: postagem?.texto,
    });

    setConsultorSelected({
      label: data?.empresa?.nickName,
      id: data?.empresa?.id,
    });
  }

  function formatarData(e: any) {
    handleOnChange('subtitulo', e?.target?.value);
  }

  function handleConsultoresSelect(user: any) {
    let arrayConsultores: any[] = [];
    user?.empresas?.map((row: any) => {
      let newConsultor = {
        label: row?.nickName,
        value: row?.id,
      };
      arrayConsultores?.push(newConsultor);
    });
    console.log(arrayConsultores);
    setConsultoresSelect(arrayConsultores);
  }

  function checkInfos(infos: any) {
    setSendingCase(true);
    try {
      if (!infos?.empresaId || infos?.empresaId === '')
        throw new Error('Você não pode remover a empresa');
      if (!infos?.titulo || infos.titulo === '')
        throw new Error('Adicione um titulo ao case de sucesso');
      if (!infos?.texto || infos.texto === '')
        throw new Error('Adicione uma descrição ao case de sucesso');
      if (infos?.text?.length > 1000)
        throw new Error(
          'O comentário da agência deve ter no máximo 1000 caracteres'
        );
      if (!infos?.comentarioEmpresa || infos.comentarioEmpresa === '')
        throw new Error('Adicione um comentário da empresa ao case de sucesso');
      if (infos?.comentarioEmpresa?.length > 1000)
        throw new Error(
          'O comentário da empresa deve ter no máximo 1000 caracteres'
        );
      if (!infos?.subtitulo || infos.subtitulo === '')
        throw new Error('Adicione a data que ocorreu o case de sucesso');

      updateInfos(infos);
    } catch (err: any) {
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  async function updateInfos(infos: any) {
    try {
      await apiDoubts
        .put(`/case/agencia/${data?.id}`, infos)
        .then((response: any) => {
          if (response?.data?.status === 'error')
            throw new Error(response?.data?.message?.error);
          setOpenModal(false);
          setEnterpreurSettings(infos);
          setSendingCase(false);
          setConsultorSelected({ label: '', value: '' });
          toast.success('Case de sucesso atualizado com sucesso!', {
            className: 'toast-sucess',
            theme: 'colored',
          });
        });
    } catch (err: any) {
      setSendingCase(false);
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  return (
    <>
      <ModalAttProduto
        onClose={() => {
          setOpenModal(false);
        }}
        id="ModalAttCaseDeSucesso"
        openModal={openModal}
        title="Atualizar Case de Sucesso"
        scroll={true}
      >
        <AreaModal>
          <div className="inputArea">
            <h3 className="label">Empresa</h3>
            <Select
              options={consultoresSelect}
              defaultValue={consultorSelected?.label}
              value={consultorSelected?.label}
              styles={colourStyles}
              onChange={(e: any) => {
                setConsultorSelected(e);
              }}
            />
          </div>
          <div className="inputArea">
            <h3 className="label">Título</h3>
            <Inputdefault
              change={(e: any) => {
                handleOnChange('titulo', e.target.value);
              }}
              placeholder={'Título'}
              tipo={'text'}
              value={DTO?.titulo}
            />
          </div>
          <div className="inputArea">
            <h3 className="label">Descrição</h3>
            <textarea
              value={DTO?.texto}
              className="comment"
              placeholder="Comentário..."
              maxLength={1000}
              onChange={(e) => {
                handleOnChange('texto', e?.target?.value);
              }}
            />
          </div>
          <div className="inputArea">
            <h3 className="label">Comentário da Empresa</h3>
            <textarea
              value={DTO?.comentarioEmpresa}
              className="comment"
              placeholder="Comentário..."
              maxLength={1000}
              onChange={(e) => {
                handleOnChange('comentarioEmpresa', e?.target?.value);
              }}
            />
          </div>
          <div className="inputArea">
            <h3 className="label">Data da Campanha</h3>
            <input
              type="date"
              className="DatePickerInput"
              onChange={(e: any) => {
                formatarData(e);
              }}
              value={moment(DTO?.subtitulo).format('YYYY-MM-DD')}
            />
          </div>
          <div className="areaButtons">
            <ButttonDefault
              title={'Cancelar'}
              tipo={'11'}
              onClick={() => {
                handleDTO(data);
                setOpenModal(false);
              }}
            />
            <ButttonDefault
              icon={sendingCase ? 'Loading-White' : ''}
              title={'Salvar'}
              tipo={'6'}
              onClick={() => {
                checkInfos(DTO);
              }}
            />
          </div>
        </AreaModal>
      </ModalAttProduto>

      <Container>
        <div className="topCardCaseArea">
          <div className="leftTopCardCaseArea">
            <div
              className="imageEnterpreuer"
              style={{ backgroundImage: `url(${data?.empresa?.fotoFull})` }}
            ></div>
            <div className="EnterpreuerInfos">
              <h2 className="EnterpreuerName">{data?.empresa?.nickName}</h2>
              <h3 className="CamapingDate">
                Campanha de{' '}
                {moment(enterpreuerSettings?.subtitulo)
                  .locale('pt-br')
                  .format('MMMM, [de] YYYY')}
              </h3>
            </div>
            {data.agenciaId === user.id && (
              <>
                <span className="editCase" onClick={() => setOpenModal(true)}>
                  <IconPencil color="var(--paleta1A)" />
                </span>

                <span
                  onClick={() => {
                    setDeletingCase(true);
                    deleteCaseDeSucesso(enterpreuerSettings?.id)
                      .then(() => {
                        setDeletingCase(false);
                      })
                      .catch(() => {
                        setDeletingCase(false);
                      });
                  }}
                  className="deleteCase"
                >
                  {deletingCase ? (
                    <IconLoading color={'var(--offline)'} size={20} />
                  ) : (
                    <IconTrash color={'var(--offline)'} />
                  )}
                </span>
              </>
            )}
          </div>
        </div>
        <div className="bottomCardCaseArea">
          <div className="AgencyCommentArea">
            <h3 className="subtitleAgencyComment">
              {enterpreuerSettings?.titulo}
            </h3>
            <p
              className={`contentAgencyComment commentArea ${
                seeFullDescription > 500 ? 'longText' : ''
              }`}
            >
              {enterpreuerSettings?.texto?.substring(0, seeFullDescription)}
            </p>
          </div>
          <div className="mostrarMaisButtonWrapper">
            {enterpreuerSettings?.texto &&
              enterpreuerSettings?.texto?.length > 200 && (
                <MostrarMais
                  onClick={() => {
                    if (seeFullDescription === 500) {
                      setSeeFullDescription(enterpreuerSettings?.texto?.length);
                    } else {
                      setSeeFullDescription(500);
                    }
                  }}
                >
                  {seeFullDescription === 500
                    ? 'MOSTRAR MAIS'
                    : 'MOSTRAR MENOS'}
                </MostrarMais>
              )}
          </div>
          <div className="enterpreuerCommentArea">
            <div className="ButtonEnterpreuerComment">
              COMENTÁRIO DA EMPRESA
            </div>
            <p
              className={`enterprauerComment commentArea ${
                seeFullEnterpreuerComment > 500 ? 'longText' : ''
              }`}
            >
              {enterpreuerSettings?.comentarioEmpresa?.substring(
                0,
                seeFullEnterpreuerComment
              )}
            </p>
            {enterpreuerSettings?.comentarioEmpresa &&
              enterpreuerSettings?.comentarioEmpresa?.length > 200 && (
                <MostrarMais
                  onClick={() => {
                    if (seeFullEnterpreuerComment === 500) {
                      setSeeFullEnterpreuerComment(
                        enterpreuerSettings?.comentarioEmpresa?.length
                      );
                    } else {
                      setSeeFullEnterpreuerComment(500);
                    }
                  }}
                >
                  {seeFullEnterpreuerComment === 500
                    ? 'MOSTRAR MAIS'
                    : 'MOSTRAR MENOS'}
                </MostrarMais>
              )}
          </div>
        </div>
      </Container>
    </>
  );
}
