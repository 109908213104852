import { ReactNode } from 'react';
import { ContainerModal } from './styles';

interface IModalForm {
  children: ReactNode;
}

export default function ModalForm({ children }: IModalForm) {
  return <ContainerModal>{children}</ContainerModal>;
}
