import { IconFile, IconMore } from '../../Icons';
import { Container } from './styles';

interface VProps {
  placeholder: string;
  tipo: 'text' | 'file';
  change: (e: any) => void;
  file: string;
}

export default function AddimageCirculo({
  placeholder,
  tipo,
  change,
  file,
}: VProps) {
  return (
    <Container placeholder={placeholder} type={tipo} onChange={change}>
      <div className="contents">
        <input type={tipo} />
        <p className="more">+</p>
      </div>
    </Container>
  );
}
