import moment from 'moment';

export function getMonthInRange(
  initialMonth: string,
  endMonth: string
): string[] {
  const start = moment(initialMonth, 'MM-YYYY');
  const end = moment(endMonth, 'MM-YYYY');
  const months: string[] = [];

  while (start.isSameOrBefore(end)) {
    months.push(start.format('MM-YYYY'));
    start.add(1, 'month');
  }

  return months;
}
