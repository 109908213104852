import ButttonDefault from '../ButtonDefault';
import { Container } from './styles';

export default function WarningLogout({
  fullscreen = true,
  title = 'Página restrita',
  description = 'Para acessar esse conteúdo é necessário fazer login',
  button = true,
}) {
  return (
    <Container className={fullscreen ? 'fullscreen' : 'normal'}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 48 48"
      >
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.94 9.44a3.1 3.1 0 0 0-3.1 3.1v1l-.41.42a21 21 0 0 1-9.33 5.6l-.48.15v.5a21 21 0 0 0 1.75 11a15.18 15.18 0 0 0 8.35 7.26l.27.1l.27-.1a15.17 15.17 0 0 0 8.36-7.26s0 0 0 0H40.4a3.12 3.12 0 0 0 3.1-3.1V12.54a3.11 3.11 0 0 0-3.1-3.1Zm7.46 13.28c0-.21-.06-2.3-.07-2.51v-.5l-.49-.15a24.09 24.09 0 0 1-10-6.07m8.76 17.67a19.26 19.26 0 0 0 1.8-8.44m-4.68.22l-6.6 6.72L10.18 26m30.65-13.46L29.17 19.7m0 0l-11.66-7.16"
        />
      </svg>
      {title && <h1>{title}</h1>}
      {description && <h2 className="takeLogin">{description}</h2>}
      {button && (
        <ButttonDefault
          onClick={() => (window.location.href = '/login')}
          title="Entrar"
        />
      )}
    </Container>
  );
}
