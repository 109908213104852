import styled from 'styled-components';

export const ContainerCalendar = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  .react-calendar {
    width: 380px;
    border: none;
    background: transparent;
    .react-calendar__navigation {
      .react-calendar__navigation__label__labelText {
        font-size: 14px;
        font-weight: 500;
        color: #49454f;
      }
      button {
        font-size: 20px;
        border-radius: 100px;
      }
    }
    .react-calendar__month-view__weekdays {
      .react-calendar__month-view__weekdays__weekday {
        abbr {
          text-decoration: none;
          font-size: 16px;
          font-weight: 400;
          color: #2e294e;
        }
      }
    }
    .react-calendar__month-view__days {
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 0px;
        abbr {
          display: flex;
          width: 40px;
          height: 40px;
          border-radius: 100px;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          font-weight: 400;
          color: #2e294e;
        }
        &.react-calendar__tile--active {
          background: transparent;
          abbr {
            color: #ffffff !important;
            background: #685ea9 !important;
          }
        }
        &.react-calendar__tile--now {
          background: transparent;
          abbr {
            background: #0fbaba;
            color: #ffffff;
          }
        }
        &.react-calendar__month-view__days__day--weekend {
          abbr {
            color: var(--danger);
          }
        }
        &:hover {
          background: transparent;
          abbr {
            background: rgba(0, 0, 0, 0.1);
            color: #2e294e;
          }
        }
      }
    }
  }
`;

export const ContainerHours = styled.div`
  display: flex;
  user-select: none;
  flex-direction: column;
  .row-hour {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 8px 8px 0px;
    height: 75px;
    &.active {
      background: #ece9ff;
    }
    &.old {
      opacity: 0.5;
    }
    .hour-text {
      font-size: 16px;
      font-weight: 500;
      color: #2e294e;
      width: 100px;
    }
    .events {
      display: flex;
      flex: 1;
      gap: 8px;
      .event {
        flex: 1;
        display: flex;
        border-radius: 8px;
        padding: 8px 16px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        &.hover {
          opacity: 0;
          &:hover {
            opacity: 1;
          }
        }
        .title {
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
        }
        .description {
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
  }
`;
