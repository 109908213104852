import { useState } from 'react';
import ButttonDefault from '../ButtonDefault';
import { AreaButton, Container } from './styles';

interface props {
  children: any;
}

export default function TrocaSenha({ children }: props) {
  const [modalOpened, setModalOpened] = useState<boolean>(false);

  return (
    <Container modalOpened={modalOpened}>
      <div className="openModal">
        <ButttonDefault
          title={'Alterar Senha'}
          full={true}
          onClick={() => {
            setModalOpened(!modalOpened);
          }}
          hover={'none'}
        />
      </div>
      <AreaButton modalOpened={modalOpened}>{children}</AreaButton>
    </Container>
  );
}
