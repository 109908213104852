import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Banner from '../../components/Banner';
import HeaderConsultor from '../../components/HeaderConsultor';
import ListPosts from '../../components/Posts/ListPosts';
import apiDoubts from '../../services/api';
import { Container } from './styles';

export default function Canal() {
  const params = useParams<any>();
  const page = 'Canal';
  const [banner, setBanner] = useState<any>('');
  const location = useLocation<any>();
  const [consultor, setConsultor] = useState<any>(
    location.state && location.state.consultor ? location.state.consultor : {}
  );
  useEffect(() => {
    getBanner();
  }, []);

  async function getBanner() {
    const response = await apiDoubts.get('/banners?tipo=2&randon=true');
    console.log('banner', response);
    setBanner(response?.data?.data[0]);
  }

  return (
    <Container>
      <HeaderConsultor
        onSetConsultor={setConsultor}
        consultorId={params.id}
        page="Canal"
      />

      <div className="description">{consultor?.descricaoCanal}</div>

      <div className="content-user-page">
        <div className="render">
          <ListPosts
            order=""
            where={{}}
            consultorId={params.id}
            tipo="consultor"
          />
        </div>
        <div className="sideRigth">
          <div className="areaBanner">
            <Banner peso="2" position="1" typeBanner="322x227" />
          </div>
        </div>
      </div>
    </Container>
  );
}
