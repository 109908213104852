import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import UserContext from '../../contexts/UserContext';
import apiDoubts from '../../services/api';
import ButttonDefault from '../ButtonDefault';
import ModalAttProduto from '../Empresa/ModalAttProduto';
import { AreaMessageConsultor, Container, ConviteConsultor } from './styles';

interface props {
  consultorData: any;
  invite: (id: string) => void;
}

export default function CardMenuOportunidade({ consultorData, invite }: props) {
  const [modalConvidarConsultor, setModalConvidarConsultor] =
    useState<boolean>(false);
  const [messageConviteConsultor, setMessageConviteConsultor] =
    useState<string>('');
  const [loadInvite, setLoadInvite] = useState<boolean>(false);

  const { user } = useContext(UserContext);

  async function handleInviteConsultor() {
    if (loadInvite === false) {
      try {
        setLoadInvite(true);
        if (!messageConviteConsultor)
          throw new Error('Por favor, digite uma mensagem para o consultor');

        await apiDoubts
          .post(`/conversa/enviar-mensagem`, {
            tipoConversa: 'convite',
            titulo: `convite - ${user?.nickName}`,
            mensagem: messageConviteConsultor,
            destinatarioId: consultorData?.id,
          })
          .then((response: any) => {
            if (response?.data?.status === 'error') {
              throw new Error(response?.data?.message);
            }
            setModalConvidarConsultor(false);
            setMessageConviteConsultor('');
            setLoadInvite(false);
            toast.success('Consultor convidado com sucesso', {
              className: 'toast-sucess',
              theme: 'colored',
            });
          });
      } catch (error: any) {
        setModalConvidarConsultor(false);
        setMessageConviteConsultor('');
        setLoadInvite(false);
        
        toast.error('tivemos um problema ao enviar esse convite', {
          className: 'toast-error',
          theme: 'colored',
        });
      }
    }
  }

  return (
    <Container>
      <ModalAttProduto
        id="modalConvidarConsultor"
        width="450"
        onClose={() => {
          setModalConvidarConsultor(false);
        }}
        openModal={modalConvidarConsultor}
        title="Convidar Consultor"
        scroll={false}
      >
        <ConviteConsultor>
          <div className="areaLeftConsultorConvite">
            <div
              className="imageProfileConsultor"
              style={{ backgroundImage: `urL(${consultorData?.foto})` }}
            ></div>
          </div>
          <div className="areaRightConsultorConvite">
            <h4 className="nameConsultor">{consultorData?.nickName}</h4>
            <p className="descriptionConsultor">
              {consultorData?.descricaoCanal}
            </p>
          </div>
        </ConviteConsultor>
        
        <AreaMessageConsultor>
          <h3 className="titleAreaMessageConsultor">Mensagem</h3>
          <textarea
            value={messageConviteConsultor}
            className="input"
            placeholder="Assuntos..."
            onChange={(e) => {
              setMessageConviteConsultor(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleInviteConsultor()
                }
            }}
          />
          <div className="areaButtonsConsultor">
            <ButttonDefault
              title={'Cancelar'}
              tipo={'11'}
              onClick={() => {
                setModalConvidarConsultor(false);
              }}
            />
            <ButttonDefault
              icon={loadInvite ? 'Loading' : ''}
              title={'Enviar'}
              tipo={'6'}
              onClick={() => {
                handleInviteConsultor();
              }}
            />
          </div>
        </AreaMessageConsultor>
      </ModalAttProduto>

      <div
        className="imageProfileConsultor"
        style={{ backgroundImage: `url(${consultorData?.foto})` }}
      ></div>
      <h3 className="nickNameConsultor">{consultorData?.nickName}</h3>
      <h4 className="consultorDescription">{consultorData?.descricaoCanal}</h4>
      <ButttonDefault
        onClick={() => {
          // if (consultorData?.convitePendente === false) {
          setModalConvidarConsultor(true);
          // }
        }}
        title={
          consultorData?.convitePendente
            ? 'Consultor Convidado'
            : 'Convidar Consultor'
        }
        tipo={consultorData?.convitePendente ? '12' : '7'}
        hover={'medio'}
      />
    </Container>
  );
}
