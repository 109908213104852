import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  .bannerConsultor {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    height: 180px;
    border-radius: 10px;
    margin-bottom: 6px;
    background-size: cover;
    background-position-y: center;
    background-color: #ffffff;
  }

  .areaConsultor {
    display: flex;
    align-items: flex-end;
    gap: 25px;
    .fotoConsultor {
      background-color: var(--background2);
      width: 180px;
      height: 180px;
      border-radius: 100px;
      background-size: cover;
      background-position: center;
      margin-top: -90px;
      margin-left: 35px;
      position: relative;
    }
    .name {
      font-size: 22px;
      color: var(--purple1);
      gap: 15px;
      display: flex;
      align-items: flex-end;
      h1 {
        font-weight: 800;
      }
    }
    .info-general {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      gap: 5px;
      p {
        font-size: 14px;
        color: var(--purple4);
      }
      b {
        font-size: 18px;
        line-height: 5px;
      }
    }
    .availabilitys {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      gap: 15px;
    }
  }

  .areaBtn {
    display: flex;
    border-bottom: 3px solid var(--background2);
    margin: 20px 0px;
    gap: 25px;
    a {
      border-bottom: 3px solid var(--background2);
      margin-bottom: -3px;
      padding: 8px 10px;
      font-weight: 500;
      color: var(--texto);
      cursor: pointer;
      &.active {
        border-bottom: 3px solid var(--purple3);
        color: var(--purple3);
        font-weight: 600;
      }
    }
  }

  @media (max-width: 1350px) {
    .areaBtn {
      margin-left: 0;
    }
  }
  @media (max-width: 650px) {
    .areaBtn {
      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      &:after {
        content: '';
        width: 40px;
        height: 39px;
        position: absolute;
        right: 0px;
        background: linear-gradient(
          270deg,
          #fafaff 0%,
          rgba(250, 250, 255, 0) 100%
        );
      }
    }
  }
`;
