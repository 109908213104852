export function formatCNPJ(cnpj: string) {
  const formattedCnpj = cnpj
    .replace(/\D/g, '') // remove tudo que não for número
    .replace(/(\d{2})(\d)/, '$1.$2') // coloca ponto após os primeiros dois dígitos
    .replace(/(\d{3})(\d)/, '$1.$2') // coloca ponto após os próximos três dígitos
    .replace(/(\d{3})(\d)/, '$1/$2') // coloca barra após os próximos três dígitos
    .replace(/(\d{4})(\d)/, '$1-$2') // coloca hífen após os últimos quatro dígitos
    .substr(0, 18); // limita a 18 caracteres

  return formattedCnpj;
}

export function formatCel(cel: string) {
  const formattedTelefone = cel
    .replace(/\D/g, '') // remove tudo que não for número
    .replace(/^(\d{2})(\d)/g, '($1) $2') // adiciona parênteses e espaço após os primeiros dois dígitos
    .replace(/(\d{5})(\d)/, '$1-$2') // adiciona hífen após os próximos quatro dígitos
    .substr(0, 15); // limita a 15 caracteres

  return formattedTelefone;
}

export function formatCep(cep: string) {
  const formattedCep = cep
    .replace(/\D/g, '') // remove tudo que não for número
    .replace(/(\d{5})(\d)/, '$1-$2') // adiciona hífen após os primeiros cinco dígitos
    .substr(0, 9); // limita a 9 caracteres
  return formattedCep;
}

export function formatCPF(cpf: string) {
  const formattedCpf = cpf
    .replace(/\D/g, '') // remove tudo que não for número
    .replace(/(\d{3})(\d)/, '$1.$2') // adiciona ponto após os primeiros três dígitos
    .replace(/(\d{3})(\d)/, '$1.$2') // adiciona ponto após os próximos três dígitos
    .replace(/(\d{3})(\d{1,2})/, '$1-$2') // adiciona hífen após os próximos três ou dois dígitos
    .substr(0, 14); // limita a 14 caracteres

  return formattedCpf;
}

export function formatRG(rg: string) {
  const formattedRg = rg
    .replace(/\D/g, '') // remove tudo que não for número
    .replace(/(\d{2})(\d)/, '$1.$2') // adiciona ponto após os primeiros dois dígitos
    .replace(/(\d{3})(\d)/, '$1.$2') // adiciona ponto após os próximos três dígitos
    .replace(/(\d{3})(\d{1,2})/, '$1-$2') // adiciona hífen após os próximos três ou dois dígitos
    .substr(0, 12); // limita a 12 caracteres

  return formattedRg;
}

export function formatData(data: string) {
  console.log(data);
  let dataFormatada = data
    .replace(/^(\d{2})(\d{2})(\d{4})$/, '$1/$2/$3')
    .substring(0, 10);
  console.log(dataFormatada);

  return dataFormatada.toString();
}

export function formatEmail(email: string) {
  let emailMascarado = email.replace(
    /^([^\s@]){2,}(?=(?:[^\s@])?@[^\s@]+\.[^\s@]+)$/,
    function (match) {
      let asteriscos = '';
      for (let i = 0; i < match.length; i++) {
        asteriscos += '*';
      }
      return asteriscos;
    }
  );
  return emailMascarado;
}

export function formatarDataString(data: any) {
  const meses = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  const dia = data.getDate();
  const mes = meses[data.getMonth()];
  const ano = data.getFullYear();

  return `${dia} de ${mes} de ${ano}`;
}

export function formatAgencia(agencia: string) {
  let formattedAgency = agencia
    .replace(/^(\d{3})(\d{1})$/, '$1-$2')
    .substring(0, 5);
  console.log(formattedAgency);
  return formattedAgency;
}

export function formatBankAccount(agencia: string) {
  let formattedBankAccount = agencia
    .replace(/^(\d{5})(\d{2})$/, '$1-$2')
    .substring(0, 8);
  console.log(formattedBankAccount);
  return formattedBankAccount;
}
