import BackArrowIcon from '../../assets/icons/BackArrow';
import ButttonDefault from '../ButtonDefault';
import { ContainerOverlay, Container } from './styles';

interface ModalProps {
  id: string;
  children: any;
  onClose: () => void;
  openModal: boolean;
  width?: string;
  prosseguir?: () => void;
}

export default function ModalSucess({
  prosseguir,
  id,
  onClose,
  width,
  openModal,
  children,
}: ModalProps) {
  const handleCloseModal = (e: any) => {
    if (e.target.id === id) {
      onClose(); 
    }
  };

  return (
    <ContainerOverlay id={id} openModal={openModal} onClick={handleCloseModal}>
      <Container width={width} openModal={openModal}>
        <div className="container-modal">          
          <div className="content">
            {children}
            <ButttonDefault title="Prosseguir" onClick={prosseguir} tipo='2'/>
          </div>
        </div>
      </Container>
    </ContainerOverlay>
  );
}
