/* eslint-disable array-callback-return */
import { useEffect, useState } from 'react';
import AnunciosVerticais from '../../../components/Anuncio/AnunciosVerticais';
import FiltrosInteresses from '../../../components/FiltrosInteresses';
import SelectDefaultSearch from '../../../components/Form/SelectDefaultSearch';
import Hashtags from '../../../components/Hashtags';
import ListPosts from '../../../components/Posts/ListPosts';
import TopConsultores from '../../../components/TopConsultores';
import { Container, ContainerContent, ContainerFilter } from './styles';
import apiDoubts from '../../../services/api';

const Home: React.FC = (props: any) => {
  const ordenacaoOpcoes = [
    {
      label: 'Populares',
      value: 'popular',
    },
    {
      label: 'Recentes',
      value: 'data',
    },
  ];
  const [ordenacao, setOrdenacao] = useState<any>({
    label: 'Populares',
    value: 'popular',
  });
  const [interesse, setInteresse] = useState<string>('');
  const [hashtag, setHashtag] = useState<string>();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [hashtag]);

  useEffect(() => {
    async function checkUserIsLoggedIn(): Promise<void> {
      if (typeof window === 'undefined') return;

      const userToken = localStorage?.getItem('token');

      if (userToken) return;

      const hasGuestToken = localStorage.getItem('token-deslogado');

      if (hasGuestToken) return;

      async function getGuestToken(): Promise<string> {
        const { data } = await apiDoubts?.post(`/token-usuario-deslogado`);

        return data?.data?.token;
      }

      const guestToken = await getGuestToken();

      localStorage?.setItem('token-deslogado', guestToken);
    }

    checkUserIsLoggedIn();
  }, []);

  return (
    <Container>
      <ContainerFilter>
        <div className="order">
          <div className="areaSelect">
            <SelectDefaultSearch
              options={ordenacaoOpcoes}
              value={ordenacao}
              onChange={(value) => setOrdenacao(value)}
            />
          </div>
          <div className="hashMobile">
            <Hashtags
              currentHashtags={hashtag}
              hash={(e) => setHashtag(e)}
              title={false}
              position="top"
            />
          </div>
        </div>

        <div className="where">
          <FiltrosInteresses
            selected={interesse}
            setSelected={(e) => setInteresse(e)}
          />
        </div>
      </ContainerFilter>

      <ContainerContent>
        <div className="section-left">
          <ListPosts
            order={ordenacao.value}
            where={{
              interesse: interesse,
              hash: hashtag,
            }}
          />
          <div className="areaButton"></div>
        </div>
        <div className="section-right">
          <AnunciosVerticais
            count={1}
            where={{
              interesse: interesse,
              search: '',
              hashs: [],
            }}
          />
          <TopConsultores direction={'colum'} />
          <Hashtags
            currentHashtags={hashtag}
            hash={(e) => setHashtag(e)}
            title={true}
            position={'lateral'}
          />
        </div>
      </ContainerContent>
    </Container>
  );
};

export default Home;
