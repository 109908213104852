import styled from "styled-components";


export const Container = styled.div`
  height: 230px;
  background-color: var(--background2);
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 9px -4px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 9px -4px rgba(0, 0, 0, 0.8);
  .top {
    height: 140px;
    background-size: cover;
    background-position: center;
    border-radius: 5px 5px 0 0;
  }
  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    .titleCard {
      font-size: 13px;
      font-weight: 700;
      line-height: 15px;
      color: var(--paleta2C);
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }
`;