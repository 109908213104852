import { useEffect, useState } from 'react';
import { Container } from './styles';

interface props {
  icon: any;
  path: string;
}

export default function MiniButton({ icon, path }: props) {
  const [newPath, setNewPath] = useState<string>('');

  useEffect(() => {
    if (path) {
      handlelink(path);
    }
  }, [path]);

  function handlelink(link: string) {
    if (link?.includes('https://') || link?.includes('http://')) {
      setNewPath(link);
      return;
    }

    let newLink: string = `https://${link}`;
    setNewPath(newLink);
  }

  return (
    <Container
      onClick={() => {
        window.open(`${newPath}`, '_blank');
      }}
    >
      {icon}
    </Container>
  );
}
