/* eslint-disable react-hooks/exhaustive-deps */
import { Container } from './styles';

import bannerConsultor from '../../assets/bannerConsultor.png';
import apiDoubts from '../../services/api';
import { useContext, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { IconVerify } from '../Icons';
import Online from '../Online';
import ButttonDefault from '../ButtonDefault';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import UserContext from '../../contexts/UserContext';
import { toast } from 'react-toastify';
import Availability from '../Availability';

interface IConsultor {
  consultorId: string;
  page?: string;
  onSetConsultor?(consultor: any): void;
}

export default function HeaderConsultor({
  consultorId,
  page,
  onSetConsultor,
}: IConsultor) {
  const location = useLocation<any>();
  const { user } = useContext(UserContext);
  const [consultor, setConsultor] = useState<any>(
    location.state && location.state.consultor ? location.state.consultor : {}
  );
  const pages = ['Canal', 'Playlist', 'Social', 'Consultoria'];
  const [pageSelected, setPageSelected] = useState('');
  const history = useHistory();
  const params = useParams<any>();

  //follower
  const [loadingButtonFollower, setLoadingButtonFollower] =
    useState<boolean>(false);
  const [isFollower, setIsFollower] = useState<boolean>(
    location.state && location.state.consultor
      ? location.state.isFollower
      : false
  );

  useEffect(() => {
    if (consultorId) {
      getConsultor();
    }
  }, [consultorId, location]);

  async function getConsultor() {
    await apiDoubts
      .get(`consultor/get-by-id/${consultorId}`)
      .then((response) => {
        // console.log(response.data);
        if (onSetConsultor) onSetConsultor(response?.data?.data[0]);
        setConsultor(response?.data?.data[0]);
        if (user.id) getStatusSeguidor();
      });
  }

  function handlePage(key: string, row: string) {
    history.push(
      `/consultor/visualizar/${row.toLocaleLowerCase()}/${params?.id}`,
      {
        consultor,
        isFollower,
      }
    );
  }

  async function getStatusSeguidor() {
    const response = await apiDoubts.get(
      `/consultor/seguidor/status/${consultorId}`
    );
    setIsFollower(response.data.data.seguindo);
  }

  async function handleFollower() {
    setLoadingButtonFollower(true);
    if (user?.id) {
      await apiDoubts
        .put(`/consultor/seguidor/novo`, { consultorId: consultorId })
        .then((response) => {
          setLoadingButtonFollower(false);
          getStatusSeguidor();
        });
    } else {
      setLoadingButtonFollower(false);
      toast.error('É preciso estar logado para seguir um consultor', {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  return (
    <Container>
      <div
        className="bannerConsultor"
        style={{ backgroundImage: `url(${consultor?.capa})` }}
      />
      <div className="areaConsultor">
        <div
          className="fotoConsultor"
          style={{ backgroundImage: `url(${consultor.foto})` }}
        ></div>
        <div className="informations">
          <div className="name">
            <h1>{consultor.nickName ?? 'Consultor'}</h1>
            <Online consultorId={consultor?._id} />
          </div>
          <div className="info-general">
            <p>
              Consultor {moment(consultor.createdAt).locale('pt-br').fromNow()}
            </p>
            <b>‧</b>
            <p>
              {consultor.seguidores && consultor.seguidores.length} Seguidores
            </p>
          </div>
          <div className="availabilitys">
            <Availability
              value={consultor?.disponivelConferencia}
              title="Disponível para conferência"
            />
            <Availability
              value={consultor?.disponivelEmail}
              title="Disponível Email"
            />
            <Availability
              value={consultor?.disponivelWhatsApp}
              title="Disponível Agendamentos"
            />
          </div>
          {user.id && consultor.id !== user.id && (
            <ButttonDefault
              tipo={isFollower ? '15' : '16'}
              onClick={handleFollower}
              icon={loadingButtonFollower ? 'Loading' : 'Favoritar'}
              title={isFollower ? 'Deixar de Seguir' : 'Seguir'}
            />
          )}
        </div>
      </div>
      <div className="areaBtn">
        {pages.map((row: any, key: any) => (
          <a
            className={page === row ? 'active' : 'normal'}
            onClick={() => handlePage(key, row)}
            title={row}
          >
            {row}
          </a>
        ))}
      </div>
    </Container>
  );
}
