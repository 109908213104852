import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import apiDoubts from '../../services/api';
import ButttonDefault from '../ButtonDefault';
import ComentarArea from '../ComentarArea';
import ComentariosCard from '../ComentarioCard';
import { Container } from './styles';

interface VProps {
  videoId?: string;
  video?: any;
  consultorId?: string;
  reload?: boolean;
  setReload?: any;
}

export default function Comentarios({
  videoId,
  video,
  consultorId,
  reload,
  setReload,
}: VProps) {
  const [commentConsultor, setCommentConsultor] = useState<any[]>();
  const [comment, setComment] = useState<any>('');
  const [data, setData] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [loadButton, setLoadButton] = useState<boolean>(false);

  const [buttonLoadMoreComments, setButtonLoadMoreComments] =
    useState<boolean>(true);

  useEffect(() => {
    if (videoId) {
      setData([]);
      setPage(1);
      getData();
    }
  }, [videoId]);

  useEffect(() => {
    getData();
  }, [page]);

  useEffect(() => {
    if (consultorId) {
      handleConsultorId();
    }
  }, [consultorId]);

  async function getData(newComment?: boolean) {
    // O parêmtro new Comment serve para mudar a direção de iteração do Array. 
    // Caso seja um novo comentário ele deve ficar em primeiro, se for apenas para carregar mais comentários eles devem ficar por último

    const response: any = await apiDoubts.get(
      `/Comentarios/${videoId}?pagination=true&page=${page}&limit=5`
    );

    try {
      if (response?.data?.data?.docs?.length > 0) {
        let newArray;

        if (newComment) {
          newArray = [...response?.data?.data?.docs, ...data];
        } else {
          newArray = [...data, ...response?.data?.data?.docs];
        }

        setData(Array.from(new Set(newArray)));
        setLoadButton(false);
      }
      if (response.data.data?.docs?.length === 0 && page > 1) {
        setButtonLoadMoreComments(false);
        throw new Error('Não há mais comentários para serem carregados');
      }
    } catch (err: any) {
      setLoadButton(false);
      toast.error(err.message, {
        className: 'toast-error',
        theme: 'colored',
      });
    }
  }

  async function handleConsultorId() {
    const response = await apiDoubts.get(
      `/comentarios-canal-consultor/${consultorId}?pagination=true&limit=10&page=${page}`
    );
    setCommentConsultor(response?.data?.data?.docs);
  }

  function loadMoreComments() {
    setLoadButton(true);
    setPage(page + 1);
  }

  return (
    <>
      <Container>
        <div className="comentar">
          <ComentarArea
            comment={(e: any) => {
              setComment(e);
            }}
            //function to atualize comments
            getComents={() => {
              getData(true);
            }}
            reload={reload}
            videoId={video?._id}
          />
        </div>

        {data?.length > 0 && (
          <p>{data?.length === 1 ? 'Comentário' : ' Comentários'}</p>
        )}

        {data.length > 0 &&
          data?.map((row: any, key: number) => (
            <ComentariosCard key={key} comentario={row} />
          ))}

        {data && data?.length >= 5 && buttonLoadMoreComments && (
          <div className="areaButton">
            <ButttonDefault
              title={'Mostrar mais comentários...'}
              tipo="2"
              onClick={loadMoreComments}
              icon={loadButton ? 'Loading' : ''}
              hover="none"
            />
          </div>
        )}
        {commentConsultor && commentConsultor?.length > 10 && (
          <div className="areaButton">
            <ButttonDefault
              title={'Mostrar mais comentários...'}
              tipo="2"
              onClick={loadMoreComments}
              icon={loadButton ? 'Loading' : ''}
            />
          </div>
        )}
      </Container>

      {commentConsultor && (
        <Container>
          <p>
            {commentConsultor?.length === 0 &&
              'Esse consultor não possui comentários'}
          </p>
          {commentConsultor?.length > 0 && (
            <>
              <p>
                {commentConsultor?.length === 1 && '1 Comentário'}
                {commentConsultor?.length > 1 &&
                  commentConsultor.length + ' comentários'}
              </p>
              <div>
                {commentConsultor.map((row: any, key: number) => (
                  <ComentariosCard key={key} comentario={row} />
                ))}
              </div>
            </>
          )}
          <div></div>
        </Container>
      )}
    </>
  );
}
