import { useEffect, useState } from 'react';
import apiDoubts from '../../../services/api';
import CardPost from '../CardPost';
import { Container } from './styles';
import GIF from '../../../assets/not-found.gif';
import ButttonDefault from '../../ButtonDefault';
import { toast } from 'react-toastify';
import { IconLoading } from '../../Icons';
import InfiniteScroll from 'react-infinite-scroll-component';

interface IProps {
  playlist?: any;
  posicao?: string;
  where?: {
    interesse?: string;
    search?: string;
    hash?: string;
  };
  order?: string;
  consultorId?: string;
  tipo?: string;
  vistas?: boolean;
}

export default function ListPostsDescubra({
  vistas,
  playlist,
  posicao,
  where = {
    hash: '',
    search: '',
    interesse: '',
  },
  order = 'popular',
  consultorId,
  tipo,
}: IProps) {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [completeData, setCompleteData] = useState<any>();

  useEffect(() => {
    if (page == 1) {
      //quando abre a página pela primeira vez
      console.log('puxar os primeiros vídeos');
      getData([]);
      GetDataVistas([]);
    }
    if (page > 1) {
      //quando carrega um novo video
      console.log('puxar mais videos');
      getData(data);
      GetDataVistas(data);
    }
  }, [playlist, page]);

  useEffect(() => {
    getData([]);
  }, [where]);

  async function GetDataVistas(dados: any) {
    if (vistas) {
      setLoading(true);
      try {
        if (!page) {
          throw 'Não há mais vídeos para serem carregados';
        }

        {
          page === 1 && setData([]);
        }

        const response: any = await apiDoubts.get(
          `/postagens/usuario-historico`
        );
        setCompleteData(response);
        console.log('response =====>', response);
        const newData: any = [];

        response?.data?.data?.docs?.map((row: any, key: number) => {
          newData.push(row.postagens[0]);
        });

        if (!dados[0]) {
          //quando eu quero que apareça apenas os vídeos que foram puxados agora
          setData([...newData]);
        }
        if (dados[0]) {
          //quando eu quero carregar novas postagens
          console.log('adiciona postagens');
          setData([...dados, ...newData]);
        }

        // setData( [...data, ...newData]);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        console.log('error', error);
        toast.error(error, {
          className: 'toast-error',
          theme: 'colored',
        });
      }
    }
  }

  async function getData(dados?: any) {
    if (!vistas) {
      console.log('pegou os videos do padrões');
      setLoading(true);
      try {
        if (!page) {
          throw 'Não há mais vídeos para serem carregados';
        }

        {
          page === 1 && setData([]);
        }

        const response: any = await apiDoubts.get(
          `/postagens/descubra?pagination=true&limit=20&page=${page}&ordenar=${order}&listaInteresses=${
            where?.interesse ?? ''
          }&listaHashtags=${where?.hash ?? ''}`
        );
        setCompleteData(response);
        console.log('response =====>', response);
        const newData: any = [];
        if (!dados[0]) {
          newData.push({
            titulo: 'Nome do anúcio',
            thumbnail:
              'https://img.freepik.com/fotos-gratis/foto-de-uma-mulher-afro-americana-encantada-aponta-para-longe-com-os-dedos-indicadores-promovendo-um-lugar-incrivel-para-seu-conteudo-de-publicidade_273609-27157.jpg?w=2000',
            type: 'anuncio',
            consultores: {
              nickName: 'Faça seu anuncio aqui',
              foto: 'https://img.freepik.com/fotos-gratis/foto-de-uma-mulher-afro-americana-encantada-aponta-para-longe-com-os-dedos-indicadores-promovendo-um-lugar-incrivel-para-seu-conteudo-de-publicidade_273609-27157.jpg?w=2000',
            },
          });
        }

        response?.data?.data?.docs?.reverse().map((row: any, key: number) => {
          newData.push(row);
        });

        if (!dados[0]) {
          //quando eu quero que apareça apenas os vídeos que foram puxados agora
          setData([...newData]);
        }
        if (dados[0]) {
          //quando eu quero carregar novas postagens
          console.log('adiciona postagens');
          setData([...dados, ...newData]);
        }

        // setData( [...data, ...newData]);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        console.log('error', error);
        toast.error(error, {
          className: 'toast-error',
          theme: 'colored',
        });
      }
    }
  }

  const handleScroll = (event: any) => {
    console.log('scrollTop: ', event.currentTarget.scrollTop);
    console.log('offsetHeight: ', event.currentTarget.offsetHeight);
  };

  return (
    <Container
      loading={loading}
      lenght={data.length}
      posicao={posicao}
      playlist={playlist}
      onScroll={handleScroll}
    >
      <InfiniteScroll
        dataLength={data?.length} //This is important field to render the next data
        next={() => {
          setPage(completeData?.data?.data?.nextPage);
        }}
        hasMore={true}
        loader={<h4></h4>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        {data.length > 0 &&
          data.map((row: any, key) => (
            <CardPost
              playlist={playlist}
              key={key}
              item={row}
              type={tipo}
              posicao={posicao}
            />
          ))}

        {loading == false && data.length === 0 && (
          <div className="empty-posts">
            <img src={GIF} />
            <p>Nenhum registro encontrado.</p>
          </div>
        )}

        {loading && (
          <div className="divLoading">
            <IconLoading />
          </div>
        )}

        {/* {page && data.length > 1 && (
        <ButttonDefault
          title="carregar mais vídeos"
          tipo="2"
          onClick={() => {
            setPage(completeData.data.data.nextPage);
          }}
          icon={loading ? 'Loading' : ''}
        />
        )} */}
      </InfiniteScroll>
    </Container>
  );
}
