import styled from 'styled-components';

export const Container = styled.div`
  background-color: var(--online);
  padding: 3px;
  width: 72px;
  border-radius: 5px;
  text-align: center;
  color: var(--branco);
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
`;
