import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Banner from '../../../components/Banner';
import CardProdutosSevicos from '../../../components/Empresa/CardProdutosServicos';
import HeaderEmpresa from '../../../components/Empresa/HeaderEmpresa';
import apiDoubts from '../../../services/api';
import { Element } from '../Canal/styles';
import { Container, Content } from './styles';
import { AxiosResponse } from 'axios';

export default function EmpresaProdutos() {
  const params = useParams<any>();
  const [banner, setBanner] = useState<any>();
  const [produtosServicos, setProdutosServicos] = useState<any[]>([
    {
      nome: 'Consultoria de investimento',
      descricao: 'Uma consuyltoria do básico ao avançado sobre investimentos',
      imagem:
        'https://files.sunoresearch.com.br/p/uploads/2018/08/empresas-de-investimento-800x450.jpg',
      link: 'https://google.com',
      botao: 'Consultar agora',
    },
    {
      nome: 'Consultoria de investimento',
      descricao: 'Uma consuyltoria do básico ao avançado sobre investimentos',
      imagem:
        'https://files.sunoresearch.com.br/p/uploads/2018/08/empresas-de-investimento-800x450.jpg',
      link: 'https://google.com',
      botao: 'Consultar agora',
    },
    {
      nome: 'Consultoria de investimento',
      descricao: 'Uma consuyltoria do básico ao avançado sobre investimentos',
      imagem:
        'https://files.sunoresearch.com.br/p/uploads/2018/08/empresas-de-investimento-800x450.jpg',
      link: 'https://google.com',
      botao: 'Consultar agora',
    },
  ]);

  useEffect(() => {
    getBanner();
    getProducts()
  }, []);

  async function getBanner() {
    await apiDoubts.get(`/banners?limit=2`).then((response: any) => {
      setBanner(response?.data?.data);
    });
  }

  async function getProducts() {
    apiDoubts.get(`/produtosservicos?idEmpresa=${params?.id}`).then((response:AxiosResponse<any>)=>{
      setProdutosServicos(response?.data?.data)
      console.log(response?.data?.data)
    })
  }

  return (
    <Container id="ContainerEmpresaProdutos">
      <HeaderEmpresa id={params.id} page="produtos" />
      <Element />
      <Content id="ContentEmpresaProdutos">
        <div className="left">
          <h2 className="title">Produtos e Serviços</h2>
          <div className="listProdutosServicos">
            {produtosServicos?.map((row: any, key: number) => (
              <CardProdutosSevicos data={row} key={key} />
            ))}
          </div>
        </div>
        <div className="rigth">
          {banner && (
            <>
              <Banner peso="1" position="1" typeBanner="322x227" />
              <Banner peso="1" position="2" typeBanner="322x673" />
            </>
          )}
        </div>
      </Content>
    </Container>
  );
}
