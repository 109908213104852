import { Container } from './styles';
import Select from 'react-select';
import { useEffect, useState } from 'react';

export interface IOptions {
  label: any;
  value: any;
}

interface ISelect {
  tipo?: '1' | '2';
  options: IOptions[];
  value: any;
  onChange(value: IOptions): void;
  placeholderValue?: string
}

export default function SelectDefaultCadastrar({
  options,
  onChange,
  value,
  tipo,
  placeholderValue ='Selecionar...'
}: ISelect) {
  const [valueState, setValueState] = useState<any>(value);

  useEffect(() => {
    setValueState(value);
  }, [value]);

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      border: '1px solid #D0D5DD',
      height: '40px',
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: '',
      fontSize: 13,
      fontWeight: 400,
    }),
  };

  const customStyles2 = {
    option: (provided: any, state: any) => ({
      ...provided,
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: '#E7E7F3',
      border: 'none',
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: '#AAA',
      fontSize: 13,
      fontWeight: 400,
    }),
  };

  return (
    <Container tipo={tipo}>
      <Select
        placeholder={placeholderValue}
        options={options}
        onChange={(e: IOptions) => onChange(e)}
        value={valueState}
        styles={tipo === '2' ? customStyles2 : customStyles}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    </Container>
  );
}
