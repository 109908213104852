// Styles
import * as S from './styles';

// Libraries
import moment from 'moment';

// Types
import { IChartDashboardTooltip } from './types';

export const ChartDashboardTooltip = ({
  payload,
  label,
}: IChartDashboardTooltip) => {
  function formatText(text: string) {
    return text?.charAt(0)?.toUpperCase() + text?.slice(1);
  }

  function handleLabel(label: string) {
    const dateFormat = /^\d{2}-\d{2}-\d{4}$/; // 'DD-MM-YYYY';

    if (dateFormat?.test(label)) {
      const fullDate = moment(label, 'DD-MM-YYYY')
        ?.locale('pt-br')
        ?.format('ddd, DD [de] MMM[.] [de] YYYY');

      return formatText(fullDate);
    }

    const monthDate = moment(label, 'MM-YYYY')
      ?.locale('pt-br')
      ?.format('MMM[.] [de] YYYY');

    return formatText(monthDate);
  }

  function formatValue(value: number, type: 'currency' | 'grouping') {
    const format: { [key: string]: any } = {
      currency: {
        style: 'currency',
        minimumFractionDigits: 2,
        currency: 'BRL',
      },
      grouping: {
        useGrouping: true,
      },
    };

    const localeParameter = type === 'currency' ? 'pt-BR' : undefined;

    return value?.toLocaleString(localeParameter, format[type]);
  }

  return (
    <S.Container>
      <S.TitleContainer>
        <p className="label">{handleLabel(label ? label : '')}</p>
      </S.TitleContainer>

      <S.MetricsContainer>
        {payload?.map((payload, key) => (
          <div className="metric" key={key}>
            <p className="metric-value">
              {payload?.name === 'Valor Gasto' ? (
                <>{formatValue(payload?.value, 'currency')}</>
              ) : (
                <>{formatValue(payload?.value, 'grouping')}</>
              )}
            </p>
            <p className="metric-label">{payload?.name}</p>
          </div>
        ))}
      </S.MetricsContainer>
    </S.Container>
  );
};
